import { createSelector } from 'reselect';
import { StoreType } from './index';
import { getMindItems } from './mindItems.selectors';
import { ContentItem } from '../types/AppContent';

const getDownloadedItems = (state: StoreType) => state.download.items;

export const selectDownloadedItems = createSelector(
  [getDownloadedItems, getMindItems],
  (downloaded, mindItems) =>
    downloaded
      .map(
        (id) =>
          mindItems[id] && {
            ...mindItems[id].data,
            type: 'mindItem' as const,
          }
      )
      .filter((v) => !!v) as ContentItem[]
);
