import { DownloadInformationData } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import React, { useState } from 'react';
import services from '../services';
import { useSelector } from 'react-redux';
import { selectUser } from '../reducers/user.selectors';
import { selectMindItem } from '../reducers/mindItems.selectors';
import DownloadModal, { DownloadPreselect } from '../components/modals/DownloadModal';
interface ShowOptions {
  preselect?: DownloadPreselect;
}
type ShowFunc = (mindItemId: string, options?: ShowOptions) => void;
const noop = () => undefined;

interface Context {
  isOpen: boolean;
  show: ShowFunc;
}

const defaultContext: Context = {
  isOpen: false,
  show: noop as ShowFunc,
};

const DownloadModalContext = React.createContext(defaultContext);

const DownloadModalProvider: React.FC = (props) => {
  const [downloadInfo, setDownloadInfo] = useState({} as DownloadInformationData);
  const [preselect, setPreselect] = useState<DownloadPreselect>('mindItem');
  const user = useSelector(selectUser);

  const showDownloadModal: ShowFunc = (mindItemId, options) => {
    setPreselect((options && options.preselect) || 'mindItem');
    services.mindItem
      .getDownloadInformation(mindItemId)
      .then((data: DownloadInformationData) => {
        setDownloadInfo(data);
        setModalVisibility(true, mindItemId);
      })
      .catch(noop);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [mindItemId, setMindItemId] = useState('');
  const mindItem = useSelector(state => selectMindItem(state, mindItemId));
  const productId = mindItem?.product || '';

  const isPremium = !!(user && user.isPremium);

  const setModalVisibility = (open: boolean, mindItemId?: string) => {
    if (mindItemId) setMindItemId(mindItemId);
    setIsOpen(open);
  };

  return (
    <DownloadModalContext.Provider value={{
      show: showDownloadModal,
      isOpen,
    }}>
      {isOpen && <DownloadModal
        downloadInfo={downloadInfo}
        isPremium={isPremium}
        isOpen={isOpen}
        onClose={() => setModalVisibility(false)}
        preselect={preselect}
        mindItemId={mindItemId}
        productId={productId}
      />}
      {props.children}
    </DownloadModalContext.Provider>
  );
};

export { DownloadModalContext, DownloadModalProvider };
