import React, { createContext, useContext } from 'react';
import { withMedia } from 'react-media-query-hoc';

interface MediaContextValue {
  /**
   * @deprecated This value is deprecated, use viewport definitions instead
  */
  isMobile: boolean;
  /**
   * @deprecated This value is deprecated, use viewport definitions instead
  */
  isTablet: boolean;
  /**
   * @deprecated This value is deprecated, use viewport definitions instead
  */
  isDesktop: boolean;
  hasAnyFinePointer: boolean;
  isSmallViewport: boolean;
  isMediumViewport: boolean;
  isLargeViewport: boolean;
  isXLargeViewport: boolean;
  isCoarsePointer: boolean;
  isFinePointer: boolean;
}

const MediaContext = createContext<MediaContextValue>({
  hasAnyFinePointer: false,
  isMobile: false,
  isTablet: false,
  isDesktop: false,
  isSmallViewport: false,
  isMediumViewport: false,
  isLargeViewport: false,
  isXLargeViewport: false,
  isCoarsePointer: false,
  isFinePointer: false,
});

export const MediaProvider = withMedia((props) => {
  const media = props.media;

  return (
    <MediaContext.Provider value={{
      hasAnyFinePointer:
        window.matchMedia('(any-pointer: fine), (pointer: fine), (pointer: none)').matches, // mouse possible
      isMobile: media.xs || media.sm,
      isTablet: media.md,
      isDesktop: media.xl || media.lg || media.md,
      isSmallViewport: media.viewportSmall, // 0 - 767px
      isMediumViewport: media.viewportMedium, // 768px - 1023px
      isLargeViewport: media.viewportLarge, // 1024px - 1339px
      isXLargeViewport: media.viewportXLarge, // <1440px
      isCoarsePointer: window.matchMedia('(pointer: coarse)').matches, // touch
      isFinePointer: window.matchMedia('(pointer: fine), (pointer: none)').matches, // mouse
    }}>
      {props.children}
    </MediaContext.Provider>
  );
});

const useMedia = () => useContext(MediaContext);

export default useMedia;
