import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PaymentPeriod } from '@klab-berlin/api-sdk/lib/types/common';
import services from '../../../../services';
import { paymentRhythmDisplayNames } from '../../../../constants';
import { getSubscription } from '../../../../reducers/user.selectors';
import FormInputWrapper from '../../../common/FormInputWrapper';
import PersonalDetailsInputGroup from '../../PersonalDetailsInputGroup';
import { Select } from '../../../common/Select';
import PersonalDetailsActions from '../../Common/PersonalDetailsActions';

interface OwnProps {
  onCancel: () => void;
  onSuccess: () => void;
}

interface formFields {
  paymentPeriod: PaymentPeriod[];
}

const PaymentRhythmForm: React.FC<OwnProps> = (props) => {
  const [serverError, setServerError] = useState<Error | null>(null);
  const { handleSubmit, control, reset } = useForm<formFields>();
  const { t } = useTranslation();
  const subscription = useSelector(getSubscription);

  const paymentRhythmOptions = subscription?.paymentPeriod.possibleValues.map(value => (
    {
      value: value,
      name: t(paymentRhythmDisplayNames[value])
    }
  )) || [];

  const onSubmit = handleSubmit(useCallback(async (formData: formFields) => {
    setServerError(null);
    try {
      await services.billing.updatePaymentPeriod(formData.paymentPeriod[0]);
      props.onSuccess();
    } catch (error: any) {
      setServerError(error);
    }
  }, []));

  useEffect(() => {
    reset({
      paymentPeriod: subscription?.paymentPeriod.type ? [subscription?.paymentPeriod.type] : []
    });
  }, [subscription]);

  return (
    <form aria-label='form' onSubmit={onSubmit}>
      <PersonalDetailsInputGroup>
        <FormInputWrapper
          label={t('Payment rhythm')}
          labelFor='paymentPeriod'
        >
          <Controller
            as={<Select
              onSelectionClear={reset}
              options={paymentRhythmOptions}
              label={t('Payment rhythm')}
              defaultValue={subscription?.paymentPeriod.type ? [subscription?.paymentPeriod.type] : []}
            />}
            control={control}
            name='paymentPeriod'
          >
          </Controller>
        </FormInputWrapper>
      </PersonalDetailsInputGroup>
      <PersonalDetailsActions
        showError={!!serverError }
        onCancel={props.onCancel}
      />
    </form>
  );
};

export default PaymentRhythmForm;
