import React from 'react';
import { useTranslation } from 'react-i18next';
import MasterLayout from '../../../components/AppLayout/MasterLayout';
import { routes } from '../../../constants';
import PersonalDetailsPageNavItem from './PersonalDetailsPageNavItem';
import './personalDetailsPageLayout.scss';
import { Tab } from 'react-bootstrap';
import MuTabs from '../../../components/common/MuTabs';
import useRouter from '../../../utils/useRouter';
import { PersonalDetailsSection } from '../PersonalDetailsPage';

interface PersonalDetailsPageLayoutProps {
  currentSection: PersonalDetailsSection;
}

const PersonalDetailsPageLayout: React.FC<PersonalDetailsPageLayoutProps> = ({ children, currentSection }) => {
  const { t } = useTranslation();
  const { router } = useRouter();
  const onTabChange = (key: PersonalDetailsSection) => {
    router.push(`/${routes.personalDetails.root}/${key}`);
  };

  return (
    <MasterLayout>
      <div className='personal-details-page-layout'>
        <div className='personal-details-page-layout__nav'>
          <div className='personal-details-page-layout__nav--tablet-mobile'>
            <Tab.Container
              activeKey={currentSection}
              onSelect={(key) => onTabChange(key as PersonalDetailsSection)}
            >
              <MuTabs tabs={[
                {
                  key: PersonalDetailsSection.PROFILE,
                  name: t('My Profile')
                },
                {
                  key: PersonalDetailsSection.ACCOUNT,
                  name: t('My Account')
                },

              ]} />
            </Tab.Container>
          </div>
          <div className='personal-details-page-layout__nav--desktop'>
            <PersonalDetailsPageNavItem
              url={`/${routes.personalDetails.root}/${PersonalDetailsSection.PROFILE}`}
              iconName='profile'
              text={`${t('My Profile')}`}
              isActive={currentSection === PersonalDetailsSection.PROFILE}
            />
            <PersonalDetailsPageNavItem
              url={`/${routes.personalDetails.root}/${PersonalDetailsSection.ACCOUNT}`}
              iconName='payment'
              text={`${t('My Account')}`}
              isActive={currentSection === PersonalDetailsSection.ACCOUNT}
            />
          </div>
        </div>
        <div className='personal-details-page-layout__content'>{children}</div>
      </div>
    </MasterLayout>
  );
};

export default PersonalDetailsPageLayout;
