import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { 
  Autocomplete,
  Box,
  Button, 
  CircularProgress, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SvgIcon,
  TextField,
} from '@mui/material';
import constants, { NONE_OF_THE_ABOVE_OPTION } from '../../../constants';
import { 
  ClassYears, 
  GenerateLernsetWorksheet,
  Languages 
} from '@klab-berlin/api-sdk/lib/types/requests/Ai';
import services from '../../../services';
import { SnackbarContext } from '../../../state/SnackbarContextProvider';
import { borderRadius, palette, spaces } from '../../../assets/styles/themes/tokens';
import copyLink from '../../../assets/lernsets/icons/link.svg';
import check from '../../../assets/lernsets/icons/check.svg';

interface OwnProps {
    isOpen: boolean;
    onCloseModal: () => void;
}

interface FormErrors {
  [key: string]: string;
}

type InputName = keyof GenerateLernsetWorksheet;

const GenerateWorksheetDialog: React.FC<OwnProps> = (props) => {
  const { t } = useTranslation();
  const { openSnackbar } = useContext(SnackbarContext);
  const { 
    isOpen,
    onCloseModal,
  } = props;

  const markdownRef = useRef<HTMLInputElement>(null);
  const [isGeneratingWorksheet, setIsGeneratingWorksheet] = useState(false);
  const [inputSubject, setInputSubject] = useState('');
  const [worksheet, setWorksheet] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  
  const [formData, setFormData] = useState<GenerateLernsetWorksheet>({
    subject: '',
    classYear: ClassYears[8],
    language: Languages[0],
    topic: '',
  });

  const [formErrors, setFormErrors] = useState<FormErrors>({
    subject: '',
    topic: '',
  });

  const germanSubjects = useMemo(
    () => constants.subjects
      .filter((subject) => subject.id !== NONE_OF_THE_ABOVE_OPTION && subject.englishTranslation)
      .map((subject) => subject.text),
    [constants.subjects],
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (isCopied) {
      timeout = setTimeout(() => setIsCopied(false), 5000);
    }

    return () => clearTimeout(timeout);
  }, [isCopied]);

  const handleCopy = async () => {
    const errorNotification = () => {
      openSnackbar({
        type: 'error',
        message: t('The worksheet was not copied. Please try again'),
      });
    };

    if (markdownRef.current) {
      try {
        if (navigator.clipboard) {
          await navigator.clipboard.write([
            new ClipboardItem({
              'text/html': new Blob([markdownRef.current.innerHTML], { type: 'text/html' }),
            })
          ]);
          setIsCopied(true);
        } else {
          const range = document.createRange();
          range.selectNodeContents(markdownRef.current);
          const selection = window.getSelection();
          if (selection) {
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand('copy');
            setIsCopied(true);
          }
        }
      } catch (error) {
        errorNotification();
      }
    }
  };

  const validateForm = () => {
    const errors: { [key: string]: string } = {};
    const errorMessage = t('This field is required');
  
    if (!formData.subject.trim()) errors.subject = errorMessage;
    if (!formData.topic.trim()) errors.topic = errorMessage;

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFormChange = (inputName: InputName, newValue: string) => {
    if (formErrors[inputName]) {
      setFormErrors((prevErrors: FormErrors) => ({
        ...prevErrors,
        [inputName]: '',
      }));
    }

    setFormData((prevData: GenerateLernsetWorksheet) => ({
      ...prevData,
      [inputName]: newValue,
    }));
  };

  const handleConfirm = async () => {
    if (!validateForm()) return;

    try {
      setWorksheet('');
      setIsGeneratingWorksheet(true);
      const { subject, classYear, language, topic } = formData;
      const englishSubject = constants.subjects.find((s) => s.text === subject)?.englishTranslation;
      if (englishSubject) {
        const worksheetText = 
          await services.ai.generateLernsetWorksheet({ subject: englishSubject, classYear, language, topic });
        setWorksheet(worksheetText);
      }
    } catch (error) {
      openSnackbar({
        type: 'error',
        message: t('The worksheet could not be generated. Please try again'),
      });
    }
  
    setIsGeneratingWorksheet(false);
  };

  return (
    <Dialog open={isOpen}
      onClose={onCloseModal}
    >
      <DialogTitle sx={{ typography: 'h5' }}>
        Generate Worksheet
      </DialogTitle>
      <DialogContent sx={{ mt: 2, minHeight: 190 }}>
        <Box component='form' sx={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
          <FormControl fullWidth>
            <Autocomplete
              id='subject-select'
              value={formData.subject}
              onChange={(_, newValue) => {
                if (newValue) handleFormChange('subject', newValue);
              }}
              inputValue={inputSubject}
              onInputChange={(_, newInputValue) => setInputSubject(newInputValue)}
              disableClearable
              options={germanSubjects}
              isOptionEqualToValue={(option, value) => value === '' || option === value ? true : false}
              renderInput={(params) => (
                <TextField
                  {...params}
                  data-testid='subject-textfield'
                  label={t('Subject')}
                  variant='standard'
                  size='small'
                  required
                  disabled={isGeneratingWorksheet}
                  error={!!formErrors.subject}
                  helperText={formErrors.subject}
                  onBlur={() => {
                    if (!formData.subject) {
                      setFormErrors((prevData) => ({
                        ...prevData,
                        subject: t('This field is required'),
                      }));
                    }
                  }}
                />
              )}
            />
          </FormControl>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 6,
          }}>
            <FormControl sx={{ minWidth: 180 }} variant='standard'>
              <InputLabel id='class-year-select-standard-label'>{t('Class years')}</InputLabel>
              <Select
                name='classYear'
                labelId='class-year-select-standard-label'
                id='class-year-select-standard'
                value={formData.classYear}
                onChange={(e) => handleFormChange('classYear', e.target.value)}
                label={t('Class years')}
                defaultValue={ClassYears[0]}
                disabled={isGeneratingWorksheet}
              >
                {ClassYears.map((classYear) => (
                  <MenuItem key={classYear} value={classYear}>{classYear}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 120 }} variant='standard'>
              <InputLabel id='language-select-standard-label'>{t('Language')}</InputLabel>
              <Select
                name='language'
                labelId='language-select-standard-label'
                id='language-select-standard'
                value={formData.language}
                onChange={(e) => handleFormChange('language', e.target.value)}
                label={t('Language')}
                defaultValue={t(Languages[0])}
                disabled={isGeneratingWorksheet}
              >
                {Languages.map((language) => (
                  <MenuItem key={language} value={language}>{t(language)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <FormControl fullWidth>
            <TextField
              data-testid='topic-textfield'
              name='topic'
              value={formData.topic}
              label={t('SubTopic')}
              variant='standard'
              size='small'
              type='text'
              placeholder='Specify the worksheet topic...'
              required
              disabled={isGeneratingWorksheet}
              error={!!formErrors.topic}
              helperText={formErrors.topic}
              onBlur={() => {
                if (!formData.topic) {
                  setFormErrors((prevData) => ({
                    ...prevData,
                    topic: t('This field is required'),
                  }));
                }
              }}
              onChange={(e) => handleFormChange('topic', e.target.value)}
              inputProps={{ 
                maxLength: 150,
                style: {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }, 
              }}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogContent sx={{ mt: 2, maxHeight: 300, paddingBottom: 0 }}>
        <Box sx={{
          height: '100%',
          display: 'flex',
        }}>
          {isGeneratingWorksheet && (
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignSelf: 'center',
              width: '100%',
              marginBottom: spaces.sm,
            }}>
        
              <CircularProgress sx={{
                '& .MuiCircularProgress-svg': {
                  height: 'auto',
                  width: 'auto',
                },
              }} />
            </Box>
          )}
          {worksheet && (
            <Box 
              ref={markdownRef}
              sx={{
                borderRadius: borderRadius.sm,
                border: `1px solid ${palette.elevation.outlined}`,
                padding: spaces.lg,
                backgroundColor: palette.background.default,
                height: '100%',
                textAlign: 'justify',
                overflow: 'auto',
              }}>
              <ReactMarkdown>
                {worksheet}
              </ReactMarkdown>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogContent sx={{
        paddingTop: 0,
      }}>
        {worksheet && (
          <Button
            sx={{ mt: spaces.xxs }}
            variant='text'
            size='medium'
            onClick={handleCopy}
            startIcon={<SvgIcon
              component={isCopied ? check : copyLink} 
              sx={{ fill: palette.primary.main }} />
            }
          >
            {isCopied ? `${t('Copied')}!` : t('Copy')}
          </Button>
        )}
      </DialogContent>
      <DialogActions sx={{ mt: 1 }}>
        <Button 
          onClick={() => {
            setWorksheet('');
            onCloseModal();
          }}
          variant='text'
          color='secondary'
          size='large'
        >
          {t('Cancel')}
        </Button>
        <Button 
          disabled={isGeneratingWorksheet}
          onClick={handleConfirm}
          variant='contained'
          size='large'
        >
          Generate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GenerateWorksheetDialog;

