import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { updateNewsletter as updateNewsletterAction } from '../../../actions/user.actions';
import { selectUser } from '../../../reducers/user.selectors';
import useAction from '../../../utils/useAction';
import Checkbox from '../../common/Checkbox';
import PersonalDetailsSection from '../Common/PersonalDetailsSection';
import './newsletterPreference.scss';

const NewsletterPreference: React.FC = () => {
  const user = useSelector(selectUser);
  const { t } = useTranslation();

  const updateNewsletter = useAction(updateNewsletterAction);

  if (!user) {
    return <></>;
  }
  return (
    <PersonalDetailsSection
      title={t('Notifications')}
      renderNormalView={() => (
        <div className='newsletter-preference'>
          <p>{t('With our weekly to bi-weekly newsletter...')}</p>
          <Checkbox
            checked={user.isSubscribedToNewsletter}
            id='newsletter-preference'
            onChange={() => {
              updateNewsletter(!user.isSubscribedToNewsletter);
            }}
            label={t('I\'d like to receive the newsletter')}
          />
        </div>
      )}
    />
  );
};

export default NewsletterPreference;
