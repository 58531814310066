import { UpgradeCodeResponse } from '@klab-berlin/api-sdk/lib/types/responses/Configuration';
import { AsyncAction } from './common.actionTypes';

export enum FETCH_UPGRADE_CODE {
  START = 'FETCH_UPGRADE_CODE_START',
  SUCCESS = 'FETCH_UPGRADE_CODE_SUCCESS',
  ERROR = 'FETCH_UPGRADE_CODE_ERROR',
}

export type FetchUpgradeCodeAction = AsyncAction<
  typeof FETCH_UPGRADE_CODE.START, 
  typeof FETCH_UPGRADE_CODE.SUCCESS, 
  typeof FETCH_UPGRADE_CODE.ERROR, 
  UpgradeCodeResponse,
  { upgradeCode: string }
>;
