import { StoreType } from './index';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import { Product } from '@klab-berlin/api-sdk/lib/types/responses/Product';
import { createSelector } from 'reselect';
import { getProducts } from './products.selectors';

export const getSubjectProducts = (state: StoreType, subjectId: string, prefix?: string) => 
  state.dashboard.productsBySubjectId[prefix ? `${prefix}-${subjectId}` : subjectId];

export const getProductsBySubjectId = createSelector(
  [getSubjectProducts, getProducts],
  (products, allProducts) => {
    if (!products) return [];

    return products.ids
      .map(productId => allProducts[productId]?.data as Product)
      .filter(p => p);
  } 
);

export const getProductsBySubjectIdStatus = createSelector(
  [getSubjectProducts],
  (products) => {
    if (!products) return AsyncActionStatus.INITIAL;

    return products.status;
  }
);
