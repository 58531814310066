import React, { useContext } from 'react';
import ActionButton from '../ActionButton';
import { ShareModalContext } from '../../state/ShareModalProvider';
import { ShareModalItemTypeEnum } from '../../types/sharing';

interface ShareItemButtonProps {
  itemId: string;
  type: ShareModalItemTypeEnum;
  iconClassName?: string;
  preOpenModal?: () => void | undefined;
}

const ShareItemButton: React.FC<ShareItemButtonProps> = (props) => {
  const { itemId, type, iconClassName, preOpenModal } = props;
  const {
    show: showShareModal,
    isOpen: isShareModalOpen
  } = useContext(ShareModalContext);

  const onClick = () => {
    if (preOpenModal) {
      preOpenModal();
    }
    
    showShareModal(itemId, type);
  };

  return (
    <ActionButton
      onClick={onClick}
      icon='share'
      highlight={isShareModalOpen}
      iconClassName={iconClassName}
      testId='share-button'
    />
  );
};

export default ShareItemButton;
