import React from 'react';
import ManageItemInCollectionsButton from '../../ManageItemInCollectionsButton';
import PinItemButton from '../../PinItemButton';
import './productCard.scss';
import ItemCardFooter from '../common/ItemCardFooter';
import useMedia from '../../../utils/useMedia';
import Truncate from '../../Truncate';
import CardThumbnail from '../common/CardThumbnail';
import TagContainer from '../common/TagContainer';
import WithTracking from '../../utils/WithTracking';
import { trackingEvents } from '../../../services/tracking/trackConfig';
import CardDescription from '../common/CardDescription';

interface ProductCardProps {
  attachmentsCount: number;
  classYears: number[];
  description?: string;
  id: string;
  isPinned: boolean;
  mindItemsCount: number;
  publisher?: string;
  subjects: string[];
  thumbnail: string;
  title: string;
  hideCollectionAction?: boolean;
  hidePinAction?: boolean;
  isNew: boolean;
  isReadOnly: boolean;
  index?: number;
  searchEngineVersion?: number;
  schoolTypes: string[];
}

const ProductCard: React.FC<ProductCardProps> = (props) => {
  const { isCoarsePointer } = useMedia();
  const {
    isNew,
    isReadOnly,
    id, 
    thumbnail,
    description,
    title,
    subjects,
    publisher,
    isPinned,
    hideCollectionAction,
    hidePinAction,
    classYears,
    mindItemsCount,
    attachmentsCount,
    index,
    searchEngineVersion,
    schoolTypes
  } = props;

  const itemData = {
    id,
    title,
    subjects,
    publisher,
    classYears,
    schoolTypes,
    isReadOnly,
  };

  return (
    <WithTracking
      events={{
        onClick: trackingEvents.itemOpen,
        onMiddleClick: trackingEvents.itemOpen,
        onContextMenu: trackingEvents.itemOpen,
      }}
      eventPayload={{ other: { 'product_id': id, index, searchEngineVersion } }}
    >
      <div className='product-card' data-testid='product-card'>
        <TagContainer isNew={isNew} isReadOnly={isReadOnly} />
        <div className='product-card__content'>
          <div className='product-card__grid'>
            <CardThumbnail
              thumbnailUrl={thumbnail}
              className='product-card__thumbnail'
            />
            <div
              className='product-card__actions'
            >
              {!hideCollectionAction &&
              <ManageItemInCollectionsButton
                documentId={id}
                itemData={itemData}
                type='product'
                iconClassName='product-card__actions__collection'
              />
              }
              {!hidePinAction &&
              <PinItemButton
                className='mt-2'
                isPinned={isPinned}
                itemData={itemData}
                documentId={id}
                type='product'
                iconClassName='product-card__actions__pin'
              />
              }
            </div>
            <div className='product-card__title'>
              <Truncate text={title} lines={isCoarsePointer ? 5 : 2} />
            </div>
            {isCoarsePointer &&
            <CardDescription 
              description={description} 
              numLines={5} 
              className='product-card__description'
            />
            }
            <div className='product-card__footer'>
              <ItemCardFooter
                publisher={publisher || ''}
                subject={subjects[0]}
                classYears={classYears}
                mindItemsCount={mindItemsCount}
                attachmentsCount={attachmentsCount}
              />
            </div>
          </div>
        </div>
      </div>
    </WithTracking>
  );
};

export default React.memo(ProductCard);
