import React, { useEffect, useState } from 'react';
import useRouter from '../../../utils/useRouter';
import { routes } from '../../../constants';
import lernsetsService from '../../../services/lernsets.service';
import { PublicLernset } from '@klab-berlin/api-sdk/lib/types/responses/Lernsets';
import LernsetsMasterLayout from '../../../components/lernsets/LernsetsMasterLayout';
import { Box, Button, CircularProgress, IconButton, Stack, SvgIcon, Typography } from '@mui/material';
import { borderRadius, palette, spaces } from '../../../assets/styles/themes/tokens';
import { useTranslation } from 'react-i18next';
import printer from '../../../assets/lernsets/icons/printer.svg';
import downloader from '../../../assets/lernsets/icons/download.svg';
import invalidLinkSvg from '../../../assets/lernsets/invalid-public-link.svg';
import { splitArrayIntoChunks } from '../../../utils/splitArrayIntoChunks';
import { byteArrayToObjectUrl } from '../../../utils/byteArrayToObjectUrl';
import _ from 'lodash';
import services from '../../../services';
import { downloadFile } from '../../../utils/lernsets';
import openPrintDialogWithFile from '../../../utils/openPrintDialogWithFile';
import useScreenSize from '../../../utils/useScreenSize';
import { getAmpliDeviceId, getAmpliSessionId } from '../../../utils/ampli';
import { EventType } from '@klab-berlin/api-sdk/lib/types/requests/Track';

const PublicLernsetPage: React.FC = () => {
  const { match: { params: { accessToken } }, router } = useRouter();
  const [publicLernset, setPublicLernset] = useState<PublicLernset>();
  const lernsetParts = publicLernset?.parts;
  const [isDownloadDisabled, setIsDownloadDisabled] = useState(false);
  const [isPrintDisabled, setIsPrintDisabled] = useState(false);
  const [partImageUrlById, setPartImageUrlById] = useState<{ [key: string]: string; }>({});
  const { t } = useTranslation();
  const { isSmallScreen } = useScreenSize();
  const hasInitialPartsImages = !_.isEmpty(partImageUrlById);
  const isEmptyLernset = lernsetParts && lernsetParts.length === 0;
  const fetchImagesChunkSize = 5;
  const a4ratio = 1.426;
  const imageMaxSideLength = 993;
  const defaultImageHeight = imageMaxSideLength;
  const defaultImageWidth = defaultImageHeight/a4ratio;

  useEffect(() => {
    lernsetsService.getPublicLernset(accessToken)
      .then((result) => setPublicLernset(result))
      .catch((error: any) => {
        if (error.statusCode == 403 || error.message.includes('expire')) {
          setPublicLernset({ id: '', title: '', parts: [] });
        }
        else {
          router.push(`/${routes.notFound.root}`);
        }
      });
  }, []);

  useEffect(() => {
    if (!lernsetParts || lernsetParts.length < 1) return;

    fetchPartsImages(publicLernset, fetchImagesChunkSize);
  }, [publicLernset]);

  const trackPublicLernset = async (lernset: PublicLernset, eventType: EventType, accessToken?: string) => {
    const sessionId = getAmpliSessionId();
    const deviceId = getAmpliDeviceId();

    if (!sessionId || !deviceId) return; 
    
    await lernsetsService.trackLernset(lernset.id, {
      'event_properties': {
        'lernset id': lernset.id,
        'lernset title': lernset.title,
        'page source': 'public page lernsets'
      },
      'event_type': eventType,
      'session_id': sessionId,
      'device_id': deviceId,
    }, accessToken);
  };

  const fetchPartsImages = async (lernset: PublicLernset, chunkSize: number) => {
    const lernsetPartsLength = lernset.parts.length;
    const partIndices = Array.from(Array(lernsetPartsLength).keys());
    
    // Split partIndices into chunks
    const chunkedPartIndices: number[][] = splitArrayIntoChunks(partIndices, chunkSize);

    // Fetch parts-images of the lernset in chunks
    for (const chunk of chunkedPartIndices) {
      const partsImages = await lernsetsService.getPartsImages(lernset.id, chunk, imageMaxSideLength, accessToken);
      const urlByPartId: { [key: string]: string; } = {};

      for (const partId in partsImages) {
        urlByPartId[partId] = byteArrayToObjectUrl(partsImages[partId].data, 'image/webp');
      }

      setPartImageUrlById((prev) => ({ ...prev, ...urlByPartId }));
    }
  };

  const onDownloadClick = async () => {
    if (!publicLernset) return;

    setIsDownloadDisabled(true);
    const documentUrl = await services.lernsets.getLernsetDocumentUrl(publicLernset, accessToken);
    downloadFile(documentUrl, `${publicLernset?.title}.pdf`);
    URL.revokeObjectURL(documentUrl);
    setIsDownloadDisabled(false);

    await trackPublicLernset(publicLernset, 'Set Downloaded', accessToken);
  };

  const onPrintClick = async () => {
    if (!publicLernset) return;

    setIsPrintDisabled(true);
    const documentUrl = await services.lernsets.getLernsetDocumentUrl(publicLernset, accessToken);
    openPrintDialogWithFile(documentUrl);
    URL.revokeObjectURL(documentUrl);
    setIsPrintDisabled(false);

    await trackPublicLernset(publicLernset, 'Set Printed', accessToken);
  };

  const topBarButtons = <Stack direction='row' spacing={spaces.xs}>
    <Button
      onClick={onPrintClick}
      variant='outlined'
      size='large'
      color='primary'
      disabled={isPrintDisabled}
      key='print-button'
      startIcon={
        <SvgIcon
          component={printer}
          sx={{ fill: isPrintDisabled ? palette.action.disabled : palette.primary.main }}
        />}
    >
      {t('Print')}
    </Button>
    <Button
      onClick={onDownloadClick}
      variant='contained'
      size='large'
      color='primary'
      disabled={isDownloadDisabled}
      key='download-button'
      startIcon={
        <SvgIcon 
          component={downloader} 
          sx={{ fill: isDownloadDisabled ? palette.action.disabled : palette.primary.contrastText }} 
        />}
    >
      {t('Download')}
    </Button>
  </Stack>;

  const topBarIconButtons = <Stack direction='row' spacing={spaces.xs}>
    <IconButton
      onClick={onPrintClick}
      size='medium'
      color='primary'
      disabled={isPrintDisabled}
      data-testid='print-icon-button'
    >
      <SvgIcon
        component={printer}
      />
    </IconButton>
    <IconButton
      onClick={onDownloadClick}
      size='medium'
      color='primary'
      disabled={isDownloadDisabled}
      data-testid='download-icon-button'
    >
      <SvgIcon
        component={downloader}
      />
    </IconButton>
  </Stack>;

  const renderPageCards = () => {
    return lernsetParts?.map((part) => {
      const url = partImageUrlById[part.id];

      if (!url) return <React.Fragment key={`empty-${part.id}`}></React.Fragment>;

      return (
        <Box 
          key={part.id} 
          id={`part-${part.id}`} 
          data-testid={`lernset-part-${part.id}`}
          sx={{ 
            position: 'relative',

          }}
        >
          <Box
            component='img'
            sx={{
              width: '100%',
              height: '100%',
              borderRadius: borderRadius.lg,
            }}
            src={url}
            data-testid='part-image' />
        </Box>
      );
    });
  };

  const renderLernsetContent = () => {
    if (!isEmptyLernset && hasInitialPartsImages)
      return <>
        <Typography variant='h5'
          sx={{
            maxWidth: defaultImageWidth,
            width: '100%',
            textAlign: 'left',
          }}
        >
          {publicLernset?.title}
        </Typography>
        {renderPageCards()}
      </>;

    if (isEmptyLernset) return (
      <Box 
        data-testid='empty-state-container'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: spaces.md,
          alignItems: 'center',
        }}
      >
        <SvgIcon component={invalidLinkSvg} sx={{ width: '100%', height: '100%' }} viewBox='0 0 400 300' />
        <Typography variant='h5'>{t('This page is no longer valid')}</Typography>
        <Typography variant='body2' sx={{ whiteSpace: 'pre-line', textAlign: 'center' }}>
          {t('It looks like the link has expired or the lesson has been deleted')}
        </Typography>
      </Box>
    );

    return (
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <CircularProgress
          color='primary'
          sx={{
            '& .MuiCircularProgress-svg': {
              height: 'auto',
              width: 'auto',
            },
          }}        
        />
      </Box>
    ); 
  };

  const getTopBarButtons = () => {
    return isSmallScreen ? topBarIconButtons : topBarButtons;
  };

  return (
    <LernsetsMasterLayout
      topRightComponent={hasInitialPartsImages ? getTopBarButtons() : undefined}
      isPublicView={true}
      hideSidebar={true}
    >
      <Box sx={{
        padding: isSmallScreen ? spaces.md : spaces.lg,
        paddingBottom: isSmallScreen ? spaces.xl : spaces.xxxxl,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 auto',
        minHeight: '100%',
        gap: isSmallScreen ? spaces.xs : spaces.md,
      }}>
        {renderLernsetContent()}
      </Box>
    </LernsetsMasterLayout>
  );
};

export default PublicLernsetPage;
