import React, { useContext } from 'react';
import { CookiebotContext } from '../../state/CookiebotContextProvider';
import FacebookLoginButton, { FacebookLoginButtonOwnProps } from './FacebookLoginButton';
import FacebookReplacementButton from './FacebookReplacementButton';

const FacebookLogin: React.FC<FacebookLoginButtonOwnProps> = (props) => {
  const consent = useContext(CookiebotContext);
  return (
    <>
      {
        consent.marketing &&
        <FacebookLoginButton
          onClick={props.onClick}
          onLoginSuccess={props.onLoginSuccess}
          onLoginFailure={props.onLoginFailure}
        />
      }
      { !consent.marketing && <FacebookReplacementButton />}
    </>
  );
};

export default FacebookLogin;
