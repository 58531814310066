const openPrintDialogWithFile = (fileUrl: string) => {
  const iframe = document.createElement('iframe');
  document.body.appendChild(iframe);
  
  iframe.style.display = 'none';
  iframe.src = fileUrl;
  iframe.onload = function () {
    iframe.focus();
    iframe.contentWindow?.print();
  };
};

export default openPrintDialogWithFile;
