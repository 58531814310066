import { Match, Router } from 'found';
import { useSelector } from 'react-redux';
import { selectUser } from '../../reducers/user.selectors';
import services from '../../services';
import { getAmpliSearchEventPayload, mapDesignPreference } from '../../utils/ampli';
import { SearchFilterApplied } from '../../ampli';
import { useNewDesign } from '../../state/DesignPreferenceContextProvider';

const useQueryModification = <T extends Record<string, any>,>(router: Router, match: Match) => {
  const user = useSelector(selectUser);
  const { prefersNewDesign, shouldShowDesignPreferenceSwitch } = useNewDesign();
  /**
   * Update query params with given params
   * Remove query param when value is null, undefined, 0, '0', or empty array
   * @param params 
   */
  const updateQueryParams = (params: T) => {  
    const newQuery: { [key: string]: string } = {};
    const currentQuery = match.location.query;

    Object.entries(params).forEach(([param, value]) => {
      if (!value || value.length === 0) {
        delete currentQuery[param];
        return;
      }

      if (Array.isArray(value)) {
        value = value.join(',');
      }

      newQuery[param] = value.toString();
    });
    
    const query = { ...currentQuery, ...newQuery };
    if (user) {
      const searchPayload = getAmpliSearchEventPayload({
        query,
        term: query.term,
      });      

      if (searchPayload) {
        const event = new SearchFilterApplied(searchPayload as any);
        if (shouldShowDesignPreferenceSwitch) {
          event.event_properties['ui'] = mapDesignPreference(prefersNewDesign);
        }
        services.track.ampliEventTrack({ event });
      }
    }

    router.push({
      pathname: match.location.pathname,
      query,
    });
  };

  const clearQueryParams = (params: (keyof T)[]) => {
    const query: {[key: string]: null} = {};
    params.forEach(param => query[param as string] = null);

    updateQueryParams(query as T);
  };

  const getQueryParam: <K extends keyof T>(param: K) => T[K] = (param) => {
    return match.location.query[param as string] as T[typeof param];
  };

  const clearQueryParam = (param: keyof T) => clearQueryParams([param]);

  return {
    clearQueryParams,
    clearQueryParam,
    updateQueryParams,
    getQueryParam
  };
};

export default useQueryModification;
