import React from 'react';
import { Box, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { spaces } from '../../../../assets/styles/themes/tokens';

interface OwnProps {
  handleClose: () => void;
}

const ExportDialogTitle: React.FC<OwnProps> = (props) => {
  const { handleClose } = props;
  
  return <DialogTitle sx={{
    paddingTop: spaces.lg,
    paddingBottom: spaces.lg,
    display: 'flex',
    justifyContent: 'space-between',
  }}>
    <Box>
      { props.children }
    </Box>
    <Box>
      <IconButton
        color='default'
        size='small'
        onClick={handleClose}
        data-testid='close-button'
      >
        <CloseIcon />
      </IconButton>
    </Box>
  </DialogTitle>;
};

export default ExportDialogTitle;
