import React, { useCallback, useEffect, useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import TextInput from '../../common/Input/TextInput';
import Radio from '../../common/Radio/Radio';
import PersonalDetailsInputGroup from '../PersonalDetailsInputGroup';
import { selectUser } from '../../../reducers/user.selectors';
import { useSelector } from 'react-redux';
import { updateMe as updateMeAction } from '../../../actions/user.actions';
import useAction from '../../../utils/useAction';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { getDateInGermanFormat, getTimestampFromGermanDate } from '../../../utils/format';
import { Gender } from '@klab-berlin/api-sdk/lib/types/requests/User';
import PersonalDetailsActions from '../Common/PersonalDetailsActions';
import { ascii } from '../../../validators';
interface OwnProps {
  onSave?: () => void;
  onCancel: () => void;
}
interface formFields {
  firstName: string;
  lastName: string;
  gender: Gender;
  birthday: string;
}

const DEFAULT_GENDER: Gender = 'w';

const formValidator = yup.object<formFields>({
  gender: yup.mixed().oneOf(['m', 'f', 'w']).required('Required field. please check the entry'),
  firstName: ascii.required('Required field. please check the entry').max(70, 'Max. 70 characters'),
  lastName: ascii.max(70, 'Max. 70 characters'),
  birthday: yup.string()
    .matches(
      /^((0[1-9]|[1-2][0-9]|3[0-2])\.(0[1-9]|1[0-2])\.(19|20)([0-9][0-9]))?$/,
      'Date should be in format DD.MM.YYYY'
    ),
});

const PersonalDataForm: React.FC<OwnProps> = (props) => {
  const [serverError, setServerError] = useState<Error | null>(null);
  const { errors, handleSubmit, register, reset } = useForm<formFields>({
    mode: 'onBlur', validationSchema: formValidator,
  });
  const { t } = useTranslation();
  const updateMe = useAction(updateMeAction);
  const user = useSelector(selectUser);

  useEffect(() => {
    let birthday = user?.birthday ? getDateInGermanFormat(user.birthday as string) : '';
    if (!birthday) birthday = '';
    
    reset({
      firstName: user?.firstName,
      lastName: user?.lastName,
      gender: user?.gender as Gender || DEFAULT_GENDER,
      birthday
    });
  }, [user]);

  const cancel = () => {
    props.onCancel();
  };

  const onSubmit = handleSubmit(useCallback(async (formData: formFields) => {
    setServerError(null);
    try {
      const birthday = getTimestampFromGermanDate(formData.birthday);
      await updateMe({
        firstName: formData.firstName,
        lastName: formData.lastName,
        birthday: birthday,
        gender: formData.gender,
      });
      props.onSave && props.onSave();
    } catch (error: any) {
      setServerError(error);
    }
  }, []));

  const genderOptions = [
    { label: t('Female'), value: 'f' },
    { label: t('Male'), value: 'm' },
    { label: t('Not specified'), value: 'w' },
  ];

  const getFieldError = (fieldName: keyof formFields) => {
    if (!errors[fieldName]) return;
    const err = errors[fieldName] as FieldError;
    const errMsg = err.message?.toString();
    return t(errMsg ? errMsg : '');
  };

  return (
    <div className='personal-data-form'>
      <form onSubmit={onSubmit}>
        <PersonalDetailsInputGroup fullWidthItems>
          <Radio
            name='gender'
            label={t('Gender')}
            options={genderOptions}
            error={getFieldError('gender')}
            ref={register}
          />
        </PersonalDetailsInputGroup>
        <PersonalDetailsInputGroup>
          <TextInput name='firstName' required
            label={t('First name')}
            error={getFieldError('firstName')}
            ref={register} />
          <TextInput name='lastName'
            label={t('Last name')}
            error={getFieldError('lastName')}
            ref={register} />
        </PersonalDetailsInputGroup>
        <PersonalDetailsInputGroup>
          <TextInput name='birthday'
            label={t('Birthday')} placeholder='TT.MM.JJJJ'
            error={getFieldError('birthday')}
            ref={register} />
        </PersonalDetailsInputGroup>
        <PersonalDetailsActions
          onCancel={cancel}
          showError={!!serverError}
        />
      </form>
    </div>
  );
};

export default PersonalDataForm;
