import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Icon from '../../../../Icon';
import ResetButton from '../ResetButton';
import SaveButton from '../SaveButton';
import './modalBody.scss';

interface ModalBodyProps {
  title: string;
  modalOpen: boolean;
  onModalClose: () => void;
  enableSaveButton: boolean;
  showResetButton: boolean;
  onSave: () => void;
  onReset: () => void;
  hasButtons?: boolean;
}

const ModalBody: React.FC<ModalBodyProps> = (props) => {
  return (
    <Modal
      show={props.modalOpen}
      onHide={props.onModalClose}
      className='modal-body__container'
      data-testid='modal-body'
    >
      <Modal.Header className='pt-3 px-4 border-0 d-flex align-items-center color--grey-dark'>
        <Modal.Title>{props.title}</Modal.Title>
        <Button
          className='btn-none pr-1 pl-1'
          variant='light'
          onClick={props.onModalClose}
        >
          <Icon icon='cancel' size='lg' />
        </Button>
      </Modal.Header>
      <Modal.Body className='py-2 px-4'>
        {props.children}
      </Modal.Body>
      {
        props.hasButtons ?
          <Modal.Footer className='border-0 d-flex justify-content-between px-4 py-4'>
            <SaveButton
              onClick={props.onSave}
              disabled={!props.enableSaveButton}
              size='lg'
            />
            <ResetButton
              onClick={props.onReset}
              hidden={!props.showResetButton}
              size='lg'
            />
          </Modal.Footer> : 
          null
      }
    </Modal>
  );
};

export default ModalBody;
