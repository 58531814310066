import { Product } from '@klab-berlin/api-sdk/lib/types/responses/Product';
import { AsyncAction } from './common.actionTypes';

export enum FETCH_USER_PRODUCT_RECOMMENDATIONS {
  START = 'FETCH_USER_PRODUCT_RECOMMENDATIONS_START',
  SUCCESS = 'FETCH_USER_PRODUCT_RECOMMENDATIONS_SUCCESS',
  ERROR = 'FETCH_USER_PRODUCT_RECOMMENDATIONS_ERROR',
}

export type FetchUserProductRecommendationsAction = AsyncAction<
  typeof FETCH_USER_PRODUCT_RECOMMENDATIONS.START,
  typeof FETCH_USER_PRODUCT_RECOMMENDATIONS.SUCCESS,
  typeof FETCH_USER_PRODUCT_RECOMMENDATIONS.ERROR,
  Product[],
  {
    limit?: number;
  }
>;
