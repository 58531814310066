import { Link } from 'found';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './dashboardCarouselFooter.scss';

interface OwnProps {
  uri: string;
}

const DashboardCarouselFooter: React.FC<OwnProps> = (props) => {
  const { t } = useTranslation();
  return (
    <div
      data-testid='dashboard-carousel-footer__container'
      className='dashboard-carousel-footer__container'
    >
      <Link
        to={props.uri}
        className='dashboard-carousel-footer__link'>
        {t('show more')}
      </Link>
    </div>
  );
};

export default DashboardCarouselFooter;
