import immutable from 'object-path-immutable';
import variables from './variables.scss';
import navigationLayoutMobileVariables
  from '../../../components/AppLayout/NavigationLayout/NavigationLayoutMobile/navigationLayoutMobileVariables.scss';

interface ScssVariables {
  collectionGridGap: number;
  collectionGridItemHeight: number;
  collectionGridItemWidth: number;
  collectionListHeight: number;
  gridBreakPoints: {
    xs: number;
    sm: number;
    md: number;
    lg: number;
    xl: number;
  };
  greyLightest: string;
  greyLight2: string;
  muBlue: string;
  neuVeryLightBlue: string;
  navigationLayoutMobileHeaderHeight: number;
}

export const parseVariables = (variables: { [key: string]: string }): ScssVariables => {
  const obj = immutable({});

  Object.entries<string>(variables)
    .forEach(([key, value]) => {
      const parsedKey = key.replace(/_-/g, '.');
      let parsedValue: string | number;

      if (value.match(/^\d+px$/) || value === '0') {
        parsedValue = parseInt(value, 10);
      } else {
        parsedValue = value;
      }

      // @ts-ignore
      obj.set(parsedKey, parsedValue);
    });
  
  return obj.value() as ScssVariables;
};

const cssVariables = {
  ...variables,
  ...navigationLayoutMobileVariables
};

export default parseVariables(cssVariables);
