import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SearchOptions,
  searchMindItems as searchMindItemsAction,
  searchProducts as searchProductsAction,
  searchUnified as searchUnifiedAction,
  searchTopics as searchTopicsAction,
  searchVideoMindItems as searchVideoMindItemsAction,
} from '../../actions/search.actions';
import TopBar from '../../components/AppLayout/TopBar';
import useAction from '../../utils/useAction';
import useRouter from '../../utils/useRouter';
import { SearchFilters, SearchResultsTab } from '../../types/search';
import querystring from 'query-string';
import Icon from '../../components/Icon';
import SearchResults from '../../components/Search/SearchResults/SearchResults';
import { routes, searchSettings } from '../../constants';
import searchQuerySchema from './searchQuerySchema';
import { getPaginationSelector, selectSearchFilters } from '../../reducers/search.selectors';
import { useSelector } from 'react-redux';
import useQueryModification from './useQueryModification';
import { trackingEvents } from '../../services/tracking/trackConfig';
import { useViewMode } from '../../components/ViewSwitch/ViewModeContext';
import services from '../../services';
import _ from 'lodash';
import { getRecentSearches, setRecentSearches } from '../../utils/recentSearches';
import { selectUser } from '../../reducers/user.selectors';
import { useAmplitudeExperiment } from '../../utils/ampli';
import UpgradeBanner from '../../components/UpgradeBanner';
import config from 'config';
import NewDesignSwitch from '../../components/NewDesignSwitch/NewDesignSwitch';
import { useNewDesign } from '../../state/DesignPreferenceContextProvider';
import { cardDimensions, palette, spaces } from '../../assets/styles/themes/tokens';
import { Box } from '@mui/material';
import { SearchPageContainer, getNewUiSearchPagePadding } from './SearchPageContainer';
import searchResultsStorageManager from '../../utils/searchResultsStorageManager';
import FeedbackDialog from '../../components/modals/FeedbackDialog';
import PageHeader from '../../components/PageHeader/PageHeader';
import { SearchFiltersContainer, SearchFiltersContainerLegacy } from '../../components/Search/SearchFiltersContainer';

const defaultSearchFilters = {
  sortBy: searchSettings.defaultSortBy,
};

export type DisabledSearchFilters = {
  [K in keyof SearchFilters]?: boolean;
};

const LegacyHeader: React.FC<{
  prefersNewDesign: boolean;
  term?: string;
  headerText: string;
  onClick: () => void;
}> = ({ prefersNewDesign, term, headerText, onClick }) => {
  const { t } = useTranslation();
  return <><h1 className='d-none d-md-flex align-items-center font-size-24'>
    {!prefersNewDesign && headerText}
    {!prefersNewDesign && term &&
    <button
      className='btn-none ml-3 focus--highlight-blue'
      onClick={onClick}
      data-testid='query-clear-btn'
    >
      <Icon icon='cancel' />
    </button>
    }
  </h1>
  <h1 className='d-md-none font-size-24'>
    {t('Search results')}
  </h1></>;
};

const SearchPage: React.FC = () => {
  const { t } = useTranslation();
  const { router, match } = useRouter();
  const searchMindItems = useAction(searchMindItemsAction);
  const searchVideoMindItems = useAction(searchVideoMindItemsAction);
  const searchProducts = useAction(searchProductsAction);
  const searchUnified = useAction(searchUnifiedAction);
  const requestFilters = useRef<SearchFilters>(defaultSearchFilters);
  const [disabledFilters, setDisabledFilters] = useState<DisabledSearchFilters>({});
  const searchTopics = useAction(searchTopicsAction);
  const { clearQueryParam } = useQueryModification<SearchFilters>(router, match);
  const { viewMode } = useViewMode();
  const mindItemsPagination = useSelector(getPaginationSelector('mindItems'));
  const productsPagination = useSelector(getPaginationSelector('products'));
  const videoMindItemsPagination = useSelector(getPaginationSelector('videoMindItems'));
  const lastSearchFilters = useSelector(selectSearchFilters);
  const storageSessionSearchFilters = searchResultsStorageManager.getSearchFilters();
  const user = useSelector(selectUser);
  const { shouldShowDesignPreferenceSwitch, prefersNewDesign } = useNewDesign();

  const userId = user && user.id;
  const {
    variant: searchVariant,
    isReady: isSearchAmplitudeReady,
  } = useAmplitudeExperiment(user, config.amplitudeExperiments['search']);
  const isNewSearch = isSearchAmplitudeReady && searchVariant !== 'control';
  // remove when product aggregation experiment is done
  const productAggregationType = isNewSearch ? searchVariant : 'sum';
  const showNewFilters = match.location.query['showNewFilters'] === 'true';

  const getCurrentSearchResultsTab = () => {
    if (Object.values(SearchResultsTab).includes(match.params.tab as SearchResultsTab)) {
      return match.params.tab as SearchResultsTab;
    } else if (user?.isCoreMember) {
      return SearchResultsTab.ALL;
    }
    return SearchResultsTab.MINDITEMS;
  };

  const getPaginationOffset = (currentTab: SearchResultsTab) => {
    if (currentTab === SearchResultsTab.PRODUCTS) {
      return productsPagination.offset;
    } else if (currentTab === SearchResultsTab.MINDITEMS) {
      return mindItemsPagination.offset;
    } else {
      return videoMindItemsPagination.offset;
    }
  };

  const currentTab = getCurrentSearchResultsTab();
  const eventPayload = {
    other: {
      tab: currentTab,
      results: getPaginationOffset(currentTab),
      query: match.location.query,
      view: viewMode,
      term: requestFilters.current.term,
    }
  };

  useEffect(() => {
    const shouldDisableSubTopic = !isNewSearch && (currentTab === SearchResultsTab.PRODUCTS);
    setDisabledFilters({ subTopic: shouldDisableSubTopic });
  }, [currentTab, isNewSearch]);

  const executeMindItemsSearch = (options: SearchOptions) => {
    searchMindItems(requestFilters.current, { ...options, populateProducts: true });
    services.track.eventTrack(trackingEvents.searchExecute, _.merge(eventPayload));
  };

  const executeProductsSearch = (options: SearchOptions) => {
    searchProducts(requestFilters.current, options);
    services.track.eventTrack(trackingEvents.searchExecute, _.merge(eventPayload));
  };

  const executeUnifiedSearch = (options: SearchOptions) => {
    searchUnified(requestFilters.current, options);
    services.track.eventTrack(trackingEvents.searchExecute, _.merge(eventPayload));
  };

  const executeVideoMindItemsSearch = (options: SearchOptions) => {
    searchVideoMindItems(requestFilters.current, { ...options, populateProducts: true });
    services.track.eventTrack(trackingEvents.searchExecute, _.merge(eventPayload));
  };

  const updateRecentSearchSuggestions = (searchQuery: SearchFilters) => {
    if (!searchQuery.term || !userId) return;
    const recentSearches = getRecentSearches(userId);
    const filteredSearches = recentSearches.filter((search) => search.term !== searchQuery.term);
    filteredSearches.unshift(searchQuery);
    filteredSearches.splice(10);
    setRecentSearches(userId, filteredSearches);
  };

  const haveSearchFiltersChanged = (filters?: SearchFilters) => {
    return !_.isEqual(filters, requestFilters.current);
  };

  const triggerAllSearches = (options: SearchOptions) => {
    executeMindItemsSearch(options);
    executeVideoMindItemsSearch(options);
    executeProductsSearch(options);
    if (user?.isCoreMember) {
      executeUnifiedSearch(options);
    }
  };

  useEffect(() => {
    requestFilters.current = queryToSearchFilters();
    const options: SearchOptions = {
      clearPreviousResults: true,
      isNewSearch,
      populateProducts: true,
      productAggregationType,
    };

    if (isSearchAmplitudeReady) {
      if (lastSearchFilters === undefined && storageSessionSearchFilters !== undefined) {
        const filtersQuery = searchFiltersToQuery(storageSessionSearchFilters);
        router.push(`${match.location.pathname}?${filtersQuery}`);
      }

      if (haveSearchFiltersChanged(storageSessionSearchFilters)) {
        searchResultsStorageManager.clearSearchState();
      }

      if (haveSearchFiltersChanged(lastSearchFilters)) {
        window.scrollTo(0, 0);
        
        triggerAllSearches(options);
      }

      if (requestFilters.current.subject) {
        requestFilters.current.subject.forEach(subject => searchTopics(subject));
      } 
      updateRecentSearchSuggestions(match.location.query);
    }
  }, [
    match.location.search,
    isSearchAmplitudeReady,
    isNewSearch,
  ]);

  const queryToSearchFilters = (): SearchFilters => {
    const parsedFilters = querystring.parse(match.location.search, { arrayFormat: 'comma' });

    return searchQuerySchema.cast(parsedFilters);
  };

  const searchFiltersToQuery = (filters: SearchFilters): string => {
    return querystring.stringify(filters, { arrayFormat: 'comma' });
  };

  const changeTab = (key: SearchResultsTab) => {
    router.push({
      ...(match.location),
      pathname: `/${routes.search.root}/${key}`,
    });
  };

  const headerText = requestFilters.current.term
    ? t('Search results for "<searchTerm>"', { searchTerm: requestFilters.current.term })
    : t('Browse all');

  const containerSx = {
    maxWidth: cardDimensions.desktop.maxWidth,
    marginX: 'auto',
    paddingX: getNewUiSearchPagePadding(),
    width: '100%',
  };

  return <Box sx={{ backgroundColor: palette.common.white }} data-testid='search-page'>
    <UpgradeBanner />
    <TopBar />
    <FeedbackDialog />
    <SearchPageContainer showNewFilters={showNewFilters}>
      <Box sx={prefersNewDesign ? containerSx : {}}>
        { shouldShowDesignPreferenceSwitch && <NewDesignSwitchWrapper /> }
        {(showNewFilters && prefersNewDesign) ? 
          <Box>
            <PageHeader sx={{ color: palette.text.primary }} text={'Alles durchsuchen'} />
            <SearchFiltersContainer />
          </Box> :
          <Box>
            <LegacyHeader
              prefersNewDesign={prefersNewDesign}
              term={requestFilters.current.term}
              headerText={headerText}
              onClick={() => clearQueryParam('term')}
            />
            <SearchFiltersContainerLegacy 
              requestFilters={requestFilters.current}
              disabledFilters={disabledFilters}
              trackingPayload={eventPayload}
            />
          </Box>
        }
      </Box>
      {isSearchAmplitudeReady &&
        <SearchResults
          trackingPayload={eventPayload}
          requestFilters={requestFilters.current}
          searchMindItems={() => executeMindItemsSearch({
            clearPreviousResults: false, isNewSearch })}
          searchProducts={() => executeProductsSearch({
            clearPreviousResults: false, isNewSearch })}
          searchVideoMindItems={() => executeVideoMindItemsSearch({
            clearPreviousResults: false, isNewSearch })}
          searchUnified={() => executeUnifiedSearch({
            clearPreviousResults: false, isNewSearch })}
          currentTab={currentTab}
          setCurrentTab={changeTab}
          isNewSearch={isNewSearch}
          searchTerm={requestFilters.current.term}
        />
      }
    </SearchPageContainer>
  </Box>;
};

const NewDesignSwitchWrapper = () => (
  <Box sx={theme => {
    const isMobile = theme.breakpoints.down('mobile');
    const isTablet = theme.breakpoints.between('mobile', 'tablet');
    const isDesktop = theme.breakpoints.up('tablet');
    return {
      position: 'absolute',
      [isMobile]: {
        right: spaces.md,
        top: spaces.xs,
      },
      [isTablet]: {
        right: spaces.lg,
        top: spaces.sm,
      },
      [isDesktop]: {
        right: spaces.xl,
        top: spaces.sm,
      },
    };
  }}>
    <NewDesignSwitch />
  </Box>
);

export default SearchPage;
