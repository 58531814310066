import React, { memo, useMemo, useCallback } from 'react';
import { ContentItem } from '../../types/AppContent';
import Row from 'react-bootstrap/Row';
import AddPinnedItemToCollection from '../AddPinnedItemToCollection';
import MinimalItem from '../GridItem/MinimalItem';
import { ItemVariation } from '@klab-berlin/api-sdk/lib/types/responses/Collection';

interface Props {
  item: ContentItem;
  collectionId: string;
  isInCollection: boolean;
  addItemToCollection: (collection: string, type: ItemVariation, id: string) => void;
  deleteItemFromCollection: (collection: string, type: ItemVariation, id: string) => void;
}

const PinnedContentsContextItem: React.FC<Props> = memo(({ item, ...props }) => {
  const addItem = useCallback(() => {
    props.addItemToCollection(props.collectionId, item.type, item.id);
  }, [props.collectionId]);

  const removeItem = useCallback(() => {
    props.deleteItemFromCollection(props.collectionId, item.type, item.id);
  }, [props.collectionId]);

  return <div onClick={props.isInCollection ? removeItem : addItem}>
    {useMemo(() => <Row
      className='position-relative d-flex justify-content-center my-2 mx-0'
    >
      <AddPinnedItemToCollection
        className='add-pinned-item-to-collections-button d-flex justify-content-center align-items-center'
        itemType={item.type}
        itemId={item.id}
        isInCollection={props.isInCollection}
      />
      <MinimalItem item={item} />
    </Row>, [props.isInCollection])}
  </div>;
});

export default PinnedContentsContextItem;
