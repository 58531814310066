const DOWNLOAD_IFRAME_REMOVAL_TIMEOUT = 15 * 60 * 1000;

const initFileDownload = (url: string) => {
  const iframe = document.createElement('iframe');

  iframe.style.display = 'none';
  document.body.appendChild(iframe);
  iframe.src = url;

  return setTimeout(() => document.body.removeChild(iframe), DOWNLOAD_IFRAME_REMOVAL_TIMEOUT);
};

export default initFileDownload;
