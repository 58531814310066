import React from 'react';
import { useTranslation } from 'react-i18next';
import NewReaderOverlay from '../../../NewReaderOverlay';
import { WatermarkTrackingObject } from '../NewBasisReaderOverlay';
import UnlockPremium from '../UnlockPremium';
import OfferPoints from '../../../../../Upgrade/PackageCards/OfferPoints';
import { ampliConversionPath } from '../../../../../../constants';

interface ExclusiveContentOverlayProps {
  onClose?: () => void;
  category: 'video' | 'read-only' | 'attachment';
  trackingObject?: WatermarkTrackingObject;
}

const infoTextByCategory = {
  attachment: 'As a Premium User you have access to attachments.',
  'read-only': 'As a Premium User you can read this and hundreds of other eBooks without limit',
  video: 'As a Premium User you can stream and share complete videos.',
};

const alignmentByCategory = {
  attachment: 'center',
  'read-only': 'center',
  video: 'baseline',
};

const ExclusiveContentOverlay: React.FC<ExclusiveContentOverlayProps> = ({ category, onClose, trackingObject }) => {
  const { t } = useTranslation();
  const offerPoints = [
    '100 downloads per month',
    'Discover content without watermarks',
    'Every week new content',
    category !== 'read-only' ? 'Limitless reading content online' : '',
    category === 'video' ? 'Audio files' : 'Videos and audio files',
    'Webinars for your further education'
  ].filter(point => point);

  return (
    <NewReaderOverlay
      emoji='🚀' title={t('Exclusive Premium Content')} onClose={onClose} alignment={alignmentByCategory[category]}>
      <NewReaderOverlay.BodyText>
        <p>{t(infoTextByCategory[category])} {t('Additionally, you can expect:')}</p>
        <OfferPoints areAvailable={true} points={offerPoints} />
      </NewReaderOverlay.BodyText>
      <UnlockPremium
        emoji='🚀'
        text={t('Select package')}
        minditem_id={trackingObject?.minditem_id}
        current_page={trackingObject?.current_page}
        onClick={onClose}
        page_count={trackingObject?.page_count}
        conversionPath={ampliConversionPath.PremiumContent}
      />
    </NewReaderOverlay>
  );
};

export default ExclusiveContentOverlay;
