import services from '../services';

let timeout: number;

const hasValidLibraryDomain = async (email : string, library : string): Promise<boolean> => {
  if (!email) return false;

  if (timeout) {
    window.clearTimeout(timeout);
  }

  return new Promise(resolve => {
    timeout = window.setTimeout(async () => {
      const { hasValidLibraryDomain } = await services.user.hasValidLibraryDomain(email, library);
      
      resolve(hasValidLibraryDomain);
    }, 200);
  });
};

export default hasValidLibraryDomain;
