import { AsyncActionStatus, ThunkAction } from './common.actionTypes';
import {
  FetchWorkloadsAction,
  FETCH_WORKLOADS,
  FetchTeachingFormatsAction,
  FETCH_TEACHING_FORMATS,
  SearchMindItemsAction,
  SearchProductsAction,
  SearchVideoMindItemsAction,
  SearchTopicsAction,
  SearchUnifiedAction,
  SEARCH_MIND_ITEMS,
  SEARCH_VIDEO_MIND_ITEMS,
  SEARCH_PRODUCTS,
  SEARCH_UNIFIED,
  SEARCH_TOPICS,
} from './search.actionTypes';
import services from '../services';
import { hasNext } from '../utils/pagination';
import { SearchFilters, Pagination } from '../types/search';
import _ from 'lodash';
import { StoreType } from '../reducers';
import {
  isMindItemPageLoading,
  isProductPageLoading,
  isUnifiedPageLoading,
  isVideoMindItemPageLoading
} from '../reducers/search.selectors';
import { SearchRequest } from '@klab-berlin/api-sdk/lib/types/requests/Common';
import { defaultSearchFilters } from '../constants';
import { isSchoolSubdomain } from '../utils/subdomain';
import config from 'config';

export interface SearchOptions {
  clearPreviousResults?: boolean;
  isNewSearch?: boolean;
  excludePublishers?: boolean;
  populateProducts?: boolean;
  productAggregationType?: string;
  scoreAggregationMethod?: string;
  pagination?: Pagination;
  recencyModifier?: number;
}

export function searchMindItems(
  filters: SearchFilters,
  searchOptions: SearchOptions = { clearPreviousResults: false },
): ThunkAction<SearchMindItemsAction> {
  return async (dispatch, getState) => {
    let request: SearchRequest;
    let payload: { filters: SearchFilters; pagination: Pagination };
    const pagination = searchOptions.pagination || getState().search.mindItems.pagination;

    if (isMindItemPageLoading(getState())) { return; }

    if (shouldFetchNextResults(filters, getState(), searchOptions)) {
      if (!hasNext(getState().search.mindItems.pagination)) { return; }

      request = {
        ...getState().search.searchFilters,
        offset: pagination.offset,
        limit: pagination.limit,
        isNewSearch: searchOptions.isNewSearch || filters.boost !== undefined,
        excludePublishers: searchOptions.excludePublishers,
        recencyModifier: searchOptions.recencyModifier,
        scoreAggregationMethod: searchOptions.scoreAggregationMethod,
        populateProducts: searchOptions.populateProducts,
      };
      payload = {
        filters: getState().search.searchFilters || defaultSearchFilters,
        pagination
      };
    }
    else {
      pagination.offset = 0;
      request = {
        ...filters,
        offset: pagination.offset,
        limit: pagination.limit,
        isNewSearch: searchOptions.isNewSearch || filters.boost !== undefined,
        excludePublishers: searchOptions.excludePublishers,
        recencyModifier: searchOptions.recencyModifier,
        scoreAggregationMethod: searchOptions.scoreAggregationMethod,
        populateProducts: searchOptions.populateProducts,
      };
      payload = { filters, pagination };
    }

    dispatch({ type: SEARCH_MIND_ITEMS.START, payload });

    // Don't fetch video mind items if it's on the school subdomain
    if (isSchoolSubdomain(window.location.hostname)) {
      request.contentType = 'document';
    }

    services.mindItem
      .search(request)
      .then((result) => {
        dispatch({ type: SEARCH_MIND_ITEMS.SUCCESS, result, payload });
      })
      .catch((error) => {
        dispatch({ error, type: SEARCH_MIND_ITEMS.ERROR, payload });
      });
  };
}

export function searchVideoMindItems(
  filters: SearchFilters,
  searchOptions: SearchOptions = { clearPreviousResults: false },
): ThunkAction<SearchVideoMindItemsAction> {
  return async (dispatch, getState) => {
    let request: SearchRequest;
    let payload: { filters: SearchFilters; pagination: Pagination };
    const pagination = searchOptions.pagination || getState().search.videoMindItems.pagination;

    if (isVideoMindItemPageLoading(getState())) { return; }

    if (shouldFetchNextResults(filters, getState(), searchOptions)) {
      if (!hasNext(getState().search.videoMindItems.pagination)) { return; }

      request = {
        ...getState().search.searchFilters,
        offset: pagination.offset,
        limit: pagination.limit,
        isNewSearch: searchOptions.isNewSearch || filters.boost !== undefined,
        excludePublishers: searchOptions.excludePublishers,
        recencyModifier: searchOptions.recencyModifier,
        scoreAggregationMethod: searchOptions.scoreAggregationMethod,
        populateProducts: searchOptions.populateProducts,
      };
      payload = {
        filters: getState().search.searchFilters || defaultSearchFilters,
        pagination
      };
    }
    else {
      pagination.offset = 0;
      request = {
        ...filters,
        offset: pagination.offset,
        limit: pagination.limit,
        isNewSearch: searchOptions.isNewSearch || filters.boost !== undefined,
        excludePublishers: searchOptions.excludePublishers,
        recencyModifier: searchOptions.recencyModifier,
        scoreAggregationMethod: searchOptions.scoreAggregationMethod,
        populateProducts: searchOptions.populateProducts,
      };
      payload = { filters, pagination };
    }

    dispatch({ type: SEARCH_VIDEO_MIND_ITEMS.START, payload });

    // Fetch only video mind items
    request.contentType = 'video';

    // If the user is on the school subdomain, only fetch videos from non-vimeo-publishers
    let shouldSendRequest = true;
    if (isSchoolSubdomain(window.location.hostname)) {
      if (request.publisher && request.publisher.length > 0) {
        const validSelectedPublishers = request.publisher
          .filter(publisher => config.schoolsApp.nonVimeoVideoPublishers.includes(publisher));
        if (validSelectedPublishers.length > 0) {
          request.publisher = validSelectedPublishers;
        } else {
          shouldSendRequest = false;
        }
      } else {
        request.publisher = config.schoolsApp.nonVimeoVideoPublishers;
      }
    }

    if (shouldSendRequest) {
      services.mindItem
        .search(request)
        .then((result) => {
          dispatch({ type: SEARCH_VIDEO_MIND_ITEMS.SUCCESS, result, payload });
        })
        .catch((error) => {
          dispatch({ error, type: SEARCH_VIDEO_MIND_ITEMS.ERROR, payload });
        });
    } else {
      const emptySearchResult = {
        data: [],
        meta: {
          pagination: {
            total: 0
          }
        }
      };
      dispatch({ type: SEARCH_VIDEO_MIND_ITEMS.SUCCESS, result: emptySearchResult, payload });
    }
  };
}

export function searchProducts(
  filters: SearchFilters,
  searchOptions: SearchOptions = { clearPreviousResults: false },
): ThunkAction<SearchProductsAction> {
  return async (dispatch, getState) => {
    let request: SearchRequest;
    let payload: { filters: SearchFilters; pagination: Pagination };
    const pagination = searchOptions.pagination || getState().search.products.pagination;

    if (isProductPageLoading(getState())) { return; }

    if (shouldFetchNextResults(filters, getState(), searchOptions)) {
      if (!hasNext(getState().search.products.pagination)) {
        return;
      }
      request = {
        ...getState().search.searchFilters,
        offset: pagination.offset,
        limit: pagination.limit,
        isNewSearch: searchOptions.isNewSearch || filters.boost !== undefined,
        excludePublishers: searchOptions.excludePublishers,
        productAggregationType: searchOptions.productAggregationType,
        recencyModifier: searchOptions.recencyModifier,
        scoreAggregationMethod: searchOptions.scoreAggregationMethod,
      };
      payload = {
        filters: getState().search.searchFilters || defaultSearchFilters,
        pagination
      };
    }
    else {
      pagination.offset = 0;
      request = {
        ...filters,
        offset: pagination.offset,
        limit: pagination.limit,
        isNewSearch: searchOptions.isNewSearch || filters.boost !== undefined,
        excludePublishers: searchOptions.excludePublishers,
        productAggregationType: searchOptions.productAggregationType,
        recencyModifier: searchOptions.recencyModifier,
        scoreAggregationMethod: searchOptions.scoreAggregationMethod,
      };
      payload = { filters, pagination };
    }

    dispatch({ type: SEARCH_PRODUCTS.START, payload });

    services.product
      .search(request)
      .then((result) => {
        dispatch({ type: SEARCH_PRODUCTS.SUCCESS, result, payload });
      })
      .catch((error) => {
        dispatch({ error, type: SEARCH_PRODUCTS.ERROR, payload });
      });
  };
}

export function searchUnified(
  filters: SearchFilters,
  searchOptions: SearchOptions = { clearPreviousResults: false },
): ThunkAction<SearchUnifiedAction> {
  return async (dispatch, getState) => {
    let request: SearchRequest;
    let payload: { filters: SearchFilters; pagination: Pagination };
    const pagination = searchOptions.pagination || getState().search.unified.pagination;

    if (isUnifiedPageLoading(getState())) { return; }

    if (shouldFetchNextResults(filters, getState(), searchOptions)) {
      if (!hasNext(getState().search.unified.pagination)) {
        return;
      }
      request = {
        ...getState().search.searchFilters,
        offset: pagination.offset,
        limit: pagination.limit,
        isNewSearch: searchOptions.isNewSearch || filters.boost !== undefined,
        excludePublishers: searchOptions.excludePublishers,
        recencyModifier: searchOptions.recencyModifier,
        productAggregationType: searchOptions.productAggregationType,
        scoreAggregationMethod: searchOptions.scoreAggregationMethod,
      };
      payload = {
        filters: getState().search.searchFilters || defaultSearchFilters,
        pagination: pagination
      };
    }
    else {
      pagination.offset = 0;
      request = {
        ...filters,
        offset: pagination.offset,
        limit: pagination.limit,
        isNewSearch: searchOptions.isNewSearch || filters.boost !== undefined,
        excludePublishers: searchOptions.excludePublishers,
        recencyModifier: searchOptions.recencyModifier,
        productAggregationType: searchOptions.productAggregationType,
        scoreAggregationMethod: searchOptions.scoreAggregationMethod,
      };
      payload = { filters, pagination };
    }

    dispatch({ type: SEARCH_UNIFIED.START, payload });

    services.search
      .unifiedSearch(request)
      .then((result) => {
        dispatch({ type: SEARCH_UNIFIED.SUCCESS, result, payload });
      })
      .catch((error) => {
        dispatch({ error, type: SEARCH_UNIFIED.ERROR, payload });
      });
  };
}

export function searchTopics(
  subject?: string,
): ThunkAction<SearchTopicsAction> {
  return async (dispatch, getState) => {
    if (!subject) {
      return;
    }
    const payload = { subject };
    if (getState().search.subTopics.terms[subject]) {
      return;
    }
    dispatch({ type: SEARCH_TOPICS.START, payload });
    services.mindItem
      .getTopics(subject)
      .then((result) => {
        dispatch({ type: SEARCH_TOPICS.SUCCESS, result, payload });
      })
      .catch((error) => {
        dispatch({ error, type: SEARCH_TOPICS.ERROR, payload });
      });
  };
}

export function fetchWorkloads(): ThunkAction<FetchWorkloadsAction> {
  return async (dispatch, getState) => {
    if (getState().search.workloads.status === AsyncActionStatus.READY) {
      return;
    }

    dispatch({ type: FETCH_WORKLOADS.START });

    return services.text.fetchWorkloads()
      .then(result => dispatch({ type: FETCH_WORKLOADS.SUCCESS, result }))
      .catch(error => dispatch({ type: FETCH_WORKLOADS.ERROR, error }));
  };
}

export function fetchTeachingFormats(): ThunkAction<FetchTeachingFormatsAction> {
  return async (dispatch, getState) => {
    if (getState().search.teachingFormats.status === AsyncActionStatus.READY) {
      return;
    }

    dispatch({ type: FETCH_TEACHING_FORMATS.START });

    return services.text.fetchTeachingFormats()
      .then(result => dispatch({ type: FETCH_TEACHING_FORMATS.SUCCESS, result }))
      .catch(error => dispatch({ type: FETCH_TEACHING_FORMATS.ERROR, error }));
  };
}

const shouldFetchNextResults = (filters: SearchFilters, store: StoreType, options: SearchOptions) => {
  return _.isEqual(filters, store.search.searchFilters) && !options.clearPreviousResults;
};
