/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull browser'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 37
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest)
 *
 * [Full Setup Instructions](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/implementation/browser)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'production' | 'development';

export const ApiKey: Record<Environment, string> = {
  production: '422237ff0cd44cdbae18c2b3230951e7',
  development: 'fa5c9de8e2b50299fecde4f839003acf'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '37',
    branch: 'main',
    source: 'browser',
    versionId: '1ac36f71-b874-4fb8-9f15-1a857659a4a3'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  },
  serverZone: amplitude.Types.ServerZone.EU
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * The class year(s) that the user teaches
   */
  "class year"?: string;
  /**
   * The subject that the user ranked highest in the subject prioritization
   */
  "first subject"?: string;
  /**
   * The license id includes information about subscription type (teacher, ref, school), price, interval (6mo, 12mo, 24mo).
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "license id"?: number;
  /**
   * The type of school at which the user teaches
   */
  "school type"?: string;
  /**
   * When the user signed up
   */
  "signup date"?: string;
  /**
   * Whether the user signed up using Email or Facebook
   */
  "signup method"?: string;
  /**
   * The relevant subject(s) that the user selected
   */
  subject?: string;
  /**
   * The teacher type (Ref, Regular)
   */
  "teacher type"?: string;
  /**
   * Whether the user is a free user or has paid for a subscription
   */
  "user type"?: string;
}

export interface AnnotationToolUsedProperties {
  /**
   * The amount of annotations and updates to annoations a user did before saving
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "amount of updates": number;
  /**
   * Object array that contains all content event properties
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  content: string[];
  /**
   * The unique id of the lernset
   */
  "lernset id": string;
  /**
   * The title of the lernset
   */
  "lernset title": string;
}

export interface CollectionContentAddedProperties {
  /**
   * Whether the content was used to create a new collection or whether it was added to an existing collection.
   *
   * Sample values: new collection, existing collection
   */
  "collection preexistence": string;
  /**
   * Object array that contains all content event properties
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  content: string[];
  /**
   * The UI the user has chosen to use
   */
  ui: string;
}

export interface CollectionContentRemovedProperties {
  /**
   * Object array that contains all content event properties
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  content: string[];
}

export interface CollectionContentSelectedProperties {
  /**
   * Object array that contains all content event properties
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  content: string[];
}

export interface CollectionCreatedProperties {
  /**
   * The description of the collection
   *
   * Sample values: This is a collection about environment protection.
   */
  "collection description"?: string;
  /**
   * The unique id of the collection
   */
  "collection id": string;
  /**
   * The title of the collection
   *
   * Sample values: Environment, Mindmaps, Bäume...
   */
  "collection name": string;
  /**
   * The type of school addressed by the collection
   *
   * Sample values: Berufliche Schule, Förderschule / Inklusion, Gesamtschule...
   */
  "collection school type"?: string;
  /**
   * The subject(s) covered by the collection
   *
   * Sample values: Deutsch, Mathematik, Geschichte...
   */
  "collection subjects"?: string;
}

export interface CollectionDeletedProperties {
  /**
   * The description of the collection
   *
   * Sample values: This is a collection about environment protection.
   */
  "collection description"?: string;
  /**
   * The unique id of the collection
   */
  "collection id": string;
  /**
   * The title of the collection
   *
   * Sample values: Environment, Mindmaps, Bäume...
   */
  "collection name": string;
  /**
   * The type of school addressed by the collection
   *
   * Sample values: Berufliche Schule, Förderschule / Inklusion, Gesamtschule...
   */
  "collection school type"?: string;
  /**
   * The subject(s) covered by the collection
   *
   * Sample values: Deutsch, Mathematik, Geschichte...
   */
  "collection subjects"?: string;
}

export interface CollectionEditedProperties {
  /**
   * The description of the collection
   *
   * Sample values: This is a collection about environment protection.
   */
  "collection description"?: string;
  /**
   * The unique id of the collection
   */
  "collection id": string;
  /**
   * The title of the collection
   *
   * Sample values: Environment, Mindmaps, Bäume...
   */
  "collection name": string;
  /**
   * The type of school addressed by the collection
   *
   * Sample values: Berufliche Schule, Förderschule / Inklusion, Gesamtschule...
   */
  "collection school type"?: string;
  /**
   * The subject(s) covered by the collection
   *
   * Sample values: Deutsch, Mathematik, Geschichte...
   */
  "collection subjects"?: string;
}

export interface CollectionFollowedProperties {
  /**
   * The description of the collection
   *
   * Sample values: This is a collection about environment protection.
   */
  "collection description"?: string;
  /**
   * The unique id of the collection
   */
  "collection id": string;
  /**
   * The title of the collection
   *
   * Sample values: Environment, Mindmaps, Bäume...
   */
  "collection name": string;
  /**
   * The type of school addressed by the collection
   *
   * Sample values: Berufliche Schule, Förderschule / Inklusion, Gesamtschule...
   */
  "collection school type"?: string;
  /**
   * The subject(s) covered by the collection
   *
   * Sample values: Deutsch, Mathematik, Geschichte...
   */
  "collection subjects"?: string;
}

export interface CollectionUnfollowedProperties {
  /**
   * The description of the collection
   *
   * Sample values: This is a collection about environment protection.
   */
  "collection description"?: string;
  /**
   * The unique id of the collection
   */
  "collection id": string;
  /**
   * The title of the collection
   *
   * Sample values: Environment, Mindmaps, Bäume...
   */
  "collection name": string;
  /**
   * The type of school addressed by the collection
   *
   * Sample values: Berufliche Schule, Förderschule / Inklusion, Gesamtschule...
   */
  "collection school type"?: string;
  /**
   * The subject(s) covered by the collection
   *
   * Sample values: Deutsch, Mathematik, Geschichte...
   */
  "collection subjects"?: string;
}

export interface CollectionViewedProperties {
  /**
   * The description of the collection
   *
   * Sample values: This is a collection about environment protection.
   */
  "collection description"?: string;
  /**
   * The unique id of the collection
   */
  "collection id": string;
  /**
   * The title of the collection
   *
   * Sample values: Environment, Mindmaps, Bäume...
   */
  "collection name": string;
  /**
   * The type of school addressed by the collection
   *
   * Sample values: Berufliche Schule, Förderschule / Inklusion, Gesamtschule...
   */
  "collection school type"?: string;
  /**
   * The subject(s) covered by the collection
   *
   * Sample values: Deutsch, Mathematik, Geschichte...
   */
  "collection subjects"?: string;
  /**
   * The collection type of the collection. Sample values: private, curated
   */
  "collection type": string;
}

export interface ContentDownloadedProperties {
  /**
   * Object array that contains all content event properties
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  content: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "search filter": string[];
  /**
   * The UI the user has chosen to use
   */
  ui: string;
}

export interface ContentFavoriteAddedProperties {
  /**
   * Object array that contains all content event properties
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  content: string[];
  /**
   * The UI the user has chosen to use
   */
  ui: string;
}

export interface ContentFavoriteRemovedProperties {
  /**
   * Object array that contains all content event properties
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  content: string[];
}

export interface ContentPagesSelectedProperties {
  /**
   * Object array that contains all content event properties
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  content: string[];
  /**
   * Amount of pages that were selected to export a set.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "page amount": number;
}

export interface ContentSharedProperties {
  /**
   * Object array that contains all content event properties
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  content: string[];
}

export interface ContentViewedProperties {
  /**
   * Object array that contains all content event properties
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  content: string[];
  /**
   * The page source where the event was triggered since the event may be triggered on different pages.
   *
   * Sample values: first screen, second screen, third screen
   */
  "page source": string;
  /**
   * At which position was the document in the search result page ranked
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  position: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "search filter": string[];
  /**
   * The UI the user has chosen to use
   */
  ui: string;
}

export interface EditorOpenedProperties {
  /**
   * Object array that contains all content event properties
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  content: string[];
  /**
   * The unique id of the lernset
   */
  "lernset id": string;
  /**
   * The title of the lernset
   */
  "lernset title": string;
}

export interface ExportButtonClickedProperties {
  /**
   * Object array that contains all content event properties
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  content: string[];
}

export interface LernsetsContentAddedProperties {
  /**
   * Object array that contains all content event properties
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  content: string[];
  /**
   * The unique id of the lernset
   */
  "lernset id": string;
  /**
   * The title of the lernset
   */
  "lernset title": string;
  /**
   * Amount of pages that were selected to export a set.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "page amount": number;
}

export interface PageReadProperties {
  /**
   * Object array that contains all content event properties
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  content: string[];
  /**
   * The page number of the page being read in the document. It’s required to know what pages were read in the document in order to decide when a document is truly read.
   *  Sample values: 1, 2, 3...
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "page number"?: number;
  /**
   * The UI the user has chosen to use
   */
  ui: string;
}

export interface PremiumPlanSelectedProperties {
  /**
   * The license id includes information about subscription type (teacher, ref, school), price, interval (6mo, 12mo, 24mo). Sample values: 41, 132
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "license id": number;
  /**
   * The subscription interval Sample values: 6mo, 12mo, 24mo
   */
  "subscription interval": string;
  /**
   * Whether the user has signed up for a teacher, ref, or school subscription
   *  Sample values: teacher, ref, school
   */
  "subscription type": string;
}

export interface PricingPlansViewedProperties {
  /**
   * From which previous screen did the user arrive at the "Pricing" screen
   *  Sample values: navigation bar, my account, premium info popup, document overlay, content download, premium content
   */
  "conversion path": string;
}

export interface SearchCompletedProperties {
  /**
   * Number of minditem results returned for the search term
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "nr of minditem search results"?: number;
  /**
   * Number of product results provided for the search term
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "nr of product search results"?: number;
  /**
   * Number of video results provided for the search term
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "nr of video search results"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "search filter": string[];
  /**
   * The tab in which the search happened.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | document, product, video |
   */
  "search results tab": "document" | "product" | "video";
  /**
   * The search term submitted by the user.
   */
  "search term"?: string;
  /**
   * The UI the user has chosen to use
   */
  ui: string;
}

export interface SearchFilterAppliedProperties {
  /**
   * Number of minditem results returned for the search term
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "nr of minditem search results"?: number;
  /**
   * Number of product results provided for the search term
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "nr of product search results"?: number;
  /**
   * Number of video results provided for the search term
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "nr of video search results"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "search filter": string[];
  /**
   * By which criteria the search is sorted by.
   *
   *
   * Example values:
   *
   *
   *  *Relevanz*, *Kürzlich* *hinzugefügt*, *Erscheinungsdatum*, *Beliebtheit*
   */
  "search sorted by"?: string;
  /**
   * The search term submitted by the user.
   */
  "search term"?: string;
  /**
   * The UI the user has chosen to use
   */
  ui: string;
}

export interface SearchSubmittedProperties {
  /**
   * Number of minditem results returned for the search term
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "nr of minditem search results"?: number;
  /**
   * Number of product results provided for the search term
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "nr of product search results"?: number;
  /**
   * Number of video results provided for the search term
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "nr of video search results"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "search filter": string[];
  /**
   * By which criteria the search is sorted by.
   *
   *
   * Example values:
   *
   *
   *  *Relevanz*, *Kürzlich* *hinzugefügt*, *Erscheinungsdatum*, *Beliebtheit*
   */
  "search sorted by"?: string;
  /**
   * The search term submitted by the user.
   */
  "search term"?: string;
  /**
   * The UI the user has chosen to use
   */
  ui: string;
}

export interface SetCardDeletedProperties {
  /**
   * The unique id of the lernset
   */
  "lernset id": string;
  /**
   * The title of the lernset
   */
  "lernset title": string;
}

export interface SetCardDuplicatedProperties {
  /**
   * The unique id of the lernset
   */
  "lernset id": string;
  /**
   * The title of the lernset
   */
  "lernset title": string;
}

export interface SetCardMovedProperties {
  /**
   * The unique id of the lernset
   */
  "lernset id": string;
  /**
   * The title of the lernset
   */
  "lernset title": string;
}

export interface SetCreatedProperties {
  /**
   * The unique id of the lernset
   */
  "lernset id": string;
  /**
   * The title of the lernset
   */
  "lernset title": string;
  /**
   * The page source where the event was triggered since the event may be triggered on different pages.
   *
   * Sample values: first screen, second screen, third screen
   */
  "page source": string;
}

export interface SetDownloadedProperties {
  /**
   * The unique id of the lernset
   */
  "lernset id": string;
  /**
   * The title of the lernset
   */
  "lernset title": string;
  /**
   * The page source where the event was triggered since the event may be triggered on different pages.
   *
   * Sample values: first screen, second screen, third screen
   */
  "page source": string;
}

export interface SetOpenedProperties {
  /**
   * The unique id of the lernset
   */
  "lernset id": string;
  /**
   * The title of the lernset
   */
  "lernset title": string;
}

export interface SetPrintedProperties {
  /**
   * The unique id of the lernset
   */
  "lernset id": string;
  /**
   * The title of the lernset
   */
  "lernset title": string;
  /**
   * The page source where the event was triggered since the event may be triggered on different pages.
   *
   * Sample values: first screen, second screen, third screen
   */
  "page source": string;
}

export interface SetSharedProperties {
  /**
   * The unique id of the lernset
   */
  "lernset id": string;
  /**
   * The title of the lernset
   */
  "lernset title": string;
  /**
   * Qr code downlod or link copying to share a link with the public.
   */
  "sharing method": string;
}

export interface SharingModalOpenedProperties {
  /**
   * The unique id of the lernset
   */
  "lernset id": string;
  /**
   * The title of the lernset
   */
  "lernset title": string;
}

export interface SubscriptionCancellationSelectedProperties {
  /**
   * The page source where the event was triggered since the event may be triggered on different pages.
   *
   * Sample values: first screen, second screen, third screen
   */
  "page source": string;
  /**
   * Whether the subscription is on trial.
   *  Sample values: yes, no
   */
  trial: string;
}

export interface SubscriptionCancellationViewedProperties {
  /**
   * Whether the subscription is on trial.
   *  Sample values: yes, no
   */
  trial: string;
}

export interface SubscriptionPauseSelectedProperties {
  /**
   * The page source where the event was triggered since the event may be triggered on different pages.
   *
   * Sample values: first screen, second screen, third screen
   */
  "page source": string;
}

export interface SubscriptionPauseSubmittedProperties {
  /**
   * The duration of the pause from the subscription. After that duration, the subscription continues.
   *
   * Sample values: 1mo, 2mo, 3mo
   */
  "pause duration": string;
}

export interface SubscriptionSubmittedProperties {
  /**
   * The billing cycle of the subscription
   *  Sample values: monthly, quarterly, total
   */
  "billing cycle"?: string;
  /**
   * The license id includes information about subscription type (teacher, ref, school), price, interval (6mo, 12mo, 24mo). Sample values: 41, 132
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "license id": number;
  /**
   * The payment method used for the subscription
   *   Sample values: SEPA, credit card, Paypal
   */
  "payment method": string;
  /**
   * The promotion/discount code a user used Sample value: schulstart23
   */
  "promo code": string;
  /**
   * The subscription interval Sample values: 6mo, 12mo, 24mo
   */
  "subscription interval": string;
  /**
   * Whether the user has signed up for a teacher, ref, or school subscription
   *  Sample values: teacher, ref, school
   */
  "subscription type": string;
}

export interface VideoUnlockedProperties {
  /**
   * Object array that contains all content event properties
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  content: string[];
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AdditionalFiltersDisplayed implements BaseEvent {
  event_type = 'Additional Filters Displayed';
}

export class AnnotationToolUsed implements BaseEvent {
  event_type = 'Annotation Tool Used';

  constructor(
    public event_properties: AnnotationToolUsedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClassesSelected implements BaseEvent {
  event_type = 'Classes Selected';
}

export class CollectionContentAdded implements BaseEvent {
  event_type = 'Collection Content Added';

  constructor(
    public event_properties: CollectionContentAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CollectionContentRemoved implements BaseEvent {
  event_type = 'Collection Content Removed';

  constructor(
    public event_properties: CollectionContentRemovedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CollectionContentSelected implements BaseEvent {
  event_type = 'Collection Content Selected';

  constructor(
    public event_properties: CollectionContentSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CollectionCreated implements BaseEvent {
  event_type = 'Collection Created';

  constructor(
    public event_properties: CollectionCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CollectionDeleted implements BaseEvent {
  event_type = 'Collection Deleted';

  constructor(
    public event_properties: CollectionDeletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CollectionEdited implements BaseEvent {
  event_type = 'Collection Edited';

  constructor(
    public event_properties: CollectionEditedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CollectionFollowed implements BaseEvent {
  event_type = 'Collection Followed';

  constructor(
    public event_properties: CollectionFollowedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CollectionUnfollowed implements BaseEvent {
  event_type = 'Collection Unfollowed';

  constructor(
    public event_properties: CollectionUnfollowedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CollectionViewed implements BaseEvent {
  event_type = 'Collection Viewed';

  constructor(
    public event_properties: CollectionViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ContentDownloaded implements BaseEvent {
  event_type = 'Content Downloaded';

  constructor(
    public event_properties: ContentDownloadedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ContentFavoriteAdded implements BaseEvent {
  event_type = 'Content Favorite Added';

  constructor(
    public event_properties: ContentFavoriteAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ContentFavoriteRemoved implements BaseEvent {
  event_type = 'Content Favorite Removed';

  constructor(
    public event_properties: ContentFavoriteRemovedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ContentPagesSelected implements BaseEvent {
  event_type = 'Content Pages Selected';

  constructor(
    public event_properties: ContentPagesSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ContentShared implements BaseEvent {
  event_type = 'Content Shared';

  constructor(
    public event_properties: ContentSharedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ContentViewed implements BaseEvent {
  event_type = 'Content Viewed';

  constructor(
    public event_properties: ContentViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EditorOpened implements BaseEvent {
  event_type = 'Editor Opened';

  constructor(
    public event_properties: EditorOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExportButtonClicked implements BaseEvent {
  event_type = 'Export Button Clicked';

  constructor(
    public event_properties: ExportButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LernsetsContentAdded implements BaseEvent {
  event_type = 'Lernsets Content Added';

  constructor(
    public event_properties: LernsetsContentAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NameAndPasswordSubmitted implements BaseEvent {
  event_type = 'Name and Password Submitted';
}

export class PageRead implements BaseEvent {
  event_type = 'Page Read';

  constructor(
    public event_properties: PageReadProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PremiumPlanSelected implements BaseEvent {
  event_type = 'Premium Plan Selected';

  constructor(
    public event_properties: PremiumPlanSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PricingPlansViewed implements BaseEvent {
  event_type = 'Pricing Plans Viewed';

  constructor(
    public event_properties: PricingPlansViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProfileCreationStarted implements BaseEvent {
  event_type = 'Profile Creation Started';
}

export class SchoolSelected implements BaseEvent {
  event_type = 'School Selected';
}

export class SearchCompleted implements BaseEvent {
  event_type = 'Search Completed';

  constructor(
    public event_properties: SearchCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchFilterApplied implements BaseEvent {
  event_type = 'Search Filter Applied';

  constructor(
    public event_properties: SearchFilterAppliedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchSubmitted implements BaseEvent {
  event_type = 'Search Submitted';

  constructor(
    public event_properties: SearchSubmittedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SetCardDeleted implements BaseEvent {
  event_type = 'Set Card Deleted';

  constructor(
    public event_properties: SetCardDeletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SetCardDuplicated implements BaseEvent {
  event_type = 'Set Card Duplicated';

  constructor(
    public event_properties: SetCardDuplicatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SetCardMoved implements BaseEvent {
  event_type = 'Set Card Moved';

  constructor(
    public event_properties: SetCardMovedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SetCreated implements BaseEvent {
  event_type = 'Set Created';

  constructor(
    public event_properties: SetCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SetDownloaded implements BaseEvent {
  event_type = 'Set Downloaded';

  constructor(
    public event_properties: SetDownloadedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SetOpened implements BaseEvent {
  event_type = 'Set Opened';

  constructor(
    public event_properties: SetOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SetPrinted implements BaseEvent {
  event_type = 'Set Printed';

  constructor(
    public event_properties: SetPrintedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SetShared implements BaseEvent {
  event_type = 'Set Shared';

  constructor(
    public event_properties: SetSharedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SharingModalOpened implements BaseEvent {
  event_type = 'Sharing Modal Opened';

  constructor(
    public event_properties: SharingModalOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubjectsClassified implements BaseEvent {
  event_type = 'Subjects Classified';
}

export class SubjectsSelected implements BaseEvent {
  event_type = 'Subjects Selected';
}

export class SubscriptionCancellationSelected implements BaseEvent {
  event_type = 'Subscription Cancellation Selected';

  constructor(
    public event_properties: SubscriptionCancellationSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubscriptionCancellationViewed implements BaseEvent {
  event_type = 'Subscription Cancellation Viewed';

  constructor(
    public event_properties: SubscriptionCancellationViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubscriptionPauseSelected implements BaseEvent {
  event_type = 'Subscription Pause Selected';

  constructor(
    public event_properties: SubscriptionPauseSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubscriptionPauseSubmitted implements BaseEvent {
  event_type = 'Subscription Pause Submitted';

  constructor(
    public event_properties: SubscriptionPauseSubmittedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubscriptionSubmitted implements BaseEvent {
  event_type = 'Subscription Submitted';

  constructor(
    public event_properties: SubscriptionSubmittedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VideoUnlocked implements BaseEvent {
  event_type = 'Video Unlocked';

  constructor(
    public event_properties: VideoUnlockedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Additional Filters Displayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Additional%20Filters%20Displayed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  additionalFiltersDisplayed(
    options?: EventOptions,
  ) {
    return this.track(new AdditionalFiltersDisplayed(), options);
  }

  /**
   * Annotation Tool Used
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Annotation%20Tool%20Used)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. amount of updates)
   * @param options Amplitude event options.
   */
  annotationToolUsed(
    properties: AnnotationToolUsedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AnnotationToolUsed(properties), options);
  }

  /**
   * Classes Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Classes%20Selected)
   *
   * When the user has selected the classes he teaches and provided information on whether he is a ref teacher or not
   *
   * Owner: Thomas Siegl
   *
   * @param options Amplitude event options.
   */
  classesSelected(
    options?: EventOptions,
  ) {
    return this.track(new ClassesSelected(), options);
  }

  /**
   * Collection Content Added
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Collection%20Content%20Added)
   *
   * When a user has successfully added content to a collection.
   *
   * Owner: Thomas Siegl
   *
   * @param properties The event's properties (e.g. collection preexistence)
   * @param options Amplitude event options.
   */
  collectionContentAdded(
    properties: CollectionContentAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CollectionContentAdded(properties), options);
  }

  /**
   * Collection Content Removed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Collection%20Content%20Removed)
   *
   * When a user has removed content from a collection.
   *
   * Owner: Thomas Siegl
   *
   * @param properties The event's properties (e.g. content)
   * @param options Amplitude event options.
   */
  collectionContentRemoved(
    properties: CollectionContentRemovedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CollectionContentRemoved(properties), options);
  }

  /**
   * Collection Content Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Collection%20Content%20Selected)
   *
   * When a user taps on a button to add content to a collection. The content has not been added to a specific collection yet at this point, the user may still cancel.
   *
   * Owner: Thomas Siegl
   *
   * @param properties The event's properties (e.g. content)
   * @param options Amplitude event options.
   */
  collectionContentSelected(
    properties: CollectionContentSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CollectionContentSelected(properties), options);
  }

  /**
   * Collection Created
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Collection%20Created)
   *
   * When a user has created a new collection.
   *
   * Owner: Thomas Siegl
   *
   * @param properties The event's properties (e.g. collection description)
   * @param options Amplitude event options.
   */
  collectionCreated(
    properties: CollectionCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CollectionCreated(properties), options);
  }

  /**
   * Collection Deleted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Collection%20Deleted)
   *
   * When a user has deleted an existing collection.
   *
   * Owner: Thomas Siegl
   *
   * @param properties The event's properties (e.g. collection description)
   * @param options Amplitude event options.
   */
  collectionDeleted(
    properties: CollectionDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CollectionDeleted(properties), options);
  }

  /**
   * Collection Edited
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Collection%20Edited)
   *
   * When a user has edited an existing collection.
   *
   * Owner: Thomas Siegl
   *
   * @param properties The event's properties (e.g. collection description)
   * @param options Amplitude event options.
   */
  collectionEdited(
    properties: CollectionEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CollectionEdited(properties), options);
  }

  /**
   * Collection Followed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Collection%20Followed)
   *
   * When a user has followed a collection.
   *
   * Owner: Thomas Siegl
   *
   * @param properties The event's properties (e.g. collection description)
   * @param options Amplitude event options.
   */
  collectionFollowed(
    properties: CollectionFollowedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CollectionFollowed(properties), options);
  }

  /**
   * Collection Unfollowed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Collection%20Unfollowed)
   *
   * When a user has unfollowed a collection.
   *
   * Owner: Thomas Siegl
   *
   * @param properties The event's properties (e.g. collection description)
   * @param options Amplitude event options.
   */
  collectionUnfollowed(
    properties: CollectionUnfollowedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CollectionUnfollowed(properties), options);
  }

  /**
   * Collection Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Collection%20Viewed)
   *
   * When a user had viewed a collection.
   *
   * Owner: Thomas Siegl
   *
   * @param properties The event's properties (e.g. collection description)
   * @param options Amplitude event options.
   */
  collectionViewed(
    properties: CollectionViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CollectionViewed(properties), options);
  }

  /**
   * Content Downloaded
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Content%20Downloaded)
   *
   * When the user has downloaded a document (mind item).
   *
   * Owner: Thomas Siegl
   *
   * @param properties The event's properties (e.g. content)
   * @param options Amplitude event options.
   */
  contentDownloaded(
    properties: ContentDownloadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ContentDownloaded(properties), options);
  }

  /**
   * Content Favorite Added
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Content%20Favorite%20Added)
   *
   * When a user has favorited a content.
   *
   * Owner: Thomas Siegl
   *
   * @param properties The event's properties (e.g. content)
   * @param options Amplitude event options.
   */
  contentFavoriteAdded(
    properties: ContentFavoriteAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ContentFavoriteAdded(properties), options);
  }

  /**
   * Content Favorite Removed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Content%20Favorite%20Removed)
   *
   * When a user has removed a content from his favorites.
   *
   * Owner: Thomas Siegl
   *
   * @param properties The event's properties (e.g. content)
   * @param options Amplitude event options.
   */
  contentFavoriteRemoved(
    properties: ContentFavoriteRemovedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ContentFavoriteRemoved(properties), options);
  }

  /**
   * Content Pages Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Content%20Pages%20Selected)
   *
   * Event to track when users select pages in the first step of the export flow. The event is triggered, when the user clicks on "Weiter".
   *
   * @param properties The event's properties (e.g. content)
   * @param options Amplitude event options.
   */
  contentPagesSelected(
    properties: ContentPagesSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ContentPagesSelected(properties), options);
  }

  /**
   * Content Shared
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Content%20Shared)
   *
   * When a user has tapped on the button to share a piece of content. The user may not copy and paste the URL nor actually share the URL.
   *
   * Owner: Thomas Siegl
   *
   * @param properties The event's properties (e.g. content)
   * @param options Amplitude event options.
   */
  contentShared(
    properties: ContentSharedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ContentShared(properties), options);
  }

  /**
   * Content Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Content%20Viewed)
   *
   * When the user has viewed any content
   *
   * Owner: Thomas Siegl
   *
   * @param properties The event's properties (e.g. content)
   * @param options Amplitude event options.
   */
  contentViewed(
    properties: ContentViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ContentViewed(properties), options);
  }

  /**
   * Editor Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Editor%20Opened)
   *
   * When a user opens the mode, in which a card of a lesson can be edited
   *
   * @param properties The event's properties (e.g. content)
   * @param options Amplitude event options.
   */
  editorOpened(
    properties: EditorOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EditorOpened(properties), options);
  }

  /**
   * Export Button Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Export%20Button%20Clicked)
   *
   * When a user clicks on the Export CTA to add pages of a mind item to a new or existing Lernset in the reader.
   *
   * @param properties The event's properties (e.g. content)
   * @param options Amplitude event options.
   */
  exportButtonClicked(
    properties: ExportButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExportButtonClicked(properties), options);
  }

  /**
   * Lernsets Content Added
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Lernsets%20Content%20Added)
   *
   * When a user successfully added pages to a set by clicking on "Hinzufügen und Öfnnen" or "Hinzufügen und schließen"
   *
   * @param properties The event's properties (e.g. content)
   * @param options Amplitude event options.
   */
  lernsetsContentAdded(
    properties: LernsetsContentAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LernsetsContentAdded(properties), options);
  }

  /**
   * Name and Password Submitted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Name%20and%20Password%20Submitted)
   *
   * When the user has provided a name and password during profile creation
   *
   * Owner: Thomas Siegl
   *
   * @param options Amplitude event options.
   */
  nameAndPasswordSubmitted(
    options?: EventOptions,
  ) {
    return this.track(new NameAndPasswordSubmitted(), options);
  }

  /**
   * Page Read
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Page%20Read)
   *
   * When the user has been on a page for at least 30 seconds.
   *
   * Owner: Thomas Siegl
   *
   * @param properties The event's properties (e.g. content)
   * @param options Amplitude event options.
   */
  pageRead(
    properties: PageReadProperties,
    options?: EventOptions,
  ) {
    return this.track(new PageRead(properties), options);
  }

  /**
   * Premium Plan Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Premium%20Plan%20Selected)
   *
   * When a user selects the Premium Plan
   *
   * Owner: Thomas Siegl
   *
   * @param properties The event's properties (e.g. license id)
   * @param options Amplitude event options.
   */
  premiumPlanSelected(
    properties: PremiumPlanSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PremiumPlanSelected(properties), options);
  }

  /**
   * Pricing Plans Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Pricing%20Plans%20Viewed)
   *
   * When a user views the pricing page
   *
   * Owner: Thomas Siegl
   *
   * @param properties The event's properties (e.g. conversion path)
   * @param options Amplitude event options.
   */
  pricingPlansViewed(
    properties: PricingPlansViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PricingPlansViewed(properties), options);
  }

  /**
   * Profile Creation Started
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Profile%20Creation%20Started)
   *
   * When the user has clicked on the Start button on the first screen of the profile creation flow (after clicking on the email confirmation link from the email)
   *
   * Owner: Thomas Siegl
   *
   * @param options Amplitude event options.
   */
  profileCreationStarted(
    options?: EventOptions,
  ) {
    return this.track(new ProfileCreationStarted(), options);
  }

  /**
   * School Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/School%20Selected)
   *
   * When the user has selected the type of school he teaches at
   *
   * Owner: Thomas Siegl
   *
   * @param options Amplitude event options.
   */
  schoolSelected(
    options?: EventOptions,
  ) {
    return this.track(new SchoolSelected(), options);
  }

  /**
   * Search Completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Search%20Completed)
   *
   * Event indicating a search has been completed and logs the amount of search results
   *
   * @param properties The event's properties (e.g. nr of minditem search results)
   * @param options Amplitude event options.
   */
  searchCompleted(
    properties: SearchCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchCompleted(properties), options);
  }

  /**
   * Search Filter Applied
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Search%20Filter%20Applied)
   *
   * When the user applied a new filter to the search by adding or removing a filter
   *
   * Owner: Ahmed Elhalwagy
   *
   * @param properties The event's properties (e.g. nr of minditem search results)
   * @param options Amplitude event options.
   */
  searchFilterApplied(
    properties: SearchFilterAppliedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchFilterApplied(properties), options);
  }

  /**
   * Search Submitted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Search%20Submitted)
   *
   * When the user submitted a new search
   *
   * Owner: Ahmed Elhalwagy
   *
   * @param properties The event's properties (e.g. nr of minditem search results)
   * @param options Amplitude event options.
   */
  searchSubmitted(
    properties: SearchSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchSubmitted(properties), options);
  }

  /**
   * Set Card Deleted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Set%20Card%20Deleted)
   *
   * When a user deletes a card.
   *
   * @param properties The event's properties (e.g. lernset id)
   * @param options Amplitude event options.
   */
  setCardDeleted(
    properties: SetCardDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SetCardDeleted(properties), options);
  }

  /**
   * Set Card Duplicated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Set%20Card%20Duplicated)
   *
   * When a user duplicates a card
   *
   * @param properties The event's properties (e.g. lernset id)
   * @param options Amplitude event options.
   */
  setCardDuplicated(
    properties: SetCardDuplicatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SetCardDuplicated(properties), options);
  }

  /**
   * Set Card Moved
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Set%20Card%20Moved)
   *
   * When a user repositioned a card, by clicking on of the arrow icons in the lesson overview
   *
   * @param properties The event's properties (e.g. lernset id)
   * @param options Amplitude event options.
   */
  setCardMoved(
    properties: SetCardMovedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SetCardMoved(properties), options);
  }

  /**
   * Set Created
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Set%20Created)
   *
   * When a user creates a new set by clicking the 'create new Lernset' button. Either in the export flow or in the dashboard
   *
   * @param properties The event's properties (e.g. lernset id)
   * @param options Amplitude event options.
   */
  setCreated(
    properties: SetCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SetCreated(properties), options);
  }

  /**
   * Set Downloaded
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Set%20Downloaded)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. lernset id)
   * @param options Amplitude event options.
   */
  setDownloaded(
    properties: SetDownloadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SetDownloaded(properties), options);
  }

  /**
   * Set Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Set%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. lernset id)
   * @param options Amplitude event options.
   */
  setOpened(
    properties: SetOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SetOpened(properties), options);
  }

  /**
   * Set Printed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Set%20Printed)
   *
   * When a user triggered the CTA for printing
   *
   * @param properties The event's properties (e.g. lernset id)
   * @param options Amplitude event options.
   */
  setPrinted(
    properties: SetPrintedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SetPrinted(properties), options);
  }

  /**
   * Set Shared
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Set%20Shared)
   *
   * When a user shares a set through copying a link (right click/copy or clicking the copy button) or downloading a qr code.
   *
   * @param properties The event's properties (e.g. lernset id)
   * @param options Amplitude event options.
   */
  setShared(
    properties: SetSharedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SetShared(properties), options);
  }

  /**
   * Sharing Modal Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Sharing%20Modal%20Opened)
   *
   * When the sharing modal is opened by a user in the lesson overview
   *
   * @param properties The event's properties (e.g. lernset id)
   * @param options Amplitude event options.
   */
  sharingModalOpened(
    properties: SharingModalOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SharingModalOpened(properties), options);
  }

  /**
   * Subjects Classified
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Subjects%20Classified)
   *
   * When the user has further classified which of the previously selected subjects are main and minor subjects that he teaches
   *
   * Owner: Thomas Siegl
   *
   * @param options Amplitude event options.
   */
  subjectsClassified(
    options?: EventOptions,
  ) {
    return this.track(new SubjectsClassified(), options);
  }

  /**
   * Subjects Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Subjects%20Selected)
   *
   * When the user has selected the subject(s) that are relevant to him during profile creation
   *
   * Owner: Daniel Siebenson
   *
   * @param options Amplitude event options.
   */
  subjectsSelected(
    options?: EventOptions,
  ) {
    return this.track(new SubjectsSelected(), options);
  }

  /**
   * Subscription Cancellation Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Subscription%20Cancellation%20Selected)
   *
   * When a subscriber confirms that he wants to proceed with cancelling his subscription in the cancellation flow
   *
   * Owner: Thomas Siegl
   *
   * @param properties The event's properties (e.g. page source)
   * @param options Amplitude event options.
   */
  subscriptionCancellationSelected(
    properties: SubscriptionCancellationSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionCancellationSelected(properties), options);
  }

  /**
   * Subscription Cancellation Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Subscription%20Cancellation%20Viewed)
   *
   * When a subscriber views the subscription cancellation flow
   *
   * Owner: Thomas Siegl
   *
   * @param properties The event's properties (e.g. trial)
   * @param options Amplitude event options.
   */
  subscriptionCancellationViewed(
    properties: SubscriptionCancellationViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionCancellationViewed(properties), options);
  }

  /**
   * Subscription Pause Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Subscription%20Pause%20Selected)
   *
   * When a subscriber selects that he wants to pause the current subscription during the cancellation flow
   *
   * Owner: Thomas Siegl
   *
   * @param properties The event's properties (e.g. page source)
   * @param options Amplitude event options.
   */
  subscriptionPauseSelected(
    properties: SubscriptionPauseSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionPauseSelected(properties), options);
  }

  /**
   * Subscription Pause Submitted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Subscription%20Pause%20Submitted)
   *
   * When a subscriber submits the confirmation that he wants to pause the current subscription
   *
   * Owner: Thomas Siegl
   *
   * @param properties The event's properties (e.g. pause duration)
   * @param options Amplitude event options.
   */
  subscriptionPauseSubmitted(
    properties: SubscriptionPauseSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionPauseSubmitted(properties), options);
  }

  /**
   * Subscription Submitted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Subscription%20Submitted)
   *
   * When the user has submitted the required information for starting the subscription
   *
   * Owner: Thomas Siegl
   *
   * @param properties The event's properties (e.g. billing cycle)
   * @param options Amplitude event options.
   */
  subscriptionSubmitted(
    properties: SubscriptionSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionSubmitted(properties), options);
  }

  /**
   * Video Unlocked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/meinunterricht/meinUnterricht%20tracking%20plan/events/main/latest/Video%20Unlocked)
   *
   * When the user has unlocked any video for pupils
   *
   * @param properties The event's properties (e.g. content)
   * @param options Amplitude event options.
   */
  videoUnlocked(
    properties: VideoUnlockedProperties,
    options?: EventOptions,
  ) {
    return this.track(new VideoUnlocked(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
