import React from 'react';
import { IconName } from '../../Icon';
import { Modal } from 'react-bootstrap';
import Button from '../../common/Button';
import './ScreenStepContainer.scss';
import services from '../../../services';
import { TrackingEvent } from '../../../types/tracking';
import { noop } from 'lodash';

interface FooterButton {
  title: string;
  action: () => void;
  icon?: IconName;
  disabled?: boolean;
  event?: TrackingEvent;
}

export interface ScreenStepProps {
  onClose: () => void;
  setScreen: (screen: string) => void;
}

interface ScreenStepContainerProps {
  title: string;
  onClose: () => void;
  primaryButton: FooterButton;
  secondaryButton?: FooterButton;
  trackingId: string;
}

const ScreenStepContainer: React.FC<ScreenStepContainerProps> = (props) => {
  const {
    children,
    onClose,
    primaryButton: primary,
    secondaryButton: secondary,
    title,
    trackingId,
  } = props;
  const onPrimaryClick = () => {
    if (primary.event) {
      services.track.eventTrack(primary.event, { other: { screen_id: trackingId } });
    }
    primary.action();
  };
  const onSecondaryClick = secondary ? () => {
    if (secondary.event) {
      services.track.eventTrack(secondary.event, { other: { screen_id: trackingId } });
    }
    secondary.action();
  } : noop;

  return (
    <>
      <Modal.Header className='screen-step__header'>
        <Modal.Title
          className='screen-step__title'
          data-testid='screen-step__title'>{title}
        </Modal.Title>
        <Button
          className='screen-step__close-button'
          variant='ghost'
          onClick={onClose}
          icon='cancel'
          type='button' />
      </Modal.Header>
      <Modal.Body className='screen-step__body'>
        {children}
      </Modal.Body>
      <Modal.Footer className='screen-step__footer'>
        <Button
          onClick={onPrimaryClick}
          icon={primary.icon}
          variant='primary'
          disabled={!!primary.disabled}
        >
          {primary.title}
        </Button>
        {secondary && <Button
          onClick={onSecondaryClick}
          icon={secondary.icon}
          variant='ghost'
          disabled={!!secondary.disabled}
        >
          {secondary.title}
        </Button>}
      </Modal.Footer>
    </>
  );
};

export default ScreenStepContainer;
