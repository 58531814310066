import React, { useEffect, useRef } from 'react';
import { WidgetInstance } from 'friendly-challenge';
import config from 'config';
import './friendlyCaptcha.scss';

type OwnProps = {
  doneCallback?: (solution: string) => void;
  errorCallback?: (error: any) => void;
};

const FriendlyCaptcha:React.FC<OwnProps> = (props) => {
  const container = useRef<HTMLDivElement | null>(null);
  const widget = useRef<WidgetInstance | null>(null);

  useEffect(() => {
    if (!widget.current && container.current) {
      widget.current = new WidgetInstance(container.current, { 
        startMode: 'auto',
        doneCallback: props.doneCallback,
        errorCallback: props.errorCallback 
      });
    }

    return () => {
      if (widget.current != undefined) widget.current.destroy();
    };
  }, [container]);

  return (
    <div 
      ref={container} 
      data-sitekey={config.friendlyCaptchaSiteKey} 
      data-lang='de' 
      className='frc-captcha' 
    />
  );
};

export default FriendlyCaptcha;
