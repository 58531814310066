import React, { useContext } from 'react';
import './productSummary.scss';
import { compressClassYearArray } from '../../../utils/compressClassYearArray';
import PinItemButton from '../../PinItemButton';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../reducers/user.selectors';
import { selectProduct, selectProductStatus } from '../../../reducers/products.selectors';
import { CurrentProductContext } from '../../Product/CurrentProductContext';
import ProductAttributeCategory from '../ProductAttributeCategory';
import { ShareModalItemTypeEnum } from '../../../types/sharing';
import { AsyncActionStatus } from '../../../actions/common.actionTypes';
import Skeleton from 'react-loading-skeleton';
import { Product } from '@klab-berlin/api-sdk/lib/types/responses/Product';
import WithTooltip from '../../WithTooltip';
import { useTranslation } from 'react-i18next';
import ManageItemInCollectionsButton from '../../ManageItemInCollectionsButton';
import ShareItemButton from '../../ShareItemButton';
import BackgroundImage from '../../BackgroundImage';
import PublisherImage from '../../PublisherImage';
import TruncateWithExpand from '../../TruncateWithExpand/TruncateWithExpand';
import { getDateInGermanFormat } from '../../../utils/format';
import { AttachmentsButton, NewAttachmentsButton } from '../../AttachmentsButton';
import LicenceCategory from '../../LicenceCategory';
import { useNewDesign } from '../../../state/DesignPreferenceContextProvider';

export const ProductSummary: React.FC = () => {
  const { t } = useTranslation();
  const { productId } = useContext(CurrentProductContext);
  const isLoading =
    useSelector(state => selectProductStatus(state, productId)) === AsyncActionStatus.LOADING;

  const product: Partial<Product> = useSelector(state => selectProduct(state, productId)) || {};

  if (!product && !isLoading) return <></>;

  const user = useSelector(selectUser);
  const { prefersNewDesign, isExperimentReady } = useNewDesign();

  const compressedClassYears = compressClassYearArray(product.classYears || []);

  const getAttachmentButton = () => {
    if (!product.attachmentsCount || !isExperimentReady) return (<></>);
    if (prefersNewDesign) return (
      <NewAttachmentsButton 
        attachmentsCount={product.attachmentsCount}
        productId={productId}
      />
    ); 
    return (
      <AttachmentsButton
        attachmentsCount={product.attachmentsCount}
        productId={productId}
      />
    );
  };

  return (
    <div className='product-grid'>
      <BackgroundImage
        className='product-grid__cover'
        url={product.thumbnail}
      >
        {isLoading && <Skeleton height='400px' />}
      </BackgroundImage>
      <div className='product-grid__summary'>
        {isLoading ? <Skeleton /> : <h2 className='product-grid__summary__title'>{product.title}</h2>}
        {isLoading ? <Skeleton /> : <h3 className='product-grid__summary__subtitle'>{product.subtitle}</h3>}
        {(product.description || isLoading) && <div className='product-grid__summary__description'>
          {isLoading ? <Skeleton count={5} /> : <TruncateWithExpand
            text={product.description || ''}
            lines={5}
            expandTrackingPayload={{
              'product_id': product.id
            }}
          />}
        </div>}
      </div>
      <div className='product-grid__product-actions'>
        {!user?.hideCollectionAction &&
          <WithTooltip text={t('Add to collection')} placement='left'>
            <ManageItemInCollectionsButton
              itemData={product}
              documentId={productId}
              type='product'
              iconClassName='product-grid__product-actions__action-icon'
            />
          </WithTooltip>
        }
        {!user?.hidePinAction &&
          <WithTooltip text={product.isPinned ? t('Unpin') : t('Pin')} placement='left'>
            <PinItemButton
              isPinned={!!product.isPinned}
              documentId={productId}
              type='product'
              itemData={product}
              iconClassName='product-grid__product-actions__action-icon'
            />
          </WithTooltip>
        }
        <WithTooltip text={t('Share')} placement='left'>
          <ShareItemButton
            type={ShareModalItemTypeEnum.PRODUCT}
            itemId={productId}
            iconClassName='product-grid__product-actions__action-icon'
          />
        </WithTooltip>
      </div>
      <div className='product-grid__properties'>
        {(isLoading || product.publisher) &&
          <div className='product-grid__properties__logo'>
            {isLoading ?
              <Skeleton width='100px' height='40px' /> :
              <PublisherImage publisher={product.publisher} className='h-100 w-100' />
            }
          </div>
        }
        <div className='product-grid__properties__attributes'>
          {(isLoading || (product.subjects && product.subjects.length > 0)) &&
            <ProductAttributeCategory
              iconName='subjects'
              category='subjects'
              text='Subjects'
              items={product.subjects}
            />
          }
          {(isLoading || (product.classYears && product.classYears.length > 0)) &&
            <ProductAttributeCategory
              iconName='classes'
              text='Class year'
              category='class_years'
              items={compressedClassYears}
            />
          }
          {(isLoading || product.licence) &&
            <LicenceCategory
              licence={product.licence}
            />
          }
          {(isLoading || product.publisherReleaseDate) &&
            <ProductAttributeCategory
              iconName='time'
              className='product-grid__properties__attributes__unimportant'
              text='Release date'
              category='release_date'
              items={product.publisherReleaseDate ?
                [getDateInGermanFormat(product.publisherReleaseDate) || 'Invalid Date'] : []}
            />
          }
          {(isLoading || product.author) &&
            <ProductAttributeCategory
              iconName='profile'
              className='product-grid__properties__attributes__unimportant'
              text='Author'
              category='author'
              items={product.author ? [product.author] : []}
            />
          }
        </div>
      </div>
      <div className='product-grid__attachments-button-container'>
        {getAttachmentButton()}
      </div>
    </div>
  );
};
