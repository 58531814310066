import React from 'react';
import ControlButton, { ControlButtonProps } from '../ControlButton/ControlButton';
import { Menu, MenuItem, SvgIcon, Typography } from '@mui/material';
import { borderRadius, boxShadows, palette, spaces } from '../../../assets/styles/themes/tokens';

interface MenuItemData {
  icon: React.ComponentType;
  text: string;
  onClick: () => void;
  dataTestId?: string;
}

interface ControlButtonWithMenuProps {
  controlButtonProps: ControlButtonProps;
  menuItemsData: MenuItemData[];
}

const ControlButtonWithMenu: React.FC<ControlButtonWithMenuProps> = (props) => {
  const [buttonAnchor, setButtonAnchor] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(buttonAnchor);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setButtonAnchor(event.currentTarget);
  };
  
  const handleMenuClose = () => {
    setButtonAnchor(null);
  };

  return <>
    <ControlButton 
      {...props.controlButtonProps}
      onClick={handleButtonClick}
    />
    <Menu
      anchorEl={buttonAnchor}
      open={isMenuOpen}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        '& .MuiMenu-paper': {
          borderRadius: spaces.md,
          boxShadow: boxShadows.elevation4,
          padding: `0 ${spaces.xs}`,
        }
      }}
    >
      {props.menuItemsData.map((menuItemData) => (
        <MenuItem key={menuItemData.text} data-testid={menuItemData.dataTestId} onClick={() => {
          menuItemData.onClick();
          handleMenuClose();
        }}
        sx={{
          borderRadius: borderRadius.sm,
        }}>
          <SvgIcon component={menuItemData.icon} sx={{ fill: palette.text.primary, marginRight: spaces.md }}/>
          <Typography sx={{ fontWeight: 400 }}>{menuItemData.text}</Typography>
        </MenuItem>
      ))}
    </Menu>
  </>;
};

export default ControlButtonWithMenu;
