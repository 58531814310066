import React, { memo, useMemo } from 'react';
import { ArrowDropDown, SvgIconComponent } from '@mui/icons-material';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { borderRadius, palette, spaces } from '../../../../../assets/styles/themes/tokens';
import { TextFormatOption } from '../../../../../utils/tipTapEditor';

interface IconRendererProps {
    icon: string | SvgIconComponent;
}

interface TextFormatSelectProps {
    anchorEl: HTMLElement | null;
    textFormatOptions: Array<TextFormatOption>;
    handleClick: (event: React.MouseEvent<HTMLElement>) => void;
    handleClose: () => void;
}
  
const IconRenderer: React.FC<IconRendererProps> = ({ icon }) => {
  if (typeof icon === 'string') {
    return <Box>{icon}</Box>;
  }
  const IconComponent = icon;
  return <IconComponent />;
};
  
const TextFormatSelect: React.FC<TextFormatSelectProps> = ({ 
  anchorEl,
  textFormatOptions, 
  handleClick, 
  handleClose 
}) => {

  const activeItem = useMemo(
    () => textFormatOptions.find(option => option.isActive), 
    [textFormatOptions]
  );

  const icon = activeItem?.icon || textFormatOptions[0].icon;
      
  return (
    <>
      <Button
        data-testid='text-format-select-button'
        size='small'
        variant='text'
        color='secondary'
        endIcon={<ArrowDropDown />}
        onClick={handleClick}
        sx={{
          '&:hover': {
            backgroundColor: alpha(palette.primary.main, 0.06),
          },
        }}
      >
        <IconRenderer icon={icon} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          style: {
            padding: spaces.xxs,
            borderRadius: borderRadius.sm,
            backgroundColor: 'white',
            border: `1px solid ${palette.divider}`,
          },
        }}
        PaperProps={{
          style: {
            boxShadow: 'none',
            borderRadius: borderRadius.sm,
          },
        }}
      >
        {textFormatOptions.map((option) => (
          <MenuItem
            selected={option.isActive}
            key={option.id}
            onClick={() => {
              option.onClick();
              handleClose();
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default memo(TextFormatSelect);
