import React from 'react';
import { Navbar } from 'react-bootstrap';
import './topBar.scss';
import MuBag from '../../../assets/MuBag.svg';

const PublicTopBar: React.FC = () => {

  return (
    <Navbar className='top-bar__container' sticky='top'>
      <div className='top-bar__icon'>
        <MuBag />
      </div>
    </Navbar>
  );
};

export default PublicTopBar;
