import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as yup from 'yup';
import { routes } from '../../../constants';
import services from '../../../services';
import { useTranslation, Trans } from 'react-i18next';
import { FieldError, useForm } from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import FormInput from '../../forms/FormInput';
import { Link } from 'found';
import config from 'config';
import iam from '../../../assets/IAM.png';
import { TFunction } from 'i18next';
import Checkbox from '../../common/Checkbox';
import InputError from '../../common/InputError/InputError';
import LicenceAgreementText from '../../common/LicenceAgreementText';
import isFakeEmail from '../../../validators/isFakeEmail';
import useCaptcha from '../../../utils/useCaptcha';
import { RegisterBasisPayload } from '@klab-berlin/api-sdk/lib/types/requests/Registration';

interface BasisRegistrationProps {
  onRegisterSuccess: (email: string) => void;
  redirectTo?: string;
}

interface BasisRegistrationPayload {
  email: string;
  licenceAgreement: boolean;
}

const basisRegistrationFormValidator = yup.object<BasisRegistrationPayload>({
  email: yup
    .string()
    .email('The email is not valid')
    .required('This field is required')
    .test('Fake email', 'The email is not valid', isFakeEmail),
  licenceAgreement: yup.boolean().oneOf([true], 'You have to agree to our terms and conditions')
});

const renderError = (errorMessage: string, t: TFunction) => {
  if (errorMessage.match(/already exists/)) {
    return <Trans i18nKey={'You are already a member. Login now!'}>
      <div className='mb-3 text-danger'>
        You are already a member.
        <Link to={`/${routes.login.root}`}>
          {{ login: t('Login') }}
        </Link> now.
      </div>
    </Trans>;
  }

  return;
};

const BasisRegistration: React.FC<BasisRegistrationProps> = (props) => {
  const [error, setError] = useState<string>('');
  const [renderCaptcha, isCaptchaSolved] = useCaptcha();
  const { t } = useTranslation();

  const {
    errors,
    handleSubmit,
    register,
    formState
  } = useForm({
    mode: 'onBlur',
    validationSchema: basisRegistrationFormValidator,
  });

  const getFieldError = (fieldName: keyof BasisRegistrationPayload) => {
    if (!errors[fieldName]) return;
    const err = errors[fieldName] as FieldError;
    const errMsg = err.message?.toString();
    return t(errMsg ? errMsg : '');
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      const { email } = data;
      const registerPayload:RegisterBasisPayload = {
        email,
      };
      if (props.redirectTo) {
        registerPayload.redirectTo = { docId: props.redirectTo };
      }
      await services.registration.registerBasis(registerPayload);
      props.onRegisterSuccess(email);
    }
    catch (err: any) {
      setError(err.message);
    }
  });

  return (
    <>
      <Row>
        <Col>
          <form onSubmit={onSubmit} className='py-3 login-form'>
            <fieldset disabled={formState.isSubmitting}></fieldset>
            <Row>
              <Col>
                <FormInput
                  required={true}
                  name='email'
                  placeholder={t('Enter E-Mail address')}
                  id='email'
                  type='email'
                  errors={errors}
                  ref={register}
                  data-testid='register-email-input'
                />
                {error && renderError(error, t)}
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='d-flex'>
                  <Checkbox
                    id='licenceAgreement'
                    name='licenceAgreement'
                    ref={register}
                  />
                  <LicenceAgreementText />
                </div>
                <div className='mb-1'>
                  <InputError error={getFieldError('licenceAgreement')} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className='d-flex justify-content-center mb-3'>
                {renderCaptcha()}
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  variant='primary'
                  type='submit'
                  className='login-button w-100'
                  data-testid='register-btn'
                  disabled={formState.isSubmitting || !isCaptchaSolved}
                >
                  {formState.isSubmitting ? <Spinner animation='border' /> : t('Register')}
                </Button>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
      <Row className='my-2'>
        <Col className='d-flex justify-content-center'>
          <h4>
            {t('or')}
          </h4>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex justify-content-center'>
          <a className='btn login-sso-button border-secondary text-secondary bg-white'
            href={config.links.sso.lux}>
            <img src={iam} className='login-button__icon' />
            {t('Continue with IAM')}
          </a>
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col>
          <Col className='d-flex justify-content-center border-top py-4 px-0'>
            <Link to={`/${routes.login.root}`}>
              {t('Back to login')}
            </Link>
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default BasisRegistration;
