import * as yup from 'yup';

interface PasswordOptions {
  requiredMessage?: string;
}

export default function password(options?: PasswordOptions) {
  const minChar = 6;
  return yup.string()
    .required(options?.requiredMessage || 'Password is required')
    .min(minChar, `Password must be at least ${minChar} characters long`);
}
