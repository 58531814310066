import Icon from '../Icon';
import React, { ReactNode } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

interface ConfirmModalProps {
  message: string | ReactNode;
  show: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ show, message, onCancel, onConfirm }) => {
  const { t } = useTranslation();
  
  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Header closeButton={true}>
        <Modal.Title>
          {`${t('Delete Collection')}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Icon icon='warning' className='text-warning mr-2' />
        {message}
      </Modal.Body >
      <Modal.Footer>
        <Button variant='primary' onClick={onConfirm}>
          {t('Yes')}
        </Button>
        <Button variant='secondary' onClick={onCancel}>
          {t('No')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
