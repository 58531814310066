import React, { useCallback, useEffect, useState, useContext } from 'react';
import './teachingProfileForm.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../reducers/user.selectors';
import useAction from '../../../utils/useAction';
import LabeledText from '../../common/LabeledText';
import PersonalDetailsActions from '../Common/PersonalDetailsActions';
import PersonalDetailsInputGroup from '../PersonalDetailsInputGroup';
import { updateMe as updateMeAction } from '../../../actions/user.actions';
import { Controller, FieldError, useForm } from 'react-hook-form';
import * as yup from 'yup';
import FormInputWrapper from '../../common/FormInputWrapper';
import { Select } from '../../common/Select';
import SwitchPad from '../../common/SwitchPad';
import { SubjectSelectionModalContext } from '../../../state/SubjectSelectionModalProvider';
import Button from '../../common/Button';
import { selectSchoolTypes } from '../../../reducers/schoolTypes.selectors';

interface OwnProps {
  onSave?: () => void;
  onCancel: () => void;
}

interface formFields {
  classes: number[],
  schoolType: string[],
  isTrainee: boolean,
}

const formValidator = yup.object<formFields>({
  classes: yup.array(yup.number()).required('Please select at least one class'),
  schoolType: yup.array(yup.string()).required('Please select a school type'),
  isTrainee: yup.bool(),
});

const TeachingProfileForm: React.FC<OwnProps> = (props) => {
  const user = useSelector(selectUser);
  const schoolTypes = useSelector(selectSchoolTypes);
  const updateMe = useAction(updateMeAction);
  const { t } = useTranslation();
  const [serverError, setServerError] = useState<Error | null>(null);
  const { show: showSubjectSelectionModal } = useContext(SubjectSelectionModalContext);
  const { errors, handleSubmit, reset, control } = useForm<formFields>({
    mode: 'onBlur', validationSchema: formValidator,
  });

  useEffect(() => {
    reset({
      classes: user?.classes,
      schoolType: user?.schoolType ? [user.schoolType] : [],
      isTrainee: user?.isTrainee
    });
  }, [user]);

  const getFieldError = (fieldName: keyof formFields) => {
    if (!errors[fieldName]) return;
    const err = errors[fieldName] as FieldError;
    const errMsg = err.message?.toString();
    return t(errMsg ? errMsg : '');
  };

  const generateSchoolOptions = () => {
    return schoolTypes
      .filter(schoolType => schoolType.profileId)
      .map(schoolType => ({
        name: schoolType.name,
        value: schoolType.profileId as string,
      }));
  };

  const getSchoolYearsOptions = (minClass: number, maxClass: number) => {
    const classes = [];
    for (let i = minClass; i <= maxClass; i++) {
      classes.push({ name: i.toString(), value: i });
    }
    return classes;
  };

  const onSubmit = handleSubmit(useCallback(async (formData: formFields) => {
    setServerError(null);

    try {
      await updateMe({
        classes: formData.classes,
        schools: formData.schoolType.map((school) => { return { type: school }; }),
        isTrainee: formData.isTrainee,
      });
      props.onSave && props.onSave();
    } catch (error: any) {
      setServerError(error);
    }
  }, []));

  const traineeOptions = [
    { name: t('Yes'), value: true },
    { name: t('No'), value: false }
  ];

  if (!user) {
    return <></>;
  }

  return (
    <div className='teaching-profile-form'>
      <form onSubmit={onSubmit}>
        <PersonalDetailsInputGroup fullWidthItems={true}>
          <div>
            <LabeledText
              label={t('Subjects')}
              text={user.subjectsFlattened.map(s => s.text).join(', ')}
              id='subjects'
            />
            <Button
              type='button'
              variant='subtle'
              className={'teaching-profile-form__edit-btn'}
              onClick={() => {
                showSubjectSelectionModal();
              }}
            >
              {t('Edit')}
            </Button>
          </div>
        </PersonalDetailsInputGroup>
        <PersonalDetailsInputGroup>
          <FormInputWrapper
            label={t('Class years')}
            error={getFieldError('classes')}
            labelFor='classes'
          >
            <Controller
              as={<Select
                options={getSchoolYearsOptions(1, 13)}
                label={t('Class years')}
                multi={true}
                testId={'dropdown-classes'}
              />}
              control={control}
              name='classes'
            >
            </Controller>
          </FormInputWrapper>
          <FormInputWrapper
            label={t('SchoolType')}
            error={getFieldError('schoolType')}
            labelFor='schoolType'
          >
            <Controller
              as={<Select
                options={generateSchoolOptions()}
                label={t('SchoolType')}
                testId={'dropdown-schooltype'}
              />}
              control={control}
              name='schoolType'
            >
            </Controller>
          </FormInputWrapper>
          <FormInputWrapper
            label={t('Traineeship')}
            error={getFieldError('isTrainee')}
            labelFor='isTrainee'
          >
            <div className='teaching-profile-form__switch-pad-wrapper'>
              <Controller
                onChange={([isTrainee]) => {
                  return isTrainee;
                }}
                as={<SwitchPad
                  options={traineeOptions}
                />}
                name='isTrainee'
                control={control}
                // Pass current value using "value" prop (it would be "checked" by default because of yup.bool)
                // https://react-hook-form.com/v5/api#Controller
                valueName='value'
              />
            </div>
          </FormInputWrapper>
        </PersonalDetailsInputGroup>
        <PersonalDetailsActions
          onCancel={props.onCancel}
          showError={!!serverError}
        />
      </form>
    </div>
  );
};

export default TeachingProfileForm;
