import React from 'react';
import { Tab, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { ZuoraPaymentMethod } from '@klab-berlin/api-sdk/lib/services/billing';
import cssVariables from '../../../assets/styles/deprecated/variables';

interface PaymentTabPanelProps {
  loadingError: boolean;
  isLoaded: boolean;
  children: React.ReactNode;
  method: ZuoraPaymentMethod;
  skeletonHeight: number;
}

const PaymentTabPanel: React.FC<PaymentTabPanelProps> = ({
  method,
  loadingError,
  isLoaded,
  children,
  skeletonHeight,
}) => {

  const { t } = useTranslation();
  return (
    <Tab.Pane
      eventKey={method}>
      {loadingError &&
        <Alert variant='warning'>
          {t('Error while loading payment form, please refresh.')}
        </Alert>
      }
      {!loadingError && !isLoaded &&
        <SkeletonTheme color={cssVariables.greyLightest} highlightColor='#ffffff'>
          <Skeleton height={skeletonHeight} />
        </SkeletonTheme>
      }
      {children}
    </Tab.Pane>
  );
};

export default PaymentTabPanel;
