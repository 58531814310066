import React, { useContext, useState } from 'react';
import { Box, Chip, Skeleton, Theme, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { cardDimensions, palette, spaces } from '../../../assets/styles/themes/tokens';
import { ShareModalItemTypeEnum } from '../../../types/sharing';
import ShareButton from '../../ShareButton';
import { 
  AddMenu,
  CoverImage,
  Metadata,
  MuiCardBaseProps,
  mapContentTypeName
} from '../../cards/MuiCard/MuiCardBase/MuiCardBase';
import { Product } from '@klab-berlin/api-sdk/lib/types/responses/Product';
import { useSelector } from 'react-redux';
import { selectProduct, selectProductStatus } from '../../../reducers/products.selectors';
import { CurrentProductContext } from '../CurrentProductContext';
import { AsyncActionStatus } from '../../../actions/common.actionTypes';
import IsNewChip from '../../cards/MuiCard/MuiCardBase/IsNewChip';
import MuiCardBaseLongDescription from '../../cards/MuiCard/MuiCardBase/MuiCardBaseLongDescription';
import { getDateInGermanFormat } from '../../../utils/format';
import { NewAttachmentsButton } from '../../AttachmentsButton';
import Tooltip from '../../Tooltip';
import { compressClassYearArray } from '../../../utils/compressClassYearArray';
import getTextTruncationSx from '../../../utils/getTextTruncationSx';

export const NewProductSummary: React.FC = () => {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobile'));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between('mobile', 'tablet'));
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('tablet'));
  const flexDirectionBreakPoint = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  
  const { productId } = useContext(CurrentProductContext);
  const product: Partial<Product> = useSelector(state => selectProduct(state, productId)) || {};
  const isLoading = 
    useSelector(state => selectProductStatus(state, productId)) === AsyncActionStatus.LOADING;

  const getContainerMaxWidth = () => {
    if (isDesktop) return cardDimensions.desktop.maxWidth;
    if (isTablet) return cardDimensions.tablet.maxWidth;
    return cardDimensions.mobile.maxWidth;
  };

  const PageTitle = () => {
    return (
      <Box sx={{
        color: palette.text.primary,
        padding: `${spaces.md} 0px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}
      data-testid='product-summary-page-title'
      >
        <Typography variant='h5'>{t('Product overview')}</Typography>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: spaces.xs,
        }}>
          <ShareButton 
            type={ShareModalItemTypeEnum.PRODUCT}
            itemId={productId}
            size='large'
          />
          <AddMenu 
            isPinned={!!product.isPinned}
            id={productId}
            itemData={product}
            type='product'
            buttonSx={{
              position: 'relative',
              top: 'unset',
              right: 0,
            }}
          />
        </Box>
      </Box>
    );
  };

  const ProductCoverImage = () => {
    const getCoverImageHeight = () => {
      if (isDesktop) return 422;
      if (isTablet) return 354;
      return 316;
    };

    const getCoverImageWidth = () => {
      if (isDesktop) return 298;
      if (isTablet) return 250;
      return 224;
    };

    return (
      <Box sx={{
        minWidth: getCoverImageWidth(),
        maxWidth: getCoverImageWidth(),
        height: getCoverImageHeight(),
        position: 'relative',
        display: 'flex',
        marginRight: flexDirectionBreakPoint ? 'auto' : 0,
        marginLeft: flexDirectionBreakPoint ? 'auto' : 0,
        alignItems: flexDirectionBreakPoint ? 'center' : 'flex-start',
      }}
      data-testid='product-cover-image-container'
      >
        { isLoading && <Skeleton height={`${getCoverImageHeight()}px`} /> }
        { 
          !isLoading
          && product.thumbnail
          && <CoverImage 
            cover={product.thumbnail}
            className='product-cover-image'
            customSx={{
              ':hover': { boxShadow: 'none' },
              height: '100%',
            }}
          />
        }
        { !isLoading && product.isNew && <IsNewChip size={ isMobile ? 'small' : 'medium' } /> }
      </Box>
    );
  };

  const Summary = () => {
    type ContentMetaProps = {
      contentType: MuiCardBaseProps['contentType'];
      publisher?: MuiCardBaseProps['publisher'];
      isReadOnly?: MuiCardBaseProps['isReadOnly'];
    };

    const ContentMeta: React.FC<ContentMetaProps> = ({ contentType, isReadOnly, publisher }) => {
      return (
        <Box
          sx={{
            gap: spaces.sm,
            display: 'inline-flex',
            alignItems: 'baseline',
            paddingRight: spaces.sm,
          }}
        >
          <Typography variant='overline' color='text.secondary' textTransform='uppercase'>
            {t(mapContentTypeName(contentType))}
          </Typography>
          <Typography variant='caption' color='text.secondary'>
            •
          </Typography>
          <Typography variant='caption' color='text.secondary'>
            {publisher}
          </Typography>
          { isReadOnly && <Chip label={t('Read only')} /> }
        </Box>
      );
    };

    const SummaryHeader = () => (
      <>
        <ContentMeta contentType='product' publisher={product.publisher} isReadOnly={product.isReadOnly} />
        <Typography variant='h5' sx={{ color: palette.text.primary }} data-testid='product-summary__product-title'>
          {product.title}
        </Typography>
        {
          product.subtitle &&
          <Tooltip label={product.subtitle} placement='bottom' hideWithoutOverflow={true}>
            <Typography 
              variant='subtitle1'
              sx={{
                color: palette.text.primary,
                ...getTextTruncationSx(2)
              }}
            >
              {product.subtitle}
            </Typography>
          </Tooltip>
        }
      </>
    );

    const MobileSummaryHeader = () => (
      <>
        <Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: spaces.xs,
          }}>
            <ContentMeta contentType='product' publisher={product.publisher} />
            <Typography variant='h6' sx={{ color: palette.text.primary }}>{product.title}</Typography>
            <Typography 
              variant='subtitle1'
              sx={{
                color: palette.text.primary,
                ...getTextTruncationSx(2)
              }}
            >
              {product.subtitle}
            </Typography>
          </Box>
        </Box>
        { true && <Box><Chip label={t('Read only')} /></Box> }
      </>
    );

    const ProductProperties = () => {
      const dateInGermanFormat = 
        product.publisherReleaseDate &&
        getDateInGermanFormat(product.publisherReleaseDate);

      return (
        <Box sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          gap: spaces.md,
        }}>
          {
            product.subjects && product.subjects.length > 0 &&
              <Tooltip label={product.subjects.join(', ')} placement='top-start'>
                <Metadata
                  icon='tag'
                  sx={{ color: palette.text.secondary }}
                  label={product.subjects.join(', ')}
                />
              </Tooltip>
          }
          {
            product.classYears && product.classYears.length > 0 && 
              <Metadata
                icon='teacher'
                sx={{ color: palette.text.secondary }}
                label={compressClassYearArray(product.classYears).join(', ') + ' ' + t('Class year')}
              />
          }
          {
            dateInGermanFormat &&
            <Metadata
              icon='calendar'
              label={dateInGermanFormat || ''}
            />
          }
        </Box>
      );
    };

    const AttachmentsButton = () => (
      <Box sx={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        marginTop: spaces.md,
        width: (isMobile || isTablet) ? '100%' : 'auto',
      }}>
        {
          !!product.attachmentsCount 
          && product.attachmentsCount > 0 
          && 
            <NewAttachmentsButton 
              attachmentsCount={product.attachmentsCount}
              productId={productId}
              buttonSx={(isMobile || isTablet) ?  {  width: '100%' } : {}}
            />
        }
      </Box>
    );

    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: spaces.sm,
        width: '100%',
      }}>
        { isMobile ? <MobileSummaryHeader /> : <SummaryHeader /> }
        <MuiCardBaseLongDescription
          description={product.description}
          lines={5}
          isExpanded={isExpanded}
          onShowToggleClick={() => setIsExpanded(!isExpanded)}
        />
        <Box sx={{ 
          display: 'inline-flex',
          flexWrap: 'wrap',
          width: '100%',
          justifyContent: 'space-between',
        }}>
          <ProductProperties />
          <AttachmentsButton />
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        maxWidth: getContainerMaxWidth(),
        flexDirection: 'column',
      }}
      data-testid='product-summary-container'
    >
      <PageTitle />
      <Box sx={{
        display: 'flex',
        flexDirection: flexDirectionBreakPoint ? 'column' : 'row',
        gap: isTablet ? spaces.md : spaces.xl,
      }}>
        <ProductCoverImage />
        <Summary />
      </Box>
    </Box>
  );
};
