import React from 'react';
import FormInputWrapper from '../FormInputWrapper';
import './Radio.scss';
import classnames from 'classnames';

type RadioOption = { label: string, value: string };

interface OwnProps {
  label?: string;
  options: RadioOption[];
  name: string;
  error?: string;
  handleChange?: (value:string) => void;
  radioClass?: string;
  labelClass?: string;
}

const Radio = React.forwardRef<HTMLInputElement, OwnProps>((props, ref) => {
  return (
    <div className='radio'>
      <FormInputWrapper
        label={props.label}
        labelFor={props.name}
        error={props.error}
      >
        <div>
          {props.options.map(option => (
            <div key={option.value} className='radio__option'>
              <input
                type='radio'
                name={props.name}
                id={option.value}
                value={option.value}
                className={classnames('radio__option-input', props.radioClass)}
                onChange={() => props.handleChange && props.handleChange(option.value)}
                ref={ref}
              />
              <label
                htmlFor={option.value}
                className={classnames('radio__option-label', props.labelClass)}
              >{option.label}</label>
            </div>
          ))}
        </div>
      </FormInputWrapper>
    </div>
  );
});

export default Radio;
