import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../Icon';
import '../readerProductNavigation.scss';
import classnames from 'classnames';
import { DownloadModalContext } from '../../../../state/DownloadModalProvider';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../reducers/user.selectors';
import useRouter from '../../../../utils/useRouter';
import { routes } from '../../../../constants';
import { ModalsContext } from '../../../../state/ModalsProvider';

interface ReaderProductNavigationAttachmentsProps {
  mindItemId?: string;
  productId: string;
  highlightAttachmentsButton?: boolean;
}

const ReaderProductNavigationAttachments: React.FC<ReaderProductNavigationAttachmentsProps> = (props) => {
  const { t } = useTranslation();
  const { isOpen: downloadModalIsOpen } = useContext(DownloadModalContext);
  const { openModal } = useContext(ModalsContext);
  const user = useSelector(selectUser);
  const { router } = useRouter();
  const attachmentButtonRef = React.createRef<HTMLButtonElement>();

  if (!user) return <></>;

  useEffect(() => {
    const attachmentButtonRefCurrent = attachmentButtonRef && attachmentButtonRef.current;
    if (!downloadModalIsOpen && attachmentButtonRefCurrent) {
      attachmentButtonRefCurrent.blur();
    }
  }, [downloadModalIsOpen]);

  const onClick = () => {
    if (user.isPremium) {
      router.push(`/${routes.productAttachmentsPage.root}/${props.productId}`);
    } else {
      openModal('premiumContent');
    }
  };

  return (
    <button
      ref={attachmentButtonRef}
      className={
        classnames(
          'table-of-contents__attachments-button',
          'table-of-contents__item',
          {
            'table-of-contents__item--highlighted':
              props.highlightAttachmentsButton || downloadModalIsOpen
          }
        )}
      onClick={onClick}
    >
      <div className='table-of-contents__item-icon'>
        <Icon className='paperclip' icon='paperclip' />
      </div>
      <span className='show-on-expand table-of-contents__item-name'>{t('Attachments')}</span>
    </button>
  );
};

export default ReaderProductNavigationAttachments;
