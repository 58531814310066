import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectWorkloadLoadingStatus, selectWorkloads } from '../../reducers/search.selectors';
import { BasicFilterProps } from '../../types/search';
import { Select } from '../common/Select';
import SearchFilterSkeleton from './SearchFilterSkeleton';
import useFilterStatus from './useFilterStatus';

const FilterByWorkload: React.FC<BasicFilterProps<string[]>> = (
  { updateFilter, clearFilter, currentValue, onToggle, onItemClick }
) => {
  const { t } = useTranslation();
  const workloads = useSelector(selectWorkloads);
  const { isReady, isError } = useFilterStatus(selectWorkloadLoadingStatus);

  type Option = {
    name: string;
    value: string;
  };

  const options: Option[] = workloads.map((workload) => ({ name: workload.name, value: workload.id }));

  if (isError) {
    return null;
  }

  if (!isReady) {
    return <SearchFilterSkeleton />;
  }

  return (
    <Select
      onSelectionConfirm={(values) => {
        updateFilter(values);
      }}
      onSelectionClear={() => {
        clearFilter();
      }}
      label={t('Workload')}
      options={options}
      value={currentValue}
      alwaysShowLabel={true}
      multi={true}
      onToggle={onToggle}
      onItemClick={onItemClick}
      saveButtonLabel='Anwenden'
      resetButtonLabel='Löschen'
    />
  );
};

export default FilterByWorkload;
