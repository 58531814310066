import { TrackingEvent } from '../../types/tracking';

export const trackingEvents = {
  DLpush: {
    gtm: 'DLpush',
    requiredData: ['user', 'utm']
  } as TrackingEvent,
  registerPremiumFinish: {
    gtm: 'register_premium_finish',
    requiredData: ['other']
  } as TrackingEvent,
  collectionDoubleClick: {
    intercom: 'collection_open'
  },
  collectionMindItemDoubleClick: {
    intercom: 'item_open',
    requiredData: ['document'],
  } as TrackingEvent,
  collectionOpen: {
    intercom: 'collection_open'
  },
  collectionProductDoubleClick: {
    intercom: 'item_open',
    requiredData: ['product'],
  } as TrackingEvent,
  curatedCollectionFollow: {
    intercom: 'CuratedCollection_follow',
  },
  curatedCollectionUnfollow: {
    intercom: 'CuratedCollection_un-follow',
  },
  emptyStateCreateCollectionModalOpen: {
    intercom: 'Collection_Create',
  },
  itemOpen: {
    intercom: 'item_open',
    requiredData: [ 'other' ]
  } as TrackingEvent,
  loginSuccess: {
    email: {
      intercom: 'Login_EmailSuccesful',
      requiredData: ['other'],
      defaultPayload: {
        other: {
          'login_type': 'Email',
        },
      },
    } as TrackingEvent,
  },
  loginFailure: {
    facebookCancelled: {
      intercom: 'Login_FBCancelled',
      requiredData: ['other'],
      defaultPayload: {
        other: {
          'login_type': 'FB',
          'login_error': 'FB Login Cancelled'
        },
      },
    } as TrackingEvent,
    facebookIncorrectEmail: {
      intercom: 'Login_FBIncorrectEmail',
      requiredData: ['other'],
      defaultPayload: {
        other: {
          'login_type': 'FB',
          'login_error': 'FB Invalid Email'
        },
      },
    } as TrackingEvent,
    incorrectEmail: {
      intercom: 'Login_EmailIncorrectEmail',
      requiredData: ['other'],
      defaultPayload: {
        other: {
          'login_type': 'Email',
        },
      },
    } as TrackingEvent,
    incorrectIP: {
      intercom: 'login_EmailIncorrectIPRange',
      requiredData: ['other'],
      defaultPayload: {
        other: {
          'login_error': 'Incorrect IP Range'
        }
      }
    } as TrackingEvent,
    notVerified: {
      intercom: 'Login_EmailNotVerified',
      requiredData: ['other'],
      defaultPayload: {
        other: {
          'login_type': 'Email',
          'login_error': 'Email not verified'
        },
      },
    } as TrackingEvent,
  },
  menuCreateCollectionModalOpen: {
    intercom: 'Collection_Create',
  },
  mindItemContextPin: {
    intercom: 'Document_pin',
  },
  mindItemContextUnpin: {
    intercom: 'Document_un-pin',
  },
  mindItemMenuPin: {
    intercom: 'Document_pin',
  },
  mindItemMenuUnpin: {
    intercom: 'Document_un-pin',
  },
  passwordResetSuccessful: {
    intercom: 'passwordResetSuccessful',
    requiredData: ['other']
  } as TrackingEvent,
  passwordResetFailure: {
    intercom: 'passwordResetFailure',
    requiredData: ['other']
  } as TrackingEvent,
  productContextPin: {
    intercom: 'Product_pin',
  },
  productContextUnpin: {
    intercom: 'Product_un-pin',
  },
  productMenuPin: {
    intercom: 'Product_pin',
  },
  productMenuUnpin: {
    intercom: 'Product_un-pin',
  },
  registrationClicked: {
    intercom: 'Login_ClickRegistration',
  },
  searchDashboardExecute: {
    intercom: 'search_execute',
    requiredData: ['other'],
  } as TrackingEvent,
  searchExecute: {
    intercom: 'search_execute',
    requiredData: ['other'],
  } as TrackingEvent,
  searchFilterSave: {
    intercom: 'search_setfilter',
    requiredData: ['other']
  } as TrackingEvent,
  carouselItemsPin: {
    intercom: 'Carousel_pin',
    requiredData: ['product']
  } as TrackingEvent,
  upgradeChangeUpgrade: {
    intercom: 'Upgrade_ChangeUpgrade',
  },
  upgradeClickPaymentMethod: {
    intercom: 'Upgrade_ClickPayment',
    requiredData: ['other'],
  } as TrackingEvent,
  upgradeClickLehrerinnen: {
    intercom: 'Upgrade_ClickLehrerInnen'
  },
  upgradeClickReferendarinnen: {
    intercom: 'Upgrade_ClickReferendarinnen'
  },
  upgradeCodeEnter: {
    intercom: 'Upgrade_CodeEnter',
    requiredData: ['other']
  } as TrackingEvent,
  upgradeCodeRedeem: {
    intercom: 'Upgrade_ClickJetztSichern',
    requiredData: ['other']
  } as TrackingEvent,
  upgradeFinish: {
    intercom: 'Upgrade_Finish',
    requiredData: ['user', 'other']
  } as TrackingEvent,
  reader: {
    itemDownload: {
      intercom: 'Document_download-execute',
      requiredData: ['other']
    } as TrackingEvent,
    videoStart: {
      intercom: 'video_start',
      requiredData: ['other']
    } as TrackingEvent,
    videoStreamed30Seconds: {
      intercom: 'video_streamed_30_sec',
      requiredData: ['other', 'user']
    } as TrackingEvent,
    videoCopyPublicLink: {
      intercom: 'video_copy_public_link',
      requireData: ['other'],
    } as TrackingEvent,
    clickVideoShare: {
      intercom: 'video_click_share',
      requiredData: ['other']
    } as TrackingEvent,
  },
  mindItemPin: {
    intercom: 'Document_pin',
    requiredData: ['other']
  } as TrackingEvent,
  productPin: {
    intercom: 'product_pin',
    requiredData: ['other']
  } as TrackingEvent,   
};
