import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import NewReaderOverlay from '../../../NewReaderOverlay';
import { WatermarkTrackingObject } from '../NewBasisReaderOverlay';
import UnlockPremium from '../UnlockPremium';
import { ampliConversionPath } from '../../../../../../constants';

interface DownloadLimitExceededOverlayProps {
  trackingObject: WatermarkTrackingObject;
  onClose?: () => void;
  isPremium?: boolean;
}

const DownloadLimitExceededOverlay: React.FC<DownloadLimitExceededOverlayProps> = (props) => {
  const { t } = useTranslation();

  return (
    <NewReaderOverlay emoji='📁' title={t('Download limit exceeded')} onClose={props.onClose}>
      <NewReaderOverlay.BodyText>
        {props.isPremium
          ? <Trans i18nKey='Download limit exceeded for a premium user'>
            <p>
              Unfortunately this does not work at the moment,
              because you have already used up your 100 possible downloads this month. 😕
            </p>
            <p>
              Tip: It&#39;s best to add this content to your watchlist
              so that you can download it directly in the next calendar month!
            </p>
          </Trans>
          : t('Get meinUnterricht Premium now to download this document')
        }
      </NewReaderOverlay.BodyText>
      {!props.isPremium && <UnlockPremium
        emoji='👀'
        text={t('Discover meinUnterricht Premium')}
        minditem_id={props.trackingObject.minditem_id}
        current_page={props.trackingObject.current_page}
        page_count={props.trackingObject.page_count}
        onClick={props.onClose}
        conversionPath={ampliConversionPath.DownloadLimitReached}
      />}
    </NewReaderOverlay>
  );
};

export default DownloadLimitExceededOverlay;
