import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { FieldErrors } from 'react-hook-form';
import './formInput.scss';

interface FormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  name: string;
  groupClassName?: string;
  className?: string;
  errors: FieldErrors<{ [key: string]: any }>;
}

const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>(
  ({ id, name, title, errors, className, groupClassName, ...props },
    ref,
  ) => {
    const { t } = useTranslation();
    const error = errors[name];

    return (
      <div className={classnames('form-group', groupClassName)}>
        {title && <label htmlFor={id}>{title}</label>}
        <input
          className={classnames('mu-form-input__input form-control', { 'is-invalid': !!error }, className)}
          id={id}
          data-testid={`mu-form-input__${name}`}
          name={name}
          type='text'
          ref={ref}
          {...props}
        />
        {error && (
          <div className='invalid-feedback'>
            {t(String(error.message))}
          </div>
        )}
      </div>
    );
  });

export default FormInput;
