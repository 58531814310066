import React, { createContext, useEffect, useState } from 'react';

export interface CookiebotContextProps {
  necessary: boolean;
  marketing: boolean;
  preferences: boolean;
  statistics: boolean;
}

const defaultConsent: CookiebotContextProps = {
  necessary: true,
  marketing: false,
  preferences: false,
  statistics: false,
};

export const CookiebotContext = createContext<CookiebotContextProps>(defaultConsent);

const CookiebotContextProvider: React.FC = ({ children }) => {
  const [consent, setConsent] = useState(defaultConsent);

  useEffect(() => {
    const cookieEventListener = () => {
      setConsent(Object.assign({}, window.Cookiebot.consent));
    };

    if (window.Cookiebot?.consent) {
      cookieEventListener();
    }

    window.addEventListener('CookiebotOnAccept', cookieEventListener);
    window.addEventListener('CookiebotOnDecline', cookieEventListener);

    return () => {
      window.removeEventListener('CookiebotOnAccept', cookieEventListener);
      window.removeEventListener('CookiebotOnDecline', cookieEventListener);
    };
  }, []);

  return (
    <CookiebotContext.Provider value={consent}>
      {children}
    </CookiebotContext.Provider>
  );
};

export default CookiebotContextProvider;
