import fscreen from 'fscreen';
import { noop } from 'lodash';

const exitFullscreen = (onExit?: () => void) => {
  if (fscreen.fullscreenElement) {
    fscreen.onfullscreenchange = () => {
      if (onExit) onExit();
      fscreen.onfullscreenchange = noop;
    };
    fscreen.exitFullscreen();
  }
  else {
    if (onExit) onExit();
  }
};

export default exitFullscreen;
