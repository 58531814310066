import './videoPlayerVimeo.scss';
import React, {  } from 'react';
import VideoPlayer, { TimeUpdateProps } from '../../../../VideoPlayer/VideoPlayer';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../reducers/user.selectors';
import service, { ampliServerSideEventTrack } from '../../../../../services/track.service';
import { trackingEvents } from '../../../../../services/tracking/trackConfig';
import { ReaderContentViewVariant } from '../../../../../types/reader';
import { isFeatureEnabled } from '../../../../../utils/feature';
import { getContentEventPayload, mapDesignPreference } from '../../../../../utils/ampli';
import classnames from 'classnames';
import { VideoNotAvailableContent } from '../../../../cards/VideoNotAvailableCard/VideoNotAvailableCard';
import { amplitudeContentTypes, amplitudeEvents, } from '../../../../../constants';
import { AmplitudeTrackPayload } from '@klab-berlin/api-sdk/lib/types/requests/Track';
import { useNewDesign } from '../../../../../state/DesignPreferenceContextProvider';

interface VideoPlayerVimeoProps {
  url: string;
  mindItemId: string;
  classYears: number[];
  schoolTypes: string[];
  subjects: string[];
  title: string;
  publisher: string;
  variant: ReaderContentViewVariant;
  setIsReady: (arg: boolean) => void;
  onPause: () => void;
  isPublic?: boolean;
}

const VideoPlayerVimeo: React.FC<VideoPlayerVimeoProps> =
  ({ 
    mindItemId, 
    title, 
    url, classYears, 
    schoolTypes, 
    subjects, 
    publisher, 
    setIsReady, 
    onPause, 
    variant, 
    isPublic 
  }) => {  
    const user = useSelector(selectUser);
    const { shouldShowDesignPreferenceSwitch, prefersNewDesign } = useNewDesign();
    const eventPayload = isPublic ?
      { other: { 'mind_item_id': mindItemId, user_type: 3 } } :
      { other: { 'mind_item_id': mindItemId } };

    const trackingFunction = () => !isPublic &&
        service.eventTrack(trackingEvents.reader.videoStreamed30Seconds, eventPayload);
    
    const isPremium = user && user.isPremium;

    const onPlay = () => {
      service.eventTrack(trackingEvents.reader.videoStart, eventPayload);
    };
    let is30SecondsAchieved = false;
    const onStreamed30Seconds = (data: TimeUpdateProps) => {
      if (!is30SecondsAchieved && (isPremium || isPublic) && data && data.seconds >= 30) {
        is30SecondsAchieved = true;
        trackingFunction();
        trackAmpliEvent();
      }
    };

    const trackAmpliEvent = () => {
      const isPublicMindItem: boolean = isPublic || false;
      const mindItem = {
        title,
        classYears,
        schoolTypes,
        subjects,
        publisher,
        id: mindItemId,
        publicVideo: isPublicMindItem ? 'Yes': 'No'
      };
      
      const contentCompletedEventPayload: AmplitudeTrackPayload | null = getContentEventPayload({ 
        content: mindItem,
        event_type: amplitudeEvents.ContentCompleted, 
        contentType: amplitudeContentTypes.video
      });

      if (contentCompletedEventPayload) {
        if (shouldShowDesignPreferenceSwitch) {
          contentCompletedEventPayload.event_properties['ui'] = mapDesignPreference(prefersNewDesign);
        }
        ampliServerSideEventTrack(isPublicMindItem, contentCompletedEventPayload);
      }
    };

    const renderVideoPlayer = () => {
      if (isPublic) {
        return (
          <VideoPlayer
            url={url}
            onPlay={onPlay}
            onStreamed={onStreamed30Seconds}
          />
        );
      } else {
        if (isFeatureEnabled('vimeo')) {
          return <VideoPlayer
            url={url}
            onPause={onPause}
            onPlay={onPlay}
            onStreamed={onStreamed30Seconds}
            onReady={(value) => setIsReady(value)}
          />;    
        } else {
          return (
            <div className={classnames({
              'video-not-available-container--mobile': variant === 'mobile',
              'video-not-available-container': variant === 'desktop'
            })}>
              <VideoNotAvailableContent />
            </div>
          );
        }
      }
      
    };

    return renderVideoPlayer();
  };

export default VideoPlayerVimeo;
