import { AsyncAction } from './common.actionTypes';
import { AuthLoginResponseData } from '@klab-berlin/api-sdk/lib/types/responses/Auth';
import { Action } from 'redux';

export enum LOGIN {
  START = 'LOGIN_START',
  SUCCESS = 'LOGIN_SUCCESS',
  ERROR = 'LOGIN_ERROR',
}

export type LoginAction = AsyncAction<
  typeof LOGIN.START,
  typeof LOGIN.SUCCESS,
  typeof LOGIN.ERROR,
  AuthLoginResponseData,
  { type: 'password'; username: string } |
  { type: 'facebook'; facebookId: string }
>;

export enum LOGOUT {
  START = 'LOGOUT_START',
  SUCCESS = 'LOGOUT_SUCCESS',
  ERROR = 'LOGOUT_ERROR',
}

export type LogoutAction = AsyncAction<
  typeof LOGOUT.START,
  typeof LOGOUT.SUCCESS,
  typeof LOGOUT.ERROR,
  undefined
>;

export enum REFRESH_TOKEN {
  EXPIRED = 'REFRESH_TOKEN_EXPIRED',
}

export type RefreshTokenExpiredAction = Action<REFRESH_TOKEN.EXPIRED>;
