import ViewSwitch from './ViewSwitch';
import classnames from 'classnames';
import { Link, Match, Router, withRouter } from 'found';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { deleteItemFromCollection } from '../actions/collections.actions';
import EmptyState from '../components/EmptyState';
import { routes } from '../constants';
import { ContentItem } from '../types/AppContent';
import ItemsListItem from './ItemsListItem';
import InfiniteScroll from 'react-infinite-scroller';
import { pagination } from '../constants';
import cssVariables from '../assets/styles/deprecated/variables';
import { useViewMode } from './ViewSwitch/ViewModeContext';
import useMedia from '../utils/useMedia';

interface ItemsListOwnProps {
  activeId?: string;
  items: ContentItem[];
  linkRoot: string[];
  setActiveId: (id: string) => void;
  isLoading: boolean;
  hasNext: boolean;
  loadNext: () => void;
  total: number;
}

interface ItemsListProps extends ItemsListOwnProps {
  deleteItemFromCollection: typeof deleteItemFromCollection;
  match: Match;
  router: Router;
}

const ItemsList: React.FC<ItemsListProps> = ({ items, ...props }) => {
  
  const { viewMode } = useViewMode();
  const { isMobile } = useMedia();

  const skeletonsToDisplay = props.total === -1
    ? 1
    : Math.min(
      pagination.itemsOnPage,
      // sometimes items.length is higher than props.total, when we add an item to a collection
      // and then collections get refreshed before refreshing their contents
      Math.max(0, props.total - items.length),
    );

  const skeletonHeight = viewMode === 'list'
    ? cssVariables.collectionListHeight
    : cssVariables.collectionGridItemHeight;

  if (props.total === 0) {
    return (
      <ListEmptyState linkRoot={props.linkRoot[0]} />
    );
  }

  return (
    <>
      <div className='d-none d-md-flex justify-content-end'>
        <ViewSwitch
          iconSize='lg'
          listSize={items.length}
        />
      </div>

      <InfiniteScroll
        threshold={(skeletonHeight + cssVariables.collectionGridGap) * 5}
        className={classnames({ 'pt-3 grid-container': viewMode === 'grid' })}
        loadMore={() => props.loadNext()}
        hasMore={props.hasNext && !props.isLoading}
        useWindow={false}
        getScrollParent={() => document.querySelector('.main-container-grid__body')}
      >
        {items.map((item) =>
          <ItemsListItem
            key={item.id}
            item={item}
            setActiveId={props.setActiveId}
            isSelected={item.id === props.activeId}
            deleteItemFromCollection={props.deleteItemFromCollection}
            openOnClick={!!isMobile}
          />
        )}
        {props.isLoading && (
          Array(skeletonsToDisplay).fill(undefined).map((_, i) =>
            <Skeleton key={i} height={skeletonHeight} />,
          )
        )}
      </InfiniteScroll>
    </>
  );
};

const SearchLink = () => {
  const { t } = useTranslation();
  return <Link to={`/${routes.search.root}`}>{t('Search')}</Link>;
};

const ListEmptyState: React.FC<{ linkRoot: string }> = ({ linkRoot }) => {
  const { t } = useTranslation();

  switch (linkRoot) {
    case routes.downloads.root:
      return <EmptyState iconName='text-file' heading={t('no downloads yet')} />;

    case routes.pinned.root:
      return (
        <EmptyState
          iconName='text-file'
          heading={t('no pinned documents yet')}
          text={
            <Trans i18nKey='here you will find all items that you pinned' key={1}>
              <p><SearchLink /></p>
            </Trans>
          }
        />
      );
    default:
      return (
        <EmptyState
          iconName='text-file'
          heading={t('it\'s pretty empty here')}
          text={
            <Trans i18nKey='go look for some content' key={1}>
              <p><SearchLink /></p>
            </Trans>
          }
        />
      );
  }
};

export default compose<React.FC<ItemsListOwnProps>>(
  withRouter,
  connect(
    undefined,
    { deleteItemFromCollection },
  ),
)(ItemsList);
