import { LicenceModelsById } from '@klab-berlin/api-sdk/lib/types/responses/Billing';
import { AsyncAction } from './common.actionTypes';

export enum FETCH_ACTIVE_LICENCE_MODELS {
  START = 'FETCH_ACTIVE_LICENCE_MODELS_START',
  SUCCESS = 'FETCH_ACTIVE_LICENCE_MODELS_SUCCESS',
  ERROR = 'FETCH_ACTIVE_LICENCE_MODELS_ERROR',
}

export type FetchActiveLicenceModelsAction = AsyncAction<
  typeof FETCH_ACTIVE_LICENCE_MODELS.START,
  typeof FETCH_ACTIVE_LICENCE_MODELS.SUCCESS,
  typeof FETCH_ACTIVE_LICENCE_MODELS.ERROR,
  LicenceModelsById
>;
