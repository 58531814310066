import { LicenceModel } from '@klab-berlin/api-sdk/lib/types/responses/Billing';
import { addDays, format } from 'date-fns';
import { currencyFormat } from '../../../utils/format';
import { dateFormat } from '../../../constants';

export interface LicenceInformation {
  endDate: string;
  price: string;
  totalPrice: string;
  subtitle?: string;
  title?: string;
  period: string;
  months: string;
}

const getLicenceInformation = (licenceData: LicenceModel): LicenceInformation => {
  return {
    endDate: format(addDays(Date.now(), 14), dateFormat),
    price: currencyFormat(licenceData.price),
    totalPrice: currencyFormat(licenceData.totalPrice),
    subtitle: licenceData.landingPageSubtitle,
    title: licenceData.landingPageTitle,
    period: licenceData.period,
    months: licenceData.period
  };
};
  
export default getLicenceInformation;
