import { NotificationSupportRequest } from '@klab-berlin/api-sdk/lib/types/requests/Notification';
import api from '../clients/api';
import { notifyBugsnagNetwork } from '../utils/bugsnagClient';

const service = {
  sendSupportNotification,
};

function sendSupportNotification(payload: NotificationSupportRequest) {
  notifyBugsnagNetwork('notification', 'sendSupportNotification', { payload });
  return api.notification.sendSupportNotification(payload);
}

export default service;
