import React from 'react';
import { useSelector } from 'react-redux';
import ProductMindItem from './ProductMindItem';
import './productContents.scss';
import MindItemListItem from '../../cards/MindItemListItem';
import MindItemCard from '../../cards/MindItemCard';
import { selectUser } from '../../../reducers/user.selectors';
import { MindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import { ItemViewMode } from '../../../types/AppContent';

interface ProductMindItemListProps {
    mindItems: MindItem[] | undefined;
    viewMode: ItemViewMode;
}

type ProductMindItemsListProps = ProductMindItemListProps;

const ProductMindItemsList: React.FC<ProductMindItemsListProps> = ({ mindItems, viewMode }) => {
  const user = useSelector(selectUser);

  const userActionConfig = {
    hidePinAction: user?.hidePinAction,
    hideCollectionAction: user?.hideCollectionAction,
  };

  if (mindItems) {
    return (
      <div className={`product-content__mind-items__${viewMode}`}>
        {mindItems.map((mindItem, index) => (
          <ProductMindItem key={index} mindItemId={mindItem.id}>
            { viewMode === 'grid'
              ? <MindItemCard {...mindItem} {...userActionConfig} />
              : <MindItemListItem {...mindItem} {...userActionConfig} />
            }
          </ProductMindItem>))
        }
      </div>
    );
  }

  return null;
};

export default ProductMindItemsList;
