import React from 'react';

interface FormSelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  containerClassName?: string;
  onChoose?: (key: string) => void;
  id: string;
  name: string;
  title?: string;
  multiple?: boolean;
  testId?: string;
  items: Array<{
    label: string;
    value: string;
  }>;
}

const FormSelect = React.forwardRef<HTMLSelectElement, FormSelectProps>(
  ({
    containerClassName,
    id,
    name,
    title,
    items,
    onChoose,
    testId,
    ...props
  }, ref) => {

    return (
      <div className={containerClassName || 'form-group'}>
        {title && <label className='form-label' htmlFor={id}>{title}</label>}
        <select
          className='form-control'
          id={id}
          name={name}
          ref={ref}
          onChange={event => onChoose && onChoose(event.target.value)}
          data-testid={testId}
          {...props}
        >
          {items.map((item) => (
            <option key={item.value} value={item.value}>{item.label}</option>
          ))}
        </select>
      </div>
    );
  });

export default FormSelect;
