import api from '../clients/api';
import { handleUnauthorized } from '../utils/apiWrapper';
import { notifyBugsnagNetwork } from '../utils/bugsnagClient';
import { 
  AddBlankPageToLernsetRequest,
  AddLernsetRequest, 
  AddPagesToLernsetRequest, 
  AddRichTextPartRequest, 
  UpdateLernsetRequest 
} from '@klab-berlin/api-sdk/lib/types/requests/Lernsets';
import services from '.';
import { Instance } from 'pspdfkit';
import { Lernset, PublicLernset } from '@klab-berlin/api-sdk/lib/types/responses/Lernsets';
import { AmplitudeTrackPayload } from '@klab-berlin/api-sdk/lib/types/requests/Track';

const service = {
  addLernset,
  addPagesToLernset,
  addBlankPage,
  createPublicLink,
  getLernsets,
  getPublicLernset,
  getPartsImages,
  deleteLernset,
  duplicateLernset,
  updateLernset,
  getLernsetPartToken,
  getLernsetDocumentUrl,
  getLernsetPartsTokens,
  rotateLernsetPart,
  trackLernset,
  addRichTextPart,
};

function addLernset(userId: string, payload: AddLernsetRequest) {
  notifyBugsnagNetwork('lernsets', 'addLernset', { userId, ...payload });
  return handleUnauthorized(() => api.lernsets.addLernset(userId, payload));
}

function getLernsets(userId: string) {
  notifyBugsnagNetwork('lernsets', 'getLernsets', { userId });
  return handleUnauthorized(() => api.lernsets.getLernsetsByOwner(userId));
}

function getPublicLernset(accessToken: string) {
  notifyBugsnagNetwork('lernsets', 'getPublicLernset', { accessToken });
  return api.lernsets.getPublicLernset(accessToken);
}

function getLernsetPartToken(lernsetId: string, partId: string) {
  notifyBugsnagNetwork('lernsets', 'getLernsetPartToken', { lernsetId, partId });
  return handleUnauthorized(() => api.lernsets.getLernsetPartToken(lernsetId, partId));
}

function getLernsetPartsTokens(lernsetId: string, accessToken?: string) {
  notifyBugsnagNetwork('lernsets', 'getLernsetPartsTokens', { lernsetId });
  return handleUnauthorized(() => api.lernsets.getLernsetPartsTokens(lernsetId, accessToken));
}

function addPagesToLernset(userId: string, lernsetId: string, payload: AddPagesToLernsetRequest) {
  notifyBugsnagNetwork('lernsets', 'addPagesToLernset', { userId, lernsetId, ...payload });
  return handleUnauthorized(() => api.lernsets.addPagesToLernset(userId, lernsetId, payload));
}

function getPartsImages(lernsetId: string, partIndices: number[], imageMaxSideLength: number, accessToken?: string) {
  notifyBugsnagNetwork('lernsets', 'getPartsImages', { lernsetId, partIndices, accessToken });
  return handleUnauthorized(() => api.lernsets.getPartsImages(lernsetId, partIndices, imageMaxSideLength, accessToken));
}

function deleteLernset(lernsetId: string) {
  notifyBugsnagNetwork('lernsets', 'deleteLernset', { lernsetId });
  return handleUnauthorized(() => api.lernsets.deleteLernset(lernsetId));
}

function duplicateLernset(lernsetId: string, partId: string) {
  notifyBugsnagNetwork('lernsets', 'duplicateLernset', { lernsetId, partId });
  return handleUnauthorized(() => api.lernsets.duplicateLernsetPart(lernsetId, partId));
}

function updateLernset(lernsetId: string, payload: UpdateLernsetRequest) {
  notifyBugsnagNetwork('lernsets', 'updateLernset', { lernsetId, payload });
  return handleUnauthorized(() => api.lernsets.updateLernset(lernsetId, payload));
}

async function getLernsetDocumentUrl(lernset: Lernset | PublicLernset, accessToken?: string) {
  notifyBugsnagNetwork('lernsets', 'getLernsetDocumentUrl', { lernset });

  // Get all part-tokens of the lernset
  const partTokens = await services.lernsets.getLernsetPartsTokens(lernset.id, accessToken);

  // Get all part-blobs of the lernset
  const partBlobs = await Promise.all(lernset.parts.map((part, index) => {
    // Return an empty blob for the first part (as we don't need it)
    if (index === 0) return Promise.resolve(new Blob());

    const config = {
      headless: true,
      documentId: part.id,
      authPayload: { jwt: partTokens[index] },
      instant: false,
    };

    return window.PSPDFKit.load(config).then((instance: Instance) => {
      return instance.exportPDF()
        .then((documentBuffer) => 
          new Blob([documentBuffer], { type: 'application/pdf' })
        ).finally(() => {
          window.PSPDFKit.unload(instance);
        });
    });
  }));

  // Merge the part blobs into a single document
  const config = {
    headless: true,
    documentId: lernset.parts[0].id,
    authPayload: { jwt: partTokens[0] },
    instant: false,
  };

  const operations: any[] = partBlobs.slice(1).map((blob, index) => {
    return {
      type: 'importDocument',
      afterPageIndex: index,
      document: blob
    };
  });
  operations.push({ type: 'updateMetadata', metadata: { title: lernset.title, author: '' } });
  operations.push({ type: 'flattenAnnotations' });

  const instance: Instance = await window.PSPDFKit.load(config);
  const documentBuffer = await instance.exportPDFWithOperations(operations);

  const documentBlob = new Blob([documentBuffer], { type: 'application/pdf' });
  const documentUrl = URL.createObjectURL(documentBlob);
  window.PSPDFKit.unload(instance);

  return documentUrl;
}

function addBlankPage(lernsetId: string, payload: AddBlankPageToLernsetRequest) {
  notifyBugsnagNetwork('lernsets', 'addBlankPage', { lernsetId, ...payload });
  return handleUnauthorized(() => api.lernsets.addBlankPageToLernset(lernsetId, payload));
}

async function rotateLernsetPart(lernsetId: string, partId: string, partIndex: number) {
  const partsTokens = await services.lernsets.getLernsetPartsTokens(lernsetId);

  const config = {
    headless: true,
    documentId: partId,
    authPayload: { jwt: partsTokens[partIndex] },
    instant: false,
  };

  const instance: Instance = await window.PSPDFKit.load(config);
  await instance.applyOperations([
    {
      type: 'rotatePages',
      pageIndexes: [0],
      rotateBy: 90
    }
  ]);

  window.PSPDFKit.unload(instance);
}

function createPublicLink(lernsetId: string) {
  notifyBugsnagNetwork('lernsets', 'createPublicLink', { lernsetId });
  return handleUnauthorized(() => api.lernsets.createPublicLink(lernsetId));
}

function trackLernset(lernsetId: string, payload: AmplitudeTrackPayload, accessToken?: string) {
  notifyBugsnagNetwork('lernsets', 'trackLernset', { lernsetId, payload, accessToken });
  return handleUnauthorized(() => api.lernsets.trackLernset(lernsetId, payload, accessToken));
}

function addRichTextPart(lernsetId: string, payload: AddRichTextPartRequest) {
  notifyBugsnagNetwork('lernsets', 'addRichTextPart', { lernsetId, payload });
  return handleUnauthorized(() => api.lernsets.addRichtextPart(lernsetId, payload));
}

export default service;
