import { SearchResponseData } from '@klab-berlin/api-sdk/lib/types/responses/Common';
import { MindItemSearchResult, Topic } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import { ProductSearchResult } from '@klab-berlin/api-sdk/lib/types/responses/Product';
import { SearchFilters } from '../types/search';
import { AsyncAction } from './common.actionTypes';
import { Pagination } from '../types/search';
import { WorkloadListResponse, TeachingFormatListResponse } from '@klab-berlin/api-sdk/lib/types/responses/Text';

export enum SEARCH_MIND_ITEMS {
  START = 'SEARCH_MIND_ITEMS_START',
  SUCCESS = 'SEARCH_MIND_ITEMS_SUCCESS',
  ERROR = 'SEARCH_MIND_ITEMS_ERROR',
}

export enum SEARCH_VIDEO_MIND_ITEMS {
  START = 'SEARCH_VIDEO_MIND_ITEMS_START',
  SUCCESS = 'SEARCH_VIDEO_MIND_ITEMS_SUCCESS',
  ERROR = 'SEARCH_VIDEO_MIND_ITEMS_ERROR',
}

export enum SEARCH_PRODUCTS {
  START = 'SEARCH_PRODUCTS_START',
  SUCCESS = 'SEARCH_PRODUCTS_SUCCESS',
  ERROR = 'SEARCH_PRODUCTS_ERROR',
}

export enum SEARCH_UNIFIED {
  START = 'SEARCH_UNIFIED_START',
  SUCCESS = 'SEARCH_UNIFIED_SUCCESS',
  ERROR = 'SEARCH_UNIFIED_ERROR',
}

export enum SEARCH_TOPICS {
  START = 'SEARCH_TOPICS_START',
  SUCCESS = 'SEARCH_TOPICS_SUCCESS',
  ERROR = 'SEARCH_TOPICS_ERROR',
}

export enum FETCH_WORKLOADS {
  START = 'FETCH_WORKLOADS_START',
  SUCCESS = 'FETCH_WORKLOADS_SUCCESS',
  ERROR = 'FETCH_WORKLOADS_ERROR',
}

export enum FETCH_TEACHING_FORMATS {
  START = 'FETCH_TEACHING_FORMATS_START',
  SUCCESS = 'FETCH_TEACHING_FORMATS_SUCCESS',
  ERROR = 'FETCH_TEACHING_FORMATS_ERROR',
}

export type SearchMindItemsAction = AsyncAction<
  SEARCH_MIND_ITEMS.START,
  SEARCH_MIND_ITEMS.SUCCESS,
  SEARCH_MIND_ITEMS.ERROR,
  SearchResponseData<MindItemSearchResult>,
  { filters: SearchFilters; pagination: Pagination }
>;

export type SearchVideoMindItemsAction = AsyncAction<
  SEARCH_VIDEO_MIND_ITEMS.START,
  SEARCH_VIDEO_MIND_ITEMS.SUCCESS,
  SEARCH_VIDEO_MIND_ITEMS.ERROR,
  SearchResponseData<MindItemSearchResult>,
  { filters: SearchFilters; pagination: Pagination }
>;

export type SearchProductsAction = AsyncAction<
  SEARCH_PRODUCTS.START,
  SEARCH_PRODUCTS.SUCCESS,
  SEARCH_PRODUCTS.ERROR,
  SearchResponseData<ProductSearchResult>,
  { filters: SearchFilters; pagination: Pagination }
>;

export type SearchUnifiedAction = AsyncAction<
  SEARCH_UNIFIED.START,
  SEARCH_UNIFIED.SUCCESS,
  SEARCH_UNIFIED.ERROR,
  SearchResponseData<MindItemSearchResult|ProductSearchResult>,
  { filters: SearchFilters; pagination: Pagination }
>;

export type SearchTopicsAction = AsyncAction<
  SEARCH_TOPICS.START,
  SEARCH_TOPICS.SUCCESS,
  SEARCH_TOPICS.ERROR,
  Topic[],
  { subject: string }
>;

export type FetchWorkloadsAction = AsyncAction<
  FETCH_WORKLOADS.START,
  FETCH_WORKLOADS.SUCCESS,
  FETCH_WORKLOADS.ERROR,
  WorkloadListResponse
>;

export type FetchTeachingFormatsAction = AsyncAction<
  FETCH_TEACHING_FORMATS.START,
  FETCH_TEACHING_FORMATS.SUCCESS,
  FETCH_TEACHING_FORMATS.ERROR,
  TeachingFormatListResponse
  >;
