import React from 'react';
import ItemMetadata from '../ItemMetadata';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import { ContentItem } from '../../types/AppContent';
import BackgroundImage from '../BackgroundImage';
import PublisherImage from '../PublisherImage';

interface Props {
  item: ContentItem;
}

const MinimalItem: React.FC<Props> = (props) => {
  return (
    <Card className='item item--card text-dark text-decoration-none'>
      <div className='item-header'>
        <BackgroundImage
          className='item--header-image'
          url={props.item.thumbnail}
        />
      </div>
      <Card.Body
        className={'pt-2 pb-2 px-3'}
      >
        <Row className='align-items-center'>
          <Col>
            <div className='item__title font-weight-bold'>{props.item.title}</div>
          </Col>
          <Col
            xs={1}
            className='rounded-circle px-0 justify-content-center align-items-center mr-2'
          >
            <PublisherImage className='publisher-logo' publisher={props.item.publisher} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className='pt-2'>
            <ItemMetadata
              schoolTypes={props.item.schoolTypes}
              subjects={props.item.subjects}
              classYears={props.item.classYears}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default MinimalItem;
