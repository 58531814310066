import React from 'react';
import classNames from 'classnames';
import Icon from '../../../Icon';
import './playIcon.scss';

interface PlayIconProps {
  className?: string;
  testId?: string;
  isSmall?: boolean; 
}

const PlayIcon: React.FC<PlayIconProps> = (props) => {
  const { className, testId, isSmall } = props; 

  return (
    <div 
      role='img'
      aria-label='Play button'
      className={classNames('play-icon', className, { 'play-icon-small': isSmall  })}
      data-testid={testId}>
      <Icon
        icon='play'
        className='play-icon__icon'
      />
    </div>
  );

};

export default PlayIcon;
