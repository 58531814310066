import classNames from 'classnames';
import React from 'react';
import './backgroundImage.scss';

export type BackgroundImageMode = 'contain' | 'cover';

interface ImageProps {
  url?: string;
  className?: string;
  testId?: string;
  mode?: BackgroundImageMode;
}

const BackgroundImage: React.FC<ImageProps> = ({ mode = 'contain', testId, ...props }) => (
  <div 
    className={classNames(props.className, 'url-background-image', {
      'url-background-image__contain': mode === 'contain',
      'url-background-image__cover': mode === 'cover',
    })}
    // eslint-disable-next-line react/forbid-dom-props
    style={{ backgroundImage: `url("${props.url}")` }}
    data-testid={testId}
  >
    {props.children}
  </div>
);

export default BackgroundImage;
