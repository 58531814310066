import { decodeToken } from '../../utils/jwt';
import useRouter from '../../utils/useRouter';

export const getLicenceIdFromUrl = () => {
  const { match: { location: { query } } } = useRouter();
  const token = query.data;
  let licenceIdFromToken;
  try {
    licenceIdFromToken = token && decodeToken(token).licenceId;
  } catch (error) {
    licenceIdFromToken = undefined;
  }
  return parseInt(licenceIdFromToken || query.licenceId || '132');
};
