import React from 'react';
import { Nav } from 'react-bootstrap';
import './muTabs.scss';

interface OwnProps {
  tabs: Tab[];
}

type Tab = {
  key: string;
  name: string;
};

const MuTabs: React.FC<OwnProps> = (props) => {
  return (
    <Nav className='mu-tabs__container' variant='tabs'>
      {props.tabs.map(tab => {
        const navItem = (
          <Nav.Item className='mu-tabs__tab-item' key={tab.key}>
            <Nav.Link
              className='mu-tabs__tab-item-link'
              eventKey={tab.key}
              data-testid={`tabs__${tab.key}`}
            >
              {tab.name}
            </Nav.Link>
          </Nav.Item>
        );
        return navItem;
      })}
    </Nav>
  );
};

export default MuTabs;
