import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../common/Button';
import './personalDetailsActions.scss';

interface OwnProps {
  showError: boolean;
  errorMessage?: string;
  onCancel: () => void;
  onSubmit?: () => void;
}

const PersonalDetailsActions: React.FC<OwnProps> = (props) => {
  
  const { t } = useTranslation();
  return (
    <>
      <div>
        <Button
          type='submit'
          onClick={() => props.onSubmit && props.onSubmit()}
          className='personal-details-actions__save-button'
        >
          {t('Save')}
        </Button>
        <Button variant='ghost' onClick={() => props.onCancel()}>{t('Cancel')}</Button>
      </div>
      {props.showError &&
        <div className='personal-details-actions__error'>
          {props.errorMessage || t('Ups, something went wrong')}
        </div>
      }
    </>
  );
};

export default PersonalDetailsActions;
