import React from 'react';
import ExclusiveContentOverlay from '../ReaderPagesBasis/NewBasisReaderOverlay/ExclusiveContentOverlay';

interface VideoOverlayProps {
  onClose: () => void;
  mindItemId: string;
}

const VideoOverlay: React.FC<VideoOverlayProps> = (props) => {
  return <ExclusiveContentOverlay
    category='video'
    trackingObject={{ minditem_id: props.mindItemId }}
    onClose={props.onClose} />;
};

export default VideoOverlay;
