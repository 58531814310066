/**
 * Splits an array into chunks of a specified size.
 * 
 * @param array - The array to be split.
 * @param chunkSize - The size of each chunk.
 * @returns An array of chunks.
 */
export function splitArrayIntoChunks(array: any[], chunkSize: number) {
  const chunkedArray = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunkedArray.push(array.slice(i, i + chunkSize));
  }
  return chunkedArray;
}
