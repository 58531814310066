import React, { useState } from 'react';
import { routes } from '../constants';
import GradientModalAppLayout from '../components/AppLayout/GradientModalLayout';
import CreateProfileFrame from '../components/CreateProfileFrame';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateMe } from '../actions/user.actions';
import IsTraineeSelection from '../components/Profile/IsTraineeSelection';
import ClassesSelection from '../components/Profile/ClassesSelection';
import services from '../services';
import { selectUser } from '../reducers/user.selectors';
import useAction from '../utils/useAction';
import useRouter from '../utils/useRouter';
import { ClassesSelected } from '../ampli';
interface ClassesUpdateRequest {
  classes?: number[],
  isTrainee?: boolean,
}

const CreateProfileClassesPage: React.FC = () => {
  const user = useSelector(selectUser);
  const { router } = useRouter();
  const { t } = useTranslation();
  const updateMeAction = useAction(updateMe);
  const initialValues = {
    isTrainee: user && user.isTrainee || false,
    classes: user && user.classes || [],
  };

  const [isTrainee, setTrainee] = useState<boolean|undefined>(initialValues.isTrainee);
  const [classes, setClasses] = useState<number[]>(initialValues.classes);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const chooseSchoolTypeUrl = `/${routes.createProfile.root}/${routes.createProfileSchoolType.root}`;
  const chooseSubjectsUrl = `/${routes.createProfile.root}/${routes.createProfileSubjects.root}`;

  const nextAction = () => {
    setIsSubmitting(true);

    if (user) {
      services.track.ampliEventTrack({
        event: new ClassesSelected(), 
        userProperties: {
          id: user.id,
          isTrainee,
          classes
        },
      });
    }

    const updateObject: ClassesUpdateRequest = {};
    if (classes.length > 0) {
      updateObject.classes = classes;
    }
    if (isTrainee !== undefined) {
      updateObject.isTrainee = isTrainee;
    }

    updateMeAction(updateObject)
      .then(() => {
        router.push(chooseSchoolTypeUrl);
      })
      .catch(() => {
        setIsSubmitting(false);
      });
  };

  const backAction = () => {
    router.push(chooseSubjectsUrl);
  };

  const frameProps = {
    progressBar: {
      numberOfSteps: 4,
      currentStep: 3,
    },
    next: {
      action: nextAction,
      text: t('Next'),
      disabled: isSubmitting,
    },
    back: {
      action: backAction,
      disabled: isSubmitting,
      text: t('Back'),
    }
  };

  return (
    <GradientModalAppLayout>
      <CreateProfileFrame {...frameProps}>
        <div className='p-4 profile__classes-page'>
          <IsTraineeSelection value={isTrainee} onChange={setTrainee} />
          <ClassesSelection value={classes} onChange={setClasses} />
        </div>
      </CreateProfileFrame>
    </GradientModalAppLayout>
  );
};

export default CreateProfileClassesPage;
