import React from 'react';
import { useTranslation } from 'react-i18next';
import './sortByFilter.scss';
import { SortByOption } from '@klab-berlin/api-sdk/lib/types/requests/Common';
import Icon from '../../Icon';
import { Select } from '../../common/Select';
import { BasicFilterProps } from '../../../types/search';
import { noop } from 'lodash';
import { getSortFilterOptions } from '../SortByFilterOptions';

const SortByFilter: React.FC<BasicFilterProps<SortByOption>> = (
  { currentValue, updateFilter, clearFilter = noop, onToggle, onItemClick }
) => {
  const { t } = useTranslation();
  return (
    <Select
      onSelectionConfirm={([sortByValue]) => {
        updateFilter(sortByValue);
      }}
      onSelectionClear={() => {
        clearFilter();
      }}
      icon={<Icon icon='sort' className='sort-by-filter__icon' />}
      defaultValue={['relevance']}
      label={t('Sort by')}
      options={getSortFilterOptions()}
      showClearButton={false}
      value={currentValue ? [currentValue] : ['relevance']}
      onToggle={onToggle}
      onItemClick={onItemClick}
      hideIconOnMobile={true}
      hasButtons={false}
      align='right'
      alwaysShowLabel={false}
      data-testid='zero-results-old-ui'
    />
  );
};

export default SortByFilter;
