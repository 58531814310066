import React from 'react';
import { CarouselTrackingProperties } from '../../../types/AppContent';
import Carousel from '../../Carousel/Carousel';
import { SlideDefinition } from '../../Carousel/Carousel';
import DashboardCarouselFooter from './DashboardCarouselFooter';
import DashBoardCarouselHeader from './DashboardCarouselHeader';
import './dashboardCarousel.scss';
import classnames from 'classnames';

interface DashboardCarouselProps extends CarouselTrackingProperties {
  className?: string;
  infinite?: boolean;
  slides: React.ReactNode[];
  numberOfSlides: SlideDefinition;
  title?: string;
  uri?: string;
  isLoading?: boolean;
  skeleton?: React.ReactNode;
}

const DashboardCarousel: React.FC<DashboardCarouselProps> = (props) => {

  if (props.isLoading) {
    return (
      <div className='dashboard-carousel__container'>
        {props.skeleton}
      </div>
    );
  }

  if (props.slides.length === 0) {
    return null;
  }

  return (
    <div className='dashboard-carousel__container'>
      {props.title &&
        <DashBoardCarouselHeader
          title={props.title}
          uri={props.uri}
        />
      }
      <Carousel
        className={classnames('dashboard-carousel__carousel', props.className)}
        infinite={props.infinite}
        slides={props.slides}
        numberOfSlides={props.numberOfSlides}
      />
      {props.uri &&
        <DashboardCarouselFooter
          uri={props.uri}
        />
      }
    </div>
  );
};

export default DashboardCarousel;
