import React from 'react';
import classnames from 'classnames';
import useRouter from '../../../utils/useRouter';
import Icon from '../../Icon';
import WithTooltip from '../../WithTooltip';
import { useTranslation } from 'react-i18next';

interface TopBarHistoryNavigationProps {
  className?: string;
}

const TopBarHistoryNavigation: React.FC<TopBarHistoryNavigationProps> = (props) => {
  const { router, match } = useRouter();
  const { t } = useTranslation();

  const canMoveBackwards = match.location.index > 0;

  const navigate = (delta: number) => router.go(delta);

  return (
    <span className={props.className}>
      <WithTooltip text={t('Back')} placement='bottom'>
        <button
          className={classnames(
            'btn-none focus--very-light-blue',
            { 'color--white': canMoveBackwards, 'color--grey-light': !canMoveBackwards }
          )}
          disabled={!canMoveBackwards}
          onClick={() => navigate(-1)}
          data-testid='top-bar-history-navigation-back'
        >
          <Icon size='lg' icon='arrow-small-left' />
        </button>
      </WithTooltip>
      <WithTooltip text={t('Forward')} placement='bottom'>
        <button
          className='btn-none focus--very-light-blue color--white'
          onClick={() => navigate(1)}
          data-testid='top-bar-history-navigation-forward'
        >
          <Icon size='lg' icon='arrow-small-right' />
        </button>
      </WithTooltip>
    </span>
  );
};

export default TopBarHistoryNavigation;
