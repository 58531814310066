import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DownloadModalContext } from '../../../../../../state/DownloadModalProvider';
import NewReaderOverlay from '../../../NewReaderOverlay';
import { ReaderContext } from '../../../../ReaderContext';
import { WatermarkTrackingObject } from '../NewBasisReaderOverlay';
import UnlockPremium from '../UnlockPremium';
import Button from '../../../../../common/Button';

interface DownloadInfoOverlayProps {
  downloadsRemaining: number;
  trackingObject: WatermarkTrackingObject;
}

const DownloadInfoOverlay: React.FC<DownloadInfoOverlayProps> = (props) => {
  const { t } = useTranslation();
  const { show: openDownloadModal } = useContext(DownloadModalContext);
  const downloadsRemaining = Math.max(0, props.downloadsRemaining);
  const { isReadOnly, mindItemId } = useContext(ReaderContext);

  const bodyText = isReadOnly
    ? t('This content can be read exclusively by premium users.')
    : <Trans i18nKey='As a basis user, you can still download downloadsRemaining documents' values={{
      downloadsRemaining
    }}>
      As a basis user, you can still <strong>download downloadsRemaining documents</strong>
    </Trans>;

  const DownloadDocumentButton = () => (
    <Button
      onClick={() => openDownloadModal(mindItemId)}
      variant='black'
      emoji='📁'
      className='new-reader-overlay__button'
    >
      {t('Document download')}
    </Button>
  );

  return (
    <NewReaderOverlay emoji='📁' title={isReadOnly ? t('Read Only Content') : t('Document download')}>
      <NewReaderOverlay.BodyText>
        {bodyText}
      </NewReaderOverlay.BodyText>
      {isReadOnly
        ? <UnlockPremium text={t('Discover meinUnterricht Premium')} emoji='👀' {...props.trackingObject} />
        : <DownloadDocumentButton />}
    </NewReaderOverlay>
  );
};

export default DownloadInfoOverlay;
