import { ZuoraPaymentMethod } from '@klab-berlin/api-sdk/lib/services/billing';
import { PaymentMethod } from '@klab-berlin/api-sdk/lib/types/requests/User';

export const getPaymentMethodData = (type: ZuoraPaymentMethod, token: string): PaymentMethod => {
  let paymentMethod: PaymentMethod;
  if (type === 'creditCard') {
    paymentMethod = {
      type: 'creditCard',
      auth: {
        type: 'zuora',
        token,
      }
    };
  } else if (type === 'sepa') {
    paymentMethod = {
      type: 'sepa',
      auth: {
        type: 'zuora',
        token,
      }
    };
  } else if (type === 'braintree') {
    paymentMethod = {
      type: 'paypal',
      auth: {
        type: 'braintree',
        token,
      }
    };
  } else {
    throw TypeError('Payment method is wrong');
  }
  return paymentMethod;
};

export const isZuoraPaymentObjectDefined = () => !!window.Z;

export const isEveryPayPalPaymentObjectDefined = () =>
  !!window.braintree?.client 
  && !!window.braintree?.paypalCheckout
  && !!window.paypal?.Button;
