import api from '../clients/api';
import { notifyBugsnagNetwork } from '../utils/bugsnagClient';

const service = {
  getUpgradeCode
};

function getUpgradeCode(code: string) {
  notifyBugsnagNetwork('configuration', 'getUpgradeCode', { code });
  return api.configuration.getUpgradeCode(code);
}

export default service;
