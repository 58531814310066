import classNames from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Icon from '../../Icon';
import './newReaderOverlay.scss';

interface NewReaderOverlayProps {
  children?: React.ReactNode | React.ReactNode[];
  alignment?: string;
  emoji: string;
  title: string;
  onClose?: () => void;
}

const NewReaderOverlayBodyText: React.FC = (props) => (
  <div className='new-reader-overlay__body-text'>
    {props.children}
  </div>
);

const NewReaderOverlay: React.FC<NewReaderOverlayProps> = (
  { children, emoji, alignment, title, onClose }) => {
  const { t } = useTranslation();

  return (
    <div className={
      classNames('new-reader-overlay-container', {
        'new-reader-overlay-container--baseline': alignment === 'baseline' })}>
      <div className='new-reader-overlay'>
        <div className='new-reader-overlay__header'>
          <div className='new-reader-overlay__header-emoji'>
            {emoji}
          </div>
          <div className='new-reader-overlay__header-text'>
            {title}
          </div>
        </div>
        {onClose &&
          <Button
            className='new-reader-overlay__close-button'
            onClick={onClose}
            size='lg'
            aria-label={t('Close')}
            data-testid='new-reader-overlay__cancel-button'>
            <Icon icon='cancel' />
          </Button>
        }
        <div className='new-reader-overlay__body'>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Object.assign(NewReaderOverlay, {
  BodyText: NewReaderOverlayBodyText
});
