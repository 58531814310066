import React, { useEffect, useState, useRef, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import config from 'config';
import useRouter from '../../../utils/useRouter';
import { onboardingHints, routes, sharingLinks } from '../../../constants';
import LernsetsMasterLayout from '../../../components/lernsets/LernsetsMasterLayout';
import { getLastAnnotatedPartId, getLernsetById, getLernsetsStatus } from '../../../reducers/lernsets.selectors';
import { useSelector } from 'react-redux';
import useAction, { useSyncAction } from '../../../utils/useAction';
import { getLernsets, updateLernset, duplicateLernset,
  fetchPartImages as fetchPartImagesAction,
  setLastAnnotatedPartId as setLastAnnotatedPartIdAction, 
  addBlankPageToLernset,
  addRichTextPart as addRichTextPartAction,
} from '../../../actions/lernsets.actions';
import PageCard from '../../../components/lernsets/PageCard';
import { Box, SvgIcon, Button, Typography, Stack, ButtonOwnProps, CircularProgress } from '@mui/material';
import { palette, spaces } from '../../../assets/styles/themes/tokens';
import printer from '../../../assets/lernsets/icons/printer.svg';
import downloader from '../../../assets/lernsets/icons/download.svg';
import add from '../../../assets/lernsets/icons/add.svg';
import emptyStateSvg from '../../../assets/lernsets/empty-state.svg';
import { splitArrayIntoChunks } from '../../../utils/splitArrayIntoChunks';
import { ArrowDirection } from '../../../components/lernsets/PageCard/PageCardControls';
import ConfirmDialog from '../../../components/lernsets/ConfirmDialog';
import openPrintDialogWithFile from '../../../utils/openPrintDialogWithFile';
import services from '../../../services';
import { getAmpliContentData, useAmplitudeExperiment } from '../../../utils/ampli';
import { selectUser } from '../../../reducers/user.selectors';
import { 
  SetCardMoved, 
  SetOpened,
  SetCardDeleted, 
  SetCardDuplicated, 
  LernsetsContentAdded, 
  SharingModalOpened,
  SetShared
} from '../../../ampli';
import { SnackbarContext } from '../../../state/SnackbarContextProvider';
import { AsyncActionStatus } from '../../../actions/common.actionTypes';
import { downloadFile, trackDownloadedEvent, trackPrintedEvent } from '../../../utils/lernsets';
import { Part } from '../../../reducers/lernsets.reducers';
import AddMaterialBetweenParts from '../../../components/lernsets/AddMaterialBetweenParts';
import ShareDialog from '../../../components/lernsets/ShareDialog';
import share from '../../../assets/lernsets/icons/share.svg';
import { OnboardingHint } from '../../../components/OnboardingHint';
import GenerateWorksheetDialog from '../../../components/lernsets/GenerateWorksheetDialog';

type SharingMethod = 'qr code' | 'link'; 

const LernsetsOverviewPage: React.FC = () => {
  const { t } = useTranslation();
  const { openSnackbar } = useContext(SnackbarContext);
  const user = useSelector(selectUser);
  const {
    variant: lernsetVariant,
    isReady: isClientReady,
  } = useAmplitudeExperiment(user, config.amplitudeExperiments.enableLearnsetsFeature);
  const isLernsetsActive = isClientReady && lernsetVariant === 'on';
  const { router, match: { params: { lernsetId } } } = useRouter();
  const getUserLernsets = useAction(getLernsets);
  const updateLernsetAction = useAction(updateLernset);
  const duplicateLernsetPart = useAction(duplicateLernset);
  const addBlankPage = useAction(addBlankPageToLernset);
  const [currentDuplicatingPartId, setCurrentDuplicatingPartId] = useState<string | null>(null);
  const [currentRotatingCardId, setCurrentRotatingCardId] = useState<string | null>(null);
  const fetchPartsImagesFromAPI = useAction(fetchPartImagesAction);
  const setLastAnnotatedPartId = useSyncAction(setLastAnnotatedPartIdAction);
  const addRichTextPart = useAction(addRichTextPartAction);
  const lastAnnotatedPartId = useSelector(getLastAnnotatedPartId);
  const lernsetActionStatus = useSelector(getLernsetsStatus);
  const lernset = useSelector(state => getLernsetById(state, lernsetId));
  const lernsetParts = lernset?.parts;
  const lernsetTitle = lernset?.title;   
  const [isScrollLocked, setIsScrollLocked] = useState(false);
  const [shouldScrollToCard, setShouldScrollToCard] = useState(false);
  const [scrollToElement, setScrollToElement] = useState<HTMLDivElement | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEmptyState, setIsEmptyState] = useState(false);
  const [areCardsVisible, setAreCardsVisible] = useState(true);
  const partIdToDelete = useRef<string | null>(null);
  const [isPrintDisabled, setIsPrintDisabled] = useState(false);
  const [isCreatingBlankPage, setIsCreatingBlankPage] = useState(false);
  const [isDownloadDisabled, setIsDownloadDisabled] = useState(false);
  const [shouldScrollToElementAfter, setShouldScrollToElementAfter] = useState<string | null>(null);
  const [highlightedPartId, setHighlightedPartId] = useState<string | null>(null);
  const setOpenedEventSent = useRef(false);
  const fetchImagesChunkSize = 5;
  const isLernsetReady = lernsetActionStatus === AsyncActionStatus.READY;
  const pageCardIdPrefix = 'part-';
  const [isPublicLinkDialogOpen, setIsPublicLinkDialogOpen] = useState(false);
  const [publicLink, setPublicLink] = useState('');
  const [isCreatingPublicLink, setIsCreatingPublicLink] = useState(false);
  const [isGenerateWorksheetDialogOpen, setIsGenerateWorksheetDialogOpen] = useState(false);

  const pdfParts = useMemo(() => {
    return lernsetParts?.filter((part) => part.type === 'pdf');
  }, [lernsetParts]);

  const areAllPartsImagesReady = useMemo(() => {
    return pdfParts && pdfParts.every((part) => part.imageUrl !== undefined);
  }, [pdfParts]);

  const hasInitialPartToRender = useMemo(() => {
    if (!pdfParts?.length) return true;
    return pdfParts.some((part) => part.imageUrl !== undefined);
  }, [pdfParts]);

  useEffect(() => {
    if (user?.id) getUserLernsets();
  }, [user?.id]);

  useEffect(() => {
    const onMouseDown = () => {
      setHighlightedPartId(null);
    };

    document.addEventListener('mousedown', onMouseDown);

    return () => {
      document.removeEventListener('mousedown', onMouseDown);
    };
  }, []);

  useEffect(() => {
    if (areAllPartsImagesReady && lastAnnotatedPartId) {
      setAreCardsVisible(false);
      // Scroll to the last annotated part and fetch its updated image
      // Using Timeout as the element will exist only after the render (on the next tick)
      setTimeout(() => {
        const element = document.querySelector(`#${pageCardIdPrefix}${lastAnnotatedPartId}`) as HTMLDivElement;
        if (element) {
          element.scrollIntoView({ behavior: 'instant' });
          const partIndex = lernsetParts.findIndex(part => part.id === lastAnnotatedPartId);
          fetchPartsImagesFromAPI(lernsetId, [partIndex]);
        }
        setLastAnnotatedPartId('');
        setAreCardsVisible(true);
      }, 1);
    }
  }
  , []);

  useEffect(() => {
    if (isLernsetReady) {
      setIsEmptyState(!lernsetParts?.length);
    }
  }, [isLernsetReady, lernsetParts]);

  useEffect(() => {
    if (lernset && !setOpenedEventSent.current) {
      // Track the set opened event
      services.track.ampliEventTrack({ 
        event: new SetOpened({
          'lernset id': lernsetId,
          'lernset title': lernsetTitle,
        })
      });
    
      setOpenedEventSent.current = true;
    }
  }, [lernset]);

  // If `shouldScrollToCard` and `scrollToElement` are set, we should scroll to desired element
  useEffect(() => {
    if (shouldScrollToCard && scrollToElement) {
      scrollToElement.scrollIntoView({ behavior: 'smooth' });
      setIsScrollLocked(false);
      setShouldScrollToCard(false);
      setScrollToElement(null);
    }
  }, [shouldScrollToCard, scrollToElement]);

  // If `lernsetParts` were updated and the scroll is locked, we should scroll away
  useEffect(() => {
    if (isScrollLocked) setShouldScrollToCard(true);
  }, [lernsetParts]);

  const fetchPartsImages = async (parts: Part[], chunkSize: number) => {
    const partIndices = parts.reduce((indices: number[], part: Part, index: number) => {
      if (part.type === 'pdf') indices.push(index);
      return indices;
    }, []);
  
    // Split partIndices into chunks
    const chunkedPartIndices: number[][] = splitArrayIntoChunks(partIndices, chunkSize);

    // Fetch parts-images of the lernset in chunks
    for (const chunk of chunkedPartIndices) {
      await fetchPartsImagesFromAPI(lernsetId, chunk);
    }
  };

  const trackSetCardMoved = () => {
    services.track.ampliEventTrack({ 
      event: new SetCardMoved({
        'lernset id': lernsetId,
        'lernset title': lernsetTitle,
      })
    });
  };

  const movePageCard = async (partId: string, direction: ArrowDirection, cardRef: HTMLDivElement) => {
    setIsScrollLocked(true);
    setScrollToElement(cardRef);
    await updateLernsetAction(lernsetId, { action: 'movePart', partId, direction  });
    trackSetCardMoved();
  };

  const trackSetCardDeleted = () => {
    services.track.ampliEventTrack({
      event: new SetCardDeleted({
        'lernset id': lernsetId,
        'lernset title': lernsetTitle,
      })
    });
  };

  const deletePageCard = async () => {
    try {
      if (partIdToDelete?.current) {
        await updateLernsetAction(lernsetId, { action: 'deletePart', partId: partIdToDelete.current });
        trackSetCardDeleted();
      }
  
      partIdToDelete.current = null;
      setIsDeleteModalOpen(false);
      openSnackbar({
        type: 'success',
        message: t('The material has been deleted successfully!'),
      });
    } catch (error) {
      openSnackbar({
        type: 'error',
        message: t('The material could not be deleted. Please try again.'),
      });
    } 
  };

  const getFollowingPartId = (partId: string) => {
    const partIndex = lernsetParts.findIndex(part => part.id === partId);
    if (partIndex === -1) return null;

    const followingPart = lernsetParts[partIndex + 1];
    return followingPart?.id;
  };

  const trackSetCardDuplicated = () => {
    services.track.ampliEventTrack({ 
      event: new SetCardDuplicated({
        'lernset id': lernsetId,
        'lernset title': lernsetTitle,
      })
    });
  };

  const duplicatePageCard = async (lernsetId: string, partId: string) => {
    setCurrentDuplicatingPartId(partId);
    try {
      await duplicateLernsetPart(lernsetId, partId);
      setShouldScrollToElementAfter(partId);
      trackSetCardDuplicated();
    } catch (error: any) {
      if (error.statusCode === 400 && error.data?.code === 'PAGE_LIMIT_EXCEEDED') {
        openSnackbar({
          type: 'error',
          message: t('You can add a limited number of pages to a lernset.', {
            partsLengthLimit: error.data?.data?.partsLengthLimit
          }),
        });
      }
    }
    setCurrentDuplicatingPartId(null);
  };

  const trackLernsetsContentAdded = () => {
    const eventProperties = {
      content: getAmpliContentData({}, 'blankPage') as any,
      'lernset id': lernsetId,
      'lernset title': lernsetTitle,
      'page amount': 1,
    };

    services.track.ampliEventTrack({ 
      event: new LernsetsContentAdded(eventProperties)
    });
  };
  
  const rotateCard = async (lernsetId: string, partId: string, partIndex: number) => {
    setCurrentRotatingCardId(partId);
    await services.lernsets.rotateLernsetPart(lernsetId, partId, partIndex);
    await fetchPartsImagesFromAPI(lernsetId, [partIndex]);
    setCurrentRotatingCardId(null);
  };

  const createBlankPage = async (createAtIndex: number) => {
    try {
      setIsCreatingBlankPage(true);
      await addBlankPage(lernsetId, { partIndex: createAtIndex });
      trackLernsetsContentAdded();
      setTimeout(() => {
        setShouldScrollToElementAfter(lernsetParts[createAtIndex - 1].id);
      }, 1);
      
    } catch (error: any) {
      if (error.statusCode === 400 && error.data?.code === 'PAGE_LIMIT_EXCEEDED') {
        openSnackbar({
          type: 'error',
          message: t('You can add a limited number of pages to a lernset.', {
            partsLengthLimit: error.data?.data?.partsLengthLimit
          }),
        });
      }
    }

    setIsCreatingBlankPage(false);
  };

  const onAddRichPartClick = async (lernsetId: string, partIndex: number) => {
    try {
      await addRichTextPart(lernsetId, { partIndex, content: '' });
      setTimeout(() => {
        setShouldScrollToElementAfter(lernsetParts[partIndex - 1].id);
      }, 1);
    } catch (error: any) {
      if (error.statusCode === 400 && error.data?.code === 'PAGE_LIMIT_EXCEEDED') {
        openSnackbar({
          type: 'error',
          message: t('You can add a limited number of pages to a lernset.', {
            partsLengthLimit: error.data?.data?.partsLengthLimit
          }),
        });
      }
    }
  };

  useEffect(() => {
    if (shouldScrollToElementAfter) {
      const followingPartId = getFollowingPartId(shouldScrollToElementAfter);
      if (currentDuplicatingPartId) setHighlightedPartId(followingPartId);
      const newElement = document.querySelector(`#${pageCardIdPrefix}${followingPartId}`) as HTMLDivElement;
      newElement?.scrollIntoView({ behavior: 'smooth' });
      setShouldScrollToElementAfter(null);
    }
  }, [shouldScrollToElementAfter]);

  useEffect(() => {
    if (!lernsetParts || lernsetParts.length < 1 || hasInitialPartToRender) return;

    fetchPartsImages(lernsetParts, fetchImagesChunkSize);
  }, [lernsetParts, hasInitialPartToRender]);

  // Wait for the Amplitude client to be ready before we can render the page
  if (!isClientReady) {
    return <>Loading...</>;
  } else if (!lernsetId || !isLernsetsActive) {
    router.replace(`/${routes.notFound.root}`);
    return <></>;
  }

  const renderPageCards = () => {
    return lernsetParts?.map((part, index) => {
      const imageUrl = part.imageUrl;
      const isRichTextPart = part.type === 'markup-text' && part.content !== undefined;

      const shouldRenderCard = !!imageUrl || isRichTextPart;

      if (!shouldRenderCard) return <React.Fragment key={`empty-${part.id}`}></React.Fragment>;

      const getDisabledArrowControl = (index: number, totalParts: number) => {
        if (totalParts === 1) {
          return 'both';
        } else if (index === 0) {
          return 'up';
        } else if (index === totalParts - 1) {
          return 'down';
        } else {
          return undefined;
        }
      };

      const onNavigateToPdfEditor = (lernsetId: string, partId: string) => {
        setLastAnnotatedPartId(partId);
        const lernsetsEditorUrl = routes.lernsetsEditor.root;
        router.push(`/${lernsetsEditorUrl.replace(':lernsetId', lernsetId).replace(':partId', partId)}`);
      };

      return (
        <Box 
          key={part.id} 
          id={`${pageCardIdPrefix}${part.id}`} 
          data-testid={`lernset-part-${part.id}`}
          sx={{ position: 'relative' }}
        >
          <PageCard
            onCardMove={(direction: ArrowDirection, cardRef: HTMLDivElement) =>
              movePageCard(part.id, direction, cardRef)
            }
            imageUrl={imageUrl}
            onImageClick={() => onNavigateToPdfEditor(lernsetId, part.id)}
            onEditIconClick={() => onNavigateToPdfEditor(lernsetId, part.id)}
            onDeleteClick={() => {
              partIdToDelete.current = part.id;
              setIsDeleteModalOpen(true);
            }}
            onDuplicateClick={() => duplicatePageCard(lernsetId, part.id)}
            onRotateClick={() => rotateCard(lernsetId, part.id, index)}
            selected={false}
            disabledArrowControl={getDisabledArrowControl(index, lernsetParts.length)}
            disabled={currentDuplicatingPartId === part.id || currentRotatingCardId === part.id}
            showSpinner={currentDuplicatingPartId === part.id || currentRotatingCardId === part.id}
            highlight={highlightedPartId === part.id}
            onOpenGenerateWorksheetDialog={() => {
              setIsGenerateWorksheetDialogOpen(true);
            }}
            onAddRichTextPartClick={() => onAddRichPartClick(lernsetId, index + 1)}
            type={part.type}
            content={part.content}
          />
          <AddMaterialBetweenParts 
            label={t('Blank page')} 
            positionedAfterPartIndex={index + 1} 
            onCreateBlankPageClick={(createAtIndex) => createBlankPage(createAtIndex)} 
            isDisabled={isCreatingBlankPage}
          />
        </Box>
      );
    });
  };

  const onNewTitleSave = async (newTitle: string) => {
    await updateLernsetAction(lernsetId, { action: 'updateTitle', title: newTitle });
  };

  const onPrintClick = async () => {
    setIsPrintDisabled(true);
 
    const documentUrl = await services.lernsets.getLernsetDocumentUrl(lernset);

    openPrintDialogWithFile(documentUrl);
    URL.revokeObjectURL(documentUrl);
    setIsPrintDisabled(false);

    // Track the print event
    trackPrintedEvent(lernsetId, lernsetTitle, 'lesson overview');
  };

  const onDownloadClick = async () => {
    setIsDownloadDisabled(true);
    const documentUrl = await services.lernsets.getLernsetDocumentUrl(lernset);
    downloadFile(documentUrl, `${lernsetTitle}.pdf`);
    URL.revokeObjectURL(documentUrl);
    setIsDownloadDisabled(false);

    // Track the set downloaded event
    trackDownloadedEvent(lernsetId, lernsetTitle, 'lesson overview');
  };

  const handleShareButtonClick = async() => {
    setIsCreatingPublicLink(true);
    try {
      const { accessToken } = await services.lernsets.createPublicLink(lernsetId);
      const link = `${sharingLinks.lernsets}/${accessToken}`;
      setPublicLink(link);
      setIsPublicLinkDialogOpen(true);

      services.track.ampliEventTrack({
        event: new SharingModalOpened({
          'lernset id': lernsetId,
          'lernset title': lernsetTitle,
        })
      });
    } catch (error) {
      openSnackbar({
        type: 'error',
        message: t('The link could not be created. Please try again'),
      });
    }
    setIsCreatingPublicLink(false);
  };

  const MuMaterialButton: React.FC<{ variant: ButtonOwnProps['variant'], dataTestId: string }>
  = ({ variant, dataTestId }) => {
    return (
      <Button
        onClick={() => router.push(`/${routes.search.root}`)}
        variant={variant}
        size='large'
        color='primary'
        key='add-material-button'
        data-testid={dataTestId}
        startIcon={
          <SvgIcon 
            component={add} 
            sx={{ stroke: variant === 'contained' ? palette.primary.contrastText : palette.primary.main }}
          />
        }
      >
        {t('Add mU material')}
      </Button>
    );
  };

  const topBarButtons = <Stack direction='row' spacing={spaces.xs}>
    <Button
      onClick={onPrintClick}
      variant='text'
      size='large'
      color='primary'
      disabled={isPrintDisabled}
      key='print-button'
      startIcon={
        <SvgIcon 
          component={printer}  
          sx={{ fill: isPrintDisabled ? palette.action.disabled : palette.primary.main }} 
        />}
    >
      {t('Print')}
    </Button>
    <Button
      onClick={onDownloadClick}
      variant='outlined'
      size='large'
      color='primary'
      disabled={isDownloadDisabled}
      key='download-button'
      startIcon={
        <SvgIcon 
          component={downloader} 
          sx={{ fill: isDownloadDisabled ? palette.action.disabled : palette.primary.main }} 
        />}
    >
      {t('Download')}
    </Button>
    <OnboardingHint
      id={onboardingHints.lernsetsShareButton}
      title={t('Share sets with your students')}
      description={t('You can now easily share sets via QR code or link. Try it out now!')}
      placement='bottom-end'
      isNew={true}
      maxWidth={380}
    >
      <Button
        onClick={handleShareButtonClick}
        variant='contained'
        size='large'
        color='primary'
        disabled={isCreatingPublicLink}
        key='share-button'
        startIcon={
          <SvgIcon 
            component={share} 
            sx={{ fill: isCreatingPublicLink ? palette.action.disabled : palette.primary.contrastText }} 
          />}
      >
        {t('Share')}
      </Button>
    </OnboardingHint>
  </Stack>;

  const confirmDeleteDialog = ({ isOpen }: { isOpen: boolean}) => {
    return (
      <ConfirmDialog
        title={t('Delete Page')}
        message={t('Are you sure you want to delete the card? Deleting materials is permanent.')}
        confirmButtonText={t('Yes, delete')}  
        isOpen={isOpen}
        confirmButtonColor='error'
        onCloseModal={() => setIsDeleteModalOpen(false)}
        onConfirm={deletePageCard}
      />);
  };

  const renderShareDialog = ({ isOpen }: { isOpen: boolean}) => {
    return (
      <ShareDialog
        title={t('Share this set with your students')}
        subTitle={t('This link is available for 14 days')}
        linkUrl={publicLink}
        qrImageName={lernsetTitle}
        onClose={() => setIsPublicLinkDialogOpen(false)} 
        open={isOpen}
        onQrDownload={() => trackSetSharedEvent('qr code')}
        onLinkCopy={() => trackSetSharedEvent('link')}
      />
    );
  };

  const renderGenerateWorksheetDialog = ({ isOpen }: { isOpen: boolean}) => {
    return (
      <GenerateWorksheetDialog
        isOpen={isOpen}
        onCloseModal={() => setIsGenerateWorksheetDialogOpen(false)}
      />);
  };
  
  const renderLernsetContent = () => {
    if (!isEmptyState && hasInitialPartToRender) return (
      <>
        {renderPageCards()}
        <Box sx={{
          marginTop: spaces.xxl,
          marginBottom: spaces.xxxxl,
        }}>
          <MuMaterialButton dataTestId='add-mu-material-button-body' variant='outlined'/>
        </Box>
      </>
    );

    if (isEmptyState) return (
      <Box 
        data-testid='empty-state-container'
        sx={{
          paddingTop: spaces.xxxxl,
          display: 'flex',
          flexDirection: 'column',
          gap: spaces.xl,
          alignItems: 'center',
        }}
      >
        <SvgIcon component={emptyStateSvg} sx={{ width: 400, height: 300 }} viewBox='0 0 400 300' />
        <Typography variant='subtitle1'>{t('This set does not yet contain any materials')}</Typography>
        <MuMaterialButton dataTestId='add-mu-material-button-empty-state' variant='contained' />
      </Box>
    );

    return (
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <CircularProgress
          color='primary'
          sx={{
            '& .MuiCircularProgress-svg': {
              height: 'auto',
              width: 'auto',
            },
          }}        
        />
      </Box>
    ); 
  };

  const trackSetSharedEvent = (sharingMethod: SharingMethod) => {
    services.track.ampliEventTrack({
      event: new SetShared({
        'lernset id': lernsetId,
        'lernset title': lernsetTitle,
        'sharing method': sharingMethod,
      })
    });
  };

  return (
    <LernsetsMasterLayout 
      title={{ 
        text: lernsetTitle,
        tooltipMessage: t('Back to Dashboard'),
        isEditable: true, 
        onNewTitleSave: onNewTitleSave 
      }}
      onBackClick={() => router.push(`/${routes.learnsetsDashboard.root}`)}
      topRightComponent={!isEmptyState ? topBarButtons : undefined}
      lockContentScrolling={isScrollLocked}
    >
      <Box sx={{
        paddingTop: !isEmptyState ? spaces.lg : spaces.xxxl,
        paddingBottom: spaces.xxxxxl,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto',
        minHeight: '100%',
        visibility: areCardsVisible ? 'visible' : 'hidden',
      }}>
        {isLernsetReady && renderLernsetContent()}
        {confirmDeleteDialog({ isOpen: isDeleteModalOpen })}
        {renderGenerateWorksheetDialog({ isOpen: isGenerateWorksheetDialogOpen })}
        {renderShareDialog({ isOpen: isPublicLinkDialogOpen })}
      </Box>
    </LernsetsMasterLayout>
  );
};

export default LernsetsOverviewPage;
