import React, { useRef } from 'react';
import DropdownBody from '../common/DropdownBody';
import DropdownToggleButton from '../common/DropdownToggleButton';
import ResetButton from '../common/ResetButton';
import SaveButton from '../common/SaveButton';
import { RangeSelectDefaultProps } from '../RangeSelect';
import useRangeSelect from '../common/useRangeSelect';
import { RangeSlider, RangeSliderValues } from '../common/RangeSlider';
import RangeInputBoxes from './RangeInputBoxes';

const DropdownRangeSelect = (props: RangeSelectDefaultProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const {
    isOpen,
    savedValues,
    setSavedValues,
    open,
    close,
    getLabel,
    isDefaultState,
    handleClearSelection,
    handleSaveSelection,
    isSaveButtonDisabled,
    setIsTouched,
    potentialValues,
    setPotentialValues,
  } = useRangeSelect(props.range, props.label, props.value, props.alwaysShowLabel, wrapperRef);

  const handleToggleButtonClick = () => {
    if (isOpen) {
      close();
    }
    else {
      open();
    }
    props.onToggle && props.onToggle();
  };

  return (
    <div className='w-100 d-flex flex-column font-size-16'>
      <div className='position-relative w-100'>
        <DropdownToggleButton
          UIState={{
            isOpen,
            isSelected: !!props.value
          }}
          label={getLabel()}
          showClearIcon={!!props.value && !isOpen}
          onClearIconClick={() => handleClearSelection(props.onSelectionClear)}
          toggleButtonProps={{ onClick: handleToggleButtonClick }}
        />
      </div>
      <div>
        <div className='z-index-overlay position-absolute bg--neu-very-light-blue rounded' ref={wrapperRef}>
          {isOpen && 
            <DropdownBody
              main={
                <div className='px-3 py-2'>
                  <RangeSliderValues
                    min={potentialValues ? potentialValues.min : savedValues.min}
                    max={potentialValues ? potentialValues.max : savedValues.max}
                  />
                  <RangeSlider
                    min={props.range.min}
                    max={props.range.max}
                    values={potentialValues || savedValues}
                    onChange={setSavedValues}
                  />
                  <RangeInputBoxes
                    min={Math.min(savedValues.min, savedValues.max)} 
                    max={Math.max(savedValues.min, savedValues.max)}
                    save={setSavedValues}
                    setPotentialValues={setPotentialValues}
                    onFocus={() => setIsTouched(true)}
                  />
                </div>
              }
              footer={
                <>
                  <SaveButton
                    onClick={() => handleSaveSelection(props.onSelectionConfirm)}
                    className='mr-2'
                    disabled={isSaveButtonDisabled}
                    label={props.saveButtonLabel}
                  />
                  <ResetButton
                    onClick={() => handleClearSelection(props.onSelectionClear, false)}
                    hidden={isDefaultState}
                    label={props.resetButtonLabel}
                  />
                </>
              }
            />
          }
        </div>
      </div>
    </div >
  );
};

export default DropdownRangeSelect;
