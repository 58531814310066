import React from 'react';
import './videoMindItemCard.scss';
import useMedia from '../../../utils/useMedia';
import WithTooltip from '../../WithTooltip';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ManageItemInCollectionsButton from '../../ManageItemInCollectionsButton';
import PinItemButton from '../../PinItemButton';
import { trackingEvents } from '../../../services/tracking/trackConfig';
import WithTracking from '../../utils/WithTracking';
import CardThumbnail from '../common/CardThumbnail';
import CardDescription from '../common/CardDescription';
import ItemCardFooter from '../common/ItemCardFooter';
import Truncate from '../../Truncate';
import TagContainer from '../common/TagContainer';
import PlayIcon from '../common/PlayIcon';
import { ContentType } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import VideoNotAvailableCard from '../VideoNotAvailableCard';
import { isValidVideoItem } from '../../../utils/subdomain';

interface VideoMindItemCardProps {
  classYears: number[];
  isNew: boolean;
  description?: string;
  fileType: ContentType;
  id: string;
  isPinned: boolean;
  thumbnail: string;
  title: string;
  subjects: string[];
  publisher?: string;
  hideCollectionAction?: boolean;
  hidePinAction?: boolean;
  index?: number;
  searchEngineVersion?: number;
  schoolTypes: string[];
}

const VideoMindItemCard: React.FC<VideoMindItemCardProps> = (props) => {
  const {
    classYears,
    isNew,
    description,
    thumbnail,
    title,
    subjects,
    publisher,
    id,
    isPinned,
    hideCollectionAction,
    hidePinAction,
    index,
    searchEngineVersion,
    schoolTypes,
    fileType,
  } = props;

  const { t } = useTranslation();
  const { isCoarsePointer } = useMedia();
  const itemData = {
    id,
    title,
    subjects,
    publisher,
    classYears,
    schoolTypes,
    fileType,
  };

  const VideoMindItemCardContent = () => (
    <div className='video-mind-item-card__content' data-testid='video-mind-item-card__content'>
      <div className='video-mind-item-card__header'>
        <div className='video-mind-item-card__actions'>
          {!hideCollectionAction &&
            <WithTooltip text={t('Add to collection')} placement='left'>
              <ManageItemInCollectionsButton
                itemData={itemData}
                documentId={id}
                type='mindItem'
                iconClassName='video-mind-item-card__actions__collection'
              />
            </WithTooltip>
          }
          {!hidePinAction &&
            <WithTooltip text={isPinned ? t('Unpin') : t('Pin')} placement='left'>
              <PinItemButton
                isPinned={isPinned}
                documentId={id}
                itemData={itemData}
                type='mindItem'
                iconClassName='video-mind-item-card__actions__pin'
              />
            </WithTooltip>
          }
        </div>
        <div className='video-mind-item-card__head'>
          <div className='video-mind-item-card__title font-weight-bold'>
            <Truncate text={title} lines={3} />
          </div>
        </div>
      </div>
      <div className='video-mind-item-card__body'>
        <PlayIcon
          testId='video-icon-desktop'
          className={classNames({ 'hide-on-hover': !!description })}
        />
        <CardThumbnail
          thumbnailUrl={thumbnail}
          className={classNames({ 'hide-on-hover': !!description })}
          imageMode='cover'
        />
        {!isCoarsePointer && description &&
          <CardDescription
            description={description}
            className='show-on-hover'
            numLines={7}
          />}
      </div>
      {isCoarsePointer &&
        <CardDescription
          description={description}
          numLines={5}
        />}
      <div className='video-mind-item-card__footer'>
        <ItemCardFooter
          publisher={publisher || ''}
          subject={subjects[0]}
          classYears={classYears}
          isVideo
        />
      </div>
    </div>
  );

  return (
    <WithTracking
      events={{
        onClick: trackingEvents.itemOpen,
        onMiddleClick: trackingEvents.itemOpen,
        onContextMenu: trackingEvents.itemOpen,
      }}
      eventPayload={{ other: { 'minditem_id': id, index, searchEngineVersion } }}
    >
      <div className='video-mind-item-card' data-testid='video-mind-item-card'>
        <TagContainer isNew={isNew} />
        {
          isValidVideoItem(publisher)
            ? <VideoMindItemCardContent />
            : <VideoNotAvailableCard />
        }
      </div>
    </WithTracking>
  );
};

export default React.memo(VideoMindItemCard);
