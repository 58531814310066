import { combineReducers } from 'redux';
import collections, {
  CollectionsInitialStateType,
} from './collections.reducers';
import download, { DownloadInitialStateType } from './download.reducers';
import errors, { ErrorsState } from './errors.reducers';
import mindItems, { MindItemState } from './mindItems.reducers';
import upgradeCodes, { UpgradeCodeState } from './upgradeCodes.reducers';
import pinned, { PinnedInitialStateType } from './pinned.reducers';
import recommendation, { RecommendationInitialStateType } from './recommendation.reducers';
import products, { ProductState } from './products.reducers';
import user, { UserInitialStateType } from './user.reducers';
import dashboard, { DashboardInitialStateType } from './dashboard.reducers';
import licenceModels, {
  LicenceModelsStateType,
} from './licenceModels.reducers';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import search, { SearchInitialStateType } from './search.reducers';
import publishers, { PublisherState } from './publishers.reducers';
import schoolTypes, { SchoolTypeState } from './schoolTypes.reducers';
import lernsets, { LernsetsState } from './lernsets.reducers';

export type StoreItem<T> = {
  data?: T;
  status: AsyncActionStatus;
  error?: string;
};

export interface StoreType {
  collections: CollectionsInitialStateType;
  dashboard: DashboardInitialStateType;
  download: DownloadInitialStateType;
  errors: ErrorsState;
  licenceModels: LicenceModelsStateType;
  mindItems: MindItemState;
  upgradeCodes: UpgradeCodeState;
  pinned: PinnedInitialStateType;
  products: ProductState;
  publishers: PublisherState;
  recommendation: RecommendationInitialStateType;
  schoolTypes: SchoolTypeState;
  search: SearchInitialStateType;
  user: UserInitialStateType;
  lernsets: LernsetsState;
}

export default combineReducers<StoreType>({
  collections,
  dashboard,
  download,
  errors,
  licenceModels,
  mindItems,
  upgradeCodes,
  pinned,
  products,
  recommendation,
  schoolTypes,
  search,
  publishers,
  user,
  lernsets
});
