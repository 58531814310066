import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import config from 'config';
import useAction from '../../../utils/useAction';
import { fetchMindItem as fetchMindItemAction } from '../../../actions/mindItems.actions';
import { getLernsets } from '../../../actions/lernsets.actions';
import { selectUser } from '../../../reducers/user.selectors';
import { getLernsetById } from '../../../reducers/lernsets.selectors';
import { selectMindItem } from '../../../reducers/mindItems.selectors';
import { useAmplitudeExperiment, getAmpliContentData } from '../../../utils/ampli';
import useRouter from '../../../utils/useRouter';
import { routes } from '../../../constants';
import services from '../../../services';
import LernsetsAnnotator from '../../../components/lernsets/LernsetsAnnotator';
import { EditorOpened } from '../../../ampli';
import { MindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import { Lernset } from '@klab-berlin/api-sdk/lib/types/responses/Lernsets';
import { Box } from '@mui/material';

const LernsetsAnnotationsPage: React.FC = () => {
  const getUserLernsets = useAction(getLernsets);
  const fetchMindItem = useAction(fetchMindItemAction);
  const user = useSelector(selectUser);
  const {
    variant: lernsetVariant,
    isReady: isClientReady,
  } = useAmplitudeExperiment(user, config.amplitudeExperiments.enableLearnsetsFeature);
  const isLernsetsActive = isClientReady && lernsetVariant === 'on';

  const { router, match } = useRouter();
  const lernsetId = match.params.lernsetId;
  const partId = match.params.partId;
  const lernset = useSelector(state => getLernsetById(state, lernsetId));
  const lernsetPart = lernset?.parts.find(part => part.id === partId);
  const mindItemId = lernset?.parts.find(part => part.id === partId)?.origin?.mindItemId;
  const mindItem = useSelector(state => selectMindItem(state, mindItemId || ''));

  const trackEditorOpenedEvent = (lernset: Lernset, mindItem?: MindItem) => {
    const eventProperties = {
      content: mindItem ? getAmpliContentData(mindItem, 'mindItem') : getAmpliContentData({}, 'blankPage'),
      'lernset id': lernset.id,
      'lernset title': lernset.title,
    };

    services.track.ampliEventTrack({ 
      event: new EditorOpened(eventProperties)
    });   
  };

  // Fetch the mind item and the lernset
  useEffect(() => {
    if (lernset && mindItemId) {
      fetchMindItem(mindItemId);
    } else {
      getUserLernsets();
    }
  }, [lernset]);

  useEffect(() => {
    // Track the editor opened event
    if (isLernsetsActive && lernset) {
      if (lernsetPart && !lernsetPart?.origin) trackEditorOpenedEvent(lernset, undefined);
      if (lernsetPart?.origin && mindItem) trackEditorOpenedEvent(lernset, mindItem);
    }
  },[lernset, lernsetPart, mindItem, isLernsetsActive]);

  // Wait for the Amplitude client to be ready before we can render the page
  if (!isClientReady) {
    return <>Loading...</>;
  } else if (!partId || !isLernsetsActive) {
    router.replace(`/${routes.notFound.root}`);
    return <></>;
  }

  return (
    <Box sx={{ height: '100vh' }}>
      <LernsetsAnnotator 
        lernsetId={lernsetId}
        partId={partId}
      />
    </Box>
  );
};

export default LernsetsAnnotationsPage;
