import React, { ReactNode } from 'react';
import Container from 'react-bootstrap/Container';
import { MeatballMenuScrollableArea } from '../../state/MeatballMenuProvider';

interface MainGridArea {
  header?: ReactNode;
  body?: ReactNode;
  backgroundClick?: () => void;
  context?: ReactNode;
}

const MainGridArea: React.FunctionComponent<MainGridArea> = ({ header, body, backgroundClick, context }) => (
  <div className='main-container-grid'>
    <header className='main-container-grid__header border-bottom h-100'>
      <Container className='d-flex justify-content-between align-items-center h-100' fluid={true}>{header}</Container>
    </header>
    <main className='main-container-grid__body' onClick={backgroundClick}>
      <Container fluid={true} className='position-relative h-100'>
        <MeatballMenuScrollableArea />
        {body}
      </Container>
    </main>
    <aside className='main-container-grid__context h-100 border-left d-none d-md-block'>
      <Container>{context}</Container>
    </aside>
  </div>
);

export default MainGridArea;
