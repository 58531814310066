import { Match } from 'found';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import CollectionsAppLayout from '../components/CollectionsAppLayout';
import ItemContext from '../components/contexts/ItemContext';
import ItemsList from '../components/ItemsList';
import { routes } from '../constants';
import { StoreType } from '../reducers';
import Breadcrumbs from '../components/Breadcrumbs';
import { ContentItem, Pagination } from '../types/AppContent';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import { selectPinnedContents } from '../reducers/pinned.selectors';
import { createNextPaginationRequest, createPaginationRequest, hasNext } from '../utils/pagination';
import { fetchPinnedContents } from '../actions/pinned.actions';

interface StateProps {
  pinnedContents: ContentItem[];
  pinnedStatus: AsyncActionStatus;
  pinnedPagination: Pagination;
  user: StoreType['user'];
}

interface DispatchProps {
  fetchPinnedContents: typeof fetchPinnedContents;
}

type PinnedItemListPageProps = StateProps & DispatchProps & Match;

const PinnedItemListPage: React.FC<PinnedItemListPageProps> = ({
  pinnedContents,
  pinnedStatus,
  pinnedPagination,
  fetchPinnedContents,
  user
}) => {
  const { t } = useTranslation();

  const paginationRequest = createPaginationRequest(pinnedPagination);
  const nextPaginationRequest = createNextPaginationRequest(pinnedPagination);

  useEffect(() => {
    if (user && paginationRequest.offset && paginationRequest.offset > 0) {
      fetchPinnedContents(paginationRequest);
    }
  }, [user.me]);

  const [activeId, setActiveId] = useState('');
  const activeItem = pinnedContents.find((item) => item.id === activeId);

  return (
    <CollectionsAppLayout
      header={
        <Breadcrumbs breadcrumbs={[
          { to: `/${routes.pinned.root}`, title: t('Pinned Documents') },
        ]}/>
      }
      mainBody={
        <ItemsList
          isLoading={pinnedStatus === 'loading'}
          hasNext={hasNext(pinnedPagination)}
          total={pinnedStatus === 'loading' ? -1 : pinnedContents.length}
          loadNext={() => {
            return fetchPinnedContents(nextPaginationRequest);
          }}
          activeId={activeId}
          items={pinnedContents}
          linkRoot={[routes.pinned.root]}
          setActiveId={setActiveId}
        />
      }
      context={pinnedContents.length ? <ItemContext item={activeItem} /> : <></>}
      setActiveId={setActiveId}
    />
  );
};

const mapState = (state: StoreType): StateProps => ({
  pinnedContents: selectPinnedContents(state),
  pinnedStatus: state.pinned.status,
  pinnedPagination: state.pinned.pagination,
  user: state.user,
});

export default compose<React.FC<Record<string, never>>>(
  connect(
    mapState,
    { fetchPinnedContents }
  ),
)(PinnedItemListPage);
