import React, { useCallback, useState } from 'react';
import { withRouter, WithRouter } from 'found';
import services from '../../services';
import BasisRegistration from '../../components/BasisRegistration/BasisRegistrationForm/BasisRegistrationForm';
import BasisRegistrationConfirm from
  '../../components/BasisRegistration/BasisRegistrationConfirm/BasisRegistrationConfirm';
import LoginGradientModalAppLayout from '../../components/Login/LoginGradientAppLayout';
import RedirectIfLoggedIn from '../../router/redirect/RedirectIfLoggedIn';
import useRouter from '../../utils/useRouter';

type RegisterBasisPageProps = WithRouter;

const RegisterBasisPage: React.FC<RegisterBasisPageProps> = (props) => {
  const { match: { location: { query } } } = useRouter();
  const urlRedirect = query.optin_redirect;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  const handleResetForm = () => {
    setUserEmail('');
    setShowConfirmation(false);
  };

  const handleResubmit = () => {
    if (!userEmail) {
      return;
    }
    services.email.resendConfirmationEmail(userEmail);
  };

  const handleRegisterSuccess = useCallback((email: string) => {
    setUserEmail(email);
    setShowConfirmation(true);
  }, [props.router]);

  const title = !showConfirmation ? 'Register new account' : 'Thank you!';

  return (
    <RedirectIfLoggedIn>
      <LoginGradientModalAppLayout title={title}>
        {!showConfirmation ?
          <BasisRegistration
            onRegisterSuccess={handleRegisterSuccess}
            redirectTo={urlRedirect}
          />
          :
          <BasisRegistrationConfirm
            email={userEmail}
            onResetForm={handleResetForm}
            onResubmit={handleResubmit}
          />
        }
      </LoginGradientModalAppLayout>
    </RedirectIfLoggedIn>
  );
};

export default withRouter(RegisterBasisPage);
