import React, { useEffect, useRef } from 'react';
import { AmplitudeTrackPayload } from '@klab-berlin/api-sdk/lib/types/requests/Track';
import { useSelector } from 'react-redux';
import './productPage.scss';
import {
  fetchAttachments as fetchAttachmentsAction,
  fetchProduct as fetchProductAction,
  fetchProductMindItems as fetchProductMindItemsAction,
} from '../../actions/product.actions';
import TopBar from '../../components/AppLayout/TopBar';
import { CurrentProductContext } from '../../components/Product/CurrentProductContext';
import { ProductContents, NewProductContents } from '../../components/Product/ProductContents';
import { ProductSummary, NewProductSummary } from '../../components/Product/ProductSummary';
import useAction from '../../utils/useAction';
import useRouter from '../../utils/useRouter';
import { setCustomPageTitle } from '../../utils/customTitle';
import { selectProduct } from '../../reducers/products.selectors';
import UpgradeBanner from '../../components/UpgradeBanner';
import { selectUser } from '../../reducers/user.selectors';
import { amplitudeContentTypes, amplitudeEvents, routes } from '../../constants';
import { getContentEventPayload, mapDesignPreference } from '../../utils/ampli';
import services from '../../services';
import { Product } from '@klab-berlin/api-sdk/lib/types/responses/Product';
import { User } from '../../types/User';
import NewDesignSwitch from '../../components/NewDesignSwitch/NewDesignSwitch';
import { useNewDesign } from '../../state/DesignPreferenceContextProvider';
import { Box } from '@mui/material';
import { palette, spaces } from '../../assets/styles/themes/tokens';
import FeedbackDialog from '../../components/modals/FeedbackDialog';
import { isEqual } from 'lodash';
import { GET_PRODUCT } from '../../actions/product.actionTypes';

const ProductPage = () => {
  const { match: { params: { productId } }, router } = useRouter();
  const fetchAttachments = useAction(fetchAttachmentsAction);
  const fetchProduct = useAction(fetchProductAction);
  const fetchProductMindItems = useAction(fetchProductMindItemsAction);
  const product = useSelector(state => selectProduct(state, productId));
  const user = useSelector(selectUser);
  const { prefersNewDesign, shouldShowDesignPreferenceSwitch, isExperimentReady } = useNewDesign();

  const OldUIProductPageBody = () => (
    <div className='product-page__main-container d-flex w-100 flex-column align-items-center m-auto'>
      <ProductSummary />
      <hr className='w-100' />
      <ProductContents />
    </div>
  );

  const NewUIProductPageBody = () => (
    <>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        margin: 'auto',
        padding: `${spaces.lg} ${spaces.xl} ${spaces.xxxl} ${spaces.xl}`,
        backgroundColor: palette.background.paperElevation2,
      }}>
        <NewProductSummary />
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        margin: 'auto',
        padding: `${spaces.md} ${spaces.xl} ${spaces.xxxxxl} ${spaces.xl}`, 
        backgroundColor: palette.background.paperElevation1,
      }}>
        <NewProductContents />
      </Box>
    </>
  );
  
  const trackContentViewed = (
    product: Product | undefined,
    user: User | undefined,
  ) => {
    if (product && user) {
      const contentViewedPayload: AmplitudeTrackPayload | null = getContentEventPayload({
        content: product,
        event_type: amplitudeEvents.ContentViewed,
        contentType: amplitudeContentTypes.product,
      });

      if (contentViewedPayload) {
        if (shouldShowDesignPreferenceSwitch) {
          contentViewedPayload.event_properties['ui'] = mapDesignPreference(prefersNewDesign);
        }
        services.track.ampliServerSideEventTrack(false, contentViewedPayload);
      }
    }
  };

  useEffect(() => {
    fetchProduct(productId)
      .then((result) => {
        if (!result) return;
        const { type } = result;
        if (type === GET_PRODUCT.ERROR) {
          router.push(`/${routes.dashboard.root}`);
        }
      });
    fetchProductMindItems(productId);
    fetchAttachments(productId);
  }, [productId]);

  const prevProductRef = useRef<Product | undefined>();

  useEffect(() => {
    if (!isEqual(prevProductRef.current, product)) {
      setCustomPageTitle(product?.title);
      if (isExperimentReady) {
        trackContentViewed(product, user);
      }
    }

    prevProductRef.current = product;
  }, [product, isExperimentReady]);

  return (
    <div className='product-page__wrapper'>
      <FeedbackDialog />
      <UpgradeBanner />
      <TopBar />
      { shouldShowDesignPreferenceSwitch &&
        <Box sx={theme => ({
          [theme.breakpoints.up('tablet')]: {
            paddingTop: spaces.sm,
            paddingX: spaces.xl,
            ...(prefersNewDesign && { marginBottom: `-${spaces.lg}` }),
          },
          [theme.breakpoints.between('mobile', 'tablet')]: {
            paddingTop: spaces.sm,
            paddingX: spaces.lg,
            ...(prefersNewDesign && { marginBottom: `-${spaces.md}` }),
          },
          [theme.breakpoints.down('mobile')]: {
            paddingTop: spaces.xs,
            paddingX: spaces.md,
            ...(prefersNewDesign && { marginBottom: `-${spaces.lg}` }),
          },
          display: 'flex',
          justifyContent: 'flex-end',
          position: 'relative',
          ...(prefersNewDesign && { backgroundColor: palette.background.paperElevation2 }),
          ...(prefersNewDesign && { zIndex: 5 }),
        })}>
          <NewDesignSwitch />
        </Box>
      }
      <CurrentProductContext.Provider
        value={{
          productId
        }}
      >
        { prefersNewDesign ? <NewUIProductPageBody /> : <OldUIProductPageBody /> }
      </CurrentProductContext.Provider>
    </div>
  );
};

export default ProductPage;
