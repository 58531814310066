import React from 'react';
import Slider from 'react-slick';
import './searchFiltersCarousel.scss';

interface SearchFiltersCarouselProps {
  className?: string;
  filters: React.ReactNode[];
  showResetFilters: boolean;
  getResetFiltersButton: () => React.ReactNode;
}

const SearchFiltersCarousel: React.FC<SearchFiltersCarouselProps> = (props) => {
  // The react-slick component is re-calculating a reference to an inline styling
  // property on each re-render, causing re-renders of each child component. Wrapping
  // them in a span acts as a buffer for this styling, allowing us to memoize the
  // slide components, and avoid re-rendering of the slides themselves
  const wrappedFilters = props.filters.map((filter, i) => {
    if (!filter) {
      return;
    }
    return (
      <span className='carousel-filter' key={i}>
        {filter}
      </span>);
  });

  const settings = {
    variableWidth: true,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    swipeToSlide: true,
  };

  return (
    <div className={props.className}>
      <Slider {...settings}>
        {wrappedFilters}
        {props.showResetFilters && props.getResetFiltersButton()}
      </Slider>
    </div>
  );
};

export default SearchFiltersCarousel;
