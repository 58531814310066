/**
 * Converts a byte array to an object URL.
 * @param data - The byte array to convert.
 * @param type - The MIME type of the data.
 * @returns The object URL representing the byte array.
 */
export function byteArrayToObjectUrl (data: number[], type: string) {
  const arrayBuffer = new Uint8Array(data).buffer;
  const blob = new Blob([arrayBuffer], { type });
  return URL.createObjectURL(blob);
}
