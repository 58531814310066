import { Theme } from '@emotion/react';
import { SxProps, DialogActions as MuiDialogActions } from '@mui/material';
import React from 'react';
import { spaces } from '../../assets/styles/themes/tokens';

interface DialogActionsProps {
  sx?: SxProps<Theme>;
}

const DialogActions: React.FC<DialogActionsProps> = ({ children, sx }) => {
  return <MuiDialogActions
    disableSpacing
    sx={{
      padding: spaces.lg,
      gap: spaces.xs,
      ...sx,
    }}
  >
    {children}
  </MuiDialogActions>;
};

export default DialogActions;
