import { userType } from '../constants';
import services from '../services';
import { ThunkAction } from './common.actionTypes';
import {
  FETCH_USER_PRODUCT_RECOMMENDATIONS,
  FetchUserProductRecommendationsAction,
} from './recommendation.actionTypes';

export function fetchUserProductRecommendations(
  limit?: number
): ThunkAction<FetchUserProductRecommendationsAction> {
  return async (dispatch, getState) => {
    const me = getState().user.me;
    if (!me || me.type !== userType.premium) { return; }
    const recommendationType = 'user';
    const referenceId = me._id;
    const payload = { type: recommendationType, referenceId, limit };
    dispatch({ type: FETCH_USER_PRODUCT_RECOMMENDATIONS.START, payload });
    return services.product
      .getProductRecommendations(recommendationType, referenceId, limit)
      .then(result =>
        dispatch({
          type: FETCH_USER_PRODUCT_RECOMMENDATIONS.SUCCESS,
          payload,
          result,
        })
      )
      .catch(error => dispatch({ error, type: FETCH_USER_PRODUCT_RECOMMENDATIONS.ERROR, payload }));
  };
}
