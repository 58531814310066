import React from 'react';
import './mindItemCard.scss';
import Skeleton from 'react-loading-skeleton';

const SkeletonMindItemCard = () => {
  return <div className='mind-item-card'>
    <Skeleton height={350} />
  </div>;
};

export default SkeletonMindItemCard;
