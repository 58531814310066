import React from 'react';
import { useTranslation } from 'react-i18next';
import './DiscountCircle.scss';

interface DiscountCircleProps {
  discountAmount: number;
  discountType: 'Percentage' | 'Fixed';
}

const DiscountCircle: React.FC<DiscountCircleProps> = ({ discountAmount, discountType }) => {
  const { t } = useTranslation();
  const discountNumber = discountType === 'Fixed'
    ? `${discountAmount}€`
    : `${discountAmount}%`;

  return (
    <div className='discount-circle'>
      <div className='discount-circle__number'>{discountNumber}</div>
      <div className='discount-circle__text'>{t('Discount')}</div>
    </div>
  );
};

export default DiscountCircle;
