import React, { useCallback, useEffect, useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { selectUser } from '../../../reducers/user.selectors';
import services from '../../../services';
import { password } from '../../../validators';
import PasswordInput from '../../common/Input/PasswordInput';
import TextInput from '../../common/Input/TextInput';
import PersonalDetailsActions from '../Common/PersonalDetailsActions';
import PersonalDetailsInputGroup from '../PersonalDetailsInputGroup';

interface ChangeEmailFormProps {
  close: () => void;
  onSuccess: () => void;
}

interface ChangeEmailFormFields {
  password: string;
  email: string;
}

const ChangeEmailForm: React.FC<ChangeEmailFormProps> = (props) => {
  const [serverError, setServerError] = useState<Error | null>(null);
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  if (!user) {
    return <></>;
  }

  const formValidator = yup.object<ChangeEmailFormFields>({
    password: password({ requiredMessage: 'Please enter your current password for confirmation' }),
    email: yup.string().email('The email is not valid').required('This field is required')
      .notOneOf([user.email], 'This email address is the same as your current email address'),
  });

  const { errors, handleSubmit, register, setError, reset } = useForm<ChangeEmailFormFields>({
    mode: 'onBlur',
    validationSchema: formValidator,
  });

  const getFieldError = (fieldName: keyof ChangeEmailFormFields) => {
    if (!errors[fieldName]) return;
    const err = errors[fieldName] as FieldError;
    const errMsg = err.message?.toString();
    return t(errMsg ? errMsg : '');
  };

  interface ServerErrorMessage {
    field: keyof ChangeEmailFormFields;
    message: string;
  }

  const onSubmit = handleSubmit(useCallback((formData: ChangeEmailFormFields) => {
    services.user.changeEmail(
      user.email,
      formData.email,
      formData.password,
      false
    )
      .then(() => {
        props.onSuccess();
        props.close();
      })
      .catch((error) => {
        const serverErrorMessage = getServerErrorMessage(error.message);
        if (serverErrorMessage) {
          return setError(serverErrorMessage.field, 'manual', t(serverErrorMessage.message));
        }

        setServerError(error);
      });
  }, [user.email]));

  const getServerErrorMessage = (errorMessage: string): ServerErrorMessage | undefined => {
    if (errorMessage.match(/Invalid username or password/)) {
      return {
        field: 'password',
        message: 'The password you entered is incorrect.'
      };
    }
    if (errorMessage.match(/Email address already exists/)) {
      return {
        field: 'email',
        message: 'The email you entered already exists.'
      };
    }
    return;
  };

  useEffect(() => {
    reset({
      email: user.email
    });
  }, [user]);

  return (
    <form onSubmit={onSubmit}>
      <PersonalDetailsInputGroup>
        <TextInput
          label={t('New email address')}
          name='email'
          ref={register}
          error={getFieldError('email')}
          required={true}
        />
        <PasswordInput
          label={t('Confirm with current password')}
          name='password'
          ref={register}
          error={getFieldError('password')}
          required={true}
        />
      </PersonalDetailsInputGroup>
      <PersonalDetailsActions
        onCancel={props.close}
        showError={!!serverError}
      />
    </form>
  );
};

export default ChangeEmailForm;
