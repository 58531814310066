import React from 'react';
import Icon, { IconName } from './Icon';

interface EmptyStateProps {
  iconName: IconName;
  heading?: string;
  text?: React.ReactNode;
}

const EmptyState: React.FC<EmptyStateProps> = ({ iconName, heading, text }) => {
  return (
    <div className='text-light pt-5'>
      <div className='mx-auto text-center max-width-500'>
        <Icon
          icon={iconName}
          size='xxl'
        />
        <h4 className='mt-5 mb-3'>{heading}</h4>
        {text}
      </div>
    </div>
  );
};

export default EmptyState;
