import { PaginationRequest } from '@klab-berlin/api-sdk/lib/types/common';
import { searchSettings } from '../constants';
import { Pagination } from '../types/AppContent';
import { Pagination as SearchPagination } from '../types/search';

export function createPaginationRequest(pagination: Pagination): PaginationRequest {
  const { limit, offset, sortOrder } = pagination;

  return {
    limit,
    offset,
    sortOrder,
    version: 2,
  };
}

export function createNextPaginationRequest(pagination: Pagination): PaginationRequest {
  return createPaginationRequest({ ...pagination, offset: pagination.offset + pagination.limit });
}

export function hasNext(pagination: Pagination | SearchPagination) {
  return pagination.offset <= searchSettings.maxOffset && (
    (pagination.total === -1 && pagination.offset === 0) || (pagination.total > pagination.offset)
  );
}
