import React from 'react';
import { useTranslation } from 'react-i18next';
import { RangeSelect } from '../common/Select';
import { RangeSliderValue } from '../common/Select/common/RangeSlider/RangeSlider';

interface FilterByClassYearsProps {
  updateFilter: (values: RangeSliderValue) => void;
  clearFilter: () => void;
  currentValue: {
    min?: number;
    max?: number;
  };
  onToggle?: () => void;
}

const FilterByClassYears: React.FC<FilterByClassYearsProps> = (
  { updateFilter, clearFilter, currentValue, onToggle }
) => {
  const range = { min: 1, max: 13 };

  const { t } = useTranslation();

  return (
    <RangeSelect
      onSelectionConfirm={(values) => {
        updateFilter(values);
      }}
      onSelectionClear={() => {
        clearFilter();
      }}
      alwaysShowLabel={true}
      label={t('Class years')}
      value={currentValue.max && currentValue.min ? { min: currentValue.min, max: currentValue.max } : undefined}
      range={range}
      onToggle={onToggle}
      saveButtonLabel='Anwenden'
      resetButtonLabel='Löschen'
    ></RangeSelect>
  );
};

export default FilterByClassYears;
