import services from '../services';
import { AsyncActionStatus, ThunkAction } from './common.actionTypes';
import { FetchPublishersAction, FETCH_PUBLISHERS } from './publishers.actionTypes';

export function fetchPublishers(): ThunkAction<FetchPublishersAction> {
  return async (dispatch, getState)  => {
    if (getState().publishers.status === AsyncActionStatus.READY) {
      return;
    }

    dispatch({ type: FETCH_PUBLISHERS.START });

    return services.text.fetchPublishers()
      .then(result => dispatch({ type: FETCH_PUBLISHERS.SUCCESS, result }))
      .catch(error => dispatch({ type: FETCH_PUBLISHERS.ERROR, error }));
  };
}
