import { useSelector } from 'react-redux';
import { AsyncActionStatus } from '../../actions/common.actionTypes';
import { StoreType } from '../../reducers';

const useFilterStatus = (selector: (state: StoreType) => AsyncActionStatus) => {
  const value = useSelector(selector);

  const isReady =
    value !== AsyncActionStatus.INITIAL
    && value !== AsyncActionStatus.LOADING;
  const isError = value === AsyncActionStatus.ERROR;

  return {
    isReady,
    isError,
  };
};

export default useFilterStatus;
