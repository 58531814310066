import { useMediaQuery } from '@mui/material';
import lernsetsTheme from '../assets/styles/themes/lernsetsTheme';

const useScreenSize = () => {
  const smallScreenQuery = lernsetsTheme.breakpoints.down('mobile');
  const mediumScreenQuery = lernsetsTheme.breakpoints.between('mobile', 'tablet');
  const largeScreenQuery = lernsetsTheme.breakpoints.up('tablet');

  const isSmallScreen = useMediaQuery(smallScreenQuery, { noSsr: true });
  const isMediumScreen = useMediaQuery(mediumScreenQuery, { noSsr: true });
  const isLargeScreen = useMediaQuery(largeScreenQuery, { noSsr: true });

  return {
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
  };
};

export default useScreenSize;
