import api from '../clients/api';
import { handleUnauthorized } from '../utils/apiWrapper';
import { notifyBugsnagNetwork } from '../utils/bugsnagClient';
import { GenerateLernsetWorksheet } from '@klab-berlin/api-sdk/lib/types/requests/Ai';

const service = {
  generateLernsetWorksheet,
};

function generateLernsetWorksheet(payload: GenerateLernsetWorksheet) {
  notifyBugsnagNetwork('AI', 'generateLernsetWorksheet', {  ...payload });
  return handleUnauthorized(() => api.ai.generateLernsetWorksheet(payload));
}

export default service;
