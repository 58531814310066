import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { OutlinedInput, Tooltip } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { borderRadius, palette, spaces } from '../../../assets/styles/themes/tokens';

interface OwnProps {
    title: string; 
    onNewTitleSave: (newTitle: string) => void;
}

const LernsetTitleInput: React.FC<OwnProps>  = ({ title, onNewTitleSave }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [newTitle, setNewTitle] = useState<string>(title);
  const updatingTitle = useRef<boolean>(false);
  const blurSource = useRef<string>('');

  useEffect(() => {
    if (title) setNewTitle(title);
  }, [title]);

  const handleSave = async () => {
    if (newTitle && newTitle !== title && !updatingTitle.current) {
      updatingTitle.current = true;
      await onNewTitleSave(newTitle);
      updatingTitle.current = false;
    } else if (!newTitle) {
      setNewTitle( prev => prev || title);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key, currentTarget } = event;
    if (key === 'Escape') {
      setNewTitle(title);
      blurSource.current = key;
      currentTarget.blur();
    }
    if (key === 'Enter') {
      handleSave();
      currentTarget.blur();
    }
  };

  const handleOnBlur = () => {
    if (blurSource.current !== 'Escape') handleSave();
    blurSource.current = '';
  };
  
  return (
    <FormControl 
      sx={{ paddingRight: spaces.xs }}
      fullWidth
    >
      <Tooltip title={t('Rename')} arrow placement='bottom'>
        <OutlinedInput
          size='small'
          sx={{
            borderRadius: borderRadius.sm,
            '.MuiOutlinedInput-notchedOutline': {
              borderStyle:  newTitle === '' ? 'solid' : 'none',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderStyle: 'solid',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderStyle: 'solid',
            },
          }}
          value={newTitle}
          inputProps={{ 
            maxLength: 128,
            'aria-label': 'Lernset title',
            style: {
              padding: `${spaces.xs} ${spaces.sm}`,
              color: palette.text.primary,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              ...theme.typography.h6,
            }, 
          }}
          onChange={({ target }) => setNewTitle(target.value)}
          onKeyDown={handleKeyDown}
          onBlur={handleOnBlur}
        />
      </Tooltip>
    </FormControl>
  );
};

export default LernsetTitleInput;
