import React from 'react';
import { compressClassYearArray } from '../../../utils/compressClassYearArray';
import Icon from '../../Icon';
import './mindItemListItem.scss';
import PinItemButton from '../../PinItemButton';
import WithTooltip from '../../WithTooltip';
import { useTranslation } from 'react-i18next';
import CardDescription from '../common/CardDescription';
import ManageItemInCollectionsButton from '../../ManageItemInCollectionsButton';
import PublisherImage from '../../PublisherImage';
import CardThumbnail from '../common/CardThumbnail';
import Truncate from '../../Truncate';
import TagContainer from '../common/TagContainer';
import WithTracking from '../../utils/WithTracking';
import { trackingEvents } from '../../../services/tracking/trackConfig';
import { ContentType } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import PlayIcon from '../common/PlayIcon';
import classNames from 'classnames';
import { VideoNotAvailableContent } from '../VideoNotAvailableCard/VideoNotAvailableCard';
import { isValidVideoItem } from '../../../utils/subdomain';

interface MindItemListItemProps {
  classYears: number[];
  isNew: boolean;
  isReadOnly: boolean;
  description?: string;
  fileType: ContentType;
  id: string;
  isPinned: boolean;
  thumbnail: string;
  title: string;
  publisher?: string;
  subjects: string[];
  hideCollectionAction?: boolean;
  hidePinAction?: boolean;
  index?: number;
  searchEngineVersion?: number;
  hasWideThumbnail?: boolean;
  schoolTypes: string[];
}

const MindItemListItem: React.FC<MindItemListItemProps> = (props) => {
  const {
    classYears,
    isNew,
    isReadOnly,
    description,
    fileType,
    thumbnail,
    title,
    publisher,
    subjects,
    id,
    isPinned,
    hideCollectionAction,
    hidePinAction,
    index,
    searchEngineVersion,
    hasWideThumbnail,
    schoolTypes,
  } = props;

  const { t } = useTranslation();

  const isVideo = fileType === 'video';
  const itemData = {
    id,
    title,
    subjects,
    publisher,
    classYears,
    schoolTypes,
    fileType,
    isReadOnly,
  };

  const MindItemListItemContent = () => (
    <div className={classNames(
      'mind-item-list-item__content-wrapper',
    )}
    data-testid='mind-item-list-item__content-wrapper'>
      <div className='mind-item-list-item__image'>
        {isVideo && <PlayIcon testId='play-icon' />}
        <CardThumbnail
          className={(hasWideThumbnail)
            ? 'mind-item-list-item__video-thumbnail'
            : 'mind-item-list-item__thumbnail'}
          thumbnailUrl={thumbnail} />
      </div><div className={classNames('mind-item-list-item__body', { 'mind-item-list-item__body--video': isVideo })}>
        <div className='mind-item-list-item__content'>
          <div>
            <div className='mind-item-list-item__title font-weight-bold' data-testid='mind-item-list-item__title'>
              <Truncate lines={3} text={title} />
            </div>
            {description &&
              <CardDescription
                className='mind-item-list-item__description'
                numLines={7}
                description={description} />}
          </div>
          <div className='mind-item-list-item__details'>
            <PublisherImage
              className='mind-item-list-item__publisher'
              publisher={publisher} />
            <Icon icon='subjects' />
            <div className='mind-item-list-item__key'>{t('Subjects')}</div>
            <div className='mind-item-list-item__values mind-item-list-item__subjects text-truncate'>
              {subjects.join(', ')}
            </div>
            <Icon icon='classes' />
            <div className='mind-item-list-item__key'>{t('Class year')}</div>
            <div className='mind-item-list-item__values'>{compressClassYearArray(classYears).join(', ')}</div>
          </div>
        </div>
        <div className='mind-item-list-item__actions'>
          {!hideCollectionAction &&
            <WithTooltip text={t('Add to collection')} placement='left'>
              <ManageItemInCollectionsButton
                documentId={id}
                itemData={itemData}
                type='mindItem'
                iconClassName='mind-item-list-item__actions__collection' />
            </WithTooltip>}
          {!hidePinAction &&
            <WithTooltip text={isPinned ? t('Unpin') : t('Pin')} placement='left'>
              <PinItemButton
                isPinned={isPinned}
                documentId={id}
                itemData={itemData}
                type='mindItem'
                iconClassName='mind-item-list-item__actions__pin' />
            </WithTooltip>}
        </div>
      </div>
    </div>
  );

  return (
    <WithTracking
      events={{
        onClick: trackingEvents.itemOpen,
        onMiddleClick: trackingEvents.itemOpen,
        onContextMenu: trackingEvents.itemOpen,
      }}
      eventPayload={{ other: { 'minditem_id': id, index, searchEngineVersion } }}
    >
      <div 
        className='mind-item-list-item' 
        data-testid={isVideo ? 'video-mind-item-list-item' : 'mind-item-list-item'}
      >
        <TagContainer isNew={isNew} isReadOnly={isReadOnly}  />
        <div className='mind-item-list-item__container'>
          { 
            isVideo && !isValidVideoItem(publisher)
              ? <VideoNotAvailableContent />
              : <MindItemListItemContent /> 
          }
        </div>
      </div>
    </WithTracking>
  );
};

export default MindItemListItem;
