import React, { useState } from 'react';
import { SortByOption } from '@klab-berlin/api-sdk/lib/types/requests/Common';
import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Radio,
  useMediaQuery,
  Theme,
  ListItemText,
  alpha,
  Box,
} from '@mui/material';
import { colors } from '../../../assets/styles/themes/variables';
import { BasicFilterProps } from '../../../types/search';
import { spaces } from '../../../assets/styles/themes/tokens';
import { Option, getSortFilterOptions } from '../SortByFilterOptions';
import getMuiIcon from '../../../utils/getMuiIcon';
import classNames from 'classnames';

const SortByFilterNewUI: React.FC<BasicFilterProps<SortByOption>> = (
  { currentValue, updateFilter }
) => {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobile'));

  const handleChange = (event: SelectChangeEvent) => {
    updateFilter(event.target.value as SortByOption);
  };

  const sortFilterOptions: Option[] = getSortFilterOptions();

  return (
    <FormControl
      data-testid='sort-by-new-ui-form-control'
      sx={{
        height: spaces.xxl,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: alpha(colors.violet800, 0.16),
        },
        '& .MuiButtonBase-root.MuiMenuItem-root:hover': {
          backgroundColor: 'transparent!important',
        },
        '& .MuiInputBase-root': {
          width: isMobile ? '138px' : '220px',
          padding: `0px ${spaces.sm}`,
        },
        '& .MuiInputBase-input': {
          padding: '0px',
        },
        '& .MuiMenuItem-root': {
          padding: 0,
        },
      }}
    >
      <Select
        value={currentValue || 'relevance'}
        IconComponent={({ className, selectIcon, ...props }) => <Box
          className={classNames(className, selectIcon)}
          component={getMuiIcon('arrowDropdownFilled')}
          {...props}
          sx={{
            top: 'unset !important',
            color: alpha(colors.violet800, 0.48) + ' !important',
          }}
        /> }
        renderValue={(selected) => (
          <MenuItem>
            <ListItemText primary={sortFilterOptions.find((op) => op.value === selected)?.name} sx={{
              '& .MuiTypography-root': {
                maxWidth: isMobile ? '100px': '100%',
                overflow: isMobile ? 'hidden' : 'visible',
                fontSize: spaces.md,
                letterSpacing: '0.15px',
                fontWeight: '400',
                textOverflow: 'ellipsis',
              },
            }}/>
          </MenuItem>
        )}
        onChange={handleChange}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        open={open}
        MenuProps={{
          PaperProps: {
            sx: {
              width: '220px',
              marginTop: spaces.xs,
              borderRadius: spaces.md,
              ...(isMobile && {
                right:  spaces.xs,
                left: 'unset!important',
              }),
            },
          },
        }}
        sx={{
          height: spaces.xxl,
          borderRadius: spaces.sm,
        }}
      >
        {sortFilterOptions.map((option) => (
          <MenuItem
            data-testid='sort-by-new-ui-menu-item'
            key={option.value}
            value={option.value}
            sx={{
              padding: '0px',
              margin: `0px ${spaces.xs}`,
              height: '50px',
              gap: '0px',
              '&:hover': {
                backgroundColor: `${alpha(colors.blue500, 0.08)}`,
                borderRadius: spaces.md,
              },
              backgroundColor: currentValue === option.value ?
                `${alpha(colors.blue500, 0.08)}!important` :
                'transparent',
              borderRadius: spaces.md,
            }}
          >
            <Radio
              checked={currentValue === option.value}
              sx={{
                visibility: open ? 'visible' : 'hidden',
                gap: '0px!important',
              }}
            />
            <ListItemText primary={option.name} sx={{
              '& .MuiTypography-root': {
                letterSpacing: '0.17px',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: spaces.lg,
              },
            }}/>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SortByFilterNewUI;
