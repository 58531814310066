import { noop } from 'lodash';
import React, { useContext, useState } from 'react';
import cookies from '../clients/cookies';
import { useAmplitudeExperiment } from '../utils/ampli';
import { useSelector } from 'react-redux';
import { selectUser } from '../reducers/user.selectors';
import config from 'config';
import { isSchoolSubdomain } from '../utils/subdomain';

interface DesignPreferenceContext {
  prefersNewDesign: boolean;
  setNewDesignPreference: (newDesign: boolean) => void;
  shouldShowDesignPreferenceSwitch: boolean;
  isExperimentReady: boolean;
  setNewUIOnboardingComponentState: (state: string) => void;
  newUIOnboardingComponentState: string;
}

const DesignPreferenceContext = React.createContext<DesignPreferenceContext>({
  prefersNewDesign: false,
  setNewDesignPreference: noop,
  shouldShowDesignPreferenceSwitch: false,
  isExperimentReady: false,
  setNewUIOnboardingComponentState: noop,
  newUIOnboardingComponentState: 'invisible',
});

const DesignPreferenceContextProvider: React.FC = (props) => {
  const initialNewDesignPreference = cookies.getNewDesignPreference();
  const [prefersNewDesign, setNewDesignPreference] = useState(initialNewDesignPreference);

  const initialNewUIOnboardingComponentState = cookies.getNewUIOnboardingComponent();
  const [
    newUIOnboardingComponentState, setNewUIOnboardingComponentState
  ] = useState(initialNewUIOnboardingComponentState);

  const user = useSelector(selectUser);
  const { variant, isReady } = useAmplitudeExperiment(user, config.amplitudeExperiments.showNewDesign);

  const shouldShowDesignPreferenceSwitch = !isSchoolSubdomain(window.location.hostname) && isReady && variant === 'on';

  const allowNewDesign = shouldShowDesignPreferenceSwitch && prefersNewDesign;

  return (
    <DesignPreferenceContext.Provider
      value={{
        prefersNewDesign: allowNewDesign,
        setNewDesignPreference: (newDesignPreference) => {
          cookies.setNewDesignPreference(newDesignPreference);
          setNewDesignPreference(newDesignPreference);
        },
        shouldShowDesignPreferenceSwitch,
        isExperimentReady: isReady,
        setNewUIOnboardingComponentState: (state) => {
          if (newUIOnboardingComponentState === 'closed') {
            return;
          }

          cookies.setNewUIOnboardingComponent(state);
          setNewUIOnboardingComponentState(state);
        },
        newUIOnboardingComponentState,
      }}
    >
      {props.children}
    </DesignPreferenceContext.Provider>
  );
};

export const useNewDesign = () => useContext(DesignPreferenceContext);

export default DesignPreferenceContextProvider;
