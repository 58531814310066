import { PageSource } from '@klab-berlin/api-sdk/lib/types/requests/Track';
import { SetDownloaded, SetPrinted } from '../ampli';
import services from '../services';

export const getNewLernsetTitle = (defaultTitle: string) => {
  const date: string = new Date().toLocaleString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'Europe/Berlin',
  });
      
  return `${defaultTitle} ${date}`;
};

export const downloadFile = (fileUrl: string, name: string) => {
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const createBlankImageObjectUrl = (width: number, height: number): Promise<string> => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    const context = canvas.getContext('2d');
    if (!context) {
      reject('Failed to get 2D context');
      return;
    }

    context.fillStyle = 'white';
    context.fillRect(0, 0, width, height);

    canvas.toBlob((blob) => {
      if (blob) {
        const blobUrl = URL.createObjectURL(blob);
        resolve(blobUrl);
      } else {
        reject('Failed to create Blob');
      }
    }, 'image/png');
  });
};

export const convertSvgToPng = (svg: SVGSVGElement, width: number, height: number): Promise<string> => {
  return new Promise((resolve, reject) => {
    const svgSerialization = new XMLSerializer().serializeToString(svg);
    const svgBase64 = 'data:image/svg+xml;base64,' + btoa(svgSerialization);

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      reject('Failed to get 2D context');
      return;
    }

    const img = new Image();
    img.src = svgBase64;

    img.onload = () => {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      const png = canvas.toDataURL('image/png');
      resolve(png);
    };

    img.onerror = () => {
      reject('Error loading SVG data');
    };
  });
};

export const trackDownloadedEvent = (lernsetId: string, lernsetTitle: string, pageSource: PageSource) => {
  services.track.ampliEventTrack({ 
    event: new SetDownloaded({
      'lernset id': lernsetId,
      'lernset title': lernsetTitle,
      'page source': pageSource,
    })
  });
};

export const trackPrintedEvent = (lernsetId: string, lernsetTitle: string, pageSource: PageSource) => {
  services.track.ampliEventTrack({ 
    event: new SetPrinted({
      'lernset id': lernsetId,
      'lernset title': lernsetTitle,
      'page source': pageSource,
    })
  });
};

