import React, { memo } from 'react';
import { alpha } from '@mui/material/styles';
import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { palette, spaces, borderRadius } from '../../../../../assets/styles/themes/tokens';
import { MenuOption } from '../ContextMenu';

interface MoreFormatOptionsMenuProps {
    options: MenuOption[];
    anchorEl: HTMLElement | null;
    handleClick: (event: React.MouseEvent<HTMLElement>) => void;
    handleClose: () => void;
}
const MoreFormatOptionsMenu: React.FC<MoreFormatOptionsMenuProps> = ({ 
  options,
  anchorEl, 
  handleClick, 
  handleClose 
}) => {
  const isAnchorElOpen = Boolean(anchorEl);

  return (
    <>
      <IconButton
        data-testid='more-options-menu-button'
        color='secondary'
        size='small'
        aria-label='more'
        id='more-button'
        aria-controls={isAnchorElOpen ? 'more-menu' : undefined}
        aria-expanded={isAnchorElOpen ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
        sx={{
          '&:hover': {
            backgroundColor: alpha(palette.primary.main, 0.06),
          },
        }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id='more-menu'
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        MenuListProps={{
          'aria-labelledby': 'more-button',
          style: {
            padding: spaces.xxs,
            borderRadius: borderRadius.sm,
            backgroundColor: 'white',
            border: `1px solid ${palette.divider}`,
            display: 'flex',
            gap: spaces.xs,
          },
        }}
        anchorEl={anchorEl}
        open={isAnchorElOpen}
        onClose={handleClose}
        PaperProps={{
          style: {
            boxShadow: 'none',
            borderRadius: borderRadius.sm,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            data-testid={option.dataTestId} 
            sx={{ padding: 0, margin: 0 }}
            key={option.id}
            onClick={handleClose}
            selected={option.isActive}
          >
            <IconButton
              color='secondary'
              size='small'
              onClick={option.onClick}
            >
              {option.icon}
            </IconButton>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default memo(MoreFormatOptionsMenu);
