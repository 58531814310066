import React from 'react';
import publisherImages from '../../assets/publisher';

interface PublisherImageProps {
  className?: string;
  publisher: string | undefined;
}

const PublisherImage: React.FC<PublisherImageProps> = (props) => {
  const publisher = props.publisher as keyof typeof publisherImages;
  const src = props.publisher && (publisherImages[publisher]);

  return (
    <>
      {src && <img alt={props.publisher} className={props.className} data-testid='publisher-icon' src={src} />}
    </>
  );
};

export default PublisherImage;
