import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { onboardingHints } from '../../constants';
import { useTranslation } from 'react-i18next';
import config from 'config';
import Dialog from '../Dialog/Dialog';
import moment from 'moment';
import useMedia from '../../utils/useMedia';
import { useNewDesign } from '../../state/DesignPreferenceContextProvider';

/*
  State Descriptions:
    'visible': The dialog is currently open and visible to the user.
    'closed': The user has permanently closed the dialog.
    'hidden': The dialog is temporarily hidden and may be shown again in the future.
    'remindLater': The user has chosen to be reminded later.
*/

const FeedbackDialog: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useMedia();
  const { prefersNewDesign } = useNewDesign();
  const prevPrefersNewDesignRef = useRef<boolean>(); // Helps to store the previous value of prefersNewDesign

  const localStorageKey: string = `${onboardingHints.feedBackBanner}`;
  const localStorageDateKey: string = `${onboardingHints.feedBackBanner}-date`;

  const getLocalStorageState = () => localStorage.getItem(localStorageKey) || 'hidden';
  const getLocalStorageDate = () => localStorage.getItem(localStorageDateKey) || moment().format();

  // This variable manages the dialog's future visibility.
  // Prevents dialog show-up if user toggles design preference NEW->OLD->NEW within 5s.
  const [plannedToBeVisibleAfterDelay, setPlannedToBeVisibleAfterDelay] = useState(false);
  const [activeState, setActiveState] = useState(getLocalStorageState());

  useEffect(() => {
    prevPrefersNewDesignRef.current = prefersNewDesign; // After the render, set the previous value
  });

  const updateState = (state: string) => {
    setActiveState(state);
    localStorage.setItem(localStorageKey, state);
    localStorage.setItem(localStorageDateKey, moment().format());
  };

  const updateStateWithDelay = (state: string) => {
    setPlannedToBeVisibleAfterDelay(true); // State planned to be visible after 5s, but should not be visible yet.
    setTimeout(() => {
      const localStorageState = getLocalStorageState();
      if (localStorageState && localStorageState !== 'closed') updateState(state);
    }, 5000);
  };

  const updateStatePreProcessing = useCallback((state: string) => {
    const currentActiveState = getLocalStorageState();
    // closed state should not be updated
    if (currentActiveState === 'closed') return;
    if (currentActiveState === state) return;
    if (state !== 'visible') {
      updateState(state);
      return;
    }

    // Logic for visible status. Sometimes we need to show the dialog after 5 sec delay.
    if (currentActiveState === 'remindLater') {
      updateState(state);
    } else {
      updateStateWithDelay(state);
    }
  }, []);

  const checkRemindLater = () => {
    const currentTime = moment();
    const datefromLocalStorage = getLocalStorageDate();
    const dateFromLocalStorage = datefromLocalStorage && moment(datefromLocalStorage);
    const differenceInDays = currentTime.diff(dateFromLocalStorage, 'days');

    if (differenceInDays >= 7) {
      updateStatePreProcessing('visible');
    }
  };

  const prevPrefersNewDesign = prevPrefersNewDesignRef.current;
  useEffect(() => {
    const statefromLocalStorage = getLocalStorageState();

    if (activeState === 'closed') return;
    if (statefromLocalStorage === 'remindLater') {
      checkRemindLater();
      return;
    }

    // If the user changes design from NEW->OLD->NEW within 5s, then we don't need to show the dialog
    if (
      (activeState === 'visible' && prevPrefersNewDesign !== undefined) ||
      plannedToBeVisibleAfterDelay
    ) {
      updateStatePreProcessing('closed');
      setPlannedToBeVisibleAfterDelay(false);
      return;
    }

    // If user has changed the design preference from NEW->OLD
    if (prevPrefersNewDesign && !prefersNewDesign) {
      updateStatePreProcessing('visible');
      return;
    }
  }, [prefersNewDesign]);

  const htmlBody = (
    <Box sx={{ paddingTop: 0 }}>
      <Typography variant='body1'>
        {t('Why did you decide to switch back to the old design? With a short feedback you will help us a lot.')}
      </Typography>
    </Box>
  );

  const position: 'right-bottom' | 'center' = isMobile ? 'center' : 'right-bottom';
  const dialogProps = {
    id: onboardingHints.feedBackBanner,
    title: t('Your opinion counts!'),
    primaryCta: {
      label: t('Give feedback'),
      onClick: () => {
        updateStatePreProcessing('closed');
        window.open(config.newUIFeedbackModal.url, '_blank');
      },
    },
    secondaryCta: {
      label: t('Remind me later'),
      onClick: () => {
        updateStatePreProcessing('remindLater');
      },
    },
    htmlBody,
    position,
  };

  return (
    <Dialog
      open={activeState === 'visible'}
      onClose={() => {
        updateStatePreProcessing('closed');
      }}
      {...dialogProps}
      data-testid='feedback-dialog'
    />
  );
};

export default FeedbackDialog;
