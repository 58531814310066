export const colors = {
  neutral1000: '#1F29371F',
  neutral900: '#111827',
  neutral800: '#1F2937',
  neutral700: '#374151',
  neutral600: '#4B5563',
  neutral500: '#6B7280',
  neutral300: '#D1D5DB',
  neutral200: '#E5E7EB',
  neutral100: '#F3F4F6',
  neutral50: '#F9FAFB',
  neutral0: '#FFFFFF',
  blue700: '#0062FF7A',
  blue600: '#004ECC',
  blue550: '#005FCC',
  blue500: '#0062FF',
  blue400: '#3381FF',
  blue50: '#E5EFFF',
  violet900: '#090116',
  violet800: '#1C0441',
  violet700: '#493666',
  violet500: '#6B21A8',
  violet50: '#FCFCFA',
  red700: '#B70000',
  red600: '#E50000',
  red500: '#EA3333',
  red50: '#FEF2F2',
  orange700: '#C73B07',
  orange600: '#F05106',
  orange500: '#FF6B0D',
  orange50: '#FFF7ED',
  lightblue500: '#1E85FF',
  lightblue600: '#0663FF',
  lightblue400: '#48ABFF',
  lightblue50: '#EDF7FF',
  green600: '#059669',
  green700: '#047857',
  green500: '#10B981',
  green50: '#ECFDF5',
  gray50: '#1C04410A',
  gray100: '#1F29370A',
};
