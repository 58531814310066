import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AsyncActionStatus } from '../../../actions/common.actionTypes';
import { selectProductMindItemsLoadedStatus, selectProductWithMindItems } from '../../../reducers/products.selectors';
import { CurrentProductContext } from '../CurrentProductContext';
import { useViewMode } from '../../ViewSwitch/ViewModeContext';
import { ItemViewMode } from '../../../types/AppContent';
import { SkeletonMindItemCard } from '../../cards/MindItemCard';
import SkeletonMindItemListItem from '../../cards/MindItemListItem/SkeletonMindItemListItem';
import _ from 'lodash';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { cardDimensions, spaces } from '../../../assets/styles/themes/tokens';
import MindItemMuiCard from '../../cards/MuiCard/MindItemMuiCard/MindItemMuiCard';
import { MindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import VideoMuiCard from '../../cards/MuiCard/VideoMuiCard/VideoMuiCard';

export const NewProductContents = () => {
  const { productId } = useContext(CurrentProductContext);
  const product = useSelector(state => selectProductWithMindItems(state, productId));
  const mindItemsStatus = useSelector(state => selectProductMindItemsLoadedStatus(state, productId));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobile'));

  const { t } = useTranslation();
  const { viewMode } = useViewMode();

  const mindItems = product?.mindItems || [];

  const mindItemCountText = mindItems.length === 1
    ? `${mindItems.length} ${t('Unit')}`
    : `${mindItems.length} ${t('Units')}` ;

  const areMindItemsLoaded = mindItemsStatus && mindItemsStatus.every((status) => status === AsyncActionStatus.READY);

  const getLoadingSkeletons = (viewMode: ItemViewMode) => (
    <>
      {viewMode === 'grid'
        ? _.times(6, i => <SkeletonMindItemCard key={i} />)
        : _.times(6, i => <SkeletonMindItemListItem key={i} />)}
    </>
  );

  const getMindItems = () => {
    return mindItems.map((mindItem: MindItem) => {
      const isVideo = mindItem.fileType === 'video';
      const numberOfPages = isVideo ? undefined : mindItem.pages.length;
      const cardProps = {
        ...mindItem,
        publisher: mindItem.publisher || '',
        description: mindItem.description || '',
        cover: mindItem.thumbnail,
        date: mindItem.publisherReleaseDate,
        itemData: mindItem,
        numberOfPages,
        product: undefined,
      };

      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '100%',
          }}
          key={mindItem.id}
        >
          <Box>
            { isVideo ? <VideoMuiCard {...cardProps} /> : <MindItemMuiCard {...cardProps} /> }
          </Box>
        </Box>
      );
    });
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: isMobile ? 0 : spaces.md,
      width: '100%',
      maxWidth: cardDimensions.desktop.maxWidth,
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${spaces.md} 0px`,
      }}>
        <Typography variant='subtitle1' color='text.secondary'>{mindItemCountText}</Typography>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: spaces.md
      }}>
        { areMindItemsLoaded ? getMindItems() : getLoadingSkeletons(viewMode) }
      </Box>
    </Box>
  );
};
