import React, { useState } from 'react';
import './searchFiltersContainerLegacy.scss';
import {
  FilterByLearningFormat,
  FilterBySchoolType,
  FilterBySubTopic,
  FilterBySubject,
  FilterByClassYears,
  FilterByPublisher,
  FilterByWorkload,
  FilterByTeachingFormat,
} from '../../SearchFilters';
import { SearchFilters } from '../../../types/search';
import useRouter from '../../../utils/useRouter';
import useQueryModification from '../../../routes/SearchPage/useQueryModification';
import { DisabledSearchFilters } from '../../../routes/SearchPage/SearchPage';
import SearchFiltersCarousel from '../SearchFiltersCarousel';
import useMedia from '../../../utils/useMedia';
import services from '../../../services';
import { trackingEvents } from '../../../services/tracking/trackConfig';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Button from '../../common/Button';
import { useAmplitudeExperiment } from '../../../utils/ampli';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../reducers/user.selectors';
import config from 'config';

interface SearchFiltersContainerProps {
  requestFilters: SearchFilters;
  disabledFilters: DisabledSearchFilters;
  trackingPayload: { other: Record<string, unknown> };
}

type FilterType = {
  filter: React.ReactNode;
  id: keyof SearchFilters;
};

const SearchFiltersContainer: React.FC<SearchFiltersContainerProps> = (
  { requestFilters, disabledFilters, trackingPayload }
) => {
  const { router, match } = useRouter();
  const { clearQueryParams, updateQueryParams } = useQueryModification<SearchFilters>(router, match);
  const { isCoarsePointer } = useMedia();
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const {
    variant: showLessFiltersFlag,
    isReady: isLessAmplitudeReady,
  } = useAmplitudeExperiment(user, config.amplitudeExperiments['showLessFilters']);
  const {
    variant: showResetFiltersFlag,
    isReady: isResetAmplitudeReady,
  } = useAmplitudeExperiment(user, config.amplitudeExperiments['showResetFilters']);

  const isShowLessFiltersFlagOn = isLessAmplitudeReady && showLessFiltersFlag === 'on';
  const isShowResetFiltersFlagOn = isResetAmplitudeReady && showResetFiltersFlag === 'on';

  const [showHiddenFilters, setShowHiddenFilters] = useState(() => {
    if (!match.location) return true;

    const searchQueryFilters = Object.keys(match.location.query);
    const hiddenFilters = [ 'contentType', 'learningFormat', 'publisher', 'teachingFormat', 'workload' ];
    const searchQueryNonDefaultFilters = searchQueryFilters.filter(filter => hiddenFilters.includes(filter));
    const hasHiddenFiltersOnLoad = !!searchQueryNonDefaultFilters.length;

    return hasHiddenFiltersOnLoad;
  });

  const hasFilters = Object.keys(requestFilters).filter(filter => filter !== 'sortBy' && filter !== 'term').length > 0;
  const showAllFilters = !isShowLessFiltersFlagOn || showHiddenFilters; // By default show all filters
  const showResetFilters = isShowResetFiltersFlagOn && hasFilters;

  const getShowHiddenFiltersButton = () => (
    <Button
      variant='subtle'
      onClick={() => setShowHiddenFilters(!showHiddenFilters)}
      dataTestId='show-more-filters-button'
      className='show-more-filters-button'>
      {t('More Filters')}
    </Button>
  );

  const handleFilterUpdate = (params: SearchFilters) => {
    updateQueryParams(params);

    for (const [key, value] of Object.entries(params)) {
      let parsedValue = value;
      if (!value || value.length === 0) {
        return;
      }

      if (Array.isArray(value)) {
        parsedValue = parsedValue.join(',');
      }

      services.track.eventTrack(
        trackingEvents.searchFilterSave,
        _.merge(_.cloneDeep(trackingPayload), { other: { filter: key, items: parsedValue } })
      );
    }
  };

  const handleFilterClear = (filter: keyof SearchFilters | (keyof SearchFilters)[]) => {
    if (!Array.isArray(filter)) {
      filter = [filter];
    }

    clearQueryParams(filter);
  };

  const defaultFilters: FilterType[] = [
    {
      filter:
        <FilterBySubject
          updateFilter={(subject) => handleFilterUpdate({
            subject, subTopic: subject?.length > 0 ? requestFilters.subTopic : undefined })}
          clearFilter={() => handleFilterClear(['subject', 'subTopic'])}
          currentValue={requestFilters.subject}
        />,
      id: 'subject'
    },
    {
      filter:
        !!requestFilters.subject &&
        <FilterBySubTopic
          subjects={requestFilters.subject}
          updateFilter={(subTopic) => handleFilterUpdate({ subTopic })}
          clearFilter={() => handleFilterClear('subTopic')}
          currentValue={requestFilters.subTopic}
          disabled={disabledFilters.subTopic}
        />,
      id: 'subTopic'
    },
    {
      filter:
          <FilterByClassYears
            updateFilter={({ min, max }) => handleFilterUpdate({ minClassYear: min, maxClassYear: max })}
            clearFilter={() => handleFilterClear(['minClassYear', 'maxClassYear'])}
            currentValue={{ min: requestFilters.minClassYear, max: requestFilters.maxClassYear }}
          />,
      id: 'minClassYear'
    },
    {
      filter:
        <FilterBySchoolType
          updateFilter={(schoolType) => handleFilterUpdate({ schoolType })}
          clearFilter={() => handleFilterClear('schoolType')}
          currentValue={requestFilters.schoolType}
        />,
      id: 'schoolType'
    }
  ];

  const hiddenFilters: FilterType[] = [
    {
      filter:
        <FilterByPublisher
          updateFilter={(publisher) => handleFilterUpdate({ publisher })}
          clearFilter={() => handleFilterClear('publisher')}
          currentValue={requestFilters.publisher}
        />,
      id: 'publisher'
    },
    {
      filter:
        <FilterByLearningFormat
          updateFilter={(learningFormat) => handleFilterUpdate({ learningFormat })}
          clearFilter={() => handleFilterClear('learningFormat')}
          currentValue={requestFilters.learningFormat}
        />,
      id: 'learningFormat'
    },
    {
      filter:
        <FilterByTeachingFormat
          updateFilter={(teachingFormat) => handleFilterUpdate({ teachingFormat })}
          clearFilter={() => handleFilterClear('teachingFormat')}
          currentValue={requestFilters.teachingFormat}
        />,
      id: 'teachingFormat'
    },
    {
      filter:
        <FilterByWorkload
          updateFilter={(workload) => handleFilterUpdate({ workload })}
          clearFilter={() => handleFilterClear('workload')}
          currentValue={requestFilters.workload}
        />,
      id: 'workload'
    },
  ];

  const wrappedDefaultFilters = defaultFilters.map(item =>
    item.filter && (
      <div key={item.id} data-testid={item.id} className='filter-container'>
        {item.filter}
      </div>
    )
  );

  const wrappedHiddenFilters = hiddenFilters.map(item =>
    item.filter && (
      <div key={item.id} data-testid={item.id} className='filter-container'>
        {item.filter}
      </div>
    )
  );

  const allFilters =  [...wrappedDefaultFilters, ...wrappedHiddenFilters];

  const getResetFiltersButton = () => {
    const resetFilters = () => {
      return Object.keys(requestFilters).forEach((filter) => { 
        if (filter === 'sortBy' || filter === 'term') return;
        handleFilterClear(filter as keyof SearchFilters);
      });
    };

    return (
      <span
        className='reset-filters-text'
        data-testid='reset-filters-button'
        onClick={resetFilters}
      >
        {t('Reset all')}
      </span>
    );
  };

  return (
    <>
      {
        isCoarsePointer ?
          <SearchFiltersCarousel
            className='filters-carousel'
            filters={allFilters}
            showResetFilters={showResetFilters}
            getResetFiltersButton={getResetFiltersButton}
          />
          :
          <div className='search-filters-container' data-testid='search-filters'>
            {wrappedDefaultFilters}
            {showAllFilters && wrappedHiddenFilters}
            {!showAllFilters && getShowHiddenFiltersButton()}
            {showResetFilters && getResetFiltersButton()}
          </div>
      }
    </>
  );
};

export default SearchFiltersContainer;
