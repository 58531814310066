import Icon from '../Icon';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Trans, useTranslation } from 'react-i18next';
import { externalLinks, emailAddresses } from '../../constants';

const Email = () => (
  <a href={`mailto:${emailAddresses.support}`}>{emailAddresses.support}</a>
);

const FAQ = () => (
  <a href={externalLinks.faq} target='_blank' rel='noreferrer noopener'>hier</a>
);

interface ContactModalProps {
  show: boolean;
  onClose: () => void;
}

const ContactModal: React.FC<ContactModalProps> = ({ show, onClose }) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton={true}>
        <Modal.Title className='d-flex'>
          <Icon icon='subscription' className='mr-1' />
          {t('Contact')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Trans i18nKey='Do you have a question? Anything not clear? You can contact us'>
          <>
            <p>Do you have a question? You&apos;ll find the answer here <FAQ />.</p>
            <p>Anything not clear?</p>
            <p> Write us to <Email />.</p>
            <p>We are there for you from Monday to Friday between 10 a.m. and 6 p.m.</p>
          </>
        </Trans>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='outline-secondary'
          onClick={onClose}
        >
          {t('Ok')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ContactModal;
