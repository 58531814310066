import React from 'react';
import CollectionContextSelected from './CollectionContextSelected';
import CollectionContextUnselected from './CollectionContextUnselected';
import { CollectionProperties } from '../../../types/AppContent';

interface CollectionContextProps {
  collection?: CollectionProperties;
  ownCollection: boolean;
  linkRoot: string;
}

const CollectionContext: React.FC<CollectionContextProps> = ({ collection, ...props }) => {
  if (typeof collection !== 'undefined') {
    return <CollectionContextSelected collection={collection} {...props} />;
  } else {
    return <CollectionContextUnselected />;
  }
};

export default CollectionContext;
