import React from 'react';
import MindItemContext from './MindItemContext';
import NothingContext from './NothingContext';
import ProductContext from './ProductContext';
import { ContentItem } from '../../../types/AppContent';

interface ItemsContextProps {
  item?: ContentItem;
}

const ItemContext: React.FC<ItemsContextProps> = ({ item }) => {
  if (typeof item !== 'undefined') {
    if (item.type === 'product') {
      return (<ProductContext product={item} />);
    } else if (item.type === 'mindItem') {
      return (<MindItemContext mindItem={item} />);
    }
  }

  return (<NothingContext/>);
};

export default ItemContext;
