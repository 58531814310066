import React from 'react';
import { AsyncActionStatus } from '../../actions/common.actionTypes';
import { CarouselTrackingProperties } from '../../types/AppContent';
import { SlideDefinition } from '../Carousel/Carousel';
import CarouselSkeleton from '../Carousel/CarouselSkeleton';
import DashboardCarousel from './DashboardCarousel';
import DashboardSlideItem from './DashboardSlideItem';
import { useTranslation } from 'react-i18next';
import { getProductsBySubjectId, getProductsBySubjectIdStatus } from '../../reducers/dashboard.selectors';
import { useSelector } from 'react-redux';
import { selectUser } from '../../reducers/user.selectors';
import { routes } from '../../constants';
import querystring from 'query-string';
import { SearchFilters, SearchResultsTab } from '../../types/search';

export interface CarouselData {
  status?: AsyncActionStatus;
  schoolTypes?: string[];
  subjectId: string;
  subjectText: string;
}

interface DashboardSubjectCarouselProps extends CarouselData {
  carouselPosition: number;
  numberOfSlides: SlideDefinition;
}

const DashboardSubjectCarousel: React.FC<DashboardSubjectCarouselProps> = (props) => {
  const { t } = useTranslation();
  const products = useSelector(state => getProductsBySubjectId(state, props.subjectId));
  const loadingStatus = useSelector(state => getProductsBySubjectIdStatus(state, props.subjectId));
  const user = useSelector(selectUser);

  const carouselTrackingData: CarouselTrackingProperties = {
    carouselPosition: props.carouselPosition,
    carouselDocumentIds: products.map(product => product.id),
    carouselType: 'carousel',
    carouselSubjectTexts: [props.subjectText],
    carouselSchoolTypes: user?.schoolType ? [user.schoolType] : undefined,
    carouselClassYears: user?.classes,
    carouselSubject: props.subjectText
  };

  const searchParams: SearchFilters = {
    subject: [props.subjectId],
    maxClassYear: (user?.classes && user.classes.length > 0) ? Math.max(...user.classes) : undefined,
    minClassYear: (user?.classes && user.classes.length > 0) ? Math.min(...user.classes) : undefined,
    sortBy: 'createdDate'
  };

  const qs = querystring.stringify(searchParams, { skipNull: true });
  const uri = `/${routes.search.root}/${SearchResultsTab.PRODUCTS}?${qs}`;

  return (
    <DashboardCarousel
      {...carouselTrackingData}
      title={`${t('New in the subject')} ${props.subjectText}`}
      uri={uri}
      numberOfSlides={props.numberOfSlides}
      slides={products.map(product => (
        product ? <DashboardSlideItem
          {...carouselTrackingData}
          key={product.id}
          item={product}
          type={'product'}
        /> : null
      ))}
      infinite={true}
      isLoading={!loadingStatus || loadingStatus === AsyncActionStatus.LOADING}
      skeleton={
        <CarouselSkeleton
          height={376}
          numberOfSlides={props.numberOfSlides}
          width={256}
        />
      }
    />
  );
};

export default DashboardSubjectCarousel;
