import React from 'react';
import { AsyncActionStatus } from '../../actions/common.actionTypes';
import { ContentItem } from '../../types/AppContent';
import { SlideDefinition } from '../Carousel/Carousel';
import CarouselSkeleton from '../Carousel/CarouselSkeleton';
import DashboardCarousel from './DashboardCarousel';
import DashboardSlideItem from './DashboardSlideItem';
import { useTranslation } from 'react-i18next';

interface DashboardRecommendedProductsCarouselProps {
  carouselPosition: number;
  items: ContentItem[];
  numberOfSlides: SlideDefinition;
  status: AsyncActionStatus;
}

const DashboardRecommendedProductsCarousel:
  React.FC<DashboardRecommendedProductsCarouselProps> = ({ items, ...props }) => {
    const { t } = useTranslation();

    const itemIds = items.map(item => item.id);

    return (
      <DashboardCarousel
        title={t('Your recommended products')}
        numberOfSlides={props.numberOfSlides}
        carouselDocumentIds={itemIds}
        carouselType='recommendedProducts'
        carouselPosition={props.carouselPosition}
        slides={items.map(item => (
          <DashboardSlideItem
            key={item.id}
            item={item}
            type={item.type}
            carouselPosition={props.carouselPosition}
            carouselDocumentIds={itemIds}
            carouselType='recommendedProducts'
          />
        ))}
        infinite={true}
        isLoading={props.status === 'loading'}
        skeleton={
          <CarouselSkeleton
            height={376}
            numberOfSlides={props.numberOfSlides}
            width={256}
          />
        }
      />
    );
  };

export default React.memo(DashboardRecommendedProductsCarousel);
