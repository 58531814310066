import React, { useEffect } from 'react';
import { SlideDefinition } from '../Carousel/Carousel';
import DashboardSubjectCarousel from './DashboardSubjectCarousel';
import useAction from '../../utils/useAction';
import { fetchProductsByQuery as fetchProductsByQueryAction } from '../../actions/product.actions';
import { GetProductsOptions, GetProductsQuery } from '@klab-berlin/api-sdk/lib/services/product';
import constants, { CAROUSEL_DEFAULT_LENGTH } from '../../constants';
import { useSelector } from 'react-redux';
import { selectUser } from '../../reducers/user.selectors';

interface DashboardAllSubjectsCarouselsProps {
  firstCarouselPosition: number;
  numberOfSlides: SlideDefinition;
}

const DEFAULT_SUBJECT_ID = 'Didaktik-Methodik';
const defaultSubject = constants.subjectsById[DEFAULT_SUBJECT_ID];

const DashboardAllSubjectsCarousels: React.FC<DashboardAllSubjectsCarouselsProps> = ({ ...props }) => {

  const queryOptions: GetProductsOptions = {
    limit: CAROUSEL_DEFAULT_LENGTH,
    sort: 'desc',
    sortBy: 'createdDate',
  };

  const user = useSelector(selectUser);
  const userSubjects = user?.subjectsFlattened || [];

  const fetchProductsByQuery = useAction(fetchProductsByQueryAction);

  const getCarouselQueries = (subjects: { id: string }[], classYears?: number[]): GetProductsQuery[] => {
    return subjects
      .map(subject => ({
        subjects: [subject.id],
        ...(classYears?.length ? { classYears } : {}),
      }));
  };

  useEffect(() => {
    // All users should see didactic product recommendations, regardless of
    // their subject / school type choices. This request is separated from the others
    // so that it is only fired once
    fetchProductsByQuery({ subjects: [DEFAULT_SUBJECT_ID] }, queryOptions);
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }
    const carouselQueries = getCarouselQueries(user.subjectsFlattened, user.classes);
    carouselQueries.forEach((query: GetProductsQuery) => fetchProductsByQuery(query, queryOptions));
    // We are using subjects in the dependency array
    // since the reference to subjectsFlattened changes on every selector update
  }, [user?.subjects, user?.classes]);

  return (
    <>
      {[...userSubjects, defaultSubject].map((subject, index) => (
        <DashboardSubjectCarousel
          key={subject.id}
          carouselPosition={props.firstCarouselPosition + index}
          numberOfSlides={props.numberOfSlides}
          subjectId={subject.id}
          subjectText={subject.text}
        />
      ))}
    </>
  );
};

export default React.memo(DashboardAllSubjectsCarousels);
