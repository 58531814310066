import React from 'react';
import IcoMoon from 'react-icomoon';
import iconSet from '../../../assets/icons/mu-icomoon.json';
import './muIcon.scss';

interface MuIconProps {
  className?: string;
  icon: string;
  label?: string;
  testId?: string;
}

const MuIcon: React.FC<MuIconProps> = (props) => {
  return (
    <IcoMoon
      className={props.className || ''}
      iconSet={iconSet}
      icon={props.icon}
      data-icon={props.icon}
      data-testid={props.testId || props.icon}
      aria-label={props.label}
      role='icon'
    />
  );
};

export default MuIcon;
