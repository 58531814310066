import { Link, LocationDescriptor } from 'found';
import React from 'react';

interface Breadcrumb {
  to: LocationDescriptor;
  title: string;
}

interface BreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs }) => (
  <ul className='text-truncate breadcrumbs'>
    {breadcrumbs.map((breadcrumb, index) => (
      <li key={`breadcrumb-${index}`}>
        <Link to={breadcrumb.to}>{breadcrumb.title}</Link>
      </li>
    ))}
  </ul>
);

export default Breadcrumbs;
