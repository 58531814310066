import { FacebookCredentials } from '@klab-berlin/api-sdk/lib/types/requests/Auth';
import api from '../clients/api';
import { notifyBugsnagNetwork } from '../utils/bugsnagClient';

const service = {
  login,
  loginFB,
  logout,
  validateCaptcha
};

function login(username: string, password: string) {
  notifyBugsnagNetwork('auth', 'login', { username, password: !!password });
  return api.auth.login({ username, password });
}

function loginFB(facebookId: string, facebookToken: string, tracking: FacebookCredentials['tracking']) {
  notifyBugsnagNetwork('auth', 'loginFB', {
    facebookId,
    facebookToken: facebookToken.substr(0, 3),
  });

  return api.auth.facebookLogin({ facebookId, facebookToken, tracking });
}

function logout() {
  notifyBugsnagNetwork('auth', 'logout');
  return api.auth.logout();
}

function validateCaptcha(solution: string, sitekey: string) {
  notifyBugsnagNetwork('auth', 'validateCaptcha');
  return api.auth.validateCaptcha({ solution, sitekey });
}

export default service;
