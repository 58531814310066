import React, { useState } from 'react';
import ShareVideoModal from '../components/modals/ShareVideoModal/ShareVideoModal';
import services from '../services';

type ShowFunc = (publicVideoLink: string) => void;
const noop = () => undefined;

interface Context {
  isOpen: boolean;
  show: ShowFunc;
}

const defaultContext: Context = {
  isOpen: false,
  show: noop as ShowFunc,
};

const ShareVideoModalContext = React.createContext(defaultContext);

const ShareVideoModalProvider: React.FC = (props) => {
  const [publicVideoLink, setPublicVideoLink] = useState('');
  const [error, setError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  
  const setModalVisibility = async (open: boolean, mindItemId?: string) => {
    if (mindItemId) {
      await services.mindItem.createPublicMindItemLink(mindItemId)
        .then(result => setPublicVideoLink(result.publicLink))
        .catch(() => setError(true));
    }
    setIsOpen(open);
  };

  return (
    <ShareVideoModalContext.Provider value={{
      show: (mindItemId) => setModalVisibility(true, mindItemId),
      isOpen,
    }}>
      <ShareVideoModal
        show={isOpen}
        publicVideoLink={publicVideoLink}
        onClose={() => setModalVisibility(false)}
        showError={error}
      />
      {props.children}
    </ShareVideoModalContext.Provider>
  );
};

export { ShareVideoModalContext, ShareVideoModalProvider };
