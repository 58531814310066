import { Match } from 'found';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fetchCollections } from '../actions/collections.actions';
import CollectionContext from '../components/contexts/CollectionContext';
import CollectionsAppLayout from '../components/CollectionsAppLayout';
import CollectionsList from '../components/CollectionsList';
import withAsyncData from '../components/utils/withAsyncData';
import { routes } from '../constants';
import { StoreType } from '../reducers';
import { selectFollowedCollections } from '../reducers/collections.selectors';
import Breadcrumbs from '../components/Breadcrumbs';
import { CollectionProperties } from '../types/AppContent';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import EmptyState from '../components/EmptyState';

interface StateProps {
  collections: CollectionProperties[];
  collectionsStatus: AsyncActionStatus;
}

type FollowedCollectionListPageProps = StateProps & Match;

const FollowedCollectionListPage: React.FC<FollowedCollectionListPageProps> = ({
  collections,
  collectionsStatus,
}) => {
  const [activeId, setActiveId] = useState('');
  const { t } = useTranslation();
  const activeCollection = collections.find((collection) => collection.id === activeId);

  return (
    <CollectionsAppLayout
      header={
        <Breadcrumbs breadcrumbs={[
          { to: `/${routes.followed.root}`, title: t('I follow') },
        ]}/>
      }
      mainBody={
        <CollectionsList
          activeId={activeId}
          emptyText={<FollowedCollectionsEmptyState />}
          linkRoot={routes.followed.root}
          setActiveId={setActiveId}
          collections={collections}
          collectionsStatus={collectionsStatus}
        />
      }
      context={
        collections.length ?
          <CollectionContext
            ownCollection={false}
            collection={activeCollection}
            linkRoot={routes.followed.root}
          /> : <></>
      }
      setActiveId={setActiveId}
    />
  );
};

const FollowedCollectionsEmptyState = () => {
  const { t } = useTranslation();

  return (
    <EmptyState
      iconName='cabinet'
      heading={t('You are not following any collections currently')}
      text={
        <Trans i18nKey='Follow curated collection to find them easier' key={1}>
          <p>
            <a href={`/${routes.curatedCollections.root}`}>kuratierten Kollektionen</a>
          </p>
        </Trans>
      }
    />
  );
};

const mapState = (state: StoreType): StateProps => ({
  collections: selectFollowedCollections(state),
  collectionsStatus: state.collections.followed.status,
});

export default compose<React.FC<Record<string,never>>>(
  connect(
    mapState,
    { fetchCollections },
  ),
  withAsyncData((props) => ({
    refetch: () => props.fetchCollections(),
    dataProp: 'collections',
    statusProp: 'collectionsStatus',
  })),
)(FollowedCollectionListPage);
