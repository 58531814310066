import React, { useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { spaces, palette } from '../../../../assets/styles/themes/tokens';
import createNonPropagatingEventHandler from '../../../../utils/createNonPropagatingEventHandler';
import getTextTruncationSx from '../../../../utils/getTextTruncationSx';

interface MuiCardBaseLongDescriptionProps {
  description?: string;
  lines?: number;
  isExpanded: boolean;
  onShowToggleClick: () => void;
}

const MuiCardBaseLongDescription: React.FC<MuiCardBaseLongDescriptionProps> = ({
  description,
  lines = 3,
  isExpanded,
  onShowToggleClick: onShowMoreClick,
}) => {
  const { t } = useTranslation();
  const [isOverflowing, setIsOverflowing] = React.useState(false);
  const descriptionRef = React.useRef<HTMLParagraphElement>(null);

  if (!description) return null;

  useEffect(() => {
    const debounceFn = debounce((entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        const newIsOverflowing = entry.target.scrollHeight > entry.target.clientHeight;
        setIsOverflowing(newIsOverflowing);
      }
    }, 50);
    const observer = new ResizeObserver(debounceFn);
    if (descriptionRef.current) {
      observer.observe(descriptionRef.current);
    }
    return () => {
      if (descriptionRef.current) {
        observer.unobserve(descriptionRef.current);
      }
      debounceFn.cancel();
    };
  }, [descriptionRef.current]);

  const renderDescriptionExpand = () => {
    const showLess = isExpanded;
    const showMore = isOverflowing && !isExpanded;
    const ExpansionButton: React.FC = (props) => <Box component='span'>
      <Button
        sx={{
          marginLeft: `-${spaces.xs}`,
        }}
        variant='text'
        size='small'
        color='secondary'
        onClick={createNonPropagatingEventHandler(onShowMoreClick)}
      >
        {props.children}
      </Button>
    </Box>;
    if (showLess) {
      return <ExpansionButton> {t('Show less')} </ExpansionButton>;
    }
    if (showMore) {
      return <ExpansionButton> {t('Show more')} </ExpansionButton>;
    }
    return <></>;
  };

  return (
    <Box
      data-testid='mui-card-desktop__description'
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Typography variant='body2'
        ref={descriptionRef}
        sx={{
          ...(isExpanded ? { } : { ...getTextTruncationSx(lines) }),
          color: palette.text.secondary,
        }}
        dangerouslySetInnerHTML={{ __html: description }} >
      </Typography>
      { renderDescriptionExpand() }
    </Box>
  );
};

export default MuiCardBaseLongDescription;
