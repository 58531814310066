import { Match } from 'found';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import CollectionsAppLayout from '../components/CollectionsAppLayout';
import ItemContext from '../components/contexts/ItemContext';
import ItemsList from '../components/ItemsList';
import { routes } from '../constants';
import { StoreType } from '../reducers';
import Breadcrumbs from '../components/Breadcrumbs';
import { ContentItem } from '../types/AppContent';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import { selectDownloadedItems } from '../reducers/download.selectors';
import { fetchDownloadContents } from '../actions/download.actions';
import { noop } from 'lodash';

interface StateProps {
  downloadedItems: ContentItem[];
  downloadStatus: AsyncActionStatus;
  user: StoreType['user']['me'];
}

interface Actions {
  fetchDownloadContents: () => void;
}

type DownloadListPageProps = StateProps & Match & Actions;

const DownloadListPage: React.FC<DownloadListPageProps> = ({
  downloadedItems,
  downloadStatus,
  user,
  fetchDownloadContents,
}) => {

  const [activeId, setActiveId] = useState('');
  const activeItem = downloadedItems.find((item) => item.id === activeId);
  const { t } = useTranslation();

  const userId = user && user._id;
  useEffect(() => {
    if (userId) {
      fetchDownloadContents();
    }
  }, [userId]);

  return (
    <CollectionsAppLayout
      header={
        <Breadcrumbs breadcrumbs={[
          { to: `/${routes.downloads.root}`, title: t('Downloads') },
        ]}/>
      }
      mainBody={
        <ItemsList
          isLoading={downloadStatus === 'loading'}
          hasNext={false}
          total={downloadStatus === 'loading' ? -1 : downloadedItems.length}
          loadNext={noop}
          activeId={activeId}
          items={downloadedItems}
          setActiveId={setActiveId}
          linkRoot={[routes.downloads.root]}
        />
      }
      context={
        downloadedItems.length ? <ItemContext item={activeItem} /> : <></>
      }
      setActiveId={setActiveId}
    />
  );
};

const mapState = (state: StoreType): StateProps => ({
  user: state.user.me,
  downloadedItems: selectDownloadedItems(state),
  downloadStatus: state.download.status,
});

export default compose<React.FC<Record<string,never>>>(
  connect(mapState, { fetchDownloadContents }),
)(DownloadListPage);
