import React from 'react';
import { Editor, useEditor, EditorContent } from '@tiptap/react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { spaces } from '../../../assets/styles/themes/tokens';
import ContextMenu from './ContextMenu';
import { TipTapExtensionKit } from '../../../utils/tipTapEditor';

const StyledEditorContent = styled(EditorContent)({
  '& .tiptap p.is-editor-empty:first-of-type::before': {
    content: 'attr(data-placeholder)',
    opacity: 0.5,
    float: 'left',
    height: 0,
    pointerEvents: 'none',
  },
  '& .tiptap pre': {
    background: 'black',
    borderRadius: '0.5rem',
    color: 'white',
    fontFamily: 'monospace',
    margin: '1.5rem 0',
    padding: '0.75rem 1rem',
  },
  '& .tiptap pre code': {
    background: 'none',
    color: 'inherit',
    fontSize: '0.8rem',
    padding: 0,
  },
});

interface OwnProps {
  content: string;
}

const RichTextEditor: React.FC<OwnProps> = (props) => {
  const { content } = props;
  const editor: Editor | null = useEditor(
    {
      content,
      extensions: TipTapExtensionKit,
      editorProps: {
        attributes: {
          autocomplete: 'off',
          autocorrect: 'off',
          autocapitalize: 'off',
        },
      },
    },
    [],
  );

  if (!editor) return null;  

  return (
    <Box 
      data-testid='text-editor-container'
      sx={{
        padding: spaces.lg,
      }}>
      <StyledEditorContent editor={editor}/>
      <ContextMenu editor={editor}/>
    </Box>
  );
};

export default RichTextEditor;
