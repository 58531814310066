import api from '../clients/api';
import { SearchRequest } from '@klab-berlin/api-sdk/lib/types/requests/Common';
import { handleUnauthorized } from '../utils/apiWrapper';

const search = {
  unifiedSearch,
};

function unifiedSearch(searchParams: SearchRequest) {
  return handleUnauthorized(() => api.search.unifiedSearch(searchParams));
}

export default search;
