import React, { useState } from 'react';
import { Hint, HintProps } from '../Hint';

export interface OnboardingHintProps extends Omit<HintProps, 'open' | 'onClose'> {
  id: string;
  onClose?: () => void;
}

export const OnboardingHint: React.FC<OnboardingHintProps> = ({ id, ...props }) => {
  const localStorageKey = `hint-${id}`;
  const [shownToUser, setShownToUser] = useState(
    localStorage.getItem(localStorageKey) === 'true'
  );

  const dismiss = () => {
    localStorage.setItem(localStorageKey, 'true');
    setShownToUser(true);
    props?.onClose?.();
  };

  const actions = props.actions;

  const createOnClickHandler = (originalOnClick: () => void) => {
    return () => {
      originalOnClick();
      dismiss();
    };
  };

  if (actions) {
    if (actions.primary) {
      actions.primary.onClick = createOnClickHandler(actions.primary.onClick);
    }
    if (actions.secondary) {
      actions.secondary.onClick = createOnClickHandler(actions.secondary.onClick);
    }
  }

  return <Hint
    {...props}
    actions={actions}
    open={!shownToUser}
    onClose={dismiss}
  />;
};

export default OnboardingHint;
