const getTextTruncationSx = (lines?: number): any => {
  return ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    wordBreak: 'break-word',
    WebkitLineClamp: lines || 1,
    WebkitBoxOrient: 'vertical',
  });
};

export default getTextTruncationSx;
