import React from 'react';
import subjectSVGs from '../../assets/subjects/icons';
import constants from '../../constants';

interface SubjectButtonProps {
  subjectId: keyof typeof subjectSVGs;
}

const Subject: React.FC<SubjectButtonProps> = (props) => {
  const subjectId = props.subjectId;
  const SubjectIcon = subjectSVGs && subjectSVGs[subjectId];

  return (
    <div
      className='justify-content-between d-flex'
    >
      {constants.subjectsById[subjectId].text}
      {SubjectIcon && <SubjectIcon className='icon-dark' />}
    </div>
  );
};

export default Subject;
