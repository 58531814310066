import React, { useEffect, useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { updateMe as updateMeAction } from '../../../actions/user.actions';
import { selectUser } from '../../../reducers/user.selectors';
import useAction from '../../../utils/useAction';
import ActionButton from '../../ActionButton';
import Button from '../../common/Button';
import SubjectSelection from '../../SubjectSelection';
import { SelectedSubjects } from '../../SubjectSelection/SubjectSelection';
import './subjectSelectionModal.scss';

interface OwnProps {
  show: boolean;
  onClose: () => void;
}

const SubjectSelectionModal: React.FC<OwnProps> = (props) => {
  const { t } = useTranslation();

  const user = useSelector(selectUser);
  const [isPrioritizationStep, setIsPrioritizationStep] = useState(false);
  const [hasValidationError, setHasValidationError] = useState(false);

  const defaultSelectedSubjects = {
    majors: [],
    minors: [],
    isPrioritized: false,
  };
  const [selectedSubjects, setSelectedSubjects] = useState<SelectedSubjects>(defaultSelectedSubjects);

  const [hasServerError, setHasServerError] = useState(false);
  const updateMe = useAction(updateMeAction);

  useEffect(() => {
    if (!user) return;

    const userPrioritized = user.subjects.prioritized;
    const initialSelectedSubjects = {
      majors: user.subjects.majors.map(s => s.subject),
      minors: user.subjects.minors.map(s => s.subject),
      isPrioritized: userPrioritized === undefined ? true : userPrioritized
    };

    setSelectedSubjects(initialSelectedSubjects);
  }, [user]);

  const handleSave = () => {
    const subjects = {
      majors: selectedSubjects.majors.map((subject, i) => ({ subject, priority: i })),
      minors: selectedSubjects.minors.map((subject, i) => ({ subject, priority: i })),
      prioritized: selectedSubjects.isPrioritized
    };

    setHasServerError(false);
    updateMe({ subjects })
      .then(() => {
        props.onClose();
      })
      .catch(() => {
        setHasServerError(true);
      });
  };

  const openPrioritizationStep = () => {
    setHasValidationError(false);

    if (selectedSubjects.majors.length === 0 && selectedSubjects.minors.length === 0) {
      setHasValidationError(true);
      return;
    }

    setIsPrioritizationStep(true);
  };

  return (
    <Modal show={props.show} onHide={props.onClose} className='subject-selection-modal'>
      <Modal.Header className='subject-selection-modal__header'>
        <Modal.Title className='subject-selection-modal__title'>
          {isPrioritizationStep
            ? t('Prioritize your subjects')
            : t('Select your subjects')
          }
        </Modal.Title>
        <ActionButton icon='cancel' size='xl'
          iconClassName='subject-selection-modal__close-icon'
          onClick={props.onClose} />
      </Modal.Header>
      <Modal.Body className='subject-selection-modal__body'>
        <div className='subject-selection-modal__text'>
          {isPrioritizationStep
            ? t('Please arrange your subjects according to relevance.')
            : t('Select all subjects that are relevant to you.')
          }
        </div>
        <div className='subject-selection-modal__subject-list'>
          {hasValidationError &&
            <Alert variant='danger'>
              {`
                ${t('Please select at least one subject')}.
                ${t('If your subject isn\'t in the list, choose the button \'none of these options\' at the end')}.
              `}
            </Alert>
          }
          <SubjectSelection
            selectedSubjects={selectedSubjects}
            onChange={(subjects) => setSelectedSubjects(subjects)}
            isPrioritizationStep={isPrioritizationStep}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!isPrioritizationStep &&
          <>
            <Button variant='ghost' onClick={() => props.onClose()}>{t('Cancel')}</Button>
            <Button type='button' onClick={() => openPrioritizationStep()}>{t('Prioritize')}</Button>
          </>
        }
        {isPrioritizationStep &&
          <>
            <Button variant='ghost' onClick={() => setIsPrioritizationStep(false)}>{t('Back')}</Button>
            <Button onClick={handleSave}>{t('Save')}</Button>
          </>
        }
        {hasServerError &&
          <div className='subject-selection-modal__error'>
            {`${t('Ups, something went wrong')} ${t('Please try again')}`}
          </div>
        }
      </Modal.Footer>
    </Modal>
  );
};

export default SubjectSelectionModal;
