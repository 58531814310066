import React from 'react';
import { useTranslation } from 'react-i18next';
import PersonalDetailsSection from '../Common/PersonalDetailsSection';
import TeachingProfileForm from './TeachingProfileForm';
import TeachingProfileView from './TeachingProfileView';

const TeachingProfile = () => {
  const { t } = useTranslation();
  return (
    <PersonalDetailsSection 
      title={t('My Teaching Profile')}
      renderNormalView={() => <TeachingProfileView />}
      renderFormView={(closeEditMode) =>
        <TeachingProfileForm onCancel={closeEditMode} onSave={closeEditMode} />}
    />
  );
};

export default TeachingProfile;
