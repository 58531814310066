import { SchoolTypeListResponse } from '@klab-berlin/api-sdk/lib/types/responses/Text';
import { AsyncAction } from './common.actionTypes';

export enum FETCH_SCHOOL_TYPES {
  START = 'FETCH_SCHOOL_TYPES_START',
  SUCCESS = 'FETCH_SCHOOL_TYPES_SUCCESS',
  ERROR = 'FETCH_SCHOOL_TYPES_ERROR',
}

export type FetchSchoolTypesAction = AsyncAction<
  FETCH_SCHOOL_TYPES.START,
  FETCH_SCHOOL_TYPES.SUCCESS,
  FETCH_SCHOOL_TYPES.ERROR,
  SchoolTypeListResponse
>;
