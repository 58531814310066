import { SearchSuggestionsResponseData } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import classNames from 'classnames';
import Downshift, { DownshiftState, StateChangeOptions } from 'downshift';
import React, { useCallback, useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Icon from '../Icon';
import services from '../../services';
import { useTranslation } from 'react-i18next';
import './searchBarTop.scss';
import useRouter from '../../utils/useRouter';
import { routes } from '../../constants';
import { Subject } from '../../types/search';
import { getRecentSearches } from '../../utils/recentSearches';
import { useSelector } from 'react-redux';
import { selectUser } from '../../reducers/user.selectors';
import _ from 'lodash';
import { isClientNetworkError } from '../../utils/isClientNetworkError';
import { notifyBugsnagHandledError } from '../../utils/bugsnagClient';
import { getAmpliSearchEventPayload, mapDesignPreference, useAmplitudeExperiment } from '../../utils/ampli';
import { SearchSubmitted } from '../../ampli';
import config from 'config';
import { useNewDesign } from '../../state/DesignPreferenceContextProvider';
import { ObjectStringMap } from 'found';

interface SearchBarTopProps {
  className?: string;
}

let timeout: number | null = null;

const SearchBarTop: React.FC<SearchBarTopProps> = (props) => {
  const { router, match } = useRouter();
  
  const [searchTerm, setSearchTerm] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState<SearchSuggestionsResponseData[]>([]);
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const userId = user && user.id;
  const { prefersNewDesign, shouldShowDesignPreferenceSwitch } = useNewDesign();
  const {
    variant: searchVariant,
    isReady: isSearchAmplitudeReady,
  } = useAmplitudeExperiment(user, config.amplitudeExperiments['search']);
  const isNewSearch = isSearchAmplitudeReady && searchVariant !== 'control';

  useEffect(() => {
    // Cleaning asynchronous tasks to prevent memory leaks
    return () => {
      if (timeout !== null) {
        window.clearTimeout(timeout);
      }
    };
  }, []);

  useEffect(() => {
    const termParam = match.location.query['term'];
    setSearchTerm(termParam || '');
  }, [match.location.query['term']]);

  const updateSuggestions = useCallback((searchTerm: string) => {
    setSearchTerm(searchTerm);

    if (timeout !== null) {
      window.clearTimeout(timeout);
    }

    if (searchTerm === '') {
      return setSuggestions([]);
    }

    const appendValidRecentSearchSuggestions = (suggestions: Array<SearchSuggestionsResponseData>) => {
      const recentSearches = getRecentSearches(userId);
      if (!recentSearches) return;

      const validRecentSearches = recentSearches.filter(
        search => search.term?.toLowerCase().includes(searchTerm.toLowerCase())
      ).map(validRecentSearch => ({ text: validRecentSearch.term || '' }));

      const recentSearchTerms = validRecentSearches.map(search => search.text);
      _.remove(suggestions, (suggestion) => recentSearchTerms.includes(suggestion.text));
      suggestions.unshift(...validRecentSearches);
    };

    timeout = window.setTimeout(() => {
      services.mindItem.getSearchSuggestions(searchTerm, isNewSearch).then((suggestions) => {
        appendValidRecentSearchSuggestions(suggestions);
        setSuggestions(suggestions);
        timeout = null;
      }).catch(error => { 
        if (isClientNetworkError(error.message)) notifyBugsnagHandledError(error);
      });
    }, 300);
  }, [searchTerm, timeout, isNewSearch]);

  const showRecentSearchSuggestions = () => {
    const recentSearches = getRecentSearches(userId);
    if (searchTerm) return;  // Show this list only on click.
    if (!recentSearches) return;

    const recentSearchSuggestions: Subject[] = recentSearches.map(
      (search, index) => ({ id: String(index), text: search.term || '' })
    );

    setShowSuggestions(true);
    setSuggestions(recentSearchSuggestions);
  };

  const hideRecentSearchSuggestions = () => {
    setShowSuggestions(false);
  };

  const search = (term: string) => {
    const params: { term: string } = { term };

    const isSearchPage = match.location.pathname.startsWith(`/${routes.search.root}`);
    if (user) {
      const searchPayload= getAmpliSearchEventPayload({
        query: match.location.query,
        term,
      });

      if (searchPayload) { 
        const event = new SearchSubmitted(searchPayload as any);  
        if (shouldShowDesignPreferenceSwitch) {
          event.event_properties['ui'] = mapDesignPreference(prefersNewDesign);
        }        
        services.track.ampliEventTrack({ event });
      }
    }

    const createQuery = (params: { term: string }) => {
      const query: ObjectStringMap = isSearchPage ? match.location.query : params;

      // if the user sent an empty search term, we remove the term from the query
      // to avoid having an empty term in the URL and in the search request to the API
      if (params.term === '') {
        delete query.term;
      } else {
        query.term = params.term;
      }

      return query;
    };
    router.push({
      pathname: isSearchPage ? match.location.pathname : `/${routes.search.root}`,
      query: createQuery(params)
    });
  };

  const onSubmit = (ev: React.FormEvent) => {
    ev.preventDefault();
    search(searchTerm);
  };

  const stateReducer = (state: DownshiftState<any>, changes: StateChangeOptions<any>) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.blurInput:
      case Downshift.stateChangeTypes.mouseUp:
        return { ...state, isOpen: false };
      default:
        return changes;
    }
  };

  const handleChange = (item: SearchSuggestionsResponseData | null) => {
    if (!item) {
      return setSearchTerm('');
    }

    search(item.text);
  };

  return <Form onSubmit={onSubmit}
    className={classNames('search-bar bg--grey-lightest color--grey-medium', props.className)}>
    <div className='search-bar__input'>
      <Downshift
        onChange={handleChange}
        onInputValueChange={updateSuggestions}
        itemToString={item => (item ? item.text : '')}
        stateReducer={stateReducer}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          highlightedIndex,
          clearSelection,
          closeMenu,
        }) => {
          return (
            <div className='h-100'>
              <div className='search-bar__icon search'>
                <Icon icon='search' />
              </div>
              {
                !!searchTerm &&
                <div
                  className='search-bar__icon reset cursor-pointer'
                  onClick={() => clearSelection()}
                  data-testid='search-bar-clear'
                >
                  <Icon icon='cancel' />
                </div>
              }
              <input
                {...getInputProps({ 
                  onKeyDown: e => {
                    if (e.key === 'Enter') {
                      closeMenu();
                    }
                  }
                })}
                onClick={showRecentSearchSuggestions}
                onBlur={hideRecentSearchSuggestions}
                className='w-100 h-100 border-0 color--grey-medium'
                placeholder={t('Search meinUnterricht')}
                value={searchTerm}
                data-testid='search-bar-top'
                maxLength={128}
              />
              <div
                {...getMenuProps()}
                className={
                  classNames(
                    'search-bar__suggestions bg-white box-shadow',
                    { 'd-none': !(showSuggestions || isOpen) || suggestions.length === 0 })
                }
                data-testid='search-bar-suggestions'
              >
                {(showSuggestions || isOpen) && suggestions.map((suggestion, index) => (
                  <div
                    className={classNames(
                      'search-bar__suggestion color--grey-medium',
                      { 'bg--grey-lighter': highlightedIndex === index }
                    )}
                    key={index}
                    {...getItemProps({
                      key: suggestion.text,
                      index,
                      item: suggestion,
                    })}
                    label={suggestion.text}
                  >
                    {suggestion.text}
                  </div>
                ))}
              </div>
            </div>
          );
        }}
      </Downshift>
    </div>
  </Form>;
};

export default SearchBarTop;
