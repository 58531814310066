import classnames from 'classnames';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Icon from '../Icon';
import ItemMeatballMenu from '../ItemMeatballMenu';
import ItemMetadata from '../ItemMetadata';
import { Trans } from 'react-i18next';
import { ItemCardProps } from '../Item.props';
import { ContentItem } from '../../types/AppContent';
import BackgroundImage from '../BackgroundImage';
import PublisherImage from '../PublisherImage';
import Truncate from '../Truncate';

const getItemIconComponent = (item: ContentItem) => {
  const wrapperClass = 'h-100 item--list-thumbnail';
  if (item.type === 'product' || (item.type === 'mindItem' && item.fileType === 'document')) {
    return (
      <BackgroundImage
        className={wrapperClass}
        url={item.thumbnail}
      />
    );
  }
  else {
    return (
      <div className={wrapperClass}>
        {item.fileType === 'video' ? <Icon icon='video' className='text-danger' size='xl' /> : <></>}
      </div>
    );
  }
};

const ListItem: React.FC<ItemCardProps> = ({ item, ...props }) => {

  return (
    <div
      className={
        classnames('d-flex item item--list my-1 text-dark text-decoration-none', { 'item--active': props.selected })
      }
    >
      <Col xs={9}>
        <Row className='h-100'>
          <div className='h-100 py-3 pl-4'>{getItemIconComponent(item)}</div>
          <Col className='text-truncate'>
            <div className='centered--vertically'>
              <div className='w-100'>
                <Truncate lines={1} className='p-0' text={item.title} />
                <div className='small text-muted'>{item.subtitle}</div>
                <ItemMetadata
                  schoolTypes={item.schoolTypes}
                  classYears={item.classYears}
                  subjects={item.subjects}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={1}>
        {item.type === 'mindItem' &&
          <div className='centered--vertically text-muted text-uppercase'>
            <Trans i18nKey={item.fileType} />
          </div>}
      </Col>
      <Col xs={1}>
        <div className='centered--vertically'>
          <PublisherImage className='publisher-logo' publisher={item.publisher} />
        </div>
      </Col>
      <Col xs={1}>
        <div className='centered--vertically text-muted'>
          <ItemMeatballMenu
            itemId={item.id}
            type={item.type}
          />
        </div>
      </Col>
    </div>
  );
};

export default ListItem;
