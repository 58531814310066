import React from 'react';
import { SvgIcon, Tabs, Tab } from '@mui/material';
import getMuiIcon, { IconName } from '../../../utils/getMuiIcon';
import { SearchResultsTab } from '../../../types/search';
import useMedia from '../../../utils/useMedia';

export type SearchResultsTabType = {
  key: SearchResultsTab;
  name: string;
  icon: IconName;
};

interface OwnProps {
  tabs: SearchResultsTabType[];
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
  value: string;
}

const getIconName = (icon: string, key: string, value: string): IconName => {
  return key === value ? `${icon}Bold` as IconName : icon as IconName;
};

const MuTabsNewUI: React.FC<OwnProps> = (props) => {
  const { isMobile } = useMedia();
  const tabIndex: number = props.tabs.findIndex(tab => tab.key === props.value);
  return (
    <Tabs
      value={tabIndex}
      onChange={props.handleChange}
      variant='scrollable'
      scrollButtons={false}
    >
      {props.tabs.map((tab, index) => (
        <Tab
          key={tab.key}
          sx={{
            fontSize: '16px',
            lineHeight: '26px',
            minHeight: '48px',
            letterSpacing: '0.46px',
            textTransform: 'none',
            '& .MuiTouchRipple-root': {
              color: 'transparent',
            },
            cursor: 'pointer',
          }}
          icon={
            !isMobile ?
              <SvgIcon component={getMuiIcon(getIconName(tab.icon, tab.key, props.value))} /> :
              <></>
          }
          iconPosition='start'
          label={tab.name}
          data-testid={`tabs__${tab.key}`}
          value={index}
        />
      ))}
    </Tabs>
  );
};

export default MuTabsNewUI;
