import { DownloadInformationInterface } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import React, { useContext } from 'react';
import _ from 'lodash';
import Button from '../common/Button';
import Modal from '../common/Modal';
import { Trans } from 'react-i18next';
import { ManageItemInCollectionModalContext } from '../../state/ManageItemInCollectionModalProvider';
import DownloadManager from '../DownloadManager';
import DownloadLimitExceeded from '../DownloadManager/DownloadLimitExceeded';
import PremiumUpgradeButton from '../PremiumButtons/PremiumUpgradeButton';
import WhatIsPremiumButton from '../PremiumButtons/WhatIsPremiumButton';
import Icon from '../Icon';
import AttachmentsPromoInfo from '../DownloadManager/AttachmentsPromoInfo';
import './downloadModal.scss';
import NewDownloadLimitExceeded from '../DownloadManager/NewDownloadLimitExceeded';
import { ampliConversionPath } from '../../constants';

export type DownloadPreselect = 'mindItem' | 'attachments';
interface DownloadModalProps {
  downloadInfo: DownloadInformationInterface;
  mindItemId: string;
  productId: string;
  isOpen: boolean;
  isPremium: boolean;
  onClose: () => void;
  preselect: DownloadPreselect;
}

const DownloadModal: React.FC<DownloadModalProps> = (props) => {
  const { show: showMangeItemInCollectionsModal } = useContext(ManageItemInCollectionModalContext);
  const { downloadInfo, onClose, isPremium, isOpen, mindItemId, preselect, productId } = props;
  const hasAttachments = !_.isEmpty(downloadInfo.attachments);
  if (!downloadInfo.canDownload) {
    return <NewDownloadLimitExceeded
      trackingObject={{ minditem_id: mindItemId }}
      onClose={onClose}
      show={isOpen}
      isPremium={isPremium} />;
  }
  return <DownloadManager
    mindItemId={mindItemId}
    downloadInformation={downloadInfo}
    preselect={preselect}
    render={(DownloadsList, DownloadBtn) =>
      <Modal
        show={isOpen}
        onClose={onClose}
        title={
          <DownloadModalTitle isPremium={isPremium} isLimitExceeded={!downloadInfo.canDownload} />
        }
        body={
          downloadInfo.canDownload ?
            <DownloadsList /> :
            <DownloadLimitExceeded
              isPremium={isPremium}
              downloadCount={downloadInfo.counter} />
        }
        footer={
          <>
            <DownloadModalButtons
              isPremium={isPremium}
              isLimitExceeded={!downloadInfo.canDownload}
              upgradeBtn={
                <PremiumUpgradeButton 
                  variant='secondary' 
                  onClick={onClose} 
                  conversionPath={ampliConversionPath.ContentDownload}
                />
              }
              downloadBtn={<DownloadBtn variant='primary'/>}
              upgradeInfoBtn={<WhatIsPremiumButton handleClick={onClose} />}
              addToCollectionBtn={<AddToCollectionButton
                onClick={() => {
                  showMangeItemInCollectionsModal(mindItemId, 'mindItem');
                  onClose();
                }}
              />}
            />
            {
              productId && hasAttachments &&
              <AttachmentsPromoInfo isPremium={isPremium} productId={productId} />
            }
          </>
        }
      />
    }
  />;
};

interface AddToCollectionButtonProps {
  onClick: () => void;
}
const AddToCollectionButton: React.FC<AddToCollectionButtonProps> = (props) => {
  return <Button variant='primary' onClick={() => props.onClick}>
    <Icon icon='add-to-folder' />
    <span className='mx-1'>
      <Trans i18nKey='Add to collection'>Add to collection</Trans>
    </span>
  </Button>;
};

const DownloadModalTitle: React.FC<{ isPremium: boolean; isLimitExceeded: boolean }> = (props) => {
  if (!props.isLimitExceeded) {
    return <Trans>Document download</Trans>;
  }

  return (
    <>
      {(props.isLimitExceeded && props.isPremium) && <Trans>Limit Exceeded</Trans>}
      {(props.isLimitExceeded && !props.isPremium) && <Trans>Get meinUnterricht Premium</Trans>}
    </>
  );
};

interface DownloadModalButtonsProps {
  isPremium: boolean;
  isLimitExceeded: boolean;
  downloadBtn: React.ReactNode;
  upgradeBtn: React.ReactNode;
  upgradeInfoBtn: React.ReactNode;
  addToCollectionBtn: React.ReactNode;
}

const DownloadModalButtons: React.FC<DownloadModalButtonsProps> = (props) => {
  const ButtonsWrapper: React.FC = ({ children }) => {
    return (
      <div className='download-modal__buttons-wrapper'>
        {children}
      </div>
    );
  };

  if (props.isLimitExceeded) {
    if (props.isPremium) {
      return (
        <ButtonsWrapper>
          {props.addToCollectionBtn}
        </ButtonsWrapper>
      );
    }

    return (
      <ButtonsWrapper>
        {props.upgradeBtn}
        {props.upgradeInfoBtn}
      </ButtonsWrapper>
    );
  }

  if (props.isPremium) {
    return (
      // downloadBtn takes care of the button variant
      <ButtonsWrapper>
        {props.downloadBtn}
      </ButtonsWrapper>
    );
  }

  return (
    <ButtonsWrapper>
      {props.upgradeBtn}
      {props.downloadBtn}
    </ButtonsWrapper>
  );

};

export default DownloadModal;
