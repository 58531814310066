import api from '../clients/api';
import { ManageCollectionRequest, SearchRequest } from '@klab-berlin/api-sdk/lib/types/requests/Common';
import { handleUnauthorized } from '../utils/apiWrapper';
import { notifyBugsnagNetwork } from '../utils/bugsnagClient';

const service = {
  getDownloadInformation,
  getSearchSuggestions,
  manageCollections,
  getMindItem,
  getMindItemToken,
  createPublicMindItemLink,
  getPublicMindItem,
  getSiblings,
  getTopics,
  pinMindItem,
  unpinMindItem,
  search,
};

function getMindItem(mindItemId: string) {
  notifyBugsnagNetwork('mindItem', 'getMindItem', { mindItemId });
  return handleUnauthorized(() => api.mindItem.getMindItem(mindItemId));
}

function getMindItemToken(mindItemId: string) {
  notifyBugsnagNetwork('mindItem', 'getMindItemToken', { mindItemId });
  return handleUnauthorized(() => api.mindItem.getMindItemToken(mindItemId));
}

function createPublicMindItemLink(mindItemId: string) {
  notifyBugsnagNetwork('mindItem', 'createPublicMindItemLink', { mindItemId });
  return handleUnauthorized(() => api.mindItem.createPublicMindItemLink(mindItemId));
}

function getPublicMindItem(accessToken: string) {
  notifyBugsnagNetwork('mindItem', 'getPublicMindItem', { accessToken });
  return api.mindItem.getPublicMindItem(accessToken);
}

function getSiblings(mindItemId: string) {
  notifyBugsnagNetwork('mindItem', 'getSiblings', { mindItemId });
  return handleUnauthorized(() => api.mindItem.getMindItemSiblings(mindItemId));
}

function getDownloadInformation(mindItemId: string) {
  notifyBugsnagNetwork('mindItem', 'downloadInformation', { mindItemId });
  return handleUnauthorized(() => api.mindItem.downloadInformation(mindItemId));
}

function pinMindItem(mindItemId: string) {
  notifyBugsnagNetwork('mindItem', 'pinMindItem', { mindItemId });
  return handleUnauthorized(() => api.mindItem.pinMindItem(mindItemId));
}

function unpinMindItem(mindItemId: string) {
  notifyBugsnagNetwork('mindItem', 'unpinMindItem', { mindItemId });
  return handleUnauthorized(() => api.mindItem.unpinMindItem(mindItemId));
}

function search(searchParams: SearchRequest) {
  notifyBugsnagNetwork('mindItem', 'search', { searchParams });
  return handleUnauthorized(() => api.mindItem.search(searchParams));
}

function getSearchSuggestions(searchTerm: string, isNewSearch: boolean) {
  notifyBugsnagNetwork('mindItem', 'getSearchSuggestions', { searchTerm });
  return handleUnauthorized(() => api.mindItem.searchSuggestions(searchTerm, isNewSearch));
}

function getTopics(subject: string) {
  notifyBugsnagNetwork('mindItem', 'getTopics', { subject });
  return handleUnauthorized(() => api.mindItem.getTopics(subject));
}

function manageCollections(productId: string, payload: ManageCollectionRequest) {
  notifyBugsnagNetwork('mindItem', 'manageCollections', { productId, payload });
  return handleUnauthorized(() => api.mindItem.manageItemInMultipleCollections(productId, payload));
}

export default service;
