import { AsyncActionStatus } from '../actions/common.actionTypes';
import {
  FETCH_COLLECTION_CONTENTS,
  FETCH_DOWNLOAD_CONTENTS,
  FetchCollectionContentsAction,
} from '../actions/collections.actionTypes';
import { filteredArrayToObject, arrayToObject } from '../utils/arrayToObject';
import immutable from 'object-path-immutable';
import {
  FETCH_PINNED_CONTENTS,
  FetchPinnedContentsAction,
  PinMindItemAction,
  UnpinDocumentAction,
  PIN_MIND_ITEM,
  UNPIN_DOCUMENT
} from '../actions/pinned.actionTypes';
import { FetchDownloadContentsAction } from '../actions/download.actionTypes';
import {
  FetchMindItemAction,
  FETCH_MIND_ITEM,
  FetchPublicMindItemAction,
  FETCH_PUBLIC_MIND_ITEM,
  FetchSiblingsAction,
  FETCH_SIBLINGS,
} from '../actions/mindItems.actionTypes';
import { LogoutAction, LOGOUT } from '../actions/auth.actionTypes';
import { StoreItem } from '.';
import {
  MindItem,
  CollectionMindItem,
} from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import { GET_PRODUCT_MIND_ITEMS, GetProductMindItemsAction } from '../actions/product.actionTypes';

type MindItemActionTypes =
  | FetchDownloadContentsAction
  | FetchPinnedContentsAction
  | FetchCollectionContentsAction
  | PinMindItemAction
  | UnpinDocumentAction
  | LogoutAction
  | FetchMindItemAction
  | FetchPublicMindItemAction
  | FetchSiblingsAction
  | GetProductMindItemsAction;

type MindItemByIdState = StoreItem<MindItem> & {
  invalid?: boolean;
};

export interface MindItemState {
  byAccessToken: {
    [key: string]: {
      mindItem?: string;
      status: AsyncActionStatus;
    };
  };
  byId: {
    [key: string]: MindItemByIdState;
  };
  siblingsByProductItemId: {
    [key: string]: {
      siblings: string[];
      status: AsyncActionStatus;
    };
  };
}

const initialState: MindItemState = {
  byAccessToken: {},
  byId: {},
  siblingsByProductItemId: {}
};

const mindItems = (
  state = initialState,
  action: MindItemActionTypes
): MindItemState => {
  switch (action.type) {
    case LOGOUT.SUCCESS:
      return initialState;

    case FETCH_PINNED_CONTENTS.SUCCESS:
    case FETCH_COLLECTION_CONTENTS.SUCCESS:
    case FETCH_DOWNLOAD_CONTENTS.SUCCESS: {
      const fetchedMindItems = filteredArrayToObject(
        action.result.data.map((r) => {
          return {
            data: r as
              | CollectionMindItem<'document'>
              | CollectionMindItem<'video'>,
            status: AsyncActionStatus.READY,
          };
        }),
        (v) => v.data.id,
        (v) => v.data.type === 'mindItem'
      );

      return immutable(state).merge('byId', fetchedMindItems).value();
    }

    case FETCH_MIND_ITEM.SUCCESS: {
      const mindItemId = action.payload.mindItemId;
      const productItemId = action.result.product;

      return immutable(state)
        .set(['byId', mindItemId, 'status'], AsyncActionStatus.READY)
        .set(['byId', mindItemId, 'data'], action.result)
        .set(['siblingsByProductItemId', productItemId, 'siblings'], [])
        .set(['siblingsByProductItemId', productItemId, 'status'], AsyncActionStatus.INITIAL)
        .value();
    }

    case FETCH_PUBLIC_MIND_ITEM.SUCCESS: {
      const mindItemId = action.result.id;
      const productItemId = action.result.product;
      const accessToken = action.payload.accessToken;

      return immutable(state)
        .set(['byId', mindItemId, 'status'], AsyncActionStatus.READY)
        .set(['byId', mindItemId, 'data'], action.result)
        .set(['siblingsByProductItemId', productItemId, 'siblings'], [])
        .set(['siblingsByProductItemId', productItemId, 'status'], AsyncActionStatus.INITIAL)
        .set(['byAccessToken', accessToken], { mindItem: mindItemId, status: AsyncActionStatus.READY })
        .value();
    }

    case FETCH_PUBLIC_MIND_ITEM.START: {
      const accessToken = action.payload.accessToken;
      return immutable(state)
        .set(['byAccessToken', accessToken], { status: AsyncActionStatus.LOADING })
        .value();
    }

    case FETCH_PUBLIC_MIND_ITEM.ERROR: {
      const accessToken = action.payload.accessToken;
      return immutable(state)
        .set(['byAccessToken', accessToken], { status: AsyncActionStatus.NOTFOUND })
        .value();
    }

    case GET_PRODUCT_MIND_ITEMS.START: {
      const { productId } = action.payload;

      const newState = immutable(state);

      newState.set(
        ['siblingsByProductItemId', productId, 'status'],
        AsyncActionStatus.LOADING
      );

      return newState.value();
    }

    case GET_PRODUCT_MIND_ITEMS.SUCCESS: {
      const mindItems = arrayToObject(
        action.result.map(mindItem => ({
          data: mindItem,
          status: AsyncActionStatus.READY
        })),
        item => item.data.id,
      );

      const siblingIds = action.result.map(mindItem => mindItem.id);
      const productItemId = action.payload.productId;

      return immutable(state)
        .set(['byId'], mindItems)
        .set(['siblingsByProductItemId', productItemId, 'siblings'], siblingIds)
        .set(['siblingsByProductItemId', productItemId, 'status'], AsyncActionStatus.READY)
        .value();
    }

    case FETCH_MIND_ITEM.ERROR: {
      const mindItemId = action.payload.mindItemId;

      return immutable(state)
        .set(['byId', mindItemId, 'invalid'], true)
        .set(['byId', mindItemId, 'status'], AsyncActionStatus.ERROR)
        .value();
    }

    case FETCH_SIBLINGS.START: {
      const { productItemId } = action.payload;

      const newState = immutable(state);

      newState.set(
        ['siblingsByProductItemId', productItemId, 'status'],
        AsyncActionStatus.LOADING
      );

      return newState.value();
    }

    case FETCH_SIBLINGS.ERROR:
      return immutable(state)
        .set(
          ['siblingsByProductItemId', action.payload.productItemId, 'status'],
          AsyncActionStatus.ERROR
        )
        .value();

    case FETCH_SIBLINGS.SUCCESS: {
      const fetchedState = arrayToObject(
        action.result.map((mi) => ({
          data: mi,
          status: AsyncActionStatus.READY,
        })),
        (d) => d.data.id
      );
      const siblingIds = action.result.map(mi => mi.id);
      const productItemId = action.payload.productItemId;

      const newMindItemState = {
        ...fetchedState,
        ...state.byId,
      };
      return immutable(state)
        .merge('byId', newMindItemState)
        .set(['siblingsByProductItemId', productItemId, 'siblings'], siblingIds)
        .set(['siblingsByProductItemId', productItemId, 'status'], AsyncActionStatus.READY)
        .value();
    }

    case PIN_MIND_ITEM.START:
    case UNPIN_DOCUMENT.ERROR: {
      if (action.payload.type === 'product' || !state.byId[action.payload.id]) {
        return state;
      }
      const newlyPinnedItem = { ...state.byId[action.payload.id].data, isPinned: true };
      return immutable(state)
        .merge(['byId', action.payload.id, 'data'], newlyPinnedItem)
        .value();
    }

    case PIN_MIND_ITEM.SUCCESS: {
      return immutable(state)
        .merge(['byId', action.payload.id, 'data'], action.result)
        .set(['byId', action.payload.id, 'status'], AsyncActionStatus.READY)
        .value();
    }

    case PIN_MIND_ITEM.ERROR:
    case UNPIN_DOCUMENT.START: {
      if (action.payload.type === 'product' || !state.byId[action.payload.id]) {
        return state;
      }
      const newlyUnpinnedItem = { ...state.byId[action.payload.id].data, isPinned: false };
      return immutable(state)
        .merge(['byId', action.payload.id, 'data'], newlyUnpinnedItem)
        .value();
    }

    default:
      return state;
  }
};

export default mindItems;
