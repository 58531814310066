import React, { memo } from 'react';
import { CollectionProperties } from '../types/AppContent';
import CollectionCard from './CollectionCard';
import { withRouter, WithRouter } from 'found';
import { updateCollection } from '../actions/collections.actions';

interface Props extends WithRouter {
  collection: CollectionProperties;
  userId: string;
  canPublish: boolean;
  isSelected: boolean;
  deleteCollection: (id: string) => void;
  followCollection: (collection: CollectionProperties) => void;
  unfollowCollection: (collection: CollectionProperties) => void;
  updateCollection: typeof updateCollection;
  selectOnClick: boolean;
  setActiveId: any;
  linkRoot: string;
}

const CollectionsListItem: React.FC<Props> = memo(({ collection, router, ...props }) => {
  const openCollection = (collectionId: string) =>
    router.push(`/${props.linkRoot}/${collectionId}`);

  const collectionActions = {
    openCollection,
    deleteCollection: props.deleteCollection,
    followCollection: props.followCollection,
    unfollowCollection: props.unfollowCollection,
    updateCollection: props.updateCollection,
  };

  const selectCollection = (e: React.MouseEvent) => {
    e.stopPropagation();
    props.setActiveId(collection.id);
  };

  const onClick = props.selectOnClick ? () => openCollection(collection.id) : selectCollection;

  return (
    <div className='position-relative'
      onDoubleClick={() => openCollection(collection.id)}
    >
      <div className='cursor-pointer' onClick={onClick}>
        <CollectionCard
          collection={collection}
          active={props.isSelected}
          actions={collectionActions}
          userId={props.userId}
          canPublish={props.canPublish}
        />
      </div>
    </div>
  );
});

export default withRouter(CollectionsListItem);
