import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../reducers/user.selectors';
import { InvoiceAddress } from '../../../../types/User';
import LabeledText from '../../../common/LabeledText';
import Address from '../../Common/Address';
import PersonalDetailsInputGroup from '../../PersonalDetailsInputGroup';

const InvoiceAdressView: React.FC = () => {

  const user = useSelector(selectUser);
  const { t } = useTranslation();

  const addressData = {
    ...user?.invoiceAddress,
    firstName: user?.invoiceAddress.firstName || user?.firstName,
    lastName: user?.invoiceAddress.lastName || user?.lastName,
  };

  return (
    <PersonalDetailsInputGroup fullWidthItems={true}>
      <LabeledText
        label={t('Invoice address')}
        text={<Address address={addressData as InvoiceAddress} />}
        id='address'
      />
    </PersonalDetailsInputGroup>
  );
};

export default InvoiceAdressView;
