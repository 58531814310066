import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { selectSiblingsStatus, selectSiblings } from '../../../reducers/mindItems.selectors';
import { StoreType } from '../../../reducers';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { AsyncActionStatus } from '../../../actions/common.actionTypes';
import { selectProduct } from '../../../reducers/products.selectors';
import Skeleton from 'react-loading-skeleton';
import Icon from '../../Icon';
import './readerProductNavigation.scss';
import { MindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import { Product } from '@klab-berlin/api-sdk/lib/types/responses/Product';
import WithTooltip from '../../WithTooltip';
import ReaderNavigationSibling from './ReaderNavigationSibling/ReaderNavigationSibling';
import ReaderProductNavigationAttachments from
  './ReaderProductNavigationAttachments/ReaderProductNavigationAttachments';
import ReaderProductNavigationWrapper from './ReaderProductNavigationWrapper';
import { openItem, itemLink, isModifiedMouseClick } from '../../../utils';
import useRouter from '../../../utils/useRouter';
import { selectUser } from '../../../reducers/user.selectors';

interface ReaderProductTitleProps {
  mindItemId?: string;
  productId: string;
  title: string;
}

const ReaderProductTitle: React.FC<ReaderProductTitleProps> = (props) => {
  const { t } = useTranslation();
  const { router } = useRouter();

  const onClickHandler = (event: React.MouseEvent) => {
    if (isModifiedMouseClick(event)) return;

    event.preventDefault();
    event.stopPropagation();

    openItem(router, props.productId, 'product');

  };

  return (
    <WithTooltip className='table-of-contents__head-tooltip' text={t('To overview')} placement='bottom'>
      <a
        href={itemLink(props.productId, 'product')}
        onClick={onClickHandler}
        data-testid='reader__product__link'
        className='table-of-contents__head-link'
      >
        <div className='table-of-contents__item-icon'>
          <Icon icon='book' className='book-icon' />
        </div>
        <span className='show-on-expand table-of-contents__item-name text-truncate'>
          {props.title}
        </span>
      </a>
    </WithTooltip>
  );
};

export type ExpandedSibling = string | undefined;

export type ThumbnailClick = (
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  siblingId: string,
  imageIndex: number) => void;

interface ReaderProductNavigationOwnProps {
  mindItemId?: string;
  productId: string;
  onNavigate?: (pageIndex: number) => void;
  disableExpandButton?: boolean;
  highlightAttachmentsButton?: boolean;
}

interface StateProps {
  siblingsStatus: AsyncActionStatus;
  siblings: MindItem[];
  product?: Product;
}

type ReaderProductNavigationProps = ReaderProductNavigationOwnProps & StateProps;

const ReaderProductNavigation: React.FC<ReaderProductNavigationProps> = (props) => {
  const { t } = useTranslation();
  const [isExpanded, setExpanded] = useState<boolean>(false);

  const toggleExpanded = () => {
    setExpanded(!isExpanded);
  };

  const [expandedSibling, setExpandedSibling] = useState<ExpandedSibling>();
  const onThumbnailClick: ThumbnailClick = (event, siblingId, imageIndex) => {
    // Click on thumbnail of a different mindItem that what is currently loaded
    // Use default link behavior
    if (props.mindItemId !== siblingId) {
      return;
    }

    // Use default link behavior when ctrl or cmd is pressed while clicking
    if (event.metaKey || event.ctrlKey) {
      return;
    }

    event.preventDefault();
    props.onNavigate?.(imageIndex + 1);
  };

  const hasAttachments = props.product && props.product.hasAttachments;
  const user = useSelector(selectUser);
  const showAttachments = hasAttachments && !user?.hideDownloadAction;

  return (
    <ReaderProductNavigationWrapper
      isExpanded={isExpanded}
      head={
        props.product &&
        props.product.active &&
        <ReaderProductTitle
          title={props.product.title}
          productId={props.productId}
          mindItemId={props.mindItemId}
        />
      }
      attachmentsButton={
        showAttachments &&
        <ReaderProductNavigationAttachments
          mindItemId={props.mindItemId}
          productId={props.productId}
          highlightAttachmentsButton={props.highlightAttachmentsButton}
        />
      }
      expandButton={!props.disableExpandButton &&
        <button
          className='table-of-contents__expand-button'
          onClick={toggleExpanded}
          aria-label={isExpanded ? t('Close left reader sidebar') : t('Open left reader sidebar')}
        >
          <WithTooltip
            text={isExpanded ? t('Close left reader sidebar') : t('Open left reader sidebar')}
            placement='right'
          >
            <Icon
              className='arrow'
              testId='reader__toggle-sidebar-arrow'
              icon={isExpanded ? 'arrow-small-left' : 'arrow-small-right'}
            />
          </WithTooltip>
        </button>
      }
      items={
        <>
          {
            props.siblingsStatus === 'loading' &&
            <>
              <div className='pt-2'>
                <Skeleton height={40} />
              </div>
              <div className='py-2'>
                <Skeleton height={40} />
              </div>
              <div className='pb-2'>
                <Skeleton height={40} />
              </div>
            </>
          }
          {
            props.siblings &&
            props.siblings.map((sibling, siblingIndex) => (
              <ReaderNavigationSibling
                key={sibling.id}
                isSidebarExpanded={isExpanded}
                isSiblingExpanded={expandedSibling === sibling.id}
                onThumbnailClick={onThumbnailClick}
                setExpandedSibling={setExpandedSibling}
                sibling={sibling}
                siblingIndex={siblingIndex}
              />
            ))
          }
        </>
      }
    />
  );
};

const mapState = (state: StoreType, props: ReaderProductNavigationOwnProps): StateProps => ({
  siblingsStatus: selectSiblingsStatus(state, { id: props.productId, type: 'product' }),
  siblings: selectSiblings(state, { id: props.productId, type: 'product' }),
  product: selectProduct(state, props.productId),
});

export default compose<React.FC<ReaderProductNavigationOwnProps>>(
  connect(mapState),
)(ReaderProductNavigation);
