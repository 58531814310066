import React from 'react';
import {
  IconButton,
  SvgIcon,
  Tooltip,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { borderRadius, palette, spaces } from '../../../assets/styles/themes/tokens';

interface LernsetsSidebarButtonProps {
    title: string;
    icon: React.ComponentType;
    selected?: boolean;
    onClick?: () => void;
    
}

const LernsetsSidebarButton: React.FC<LernsetsSidebarButtonProps> = ({ title, icon, selected, onClick }) => {
  return (
    <Tooltip arrow placement='right' title={title}>
      <IconButton 
        onClick={onClick}
        size='large'
        sx={{
          borderRadius: borderRadius.xs,
          padding: spaces.sm,
          backgroundColor: selected ? alpha(palette.primary.main, 0.08) : 'transparent',
        }}>
        <SvgIcon 
          component={icon} 
          sx={{ 
            stroke: selected ? palette.primary.main : palette.text.secondary, 
            fill: 'none' 
          }} 
        />
      </IconButton>
    </Tooltip>
  );
};

export default LernsetsSidebarButton;
