import jwt from 'jsonwebtoken';
import config from 'config';

export const createToken = (payload: any, options: jwt.SignOptions = {}) => {
  const defaultOptions = {
    expiresIn: 3600
  };

  return jwt.sign(payload, config.jwt.secret, Object.assign(defaultOptions, options));
};

export function decodeToken(payload: any, options: jwt.DecodeOptions = {}) {
  const decoded = jwt.decode(payload, options);
  if (decoded && typeof decoded === 'object') {
    return decoded;
  } else {
    throw TypeError('The decoded is not an object');
  }
}

