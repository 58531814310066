import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteCollection, updateCollection } from '../actions/collections.actions';
import { CollectionFormModalContext } from '../state/CollectionFormModalProvider';
import { ConfirmModalContext, ConfirmModalSettings } from '../state/ConfirmModalProvider';
import { CollectionProperties } from '../types/AppContent';
import MeatballItem from './MeatballItem';
import MeatballMenu from './MeatballMenu';

interface JumbotronMeatballMenuProps {
  canPublish: boolean;
  collection: CollectionProperties;
  updateCollection: typeof updateCollection;
  deleteCollection: typeof deleteCollection;
}

const JumbotronMeatballMenu: React.FC<JumbotronMeatballMenuProps> = ({ collection, deleteCollection, ...props }) => {
  const { show: openConfirmModal } = useContext(ConfirmModalContext);
  const { show: openCollectionFormModal } = useContext(CollectionFormModalContext);
  const { t } = useTranslation();

  const confirmMessage = (
    <span>{t('Are you sure that you want to delete the collection')}
      <span className='font-weight-bold'>&nbsp;{collection.title}</span>
    </span>
  );
  const confirmDeleteModalSettings: ConfirmModalSettings = {
    message: confirmMessage,
    onConfirm: () => {
      deleteCollection(collection.id);
    },
  };

  const actions = {
    editCollection: (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      openCollectionFormModal(collection.id, collection);
    },

    removeCollection: (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      openConfirmModal(confirmDeleteModalSettings);
    },

    publish: (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      props.updateCollection(collection.id, { isPublic: true });
    },

    unpublish: (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      props.updateCollection(collection.id, { isPublic: false });
    },
  };

  return (
    <MeatballMenu
      itemId={collection.id}
      items={() => [<MeatballItem
        onClick={actions.editCollection}
        icon='edit'
        key='Edit'
        label='Edit'
      />,
      props.canPublish && !collection.isPublic &&
      <MeatballItem
        onClick={actions.publish}
        icon='public'
        key='Publish'
        label='Publish'
      />,
      props.canPublish && collection.isPublic &&
      <MeatballItem
        onClick={actions.unpublish}
        icon='private'
        key='Unpublish'
        label='Unpublish'
      />,
      !collection.isPublic && <MeatballItem
        onClick={actions.removeCollection}
        icon='cancel'
        key='Remove'
        label='Remove'
        iconClass='color--coral'
      />,
      ]}
    />
  );
};

export default JumbotronMeatballMenu;
