import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomToast from '../../CustomToast/CustomToast';
import PersonalDetailsSection from '../Common/PersonalDetailsSection';
import PasswordDataForm from './PasswordDataForm';
import PasswordDataView from './PasswordDataView';

const PasswordData: React.FC = () => {
  const { t } = useTranslation();

  const [showSuccessToast, setShowSuccessToast] = useState(false);

  return (
    <div data-testid='personal-details-password'>
      <PersonalDetailsSection
        title={t('Password')}
        renderNormalView={() => <PasswordDataView />}
        renderFormView={(closeEditMode) => 
          <PasswordDataForm onSuccess={() => setShowSuccessToast(true)} close={closeEditMode} />
        }
      />
      <CustomToast
        delay={8000}
        show={showSuccessToast}
        onClose={() => setShowSuccessToast(false)}
        title={t('Password')}
        bodyText={t('Your password is successfully updated!')}
      />
    </div>
  );
};

export default PasswordData;
