import { FilterRule } from '../types/FilterRule';
import { PayloadObject } from '../types/PayloadObject';

const BLUR_RULES: FilterRule[] = [
  { filter: ['password', 'passwordRepeat', 'facebookId', 'code', 'street', 'gender',  'firstName', 'lastName'] },
  { filter: ['token', 'facebookToken', 'email', 'username'], keepChars: 4 },
];

const BLUR_STRING = '***';

const _censor = (value: string | number, keepChars = 0) => {
  const charsToKeep = value.toString().substr(0, keepChars);
  return `${charsToKeep}${BLUR_STRING}`;
};

const _matchesRule = (key: string, rules: FilterRule[]) => {
  for (const rule of rules) {
    const rx = new RegExp(rule.filter.join('|'), 'gi');
    const isMatch = rx.test(key);

    if (isMatch) {
      return rule;
    }
  }

  return;
};

const _blur = (data: PayloadObject) => {
  if (data === null) {
    return;
  }

  Object.entries(data).forEach(([key, value]) => {
    const matchingRule = _matchesRule(key, BLUR_RULES);

    // Pre-process arrays
    if (value instanceof Array) {
      value.forEach((item, index) => {
        if (typeof item === 'object') {
          return _blur(item);
        }
        value[index] = matchingRule
          ? _censor(item, matchingRule.keepChars)
          : item;
      });
    }

    if (typeof value === 'object') {
      return _blur(value);
    }

    if (!value) {
      return value;
    }

    if (!matchingRule) {
      return;
    }

    data[key] = _censor(value, matchingRule.keepChars);
  });
};

/**
 * Blurs data according to blur rules (see above)
 * Modifies a copy of the passed data and returns modified copy
 */
export const blurSensitiveData = (data: PayloadObject) => {
  if (!data) {
    return data;
  }

  // Prevent removing of undefined values
  const stringified = JSON.stringify(data, (_, value) => {
    return value === undefined ? 'undefined' : value;
  });
  const dataCopy = JSON.parse(stringified);

  _blur(dataCopy);
  return dataCopy;
};
