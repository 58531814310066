import React from 'react';
import { Box, Checkbox } from '@mui/material';
import EmptyCheckbox from '../../../../assets/lernsets/icons/checkbox.svg';
import SelectedCheckbox from '../../../../assets/lernsets/icons/selectedCheckbox.svg';
import { borderRadius, boxShadows, spaces } from '../../../../assets/styles/themes/tokens';
import { colors } from '../../../../assets/styles/themes/variables';
interface SelectablePageProps {
  page: any;
  index: number;
  handlePageClick: (index: number) => void;
  isPageSelected: boolean;
  marginRight?: string;
}

const SelectablePage = (props: SelectablePageProps) => {
  const { page, index, handlePageClick, isPageSelected, marginRight } = props;

  return (
    <Box
      key={index}
    >
      <Box
        sx={{
          marginRight,
          border: '1px solid transparent',
          cursor: 'pointer'
        }}
        key={index}
        onClick={() => handlePageClick(index)}
        data-testid={isPageSelected ? 'selected-page' : 'unselected-page'}
      >
        <Box
          component='img'
          sx={{
            height: '222px',
            width: '158px',
            borderRadius: borderRadius.xs,
            border: '1px solid transparent',
            ...(isPageSelected && {
              border: `1px solid ${colors.blue500}!important`,
              boxShadow: boxShadows.elevation6,
            }),
          }}
          src={page.w300}
        />
        <Checkbox
          size='medium'
          icon={<EmptyCheckbox />}
          checkedIcon={<SelectedCheckbox />}
          checked={isPageSelected}
          color='primary'
          sx={{
            position: 'absolute',
            marginLeft: '-45px'
          }}
        />
      </Box>
      <Box
        textAlign='center'
        marginTop={spaces.md}
      >
        {index+1}
      </Box>
    </Box>
  );
};

export default SelectablePage;
