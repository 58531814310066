import React from 'react';
import { padValue } from '../../../../../utils';
import './rangeSliderValues.scss';

interface RangeSliderValueProps {
  min: number;
  max: number;
}

const RangeSliderValues: React.FC<RangeSliderValueProps> = ({ min, max }) => {
  const minVal = min || 1;
  const maxVal = max || 1;

  return (
    <div className='range-slider-values d-flex justify-content-between flex-row mb-4 color--grey-medium'>
      <span>{padValue(Math.min(minVal, maxVal))}</span>
      <span>{padValue(Math.max(minVal, maxVal))}</span>
    </div>
  );
};

export default RangeSliderValues;
