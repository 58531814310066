import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import * as yup from 'yup';
import { routes } from '../../constants';
import Spinner from 'react-bootstrap/Spinner';
import services from '../../services';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import { Link } from 'found';
import appContext from '../../services/appContext.service';
import { useForm } from 'react-hook-form';
import FormInput from '../forms/FormInput';

interface ResetPasswordProps {
  onResetPasswordSuccess: () => void;
  onResetPasswordFailure: (statusCode: number, errorCode?: number) => void;
}

interface ResetPasswordPayload {
  email: string;
}

const resetPasswordFormValidator = yup.object<ResetPasswordPayload>({
  email: yup.string()
    .required('Email is required')
    .email('Invalid email address'),
});

const ResetPassword: React.FC<ResetPasswordProps> = (props) => {
  const [hasError, setError] = useState(false);
  const { t } = useTranslation();

  const email = appContext.getResetPasswordEmail();
  const {
    errors,
    handleSubmit,
    register,
    formState,
  } = useForm<ResetPasswordPayload>({ 
    mode: 'onBlur',
    validationSchema: resetPasswordFormValidator,
    defaultValues: {
      email,
    }
  });

  const onSubmit = handleSubmit(async (payload: ResetPasswordPayload) => {
    try {
      await services.user.initPasswordReset(payload.email);
      appContext.setResetPasswordEmail(payload.email);
      props.onResetPasswordSuccess();
    } catch (error: any) {
      props.onResetPasswordFailure(error.statusCode, error.data?.code);
      setError(true);
    }
  });

  return (
    <>
      <Row>
        <Col>
          <Col className='d-flex justify-content-between pt-4 px-0'>
            {t('Send me a recovery link to the following email address:')}
          </Col>
        </Col>
      </Row>
      <Row>
        <Col>
          {hasError && (
            <Alert variant='danger'>
              <Alert.Heading>{t('Ups, something went wrong')}</Alert.Heading>
              <p>{t('Please try to refresh the page or set your password again')}</p>
            </Alert>
          )}
          <form onSubmit={onSubmit} className='py-3 login-form'>
            <fieldset disabled={formState.isSubmitting}>
              <Row>
                <Col>
                  <FormInput
                    required={true}
                    name='email'
                    placeholder={t('Enter email')}
                    id='email'
                    type='email'
                    ref={register}
                    errors={errors}
                  />
                </Col>
              </Row>
              <Button variant='primary' type='submit' className='login-button w-100'>
                {formState.isSubmitting ? <Spinner animation='border' /> : t('Send recovery link')}
              </Button>
            </fieldset>
          </form>
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col>
          <Col className='d-flex justify-content-center border-top py-4 px-0'>
            <Link to={`/${routes.login.root}`}>
              {t('Back to login')}
            </Link>
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default ResetPassword;
