import { Editor } from '@tiptap/react';
import { isTextSelection } from '@tiptap/core';

export interface StatesProps {
    isBold: boolean;
    isItalic: boolean;
    isUnderline: boolean;
    isStrike: boolean;
    isCode: boolean;
    isCodeBlock: boolean;
    isAlignLeft: boolean;
    isAlignCenter: boolean;
    isAlignRight: boolean;
    isAlignJustify: boolean;
    isParagraph: boolean;
    isHeading: (level: number) => boolean;
    isBulletList: boolean;
    isOrderedList: boolean;
}

export const isTextSelected = ({ editor }: { editor: Editor }) => {
  const {
    state: {
      doc,
      selection,
      selection: { empty, from, to },
    },
  } = editor;

  // Sometime check for `empty` is not enough.
  // Doubleclick an empty paragraph returns a node size of 2.
  // So we check also for an empty text size.
  const isEmptyTextBlock = !doc.textBetween(from, to).length && isTextSelection(selection);

  if (empty || isEmptyTextBlock || !editor.isEditable) {
    return false;
  }

  return true;
};

export const getTextFormatStates = (editor: Editor): StatesProps => {
  return {
    isBold: editor.isActive('bold'),
    isItalic: editor.isActive('italic'),
    isUnderline: editor.isActive('underline'),
    isStrike: editor.isActive('strike'),
    isCode: editor.isActive('code'),
    isCodeBlock: editor.isActive('codeBlock'),
    isAlignLeft: editor.isActive({ textAlign: 'left' }),
    isAlignCenter: editor.isActive({ textAlign: 'center' }),
    isAlignRight: editor.isActive({ textAlign: 'right' }),
    isAlignJustify: editor.isActive({ textAlign: 'justify' }),
    isParagraph:  editor.isActive('paragraph') &&
      !editor.isActive('orderedList') &&
      !editor.isActive('bulletList') &&
      !editor.isActive('taskList'),
    isHeading: (level: number) => editor.isActive('heading', { level }),
    isBulletList: editor.isActive('bulletList'),
    isOrderedList: editor.isActive('orderedList'),
  };
};
