import React from 'react';
import classNames from 'classnames';
import './switchPad.scss';

type SwitchPadOption<T> = {
  name: string;
  value: T;
};

interface OwnProps<T> {
  options: SwitchPadOption<T>[];
  value?: T;
  onChange?: (value: T) => void;
}

const SwitchPad = <T,>(props: OwnProps<T>) => {
  return (
    <div className='switch-pad' data-testid='switch-pad'>
      {
        props.options.map((option) => <button
          type='button'
          key={option.name}
          className={classNames('switch-pad__option',
            { 'switch-pad__option--selected': option.value === props.value })}
          onClick={() => props.onChange?.(option.value)}
          aria-pressed={option.value === props.value}
        >
          {option.name}
        </button>)
      }
    </div>
  );
};

export default SwitchPad;
