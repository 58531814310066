import React from 'react';
import { useTranslation } from 'react-i18next';
import { createPinTrackingConfig } from '../../../utils/tracking';
import { CtxButton } from '../ContextUtils';
import WithTracking from '../../utils/WithTracking';
import { ContentItem } from '../../../types/AppContent';
import usePinAction from '../../../utils/usePinAction';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../reducers/user.selectors';
import { ContentFavoriteAdded, ContentFavoriteRemoved } from '../../../ampli';
import services from '../../../services';
import * as ampliUtils from '../../../utils/ampli';
import { AmpliContentPayload, UIProperties } from '../../../constants';
import { useNewDesign } from '../../../state/DesignPreferenceContextProvider';
interface PinItemContextButtonProps {
  item: ContentItem;
}

const PinItemContextButton: React.FC<PinItemContextButtonProps> = ({ item, ...props }) => {
  const isPinned = item.isPinned;
  const { t } = useTranslation();
  const pinAction = usePinAction(item.id, item.isPinned, item.type);
  const user = useSelector(selectUser);
  const { prefersNewDesign, shouldShowDesignPreferenceSwitch } = useNewDesign();
  const buttonHandler = (e: React.SyntheticEvent) => {
    e.preventDefault();
    
    const eventProperties: (AmpliContentPayload & UIProperties) | undefined = 
      item && ampliUtils.getAmpliContentEventPayload(item, item.type);
    const ampliAction = !item.isPinned ? ContentFavoriteAdded : ContentFavoriteRemoved;

    if (user && eventProperties) {
      if (shouldShowDesignPreferenceSwitch && !item.isPinned) {
        eventProperties.ui = ampliUtils.mapDesignPreference(prefersNewDesign);
      }
      services.track.ampliEventTrack({
        event: new ampliAction(eventProperties as any)
      });
    }
    return pinAction();
  };
 
  return (
    <WithTracking {...createPinTrackingConfig(!!isPinned, item.type, 'context', props)}>
      <CtxButton
        icon={isPinned ? 'star-solid' : 'star-outline'}
        onClick={buttonHandler}
      >
        {t(isPinned ? 'Unpin' : 'Pin')}
      </CtxButton>
    </WithTracking>
  );
};

export default PinItemContextButton;
