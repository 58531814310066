import React from 'react';
import './caption.scss';

interface CaptionProps {
    testId?: string;
}

const Caption: React.FC<CaptionProps> = (props) => {
  return (
    <div className='caption__container' data-testid={props.testId}>
      {props.children}
    </div>
  );
};

export default Caption;
