import { Link } from 'found';
import React, { useContext, useCallback } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  deleteCollection,
  followCollection,
  unfollowCollection,
  updateCollection,
} from '../../../actions/collections.actions';
import { trackingEvents } from '../../../services/tracking/trackConfig';
import { CollectionFormModalContext } from '../../../state/CollectionFormModalProvider';
import { ConfirmModalContext, ConfirmModalSettings } from '../../../state/ConfirmModalProvider';
import { ShareModalContext } from '../../../state/ShareModalProvider';
import { ShareModalItemTypeEnum } from '../../../types/sharing';
import { createFollowCuratedCollectionTrackingConfig } from '../../../utils/tracking';
import CollectionIcon from '../../CollectionIcon';
import { CtxButton, CtxMetas } from '../ContextUtils';
import Timestamp from '../../Timestamp';
import WithTracking from '../../utils/WithTracking';
import { CollectionProperties } from '../../../types/AppContent';
import { StoreType } from '../../../reducers';

interface CollectionContextSelectedOwnProps {
  collection: CollectionProperties;
  linkRoot: string;
}

interface CollectionContextSelectedProps extends CollectionContextSelectedOwnProps {
  canPublish: boolean;
  user: StoreType['user']['me'];
  followCollection: typeof followCollection;
  unfollowCollection: typeof unfollowCollection;
  updateCollection: typeof updateCollection;
  deleteCollection: typeof deleteCollection;
}

const CollectionContextSelected: React.FC<CollectionContextSelectedProps> = ({ collection, linkRoot, ...props }) => {
  const userId = props.user && props.user._id;
  const { show: openShareModal } = useContext(ShareModalContext);
  const { show: openConfirmModal } = useContext(ConfirmModalContext);
  const { show: openCollectionFormModal } = useContext(CollectionFormModalContext);
  const { t } = useTranslation();
  const isFollowed = collection.isFollowing;

  const followHandler = () => {
    return isFollowed
      ? props.unfollowCollection(collection)
      : props.followCollection(collection);
  };

  const publish = useCallback(() => props.updateCollection(collection.id, { isPublic: true }), [collection.id]);
  const unpublish = useCallback(() => props.updateCollection(collection.id, { isPublic: false }), [collection.id]);

  const confirmMessage = (
    <span>{t('Are you sure that you want to delete the collection')}
      <span className='font-weight-bold'>&nbsp;{collection.title}</span>
    </span>
  );

  const confirmDeleteModalSettings: ConfirmModalSettings = {
    message: confirmMessage,
    onConfirm: () => {
      props.deleteCollection(collection.id);
    },
  };

  return (
    <>
      <Row className='py-4 border-bottom'>
        <Col>
          <h2 className='font-weight-normal'>
            <CollectionIcon className='mr-2' color={collection.color} />
            {collection.title}
          </h2>
        </Col>
      </Row>
      {collection.description && (
        <Row className='py-4 border-bottom'>
          <Col>
            <p>{collection.description}</p>
          </Col>
        </Row>
      )}
      <Row className='py-4 border-bottom'>
        <Col>
          <WithTracking events={{ onClick: trackingEvents.collectionOpen }}>
            <Link to={`/${linkRoot}/${collection.id}`}>
              <CtxButton icon='view'>
                {t('View')}
              </CtxButton>
            </Link>
          </WithTracking>
          {
            collection.owner === userId &&
            <CtxButton
              icon='edit'
              onClick={
                () => openCollectionFormModal(collection.id, collection)
              }
            >
              {t('Edit')}
            </CtxButton>
          }
          {
            collection.isPublic &&
            <CtxButton
              icon='share'
              onClick={() => openShareModal(collection.id, ShareModalItemTypeEnum.COLLECTION)}
            >
              {t('Share')}
            </CtxButton>
          }
          {
            collection.isPublic && collection.owner !== userId &&
            <WithTracking {...createFollowCuratedCollectionTrackingConfig(isFollowed, collection)}>
              <CtxButton onClick={followHandler} icon={isFollowed ? 'check' : 'arrow-right'}>
                {t(isFollowed ? 'I follow' : 'Follow')}
              </CtxButton>
            </WithTracking>
          }
          {
            props.canPublish && !collection.isPublic && collection.owner === userId &&
            <CtxButton onClick={publish} icon='public'>
              {t('Publish')}
            </CtxButton>
          }
          {
            props.canPublish && collection.isPublic && collection.owner === userId &&
            <CtxButton onClick={unpublish} icon='private'>
              {t('Unpublish')}
            </CtxButton>
          }
          {
            collection.owner === userId && !collection.isPublic &&
            <CtxButton
              iconClassName='text-danger'
              icon='cancel'
              onClick={() => openConfirmModal(confirmDeleteModalSettings)}
            >
              {t('Delete')}
            </CtxButton>
          }
        </Col>
      </Row>
      <Row as={'dl'} className='py-4'>
        <CtxMetas
          data={[
            ['Subjects', collection.subjects],
            ['SchoolType', collection.schoolType],
            ['Documents', collection.contentsCount],
            ['Created', collection.created, <Timestamp key='created' value={collection.created} />],
            ['Updated', collection.changed, <Timestamp key='changed' value={collection.changed} />],
          ]}
        />
      </Row>
    </>
  );
};

export default compose<React.FC<CollectionContextSelectedOwnProps>>(
  connect(
    (state: StoreType) => ({
      user: state.user.me,
      canPublish: state.user.me && state.user.me.canPublishCuratedCollections || false,
    }),
    {
      followCollection,
      unfollowCollection,
      updateCollection,
      deleteCollection,
    },
  ),
)(CollectionContextSelected);
