import { Box, CircularProgress, Paper } from '@mui/material';
import React, { useRef } from 'react';
import { borderRadius, boxShadows, palette, spaces } from '../../../assets/styles/themes/tokens';
import PageCardControls, { ArrowDirection, DisabledArrowStatus } from './PageCardControls';
import { imageMaxSideLength, defaultImageWidth, defaultImageHeight } from '../../../actions/lernsets.actions';
import { PartType } from '@klab-berlin/api-sdk/lib/types/responses/Lernsets';
import RichTextEditor from '../RichTextEditor';

interface OwnProps {
  type: PartType;
  content?: string;
  imageUrl?: string;
  selected?: boolean;
  disabledArrowControl?: DisabledArrowStatus;
  disabled?: boolean;
  showSpinner?: boolean;
  highlight?: boolean;
  styles?: React.CSSProperties;
  onCardMove: (direction: ArrowDirection, cardRef: HTMLDivElement) => void;
  onImageClick: () => void;
  onEditIconClick: () => void;
  onDeleteClick: () => void;
  onDuplicateClick: () => void;
  onRotateClick: () => void;
  onOpenGenerateWorksheetDialog: () => void;
  onAddRichTextPartClick: () => void;
  shouldShowHints?: boolean;
}

const PageCard: React.FC<OwnProps> = (props) => {
  const {
    type, 
    content,
    imageUrl, 
    styles, 
    disabledArrowControl,
    disabled,
    showSpinner,
    highlight,
    onImageClick,
    onCardMove,
    onDeleteClick,
    onEditIconClick,
    onDuplicateClick,
    onRotateClick,
    onOpenGenerateWorksheetDialog,
    onAddRichTextPartClick,
    shouldShowHints = false,
  } = props;
  const cardRef = useRef<HTMLDivElement | null>(null);

  const handleCardMove = async (direction: ArrowDirection) => {
    if (cardRef.current) {
      await onCardMove(direction, cardRef.current);
    } 
  };

  const renderRichTextPageCard = () => {
    return (
      <Box
        sx={{ 
          borderRadius: borderRadius.lg,
          boxShadow: boxShadows.elevation2,
          width: defaultImageWidth,
          height: defaultImageHeight,
          backgroundColor: 'white',
          overflowY: 'auto',
        }}
      >
        <RichTextEditor content={content || ''}/>
      </Box>
    );   
  };

  const renderPdfPageCard = () => {
    return (
      <Paper elevation={2}
        onClick={!disabled ? onImageClick : undefined}
        data-testid='page-card-image'
        sx={{
          position: 'relative',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: borderRadius.lg,
          boxShadow: boxShadows.elevation2,
          maxWidth: imageMaxSideLength,
          maxHeight: imageMaxSideLength,
          cursor: !disabled ? 'pointer' : 'default',
          outline: highlight ? `2px solid ${palette.primary.main}` : 'none',
          '&:hover': {
            boxShadow: boxShadows.elevation4,
          },
          ...styles,
        }}>
        {imageUrl 
          ? <Box
            component='img'
            sx={{
              width: '100%',
              height: '100%',
              opacity: !disabled ? 1 : 0.5,
            }}
            src={imageUrl} />
          : <Box sx={{
            width: defaultImageWidth,
            height: defaultImageHeight,
            backgroundColor: 'white',
          }} />
        }
        {showSpinner && <Box sx={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}>
          <CircularProgress sx={{
            '& .MuiCircularProgress-svg': {
              height: 'auto',
              width: 'auto',
            },
          }} />
        </Box>}
      </Paper>
    );
  };

  const renderPageCard = () => {
    if (type === 'markup-text') {
      return renderRichTextPageCard();
    }

    return renderPdfPageCard();
  };

  return (
    <Box ref={cardRef} data-testid='page-card' sx={{
      display: 'flex',
    }}>
      {renderPageCard()}
      <Box sx={{
        position: 'absolute',
        right: `-${spaces.xxxl}`,
      }}>
        <PageCardControls 
          disabledArrow={disabledArrowControl}
          disabled={disabled}
          onArrowClick={handleCardMove}
          onTrashIconClick={onDeleteClick}
          onEditIconClick={onEditIconClick}
          onDuplicateIconClick={onDuplicateClick}
          onRotateIconClick={onRotateClick}
          shouldShowHints={shouldShowHints}
          onGenerateWorksheetIconClick={onOpenGenerateWorksheetDialog}
          onAddRichTextPartIconClick={onAddRichTextPartClick}
          pageCardType={type}
        />
      </Box>
    </Box>
  );
};

export default PageCard;
