import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import CancellationStepStart from './CancellationStepStart';
import CancellationStepArguments from './CancellationStepArguments';
import CancellationStepErrorRetry from './CancellationStepErrorRetry';
import CancellationStepFinishCancelling from './CancellationStepFinishCancelling';
import CancellationStepFinishPausing from './CancellationStepFinishPausing';
import CancellationStepOfferPause from './CancellationStepOfferPause';
import CancellationStepPauseSelection from './CancellationStepPauseSelection';
import '../ScreenModal/ScreenStepContainer.scss';

export type DownloadPreselect = 'mindItem' | 'attachments';
interface CancellationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CancellationModal: React.FC<CancellationModalProps> = (props) => {
  const { onClose, isOpen } = props;
  const [screenName, setScreenName] = useState('start');
  const stepProps = { onClose, setScreen: setScreenName };
  const stepMap: { [key in string]: React.ReactNode } = {
    start: <CancellationStepStart {...stepProps} />,
    offerPause: <CancellationStepOfferPause {...stepProps} />,
    arguments: <CancellationStepArguments {...stepProps} />,
    finishCancelling: <CancellationStepFinishCancelling {...stepProps} />,
    errorRetry: <CancellationStepErrorRetry {...stepProps} />,
    pauseSelection: <CancellationStepPauseSelection {...stepProps} />,
    finishPausing: <CancellationStepFinishPausing {...stepProps} />
  };

  return (
    <Modal show={isOpen} onHide={onClose} className='screen-step__modal'>
      {stepMap[screenName]}
    </Modal>
  );
};

export default CancellationModal;
