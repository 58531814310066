import React from 'react';
import { Badge } from '@mui/material';

const WithSearchFilterNumberBadge: React.FC<{ number?: number }> = (props) => {
  if (!props.number || props.number <= 0) {
    return <>{props.children}</>;
  }

  return <Badge
    badgeContent={props.number}
    color='primary' 
    variant='standard'
    sx={{
      '& .MuiBadge-standard': {
        transform: 'none',
      },
      width: '100%',
    }}
    data-testid='search-filter-button-badge'>
    {props.children}
  </Badge>;
};

export default WithSearchFilterNumberBadge;
