import React, { useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectMindItem } from '../../../../reducers/mindItems.selectors';
import { ReaderContentViewVariant } from '../../../../types/reader';
import { isVideoItem, isStudyflixMindItem } from '../../../../utils/typeGuards';
import VideoPlayerStudyflix, { StudyflixError } from './ReaderVideoContentStudyflix/VideoPlayerStudyflix';
import VideoPlayerVimeo from './ReaderVideoContentVimeo/VideoPlayerVimeo';
import service, { ampliTrackVideoUnlockedEvent } from '../../../../services/track.service';
import Button from '../../../common/Button';
import { ShareVideoModalContext } from '../../../../state/ShareVideoModalProvider';
import { trackingEvents } from '../../../../services/tracking/trackConfig';
import classnames from 'classnames';
import VideoOverlay from './VideoOverlay';
import { useTranslation } from 'react-i18next';
import { getSubscription, selectUser } from '../../../../reducers/user.selectors';
import ReaderActions from '../../ReaderControls/ReaderActions';
import './readerVideoContent.scss';
import { StudyflixMindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import { isValidVideoItem } from '../../../../utils/subdomain';

interface ReaderVideoContentProps {
  mindItemId: string;
  variant: ReaderContentViewVariant;
  showPublicShare?: boolean;
  isPublic?: boolean;
}

const ReaderVideoContent: React.FC<ReaderVideoContentProps> = (props) => {
  const { mindItemId, variant, showPublicShare, isPublic } = props;
  const user = useSelector(selectUser);
  const subscription = useSelector(getSubscription);
  const isPremium = user && user.isPremium;
  const mindItem = useSelector((state) => selectMindItem(state, mindItemId));
  const [isVideoPlayerReady, setIsVideoPlayerReady] = useState<boolean>(false);
  const onPause = useCallback(() => setOverlayOn(!isPremium), [isPremium]);
  const { t } = useTranslation();
  const { show: openShareVideoModal } = useContext(ShareVideoModalContext);
  const [isOverlayOn, setOverlayOn] = useState<boolean>(!isPremium && !isPublic);

  if (!mindItem || !isVideoItem(mindItem)) {
    return <></>;
  }

  const showActions = !isPublic && isValidVideoItem(mindItem.publisher);

  const closeOverlay = () => setOverlayOn(false);

  const onOpenShareVideoModal = () => {
    if (mindItem && user && subscription) {
      ampliTrackVideoUnlockedEvent(mindItem);
    }
    
    openShareVideoModal(mindItemId);
    service.eventTrack(trackingEvents.reader.clickVideoShare, {
      other: { 'mind_item_id': mindItemId }
    });
  };

  const renderStudyflixPlayer = (studyflixId: number) => {
    return <VideoPlayerStudyflix
      studyflixId={studyflixId}
      setIsReady={setIsVideoPlayerReady}
    />;
  };

  const handleNonPublicStudyflixRender = (mindItem : StudyflixMindItem) => {
    if (user?.isPremium && mindItem.studyflixVideo.id) {
      return renderStudyflixPlayer(mindItem.studyflixVideo.id);
    } else {
      return <StudyflixError thumbnail={mindItem.thumbnail} />;
    }
  };

  const renderPlayer = () => {
    if (isStudyflixMindItem(mindItem)) {
      if (isPublic && mindItem.studyflixVideo.id) {
        return renderStudyflixPlayer(mindItem.studyflixVideo.id);
      } else {
        return handleNonPublicStudyflixRender(mindItem);
      }
    } else {
      return <>
        <VideoPlayerVimeo
          mindItemId={mindItemId}
          classYears={mindItem.classYears}
          title={mindItem.title}
          schoolTypes={mindItem.schoolTypes}
          subjects={mindItem.subjects}
          publisher={mindItem.publisher}
          variant={variant}
          url={mindItem.video.link}
          setIsReady={setIsVideoPlayerReady}
          onPause={onPause}
          isPublic={isPublic}
        />
      </>;
    }
  };

  const renderIllegalReproductionWarning = () => {
    return <p className='mt-4 font-size-16'>
      {t(
        'In order to avoid illegal reproduction, this video can only be streamed.'
      )}
    </p>;
  };

  const renderVideoContent = () => {
    return (<div
      className={classnames({
        'reader-video-content--mobile': variant === 'mobile',
        'w-100 position-relative': variant === 'desktop',
      })}
    >
      {isOverlayOn && (
        <VideoOverlay onClose={closeOverlay} mindItemId={mindItemId} />
      )}
      {renderPlayer()}
      {isVideoPlayerReady && (
        <>
          {showPublicShare && (
            <Button
              className='public-share-button'
              variant='secondary'
              onClick={onOpenShareVideoModal}
              dataTestId='public-share-button'
            >
              {t('Unlock for students')}
            </Button>
          )}
        </>
      )}
      {isVideoPlayerReady && !isPublic && renderIllegalReproductionWarning()}
    </div>
    );
  };

  return (
    <>
      {variant === 'mobile' && renderVideoContent()}
      {variant === 'desktop' && (
        <div className='reader-video-content reader-video-wrapper'>
          <div className='video-overflow video-content__body'>
            <div className='video-flex'>
              <div id='video-container'>
                {renderVideoContent()}
              </div>
            </div>
          </div>
          {showActions && (
            <div
              className='z-index-overlay video-content__actions position-relative'
              data-testid='video-content__actions'
            >
              <ReaderActions hideDownloadAction={true} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ReaderVideoContent;

