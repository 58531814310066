import React, { useEffect, useState } from 'react';
import { PaymentPeriod } from '@klab-berlin/api-sdk/lib/types/common';
import { DiscountInformation } from '@klab-berlin/api-sdk/lib/types/responses/Billing';
import { useForm } from 'react-hook-form';
import services from '../../../services';
import { notifyBugsnagHandledError } from '../../../utils/bugsnagClient';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import './promoCodeForm.scss';

interface FormFields {
  promoCode: string;
}

interface OwnProps {
  isPaymentRythmValid: boolean;
  submittedPromoCode?: string;
  setSubmittedPromoCode: (code: string) => void;
  setDiscountInformation: (discountInformation: DiscountInformation | undefined) => void;
  setFormLayout: (layout: number) => void;
  licenceId: number;
  paymentRhythm: PaymentPeriod;
  onPromoCodeValidation: (discountInformation: DiscountInformation, promoCode: string) => void;
  onActiveLayoutInit: () => void;
  initPromoCode?: string;
  isSmallerWidth?: boolean;
}

const ActiveLayout: React.FC<OwnProps> = (props) => {
  const { t } = useTranslation();
  const [disableButton, setDisableButton] = useState<boolean>(true);

  const {
    handleSubmit,
    register,
    errors,
    setError,
    getValues,
    setValue,
    clearError
  } = useForm<FormFields>({
    mode: 'onChange'
  });

  useEffect(() => {
    if (!props.isPaymentRythmValid) {
      setError('promoCode', 'invalidPaymentRythm', t('This code is invalid for this payment rythm'));
      notifyBugsnagHandledError(
        new Error(
          `Invalid payment rythm selected for promo code! promoCode: ${props.submittedPromoCode},
          paymentRhythm: ${props.paymentRhythm}, licenceId: ${props.licenceId}`
        )
      );
    }
  }, [props.isPaymentRythmValid]);

  useEffect(() => {
    props.onActiveLayoutInit();
    if (props.initPromoCode) {
      setValue('promoCode', props.initPromoCode);
      onUpgradeCodeSubmit();
    }
  }, []);

  const onUpgradeCodeSubmit = handleSubmit(async () => {
    const promoCode = getValues().promoCode;
    try {
      props.setSubmittedPromoCode(promoCode);
      const discountInformation =
        await services.billing.getDiscountInformation(promoCode, props.licenceId);
      if (discountInformation?.valid) {
        props.setDiscountInformation(discountInformation);
        props.setFormLayout(2);
        props.onPromoCodeValidation(discountInformation, promoCode);
      } else {
        props.setDiscountInformation(undefined);
        setError('promoCode', 'notValid', t('This code is invalid'));
      }
    } catch (error) {
      setError('promoCode', 'notValid', t('This code is invalid'));
      notifyBugsnagHandledError(new Error(String(error)));
    }
  });

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisableButton(!event.target.value);
    clearError('promoCode');
  };

  return (
    <form
      tabIndex={0}
      className={classnames('promo-code-form', { 'promo-code-form--isSmallerWidth': props.isSmallerWidth })}
      data-testid='promo-code-form-active-layout'
      onSubmit={onUpgradeCodeSubmit}
    >
      <div
        className={
          classnames(
            'promo-code-form__input-container',
            { 'promo-code-form__input-container--error': !!errors['promoCode'] }
          )
        }>
        <input
          onChange={onInputChange}
          ref={register}
          className='promo-code-form__input'
          data-testid='promo-code-form__input'
          name='promoCode'
          type='text'
        />
        <button
          className={
            classnames(
              'promo-code-form__redeem',
              { 'promo-code-form__redeem--error': !!errors['promoCode'] }
            )
          }
          disabled={disableButton}
          data-testid='promo-code-form__redeem'
          type='submit'
        >
          {t('Redeem')}
        </button>
      </div>
      {
        errors.promoCode?.message &&
        <p className='promo-code-form__error'>
          {errors.promoCode?.message}
        </p>
      }
    </form>
  );
};

export default ActiveLayout;
