import React, { useEffect, useState } from 'react';
import TopBar from '../../../components/AppLayout/TopBar';
import { Trans, useTranslation } from 'react-i18next';
import ToggleSwitch from '../../../components/common/ToggleSwitch';
import useMedia from '../../../utils/useMedia';
import BasisCard from '../../../components/Upgrade/PackageCards/BasisCard';
import PremiumCard from '../../../components/Upgrade/PackageCards/PremiumCard';
import { useSelector } from 'react-redux';
import { getSubscription, selectUser } from '../../../reducers/user.selectors';
import SuspendedPage from '../SuspendedPage';
import {
  getSuspensionType,
  isPaused as checkIfPaused,
  isSuspended as checkIfSuspended
} from '../../../utils/subscription';
import './UpgradePackageSelectionPage.scss';
import useRouter from '../../../utils/useRouter';
import { routes } from '../../../constants';
import { selectActiveLicences } from '../../../reducers/licenceModels.selectors';
import UpgradeBanner from '../../../components/UpgradeBanner';
import _ from 'lodash';

const UpgradePackageSelectionPage: React.FC = () => {
  const [isTrainee, toggleTrainee] = useState(false);
  const { isSmallViewport } = useMedia();
  const { router } = useRouter();
  const user = useSelector(selectUser);
  const { t } = useTranslation();
  const subscription = useSelector(getSubscription);
  const isPaused = checkIfPaused(subscription);
  const isSuspended = checkIfSuspended(subscription);
  const licenceModels = useSelector(state => selectActiveLicences(state));

  useEffect(() => {
    if (user && user.isPremium) {
      router.push(`/${routes.dashboard.root}`);
    }
  }, [user && user.id]);
  
  if (isPaused || isSuspended) {
    const suspensionType = getSuspensionType(subscription);
    return <SuspendedPage type={suspensionType} />;
  }

  const isLicencesDataReady = () => !_.isEmpty(licenceModels);

  return (
    <div className='package-selection' data-testid='package-selection'>
      <div className='package-selection__top-bar'>
        <UpgradeBanner />
        <TopBar
          hideSearch={true}
          hideUpgradeToPremiumButton={true}
          hideUpgradeToSchoolLicenceButton={true}
          hideOpenCollections={true}
          hidePinnedDocuments={true}
          hideMenu={true} />
      </div>
      {isLicencesDataReady() && (
        <>
          <div className='package-selection__title'>
            <Trans i18nKey='Take your lesson planning to the next level'>
              Take your lesson planning<br /> to the next level
            </Trans>
          </div>
          <div className='trainee-switch'>
            <label>{t('Trainee Discount')}</label>
            <ToggleSwitch name='isTrainee' onChange={toggleTrainee} />
          </div>
          <div className='card-container'>
            {!isSmallViewport && <BasisCard />}
            <PremiumCard licences={licenceModels} isTrainee={isTrainee} />
          </div>
        </>
      )}
     
    </div>
  );
};

export default UpgradePackageSelectionPage;
