import React from 'react';
import Button from '../../../../../common/Button';
import './unlockPremium.scss';
import { routes } from '../../../../../../constants';
import useRouter from '../../../../../../utils/useRouter';
import { useSelector } from 'react-redux';
import { selectUser, getSubscription } from '../../../../../../reducers/user.selectors';
import services from '../../../../../../services';

interface UnlockPremiumProps {
  current_page?: number;
  page_count?: number;
  minditem_id?: string;
  text: string;
  emoji: string;
  onClick?: () => void;
  conversionPath?: string;
}

const UnlockPremium: React.FC<UnlockPremiumProps> = (props) => {
  const { router } = useRouter();
  const user = useSelector(selectUser);
  const subscription = useSelector(getSubscription);

  const handleClick = () => {
    if (props.onClick) props.onClick();

    if (user && props?.conversionPath) {
      services.track.ampliTrackPricingPlansViewedEvent(
        props.conversionPath,
        subscription?.status
      );
    }

    router.push(`/${routes.upgradePackageSelection.root}`);
  };

  return (
    <Button
      variant='black'
      onClick={handleClick}
      type='button'
      emoji={props.emoji}
      className='new-reader-overlay__button'>
      {props.text}
    </Button>
  );
};

export default UnlockPremium;
