import { UpgradeCodeResponse } from '@klab-berlin/api-sdk/lib/types/responses/Configuration';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import { FetchUpgradeCodeAction, FETCH_UPGRADE_CODE } from '../actions/upgradeCodes.actionTypes';
import immutable from 'object-path-immutable';

type UpgradeCodeActionTypes =
  | FetchUpgradeCodeAction;

export interface UpgradeCodeState {
  byId: {
    [upgradeCode: string]: {
      data?: UpgradeCodeResponse;
      status: AsyncActionStatus;
      error?: Error;
    };
  };
}

const initialState: UpgradeCodeState = {
  byId: {}
};

const upgradeCodes = (
  state = initialState,
  action: UpgradeCodeActionTypes,
): UpgradeCodeState => {
  switch (action.type) {
    case FETCH_UPGRADE_CODE.START:
      return immutable(state)
        .set(['byId', action.payload.upgradeCode, 'status'], AsyncActionStatus.LOADING)
        .value();

    case FETCH_UPGRADE_CODE.SUCCESS:
      return immutable(state)
        .set(['byId', action.payload.upgradeCode], {
          status: AsyncActionStatus.READY,
          data: action.result,
          error: undefined,
        })
        .value();

    case FETCH_UPGRADE_CODE.ERROR:
      return immutable(state)
        .merge(['byId', action.payload.upgradeCode], {
          status: AsyncActionStatus.ERROR,
          error: action.error,
        })
        .value();

    default:
      return state;
  }
};

export default upgradeCodes;
