import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../Icon';
import './videoNotAvailableCard.scss';

const VideoNotAvailableCard = () => {
  return (
    <div data-testid='video-not-available-card' className='video-not-available-card'>
      { VideoNotAvailableContent() }
    </div>
  );
};

export const VideoNotAvailableContent = () => {
  const { t } = useTranslation();

  return (
    <div data-testid='video-not-available-content' className='video-not-available-content'>
      <Icon
        className='video-not-available-content__icon'
        icon='camera-off'
        size='xl'
      />
      <h2>{t('Video unavailable')}</h2>
      <p>{t('Unfortunately, the video is currently not available due to the type of your license.')}</p>
    </div>
  );
};

export default VideoNotAvailableCard;
