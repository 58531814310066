import React from 'react';
import classnames from 'classnames';
import Icon from '../../../Icon';

interface ModalSelectOptionProps {
  selected: boolean;
  label: string;
  onClick: () => void;
}

const ModalSelectOption: React.FC<ModalSelectOptionProps> = (props) => {
  return (
    <div
      onClick={props.onClick}
      className={classnames(`
        cursor-pointer
        font-size-16
        mb-4
        border-bottom
        justify-content-between
        d-flex
        w-100
        align-items-center`,
      {
        'color--black': props.selected,
        'color--grey-medium': !props.selected,
        'border-transparent': props.selected
      }
      )}
    >
      <span className='mr-1'>
        {props.label}
      </span>
      <span className={classnames({ 'invisible': !props.selected })}>
        <Icon icon='check' size='lg' />
      </span>
    </div>
  );
};

export default ModalSelectOption;
