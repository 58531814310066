import React from 'react';
import ViewModeContextProvider from '../components/ViewSwitch/ViewModeContext';
import { CancellationModalProvider } from '../state/CancellationModalProvider';
import { TrialCancellationModalProvider } from '../state/TrialCancellationModalProvider';
import { CollectionFormModalProvider } from '../state/CollectionFormModalProvider';
import { ConfirmationModalProvider } from '../state/ConfirmModalProvider';
import CookiebotContextProvider from '../state/CookiebotContextProvider';
import { DownloadModalProvider } from '../state/DownloadModalProvider';
import { ManageItemInCollectionModalProvider } from '../state/ManageItemInCollectionModalProvider';
import { ManageItemInCollectionProvider } from '../state/ManageItemInCollectionProvider';
import { MeatballMenuProvider } from '../state/MeatballMenuProvider';
import { ModalsProvider } from '../state/ModalsProvider';
import { ShareModalProvider } from '../state/ShareModalProvider';
import { ShareVideoModalProvider } from '../state/ShareVideoModalProvider';
import { SubjectSelectionModalProvider } from '../state/SubjectSelectionModalProvider';
import { UpgradeDetailsModalProvider } from '../state/UpgradeDetailsModalProvider';
import { SnackbarContextProvider } from '../state/SnackbarContextProvider';
import { SearchFiltersDialogProvider } from '../state/SearchFiltersDialogProvider';
import { MediaProvider } from '../utils/useMedia';
import { RouterProvider } from '../utils/useRouter';
import MediaQueries from './MediaQueries';
import DesignPreferenceContextProvider from '../state/DesignPreferenceContextProvider';

const contexts: (React.FC | React.ComponentType)[] = [
  MediaQueries,
  RouterProvider,
  MediaProvider,
  CookiebotContextProvider,
  CollectionFormModalProvider,
  ConfirmationModalProvider,
  DesignPreferenceContextProvider,
  DownloadModalProvider,
  ShareModalProvider,
  ShareVideoModalProvider,
  ManageItemInCollectionProvider,
  ManageItemInCollectionModalProvider,
  MeatballMenuProvider,
  ModalsProvider,
  UpgradeDetailsModalProvider,
  ViewModeContextProvider,
  SubjectSelectionModalProvider,
  CancellationModalProvider,
  TrialCancellationModalProvider,
  SnackbarContextProvider,
  SearchFiltersDialogProvider,
];

const Contexts: React.FC = (props) => (
  <>
    {contexts.reduceRight((previous, Current) => (
      <Current>
        {previous}
      </Current>
    ), props.children)}
  </>
);

export default Contexts;
