import React, { ReactElement } from 'react';
import classnames from 'classnames';

interface ProfilePillProps {
  selected?: boolean;
  className?: string;
  selectedClass?: string;
}

const ProfilePill: React.FC<ProfilePillProps> = (props) => {
  const className = classnames(
    'rounded-pill',
    'border-0',
    'px-4',
    'form-control-lg',
    'align-items-center',
    'justify-content-center',
    'text-center',
    'font-weight-light',
    'mw-100',
    'profile__pill',
    props.className,
    { [props.selectedClass || 'bg-primary']: props.selected },
    { 'bg-light text-dark': !props.selected }
  );

  return <>
    {React.Children.map(props.children, (c) => {
      if (!React.isValidElement(c)) { return c; }

      const element = c as ReactElement<{ className: string }>;

      return React.cloneElement(element, {
        ...element.props,
        className: `${element.props.className} ${className} `,
      });
    })}
  </>;
};

export default ProfilePill;
