import React, { useEffect, useState } from 'react';
import { PaymentPeriod } from '@klab-berlin/api-sdk/lib/types/common';
import { DiscountInformation, LicenceModel } from '@klab-berlin/api-sdk/lib/types/responses/Billing';
import useRouter from '../../utils/useRouter';
import TopBar from '../../components/AppLayout/TopBar';
import LicenceInfoArea from './LicenceInfoArea';
import SinglePagePaymentForm from './SinglePagePaymentForm';
import SuspendedPage from '../../routes/upgrade/SuspendedPage';
import {
  getSuspensionType,
  isPaused,
  isSuspended
} from '../../utils/subscription';
import { useSelector } from 'react-redux';
import { selectUser, getSubscription, getSubscriptionStatus } from '../../reducers/user.selectors';
import { routes } from '../../constants';
import { PaymentMode } from './SinglePagePaymentForm/SinglePagePaymentForm';
import { selectActiveLicences } from '../../reducers/licenceModels.selectors';
import { getLicenceIdFromUrl } from './getLicenceIdFromUrl';
import _ from 'lodash';
import './singlePagePayment.scss';
import { AsyncActionStatus } from '../../actions/common.actionTypes';
import getReplacementLicence from '../../utils/replacementLicence';
import UserService from '../../services/user.service';
import classnames from 'classnames';

interface OwnProps {
  paymentMode: PaymentMode;
  isPublicPage?: boolean;  // TODO: Clean up (RED-26)
}

const SinglePagePayment: React.FC<OwnProps> = (props) => {
  const { match: { location: { query, pathname } }, router } = useRouter();
  const [promoCode, setPromoCode] = useState<string>(query.promoCode || '');
  const urlLicenceId = getLicenceIdFromUrl();
  const urlLibrary = query.library;
  const urlRedirect = query.optin_redirect;
  const licenceModels = useSelector(state => selectActiveLicences(state));
  const [selectedLicenceModel, setSelectedLicenceModel] = useState<LicenceModel>(licenceModels[urlLicenceId]);
  const [traineeLicenceModel, setTraineeLicenceModel] = useState<LicenceModel>();
  const [hasValidLibraryIp, setHasValidLibraryIp] = useState(false);
  const [isLibraryReady, setIsLibraryReady] = useState(false);
  const [paymentRhythm, setPaymentRhythm] = useState<PaymentPeriod>('monthly');
  const [promoCodeDiscountInfo, setPromoCodeDiscountInfo] = useState<DiscountInformation>();
  const user = useSelector(selectUser);
  const subscription = useSelector(getSubscription);
  const subscriptionStatus = useSelector(getSubscriptionStatus);

  useEffect(() => {
    if (urlLibrary) {
      setIsLibraryReady(false);
      UserService.getLibraryIpRange(urlLibrary).then(({ isIpAllowedForLibrary }) => {
        if (!isIpAllowedForLibrary) {
          setHasValidLibraryIp(false);
          setIsLibraryReady(true);
          router.replace(`/${routes.notFound.root}`);
        }
        setHasValidLibraryIp(true);
        setIsLibraryReady(true);
      });
    } else {
      setIsLibraryReady(true);
      setHasValidLibraryIp(false);
    }
  }, [urlLibrary]);

  useEffect(() => {
    if (Object.keys(licenceModels).length) {
      
      const licence = licenceModels[urlLicenceId];
      const validLicenceIfLibrary = !isLibraryReady || (!!licence?.isLibrary === hasValidLibraryIp);
      if (licence && validLicenceIfLibrary) {
        setSelectedLicenceModel(licence);
        if (licence?.alternativeOfferId) {
          setTraineeLicenceModel(licenceModels[licence.alternativeOfferId]);
        }
      } else {
        const replacementLicence = getReplacementLicence(licenceModels, urlLicenceId);
        if (replacementLicence) {
          router.replace({
            pathname,
            query: { ...query, licenceId: replacementLicence.licenceModelId.toString() }
          });
        } else if (isLibraryReady) {
          router.replace(`/${routes.notFound.root}`);
        }
      }
    }
  }, [licenceModels, urlLicenceId, isLibraryReady]);

  const toggleSelectedLicenceModel = () => {
    const licence = licenceModels[urlLicenceId];

    if (!traineeLicenceModel || !licence.isTrial) return;
    const isTrialLicenceSelected = selectedLicenceModel.licenceModelId !== licence.licenceModelId;
    setSelectedLicenceModel(isTrialLicenceSelected ? licence : traineeLicenceModel);
  };

  const isPremiumRegistrationUX = props.paymentMode === PaymentMode.PremiumRegistration;

  useEffect(() => {
    if (user && user.isPremium) {
      router.push(`/${routes.dashboard.root}`);
    }
  }, [user && user.id]);

  const isLicencesDataReady = () => !_.isEmpty(selectedLicenceModel);

  const renderSinglePagePayment = () => {
    return (
      <div 
        className={
          classnames(
            'single-page-payment',
            { 'single-page-payment--isPublicPage': props.isPublicPage }
          )
        }
        data-testid='single-page-payment'
      >
        { isLicencesDataReady() && (
          <>
            <div className='single-page-payment__top-bar'>
              <TopBar 
                hideSearch={true} 
                hideUpgradeToPremiumButton={true}
                hideUpgradeToSchoolLicenceButton={true}
                hideOpenCollections={true} 
                hidePinnedDocuments={true} 
                hideMenu={true}
                hideHome={isPremiumRegistrationUX}
                isPublicPage={props.isPublicPage}
              />
            </div>
            <div className='single-page-payment__info'>
              <LicenceInfoArea 
                licenceModel={selectedLicenceModel}
                toggleSelectedLicenceModel={toggleSelectedLicenceModel}
                promoCode={promoCode}
                setPromoCode={setPromoCode}
                paymentRhythm={paymentRhythm}
                setPromoCodeDiscountInfo={setPromoCodeDiscountInfo}
                setPaymentRhythm={setPaymentRhythm}
                library={urlLibrary}
                isPublicPage={props.isPublicPage}
              />
            </div>
            <div className='single-page-payment__form'>
              <SinglePagePaymentForm 
                licenceId={selectedLicenceModel.licenceModelId} 
                promoCode={promoCode} 
                paymentPeriod={paymentRhythm}
                paymentMode={props.paymentMode}
                promoCodeDiscountInfo={promoCodeDiscountInfo}
                library={urlLibrary}
                redirect={urlRedirect}
                isPublicPage={props.isPublicPage}
              />
            </div>
          </>
        )}
      </div>
    );
  };

  const render = () => {
    if (props.paymentMode === PaymentMode.PremiumUpgrade){
      if (subscriptionStatus === AsyncActionStatus.LOADING){
        return <></>;
      }  
      
      if (subscriptionStatus === AsyncActionStatus.READY && (isPaused(subscription) || isSuspended(subscription))) {
        const suspensionType = getSuspensionType(subscription);
        return <SuspendedPage type={suspensionType} />;
      }
    } 

    return renderSinglePagePayment();
  };

  return render();
};

export default SinglePagePayment;
