import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import CollectionSelect from '../ManageItemInCollection/CollectionSelect';
import { useTranslation } from 'react-i18next';
import InlineCollectionCreator from '../ManageItemInCollection/InlineCollectionCreator';
import { ItemVariation } from '@klab-berlin/api-sdk/lib/types/responses/Collection';
import { ManageItemInCollectionContext } from '../../state/ManageItemInCollectionProvider';
import { useSelector } from 'react-redux';
import { selectUser } from '../../reducers/user.selectors';
import { getAmpliContentEventPayload, mapDesignPreference } from '../../utils/ampli';
import { MindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import { Product } from '@klab-berlin/api-sdk/lib/types/responses/Product';
import services from '../../services';
import { CollectionContentAdded, CollectionContentRemoved } from '../../ampli';
import { ModalCollectionItem } from '../../constants';
import { useNewDesign } from '../../state/DesignPreferenceContextProvider';

interface ManageItemInCollectionModalProps {
  onClose: () => void;
  itemId: string;
  itemType: ItemVariation;
  show: boolean;
  itemData: Partial<MindItem> | Partial<Product> | undefined;
}

const ManageItemInCollectionModal: React.FC<ManageItemInCollectionModalProps> = (props) => {
  const { t } = useTranslation();
  const { 
    collections,
    toggleCollection, 
    setItem,
    save, 
    selectedCollections,
    setSelectedCollections
  } = useContext(ManageItemInCollectionContext);
  const [newCollectionId, setNewCollectionId] = useState('');
  const user = useSelector(selectUser);  
  const { shouldShowDesignPreferenceSwitch, prefersNewDesign } = useNewDesign();
  useEffect(() => {
    setItem(props.itemId, props.itemType);
  }, [props.itemId]);

  const handleSubmit = () => {
    save().then(props.onClose);

    processingDataForEventTracking();
  };

  const processingDataForEventTracking = () => {
    if (user && props.itemData) {
      const eventProperties = getAmpliContentEventPayload(props.itemData, props.itemType);
      // Checking if an item has been removed from some collection
      if (selectedCollections.some((collection: ModalCollectionItem) => !collection.isSelected)) {
        services.track.ampliEventTrack({
          event: new CollectionContentRemoved(eventProperties as any)
        });
      }

      // Checking if an item has been added to some collection
      if (selectedCollections.some((collection: ModalCollectionItem) => collection.isSelected)) {

        // Checking if the item was added to the new collection or in an already existing collection. 
        // Required for getting "collection preexistence" field in amplitude events.
        const isSelectedNewCollection: boolean = newCollectionId?.length ?
          selectedCollections.some((collection: ModalCollectionItem) => collection.id === newCollectionId) :
          false;

        const contentAddedEventProperties = JSON.parse(JSON.stringify(eventProperties));
        if (isSelectedNewCollection) {
          contentAddedEventProperties.content[0]['collection preexistence'] = 'new collection';
        } else {
          contentAddedEventProperties.content[0]['collection preexistence'] = 'existing collection';
        }
        if (shouldShowDesignPreferenceSwitch) {
          contentAddedEventProperties['ui'] = mapDesignPreference(prefersNewDesign);
        }

        services.track.ampliEventTrack({
          event: new CollectionContentAdded(contentAddedEventProperties as any)
        });
      }

      setSelectedCollections([]);
      setNewCollectionId('');
    }
  };
  
  return (
    <Modal show={props.show} onHide={props.onClose}>
      <Modal.Header closeButton={true}>
        <Modal.Title>
          {t('Add to collection')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InlineCollectionCreator setNewCollectionId={(collectionId: string) => {setNewCollectionId(collectionId);}} />
        <CollectionSelect
          onChange={toggleCollection}
          collections={collections}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={props.onClose}>
          {t('Cancel')}
        </Button>
        <Button variant='primary' onClick={handleSubmit}>
          {t('Save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ManageItemInCollectionModal;
