import { SearchFilters } from '../types/search';

type SearchStateInstance = {
  scrollPosition: number;
  numberOfItems: number;
};
const STORAGE_KEY = 'lastSearchState';

export type ResultSet = {
  mindItems?: SearchStateInstance;
  products?: SearchStateInstance;
  videoMindItems?: SearchStateInstance;
  all?: SearchStateInstance;
};
type SearchState = ResultSet & { filters?: SearchFilters };

const getSearchState: () => SearchState = () => {
  const searchState = sessionStorage.getItem(STORAGE_KEY);
  return searchState ? JSON.parse(searchState) : {};
};

const setSearchState = (searchState: SearchState) => {
  sessionStorage.setItem(STORAGE_KEY, JSON.stringify(searchState));
};

const getSearchFilters = () => {
  const searchState = getSearchState();
  return searchState.filters;
};

const setSearchFilters = (filters: SearchFilters) => {
  const searchState = getSearchState();
  searchState.filters = filters;
  setSearchState(searchState);
};

const clearSearchState = () => {
  sessionStorage.removeItem(STORAGE_KEY);
};

const setSearchStateInstance = (keyofSearchState: keyof ResultSet, numberOfItems: number, scrollPosition: number) => {
  const searchState = getSearchState();
  searchState[keyofSearchState] = { numberOfItems, scrollPosition };
  setSearchState(searchState);
};

const getSearchStateInstance = (keyofSearchState: keyof ResultSet) => {
  const searchState = getSearchState();
  return searchState[keyofSearchState];
};

const searchResultsStorageManager = {
  getSearchState,
  setSearchState,
  getSearchFilters,
  setSearchFilters,
  clearSearchState,
  getSearchStateInstance,
  setSearchStateInstance,
};

export default searchResultsStorageManager;
