import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import variables from '../../../assets/styles/deprecated/variables';
import useMedia from '../../../utils/useMedia';
import './searchFilterSkeleton.scss';

const MOBILE_SKELETON_HEIGHT = 33;
const DESKTOP_SKELETON_HEIGHT = 44;

const SearchFilterSkeleton = () => {
  const { isMobile } = useMedia();
  return (
    <div className='search-filter-skeleton'>
      <SkeletonTheme color={variables.neuVeryLightBlue}>
        <Skeleton height={isMobile ? MOBILE_SKELETON_HEIGHT : DESKTOP_SKELETON_HEIGHT} />
      </SkeletonTheme>
    </div>
  );
};

export default SearchFilterSkeleton;
