import React from 'react';
import {
  Button,
  DialogContent,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ImageResolutionsInterface } from '@klab-berlin/api-sdk/lib/types/responses/Common';
import SelectablePage from '../SelectablePage';
import { spaces } from '../../../../assets/styles/themes/tokens';
import ExportDialogTitle from './ExportDialogTitle';
import ExportDialogActions from './ExportDialogActions';

interface PagesSelectionStageProps {
  handleClose: () => void;
  changeStage: () => void;
  handlePageClick: (index: number) => void;
  mindItemPages: ImageResolutionsInterface[];
  selectedPages: number[];
}

const PagesSelectionStage = (props: PagesSelectionStageProps) => {
  const { t } = useTranslation();
  const {
    handleClose,
    handlePageClick,
    mindItemPages,
    selectedPages,
    changeStage
  } = props;

  return (
    <>
      <ExportDialogTitle handleClose={handleClose}>
        <Typography variant='h5'>
          {t('Add materials to Lernsets')}
        </Typography>
        <Typography variant='subtitle1'>
          {t('Select pages you want to add to your Lernset')}
        </Typography>
      </ExportDialogTitle>
      <DialogContent sx={{
        width: '100%',
        display: 'inline-flex',
        position: 'relative',
        paddingTop: `${spaces.md}!important`,
        paddingBottom: spaces.md,
      }}>
        {
          mindItemPages.map((page, index) => {           
            return <SelectablePage
              key={index}
              page={page}
              index={index}
              handlePageClick={handlePageClick}
              isPageSelected={selectedPages.includes(index)}
              marginRight={index === mindItemPages.length - 1 ? '0px' : spaces.xs}
            />;
          })
        }
      </DialogContent>
      <ExportDialogActions>
        <Button
          disabled={!selectedPages.length}
          variant='contained'
          color='primary'
          size='large'
          onClick={() => {
            changeStage();
          }}
        >
          {t('Continue')}
        </Button>
      </ExportDialogActions>
    </>
  );
};

export default PagesSelectionStage;
