import React from 'react';
import { Chip } from '@mui/material';
import { spaces } from '../../../../assets/styles/themes/tokens';
import { useTranslation } from 'react-i18next';

const IsNewChip: React.FC<{ size: 'medium' | 'small' }> = ({ size }) => {
  const { t } = useTranslation();

  return (
    <Chip 
      data-testid='is-new-chip'
      label={t('New')}
      color='success'
      size={size}
      sx={{
        position: 'absolute',
        top: spaces.sm,
        left: `-${spaces.sm}`,
        zIndex: 1, // To make sure it's on top of cover image in mobile view.
      }}/>
  );
};

export default IsNewChip;
