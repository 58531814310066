import React from 'react';
import Toast from 'react-bootstrap/Toast';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { dismissError } from '../actions/errors.actions';
import { StoreType } from '../reducers';

interface ErrorToastProps {
  body: string;
  show: boolean;
  time: string;
  onClose: () => void;
}

interface ToastListProps {
  errors: StoreType['errors']['list'];
  dismissError: typeof dismissError;
}

const ErrorToast: React.FC<ErrorToastProps> = ({ body, show, onClose }) => {
  const { t } = useTranslation();
  return (
    <Toast delay={3000} autohide={true} show={show} onClose={onClose} transition={undefined}>
      <Toast.Header>
        <strong className='mr-auto'>{t('Ups, something went wrong')}</strong>
      </Toast.Header>
      <Toast.Body className='bg-white'>
        {t(body) || t('Please try again')}
      </Toast.Body>
    </Toast>
  );
};

const ToastList = (props: ToastListProps) => {
  if (!props.errors.length) {
    return null;
  }
  const { t } = useTranslation();

  return (
    <div className='toast-list-container'>
      {props.errors.map((error) => (
        <ErrorToast
          body={t(error.message)}
          key={error.id}
          onClose={() => props.dismissError(error.id)}
          show={true}
          time={error.time}
        />
      ))}
    </div>
  );
};

export default compose<React.FC>(
  connect(
    (state: StoreType) => ({
      errors: state.errors.list,
    }),
    { dismissError },
  ),
)(ToastList);
