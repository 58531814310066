import { MuIconName } from './MuIconTypes';
import { IconSize }  from '../IconTypes';

type IconMapper = { [key in MuIconName]: string };

const iconMapper: IconMapper = {
  'arrow-right': 'arrow-big-right',
  'arrow-split': 'fit-to-width',
  'arrow-bar-right': 'sidebar-in',
  'arrow-small-down': 'arrow-small-down',
  'arrow-small-left': 'arrow-small-left',
  'arrow-small-right': 'arrow-small-right',
  'arrow-small-up': 'arrow-small-up',
  'attachment': 'attachment',
  'bars': 'bars',
  'book': 'book',
  'camera-off': 'camera-off',
  'cabinet': 'cabinet',
  'cancel': 'cancel',
  'caution': 'caution',
  'check': 'checkmark',
  'classes': 'class',
  'edit': 'edit',
  'add-to-folder': 'add-to-folder',
  'add-to-folder-filled': 'add-to-folder-filled',
  'download': 'download',
  'faq': 'faq',
  'filter': 'filter',
  'full-screen': 'screen-full',
  'follow': 'follow',
  'grid': 'grid',
  'grip-lines': 'grip-lines',
  'kollektion-tag': 'kollektion-tag',
  'link': 'link',
  'list': 'list',
  'heart': 'heart',
  'information': 'info',
  'mu-logo': 'mu-logo',
  'paperclip': 'attachment',
  'private': 'private',
  'profile': 'profile',
  'public': 'public',
  'share': 'share',
  'star-solid': 'star-filled',
  'star-outline': 'star',
  'subjects': 'subjects',
  'subscription': 'subscription',
  'text-file': 'text-file',
  'time': 'date',
  'zoom-in': 'zoom-in',
  'zoom-out': 'zoom-out',
  'audio-file': 'audio-file',
  'doc-file': 'doc-file',
  'excel-file': 'excel-file',
  'ppt-file': 'ppt-file',
  'zip-file': 'zip-file',
  'image-file': 'image-file',
  'one-page': 'one-page',
  'pdf-file': 'pdf-file',
  'play': 'play',
  'plus': 'add',
  'video-file': 'video-file',
  'unknown-file': 'unknown-file',
  'search': 'search',
  'home': 'home',
  'ellipsis': 'more',
  'premium' : 'premium',
  'collection' : 'collection',
  'collection-filled': 'collection-filled',
  'phone': 'contact',
  'sign-out': 'logout',
  'sort': 'sort',
  'help': 'help',
  'legal': 'legal',
  'payment': 'payment',
  'view': 'view',
  'video': 'video',
  'warning': 'warning'
};

type SizeMapper = { [key in IconSize]: string };

const sizeMapper: SizeMapper = {
  sm: 'icon-sm',
  m: 'icon-m',
  lg: 'icon-lg',
  xl: 'icon-xl',
  xxl: 'icon-xxl',
};

export default {
  iconMapper,
  sizeMapper,
};
