import React, { useCallback, useState } from 'react';
import LoginGradientModalAppLayout from '../components/Login/LoginGradientAppLayout';
import ChangeEmail, { ChangeEmailPayload } from '../components/Login/ChangeEmail';
import services from '../services';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import TeacherWithBlob from '../assets/TeacherWithBlob.svg';
import { Spinner } from 'react-bootstrap';
import RedirectIfLoggedIn from '../router/redirect/RedirectIfLoggedIn';

const ChangeEmailPage: React.FC = () => {
  const [payload, setPayload] = useState<null | ChangeEmailPayload>(null);
  const [isResending, setIsResending] = useState(false);
  const { t } = useTranslation();

  const handleChangeEmailSuccess = useCallback((payload: ChangeEmailPayload) => {
    setPayload(payload);
  }, []);

  const resendEmail = useCallback(async () => {
    if (payload) {
      setIsResending(true);
      await services.user.changeEmail(payload.oldEmail, payload.newEmail, payload.password, payload.isLibraryUser);
      setIsResending(false);
    }
  }, [payload, isResending]);

  return (
    <RedirectIfLoggedIn>
      <LoginGradientModalAppLayout title={payload ? t('Success!') : ''}>
        {payload
          ? <>
            <Row className='mt-4 text-center'>
              <Col>{t('A confirmation E-Mail was sent successfully')}</Col>
            </Row>
            <Row className='mt-4 text-center'>
              <Col><h3>{payload.newEmail}</h3></Col>
            </Row>
            <Row className='mt-4 text-center'>
              <Col>{t('Please click on the link within to confirm the change')}</Col>
            </Row>
            <Row>
              <Col className='d-flex justify-content-center reset-pwd-confirmed-img mt-3'>
                <TeacherWithBlob className='h-100 w-100' />
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col>
                <Col className='d-flex justify-content-between border-top py-4 px-0'>
                  <span
                    className='link'
                    onClick={() => setPayload(null)}
                  >
                    {t('Change E-Mail Address')}
                  </span>
                  {isResending && <Spinner animation='border' variant='primary' />}
                  <span
                    className='link'
                    onClick={() => resendEmail()}
                  >
                    {t('Resend Link')}
                  </span>
                </Col>
              </Col>
            </Row>
          </>
          : <ChangeEmail onChangeEmailSuccess={handleChangeEmailSuccess}></ChangeEmail>
        }</LoginGradientModalAppLayout>
    </RedirectIfLoggedIn>
  );
};

export default ChangeEmailPage;
