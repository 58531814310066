import React from 'react';
import { selectAdjacentSiblings } from '../../../reducers/mindItems.selectors';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Link } from 'found';
import { routes } from '../../../constants';
import { useTranslation } from 'react-i18next';
import Icon from '../../Icon';
import WithTooltip from '../../WithTooltip';

interface NextMindItemButtonProps {
  mindItemId: string;
  onBlur?: (event: React.SyntheticEvent) => void;
  onFocus?: (event: React.SyntheticEvent) => void;
  onMouseOut?: (event: React.SyntheticEvent) => void;
  onMouseOver?: (event: React.SyntheticEvent) => void;
}

const NextMindItemButton: React.FC<NextMindItemButtonProps> = (props) => {

  const adjacentSiblings = useSelector((state) => selectAdjacentSiblings(
    state,
    { id: props.mindItemId, type: 'mindItem' }
  ));

  const { t } = useTranslation();

  if (!adjacentSiblings?.next) {
    return <></>;
  }

  const next = adjacentSiblings.next;

  return (
    <div className='p-3'
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onBlur={props.onBlur}
      onFocus={props.onFocus}>
      <div className='mb-2 font-size-16'>
        {t('Explore next chapter')}
      </div>
      <Link to={`/${routes.reader.root}/${next.mindItem.id}`}>
        <WithTooltip text={t('Next')} placement='top'>
          <Button
            data-testid='reader__next-chapter-button'
            className='w-100 d-flex justify-content-between px-3 font-size-20'>
            <div>
              {next.position}: {next.mindItem.title}
            </div>
            <Icon icon='arrow-right' />
          </Button>
        </WithTooltip>
      </Link>
    </div>
  );
};

export default NextMindItemButton;
