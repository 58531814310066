import React, { useContext } from 'react';
import { ShareModalContext } from '../../state/ShareModalProvider';
import { ShareModalItemTypeEnum } from '../../types/sharing';
import { IconButton, Box } from '@mui/material';
import getMuiIcon from '../../utils/getMuiIcon';

interface ShareButtonProps {
  itemId: string;
  type: ShareModalItemTypeEnum;
  size?: 'small' | 'medium' | 'large';
}

const ShareButton: React.FC<ShareButtonProps> = (props) => {
  const { itemId, type, size } = props;
  const { show } = useContext(ShareModalContext);

  return (
    <IconButton
      color='secondary'
      onClick={() => show(itemId, type)}
      data-testid='share-button'
      size={size || 'medium'}
    >
      <Box component={getMuiIcon('share')} />
    </IconButton>
  );
};

export default ShareButton;
