import React, { useState, useCallback } from 'react';
import { noop } from 'lodash';
import LernsetSnackbar from '../components/lernsets/LernsetSnackbar';
import { AlertColor } from '@mui/material';

interface SnackbarInfo {
  type: AlertColor;
  message: string;
}

interface Context {
  isOpen: boolean;
  closeSnackbar: () => void;
  openSnackbar: (snackbar: SnackbarInfo) => void;
}

const defaultContext: Context = {
  isOpen: false,
  closeSnackbar: noop,
  openSnackbar: noop,
};

const SnackbarContext = React.createContext(defaultContext);

const SnackbarContextProvider: React.FC = (props) => {

  const [snackbar, setSnackbar] = useState({
    type: undefined,
    isOpen: false,
    message: '',
  });

  const openSnackbar = useCallback((snackbar) => {
    setSnackbar({
      ...snackbar,
      isOpen: true,
    });
  }, [setSnackbar]);

  const closeSnackbar = useCallback(() => {
    setSnackbar({
      type: undefined,
      message: '',
      isOpen: false,
    });
  }, [setSnackbar]);

  Object.assign(defaultContext, { closeSnackbar, openSnackbar });

  return (
    <SnackbarContext.Provider value={defaultContext}>
      {snackbar.type && (
        <LernsetSnackbar 
          severity={snackbar.type}
          message={snackbar.message}
          isOpen={snackbar.isOpen}
          onCloseAlert={() => closeSnackbar()}
        />
      )}
      {props.children}
    </SnackbarContext.Provider>
  );
};

export { SnackbarContext, SnackbarContextProvider };
