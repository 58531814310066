/* eslint @typescript-eslint/no-var-requires: "off" */
export default Object.assign(
  {},
  require('./collection.json'),
  require('./colors.json'),
  require('./common.json'),
  require('./dashboard.json'),
  require('./empty.json'),
  require('./error.json'),
  require('./filetypes.json'),
  require('./login.json'),
  require('./modals.json'),
  require('./profile.json'),
  require('./reader.json'),
  require('./search.json'),
  require('./topBar.json'),
  require('./upgrade.json'),
  require('./upgradeBanner.json'),
  require('./lernsets.json'),
);
