import React, { useEffect } from 'react';
import './PersonalDetailsAccountSection.scss';
import { useSelector } from 'react-redux';
import { getPaymentMethod } from '../../../actions/user.actions';
import { selectUser } from '../../../reducers/user.selectors';
import useAction from '../../../utils/useAction';
import PasswordData from '../../../components/PersonalDetails/PasswordData';
import PaymentRhythmSection from '../../../components/PersonalDetails/PaymentRhythm/PaymentRhythmSection';
import PaymentMethodSection from '../../../components/PersonalDetails/PersonalPaymentMethod';
import SubscriptionInformationSection from '../../../components/PersonalDetails/SubscriptionInformation';
import InvoiceAddress from '../../../components/PersonalDetails/InvoiceAddress';
import InvoiceHistory from '../../../components/PersonalDetails/InvoiceHistory';
import ChangeEmail from '../../../components/PersonalDetails/ChangeEmail';

const PersonalDetailsAccountSection: React.FC = () => {
  const user = useSelector(selectUser);
  const getPaymentMethodAction = useAction(getPaymentMethod);

  useEffect(() => {
    getPaymentMethodAction();

  }, [user && user.id]);

  const showPaymentInformation = user?.isPremium && !user?.isFree && !user?.isReadOnlyLicence && !!user?.paymentMethod;

  const showInvoiceInformation = !user?.isReadOnlyLicence;

  return (
    <div className='personal-details-account-section'>
      <ChangeEmail />
      {!user?.isLuxSSO && <PasswordData />}
      {user && <SubscriptionInformationSection />}
      {showPaymentInformation && (
        <>
          <PaymentRhythmSection />
          <PaymentMethodSection />
        </>
      )}
      {showInvoiceInformation && (
        <>
          <InvoiceAddress />
          <InvoiceHistory />
        </>
      )}
    </div>
  );
};

export default PersonalDetailsAccountSection;
