import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import './readerActionsMobile.scss';
import ActionButton from '../../ActionButton';
import { noop } from 'lodash';
import { ReaderContext } from '../ReaderContext';
import PinItemButton from '../../PinItemButton';
import ManageItemInCollectionsButton from '../../ManageItemInCollectionsButton';
import { DownloadModalContext } from '../../../state/DownloadModalProvider';
import ActionLink from '../../ActionLink';
import { routes } from '../../../constants';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../reducers/user.selectors';
import { selectAdjacentSiblings, selectMindItem } from '../../../reducers/mindItems.selectors';
import classNames from 'classnames';

interface ReaderActionsMobileProps {
  hideDownloadAction?: boolean;
  hideAllMIActions?: boolean;
  showSearchIcon: boolean;
  onSearchClick?: () => void;
}
const ReaderActionsMobile: React.FC<ReaderActionsMobileProps> = (props) => {
  const { t } = useTranslation();
  const { mindItemId, isPinned } = useContext(ReaderContext);
  const siblings = useSelector(state => selectAdjacentSiblings(state, { id: mindItemId, type: 'mindItem' }));
  const mindItem = useSelector(state => selectMindItem(state, mindItemId));
  const user = useSelector(selectUser);
  const hideDownloadAction = props.hideDownloadAction || user?.hideDownloadAction;

  const {
    show: showDownloadModal,
    isOpen: isDownloadModalOpen
  } = useContext(DownloadModalContext);

  const onDownloadClick = () => {
    showDownloadModal(mindItemId);
  };

  const isPreviousEnabled = !!siblings?.previous;
  const isNextEnabled = !!siblings?.next;

  return (
    <div className='reader-actions-mobile'>
      <div className='reader-actions-mobile__container'>
        <div className='reader-actions-mobile__icon-container'>
          <ActionLink
            icon='arrow-small-left'
            to={isPreviousEnabled ? `/${routes.reader.root}/${siblings?.previous?.mindItem.id}` : ''}
            disabled={!siblings?.previous}
            labelledBy='reader-actions-previous'
            eventPayload={{
              other: {
                'current_minditem_id': mindItemId,
                'target_minditem_id': siblings?.previous,
              }
            }}
          />
          <label
            id='reader-actions-previous'
            className={classNames(
              'reader-actions-mobile__icon-label',
              { 'reader-actions-mobile__icon-label--disabled': !isPreviousEnabled }
            )}
          >
            {t('Back')}
          </label>
        </div>
        {!hideDownloadAction && !props.hideAllMIActions &&
          <div className='reader-actions-mobile__icon-container'>
            <ActionButton
              onClick={onDownloadClick}
              icon='download'
              highlight={isDownloadModalOpen}
              labelledBy='reader-actions-download'
            />
            <label
              id='reader-actions-download'
              className='reader-actions-mobile__icon-label'
            >
              {t('Download')}
            </label>
          </div>
        }
        {!user?.hideCollectionAction && !props.hideAllMIActions &&
          <div className='reader-actions-mobile__icon-container'>
            <ManageItemInCollectionsButton
              itemData={mindItem}
              type='mindItem'
              documentId={mindItemId}
              labelledBy='reader-actions-collection'
            />
            <label
              id='reader-actions-collection'
              className='reader-actions-mobile__icon-label'
            >
              {t('Collection')}
            </label>
          </div>
        }
        {!user?.hidePinAction && !props.hideAllMIActions &&
          <div className='reader-actions-mobile__icon-container'>
            <PinItemButton
              documentId={mindItemId}
              isPinned={isPinned}
              itemData={mindItem}
              type='mindItem'
              labelledBy='reader-actions-pin-item'
            />
            <label
              id='reader-actions-pin-item'
              className='reader-actions-mobile__icon-label'
            >
              {t('Pinned Documents')}
            </label>
          </div>
        }
        {props.showSearchIcon && !props.hideAllMIActions &&
          <div className='reader-actions-mobile__icon-container'>
            <ActionButton icon='search' onClick={props.onSearchClick || noop} labelledBy='reader-actions-search' />
            <label
              id='reader-actions-search'
              className='reader-actions-mobile__icon-label'
            >
              {t('Search')}
            </label>
          </div>
        }
        <div className='reader-actions-mobile__icon-container'>
          <ActionLink
            icon='arrow-small-right'
            to={isNextEnabled ? `/${routes.reader.root}/${siblings?.next?.mindItem.id}` : ''}
            disabled={!siblings?.next}
            labelledBy='reader-actions-next'
            eventPayload={{
              other: {
                'current_minditem_id': mindItemId,
                'target_minditem_id': siblings?.previous,
              }
            }}
          />
          <label
            id='reader-actions-next'
            className={classNames(
              'reader-actions-mobile__icon-label',
              { 'reader-actions-mobile__icon-label--disabled': !isNextEnabled }
            )}
          >
            {t('Next')}
          </label>
        </div>
      </div>
    </div>
  );
};

export default ReaderActionsMobile;
