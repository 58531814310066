import config from 'config';
import { User } from '../types/User';
import { isFeatureEnabled } from './feature';

export const isValidVideoItem = (publisher?: string) : boolean => {
  const isVimeoEnabled = isFeatureEnabled('vimeo');
  const nonVimeoVideoPublishers = config.schoolsApp.nonVimeoVideoPublishers.map(publisher => publisher.toLowerCase());
  const isPublisherEnabled = !!publisher && nonVimeoVideoPublishers.includes(publisher.toLowerCase());
  return  isVimeoEnabled || isPublisherEnabled;
};

export const isSchoolSubdomain = (hostname: string): boolean =>
  hostname.startsWith('schulen-app')
  || hostname.startsWith('dev-local-schulen')
  || hostname.startsWith('staging-schulen-app');

export const isCorrectHostnameForUser = (hostname: string, user: User) =>
  (user.isMigratedSchoolUser && isSchoolSubdomain(hostname))
  || (!user.isMigratedSchoolUser && !isSchoolSubdomain(hostname));

export const correspondingSubdomains = {
  'dev-local-schulen': 'dev-local',
  'staging-schulen-app': 'staging-app',
  'schulen-app': 'app',
  'dev-local': 'dev-local-schulen',
  'staging-app': 'staging-schulen-app',
  'app': 'schulen-app',
};

export const switchSubdomain = (windowLocation: Location) => {
  const { href, hostname } = windowLocation;
  const currentSubdomain = hostname.split('.')[0] as keyof typeof correspondingSubdomains;
  const isKnownSubdomain = currentSubdomain in correspondingSubdomains;
  if (!isKnownSubdomain) {
    throw new Error(`switchSubdomain failed since ${currentSubdomain} is not a known subdomain`);
  }

  window.location.replace(href.replace(currentSubdomain, correspondingSubdomains[currentSubdomain]));
};
