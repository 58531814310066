import React from 'react';
import { useTranslation } from 'react-i18next';
import ScreenStepContainer, { ScreenStepProps } from '../ScreenModal/ScreenStepContainer';
import '../ScreenModal/ScreenStepContainer.scss';

const CancellationStepFinishPausing:React.FC<ScreenStepProps> = (props) => {
  const { t } = useTranslation();
  const stopFlowButton = {
    action: props.onClose,
    title: t('All clear!'),
  };
  return (
    <ScreenStepContainer
      trackingId='finishPausing'
      title={t('Enjoy your break!')}
      primaryButton={stopFlowButton}
      onClose={props.onClose}
    >
      <p>{t('Our support-team will send a confirmation per email')}</p>
      <div className='screen-step__img screen-step__park-bench-small' />
    </ScreenStepContainer>
  );
};

export default CancellationStepFinishPausing;
