import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from '../../reducers/user.selectors';
import { ModalsContext } from '../../state/ModalsProvider';
import { routes } from '../../constants';
import Button from '../common/Button';
import './attachmentsButton.scss';

interface OwnProps {
  productId: string;
  attachmentsCount: number;
}

export const AttachmentsButton: React.FC<OwnProps> = ({ attachmentsCount, productId }) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const { openModal } = useContext(ModalsContext);
  const [premiumLink, setPremiumLink] = useState<string>();
  const [basisModal, setBasisModal] = useState<() => void>();

  useEffect(() => {
    if (!user) return;
    else if (user.isPremium) {
      setPremiumLink(`/${routes.productAttachmentsPage.root}/${productId}`);
    }
    else {
      setBasisModal(() => () => openModal('premiumContent'));
    }
  }, [user?.isPremium]);

  return (
    <Button
      url={premiumLink}
      icon='attachment'
      className='attachments-button'
      onClick={basisModal}
    >
      {t('Attachments')}{' '}
      <div className='attachments-button__description'>
        {attachmentsCount}{' '}{t('Files')}
      </div>
    </Button>
  );
};

export default AttachmentsButton;
