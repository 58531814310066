import React, { useCallback, useEffect, useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import TextInput from '../../../common/Input/TextInput';
import Radio from '../../../common/Radio/Radio';
import PersonalDetailsInputGroup from '../../PersonalDetailsInputGroup';
import { selectUser } from '../../../../reducers/user.selectors';
import { useSelector } from 'react-redux';
import {
  getPaymentMethod as getPaymentMethodAction,
  updateMe as updateMeAction
} from '../../../../actions/user.actions';
import useAction from '../../../../utils/useAction';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Gender } from '@klab-berlin/api-sdk/lib/types/requests/User';
import { Select } from '../../../common/Select';
import { countries } from '../../../../constants';
import FormInputWrapper from '../../../common/FormInputWrapper';
import PersonalDetailsActions from '../../Common/PersonalDetailsActions';
import { ascii } from '../../../../validators';

interface OwnProps {
  onSave?: () => void;
  onCancel: () => void;
}

interface formFields {
  firstName: string;
  lastName: string;
  institution: string;
  gender: Gender;
  street: string;
  areaCode: string;
  city: string;
  country: string[];
}

const DEFAULT_GENDER: Gender = 'w';
const REQUIRED_ERROR = 'Required field. please check the entry';

const schema = yup.object().shape({
  gender: yup.mixed().oneOf(['m', 'f', 'w']).required(REQUIRED_ERROR),
  firstName: ascii.max(100, 'Max. 100 characters')
    .when('$paysByInvoice', { is: true, then: (s: yup.StringSchema<string>) => s.required(REQUIRED_ERROR) }),
  lastName: ascii.max(70, 'Max. 70 characters')
    .when('$paysByInvoice', { is: true, then: (s: yup.StringSchema<string>) => s.required(REQUIRED_ERROR) }),
  street: ascii.max(512, 'Max. 512 characters')
    .when('$paysByInvoice', { is: true, then: (s: yup.StringSchema<string>) => s.required(REQUIRED_ERROR) }),
  areaCode: yup.string().max(128, 'Max. 128 characters').matches(/^[0-9]*$/, 'Area Code can only contain numbers')
    .when('$paysByInvoice', { is: true, then: (s: yup.StringSchema<string>) => s.required(REQUIRED_ERROR) }),
  city: ascii.max(128, 'Max. 128 characters')
    .when('$paysByInvoice', { is: true, then: (s: yup.StringSchema<string>) => s.required(REQUIRED_ERROR) }),
  country: yup.array(ascii)
    .when('$paysByInvoice', { is: true, then: (s: yup.StringSchema<string>) => s.required(REQUIRED_ERROR) }),
  institution: ascii.max(128, 'Max. 128 characters'),
});

const Form: React.FC<OwnProps> = (props) => {

  const [paysByInvoice, setPaysByInvoice] = useState(false);
  const [serverError, setServerError] = useState<Error | null>(null);

  const { errors, handleSubmit, register, reset, control } = useForm<formFields>({
    mode: 'onBlur',
    validationSchema: schema,
    validationContext: { paysByInvoice },
  });
  const { t } = useTranslation();
  const updateMe = useAction(updateMeAction);
  const getPaymentMethod = useAction(getPaymentMethodAction);
  const user = useSelector(selectUser);

  useEffect(() => {
    reset({
      gender: user?.invoiceAddress.gender as Gender || DEFAULT_GENDER,
      firstName: user?.invoiceAddress.firstName || user?.firstName,
      lastName: user?.invoiceAddress.lastName || user?.lastName,
      street: user?.invoiceAddress.street,
      areaCode: user?.invoiceAddress.areaCode,
      city: user?.invoiceAddress.city,
      country: user?.invoiceAddress.country ? [user?.invoiceAddress.country] : [],
      institution: user?.invoiceAddress.institution
    });
    if (user && !user.paymentMethod) {
      getPaymentMethod();
    }
    if (user?.paymentMethod?.type === 'invoice') {
      setPaysByInvoice(true);
    }
  }, [user]);

  const generateCountryOptions = useCallback((countries: { [key: string]: string }) => {
    return Object.values(countries).map(country => ({ name: country, value: country }));
  }, []);

  const cancel = () => {
    props.onCancel();
  };

  const onSubmit = handleSubmit(useCallback(async (formData: formFields) => {
    setServerError(null);
    try {
      await updateMe({
        invoiceAddress: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          gender: formData.gender,
          street: formData.street,
          areaCode: formData.areaCode,
          city: formData.city,
          country: formData.country?.[0],
          institution: formData.institution
        },
      });
      props.onSave && props.onSave();
    } catch (error: any) {
      setServerError(error);
    }
  }, []));

  const genderOptions = [
    { label: t('Female'), value: 'f' },
    { label: t('Male'), value: 'm' },
    { label: t('Not specified'), value: 'w' },
  ];

  const getFieldError = (fieldName: keyof formFields) => {
    if (!errors[fieldName]) return;
    const err = errors[fieldName] as FieldError;
    const errMsg = err.message?.toString();
    return t(errMsg ? errMsg : '');
  };

  return (
    <form onSubmit={onSubmit}>
      <PersonalDetailsInputGroup fullWidthItems>
        <Radio
          name='gender'
          label={t('Gender')}
          options={genderOptions}
          error={getFieldError('gender')}
          ref={register}
        />
      </PersonalDetailsInputGroup>
      <PersonalDetailsInputGroup>
        <TextInput
          name='firstName'
          required={paysByInvoice}
          label={t('First name')}
          error={getFieldError('firstName')}
          ref={register} />
        <TextInput
          name='lastName'
          required={paysByInvoice}
          label={t('Last name')}
          error={getFieldError('lastName')}
          ref={register} />
      </PersonalDetailsInputGroup>
      <PersonalDetailsInputGroup>
        <TextInput
          name='street'
          required={paysByInvoice}
          label={t('Street and house number')}
          error={getFieldError('street')}
          ref={register} />
        <TextInput
          name='areaCode'
          required={paysByInvoice}
          label={t('Postal Code')}
          error={getFieldError('areaCode')}
          ref={register} />
        <TextInput
          name='city'
          required={paysByInvoice}
          label={t('City')}
          error={getFieldError('city')}
          ref={register} />
      </PersonalDetailsInputGroup>
      <PersonalDetailsInputGroup>
        <FormInputWrapper
          label={t('Country')}
          required={paysByInvoice}
          error={getFieldError('country')}
          labelFor='country'
        >
          <Controller
            as={<Select
              options={generateCountryOptions(countries)}
              label={t('Country')}
            />}
            control={control}
            name='country'
          >
          </Controller>
        </FormInputWrapper>
      </PersonalDetailsInputGroup>
      <PersonalDetailsInputGroup>
        <TextInput
          name='institution'
          label={t('Institution')}
          error={getFieldError('institution')}
          ref={register} />
      </PersonalDetailsInputGroup>
      <PersonalDetailsActions
        onCancel={cancel}
        showError={!!serverError}
      />
    </form>
  );
};

export default Form;
