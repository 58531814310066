import React from 'react';
import {
  Typography,
  ListItem,
  List,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Lernset } from '@klab-berlin/api-sdk/lib/types/responses/Lernsets';
import LernsetListItem from './LernsetListItem';
import { spaces } from '../../../../assets/styles/themes/tokens';

export interface LernsetsListProps {
  lernsets: Lernset[];
  handleLernsetClick: (id: string) => void;
  selectedLernsetId?: string;
}

const LernsetsList = (props: LernsetsListProps) => {
  const { t } = useTranslation();
  const { lernsets, handleLernsetClick, selectedLernsetId } = props;

  return (
    <List sx={{
      paddingTop: '0px',
      paddingBottom: '0px',
    }}>
      {
        lernsets && lernsets.length
          ? lernsets.map((lernset: Lernset, index: number) => {
            return <LernsetListItem
              key={index}
              lernset={lernset}
              selected={selectedLernsetId === lernset.id}
              handleLernsetClick={handleLernsetClick}
              sx={{
                marginBottom: index !== lernsets.length - 1 ? spaces.xs : 0,
              }}
            >
              <Typography variant='body1'>
                { lernset.title }
              </Typography>              
            </LernsetListItem>;
          })
          : <ListItem>
            <Typography variant='body2'>
              {t('There will be a list of created lessons here.')}
            </Typography>
          </ListItem>
      }
    </List>
  );
};

export default LernsetsList;
