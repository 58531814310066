import React from 'react';
import { Link } from 'found';
import { routes } from '../../constants';
import LoginGradientModalAppLayout from '../../components/Login/LoginGradientAppLayout';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const IpErrorPage: React.FC = () => {

  const { t } = useTranslation();

  return (
    <LoginGradientModalAppLayout>
      <h2 className='text-center'>{t('Login failed')}</h2>
      <div className='mb-3'>{t('You are not within the ip range of your library, use a vpn client.')}</div>
      <div>{t('You are not a library user anymore but want to keep using meinUnterricht?')}</div>
      <Row className='my-3'>
        <Col>
          <Button
            variant='primary'
            type='submit'
            as={Link}
            className='login-button w-100'
            to={`/${routes.setNewEmail.root}`}
          >
            <span>{t('Yes, continue using meinUnterricht')}</span>
          </Button>
        </Col>
      </Row>
      <div className='text-center'>{t('or')}</div>
      <Row className='mt-4 text-center'>
        <Col>
          <Link to={`/${routes.login.root}`}>
            {t('Back to login')}
          </Link>
        </Col>
      </Row>
    </LoginGradientModalAppLayout>
  );
};

export default IpErrorPage;
