import config from 'config';
import React, { useState } from 'react';
import FriendlyCaptcha from '../../components/common/FriendlyCaptcha';
import services from '../../services';
import { notifyBugsnagHandledError } from '../bugsnagClient';

const useCaptcha = (): [() => JSX.Element, boolean] => {
  const [isCaptchaSolved, setIsCaptchaSolved] = useState<boolean>(false);

  const handleCaptchaSolved = async (solution: string) => {
    try {
      const { isValidCaptcha } = await services.auth.validateCaptcha(solution, config.friendlyCaptchaSiteKey);
      setIsCaptchaSolved(isValidCaptcha);
    }
    catch (err) {
      // We don't want to block real users from registering. 
      // So if there is an error with the request, we let the user pass.
      setIsCaptchaSolved(true);
      notifyBugsnagHandledError(err as Error);
    }
  };

  const handleCaptchaError = (err: any) => {
    setIsCaptchaSolved(true);
    notifyBugsnagHandledError(err as Error);
  };

  const renderCaptcha = () => <FriendlyCaptcha doneCallback={handleCaptchaSolved} errorCallback={handleCaptchaError} />;

  return [renderCaptcha, isCaptchaSolved];
};

export default useCaptcha;
