import React from 'react';
import { AppBar, Toolbar, Typography, Box, Tooltip, SvgIcon, Button } from '@mui/material';
import { palette, spaces, topBarHeight, borderRadius } from '../../../assets/styles/themes/tokens';
import LernsetTitleInput from '../LernsetTitleInput';
import { TopbarTitle } from '../LernsetsMasterLayout/LernsetsMasterLayout';
import arrowLeft from '../../../assets/lernsets/icons/arrow-left.svg';
import TitleLogo from '../../../assets/lernsets/logos/mU-title.svg';

interface OwnProps {
  title?: TopbarTitle;
  onBackClick?: () => void;
  rightComponent?: React.JSX.Element;
  isPublicView?: boolean;
}

const LernsetsTopbar: React.FC<OwnProps> = (props) => {
  const {
    title,
    onBackClick,
    rightComponent,
    isPublicView
  } = props;

  const onNewTitleSave = async (newTitle: string) => {
    if (title?.onNewTitleSave) await title.onNewTitleSave(newTitle);
  };

  const renderTitle = () => {
    if (isPublicView) return null;
    
    if (title?.text && title?.isEditable) {
      return <LernsetTitleInput title={title.text} onNewTitleSave={onNewTitleSave}/>;
    }
  
    if (title?.text && !title?.isEditable) {
      return (
        <Typography
          variant='h6'
          color={palette.text.primary}
          noWrap={true}
          sx={{
            paddingLeft: spaces.sm,
          }}
        >
          {title.text}
        </Typography>
      );
    }
  
    return null;
  };

  const renderMUTitleLogo = () => 
    <SvgIcon component={TitleLogo}
      viewBox='0 0 134 32'
      data-testid='mu-title-logo'
      sx={{
        width: 134,
        height: 32,
      }} />;

  return (
    <AppBar
      elevation={0}
      sx={{
        display: 'flex',
        position: 'initial',
        height: topBarHeight,
        borderBottom: `1px solid ${palette.divider}`,
        backgroundColor: palette.background?.default,
      }}
    >
      <Toolbar sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center',
        width: '100%',
        '&.MuiToolbar-root': {
          padding: `${spaces.sm} ${spaces.md}`,
        }
      }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          width: 'inherit',
          gap: spaces.xs,
        }}>
          { onBackClick && (
            <Tooltip 
              title={title?.tooltipMessage}
              arrow
              placement='bottom'>
              <Button
                color='secondary'
                size='small'
                onClick={onBackClick}
                data-testid='back-button'
                sx={{
                  minWidth: 'auto',
                  borderRadius: borderRadius.xs,
                  padding: `${spaces.xxs} ${spaces.xs}`,
                }}>
                <SvgIcon component={arrowLeft} sx={{ fill: palette.action.active }}/>
              </Button>
            </Tooltip>
          )}
          {isPublicView && renderMUTitleLogo()}
          {renderTitle()}
        </Box>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          flexShrink: 0,
        }}>
          {rightComponent}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default LernsetsTopbar;
