import React from 'react';
import './readerProductNavigation.scss';
import classNames from 'classnames';

interface ReaderProductNavigationWrapperProps {
  expandButton?: React.ReactNode;
  isExpanded?: boolean;
  head?: React.ReactNode;
  attachmentsButton?: React.ReactNode;
  items?: React.ReactNode;
}

const ReaderProductNavigationWrapper: React.FC<ReaderProductNavigationWrapperProps> = (props) => {
  return (
    <div
      data-testid='table-of-contents__wrapper'
      className={
        classNames('table-of-contents-wrapper', { 'table-of-contents-wrapper__expanded': props.isExpanded })
      }
    >
      <div className='table-of-contents'>
        <div className='table-of-contents__head table-of-contents__item'>
          {props.head}
        </div>
        {
          !!props.attachmentsButton && 
          <div className='table-of-contents__attachments-button' data-testid='table-of-contents__attachments-button'>
            {props.attachmentsButton}
          </div>
        }
        {props.expandButton}
        <div className='table-of-contents__items' data-testid='table-of-contents__items'>
          <div className='d-flex flex-column'>
            {props.items}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReaderProductNavigationWrapper;
