import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectPublishers, selectPublisherLoadingStatus } from '../../reducers/publishers.selectors';
import useFilterStatus from './useFilterStatus';
import { Select } from '../common/Select';
import SearchFilterSkeleton from './SearchFilterSkeleton';
import { BasicFilterProps } from '../../types/search';

const FilterByPublisher: React.FC<BasicFilterProps<string[]>> = (
  { updateFilter, clearFilter, currentValue, onToggle, onItemClick }
) => {
  const { t } = useTranslation();

  const publishers = useSelector(selectPublishers);
  const { isReady: isPublishersListReady, isError } = useFilterStatus(selectPublisherLoadingStatus);

  const options = publishers.map(publisher => ({
    value: publisher.id,
    name: publisher.textValue,
  }));

  if (isError) {
    return null;
  }

  if (!isPublishersListReady) {
    return <SearchFilterSkeleton />;
  }

  return (
    <Select
      onSelectionConfirm={(publishers) => {
        updateFilter(publishers);
      }}
      onSelectionClear={() => {
        clearFilter();
      }}
      label={t('Publisher')}
      options={options}
      value={currentValue}
      alwaysShowLabel={true}
      multi={true}
      onToggle={onToggle}
      onItemClick={onItemClick}
      saveButtonLabel='Anwenden'
      resetButtonLabel='Löschen'
    />
  );
};

export default FilterByPublisher;
