import React from 'react';
import { useTranslation } from 'react-i18next';
import PersonalDetailsSection from '../Common/PersonalDetailsSection';
import PersonalDetailsPaymentMethodForm from './PersonalDetailsPaymentMethodForm';
import PersonalDetailsPaymentMethodView from './PersonalDetailsPaymentMethodView';

const PersonalPaymentMethodSection: React.FC = () => {
  const { t } = useTranslation();
  
  return (
    <div data-testid='payment-method-section'>
      <PersonalDetailsSection title={t('Payment method')}
        renderNormalView={() => {
          return <PersonalDetailsPaymentMethodView />;
        }}
        renderFormView={(closeEditMode) => {
          return <PersonalDetailsPaymentMethodForm onResponse={closeEditMode} />;
        }}
      />
    </div >
  );
};

export default PersonalPaymentMethodSection;
