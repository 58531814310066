import React from 'react';
import { useSelector } from 'react-redux';
import { routes } from '../../constants';
import { selectUser } from '../../reducers/user.selectors';
import useRouter from '../../utils/useRouter';

const RedirectIfWalkIn: React.FC = (props) => {
  const user = useSelector(selectUser);
  const { router } = useRouter();

  if (user?.isWalkIn) {
    router.replace(`/${routes.search.root}`);
    return <></>;
  }
  return (
    <>
      {props.children}
    </>
  );
};

export default RedirectIfWalkIn;
