import React, { memo } from 'react';
import { ContentItem } from '../types/AppContent';
import Item from './Item';
import classnames from 'classnames';
import { openItem, itemLink, isModifiedMouseClick } from '../utils';
import useRouter from '../utils/useRouter';
import { useViewMode } from './ViewSwitch/ViewModeContext';

interface Props {
  item: ContentItem;
  isSelected: boolean;
  deleteItemFromCollection: any;
  openOnClick: boolean;
  setActiveId: any;
}

const ItemsListItem: React.FC<Props> = memo(({ item, ...props }) => {
  const { router } = useRouter();
  const { viewMode } = useViewMode();

  const onClickHandler = (event: React.MouseEvent) => {
    if (isModifiedMouseClick(event)) return;

    event.preventDefault();
    event.stopPropagation();

    if (props.openOnClick) {
      openItem(router, item.id, item.type);
      return;
    }

    props.setActiveId(item.id);
  };

  return (
    <a
      key={item.id}
      href={itemLink(item.id, item.type)}
      onClick={onClickHandler}
      onDoubleClick={() => openItem(router, item.id, item.type)}
      className={classnames('d-block', { 'mb-1': viewMode === 'grid' })}
    >
      <Item
        selected={props.isSelected}
        item={item}
      />
    </a>
  );
});

export default ItemsListItem;
