import React, { useCallback } from 'react';
import ResetPassword from '../components/Login/ResetPassword';
import { withRouter, WithRouter } from 'found';
import { routes, errorCodes } from '../constants';
import services from '../services';
import { trackingEvents } from '../services/tracking/trackConfig';
import LoginGradientModalAppLayout from '../components/Login/LoginGradientAppLayout';
import RedirectIfLoggedIn from '../router/redirect/RedirectIfLoggedIn';

type ResetPasswordPageProps = WithRouter;

const ResetPasswordPage: React.FC<ResetPasswordPageProps> = (props) => {
  const handleResetPasswordSuccess = useCallback(() => {
    props.router.push(`/${routes.resetPasswordConfirmed.root}`);
    services.track.eventTrack(trackingEvents.passwordResetSuccessful);
  }, [props.router]);

  const handleResetPasswordFailure = useCallback((statusCode: number, errorCode?: number) => {
    if (errorCode === errorCodes.ipRestriction) {
      props.router.push(`/${routes.ipRangeError.root}`);
    }
    const payload = {
      other: {
        'status_code': statusCode,
      },
    };
    services.track.eventTrack(trackingEvents.passwordResetFailure, payload);
  }, [1]);

  return (
    <RedirectIfLoggedIn>
      <LoginGradientModalAppLayout title='You can not log in?'>
        <ResetPassword
          onResetPasswordSuccess={handleResetPasswordSuccess}
          onResetPasswordFailure={handleResetPasswordFailure}
        />
      </LoginGradientModalAppLayout>
    </RedirectIfLoggedIn>
  );
};

export default withRouter(ResetPasswordPage);
