import config from 'config';

type Attributes =  {
  [key: string]: string;
};

export const loadScript = (scriptSrc: string, attributes: Attributes = {}): void => {
  const headElem = document.getElementsByTagName('head')[0];
  const scriptElem = document.createElement('script');
  scriptElem.src = scriptSrc;
  scriptElem.type = 'text/javascript';

  for (const [key, value] of Object.entries(attributes)) {
    scriptElem.setAttribute(key, value);
  }

  headElem.appendChild(scriptElem);
};

export const loadPSPDFkit = () => {
  loadScript(config.pspdfkit.scriptUrl);
};

export const loadCookiebot = () => {
  loadScript('https://consent.cookiebot.com/uc.js', {
    id: 'Cookiebot',
    'data-cbid': config.cookiebot.cbid,
    async: 'true'
  });
};

export const loadPaymentLibraries = () => {
  loadScript('https://apisandboxstatic.zuora.com/Resources/libs/hosted/1.3.1/zuora-min.js');
  loadScript('https://js.braintreegateway.com/web/3.51.0/js/client.min.js');
  loadScript('https://js.braintreegateway.com/web/3.51.0/js/paypal-checkout.min.js');
  loadScript('https://www.paypalobjects.com/api/checkout.js', { 'data-version-4': 'true' });
};
