import React from 'react';
import { useTranslation } from 'react-i18next';
import { compressClassYearArray } from '../../../utils/compressClassYearArray';
import Truncate from '../../Truncate';
import Icon from '../../Icon';
import ManageItemInCollectionsButton from '../../ManageItemInCollectionsButton';
import PinItemButton from '../../PinItemButton';
import PublisherImage from '../../PublisherImage';
import CardThumbnail from '../common/CardThumbnail';
import './productListItem.scss';
import TagContainer from '../common/TagContainer';
import WithTracking from '../../utils/WithTracking';
import { trackingEvents } from '../../../services/tracking/trackConfig';
import CardDescription from '../common/CardDescription';

interface ProductListItemProps {
  attachmentsCount: number;
  classYears: number[];
  isNew: boolean;
  isReadOnly: boolean;
  description?: string;
  id: string;
  isPinned: boolean;
  mindItemsCount: number;
  publisher?: string;
  subjects: string[];
  thumbnail: string;
  title: string;
  hideCollectionAction?: boolean;
  hidePinAction?: boolean;
  index?: number;
  searchEngineVersion?: number;
  schoolTypes: string[];
}

const ProductListItem: React.FC<ProductListItemProps> = (props) => {
  const {
    isNew,
    isReadOnly,
    id, 
    thumbnail,
    description,
    title,
    subjects,
    publisher,
    isPinned,
    hideCollectionAction,
    hidePinAction,
    classYears,
    mindItemsCount,
    attachmentsCount,
    index,
    searchEngineVersion,
    schoolTypes,
  } = props;

  const { t } = useTranslation();
  const itemData = {
    id,
    title,
    subjects,
    publisher,
    classYears,
    schoolTypes,
    isReadOnly,
  };

  return (
    <WithTracking
      events={{
        onClick: trackingEvents.itemOpen,
        onMiddleClick: trackingEvents.itemOpen,
        onContextMenu: trackingEvents.itemOpen,
      }}
      eventPayload={{ other: { 'product_id': id, index, searchEngineVersion } }}
    >
      <div className='product-list-item' data-testid='product-list-item'>
        <TagContainer isNew={isNew} isReadOnly={isReadOnly} />
        <div className='product-list-item__content'>
          <CardThumbnail
            thumbnailUrl={thumbnail}
            className='product-card__thumbnail'
          />
          <div className='product-list-item__actions'>
            {!hideCollectionAction &&
            <ManageItemInCollectionsButton
              documentId={id}
              itemData={itemData}
              type='product'
            />
            }
            {!hidePinAction &&
            <PinItemButton
              className='mt-2'
              isPinned={isPinned}
              documentId={id}
              type='product'
            />
            }
          </div>
          <div className='product-list-item__body'>
            <div className='product-list-item__title'>
              <Truncate lines={2} text={title} />
            </div>
            {description &&
            <CardDescription
              className='product-list-item__description'
              numLines={5}
              description={description}
            />
            }

          </div>
          <div className='product-list-item__footer'>
            <PublisherImage className='product-list-item__publisher' publisher={publisher} />
            <div className='product-list-item__table'>
              <div className='product-list-item__row'>
                <div className='product-list-item__cell'>
                  <Icon className='product-list-item__footer-icon' icon='subjects' />
                  {t('Subjects')}
                </div>
                <div className='product-list-item__cell'>
                  {subjects.join(', ')}
                </div>
                <div className='product-list-item__cell'>
                  <Icon className='product-list-item__footer-icon' icon='classes' />
                  {t('Class years')}
                </div>
                <div className='product-list-item__cell'>
                  {compressClassYearArray(classYears).join(', ')}
                </div>
              </div>
              <div className='product-list-item__row'>
                <div className='product-list-item__cell'>
                  <Icon className='product-list-item__footer-icon' icon='one-page' />
                  {t('MindItems')}
                </div>
                <div className='product-list-item__cell'>
                  {mindItemsCount}
                </div>
                {attachmentsCount > 0 && <>
                  <div className='product-list-item__cell'>
                    <Icon className='product-list-item__footer-icon' icon='attachment' />
                    {t('Attachments')}
                  </div>
                  <div className='product-list-item__cell'>
                    {attachmentsCount}
                  </div>
                </>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </WithTracking>
  );
};

export default ProductListItem;
