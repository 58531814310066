import React, { memo } from 'react';
import RouterMenuItem from '../RouterMenuItem';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CollectionIcon from '../CollectionIcon';

interface Props {
  to: string;
  title: string;
  isSelected: boolean;
  color: string;
}

const iconWidth = 1;

const CollectionsMenuItem: React.FC<Props> = memo((props) =>
  <RouterMenuItem linkTo={props.to} isSelected={props.isSelected}>
    <Row noGutters={true} className='py-1 text-dark'>
      <Col xs={iconWidth} className='mr-2'>
        <CollectionIcon color={props.color} />
      </Col>
      <Col className='text-truncate'>{props.title}</Col>
    </Row>
  </RouterMenuItem>);

export default CollectionsMenuItem;
