exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".master-layout__wrapper{display:grid;width:100%;height:100%;background:#fafafa;grid-template-rows:auto 1fr;grid-template-areas:\"header\" \"main\"}@media only screen and (max-width: 575px){.master-layout__wrapper{overflow:hidden}}.master-layout__header{grid-area:header}.master-layout__main{grid-area:main;overflow:auto}", ""]);
// Exports
exports.locals = {
	"collectionGridGap": "15px",
	"collectionGridItemHeight": "211px",
	"collectionGridItemWidth": "225px",
	"collectionListHeight": "84px",
	"greyLightest": "#f7f9fa",
	"greyLight2": "#d8d8d8",
	"neuVeryLightBlue": "#e9ebec",
	"muBlue": "#4298b5",
	"gridBreakPoints_-xs": "0",
	"gridBreakPoints_-sm": "576px",
	"gridBreakPoints_-md": "768px",
	"gridBreakPoints_-lg": "992px",
	"gridBreakPoints_-xl": "1440px"
};