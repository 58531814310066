import { PaginationRequest } from '@klab-berlin/api-sdk/lib/types/common';
import {
  CreateCollectionRequest,
  EditCollectionRequest,
} from '@klab-berlin/api-sdk/lib/types/requests/Collection';
import { StoreType } from '../reducers';
import collectionService from '../services/collection.service';
import userService from '../services/user.service';
import { hasNext } from '../utils/pagination';
import { ThunkAction, AsyncActionStatus } from './common.actionTypes';
import {
  ADD_COLLECTION,
  ADD_ITEM_TO_COLLECTION,
  AddCollectionAction,
  AddItemToCollectionAction,
  DELETE_COLLECTION,
  DELETE_ITEM_FROM_COLLECTION,
  DeleteCollectionAction,
  DeleteItemFromCollectionAction,
  EDIT_COLLECTION,
  EditCollectionAction,
  FETCH_COLLECTIONS,
  FETCH_COLLECTION_CONTENTS,
  FETCH_CURATED_COLLECTIONS,
  FETCH_RECOMMENDED_COLLECTIONS,
  FOLLOW_COLLECTION,
  FetchCollectionContentsAction,
  FetchCollectionsAction,
  FetchCuratedCollectionsAction,
  FollowCollectionAction,
  FetchRecommendedCollectionsAction,
  ManageProductInMultipleCollectionsAction,
  ManageMindItemInMultipleCollectionsAction,
  MANAGE_PRODUCT_IN_MULTIPLE_COLLECTIONS,
  MANAGE_MINDITEM_IN_MULTIPLE_COLLECTIONS,
  UNFOLLOW_COLLECTION,
  UnfollowCollectionAction,
} from './collections.actionTypes';
import mindItemService from '../services/mindItem.service';
import productService from '../services/product.service';
import { ManageCollectionRequest } from '@klab-berlin/api-sdk/lib/types/requests/Common';
import { ItemVariation } from '@klab-berlin/api-sdk/lib/types/responses/Collection';
import { ampliTrackCollectionDeletedEvent } from '../services/track.service';
import { trackCollectionEventToAmplitude, ampliTrackCollectionFollowEvent } from '../services/track.service';
import { CollectionProperties } from '../types/AppContent';

type GetStateType = () => StoreType;

const _getCollectionPaginationRequest = (
  getState: GetStateType,
  collectionId: string
): PaginationRequest => ({
  sortOrder: getState().collections.byId[collectionId].pagination.sortOrder,
  version: 2,
});

const hasNextForRequest = (
  getState: GetStateType,
  collectionId: string,
  paginationReq: PaginationRequest
): boolean => {
  return hasNext({
    ...getState().collections.byId[collectionId].pagination,
    ...paginationReq,
  });
};

export function fetchCollections(): ThunkAction<FetchCollectionsAction> {
  return async (dispatch, getState) => {
    const user = getState().user.me;
    const payload = { ownerId: user ? user._id : '' };

    dispatch({ type: FETCH_COLLECTIONS.START, payload });

    userService
      .getCollections()
      .then((result) =>
        dispatch({ type: FETCH_COLLECTIONS.SUCCESS, result, payload })
      )
      .catch(error => dispatch({ error, type: FETCH_COLLECTIONS.ERROR, payload }));
  };
}

export function fetchCollectionContents(
  collectionId: string,
  pagination: PaginationRequest
): ThunkAction<FetchCollectionContentsAction> {
  const payload = { collectionId, pagination };
  return async (dispatch, getState) => {
    if (!hasNextForRequest(getState, collectionId, pagination)) {
      return;
    }

    dispatch({ type: FETCH_COLLECTION_CONTENTS.START, payload });

    collectionService
      .getCollectionContentsByCollectionId(collectionId, pagination)
      .then((result) =>
        dispatch({ type: FETCH_COLLECTION_CONTENTS.SUCCESS, payload, result })
      )
      .catch(
        error => dispatch({
          error,
          type: FETCH_COLLECTION_CONTENTS.ERROR,
          payload,
        })
      );
  };
}

export function fetchCuratedCollections(): ThunkAction<
  FetchCuratedCollectionsAction
  > {
  return async (dispatch) => {
    dispatch({ type: FETCH_CURATED_COLLECTIONS.START });

    collectionService
      .getCuratedCollections()
      .then((result) =>
        dispatch({ type: FETCH_CURATED_COLLECTIONS.SUCCESS, result })
      )
      .catch(error => dispatch({ error, type: FETCH_CURATED_COLLECTIONS.ERROR }));
  };
}

export function fetchRecommendedCollections(
): ThunkAction<FetchRecommendedCollectionsAction> {
  return async (dispatch, getState) => {
    const state = getState();
    if (state.collections.recommendations.status === AsyncActionStatus.READY) {
      return;
    }
    else {
      dispatch({ type: FETCH_RECOMMENDED_COLLECTIONS.START });

      userService
        .getRecommendedCollections()
        .then((result) =>
          dispatch({ type: FETCH_RECOMMENDED_COLLECTIONS.SUCCESS, result })
        )
        .catch(error => dispatch({ error, type: FETCH_RECOMMENDED_COLLECTIONS.ERROR }));
    }
  };
}

export function createCollection(
  payload: CreateCollectionRequest
): ThunkAction<AddCollectionAction> {
  return (dispatch) => {
    dispatch({ type: ADD_COLLECTION.START, payload });

    return collectionService
      .createCollection(payload)
      .then((result) => {
        trackCollectionEventToAmplitude(result, 'created');
        return dispatch({ type: ADD_COLLECTION.SUCCESS, payload, result });
      })
      .then((response) => {
        // keep this in mind when refactoring for caching
        // fetchCollections();
        return response.result;
      })
      .catch(error => dispatch({ error, type: ADD_COLLECTION.ERROR, payload }));
  };
}

export function deleteCollection(
  collectionId: string
): ThunkAction<DeleteCollectionAction> {
  const payload = { collectionId };
  return (dispatch) => {
    dispatch({ type: DELETE_COLLECTION.START, payload });

    return collectionService
      .deleteCollection(collectionId)
      .then((result) => {
        ampliTrackCollectionDeletedEvent(result as CollectionProperties);
        return dispatch({ type: DELETE_COLLECTION.SUCCESS, payload, result });
      })
      .catch(error => dispatch({ error, type: DELETE_COLLECTION.ERROR, payload }));
  };
}

export function manageProductInMultipleCollections(
  itemId: string,
  collections: ManageCollectionRequest['collections']
): ThunkAction<ManageProductInMultipleCollectionsAction> {
  const itemType: ItemVariation = 'product';
  const payload = { collections, itemId, itemType };

  return (dispatch) => {
    dispatch({ type: MANAGE_PRODUCT_IN_MULTIPLE_COLLECTIONS.START, payload });

    return productService
      .manageCollections(itemId, { collections })
      .then(() =>
        dispatch({
          payload,
          result: undefined,
          type: MANAGE_PRODUCT_IN_MULTIPLE_COLLECTIONS.SUCCESS,
        })
      )
      .catch(
        error => dispatch({
          error,
          payload,
          type: MANAGE_PRODUCT_IN_MULTIPLE_COLLECTIONS.ERROR,
        })
      );
  };
}

export function manageMindItemInMultipleCollections(
  itemId: string,
  collections: ManageCollectionRequest['collections']
): ThunkAction<ManageMindItemInMultipleCollectionsAction> {
  const itemType: ItemVariation = 'mindItem';
  const payload = { collections, itemId, itemType };

  return (dispatch) => {
    dispatch({ type: MANAGE_MINDITEM_IN_MULTIPLE_COLLECTIONS.START, payload });

    return mindItemService
      .manageCollections(itemId, { collections })
      .then(() =>
        dispatch({
          payload,
          result: undefined,
          type: MANAGE_MINDITEM_IN_MULTIPLE_COLLECTIONS.SUCCESS,
        })
      )
      .catch(
        error => dispatch({
          error,
          payload,
          type: MANAGE_MINDITEM_IN_MULTIPLE_COLLECTIONS.ERROR,
        })
      );
  };
}

export function addItemToCollection(
  collectionId: string,
  itemType: ItemVariation,
  itemId: string
): ThunkAction<AddItemToCollectionAction> {
  const payload = { collectionId, itemId, itemType };
  return (dispatch, getState) => {
    dispatch({ type: ADD_ITEM_TO_COLLECTION.START, payload });
    return collectionService
      .addItemToCollection(collectionId, itemType, itemId)
      .then(() =>
        dispatch(
          fetchCollectionContents(
            collectionId,
            _getCollectionPaginationRequest(getState, collectionId)
          )
        )
      )
      .catch(
        error => dispatch({
          error,
          payload,
          type: ADD_ITEM_TO_COLLECTION.ERROR
        }),
      );
  };
}

export function deleteItemFromCollection(
  collectionId: string,
  itemType: ItemVariation,
  itemId: string
): ThunkAction<DeleteItemFromCollectionAction> {
  const payload = { collectionId, itemId, itemType };
  return (dispatch, getState) => {
    dispatch({ type: DELETE_ITEM_FROM_COLLECTION.START, payload });

    return collectionService
      .deleteItemFromCollection(collectionId, itemType, itemId)
      .then(() =>
        dispatch(
          fetchCollectionContents(
            collectionId,
            _getCollectionPaginationRequest(getState, collectionId)
          )
        )
      )
      .catch(
        error => dispatch({
          error,
          payload,
          type: DELETE_ITEM_FROM_COLLECTION.ERROR
        })
      );
  };
}

export function followCollection(
  collection: CollectionProperties
): ThunkAction<FollowCollectionAction> {
  const payload = { collectionId: collection.id };
  return (dispatch) => {
    dispatch({ type: FOLLOW_COLLECTION.START, payload });
    return userService
      .followCollection(collection.id)
      .then((result) => {
        ampliTrackCollectionFollowEvent(collection);
        dispatch({ type: FOLLOW_COLLECTION.SUCCESS, payload, result });
      })
      .catch(error => dispatch({ error, type: FOLLOW_COLLECTION.ERROR, payload }));
  };
}

export function unfollowCollection(
  collection: CollectionProperties
): ThunkAction<UnfollowCollectionAction> {  
  const payload = { collectionId: collection.id };
  return (dispatch) => {
    dispatch({ type: UNFOLLOW_COLLECTION.START, payload });
    return userService
      .unfollowCollection(collection.id)
      .then((result) => {
        ampliTrackCollectionFollowEvent(collection, false);
        dispatch({ type: UNFOLLOW_COLLECTION.SUCCESS, payload, result });
      })
      .catch(error => dispatch({ error, type: UNFOLLOW_COLLECTION.ERROR, payload }));
  };
}

export function updateCollection(
  collectionId: string,
  payload: EditCollectionRequest
): ThunkAction<EditCollectionAction> {
  return (dispatch) => {
    dispatch({
      type: EDIT_COLLECTION.START,
      payload: { data: payload, collectionId },
    });

    return collectionService
      .updateCollection(collectionId, payload)
      .then((result) => {
        trackCollectionEventToAmplitude(result, 'edited');
        dispatch({
          type: EDIT_COLLECTION.SUCCESS,
          payload: { data: payload, collectionId },
          result,
        });
      }
      
      )
      .catch(error =>
        dispatch({
          error,
          type: EDIT_COLLECTION.ERROR,
          payload: { data: payload, collectionId },
        })
      );
  };
}
