import React from 'react';
import { externalLinks } from '../../constants';

const DeprecatedRegisterPremiumPage = () => {
  window.location.replace(externalLinks.premiumPackageSelection);

  return <></>;
};

export default DeprecatedRegisterPremiumPage;
