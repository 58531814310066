import React, { useCallback, useEffect } from 'react';
import { Box, Button, Chip, DialogActions,
  DialogTitle, IconButton, Popper, Stack,
  Theme, TooltipProps, Typography,
  useMediaQuery } from '@mui/material';
import { borderRadius, palette, spaces } from '../../assets/styles/themes/tokens';
import {  Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import arrowDown from '../../assets/tooltipArrows/down.svg';
import arrowLeft from '../../assets/tooltipArrows/left.svg';
import arrowRight from '../../assets/tooltipArrows/right.svg';
import arrowUp from '../../assets/tooltipArrows/up.svg';

export interface HintProps  {
  placement: TooltipProps['placement'];
  isNew?: boolean;
  mobilePlacement?: TooltipProps['placement'];
  mobileMaxWidth?: number;
  title?: string;
  description?: string;
  actions?: {
    primary?: {
      label: string;
      onClick: () => void;
    };
    secondary?: {
      label: string;
      onClick: () => void;
    };
  }
  maxWidth?: number;
  width?: number,
  mobileWidth?: number
  disablePortal?: boolean;
  open: boolean;
  children: React.ReactElement<any, any>;
  onClose: () => void;
}

// As this component needs a ref-able children, keep in mind as our components are mostly FC.
// Use ref-able components like Box, div etc. when you see the tooltip doesn't appear.
export const Hint: React.FC<HintProps> = ({ 
  title, 
  placement = 'bottom', 
  open, 
  maxWidth, 
  mobileMaxWidth,
  width,
  mobileWidth,
  onClose, 
  mobilePlacement, 
  children, 
  disablePortal = true,
  description,
  actions,
  isNew,
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobile'));
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const childrenRef = React.useRef(null);

  useEffect(() => {
    setAnchorEl(childrenRef.current);
  }, [childrenRef]);

  const getPlacement = useCallback(() => {
    if (isMobile && mobilePlacement) {
      return mobilePlacement;
    } else {
      return placement;
    }
  }, [mobilePlacement, placement, isMobile]);

  const responsivePlacement = getPlacement();

  const iconButton = <IconButton
    data-testid='hint-close'
    sx={{
      color: palette.primary.contrastText,
      stroke: 'transparent',
      clear: 'right',
    }}
    size='small'
    aria-label='close'
    onClick={onClose}>
    <Close />
  </IconButton>;

  const getButtons = () => {
    if (actions) {
      return <>{actions.secondary && <Button
        size='small'
        sx={{
          borderColor: palette.primary.contrastText,
          color: palette.primary.contrastText,
        }}
        color='inherit'
        variant='outlined'
        onClick={actions.secondary.onClick}
      >
        {actions.secondary.label}
      </Button>}
      {actions.primary && <Button
        size='small'
        color='inherit'
        variant='contained'
        onClick={actions.primary.onClick}
      >
        {actions.primary.label}
      </Button>}</>;
    }
    return;
  };

  const bubble = <Box>
    <Box id='bubble' sx={{
      textAlign: 'left',
      display: 'flex',
      minWidth: 240,
      maxWidth: (isMobile ? mobileMaxWidth : maxWidth) || 400,
      width: (isMobile ? mobileWidth : width) || undefined,
      flexDirection: 'column',
      alignItems: 'flex-start',
      borderRadius: borderRadius.md,
      backgroundColor: palette.primary.main,
      position: 'relative',
      paddingY: spaces.md,
    }}>
      {(description || title) && <DialogTitle sx={{
        paddingY: 0,
        paddingX: spaces.md,
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        gap: spaces.xs,
      }}>
        <Box sx={{ paddingY: spaces.xxs, display: 'flex', flexDirection: 'column', gap: spaces.xs, flexGrow: 1 }}>
          {
            title && 
            <Box sx={{ display: 'flex', padding: 0, alignItems: 'flex-start', gap: spaces.sm, alignSelf: 'stretch' }}>
              {isNew && <Chip label={t('New')} color='info' size='small' sx={{ paddingX: spaces.xs }} />}
              <Typography variant='h6' color={palette.primary.contrastText}>
                {title}
              </Typography>
            </Box>
          }
          {
            description && <Typography variant='body2' color={palette.primary.contrastText}>{description}</Typography>
          }
        </Box>
        {iconButton}
      </DialogTitle>}
      {actions && Object.keys(actions).length !== 0 &&
      (
        isMobile ? 
          <Stack
            spacing={1}
            direction='column'
            sx={{
              padding: spaces.md,
              paddingBottom: 0,
              alignSelf: 'stretch',
            }}
          >
            {getButtons()}
          </Stack> :
          <DialogActions sx={theme => {
            return {
              display: 'flex',
              padding: spaces.md,
              paddingBottom: 0,
              width: '100%',
              [theme.breakpoints.up('tablet')]: {
                justifyContent: 'flex-end',
              },
              [theme.breakpoints.between('mobile', 'tablet')]: {
                justifyContent: 'space-between',
                '& > button': {
                  flexGrow: 1,
                  flexBasis: 0,
                }
              },
            };
          }}>
            {getButtons()}
          </DialogActions>
      )
      }
    </Box>
  </Box>;

  const arrowOffset = 8;

  const getSvgIcon = (placement: HintProps['placement']) => {
    let icon;
    let arrowStyleProperties;

    switch (placement) {
      case 'top':
      case 'top-start':
      case 'top-end':
        icon = arrowDown;
        arrowStyleProperties = { bottom: -arrowOffset, };
        break;
      case 'bottom':
      case 'bottom-start':
      case 'bottom-end':
        icon = arrowUp;
        arrowStyleProperties = {  top: -arrowOffset, };
        break;
      case 'left':
      case 'left-start':
      case 'left-end':
        icon = arrowRight;
        arrowStyleProperties = { right: -arrowOffset };
        break;
      case 'right':
      case 'right-start':
      case 'right-end':
        icon = arrowLeft;
        arrowStyleProperties = { left: -arrowOffset };
        break;
      default:
        throw new Error('Invalid placement');
    }

    return (
      <Box 
        data-popper-arrow 
        id='arrow' 
        sx={{
          lineHeight: 0,
          color: palette.primary.main,
          ...arrowStyleProperties,
        }}>
        <Box sx={{
          position: 'relative',
          '& > svg': {
            width: 'unset',
            height: 'unset',
          }
        }}>
          {React.createElement(icon)}
        </Box>
      </Box>
    );
  };

  const getOffsetBasedOnPlacement = (placement: HintProps['placement']) => {
    let offset = [0, arrowOffset + 4];
    const offsetX = 6;

    if (placement === 'bottom-end' || placement === 'top-end') {
      offset = [offsetX, arrowOffset + 4];
    } else if (placement === 'bottom-start' || placement === 'top-start') {
      offset = [-offsetX, arrowOffset + 4];
    }

    return offset;
  };

  return <>
    {React.cloneElement(React.Children.only(children), { ref: childrenRef })}
    {anchorEl && <Popper
      open={open}
      anchorEl={anchorEl}
      placement={responsivePlacement} 
      disablePortal={disablePortal}
      modifiers={[
        {
          name: 'arrow',
          enabled: true,
          options: {
            padding: spaces.lg,
          },
        },
        {
          name: 'offset',
          options: {
            offset: getOffsetBasedOnPlacement(responsivePlacement),
          }
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: [],
          },
        },
        {
          name: 'preventOverflow',
          enabled: false,
        },
      ]}
    >
      {getSvgIcon(responsivePlacement)}
      {bubble}
    </Popper>}
  </>;
};

export default Hint;
