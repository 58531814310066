import immutable from 'object-path-immutable';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import {
  ActionTypes as DownloadActionTypes,
  FETCH_DOWNLOAD_CONTENTS,
  STORE_DOWNLOADED_ITEM,
} from '../actions/download.actionTypes';
import { LogoutAction, LOGOUT } from '../actions/auth.actionTypes';

type AllDownloadActionTypes = DownloadActionTypes | LogoutAction;

export interface DownloadInitialStateType {
  items: string[];
  error: null | string;
  status: AsyncActionStatus;
}

const initialState: DownloadInitialStateType = {
  items: [],
  error: null,
  status: AsyncActionStatus.LOADING,
};

const download = (state = initialState, action: AllDownloadActionTypes): DownloadInitialStateType => {
  switch (action.type) {
    case LOGOUT.SUCCESS:
      return initialState;

    case FETCH_DOWNLOAD_CONTENTS.START:
      return immutable(state)
        .set('status', AsyncActionStatus.LOADING)
        .value();
    case FETCH_DOWNLOAD_CONTENTS.ERROR:
      return immutable(state)
        .set('status', AsyncActionStatus.ERROR)
        .set('error', action.error.message)
        .value();
    case FETCH_DOWNLOAD_CONTENTS.SUCCESS:
      return immutable(state)
        .set('status', AsyncActionStatus.READY)
        .set('items', action.result.data.map(i => i.id))
        .value();
    case STORE_DOWNLOADED_ITEM: {
      if (state.items.includes(action.payload.id)) {
        return state;
      }
      return immutable(state)
        .push('items', action.payload.id)
        .value();
    }
  }

  return state;
};

export default download;
