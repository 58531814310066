exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".mu-tabs__container{width:100%;flex-wrap:nowrap}.mu-tabs__tab-item{margin-left:16px;flex-basis:170px;flex-shrink:1}@media only screen and (min-width: 768px){.mu-tabs__tab-item{flex-basis:300px}}.mu-tabs__tab-item:last-child{margin-right:16px}.mu-tabs__tab-item-link{font-size:12px;line-height:16px;font-weight:inherit;text-align:center;text-transform:uppercase;padding:4px 0;color:#727e82 !important}@media only screen and (min-width: 768px){.mu-tabs__tab-item-link{font-size:16px;line-height:24px;font-weight:inherit;padding:8px 0}}.mu-tabs__tab-item-link:hover{background-color:#e9ebec;border-bottom:0}.mu-tabs__tab-item-link.active{background-color:#3fa2f7 !important;color:#fff !important;font-weight:bold}", ""]);
// Exports
exports.locals = {
	"collectionGridGap": "15px",
	"collectionGridItemHeight": "211px",
	"collectionGridItemWidth": "225px",
	"collectionListHeight": "84px",
	"greyLightest": "#f7f9fa",
	"greyLight2": "#d8d8d8",
	"neuVeryLightBlue": "#e9ebec",
	"muBlue": "#4298b5",
	"gridBreakPoints_-xs": "0",
	"gridBreakPoints_-sm": "576px",
	"gridBreakPoints_-md": "768px",
	"gridBreakPoints_-lg": "992px",
	"gridBreakPoints_-xl": "1440px"
};