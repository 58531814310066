import { FormControlLabel, Switch, Typography } from '@mui/material';
import React from 'react';
import { useNewDesign } from '../../state/DesignPreferenceContextProvider';
import { useTranslation } from 'react-i18next';
import { spaces } from '../../assets/styles/themes/tokens';
import { OnboardingHint } from '../OnboardingHint';
import { onboardingHints } from '../../constants';
import moment from 'moment';

export const NewDesignSwitch = () => {
  const { t } = useTranslation();
  const { prefersNewDesign, setNewDesignPreference } = useNewDesign();
  const localStorageLargeOnboardingKey: string = `onboarding-${onboardingHints.newUIBanner}`;
  const localStorageLargeOnboardingValue: string = localStorage.getItem(localStorageLargeOnboardingKey) || 'true';
  const isLargeOnboarded: boolean = localStorageLargeOnboardingValue === 'true';

  const localStorageNewUISmallBannerKey: string = `hint-${onboardingHints.newUISmallBanner}`;
  const localStorageNewUISmallBannerValue: string =
    localStorage.getItem(localStorageNewUISmallBannerKey) || moment().format();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewDesignPreference(event.target.checked);
    if (!event.target.checked) {
      localStorage.setItem(localStorageNewUISmallBannerKey, moment().add(1, 'weeks').format());
    }
  };

  const showWithHint: boolean = isLargeOnboarded && moment(localStorageNewUISmallBannerValue).isSameOrBefore(moment());
  const SwitchWithHint = <OnboardingHint
    isNew={false}
    id={onboardingHints.newUISmallBanner}
    description={ t('Did you know? With just one click you can use myLessons in the new design.') }
    placement='bottom-end'
    maxWidth={291}
    disablePortal={false}
    mobilePlacement='bottom-end'
    mobileMaxWidth={291}
    onClose={() => {
      localStorage.setItem(localStorageNewUISmallBannerKey, moment().add(1, 'weeks').format());
    }}
  >
    <Switch
      checked={prefersNewDesign}
      onChange={(event) => onChange(event)}
    />
  </OnboardingHint>;

  const DesignSwitch = (
    <FormControlLabel
      labelPlacement='start'
      sx={{
        display: 'flex',
        gap: spaces.xs,
        margin: 0,
      }}
      control={
        !prefersNewDesign && showWithHint ? SwitchWithHint : <Switch
          checked={prefersNewDesign}
          onChange={(event) => onChange(event)}
        />
      }
      label={
        <Typography variant='subtitle2' color='text.secondary'>
          {t('New Design')}
        </Typography>
      }
    />
  );

  return DesignSwitch;
};

export default NewDesignSwitch;
