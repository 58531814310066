import React, { useContext } from 'react';
import { withRouter, Router, Match } from 'found';

interface RouterContextValue {
  router: Router;
  match: Match;
}

export const RouterContext = React.createContext<RouterContextValue>({
  router: {} as Router,
  match: {} as Match,
});

export const RouterProvider = withRouter((props) => {
  return (
    <RouterContext.Provider value={{
      router: props.router,
      match: props.match,
    }}>
      {props.children}
    </RouterContext.Provider>
  );
});

/**
 * This is added as a DIY implementation of the found >= v0.4 hooks method
 * `useRouter`. This is added as the upgrade of found to 0.4.x created too
 * many issues, and the process of migrating to react-router is itself
 * quite costly.
 * 
 * If and when we either:
 *   * Upgrade found
 *   * Migrate to react-router
 * 
 * The we should be able to replace this function with the implementations
 * provided by these libraries, the functionality of which should be the same
 * (with the noted exception that react-router uses useHistory, useMatch etc
 * rather than useRouter)
 */
const useRouter = () => {
  return useContext(RouterContext);
};

export default useRouter;
