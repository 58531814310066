import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Icon from '../Icon';
import {
  createCollection as createCollectionAction
} from '../../actions/collections.actions';
import useAction from '../../utils/useAction';
import useMedia from '../../utils/useMedia';
import './inlineCollectionCreator.scss';
import { selectUser } from '../../reducers/user.selectors';
import { useSelector } from 'react-redux';

interface InlineCollectionCreatorProps {
  setNewCollectionId: (collectionId: string) => void;
}

interface ICreateCollectionResponse {
  id: string,
}

const InlineCollectionCreator: React.FC<InlineCollectionCreatorProps> = ({ setNewCollectionId }) => {
  const { t } = useTranslation();
  const createCollection = useAction(createCollectionAction);
  const [showInput, setShowInput] = useState(false);
  const [newCollectionName, setNewCollectionName] = useState('');
  const user = useSelector(selectUser);

  const { isMobile } = useMedia();
  const handleAddNewCollection = () => {
    if (!user) return;
    createCollection({ title: newCollectionName })
      .then((data: ICreateCollectionResponse) => {
        setNewCollectionName('');
        setNewCollectionId(data.id);
      });
  };

  const CreateButton = () => (
    <Row>
      <Col xs={9}>
        <Button
          variant='link'
          className='px-0'
          data-testid='create-new-collection'
          onClick={() => setShowInput(true)}>
          <Icon icon='plus' />&nbsp;
          {t('Create new collection')}</Button>
      </Col>
    </Row>
  );

  const Input = () => (
    <div className='create-collection-input'>
      <Form.Control
        className='create-collection-input__input'
        data-testid='create-new-collection-input'
        placeholder={t('Collection Title')}
        // There is a bug with FormControl typing in ts.
        // @see https://github.com/react-bootstrap/react-bootstrap/issues/2781
        // @ts-ignore
        onChange={(e: React.ChangeEvent) => setNewCollectionName(e.target.value)}
        value={newCollectionName}
        autoFocus={true}
      />
      <Button 
        variant='outline-primary' 
        data-testid='create-new-collection-add'
        onClick={handleAddNewCollection} 
        disabled={!newCollectionName}>
        {isMobile ? <Icon icon='plus' /> : t('Add')}
      </Button>
    </div>
  );

  return (
    <Container className='mb-2'>
      {showInput ? <Input /> : <CreateButton />}
    </Container>
  );
};

export default InlineCollectionCreator;
