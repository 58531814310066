import React from 'react';
import { useTranslation } from 'react-i18next';
import OfferPoints from '../OfferPoints';
import Button from '../../../common/Button';
import './schoolLicenceCard.scss';
import { externalLinks } from '../../../../constants';

const offerPoints = [
  'One premium account per teacher',
  '100 downloads per month per account',
  'Attractive discounts of up to 80%',
  'Individual contact person',
  'All meinUnterricht premium content and functionalities',
  'New content every week',
  'No more private costs for you'
];

const SchoolLicenceCard: React.FC = () => {
  const { t } = useTranslation();

  const onButtonClick = () => {
    document.location.href = externalLinks.schoolLicenceOffer;
  };

  return (
    <div className='school-licence-card'>
      <p className='school-licence-card__surtitle'>{t('Schools')}</p>
      <h2 className='school-licence-card__title'>{t('Individual price')}</h2>
      <p className='school-licence-card__subtitle'>{t('By arrangement')}</p>
      <OfferPoints points={offerPoints} areAvailable={true} />
      <Button
        className='school-licence-card__button'
        emoji='👉'
        onClick={onButtonClick}
        variant='black'
      >
        {t('Request a quote')}
      </Button>
    </div>
  );
};

export default SchoolLicenceCard;
