import React, { ReactNode, useState } from 'react';
import { ConfirmModal } from '../components/modals';

type ConfirmFunction = () => void;
type CancelFunction = () => void;
type Message = string | ReactNode;
export interface ConfirmModalSettings {
  message: Message;
  onConfirm?: ConfirmFunction;
  onCancel?: CancelFunction;
}

type ShowFunc = (settings: ConfirmModalSettings) => void;
const noop = () => undefined;
const defaultModalSettings = {} as ConfirmModalSettings;

interface Context {
  settings: ConfirmModalSettings;
  isOpen: boolean;
  show: ShowFunc;
}

const defaultContext: Context = {
  isOpen: false,
  settings: defaultModalSettings,
  show: noop as ShowFunc,
};

const ConfirmModalContext = React.createContext(defaultContext);

const ConfirmationModalProvider: React.FC = (props) => {

  const setModalVisibility = (open: boolean, settings?: ConfirmModalSettings) => {
    setModalState((prevState) => {
      return {
        ...prevState,
        isOpen: open,
        settings: settings || defaultModalSettings,
      };
    });
  };

  const handleCancel = async () => {
    const cancelCallback = modalState.settings.onCancel;
    if (cancelCallback) {
      await cancelCallback();
    }

    setModalVisibility(false);
  };

  const handleConfirm = async () => {
    const confirmCallback = modalState.settings.onConfirm;
    if (confirmCallback) {
      await confirmCallback();
    }

    setModalVisibility(false);
  };

  const defaultState = Object.assign(defaultContext, {
    show: (settings: ConfirmModalSettings) => {
      setModalVisibility(true, settings);
    },
  });

  const [modalState, setModalState] = useState(defaultState);

  return (
    <ConfirmModalContext.Provider value={modalState}>
      <ConfirmModal
        show={modalState.isOpen}
        message={modalState.settings.message}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
      {props.children}
    </ConfirmModalContext.Provider>
  );
};

export { ConfirmModalContext, ConfirmationModalProvider };
