import React from 'react';
import { useTranslation } from 'react-i18next';
import { compressClassYearArray } from '../../../../utils/compressClassYearArray';
import Icon from '../../../Icon';
import PublisherImage from '../../../PublisherImage';
import './itemCardFooter.scss';

export interface ItemCardFooterProps {
  publisher: string;
  subject: string;
  classYears: number[];
  mindItemsCount?: number;
  attachmentsCount?: number;
  isVideo?: boolean;
}

const ItemCardFooter: React.FC<ItemCardFooterProps> = (props) => {
  const { t } = useTranslation();
  const publisherClassName = `item-card-footer__publisher${props.isVideo ? '--video' : ''}`;

  const Subject = () => {
    return (
      <div data-testid='item-card-footer__subject-section'>
        <Icon
          className='item-card-footer__icon'
          icon='subjects'
          label={t('Subject')}
        />
        <span className='item-card-footer__label-text'>{props.subject}</span>
      </div>
    );
  };
  
  const ClassYears = () => {
    const compressedClassYears = compressClassYearArray(props.classYears).join(', ');
  
    return (
      <div data-testid='item-card-footer__class-years-section'>
        <Icon
          className='item-card-footer__icon'
          icon='classes'
          label={t('Class years')}
        />
        <span>{compressedClassYears}</span>
      </div>
    );
  };

  const MindItemsCount = () => (
    <div data-testid='item-card-footer__mind-items-count-section'>
      <Icon
        className='item-card-footer__icon'
        icon='one-page'
        label={t('MindItems')}
      />
      <span>{props.mindItemsCount}</span>
    </div>
  );

  const AttachmentsCount = () => {
    const count = props.attachmentsCount && props.attachmentsCount > 99 ? '99+' : props.attachmentsCount;

    return (
      <div data-testid='item-card-footer__attachments-count-section'>
        <Icon
          className='item-card-footer__icon'
          icon='attachment'
          label={t('Attachments')}
        />
        <span>{count}</span>
      </div>
    );
  };

  return (
    <div className='item-card-footer'>
      <PublisherImage className={publisherClassName} publisher={props.publisher} />
      <div className='item-card-footer__meta'>
        {props.subject ? <Subject /> : null}
        {props.classYears?.length ? <ClassYears /> : null}
        {props.mindItemsCount ? <MindItemsCount /> : null}
        {props.attachmentsCount ? <AttachmentsCount /> : null}
      </div>
    </div>
  ); 
};

export default ItemCardFooter;
