import React from 'react';
import { RangeSliderValue } from './common/RangeSlider/RangeSlider';
import DropdownRangeSelect from './DropdownRangeSelect';
import ModalRangeSelect from './ModalRangeSelect';
import useMedia from '../../../utils/useMedia';

export interface RangeSelectDefaultProps {
  label: string;
  value?: RangeSliderValue;
  range: { min: number; max: number };
  onSelectionClear?: () => void;
  onSelectionConfirm?: (value: RangeSliderValue) => void;
  onChange?: (value?: RangeSliderValue) => void;
  icon?: React.ReactNode;
  onToggle?: () => void;
  alwaysShowLabel?: boolean;
  saveButtonLabel?: string;
  resetButtonLabel?: string;
}

const RangeSelect = (props: RangeSelectDefaultProps) => {
  const handleSelectionConfirm = (value: RangeSliderValue) => {
    props.onSelectionConfirm?.(value);
    props.onChange?.(value);
  };

  const handleSelectionClear = () => {
    props.onSelectionClear?.();
    props.onChange?.();
  };

  const { isCoarsePointer } = useMedia();

  if (isCoarsePointer) {
    return (
      <ModalRangeSelect
        onSelectionConfirm={handleSelectionConfirm}
        onSelectionClear={handleSelectionClear}
        alwaysShowLabel={props.alwaysShowLabel}
        label={props.label}
        value={props.value}
        range={props.range}
        onToggle={props.onToggle}
      />
    );
  }

  return (
    <div className='d-flex'>
      <DropdownRangeSelect
        onSelectionConfirm={handleSelectionConfirm}
        onSelectionClear={handleSelectionClear}
        alwaysShowLabel={props.alwaysShowLabel}
        label={props.label}
        value={props.value}
        range={props.range}
        onToggle={props.onToggle}
        saveButtonLabel={props.saveButtonLabel}
        resetButtonLabel={props.resetButtonLabel}
      />
    </div>
  );
};

export default RangeSelect;
