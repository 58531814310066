import React from 'react';
import { useTranslation } from 'react-i18next';

interface WithAsyncDataOptionsArgument {
  dataProp: string;
  statusProp?: string;
  refetch?: () => void;
}

interface Props {
  [key: string]: any;
}

type WithAsyncDataOptionsArgumentFunction<P> = (props: P) => WithAsyncDataOptionsArgument;
type WithAsyncDataOptions<P> = WithAsyncDataOptionsArgument | WithAsyncDataOptionsArgumentFunction<P>;

/**
 * HOC which handle loading state / error for us
 * It must be placed always after the connect decorator to have an access to store
 *
 * @param {string} options.storePath - store property to observe
 */
const withAsyncData = <P extends Props>(options: WithAsyncDataOptions<P>) => (ComposedComponent: React.FC<any>) => {
  const WrappedComponent = (props: P) => {
    const { t } = useTranslation();
    const opts = typeof options === 'function' ? options(props) : options;

    const data = (opts.dataProp && props[opts.dataProp]);
    const status = opts.statusProp ? props[opts.statusProp] : data && data.status;

    if (!data && status !== 'notfound') {
      return null;
    }
    return <ComposedComponent {...props} t={t} />;
  };

  WrappedComponent.displayName = `withAsyncData(${ComposedComponent.displayName || ComposedComponent.name})`;

  return WrappedComponent;
};

export default withAsyncData;
