import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '../common/Select';
import { getSubTopics as getSubTopicsSelector, getSubTopicLoadingStatus } from '../../reducers/search.selectors';
import { useSelector } from 'react-redux';
import useFilterStatus from './useFilterStatus';
import SearchFilterSkeleton from './SearchFilterSkeleton';
import { BasicFilterProps } from '../../types/search';

interface OwnProps extends BasicFilterProps<string[]> {
  subjects?: string[];
  disabled?: boolean;
}

type Option = {
  name: string;
  value: string;
};

const FilterBySubTopic: React.FC<OwnProps> = (
  { subjects, updateFilter, clearFilter, currentValue, disabled, onToggle, onItemClick }
) => {
  const { t } = useTranslation();
  const subTopicsSelected = useSelector(getSubTopicsSelector);
  const subTopics: Option[] = [];
  if (subjects && subjects.length > 0) {
    subjects.forEach(subject => {
      const subTopicBySubject = subTopicsSelected[subject];
      if (subTopicBySubject) {
        subTopics.push(...subTopicsSelected[subject]);
      }
    });
    subTopics.sort((a: Option, b: Option) => a.name < b.name ? -1 : 1);
  }
  const { isReady, isError } = useFilterStatus(getSubTopicLoadingStatus);

  if (subTopics.length === 0) {
    subTopics.push({
      name: t('All Topics'),
      value: t('All Topics'),
    });
  }

  const options: Option[] = subTopics.map(subTopic => ({
    name: subTopic.name,
    value: subTopic.value
  }));

  if (isError) {
    return null;
  }

  if (!isReady) {
    return <SearchFilterSkeleton />;
  }

  return (
    <Select
      onSelectionConfirm={(values) => {
        updateFilter(values);
      }}
      onSelectionClear={() => {
        clearFilter();
      }}
      label={t('SubTopic')}
      options={options}
      value={currentValue}
      multi={true}
      alwaysShowLabel={true}
      disabled={disabled}
      onToggle={onToggle}
      onItemClick={onItemClick}
      saveButtonLabel='Anwenden'
      resetButtonLabel='Löschen'
    />
  );
};

export default FilterBySubTopic;
