import React from 'react';
import { Link } from 'found';
import { Trans } from 'react-i18next';
import InfoBox from '../common/InfoBox';
import { routes } from '../../constants';
import Icon from '../Icon'; 

interface AttachmentsPromoInfoProps {
  isPremium: boolean;
  productId: string;
}

const AttachmentsPromoInfo: React.FC<AttachmentsPromoInfoProps> = props => {
  const { isPremium, productId } = props;
  return <InfoBox>
    { isPremium ?
      <Trans i18nKey='New! From now on you can find all additional materials clearly in a list under the symbol'>
        <b></b>
        <LinkableIcon url={`/${routes.productAttachmentsPage.root}/${productId}`} />
      </Trans>
      : <Trans i18nKey='Get meinUnterricht premium now! This allows you to download the attachments under the icon'>
        <b></b>
        <Icon icon='attachment' />
      </Trans>
    }
  </InfoBox>;
};

interface LinkableIconProps {
  url: string;
  callback?: () => void;
}

const LinkableIcon: React.FC<LinkableIconProps> = ({ url, children }) => {
  return <Link to={url}>
    <Icon icon='attachment'/> {children}
  </Link>;
};

export default AttachmentsPromoInfo;
