import React, { useState, useContext, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { palette, spaces } from '../../../assets/styles/themes/tokens';
import { Box, Button, SvgIcon, TextField, Typography } from '@mui/material';
import copyLink from '../../../assets/lernsets/icons/link.svg';
import check from '../../../assets/lernsets/icons/check.svg';
import { SnackbarContext } from '../../../state/SnackbarContextProvider';

interface OwnProps { link: string, onCopy: () => void }

const CopyLinkField: React.FC<OwnProps> = (props) => {
  const { t } = useTranslation();
  const { openSnackbar } = useContext(SnackbarContext);
  const { link, onCopy } = props;
  const textFieldRef = useRef<HTMLInputElement>(null);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (isCopied) {
      timeout = setTimeout(() => setIsCopied(false), 5000);
    }

    return () => clearTimeout(timeout);
  }, [isCopied]);

  const handleCopy = () => {
    const errorNotification = () => {
      openSnackbar({
        type: 'error',
        message: t('The link was not copied. Please try again'),
      });
    };

    if (navigator.clipboard) {
      navigator.clipboard.writeText(link)
        .then(() => setIsCopied(true))
        .catch(() => errorNotification());
    } else {
      // Fallback for HTTP environments
      if (textFieldRef.current) {
        try {
          textFieldRef.current?.select();
          document.execCommand('copy');
          setIsCopied(true);
        } catch (error) {
          errorNotification();
        }
      }
    }

    onCopy();
  };
    
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: spaces.xs,
    }}>
      <Typography variant='h6' color={palette.text.primary}>{t('Link')}</Typography>
      <Box sx={{
        display: 'flex',
        gap: spaces.xs,
        alignSelf: 'stretch',
      }}>
        <TextField
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            flex: '1 0 0',
          }}
          size='small'
          inputRef={textFieldRef}
          fullWidth
          variant='outlined'
          value={link} 
          inputProps={{ 
            readOnly: true,
            style: { 
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }
          }}
          InputProps={{
            style: {
              borderRadius: spaces.xs,
            },
          }}
        />
        <Button
          sx={{ width: 122 }}
          onClick={handleCopy}
          variant='contained'
          color='primary'
          size='medium'
          startIcon={
            <SvgIcon 
              component={isCopied ? check : copyLink} 
              sx={{ fill: palette.primary.contrastText }} 
            />
          }
        >
          {isCopied ? `${t('Copied')}!` : t('Copy')}
        </Button>
      </Box>
    </Box>
  );
};

export default CopyLinkField;
