import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { cardDimensions, palette, spaces } from '../../../../assets/styles/themes/tokens';
import { Chip, Fab, IconButton, SxProps, Typography, alpha, Link as MuiLink } from '@mui/material';
import { compressClassYearArray } from '../../../../utils/compressClassYearArray';
import { useTranslation } from 'react-i18next';
import { AddMenu, CoverImage, Metadata, MuiCardBaseProps, mapContentTypeName } from './MuiCardBase';
import getMuiIcon from '../../../../utils/getMuiIcon';
import createNonPropagatingEventHandler from '../../../../utils/createNonPropagatingEventHandler';
import { Link } from 'found';
import { routes } from '../../../../constants';
import IsNewChip from './IsNewChip';
import useRouter from '../../../../utils/useRouter';
import getTextTruncationSx from '../../../../utils/getTextTruncationSx';
  
export const MuiCardBaseShort: React.FC<MuiCardBaseProps> = (props) => {
  const {
    id,
    index,
    contentType,
    publisher,
    cover,
    isReadOnly,
    title,
    description,
    subjects,
    classYears,
    link,
    linkFunction,
    date,
    chipText,
    isPinned,
    isNew,
    itemData,
  } = props;

  const [isTruncated, setIsTruncated] = useState(true);
  const toggleTruncated = () => {
    setIsTruncated(!isTruncated);
  };

  return <Box
    tabIndex={0}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: spaces.md,
      position: 'relative',
      minWidth: cardDimensions.mobile.minWidth,
      maxWidth: cardDimensions.mobile.maxWidth,
      border: '1px solid transparent',
      ...(isTruncated && { height: cardDimensions.short.height }),
    }}
    data-testid={`${contentType}-mui-card`}
  >
    <AddMenu isPinned={isPinned} type={contentType} id={id} itemData={itemData} index={index} />
    { isNew && <IsNewChip size='small' /> }
    <VisibleSection
      contentType={contentType}
      publisher={publisher}
      title={title}
      chipText={chipText}
      classYears={classYears}
      isTruncated={isTruncated}
      toggleTruncated={toggleTruncated}
      cover={cover}
      id={id}
    />
    {!isTruncated && <TruncatedSection
      link={link}
      linkFunction={linkFunction}
      description={description}
      subjects={subjects}
      date={date}
      isReadOnly={isReadOnly}
      isTruncated={isTruncated}
      toggleTruncated={toggleTruncated}
    />}
  </Box>;
};

const MobileMetadataSection: React.FC<{
  chipText?: MuiCardBaseProps['chipText'];
  classYears?: MuiCardBaseProps['classYears'];
  isTruncated: boolean;
  toggleTruncated: () => void;
}> = ({ chipText, classYears, isTruncated, toggleTruncated }) => {
  const { t } = useTranslation();
  return <Box
    data-testid='mui-card-mobile__metadata-section'
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'baseline',
    }}
  >
    <Typography variant='caption' color='secondary' sx={{ display: 'flex', gap: spaces.xs }}>
      {chipText && <span>{chipText}</span>}
      {classYears && classYears.length > 0 &&
        <><span>•</span><span> {compressClassYearArray(classYears).join(', ') + ' ' + t('Class year')} </span></>
      }
    </Typography>
    {isTruncated && <IconButton
      onClick={createNonPropagatingEventHandler(toggleTruncated)}
      sx={{
        position: 'absolute',
        bottom: `-${spaces.xs}`,
        right: `-${spaces.xs}`,
      }}
      aria-label={t('Show more')}
    >
      <Box
        component={getMuiIcon('arrowDown')}
        sx={{ color: palette.text.secondary }}/>
    </IconButton>}
  </Box>;
};

const MobileTitle: React.FC<{
  title: MuiCardBaseProps['title'];
  isTruncated: boolean;
}> = ({ title, isTruncated }) => {
  // do not use line clamp attributes when not truncated

  const truncationSx: SxProps = isTruncated ? getTextTruncationSx(2) : {};

  return <Typography
    data-testid='mui-card-mobile__title'
    variant='h6'
    sx={{
      color: palette.text.primary,
      ...truncationSx,
    }}
  >
    {title}
  </Typography>;
};

const VisibleSection: React.FC<{
  contentType: MuiCardBaseProps['contentType'];
  publisher: MuiCardBaseProps['publisher'];
  title: MuiCardBaseProps['title'];
  chipText?: MuiCardBaseProps['chipText'];
  classYears?: MuiCardBaseProps['classYears'];
  isTruncated: boolean;
  cover: string;
  id: string;
  toggleTruncated: () => void;
}> = ({ contentType, publisher, title, chipText, classYears, isTruncated, toggleTruncated, cover, id }) => {
  const { t } = useTranslation();
  const itemLink: string = contentType === 'product' ?
    `/${routes.productPage.root}/${id}` :
    `/${routes.reader.root}/${id}`;

  return <Box sx={{
    display: 'flex',
    gap: spaces.sm,
    overflow: 'hidden',
  }}
  data-testid='mui-card-mobile__visible-section'>
    <Link to={itemLink}>
      <Box sx={{
        width: contentType === 'video' ? 176 : 94,
        minWidth: contentType === 'video' ? 176 : 94,
        height: isTruncated ? '100%' : 130,
        position: 'relative',
      }}>
        {
          contentType === 'video' &&
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              alignItems: 'center',
              bottom: 0,
              top: 0,
            }}
          >
            <Fab size='small' sx={{
              background: alpha(palette.common.white, 0.32),
              position: 'absolute',
              zIndex: 1,
            }}>
              <Box
                component={getMuiIcon('play')}
                sx={{ color: palette.text.secondary, stroke: palette.text.secondary }} />
            </Fab>
          </Box>
        }
        <CoverImage cover={cover} data-testid='mui-card-mobile__cover-image' />
      </Box>
    </Link>
    <Box
      sx={{
        paddingTop: spaces.xxs,
        paddingBottom: spaces.xxs,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: isTruncated ? 'unset' : spaces.md,
      }}
    >
      <Box
        sx={{
          gap: spaces.xs,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            gap: spaces.sm,
            display: 'flex',
            alignItems: 'baseline',
          }}
        >
          <Typography variant='overline' color='text.secondary' textTransform='uppercase'>
            {t(mapContentTypeName(contentType))}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexFlow: 'column', gap: spaces.xxs }}>
          <Link to={itemLink}>
            <MobileTitle title={title} isTruncated={isTruncated} />
          </Link>
          <Typography variant='caption' color='secondary'> {publisher} </Typography>
        </Box>
      </Box>
      <MobileMetadataSection
        chipText={chipText}
        classYears={classYears}
        isTruncated={isTruncated}
        toggleTruncated={toggleTruncated}
      />
    </Box>
  </Box>;
};

const TruncatedSection: React.FC<{
  link?: MuiCardBaseProps['link'];
  linkFunction?: MuiCardBaseProps['linkFunction'];
  description?: MuiCardBaseProps['description'];
  subjects?: MuiCardBaseProps['subjects'];
  date?: MuiCardBaseProps['date'];
  isReadOnly: MuiCardBaseProps['isReadOnly'];
  isTruncated: boolean;
  toggleTruncated: () => void;
}> = ({
  link,
  linkFunction,
  description,
  subjects,
  date,
  isReadOnly,
  toggleTruncated,
}) => {
  const { t } = useTranslation();
  const { router } = useRouter();

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>, to: string) => {
    e.stopPropagation();
    if (linkFunction) {
      linkFunction();
      return;
    } else {
      router.push(to);
    }
  };

  return <>
    {<IconButton
      aria-label={t('Show less')}
      size='medium'
      onClick={createNonPropagatingEventHandler(toggleTruncated)}
      sx={{
        position: 'absolute',
        bottom: `-${spaces.xs}`,
        right: `-${spaces.xs}`,
      }}
    >
      <Box component={getMuiIcon('arrowUp')} sx={{ color: palette.text.secondary, }} />
    </IconButton>}
    {isReadOnly && <Box><Chip label={t('Read only')} size='small' /></Box>}
    {description && <Typography variant='body2' color='text.secondary' sx={getTextTruncationSx(3)}
      dangerouslySetInnerHTML={{ __html: description }}>
    </Typography>
    }
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: spaces.sm,
      }}
      data-testid='mui-card-mobile__truncated-section'
    >
      {
        link && 
        <MuiLink underline='none' onClick={e => handleLinkClick(e, link.to)} data-testid='mui-card-link' >
          <Metadata
            icon={link.icon}
            label={link.label}
            sx={{ color: undefined, stroke: undefined }}
          />
        </MuiLink>
      }
      {subjects && <Metadata icon='tag' label={subjects.join(', ')} />}
      {date && <Metadata icon='calendar' label={date} />}
    </Box>
  </>;
};

export default MuiCardBaseShort;
