import React, { useContext } from 'react';
import { trackingEvents } from '../services/tracking/trackConfig';
import { CollectionFormModalContext } from '../state/CollectionFormModalProvider';
import { ConfirmModalContext } from '../state/ConfirmModalProvider';
import { ShareModalContext } from '../state/ShareModalProvider';
import { CollectionProperties } from '../types/AppContent';
import { ShareModalItemTypeEnum } from '../types/sharing';
import { createFollowCuratedCollectionTrackingConfig } from '../utils/tracking';
import MeatballItem from './MeatballItem';
import { Trans } from 'react-i18next';
import MeatballMenu from './MeatballMenu';
import WithTracking from './utils/WithTracking';

interface CollectionCardMeatballMenuOwnProps {
  collection: CollectionProperties;
  canPublish: boolean;
  openCollection: (id: string) => void;
  deleteCollection: (id: string) => void;
  followCollection: (ollection: CollectionProperties) => void;
  unfollowCollection: (collection: CollectionProperties) => void;
  publishCollection: (id: string) => void;
  unpublishCollection: (id: string) => void;
}

interface CollectionCardMeatballMenuProps extends CollectionCardMeatballMenuOwnProps {
  userId?: string;
}

const CollectionCardMeatballMenu: React.FC<CollectionCardMeatballMenuProps> = ({ ...props }) => {
  const { show: openShareModal } = useContext(ShareModalContext);
  const { show: openConfirmModal } = useContext(ConfirmModalContext);
  const { show: openCollectionFormModal } = useContext(CollectionFormModalContext);

  const { collection } = props;
  const collectionId = collection.id;
  const isOwner = collection.owner === props.userId;
  const isPublic = collection.isPublic;
  const isFollowed = collection.isFollowing;

  const actions = {
    openCollection: () => props.openCollection(collectionId),
    editCollection: () => openCollectionFormModal(collectionId, collection),
    shareCollection: () => openShareModal(collectionId, ShareModalItemTypeEnum.COLLECTION),
    followCollection: () => isFollowed
      ? props.unfollowCollection(collection)
      : props.followCollection(collection),
    publishCollection: () => props.publishCollection(collectionId),
    unpublishCollection: () => props.unpublishCollection(collectionId),

    deleteCollection: () => openConfirmModal({
      message: (
        <span>
          <Trans i18nKey='Are you sure that you want to delete the collection' />
          <span className='font-weight-bold'>&nbsp;{collection.title}</span>
        </span>
      ),
      onConfirm: () => {
        props.deleteCollection(collectionId);
      },
    }),
  };

  return (
    <MeatballMenu
      itemId={collectionId}
      items={() => [
        <WithTracking events={{ onClick: trackingEvents.collectionOpen }} key='open'>
          <MeatballItem
            onClick={actions.openCollection}
            icon='view'
            label='Open'
          />
        </WithTracking>,
        isOwner && (
          <MeatballItem
            onClick={actions.editCollection}
            icon='edit'
            label='Edit'
          />
        ),
        isPublic && (
          <MeatballItem
            onClick={actions.shareCollection}
            icon='share'
            label='Share'
          />
        ),
        isPublic && !isOwner && (
          <WithTracking
            {...createFollowCuratedCollectionTrackingConfig(isFollowed, collection)}
            key='follow'
          >
            <MeatballItem
              onClick={actions.followCollection}
              icon={isFollowed ? 'check' : 'arrow-right'}
              label={isFollowed ? 'I follow' : 'Follow'}
            />
          </WithTracking>
        ),
        isOwner && props.canPublish && !isPublic && (
          <MeatballItem
            onClick={actions.publishCollection}
            icon='public'
            label='Publish'
          />
        ),
        isOwner && props.canPublish && isPublic && (
          <MeatballItem
            onClick={actions.unpublishCollection}
            icon='private'
            label='Unpublish'
          />
        ),
        !isPublic && (
          <MeatballItem
            onClick={actions.deleteCollection}
            icon='cancel'
            label='Delete'
            iconClass='color--coral'
          />
        ),
      ]}
    />
  );
};

export default CollectionCardMeatballMenu;
