import { Match } from 'found';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import CollectionContext from '../components/contexts/CollectionContext';
import CollectionsAppLayout from '../components/CollectionsAppLayout';
import CollectionsList from '../components/CollectionsList';
import { routes } from '../constants';
import { StoreType } from '../reducers';
import { selectCuratedCollections } from '../reducers/collections.selectors';
import Breadcrumbs from '../components/Breadcrumbs';
import { CollectionProperties } from '../types/AppContent';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import EmptyState from '../components/EmptyState';

interface StateProps {
  curatedCollections: CollectionProperties[];
  collectionStatus: AsyncActionStatus;
}

type CuratedCollectionListPageProps = StateProps & Match;

const CuratedCollectionListPage: React.FC<CuratedCollectionListPageProps> = ({
  curatedCollections,
  collectionStatus,
}) => {

  const [activeId, setActiveId] = useState('');
  const { t } = useTranslation();
  const activeCollection = curatedCollections.find((collection) => collection.id === activeId);
  return (
    <CollectionsAppLayout
      header={
        <Breadcrumbs breadcrumbs={[
          { to: '/kuratierte-kollektionen', title: t('Curated Collections') },
        ]}/>
      }
      mainBody={
        <CollectionsList
          activeId={activeId}
          emptyText={<CuratedCollectionsEmptyState />}
          linkRoot='kuratierte-kollektionen'
          collections={curatedCollections}
          collectionsStatus={collectionStatus}
          setActiveId={setActiveId}
        />
      }
      context={
        <CollectionContext
          ownCollection={false}
          collection={activeCollection}
          linkRoot={routes.curatedCollections.root}
        />
      }
      setActiveId={setActiveId}
    />
  );
};

const CuratedCollectionsEmptyState = () => {
  const { t } = useTranslation();

  return (
    <EmptyState
      iconName='cabinet'
      heading={t('There are no curated collections currently')}
    />
  );
};

const mapState = (state: StoreType): StateProps => ({
  curatedCollections: selectCuratedCollections(state),
  collectionStatus: state.collections.curated.status,
});

export default compose<React.FC<Record<string,never>>>(
  connect(mapState),
)(CuratedCollectionListPage);
