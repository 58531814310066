exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".search-bar{flex-basis:400px;flex-shrink:1;flex-grow:0;border-radius:2px;height:24px;line-height:24px;font-size:16px;position:relative;transition:all .2s;transition:opacity .3s}@media only screen and (max-width: 767px){.search-bar{width:262px}}.search-bar:focus-within{height:32px}.search-bar:focus-within .search-bar__icon{padding:8px;transition:all .2s}.search-bar:focus-within .search-bar__input{color:#2a2a2a}.search-bar:focus-within .search-bar__input input{font-size:16px;color:#2a2a2a;transition:all .2s}.search-bar__input{height:100%}.search-bar__input input{padding-left:32px;padding-right:32px;outline:none;border-radius:2px;transition:all .2s}.search-bar__input input::-moz-placeholder{color:#727e82}.search-bar__input input::placeholder{color:#727e82}.search-bar__icon{position:absolute;display:grid;width:32px;padding:4px 8px;transition:all .2s}.search-bar__icon svg{height:16px;width:16px}.search-bar__icon.reset{right:0}.search-bar__suggestions{display:grid;row-gap:8px;padding-top:8px;padding-bottom:8px}.search-bar__suggestion{padding-left:32px}.search-bar--hidden{opacity:0}", ""]);
// Exports
exports.locals = {
	"collectionGridGap": "15px",
	"collectionGridItemHeight": "211px",
	"collectionGridItemWidth": "225px",
	"collectionListHeight": "84px",
	"greyLightest": "#f7f9fa",
	"greyLight2": "#d8d8d8",
	"neuVeryLightBlue": "#e9ebec",
	"muBlue": "#4298b5",
	"gridBreakPoints_-xs": "0",
	"gridBreakPoints_-sm": "576px",
	"gridBreakPoints_-md": "768px",
	"gridBreakPoints_-lg": "992px",
	"gridBreakPoints_-xl": "1440px"
};