import { useTranslation } from 'react-i18next';
import React from 'react';
import Row from 'react-bootstrap/Row';
import { classOptions } from '../../constants';
import Button from 'react-bootstrap/Button';
import ProfilePill from './ProfilePill';

interface ClassesSelectionProps {
  onChange: (value: any) => void;
  value: number[];
}

const ClassesSelection: React.FC<ClassesSelectionProps> = ({  value, onChange }) => {
  const { t } = useTranslation();
  const optionElements = classOptions.map((option) => {
    const optionIndex = value.indexOf(option);
    const isSelected = optionIndex >= 0;
    return (
      <ProfilePill selected={isSelected} key={option}>
        <Button
          className='w-100 h-100 rounded-circle d-flex'
          onClick={
            () => {
              const newOptions = Array.from(value);
              if (isSelected) {
                newOptions.splice(optionIndex, 1);
              } else {
                newOptions.push(option);
              }
              onChange(newOptions);
            }
          }
        >
          <span className='display-4'>{option}</span>
        </Button>
      </ProfilePill>
    );
  });
  return (
    <>
      <h2 className='mb-3'>
        {t('Which classes are you teaching?')}
      </h2>
      <Row className='classes-selection justify-content-around mx-3'>
        {optionElements}
      </Row>
    </>
  );
};

export default ClassesSelection;
