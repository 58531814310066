import { MindItemSearchResult, Topic } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import { ProductSearchResult } from '@klab-berlin/api-sdk/lib/types/responses/Product';
import immutable from 'object-path-immutable';
import { StoreItem } from '.';
import {
  FetchWorkloadsAction,
  FETCH_WORKLOADS,
  FetchTeachingFormatsAction,
  FETCH_TEACHING_FORMATS,
  SearchMindItemsAction,
  SearchVideoMindItemsAction,
  SearchProductsAction,
  SearchUnifiedAction,
  SearchTopicsAction,
  SEARCH_MIND_ITEMS,
  SEARCH_PRODUCTS,
  SEARCH_UNIFIED,
  SEARCH_TOPICS,
  SEARCH_VIDEO_MIND_ITEMS,
} from '../actions/search.actionTypes';
import { searchSettings } from '../constants';
import { Pagination } from '../types/search';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import { SearchFilters } from '../types/search';
import { arrayToObject } from '../utils/arrayToObject';
import {
  PinMindItemAction,
  PIN_MIND_ITEM,
  PinProductAction,
  PIN_PRODUCT,
  UnpinDocumentAction,
  UNPIN_DOCUMENT,
} from '../actions/pinned.actionTypes';
import { WorkloadListResponse, TeachingFormatListResponse } from '@klab-berlin/api-sdk/lib/types/responses/Text';
import searchResultsStorageManager from '../utils/searchResultsStorageManager';

type SearchActionTypes =
  | SearchMindItemsAction
  | SearchVideoMindItemsAction
  | SearchProductsAction
  | SearchUnifiedAction
  | SearchTopicsAction
  | PinMindItemAction
  | PinProductAction
  | FetchTeachingFormatsAction
  | UnpinDocumentAction
  | FetchWorkloadsAction;

export type MindItemPage = StoreItem<string[]>;
export type ProductPage = StoreItem<string[]>;
export type UnifiedPage = StoreItem<string[]>;
export type Page = MindItemPage | ProductPage | UnifiedPage;
type SubStore = 'mindItems' | 'videoMindItems' | 'products' | 'unified';

export interface SearchInitialStateType {
  searchFilters?: SearchFilters;
  mindItems: {
    pages: MindItemPage[];
    pagination: Pagination;
    byId: {
      [key: string]: MindItemSearchResult;
    };
  };
  videoMindItems: {
    pages: MindItemPage[];
    pagination: Pagination;
    byId: {
      [key: string]: MindItemSearchResult;
    };
  };
  products: {
    pages: ProductPage[];
    pagination: Pagination;
    byId: {
      [key: string]: ProductSearchResult;
    };
  };
  unified: {
    pages: UnifiedPage[];
    pagination: Pagination;
    byId: {
      [key: string]: MindItemSearchResult | ProductSearchResult;
    };
  },
  subTopics: {
    status: AsyncActionStatus;
    terms: {
      [key: string]: Topic[];
    };
  };
  workloads: {
    status: AsyncActionStatus;
    data: WorkloadListResponse;
  };
  teachingFormats: {
    status: AsyncActionStatus;
    data: TeachingFormatListResponse;
  };
}

const initialPagination: Pagination = {
  total: -1,
  limit: searchSettings.itemsPerPage,
  offset: 0,
};

const initialState: SearchInitialStateType = {
  searchFilters: undefined,
  mindItems: {
    pages: [],
    pagination: initialPagination,
    byId: {}
  },
  videoMindItems: {
    pages: [],
    pagination: initialPagination,
    byId: {}
  },
  products: {
    pages: [],
    pagination: initialPagination,
    byId: {}
  },
  unified: {
    pages: [],
    pagination: initialPagination,
    byId: {}
  },
  subTopics: {
    status: AsyncActionStatus.INITIAL,
    terms: {},
  },
  workloads: {
    status: AsyncActionStatus.INITIAL,
    data: []
  },
  teachingFormats: {
    status: AsyncActionStatus.INITIAL,
    data: []
  }
};

const getSubStores = (state: SearchInitialStateType, itemId: string, types: string[]): SubStore[] => {
  const subStores: SubStore[] = [];

  types.forEach(type => {
    if (type === 'product') {
      subStores.push('products');
    } else if (type === 'mindItem' && state.mindItems.byId[itemId] !== undefined) {
      subStores.push('mindItems');
    } else if (type === 'videoMindItem' && state.videoMindItems.byId[itemId] !== undefined) {
      subStores.push('videoMindItems');
    } else if (state.unified.byId[itemId] !== undefined) {
      subStores.push('unified');
    }
  });

  return subStores;
};

const search = (
  state = initialState,
  action: SearchActionTypes
): SearchInitialStateType => {
  switch (action.type) {
    case SEARCH_MIND_ITEMS.START: {
      const requestPagination: Pagination = action.payload.pagination;
      const requestFilters: SearchFilters = action.payload.filters;
      const incomingPagesStartIndex: number = Math.floor(requestPagination.offset / searchSettings.itemsPerPage);
      const incomingPagesEndIndex: number = Math.floor(
        (requestPagination.offset + requestPagination.limit) / searchSettings.itemsPerPage
      );
      if (action.payload.pagination.offset == 0) {
        state.mindItems = initialState.mindItems;
      }

      let updatedState = immutable(state);

      for (let i = incomingPagesStartIndex; i < incomingPagesEndIndex; i++) {
        updatedState = updatedState.set(['mindItems', 'pages', i], { status: AsyncActionStatus.LOADING });
      }

      updatedState = updatedState.set(['searchFilters'], requestFilters);
      searchResultsStorageManager.setSearchFilters(requestFilters);
      return updatedState.value();
    }

    case SEARCH_MIND_ITEMS.SUCCESS: {
      const requestPagination: Pagination = action.payload.pagination;
      const incomingPagesStartIndex: number = Math.floor(requestPagination.offset / searchSettings.itemsPerPage);
      const incomingPagesEndIndex: number = Math.floor(
        (requestPagination.offset + requestPagination.limit) / searchSettings.itemsPerPage
      );
      let updatedState = immutable(state);
      for (let i = incomingPagesStartIndex; i < incomingPagesEndIndex; i++) {
        const firstItemIndex = (i - incomingPagesStartIndex) * searchSettings.itemsPerPage;
        const lastItemIndex = (i - incomingPagesStartIndex + 1) * searchSettings.itemsPerPage;
        const pageData = action.result.data.slice(firstItemIndex, lastItemIndex);
        const newPage: MindItemPage = {
          status: AsyncActionStatus.READY,
          data: pageData.map(mindItem => mindItem.id),
        };
        updatedState = updatedState.set(['mindItems', 'pages', i], newPage);
        updatedState = updatedState.merge(['mindItems', 'byId'], arrayToObject(pageData, mindItem => mindItem.id));
      }
      return updatedState
        .set(['mindItems', 'pagination', 'total'], action.result.meta.pagination.total)
        .set(['mindItems', 'pagination', 'offset'], state.mindItems.pagination.offset + requestPagination.limit)
        .value();
    }

    case SEARCH_VIDEO_MIND_ITEMS.START: {
      const requestPagination: Pagination = action.payload.pagination;
      const requestFilters: SearchFilters = action.payload.filters;
      const incomingPagesStartIndex: number = Math.floor(requestPagination.offset / searchSettings.itemsPerPage);
      const incomingPagesEndIndex: number = Math.floor(
        (requestPagination.offset + requestPagination.limit) / searchSettings.itemsPerPage
      );
      if (action.payload.pagination.offset == 0) {
        state.videoMindItems = initialState.videoMindItems;
      }

      let updatedState = immutable(state);

      for (let i = incomingPagesStartIndex; i < incomingPagesEndIndex; i++) {
        updatedState = updatedState.set(['videoMindItems', 'pages', i], { status: AsyncActionStatus.LOADING });
      }

      updatedState = updatedState.set(['searchFilters'], requestFilters);
      searchResultsStorageManager.setSearchFilters(requestFilters);
      return updatedState.value();
    }

    case SEARCH_VIDEO_MIND_ITEMS.SUCCESS: {
      const requestPagination: Pagination = action.payload.pagination;
      const incomingPagesStartIndex: number = Math.floor(requestPagination.offset / searchSettings.itemsPerPage);
      const incomingPagesEndIndex: number = Math.floor(
        (requestPagination.offset + requestPagination.limit) / searchSettings.itemsPerPage
      );
      let updatedState = immutable(state);
      for (let i = incomingPagesStartIndex; i < incomingPagesEndIndex; i++) {
        const firstItemIndex = (i - incomingPagesStartIndex) * searchSettings.itemsPerPage;
        const lastItemIndex = (i - incomingPagesStartIndex + 1) * searchSettings.itemsPerPage;
        const pageData = action.result.data.slice(firstItemIndex, lastItemIndex);
        const newPage: MindItemPage = {
          status: AsyncActionStatus.READY,
          data: pageData.map(videoMindItem => videoMindItem.id),
        };
        updatedState = updatedState.set(['videoMindItems', 'pages', i], newPage);
        updatedState = updatedState.merge(
          ['videoMindItems', 'byId'],
          arrayToObject(pageData, videoMindItem => videoMindItem.id)
        );
      }
      return updatedState
        .set(['videoMindItems', 'pagination', 'total'], action.result.meta.pagination.total)
        .set(['videoMindItems', 'pagination', 'offset'],
          state.videoMindItems.pagination.offset + requestPagination.limit)
        .value();
    }

    case SEARCH_PRODUCTS.START: {
      const requestPagination: Pagination = action.payload.pagination;
      const requestFilters: SearchFilters = action.payload.filters;
      const incomingPagesStartIndex: number = Math.floor(requestPagination.offset / searchSettings.itemsPerPage);
      const incomingPagesEndIndex: number = Math.floor(
        (requestPagination.offset + requestPagination.limit) / searchSettings.itemsPerPage
      );
      if (action.payload.pagination.offset == 0) {
        state.products = initialState.products;
      }

      let updatedState = immutable(state);

      for (let i = incomingPagesStartIndex; i < incomingPagesEndIndex; i++) {
        updatedState = updatedState.set(['products', 'pages', i], { status: AsyncActionStatus.LOADING });
      }

      updatedState = updatedState.set(['searchFilters'], requestFilters);
      searchResultsStorageManager.setSearchFilters(requestFilters);
      return updatedState.value();
    }

    case SEARCH_PRODUCTS.SUCCESS: {
      const requestPagination: Pagination = action.payload.pagination;
      const incomingPagesStartIndex: number = Math.floor(requestPagination.offset / searchSettings.itemsPerPage);
      const incomingPagesEndIndex: number = Math.floor(
        (requestPagination.offset + requestPagination.limit) / searchSettings.itemsPerPage
      );
      let updatedState = immutable(state);
      for (let i = incomingPagesStartIndex; i < incomingPagesEndIndex; i++) {
        const firstItemIndex = (i - incomingPagesStartIndex) * searchSettings.itemsPerPage;
        const lastItemIndex = (i - incomingPagesStartIndex + 1) * searchSettings.itemsPerPage;
        const pageData = action.result.data.slice(firstItemIndex, lastItemIndex);
        const newPage: ProductPage = {
          status: AsyncActionStatus.READY,
          data: pageData.map(product => product.id),
        };
        updatedState = updatedState.set(['products', 'pages', i], newPage);
        updatedState = updatedState.merge(['products', 'byId'], arrayToObject(pageData, product => product.id));
      }
      return updatedState
        .set(['products', 'pagination', 'total'], action.result.meta.pagination.total)
        .set(['products', 'pagination', 'offset'], state.products.pagination.offset + requestPagination.limit)
        .value();
    }

    case SEARCH_MIND_ITEMS.ERROR: {
      const requestPagination: Pagination = action.payload.pagination;
      const incomingPageIndex: number = Math.floor(requestPagination.offset / searchSettings.itemsPerPage);

      return immutable(state)
        .set(['mindItems', 'pages', incomingPageIndex],
          { status: AsyncActionStatus.ERROR, error: action.error.message }
        )
        .value();
    }

    case SEARCH_VIDEO_MIND_ITEMS.ERROR: {
      const requestPagination: Pagination = action.payload.pagination;
      const incomingPageIndex: number = Math.floor(requestPagination.offset / searchSettings.itemsPerPage);

      return immutable(state)
        .set(['videoMindItems', 'pages', incomingPageIndex],
          { status: AsyncActionStatus.ERROR, error: action.error.message }
        )
        .value();
    }

    case SEARCH_PRODUCTS.ERROR: {
      const requestPagination: Pagination = action.payload.pagination;
      const incomingPageIndex: number = Math.floor(requestPagination.offset / searchSettings.itemsPerPage);

      return immutable(state)
        .set(['products', 'pages', incomingPageIndex],
          { status: AsyncActionStatus.ERROR, error: action.error.message }
        )
        .value();
    }

    case SEARCH_UNIFIED.START: {
      const requestPagination: Pagination = action.payload.pagination;
      const requestFilters: SearchFilters = action.payload.filters;
      const incomingPageIndex: number = Math.floor(requestPagination.offset / searchSettings.itemsPerPage);
      if (action.payload.pagination.offset == 0) {
        state.unified = initialState.unified;
      }

      let updatedState = immutable(state);

      for (let i = incomingPageIndex; i < incomingPageIndex + 1; i++) {
        updatedState = updatedState.set(['unified', 'pages', i], { status: AsyncActionStatus.LOADING });
      }

      updatedState = updatedState.set(['searchFilters'], requestFilters);
      searchResultsStorageManager.setSearchFilters(requestFilters);

      return updatedState.value();
    }

    case SEARCH_UNIFIED.SUCCESS: {
      const requestPagination: Pagination = action.payload.pagination;
      const incomingPagesStartIndex: number = Math.floor(requestPagination.offset / searchSettings.itemsPerPage);
      const incomingPagesEndIndex: number = Math.floor(
        (requestPagination.offset + requestPagination.limit) / searchSettings.itemsPerPage
      );
      let updatedState = immutable(state);
      for (let i = incomingPagesStartIndex; i < incomingPagesEndIndex; i++) {
        const firstItemIndex = (i - incomingPagesStartIndex) * searchSettings.itemsPerPage;
        const lastItemIndex = (i - incomingPagesStartIndex + 1) * searchSettings.itemsPerPage;
        const pageData = action.result.data.slice(firstItemIndex, lastItemIndex);
        const newPage: UnifiedPage = {
          status: AsyncActionStatus.READY,
          data: pageData.map(item => item.id),
        };
        updatedState = updatedState.set(['unified', 'pages', i], newPage);
        updatedState = updatedState.merge(['unified', 'byId'], arrayToObject(pageData, item => item.id));
      }
      return updatedState
        .set(['unified', 'pagination', 'total'], action.result.meta.pagination.total)
        .set(['unified', 'pagination', 'offset'], state.unified.pagination.offset + requestPagination.limit)
        .value();
    }

    case SEARCH_UNIFIED.ERROR: {
      const requestPagination: Pagination = action.payload.pagination;
      const incomingPageIndex: number = Math.floor(requestPagination.offset / searchSettings.itemsPerPage);

      return immutable(state)
        .set(['unified', 'pages', incomingPageIndex],
          { status: AsyncActionStatus.ERROR, error: action.error.message }
        )
        .value();
    }

    case SEARCH_TOPICS.START: {
      return immutable(state)
        .set(['subTopics', 'status'], AsyncActionStatus.LOADING)
        .value();
    }

    case SEARCH_TOPICS.ERROR: {
      return immutable(state)
        .set(['subTopics', 'status'], AsyncActionStatus.ERROR)
        .value();
    }

    case SEARCH_TOPICS.SUCCESS: {
      return immutable(state)
        .set(['subTopics', 'terms', action.payload.subject], action.result)
        .set(['subTopics', 'status'], AsyncActionStatus.READY)
        .value();
    }

    case FETCH_WORKLOADS.START:
      return immutable(state)
        .set(['workloads', 'status'], AsyncActionStatus.LOADING)
        .value();
    case FETCH_WORKLOADS.SUCCESS:
      return immutable(state)
        .set(['workloads', 'data'], action.result)
        .set(['workloads', 'status'], AsyncActionStatus.READY)
        .value();
    case FETCH_WORKLOADS.ERROR:
      return immutable(state)
        .set(['workloads', 'status'], AsyncActionStatus.ERROR)
        .value();

    case FETCH_TEACHING_FORMATS.START:
      return immutable(state)
        .set(['teachingFormats', 'status'], AsyncActionStatus.LOADING)
        .value();
    case FETCH_TEACHING_FORMATS.SUCCESS:
      return immutable(state)
        .set(['teachingFormats', 'data'], action.result)
        .set(['teachingFormats', 'status'], AsyncActionStatus.READY)
        .value();
    case FETCH_TEACHING_FORMATS.ERROR:
      return immutable(state)
        .set(['teachingFormats', 'status'], AsyncActionStatus.ERROR)
        .value();

    case PIN_MIND_ITEM.START:
    case PIN_PRODUCT.START:
    case UNPIN_DOCUMENT.ERROR: {
      const itemId = action.payload.id;
      const type = action.payload.type;

      const subStores: (SubStore)[] = [];
      if (type === 'mindItem') {
        subStores.push(...getSubStores(state, itemId, ['videoMindItem', 'unified', 'mindItem']));
      } else if (type === 'product') {
        subStores.push(...getSubStores(state, itemId, ['unified', 'product']));
      }

      if (!subStores.length) {
        return state;
      }

      let updatedState = state;
      subStores.map((subStore) => {
        const byId = updatedState[subStore].byId;

        if (!byId[action.payload.id]) return;

        const newlyPinnedItem = { ...updatedState[subStore].byId[action.payload.id], isPinned: true };
        updatedState = immutable(updatedState)
          .merge([subStore, 'byId', action.payload.id], newlyPinnedItem)
          .value();
      });

      return updatedState;
    }

    case PIN_MIND_ITEM.ERROR:
    case PIN_PRODUCT.ERROR:
    case UNPIN_DOCUMENT.START: {
      const itemId = action.payload.id;
      const type = action.payload.type;

      const subStores: SubStore[] = [];
      if (type === 'mindItem') {
        subStores.push(...getSubStores(state, itemId, ['videoMindItem', 'unified', 'mindItem']));
      } else if (type === 'product') {
        subStores.push(...getSubStores(state, itemId, ['unified', 'product']));
      }

      if (!subStores.length) {
        return state;
      }

      let updatedState = state;
      subStores.map((subStore) => {
        const byId = updatedState[subStore].byId;
        if (!byId[action.payload.id]) return;

        const newlyPinnedItem = { ...updatedState[subStore].byId[action.payload.id], isPinned: false };

        updatedState = immutable(updatedState)
          .merge([subStore, 'byId', action.payload.id], newlyPinnedItem)
          .value();
      });

      return updatedState;
    }

    default:
      return state;
  }
};

export default search;

