import React from 'react';
import { Trans } from 'react-i18next';
import { DownloadInformationInterface } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import DownloadItem from './DownloadItem';
import './documentDownload.scss';

interface DocumentDownloadProps {
  downloadInformation: DownloadInformationInterface;
  isPremium?: boolean;
}

const DownloadCounter: React.FC<{ counter: number; isPremium: boolean }> = ({ counter, isPremium }) => {
  const downloadCountText = isPremium
    ? 'You downloaded <downloadCount> documents this month.'
    : 'You downloaded <downloadCount> documents.';

  return (
    <div className='download-counter'>
      <Trans i18nKey={downloadCountText} values={{ downloadCount: counter }}>
        <p>This month you downloaded {{ counter }} documents.</p>
      </Trans>
    </div>
  );
};

const DownloadInfo: React.FC<{ isPremium: boolean; counter: number }> = (props) => {
  const { isPremium, counter } = props;

  return <DownloadCounter isPremium={isPremium} counter={counter} />;

};

const DocumentDownload: React.FC<DocumentDownloadProps> = (props) => {
  const { downloadInformation, isPremium } = props;
  const { counter, mainDocument: document } = downloadInformation;

  return (
    <>
      {document &&
        <DownloadItem  data={document} />
      }
      <DownloadInfo isPremium={!!isPremium} counter={counter} />
    </>
  );
};

export default DocumentDownload;
