import React, { useEffect, useRef, useState } from 'react';
import { ZuoraPaymentMethod } from '@klab-berlin/api-sdk/lib/services/billing';
import { useForm, FieldError, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import Checkbox from '../../../components/common/Checkbox';
import InputError from '../../../components/common/InputError/InputError';
import InputLabel from '../../../components/common/InputLabel';
import LicenceAgreementText from '../../../components/common/LicenceAgreementText';
import Icon from '../../../components/Icon';
import SinglePagePaymentMethods
  from '../../../components/SinglePagePaymentMethods';
import { trackingEvents } from '../../../services/tracking/trackConfig';
import { PaymentData, ZuoraPaymentPageResponse } from '../../../types/ZuoraPayments';
import { getPaymentMethodData } from '../../../utils/payment';
import services from '../../../services';
import { addError } from '../../../actions/errors.actions';
import './singlePagePaymentForm.scss';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../reducers/user.selectors';
import { notifyBugsnagHandledError } from '../../../utils/bugsnagClient';
import { UpgradePayload } from '@klab-berlin/api-sdk/lib/types/requests/User';
import { upgrade } from '../../../actions/user.actions';
import useAction from '../../../utils/useAction';
import { PaymentPeriod } from '@klab-berlin/api-sdk/lib/types/common';
import config from 'config';
import TextInput from '../../../components/common/Input/TextInput';
import isFakeEmail from '../../../validators/isFakeEmail';
import canUserCreateSubscription from '../../../validators/canUserCreateSubscription';
import { RegisterLibraryPayload, RegisterPremiumPayload } from '@klab-berlin/api-sdk/lib/types/requests/Registration';
import RegisterSuccessModal from './RegisterSuccessModal';
import UpgradeSuccessModal from './UpgradeSuccessModal';
import getLicenceInformation from './getLicenceInformation';
import { selectLicence } from '../../../reducers/licenceModels.selectors';
import useCaptcha from '../../../utils/useCaptcha';
import Button from '../../../components/common/Button';
import { AmpliSubscriptionSubmitted, AmpliSubscriptionSubmittedPayload } from '../../../constants';
import { getAmpliSubscriptionSubmittedPayload } from '../../../utils/ampli';
import { SubscriptionSubmitted } from '../../../ampli';
import hasValidLibraryDomain from '../../../validators/hasValidLibraryDomain';
import classnames from 'classnames';
import { DiscountInformation } from '@klab-berlin/api-sdk/lib/types/responses/Billing';
import { currencyFormat } from '../../../utils/format';

interface FormFields {
  licenceAgreement: boolean;
  showPaymentForm: boolean;
  email?: string;
}

export enum PaymentMode {
  PremiumUpgrade,
  PremiumRegistration,
}

interface OwnProps {
  library?: string;
  licenceId: number;
  promoCode?: string;
  paymentPeriod: PaymentPeriod;
  paymentMode: PaymentMode;
  redirect?: string;
  isPublicPage?: boolean;  // TODO: Clean up (RED-26)
  promoCodeDiscountInfo?: DiscountInformation;
}

// Functions execute in a different order depending on selected payment method:
// * For SEPA/Visa the order is: [Click Confirm-Payment] -> onSubmit() -> onPaymentMethodCreated() -> upgradeUser()
// * For PayPal the order is: [Confirm Paypal account] -> onPaymentMethodCreated() ->
//   [Click Confirm-Payment] -> onSubmit() -> upgradeUser()
const SinglePagePaymentForm: React.FC<OwnProps> = ({
  library,
  licenceId,
  promoCode,
  paymentPeriod,
  paymentMode,
  promoCodeDiscountInfo,
  redirect,
  isPublicPage,
}) => {
  const { t } = useTranslation();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<ZuoraPaymentMethod>('sepa');
  const [payPalPaymentData, setPayPalPaymentData] = useState<PaymentData | null>(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
  const [isInProgress, setIsInProgress] = useState<boolean>(false);
  const [showPaymentForm, setShowPaymentForm] = useState<boolean>(false);
  const [isPaymentConfirmed, setIsPaymentConfirmed] = useState<boolean>(false);
  const [buttonVariant, setButtonVariant] = useState<'regular' | 'trial' | 'inProgress' | 'library'>();
  const user = useSelector(selectUser);
  const licence = useSelector(state => selectLicence(state, licenceId));
  const licenceData = getLicenceInformation(licence);
  if (promoCodeDiscountInfo) {
    licenceData.price = currencyFormat(promoCodeDiscountInfo?.newPricePerMonth);
  }
  const isTrialLicence = licence.isTrial;
  const isLibraryLicence = !!library && paymentMode === PaymentMode.PremiumRegistration;
  const upgradeAction = useAction(upgrade);
  const [renderCaptcha, isCaptchaSolved] = useCaptcha();

  // We need to use refs for these values because onPaymentMethodCreated() is a callback function.
  // Callbacks take the initial state value and ignore updates.
  const licenceInfoAreaRef = useRef({ promoCode, paymentPeriod });
  const licenceIdRef = useRef(licenceId);
  const userRef = useRef(user);

  const isPaymentMethodPayPal = (paymentMethod: ZuoraPaymentMethod) => paymentMethod === 'braintree';

  useEffect(() => {
    if (isPaymentMethodPayPal(selectedPaymentMethod)) {
      setIsSubmitDisabled(!payPalPaymentData);
    }
  }, [selectedPaymentMethod, payPalPaymentData]);

  useEffect(() => {
    licenceInfoAreaRef.current = { promoCode, paymentPeriod };
    licenceIdRef.current = licenceId;
  }, [promoCode, paymentPeriod, licenceId]);

  useEffect(() => {
    userRef.current = user;
  }, [user]);

  useEffect(() => {
    if (isInProgress) {
      setButtonVariant('inProgress');
    }
    else if (isTrialLicence) {
      setButtonVariant('trial');
    }
    else if (isLibraryLicence) {
      setButtonVariant('library');
    }
    else {
      setButtonVariant('regular');
    }
  }, [isInProgress, isTrialLicence]);

  const onPaymentMethodSelect = (paymentMethod: ZuoraPaymentMethod) => {
    setShowPaymentForm(true);

    if (paymentMethod !== selectedPaymentMethod) {
      setSelectedPaymentMethod(paymentMethod);
      setPayPalPaymentData(null);
    }

    if (!isPaymentMethodPayPal(paymentMethod)) {
      setIsSubmitDisabled(false);
    }
  };

  const formValidator = yup.object<FormFields>({
    licenceAgreement: yup.boolean().oneOf([true], 'You have to agree to our terms and conditions'),
    showPaymentForm: yup.boolean().oneOf([true], 'To register, you must specify a payment method'),
  }).concat(yup.object(
    paymentMode === PaymentMode.PremiumRegistration ? {
      email: yup
        .string()
        .email('The email is not valid')
        .required('This field is required')
        .test('Fake email', 'The email is not valid', isFakeEmail)
        .test('Bad Domain', 'This Email address has an incorrect domain.', (email: string) => {
          if (!library) {
            return true;
          }
          return hasValidLibraryDomain(email, library);
        })
        .test('Existing Email', 'The email you entered already exists.',
          (email: string) => canUserCreateSubscription(email)
        )
    } : undefined
  ));

  const {
    errors,
    handleSubmit,
    register,
    getValues,
    control,
    formState
  } = useForm<FormFields>({
    mode: 'onBlur',
    validationSchema: formValidator,
  });

  const getFieldError = (fieldName: keyof FormFields) => {
    if (!errors[fieldName]) return;
    const err = errors[fieldName] as FieldError;
    const errMsg = err.message?.toString();
    return t(errMsg ? errMsg : '');
  };

  const onSubmit = handleSubmit(async (formData) => {
    const eventProperties: AmpliSubscriptionSubmittedPayload = getAmpliSubscriptionSubmittedPayload({
      licence,
      promoCode,
      billingCycle: paymentPeriod,
      paymentMethod: selectedPaymentMethod,
    } as AmpliSubscriptionSubmitted);

    if (eventProperties) {
      services.track.ampliEventTrack({
        event: new SubscriptionSubmitted(eventProperties)
      });
    }

    setIsSubmitDisabled(true);
    setIsInProgress(true);

    // For PayPal, payment method is created before the payment form is submitted.
    // So we can already check that the userPaymentData exist and upgrade the user.
    if (isPaymentMethodPayPal(selectedPaymentMethod) && payPalPaymentData) {
      switch (paymentMode) {
        case PaymentMode.PremiumUpgrade:
          return upgradeUser(payPalPaymentData);
        case PaymentMode.PremiumRegistration:
          if (!formData.email)
            throw new Error('Can not register a user with no valid email.');
          return registerUser(payPalPaymentData, formData.email);
      }
    }

    if (isLibraryLicence && formData.email) {
      registerLibraryUser(formData.email);
    } else {
      // Creates Zuora payment method. This then triggers onPaymentMethodCreated function.
      window.Z.submit();
    }
  });

  const registerLibraryUser = (userEmail: string) => {
    if (library) {
      const payload: RegisterLibraryPayload = {
        email: userEmail,
        library,
      };
      services.registration.registerLibrary(payload)
        .then(() => setIsPaymentConfirmed(true))
        .catch(() => addError('Ups, something went wrong'));
    }
  };

  const registerUser = (paymentData: PaymentData, userEmail: string) => {
    const paymentMethodId = paymentData.paymentMethodId || paymentData.braintreeToken || '';

    const payload: RegisterPremiumPayload = {
      data: {
        email: userEmail,
      },
      payment: {
        licenceModelId: licenceIdRef.current,
        paymentMethod: getPaymentMethodData(paymentData.type, paymentMethodId),
        paymentPeriod: licenceInfoAreaRef.current.paymentPeriod,
        promoCode: licenceInfoAreaRef.current.promoCode ? licenceInfoAreaRef.current.promoCode : null,
      },
    };
    if (redirect) {
      payload.data.redirectTo = {
        docId: redirect
      };
    }

    services.registration.registerPremium(payload)
      .then(() => {
        services.track.eventTrack(trackingEvents.registerPremiumFinish, {
          other: {
            subscription:
            {
              licenceModelId: payload.payment.licenceModelId,
              paymentMethod: paymentData.type,
              paymentPeriod: payload.payment.paymentPeriod
            }
          },
        });

        setIsPaymentConfirmed(true);
      })
      .catch(() => {
        addError('Ups, something went wrong');
      });
  };

  const upgradeUser = (paymentData: PaymentData) => {
    const paymentMethodId = paymentData.paymentMethodId || paymentData.braintreeToken || '';

    if (userRef.current) {
      const paymentMethod = getPaymentMethodData(paymentData.type, paymentMethodId);
      const upgradePayload: UpgradePayload = {
        paymentMethod,
        licenceData: { licenceModelId: licenceIdRef.current },
        paymentPeriod: licenceInfoAreaRef.current.paymentPeriod,
        promoCode: licenceInfoAreaRef.current.promoCode ? licenceInfoAreaRef.current.promoCode : null,
        trackingData: {},
      };

      upgradeAction(userRef.current.id, upgradePayload)
        .then(() => {
          services.track.eventTrack(
            trackingEvents.upgradeFinish,
            {
              user,
              other: {
                subscription: {
                  licenceModelId: upgradePayload.licenceData.licenceModelId,
                  paymentMethod,
                  paymentPeriod: upgradePayload.paymentPeriod
                }
              }
            }
          );

          setIsPaymentConfirmed(true);
        })
        .catch(() => {
          addError('Ups, something went wrong');
          setIsSubmitDisabled(false);
          setIsInProgress(false);
        });
    } else {
      addError('Ups, something went wrong');
      notifyBugsnagHandledError(new Error(`User can't be upgraded because user not found, user: ${user}`));
      setIsSubmitDisabled(false);
      setIsInProgress(false);
    }
  };

  const onPaymentMethodCreated =
    (paymentMethod: ZuoraPaymentMethod, response: ZuoraPaymentPageResponse) => {
      if (!response.success) {
        addError('Error while setting up payment method, please refresh');
        setIsSubmitDisabled(false);
        return;
      }

      const paymentData = {
        type: paymentMethod,
        paymentMethodId: response.refId,
        braintreeToken: response.auth,
        licenceId: licenceIdRef.current,
      };

      if (isPaymentMethodPayPal(paymentMethod)) {
        setPayPalPaymentData(paymentData);
      } else {
        const email = getValues().email;
        switch (paymentMode) {
          case PaymentMode.PremiumUpgrade:
            upgradeUser(paymentData);
            break;
          case PaymentMode.PremiumRegistration:
            if (!email)
              throw new Error('Can not register a user with no valid email.');
            registerUser(paymentData, email);
            break;
        }
      }
    };

  const onClientSidePaymentErrorHandler = () => {
    setIsSubmitDisabled(false);
  };

  const SuccessModal: React.FC = () => {
    switch (paymentMode) {
      case PaymentMode.PremiumUpgrade:
        return <UpgradeSuccessModal />;
      case PaymentMode.PremiumRegistration:
        return <RegisterSuccessModal />;
    }
  };

  const isButtonDisabled = isSubmitDisabled || formState.isSubmitting || !isCaptchaSolved;

  return (
    <form className='single-page-payment-form' onSubmit={onSubmit}>
      {paymentMode === PaymentMode.PremiumRegistration &&
        <TextInput
          label='E-Mail'
          name='email'
          error={getFieldError('email')}
          ref={register}
          testId='single-page-payment-form-email-input'
          eventPayload={{ other: { licenceModelId: licenceIdRef.current } }}
        />
      }

      {!isLibraryLicence && <>
        <InputLabel className='single-page-payment-form__label'>{t('Payment method')}</InputLabel>

        <Controller
          as={
            <SinglePagePaymentMethods
              zuoraHostedPagesSource={config.zuoraSinglePageUpgradePaymentSource}
              showPaymentForm={showPaymentForm}
              selectedMethod={selectedPaymentMethod}
              onSelect={onPaymentMethodSelect}
              onResponse={onPaymentMethodCreated}
              onClientSideErrorHandler={onClientSidePaymentErrorHandler}
            />
          }
          name='showPaymentForm'
          control={control}
          defaultValue={false}
        />

        <InputError error={getFieldError('showPaymentForm')} />

        {
          isPaymentMethodPayPal(selectedPaymentMethod) &&
          payPalPaymentData?.type === 'braintree' &&
          <p className='paypal-completed-info'><Icon icon='check' /><br />{t('Payment Method noted')}</p>
        }
      </>}

      <div className='single-page-payment-form__licence-agreement-area'>
        <Checkbox
          id='licenceAgreement'
          name='licenceAgreement'
          ref={register}
          testId='licence-agreement-checkbox'
        />
        <LicenceAgreementText />
      </div>
      <InputError error={getFieldError('licenceAgreement')} />
      <div className='single-page-payment-form__captcha'>
        { renderCaptcha() }
      </div>
      <Button
        className={
          classnames(
            'single-page-payment-form__submit',
            { 'single-page-payment-form__submit--isPublicPage': isPublicPage }
          )
        }
        type='submit'
        variant='secondary'
        dataTestId='single-page-payment-submit-btn'
        disabled={isButtonDisabled}
      >
        <span className='single-page-payment-form__submit__title'>
          <b>
            {buttonVariant === 'inProgress' && t('In Progress')}
            {buttonVariant === 'trial' && t('Start premium trial')}
            {buttonVariant === 'regular' && t('Order with costs')}
            {buttonVariant === 'library' && t('Registration')}
          </b>
        </span>
        <br />
        {buttonVariant === 'trial' && <span>({t('Chargeable after the trial period')})</span>}
      </Button>
      {
        isTrialLicence &&
        <p className='single-page-payment-form__trial-information'>
          {t('After the 14 day test phase, you will be charged €<price> a month if you do not cancel ' +
            'your trial subscription. This is valid from <endDate>, for a duration of <months> months',
          licenceData)} {promoCode ? t('The discount is only valid in the first year.') : ''}<br />
        </p>
      }
      { isPaymentConfirmed && <SuccessModal /> }
    </form >
  );
};

export default SinglePagePaymentForm;
