import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import { palette, spaces } from '../../../assets/styles/themes/tokens';

interface OwnProps {
    lernsetCount: number;
}

const DashboardHeader = (props: OwnProps) => {
  const { t } = useTranslation();
  const { lernsetCount } = props;
  return (
    <Box sx={{ display: 'flex' }}>
      <Typography 
        variant='h5' 
        color={palette.text.primary}
        sx={{
          paddingRight: spaces.xs,
        }}
      >
        {lernsetCount > 1 ? t('Lessons') : t('Lesson') }
      </Typography>
      <Typography
        data-testid='lernset-count' 
        variant='subtitle1' 
        color={palette.text.secondary}
        sx={{
          alignSelf: 'end',
        }}
      >
        {`(${lernsetCount})`}
      </Typography>
    </Box>
  );
};

export default DashboardHeader;
