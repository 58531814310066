import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonVariant } from '../common/Button';
import { UpgradeDetailsModalContext } from '../../state/UpgradeDetailsModalProvider';

interface OwnProps {
  handleClick?: () => void;
  className?: string;
  variant?: ButtonVariant;
}

const WhatIsPremiumButton: React.FC<OwnProps> = (props) => {
  const { show: showUpgradeDetails } = useContext(UpgradeDetailsModalContext);
  const { variant = 'ghost' } = props;
  const { t } = useTranslation();
  return (
    <Button
      variant={variant}
      className={props.className}
      onClick={() => { showUpgradeDetails(); props.handleClick?.(); }}
      type='button'>
      {t('What is Premium?')}
    </Button>);
};

export default WhatIsPremiumButton;
