import React from 'react';
import ReactDOM from 'react-dom';
import config from 'config';
import { Provider } from 'react-redux';
import ToastList from './components/ToastList';
import Router from './router/router';
import store from './store';
import bugsnagClient, { notifyBugsnagHandledError } from './utils/bugsnagClient';
import BannerContextProvider from './state/BannerContextProvider';
import { loadPSPDFkit, loadCookiebot, loadPaymentLibraries } from './utils/loadScript';
import { isFeatureEnabled } from './utils/feature';
import { ampli } from './ampli';
import { mapNodeEnvToAmpliEnv } from './constants';
import mainTheme from './assets/styles/themes/mainTheme';
import { ThemeProvider } from '@mui/material';

const ErrorBoundary = bugsnagClient.getPlugin('react').createErrorBoundary(React);

async function loadPolyfills() {
  await import('@ungap/global-this');

  if (typeof window.IntersectionObserver === 'undefined') {
    await import('intersection-observer');
  }
  
  if (typeof window.ResizeObserver === 'undefined') {
    import('resize-observer-polyfill').then((ResizeObserver) => {
      window.ResizeObserver = ResizeObserver.default;
    });
  }
}

const initializeAmpli = () => {
  if (process.env.NODE_ENV && process.env.NODE_ENV in mapNodeEnvToAmpliEnv) {
    const neuDomain = config.urls.neu.replace(/(^\w+:|^)\/\//, '');
    const excludeDomainRegex = new RegExp(`${neuDomain.replace(/\./g, '\\.')}$`);

    ampli.load({ 
      environment: mapNodeEnvToAmpliEnv[process.env.NODE_ENV],
      client: {
        configuration: {
          defaultTracking: {
            attribution: {
              excludeReferrers: [excludeDomainRegex],
            },
            pageViews: false,
            sessions: false,
            fileDownloads: false,
            formInteractions: false,
          },
          trackingOptions: {
            ipAddress: false,
          }
        }
      }
    });
  } else {
    notifyBugsnagHandledError(Error(`NODE_ENV is invalid! NODE_ENV: ${process.env.NODE_ENV}`));
  }
};

function loadScripts() {
  // Load essential scripts
  loadPSPDFkit();

  if (isFeatureEnabled('ampli')) initializeAmpli();
  if (isFeatureEnabled('cookieBot')) loadCookiebot();
  if (isFeatureEnabled('payment')) loadPaymentLibraries();
}

const main = async () => {
  const appContainer = document.getElementById('app');
  if (!appContainer) return;

  loadScripts();

  ReactDOM.render(
    <ErrorBoundary>
      <Provider store={store}>
        <ThemeProvider theme={mainTheme}>
          <ToastList />
          <BannerContextProvider>
            <Router />
          </BannerContextProvider>
        </ThemeProvider>
      </Provider>
    </ErrorBoundary>,
    appContainer
  );
};

loadPolyfills().then(main);
