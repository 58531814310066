import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import config from 'config';
import React from 'react';
import { blurSensitiveData } from './filter';
import { isFeatureEnabled } from './feature';
import { noop } from 'lodash';

const EmptyErrorBoundary: React.FC = (props) => (
  <>{props.children}</>
);

let bugsnagClient: any = {
  getPlugin: () => ({ createErrorBoundary: () => EmptyErrorBoundary }),
  leaveBreadcrumb: noop,
  notify: noop,
  user: { id: null },
};

if (isFeatureEnabled('bugsnag')) {
  bugsnagClient = Bugsnag.start({
    // eslint-disable-next-line no-undef
    apiKey: BUGSNAG_API_KEY,
    autoDetectErrors: config.bugsnag.notify,
    enabledReleaseStages: ['production', 'staging'],
    releaseStage: config.bugsnag.stage,
    plugins: [new BugsnagPluginReact()],
    // eslint-disable-next-line no-undef
    appVersion: APP_VERSION,
  });
}

export function notifyBugsnagNetwork(service: string, method: string, args: any = null) {
  if (!isFeatureEnabled('bugsnag')) return;

  args = blurSensitiveData(args);
  bugsnagClient.leaveBreadcrumb('Network Request', {
    args: JSON.stringify(args, null, 2),
    method,
    service
  });
}

export function notifyBugsnagHandledError(err: Error) {
  if (!isFeatureEnabled('bugsnag')) return;

  bugsnagClient.notify(err);
}

export default bugsnagClient;
