import React from 'react';
import { useTranslation } from 'react-i18next';
import FacebookIcon from '../../assets/FacebookIcon.svg';

const FacebookReplacementButton: React.FC = () => {

  const { t } = useTranslation();

  const renewCookieConsent = () => {
    window.Cookiebot.renew();
  };

  return (
    <div className='d-flex mx-4 align-items-center'>
      <FacebookIcon className='mr-2 fill--facebook-blue'/>
      <div className='text-center'>
        {t('If you want to login via facebook, you need to accept marketing cookies.')}<br/>
        <a href='#' onClick={renewCookieConsent} className='ml-1'>{t('Manage Cookies')}</a>
      </div>
    </div>

  );
};

export default FacebookReplacementButton;
