import React, { useState, useEffect } from 'react';
import config from 'config';
import Alert from 'react-bootstrap/Alert';
import { routes } from '../constants';
import GradientModalAppLayout from '../components/AppLayout/GradientModalLayout';
import SubjectSelection from '../components/SubjectSelection';
import { SelectedSubjects } from '../components/SubjectSelection/SubjectSelection';
import { useSelector } from 'react-redux';
import CreateProfileFrame from '../components/CreateProfileFrame';
import services from '../services';
import { updateMe as updateMeAction } from '../actions/user.actions';
import { selectUser } from '../reducers/user.selectors';
import useAction from '../utils/useAction';
import { useTranslation } from 'react-i18next';
import useRouter from '../utils/useRouter';
import { SubjectsSelected, SubjectsClassified } from '../ampli';

const ONBOARDING_MAX_ATTEMPTS = config.onboarding.maxAttempts;

const CreateProfileSubjectsPage = () => {
  const user = useSelector(selectUser);
  const updateMe = useAction(updateMeAction);
  const { router } = useRouter();
  const { t } = useTranslation();

  const defaultSelectedSubjects = {
    majors: [],
    minors: [],
    isPrioritized: false,
  };
  const [selectedSubjects, setSelectedSubjects] = useState<SelectedSubjects>(defaultSelectedSubjects);

  const [isPrioritizationStep, setIsPrioritizationStep] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subjectChoiceValidationError, setSubjectChoiceValidationError] = useState('');

  useEffect(() => {
    if (!user) return;

    const userPrioritized = user.subjects.prioritized;
    const initialSelectedSubjects = {
      majors: user?.subjects.majors.map(s => s.subject) || [],
      minors: user?.subjects.minors.map(s => s.subject) || [],
      isPrioritized: userPrioritized === undefined ? true : userPrioritized,
    };

    setSelectedSubjects(initialSelectedSubjects);

    if (user.onboardingAttempts < ONBOARDING_MAX_ATTEMPTS) {
      updateMe({
        data: {
          onboarding: {
            attempts: user.onboardingAttempts + 1
          }
        }
      });
    }
  }, [user && user.id]);

  const handleSubmit = () => {
    if (!user) return;

    setIsSubmitting(true);

    const subjects = {
      majors: selectedSubjects.majors.map((subject, i) => ({ subject, priority: i })),
      minors: selectedSubjects.minors.map((subject, i) => ({ subject, priority: i })),
      prioritized: selectedSubjects.isPrioritized
    };
    
    services.track.ampliEventTrack({ 
      event: new SubjectsClassified(), 
      userProperties: { 
        id: user.id,
        subjects
      },
    });

    updateMe({ subjects })
      .then(() => {
        router.push(`/${routes.createProfile.root}/${routes.createProfileClasses.root}`);
      })
      .catch(() => {
        setIsSubmitting(false);
      });
  };

  const isValidSelection = () => {
    return selectedSubjects.majors.length > 0 || selectedSubjects.minors.length > 0;
  };

  const returnHandler = () => {
    setIsPrioritizationStep(false);
  };

  const openPrioritizationStep = () => {
    if (!user) return;
    
    if (!isValidSelection()) {
      setSubjectChoiceValidationError(`
        ${t('Please select at least one subject')}.
        ${t('If your subject isn\'t in the list, choose the button \'none of these options\' at the end')}.
      `);
      return;
    }

    setSubjectChoiceValidationError('');

    const subjects = {
      majors: selectedSubjects.majors.map((subject, i) => ({ subject, priority: i })),
      minors: selectedSubjects.minors.map((subject, i) => ({ subject, priority: i })),
    };
    services.track.ampliEventTrack({
      event: new SubjectsSelected(),
      userProperties: {
        id: user.id,
        subjects,
        isSubjectSelected: true
      }
    });
    
    setIsPrioritizationStep(true);
  };

  const continueHandler = isPrioritizationStep ? handleSubmit : openPrioritizationStep;

  const frameProps = {
    progressBar: {
      numberOfSteps: 4,
      currentStep: 1 + (isPrioritizationStep ? 1 : 0),
    },
    next: {
      action: continueHandler,
      text: t('Next'),
      disabled: isSubmitting
    },
    back: {
      action: returnHandler,
      text: t('Back'),
      disabled: isSubmitting || !isPrioritizationStep
    }
  };

  const subjectSelectHeader =
    <>
      <h1>{t('Which subjects do you teach?')}</h1>
      <p>
        {t('Choose those relevant to you')}
      </p>
    </>;

  return (
    <GradientModalAppLayout>
      <CreateProfileFrame {...frameProps}>
        {!isValidSelection() && subjectChoiceValidationError && (
          <Alert variant='danger'>
            {subjectChoiceValidationError}
          </Alert>
        )}
        {isPrioritizationStep
          ? <h1>{t('Prioritize your subjects')}</h1>
          : subjectSelectHeader
        }
        <SubjectSelection
          selectedSubjects={selectedSubjects}
          onChange={(subjects) => setSelectedSubjects(subjects)}
          isPrioritizationStep={isPrioritizationStep}
        />
      </CreateProfileFrame>
    </GradientModalAppLayout>
  );
};

export default CreateProfileSubjectsPage;
