import { AsyncAction, SyncAction } from './common.actionTypes';
import { CollectionContentsPaginatedData } from '@klab-berlin/api-sdk/lib/types/responses/Collection';

type StoreDownloadedItemType = 'STORE_DOWNLOADED_ITEM';

export enum FETCH_DOWNLOAD_CONTENTS {
  START = 'FETCH_DOWNLOAD_CONTENTS_START',
  SUCCESS = 'FETCH_DOWNLOAD_CONTENTS_SUCCESS',
  ERROR = 'FETCH_DOWNLOAD_CONTENTS_ERROR',
}

export const STORE_DOWNLOADED_ITEM: StoreDownloadedItemType = 'STORE_DOWNLOADED_ITEM';

export type FetchDownloadContentsAction = AsyncAction<
  typeof FETCH_DOWNLOAD_CONTENTS.START,
  typeof FETCH_DOWNLOAD_CONTENTS.SUCCESS,
  typeof FETCH_DOWNLOAD_CONTENTS.ERROR,
  CollectionContentsPaginatedData
>;

export type StoreDownloadedItemAction = SyncAction<
  StoreDownloadedItemType,
  undefined,
  { id: string }
>;

export type ActionTypes = FetchDownloadContentsAction | StoreDownloadedItemAction;
