import billingService from '../services/billing.service';
import { ThunkAction } from './common.actionTypes';
import { 
  FETCH_ACTIVE_LICENCE_MODELS, 
  FetchActiveLicenceModelsAction 
} from './licenceModels.actionTypes';

export function fetchActiveLicenceModels(): ThunkAction<FetchActiveLicenceModelsAction> {
  return async (dispatch) => {
    dispatch({ type: FETCH_ACTIVE_LICENCE_MODELS.START });

    billingService.getActiveLicences()
      .then((result) => dispatch({ type: FETCH_ACTIVE_LICENCE_MODELS.SUCCESS, result }))
      .catch(error => dispatch({ error, type: FETCH_ACTIVE_LICENCE_MODELS.ERROR }));
  };
}
