import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'found';
import MuLogoSvg from '../../assets/MuLogo.svg';
import { routes } from '../../constants';

const ChangeEmailSuccess = () => {
  const { t } = useTranslation();

  return (
    <Container className='h-100 d-flex flex-column justify-content-between'>
      <Row>
        <Container>
          <Row className='my-4'>
            <MuLogoSvg className='fill--mu-blue h-100 w-100'/>
          </Row>
          <Row>
            <div className='font-size-larger my-2'>
              {`${t('Your email was successfully changed and you can use it with the next login')}.`}
            </div>
          </Row>
        </Container>
      </Row>
      <Row className='justify-content-center my-4'>
        <Link className='btn btn-lg btn-primary w-100' to={`/${routes.dashboard.root}`}>
          {t('Back to meinUnterricht')}
        </Link>
      </Row>
    </Container>
  );
};

export default ChangeEmailSuccess;
