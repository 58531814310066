import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Nav } from 'react-bootstrap';
import { ModalsContext } from '../../../../state/ModalsProvider';
import { externalLinks, routes } from '../../../../constants';
import Icon from '../../../Icon';
import Logout from '../../../Logout';
import { ExternalLink, MenuItem, InternalLink } from './TopBarMenuItems';
import './topBarMenu.scss';
import WithTooltip from '../../../WithTooltip';

interface TopBarMenuProps {
  hideWalkInItems: boolean;
}

const TopBarMenu: React.FC<TopBarMenuProps> = ({ hideWalkInItems = false }) => {
  const { openModal } = useContext(ModalsContext);
  const { t } = useTranslation();

  const itemClasses = 'py-0 px-3 mb-4 dropdown-item';

  return (
    <Dropdown
      as={Nav.Item}
      className='top-bar-menu__dropdown-container'
    >
      <WithTooltip text={t('More Items')} placement='bottom'>
        <Dropdown.Toggle id='top-bar-menu' variant='link' className='p-1 border-0 focus--very-light-blue'>
          <Icon icon='ellipsis' size='lg' className='color--white' />
        </Dropdown.Toggle>
      </WithTooltip>
      <Dropdown.Menu alignRight className='pt-3 pb-0 mt-1 border-0 top-bar-menu__menu-container'>
        <InternalLink
          label={t('Profile')}
          iconName='profile'
          to={`/${routes.personalDetails.root}`}
          hide={hideWalkInItems}
          classNames={itemClasses}
        />
        <MenuItem
          onClick={() => openModal('contact')}
          iconName={'subscription'}
          label={t('Contact')}
          classNames={itemClasses}
        />
        <Logout
          render={(handleLogout) => (
            <MenuItem
              onClick={handleLogout}
              iconName={'sign-out'}
              label={t('Sign out')}
              classNames={itemClasses}
            />
          )}
        />
        <ExternalLink
          href={externalLinks.faq}
          newTab={true}
          iconName='help'
          label={t('FAQ')}
          classNames={itemClasses}
        />
        <MenuItem
          onClick={() => openModal('imprint')}
          iconName={'legal'}
          label={t('Imprint')}
          classNames={itemClasses}
        />
        <ExternalLink
          href={externalLinks.tac}
          newTab={true}
          iconName='legal'
          label={t('AGB')}
          classNames={itemClasses}
        />
        <MenuItem
          onClick={() => openModal('privacyPolicy')}
          iconName={'legal'}
          label={t('Data protection')}
          classNames={itemClasses}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default React.memo(TopBarMenu);
