export const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

export const sleepUntil = async (func: () => boolean, timeoutMs: number) => {
  return new Promise<void>((resolve, reject) => {
    const startTime = Date.now();
    const interval = setInterval(() => {
      if (func()) {
        clearInterval(interval);
        resolve();
      } else if (Date.now() - startTime > timeoutMs) { // Timedout
        clearInterval(interval);
        reject();
      }
    }, 20);
  });
};
