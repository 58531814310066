import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Link } from 'found';
import { Navbar, Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getSubscription, selectUser } from '../../../reducers/user.selectors';
import { routes, ampliConversionPath, onboardingHints } from '../../../constants';
import Icon from '../../Icon/Icon';
import SearchBarTop from '../../SearchBarTop/SearchBarTop';
import TopBarMenu from './TopBarMenus/TopBarMenu';
import './topBar.scss';
import TopBarHistoryNavigation from './TopBarHistoryNavigation';
import TopBarMobileMenu from './TopBarMenus/TopBarMobileMenu';
import WithTooltip from '../../WithTooltip';
import { useTranslation } from 'react-i18next';
import { add as dateAdd } from 'date-fns';
import services from '../../../services';
import { useAmplitudeExperiment } from '../../../utils/ampli';
import config from 'config';
import { Theme, useMediaQuery } from '@mui/material';
import { OnboardingHint } from '../../OnboardingHint';
import useRouter from '../../../utils/useRouter';

interface TopBarProps {
  hideSearch?: boolean;
  hideUpgradeToPremiumButton?: boolean;
  hideUpgradeToSchoolLicenceButton?: boolean;
  hideOpenCollections?: boolean;
  hidePinnedDocuments?: boolean;
  hideMenu?: boolean;
  hideHome?: boolean;
  isPublicPage?: boolean;  // TODO: Clean up (RED-26)
}

const TopBar: React.FC<TopBarProps> = (props) => {
  const user = useSelector(selectUser);
  const subscription = useSelector(getSubscription);
  const { t } = useTranslation();
  const [hasUserSubscriptionForOver30Days, setHasUserSubscriptionForOver30Days] = useState(false);
  const canUserUpgradeToSchoolLicence = user?.isPremium
    && !(user.isWalkIn || user?.isSchoolUser || user?.isTrial || user?.isLibraryUser);
  const shouldHideUpgradeToPremiumButton = props.hideUpgradeToPremiumButton || (user?.isPremium || user?.isWalkIn);
  const shouldHideUpgradeToSchoolLicenceButton = props.hideUpgradeToSchoolLicenceButton
    || (!canUserUpgradeToSchoolLicence || !hasUserSubscriptionForOver30Days);

  const {
    variant: lernsetVariant,
    isReady: isClientReady,
  } = useAmplitudeExperiment(user, config.amplitudeExperiments.enableLearnsetsFeature);
  const isLernsetsActive = isClientReady && lernsetVariant === 'on';
  const isUpgradeCTAsExist = !shouldHideUpgradeToPremiumButton || !shouldHideUpgradeToSchoolLicenceButton;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobile'));
  const showLernsetsCTA = isLernsetsActive && !props.hideMenu && user?.isPremium && !isMobile;
  const { match: { location: { pathname } } } = useRouter();
  const isReaderRoute = pathname && pathname.includes(`/${routes.reader.root}/`);
  const welcomeBannerId = onboardingHints.welcomeBanner;
  const isWelcomeBannerShown = localStorage.getItem(`onboarding-${welcomeBannerId}`);
  const welcomeBannerShownDate = localStorage.getItem(`onboarding-${welcomeBannerId}-date`);
  
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
  const isShownWelcomeBanner = 
    isWelcomeBannerShown === 'true' && 
    welcomeBannerShownDate &&
    new Date().getTime() - Number(welcomeBannerShownDate) > oneDayInMilliseconds;
  
  useEffect(() => {
    if (user && subscription) {
      const today = new Date();
      const subscriptionDatePlus30Days = dateAdd(new Date(subscription.originalStartDate), { days: 30 });
      setHasUserSubscriptionForOver30Days(subscription.status === 'Active' && today > subscriptionDatePlus30Days);
    }
  }, [user, subscription]);

  const trackAmplitudeEvent = () => {
    if (user && ampliConversionPath.NavigationBar) {
      services.track.ampliTrackPricingPlansViewedEvent(
        ampliConversionPath.NavigationBar,
        subscription?.status,
      );
    }
  };

  const lernsetsTopBarCTA = () => {
    return <div className='top-bar__icon-container'>
      <TopBarIconLink
        link={`/${routes.learnsetsDashboard.root}`}
        tooltip={'Lernsets'}
      >
        <span className='top-bar__icon top-bar__premium-link'>
          {'Lernsets'}
          <span
            data-testid='top-bar__lernsets'
            className='top-bar__premium-pill'
          >
            {'BETA'}
          </span>
        </span>
      </TopBarIconLink>
    </div>;
  };

  const onBoardingLernsetsHint = () => {
    if (!isReaderRoute && isShownWelcomeBanner) {
      return (
        <OnboardingHint
          id={onboardingHints.lernsetsTopBarCTA}
          placement='bottom'
          description={t('With Lernsets you can easily adapt and combine materials.')}
          maxWidth={317}
          disablePortal={true}
        >
          {lernsetsTopBarCTA()}
        </OnboardingHint>
      );
    }

    return lernsetsTopBarCTA(); 
  };

  return (
    <Navbar className={
      classnames(
        'top-bar__container',
        { 'top-bar__container--isPublicPage': props.isPublicPage }
      )
    } 
    data-testid='top-bar__container' 
    sticky='top'
    >
      {user && <TopBarMobileMenu hideWalkInItems={user.isWalkIn} />}
      {!props.hideHome &&
        <TopBarIconLink
          link={user?.isWalkIn ? `/${routes.search.root}` : `/${routes.dashboard.root}`}
          showOnMobile={!user}
          tooltip={t('Go to dashboard')}
        >
          <Icon icon='home' size='lg' className='top-bar__icon' />
        </TopBarIconLink>
      }
      {
        user &&
        <>
          <TopBarHistoryNavigation className='ml-2 d-none d-md-flex' />
          <Nav navbar={true} data-testid='top-bar__nav' className='align-items-center flex-grow-1 justify-content-end'>
            {
              props.hideSearch
                ? null
                : <SearchBarTop className='mr-md-4' />
            }
            { 
              showLernsetsCTA && (
                <>
                  {onBoardingLernsetsHint()}
                  {isUpgradeCTAsExist && (
                    <Nav.Item 
                      className='top-bar__icon top-bar__icon-container d-none d-md-flex' 
                      data-testid='top-bar__separator'>
                      |
                    </Nav.Item>
                  )}
                </>
              )
            }
            {
              shouldHideUpgradeToPremiumButton
                ? null
                :
                <TopBarIconLink
                  link={`/${routes.upgradePackageSelection.root}`}
                  tooltip={t('Unlock Premium')}
                >
                  <span className='top-bar__icon top-bar__premium-link' onClick={trackAmplitudeEvent}>
                    {t('meinUnterricht')}
                    <span
                      data-testid='top-bar__premium'
                      className='top-bar__premium-pill'
                    >
                      {t('PREMIUM')}
                    </span>
                  </span>
                </TopBarIconLink>
            }
            {
              shouldHideUpgradeToSchoolLicenceButton
                ? null
                :
                <TopBarIconLink
                  link={`/${routes.upgradeToSchoolLicence.root}`}
                  tooltip={t('School license information')}
                >
                  <span className='top-bar__icon top-bar__premium-link'>
                    {t('meinUnterricht')}
                    <span
                      data-testid='top-bar__school-licence'
                      className='top-bar__premium-pill'
                    >
                      {t('SCHOOLS')}
                    </span>
                  </span>
                </TopBarIconLink>
            }
            {
              props.hideOpenCollections
                ? null
                :
                <TopBarIconLink
                  hideLink={user.isWalkIn}
                  link={`/${routes.collections.root}`}
                  tooltip={t('Open Collections')}
                >
                  <Icon icon={'collection'} size='lg' className='top-bar__icon' />
                </TopBarIconLink>

            }
            {
              props.hidePinnedDocuments
                ? null
                :
                <TopBarIconLink
                  hideLink={user.isWalkIn}
                  link={`/${routes.pinned.root}`}
                  tooltip={t('Open Pinned Documents')}
                >
                  <Icon icon={'star-outline'} size='lg' className='top-bar__icon' />
                </TopBarIconLink>
            }
            {
              props.hideMenu
                ? null
                :
                <div className='top-bar__icon-container d-none d-md-flex' data-testid='user-settings-dropdown-button'>
                  <TopBarMenu hideWalkInItems={user.isWalkIn} />
                </div>
            }
          </Nav>
        </>
      }
    </Navbar>
  );
};

interface TopBarIconLinkProps {
  hideLink?: boolean;
  showOnMobile?: boolean;
  link: string;
  tooltip: string;
}

const TopBarIconLink: React.FC<TopBarIconLinkProps> = (props) => {
  if (props.hideLink) return null;
  return (
    <Nav.Item className={classnames('top-bar__icon-container', { 'd-none d-md-flex': !props.showOnMobile })}>
      <WithTooltip text={props.tooltip} placement='bottom'>
        <Link
          className='top-bar__icon__link'
          to={props.link}>
          {props.children}
        </Link>
      </WithTooltip>
    </Nav.Item>
  );
};

export default TopBar;
