import React, { useCallback, useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PasswordInput from '../../common/Input/PasswordInput';
import PersonalDetailsActions from '../Common/PersonalDetailsActions';
import PersonalDetailsInputGroup from '../PersonalDetailsInputGroup';
import * as yup from 'yup';
import { password } from '../../../validators';
import services from '../../../services';

interface PasswordDataFormProps {
  close: () => void;
  onSuccess: () => void;
}

interface PasswordFormFields {
  currentPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
}

const formValidator = yup.object<PasswordFormFields>({
  currentPassword: password({ requiredMessage: 'Please enter your current password.' }),
  newPassword: password({ requiredMessage: 'Please enter a new password.' }),
  newPasswordRepeat: password()
    .oneOf([yup.ref('newPassword')], ('Passwords must match')),
});

const PasswordDataForm: React.FC<PasswordDataFormProps> = (props) => {
  const [serverError, setServerError] = useState<Error | null>(null);
  const { errors, handleSubmit, register, setError } = useForm<PasswordFormFields>({
    mode: 'onBlur',
    validationSchema: formValidator,
  });
  const { t } = useTranslation();

  const getFieldError = (fieldName: keyof PasswordFormFields) => {
    if (!errors[fieldName]) return;
    const err = errors[fieldName] as FieldError;
    const errMsg = err.message?.toString();
    return t(errMsg ? errMsg : '');
  };

  interface ServerErrorMessage {
    field: keyof PasswordFormFields;
    message: string;
  }
  const getServerErrorMessage = (errorMessage: string): ServerErrorMessage | undefined => {
    if (errorMessage.match(/Invalid username or password/)) {
      return {
        field: 'currentPassword',
        message: 'The password you entered is incorrect.'
      };
    }

    return;
  };

  const onSubmit = handleSubmit(useCallback((formData: PasswordFormFields) => {
    services.user.changePassword(
      formData.currentPassword,
      formData.newPassword,
      formData.newPasswordRepeat
    )
      .then(() => {
        props.onSuccess();
        props.close();
      })
      .catch((error) => {
        const serverErrorMessage = getServerErrorMessage(error.message);
        if (serverErrorMessage) {
          return setError(serverErrorMessage.field, 'manual', t(serverErrorMessage.message));
        }

        setServerError(error);
      });
  }, []));

  return (
    <div>
      <form onSubmit={onSubmit}>
        <PersonalDetailsInputGroup>
          <PasswordInput
            error={getFieldError('currentPassword')}
            ref={register}
            label={t('Current Password')}
            name='currentPassword'
            required={true}
            autoComplete='current-password'
          />
          <PasswordInput
            error={getFieldError('newPassword')}
            ref={register}
            label={t('New Password')}
            name='newPassword'
            required={true}
            autoComplete='new-password'
          />
          <PasswordInput
            error={getFieldError('newPasswordRepeat')}
            ref={register}
            label={t('Confirm New Password')}
            name='newPasswordRepeat'
            required={true}
            autoComplete='new-password'
          />
        </PersonalDetailsInputGroup>
        <PersonalDetailsActions
          onCancel={props.close}
          showError={!!serverError}
        />
      </form>
    </div>
  );
};

export default PasswordDataForm;
