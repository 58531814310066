import React, { useState, createContext } from 'react';
import SearchFiltersDialog from '../components/modals/SearchFiltersDialog';

type ShowFunc = () => void;
const noop = () => undefined;

interface Context {
  isOpen: boolean;
  openSearchFiltersDialog: ShowFunc;
}

const defaultContext: Context = {
  isOpen: false,
  openSearchFiltersDialog: noop as ShowFunc,
};

const SearchFiltersDialogContext = createContext(defaultContext);

const SearchFiltersDialogProvider: React.FC = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <SearchFiltersDialogContext.Provider value={{
      openSearchFiltersDialog: () => setIsOpen(true),
      isOpen,
    }}>
      <SearchFiltersDialog isOpen={isOpen} handleClose={() => setIsOpen(false) } />
      {props.children}
    </SearchFiltersDialogContext.Provider>
  );
};

export { SearchFiltersDialogContext, SearchFiltersDialogProvider };
