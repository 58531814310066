import React from 'react';
import PersonalData from '../../../components/PersonalDetails/PersonalData/PersonalData';
import NewsletterPreference from '../../../components/PersonalDetails/NewsletterPreference';
import TeachingProfile from '../../../components/PersonalDetails/TeachingProfile';

const PersonalDetailsProfileSection: React.FC = () => {
  return (
    <>
      <PersonalData />
      <TeachingProfile />
      <NewsletterPreference />
    </>
  );
};

export default PersonalDetailsProfileSection;
