/**
 * returns a string array of classyears
 * @param classYears - an array made up of numbers
 * @returns stringArray - an array made up of strings
 */
export function compressClassYearArray (classYears: number[]) {
  if (!classYears || classYears.length === 0) {
    return [];
  }
  const sortedClassYears = [...new Set(classYears)].sort((a,b) => a>b?1:-1);
  const stringArray: string[] = [];
  let currentClassYears: number[] = [];
  let lastYear: number;

  const compressContinuousArray = (array: number[]) => {
    if (array.length > 1) {
      return `${array[0]}.-${array[array.length-1]}.`;
    }
    return `${array[0]}.`;
  };

  sortedClassYears.forEach((year) => {
    if (lastYear && lastYear != year - 1) {
      stringArray.push(compressContinuousArray(currentClassYears));
      currentClassYears = [];
    }
    currentClassYears.push(year);
    lastYear = year;
  });
  if (currentClassYears) {
    stringArray.push(compressContinuousArray(currentClassYears));
  }
  return stringArray;
}
