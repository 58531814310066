import Box from '@mui/material/Box';
import React, { useContext, useState, useRef } from 'react';
import { borderRadius, cardDimensions, palette, spaces } from '../../../../assets/styles/themes/tokens';
import {
  ClickAwayListener, Grow, IconButton,IconButtonProps, MenuItem, MenuList, Paper, Popper, SxProps, Theme, useMediaQuery
} from '@mui/material';
import MuiCardBaseShort from './MuiCardBaseShort';
import MuiCardBaseLong from './MuiCardBaseLong';
import getMuiIcon, { IconName } from '../../../../utils/getMuiIcon';
import usePinAction from '../../../../utils/usePinAction';
import { useTranslation } from 'react-i18next';
import { ManageItemInCollectionModalContext } from '../../../../state/ManageItemInCollectionModalProvider';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../reducers/user.selectors';
import { getAmpliContentEventPayload } from '../../../../utils/ampli';
import services from '../../../../services';
import { CollectionContentSelected, ContentFavoriteAdded, ContentFavoriteRemoved } from '../../../../ampli';
import { MindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import { trackingEvents } from '../../../../services/tracking/trackConfig';
import * as ampliUtils from '../../../../utils/ampli';
import { getDateInGermanFormat } from '../../../../utils/format';
import { Product } from '@klab-berlin/api-sdk/lib/types/responses/Product';
import { selectIsItemInOwnCollections } from '../../../../reducers/collections.selectors';
import { colors } from '../../../../assets/styles/themes/variables';
import { AmpliContentPayload, UIProperties } from '../../../../constants';
import getTextTruncationSx from '../../../../utils/getTextTruncationSx';

export interface MuiCardCommonProps {
  id: string;
  index?: number;
  isReadOnly: boolean;
  title: string;
  cover: string;
  description: string;
  publisher: string;
  date?: string;
  subjects?: string[];
  classYears?: number[];
  isPinned: boolean;
  isNew: boolean;
  itemData: Partial<MindItem> | Partial<Product>;
}

export interface MuiCardBaseProps extends MuiCardCommonProps {
  contentType: 'video' | 'document' | 'product';
  link?: {
    to: string;
    label: string;
    icon: IconName;
    hasTooltip: boolean;
  };
  linkFunction?: () => void;
  chipText?: string;
}

export const mapContentTypeName = (contentType: MuiCardBaseProps['contentType']) => {
  switch (contentType) {
    case 'document':
      return 'Unit';
    case 'product':
      return 'Product';
  }

  return contentType;
};

export const MuiCardBase: React.FC<MuiCardBaseProps> = (props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobile'));
  const component = isMobile ? MuiCardBaseShort : MuiCardBaseLong;

  const passedProps = {
    ...props,
    date: props.date ? getDateInGermanFormat(props.date) : undefined,
  };

  return React.createElement(component, passedProps);
};

export const Metadata: React.FC<{
  sx?: SxProps<Theme>;
  icon: IconName;
  label: string;
  onClick?: (e: React.MouseEvent) => void;
  [key: string]: any;
}> = ({ icon, label, sx, onClick }) => {
  return <Box
    typography='caption'
    sx={{
      ...getTextTruncationSx(),
      wordBreak: 'break-all',
      color: palette.text.secondary,
      ...sx,
    }}
    data-testid='metadata'
    onClick={onClick}
  >
    <Box
      component={getMuiIcon(icon)}
      sx={{
        width: 16,
        height: 16,
        marginRight: spaces.xs,
      }}
      data-testid='metadata__icon'
    />
    {label}
  </Box>;
};

export const AddMenu: React.FC<{
  index?: MuiCardBaseProps['index'],
  isPinned: MuiCardBaseProps['isPinned'],
  type: MuiCardBaseProps['contentType'],
  id: MuiCardBaseProps['id'],
  itemData: Partial<MindItem> | Partial<Product>,
  buttonSx?: SxProps<Theme>;
}> = ({ isPinned, type, id, itemData, buttonSx }) => {
  const { t } = useTranslation();
  const container = useRef<HTMLButtonElement>(null);
  const [isOpen, setOpen] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const user = useSelector(selectUser);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobile'));
  const isInCollection = useSelector(state => selectIsItemInOwnCollections(state, id));

  const variation = type === 'product' ? 'product' : 'mindItem';
  const pinAction = usePinAction(id, isPinned, variation);
  const pinIcon = isPinned ? 'starFilledPurple' : 'starPurple';
  const pinText = isPinned ? t('Unpin') : t('Pin');
  const pinTestId = isPinned ? 'new-unpin-button' : 'new-pin-button';

  const collectionIcon = isInCollection ? 'folderRemovePurple' : 'folderAddPurple';
  const collectionText = t('Add to collection');

  const {
    show: showManageItemInCollectionsModal,
  } = useContext(ManageItemInCollectionModalContext);

  const openMenu = () => {
    setOpen(true);
    setIsClicked(true);
  };

  const closeMenu = () => {
    setOpen(false);
    setIsClicked(false);
  };

  const getMenuItem = (icon: IconName, label: string, testId: string, onClick: () => void) => {
    return <MenuItem
      data-testid={testId}
      onClick={onClick}
    >
      <Box
        data-testid='add-menu__item__icon'
        component={getMuiIcon(icon)}
      />
      {label}
    </MenuItem>;
  };

  const processPinAction = () => {
    pinAction();
    closeMenu();

    let event, eventPayload, eventProperties: (AmpliContentPayload & UIProperties) | undefined;
    if (variation === 'mindItem') {
      event = trackingEvents.mindItemPin;
      eventPayload = { 'minditem_id': itemData.id };
      eventProperties = itemData && ampliUtils.getAmpliContentEventPayload(itemData, 'mindItem');
    } else if (variation === 'product') {
      event = trackingEvents.productPin;
      eventPayload = { 'product_id': itemData.id };
      eventProperties = itemData && ampliUtils.getAmpliContentEventPayload(itemData, 'product');
    }

    if (event) {
      services.track.eventTrack(event, { other: { ...eventPayload, isPinned: !isPinned } });
    }

    const ampliAction = !isPinned ? ContentFavoriteAdded : ContentFavoriteRemoved;
    if (user && eventProperties) {
      if (!isPinned) {
        eventProperties.ui = 'new';
      }
      services.track.ampliEventTrack({
        event: new ampliAction(eventProperties as any)
      });
    }
  };

  const processCollectionClick = () => {
    closeMenu();
    showManageItemInCollectionsModal(id, variation, itemData);

    if (user && itemData) {
      const eventProperties = getAmpliContentEventPayload(itemData, variation);
      services.track.ampliEventTrack({
        event: new CollectionContentSelected(eventProperties as any)
      });
    }
  };

  const iconButtonProperties: IconButtonProps = {
    className: `add-menu-button ${isClicked ? 'active' : ''}`,
    color: 'secondary',
    size: isMobile ? 'medium' : 'large',
    ref: container,
    sx: {
      position: 'absolute',
      '&.active': {
        backgroundColor: colors.gray50,
        borderRadius: spaces.xs,
      },
      top: isMobile ? `-${spaces.xs}` : 'unset',
      right: isMobile ? `-${spaces.xs}` : `-${spaces.sm}`,
      color: palette.text.secondary,
      ...buttonSx,
    },
    'aria-haspopup': 'true',
    'aria-expanded': isOpen ? 'true' : undefined,
    onClick: () => {
      if (!isOpen) {
        openMenu();
      }
    },  
    //@ts-ignore
    'data-testid': 'add-menu-button',
  };

  const iconProperties = {
    className: 'add-menu-button__icon',
    component: isPinned || isInCollection
      ? getMuiIcon('bookmarkAdded')
      : getMuiIcon('bookmark')
  };

  const bookmarkIconButton = <IconButton {...iconButtonProperties}>
    <Box {...iconProperties} sx={{ cursor: 'pointer' }} />
  </IconButton>;

  return <>
    { bookmarkIconButton }
    <Popper
      // TODO FD-3300: add partial data here for tracking on click
      open={isOpen}
      anchorEl={container.current}
      data-testid='add-menu'
      placement='bottom-end'
      transition
      sx={{
        zIndex: '1 !important',
      }}
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
        >
          <Paper
            elevation={4}
            sx={{
              marginTop: spaces.xxs,
              borderRadius: spaces.md,
              transformOrigin: 'top right',
              overflow: 'hidden',
            }}
          >
            <ClickAwayListener onClickAway={closeMenu}>
              <MenuList
                autoFocusItem={isOpen}
                sx={{
                  padding: spaces.xs,
                }}
              >
                {getMenuItem(pinIcon, pinText, pinTestId, processPinAction)}
                {getMenuItem(collectionIcon, collectionText, 'new-add-to-collection-button', processCollectionClick)}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  </>;
};

export const CoverImage: React.FC<{
  cover: MuiCardBaseProps['cover'];
  className?: string;
  dataTestId?: string;
  customSx?: SxProps<Theme>;
}> = ({ cover, className, dataTestId, customSx }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobile'));
  return <Box
    component='img'
    className={className}
    data-testid={dataTestId || 'cover-image'}
    src={cover}
    sx={{
      height: isMobile ? cardDimensions.short.height : cardDimensions.long.height,
      width: '100%',
      borderRadius: borderRadius.sm,
      objectFit: 'cover',
      border: `${isMobile ? '0.476px' : '1px'} solid ${palette.elevation.outlined}`,
      ':hover': {},
      ...customSx,
    }}
  />;
};

export default MuiCardBase;
