import React, { useState } from 'react';
import Icon from '../../../Icon';
import './PersonalDetailsSection.scss';

interface OwnProps {
  title: string;
  renderNormalView: () => React.ReactNode;
  renderFormView?: (closeEditMode: () => void) => React.ReactNode;
  isEditDisabled?: boolean;
  onEditClick?: (isEditMode: boolean) => void;
}

const PersonalDetailsSection: React.FC<OwnProps> = (props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const hasEditMode = !!props.renderFormView;

  const closeEditMode = () => setIsEditMode(false);

  return (
    <div className='personal-details-section'>
      <div className='personal-details-section__header'>
        <h2 className='personal-details-section__title'>{props.title}</h2>
        {hasEditMode && !props.isEditDisabled && <button 
          className='personal-details-section__toggle-button'
          aria-label='toggle-edit'
          onClick={() => {
            props.onEditClick?.(isEditMode);
            setIsEditMode(!isEditMode);
          }}>
          {isEditMode ? <Icon icon='cancel' size='lg' /> : <Icon icon='edit' size='lg' />}
        </button>}
      </div>
      <div className='personal-details-section__body'>
        {!isEditMode
          ? props.renderNormalView()
          : props.renderFormView && props.renderFormView(closeEditMode)
        }
      </div>
    </div>
  );
};

export default PersonalDetailsSection;
