import React from 'react';
import { useTranslation } from 'react-i18next';
import { 
  Button, 
  ButtonOwnProps, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle 
} from '@mui/material';
import { spaces } from '../../../assets/styles/themes/tokens';

interface OwnProps {
    title: string;
    message: string;
    isOpen: boolean;
    confirmButtonColor?: ButtonOwnProps['color'];
    onCloseModal: () => void;
    onConfirm: () => void;
    confirmButtonText?: string;
}

const ConfirmDialog: React.FC<OwnProps> = (props) => {
  const { t } = useTranslation();
  const { 
    title, 
    message, 
    isOpen, 
    onCloseModal, 
    onConfirm, 
    confirmButtonText = 'Confirm', 
    confirmButtonColor = 'primary' 
  } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={onCloseModal}
      sx={{ 
        '& .MuiDialog-paper': {
          width: '100%',
        }
      }}
    >
      <DialogTitle sx={{ typography: 'h5' }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ typography: 'subtitle1' }}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: spaces.lg }}>
        <Button 
          onClick={onCloseModal}
          variant='text'
          color='secondary'
          size='large'
        >
          {t('Cancel')}
        </Button>
        <Button 
          onClick={onConfirm}
          variant='contained'
          color={confirmButtonColor}
          size='large'
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
