import React from 'react';
import { routes } from '../../../../constants';
import MuiCardBase, { MuiCardCommonProps } from '../MuiCardBase/MuiCardBase';

export interface VideoMuiCardProps extends MuiCardCommonProps {
  product?: {
    name: string;
    id: string;
  }
}

const VideoMuiCard: React.FC<VideoMuiCardProps> = (props) => {
  return <MuiCardBase
    {...props}
    contentType='video'
    link={props.product? {
      to: `/${routes.productPage.root}/${props.product.id}`,
      label: props.product.name,
      icon: 'book',
      hasTooltip: true,
    } : undefined}
  />;
};

export default VideoMuiCard;
