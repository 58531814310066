import { createSelector } from 'reselect';
import { StoreType } from '.';
import { User } from '../types/User';
import { userType, licenceCategory } from '../constants';
import { flattenUserSubjects } from '../utils';
import config from 'config';

const getMe = (state: StoreType) => state.user.me;

const getInvoiceHistory = (state: StoreType) => state.user.invoiceHistory;

export const getSubscription = (state: StoreType) => state.user.subscription;

const getPaymentMethod = (state: StoreType) => state.user.paymentMethod;

export const getExperiments = (state: StoreType) => state.user.experiments;

export const getSubscriptionStatus = (state: StoreType) => state.user.subscriptionStatus;

export const selectUser = createSelector(
  [getMe, getSubscription, getPaymentMethod, getExperiments],
  (me, subscription, paymentMethod, experiments): User | undefined => {
    if (!me) return;

    const subjectsFlattened = flattenUserSubjects(me.subjects);
    // @ts-ignore (experiments.*.data is a very loosely defined object, i.e. suspension is not part of the definition)
    const pauseExperimentStatus = experiments && experiments['GRO-6']?.data?.suspension?.status;
    const allowAutomaticCancellation = subscription?.cancellation?.canCancel
      && !['requested', 'granted'].includes(pauseExperimentStatus);
    
    const isMUMember = !(me.mu?.isWalkIn) &&
      (me.email?.includes('@meinunterricht.') || me.email?.includes('@klab-berlin.'));

    return {
      id: me._id,
      email: me.email,
      firstName: me.firstName,
      lastName: me.lastName,
      classes: me.classes,
      schoolType: me.schoolType,
      subjects: me.subjects,
      subjectsFlattened,
      isAdmin: me.rights?.admin,
      isCoreMember: isMUMember && !!(me.mu?.isCoreMember),
      isMUMember,
      isSubscribedToNewsletter: me.marketing?.mailings?.newsletter?.doubleOptIn === 'confirmed',
      isFree: (subscription?.licence?.priceTotal || 0) <= 0,
      isReadOnlyLicence: !!subscription?.licence?.readonlyMembership,
      isTrainee: me.isTrainee,
      isTrial: me.type === userType.basis ? false : subscription && subscription.licence?.hasTrial,
      isInTrial: me.type === userType.basis ? false : subscription?.trial?.isInTrial,
      isParent: me.isParent,
      isPremium: me.type === userType.premium,
      isWalkIn: (me.mu?.isWalkIn) || false,
      hidePinAction: (me.mu?.isWalkIn) || false,
      hideCollectionAction: (me.mu?.isWalkIn) || false,
      hideDownloadAction: (me.mu?.isWalkIn) || false,
      isLuxSSO: me.isLuxSSO,
      isSchoolUser: me.isSchoolUser,
      isMigratedSchoolUser: me.isMigratedSchoolUser,
      isLibraryUser: subscription?.licence?.category === licenceCategory.LIBRARY,
      choseToStayBasis: me.choseToStayBasis,
      redirectTo: me.registration && me.registration.redirectTo && me.registration.redirectTo.docId,
      downloads: {
        counter: me.downloads?.counter,
        max: me.downloads?.max,
      },
      address: {
        areaCode: me.address?.areaCode,
        city: me.address?.city,
        country: me.address?.country,
        state: me.address?.state,
        street: me.address?.street,
      },
      gender: me.gender,
      birthday: me.birthday,
      invoiceAddress: {
        gender: me.invoiceAddress?.gender,
        firstName: me.invoiceAddress?.firstName,
        lastName: me.invoiceAddress?.lastName,
        areaCode: me.invoiceAddress?.areaCode,
        city: me.invoiceAddress?.city,
        country: me.invoiceAddress?.country,
        street: me.invoiceAddress?.street,
        state: me.invoiceAddress?.state,
        institution: me.invoiceAddress?.institution,
      },
      licenceId: me.payment?.licencemodelId,
      paymentMethod: paymentMethod,
      onboardingAttempts: me.data?.onboarding?.attempts || 0,
      createdAt: me.created?.timestamp,
      allowAutomaticCancellation,
      pauseExperimentStatus,
      type: me.type,
      signUpMethod: me.registration?.method,
    };
  },
);

export const selectIsOnboardingCompleted = createSelector([getMe], (me) => {
  if (!me) {
    return true;
  }

  const onboarding = me?.data?.onboarding || {};
  const onboardingAttempts = onboarding.attempts || 0;
  const isOnboardingActive = onboardingAttempts < config.onboarding.maxAttempts;
  const isOnboardingCompleted = onboarding.finished;

  return !isOnboardingActive || isOnboardingCompleted;
});

export const selectDownloadCount = (state: StoreType) => state.user.me && state.user.me.downloads.counter;

export const selectUserInvoiceHistory = createSelector([getInvoiceHistory], (invoiceHistory) => {
  if (!invoiceHistory) return;
  return invoiceHistory;
});

export const selectIsAuthenticated = (state: StoreType) => state.user.isAuthenticated;
