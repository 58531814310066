import React from 'react';
import FacebookLogin, { ReactFacebookLoginInfo } from 'react-facebook-login';
import config from 'config';
import { errorCodes } from '../../constants';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import FacebookIcon from '../../assets/FacebookIcon.svg';
import { loginFB } from '../../actions/auth.actions';
import { connect } from 'react-redux';

interface DispatchProps {
  loginFB: typeof loginFB;
}

export interface FacebookLoginButtonOwnProps {
  onLoginSuccess: () => void;
  onLoginFailure: (status: number, code?: number) => void;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  btnText?: string;
}

type FacebookLoginButtonProps = DispatchProps & FacebookLoginButtonOwnProps;

const FacebookLoginButton: React.FC<FacebookLoginButtonProps> = (props) => {
  const { t } = useTranslation();
  const handleResponse = React.useCallback(async (response: ReactFacebookLoginInfo) => {
    if (!response.email) {
      props.onLoginFailure(-1, errorCodes.fbEmailDoesntExist);
      return;
    }
    try {
      await props.loginFB(response.id, response.accessToken, {
        formCode: 'basis_app_facebook',
        lastUrl: document.referrer,
      });

      props.onLoginSuccess();
    } catch (err: any) {
      err.data = err.data || {};
      props.onLoginFailure(err.statusCode, err.data.code);
    }
  }, [props.onLoginSuccess, props.onLoginFailure]);

  const handleFailure = React.useCallback(
    (r) => props.onLoginFailure(parseInt(r.status || '0')),
    [props.onLoginFailure]
  );

  return (
    <FacebookLogin
      isMobile={false} // https://github.com/keppelen/react-facebook-login/issues/257
      appId={config.facebookAuth.appId}
      callback={handleResponse}
      onFailure={handleFailure}
      fields={config.facebookAuth.scope}
      version={config.facebookAuth.version}
      textButton={props.btnText ? t(props.btnText) : t('Login with Facebook')}
      icon={<FacebookIcon className='fill--white login-button__icon facebook-icon' />}
      cssClass='btn login-sso-button facebook'
      onClick={props.onClick}
    />
  );
};

const mapDispatch = {
  loginFB,
};

export default compose<React.FC<FacebookLoginButtonOwnProps>>(
  connect(undefined, mapDispatch),
)(FacebookLoginButton);
