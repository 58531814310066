import Icon from '../../Icon';
import React from 'react';
import './carouselButton.scss';

interface CarouselButtonProps {
  onClick?: () => void;
  direction: 'left' | 'right';
}

function CarouselButton(props: CarouselButtonProps) {
  const { direction, onClick } = props;

  return (
    <div className='carousel__nav-button-container'>
      <div
        className={'carousel__nav-button'}
        onClick={onClick}
      >
        <Icon
          icon={direction === 'left' ? 'arrow-small-left' : 'arrow-small-right'}
          size='xl'
        />
      </div>
    </div>
  );
}

export default CarouselButton;
