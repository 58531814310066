import { Box, Button } from '@mui/material';
import React from 'react';
import WithSearchFilterNumberBadge from './SearchFilterButtonNumberBadge';

type SearchFilterButtonProps = {
  text: string;
  onClick?: () => void;
  numberOfSelections?: number;
};

const SearchFilterButton: React.FC<SearchFilterButtonProps> = ({ text, onClick, numberOfSelections }) => {
  const button = <Button
    variant='outlined'
    size='large'
    color='inherit'
    onClick={onClick}
    sx={{ width: '100%' }}
  >
    {text}
  </Button>;

  return <Box
    sx={{
      flex: 1,
    }}
  >
    { <WithSearchFilterNumberBadge number={numberOfSelections}>
      {button}
    </WithSearchFilterNumberBadge> }
  </Box>;
};

export default SearchFilterButton;
