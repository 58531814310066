import classNames from 'classnames';
import React from 'react';
import BackgroundImage from '../../../BackgroundImage';
import { BackgroundImageMode } from '../../../BackgroundImage/BackgroundImage';
import './cardThumbnail.scss';

interface OwnProps {
  thumbnailUrl: string;
  className?: string;
  imageMode?: BackgroundImageMode;
}

const CardThumbnail: React.FC<OwnProps> = (props) => (
  <BackgroundImage
    className={classNames('item-card__card-thumbnail', props.className)}
    url={props.thumbnailUrl}
    testId='card-thumbnail'
    mode={props.imageMode}
  />
);

export default CardThumbnail;
