import { createSelector } from 'reselect';
import { StoreType } from './index';
import { getMindItems } from './mindItems.selectors';
import { getProducts } from './products.selectors';
import { getContent } from './selectorHelpers';
import { ContentItem } from '../types/AppContent';
import { RecommendationContentArray } from './recommendation.reducers';
import { AsyncActionStatus } from '../actions/common.actionTypes';

const getRecommendedUserProducts = (state: StoreType): RecommendationContentArray =>
  state.recommendation.user;

export const selectRecommendedProductsStatus = (state: StoreType): AsyncActionStatus => state.recommendation.status;

export const selectRecommendedUserProducts = createSelector(
  [getRecommendedUserProducts, getMindItems, getProducts],
  (recommendation, mindItems, products) =>
    recommendation
      .map((i) => getContent('product', i, mindItems, products))
      .filter((item) => !!item) as ContentItem[]
);
