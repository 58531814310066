import React from 'react';
import { Box, Divider, SvgIcon } from '@mui/material';
import { borderRadius, boxShadows, palette, spaces } from '../../../assets/styles/themes/tokens';
import LoadingButton from '../LoadingButton';
import add from '../../../assets/lernsets/icons/add.svg';

interface OwnProps {
    label: string;
    positionedAfterPartIndex: number;
    onCreateBlankPageClick: (index: number) => void;
    isDisabled: boolean;
}

const AddMaterialBetweenParts: React.FC<OwnProps> = (props) => {
  const { label, positionedAfterPartIndex, isDisabled, onCreateBlankPageClick } = props;

  const divider = <Divider sx={{ 
    flex: '1 0 0',
  }} />;
  
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      padding: `0px ${spaces.lg}`,
      alignItems: 'center',
      gap: spaces.md,
      height: spaces.xl,
      '&:hover > *': {
        opacity: 1,
        transitionDelay: '0.2s !important',
      },
      '& > *': {
        opacity: 0,
        transition: 'opacity 0.3s ease !important',
      },
    }}>
      {divider}
      <LoadingButton 
        dataTestId='add-blank-page-button-between-parts'
        color='inherit'
        variant='outlined'
        label={label}
        onClick={() => onCreateBlankPageClick(positionedAfterPartIndex)}
        isDisabled={isDisabled}
        isLoading={isDisabled}
        SvgIcon={() => <SvgIcon component={add} sx={{ stroke: palette.text.primary }}/>}
        styles={{
          backgroundColor: palette.secondary.contrastText,
          border: 'transparent',
          borderRadius: borderRadius.sm,
          boxShadow: boxShadows.elevation4,
          padding: `${spaces.xs} ${spaces.lg}`,
          zIndex: 1,
          '&:hover': {
            backgroundColor: palette.common.white,
          },
        }}
      />
      {divider}
    </Box>
  );
};

export default AddMaterialBetweenParts;
