exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".range-slider-thumb{width:16px;height:16px}@media only screen and (max-width: 575px){.range-slider-thumb{width:32px;height:32px}}.range-slider-track{width:calc(100% - 16px);height:4px}@media only screen and (max-width: 575px){.range-slider-track{width:calc(100% - 32px);height:8px}}", ""]);
// Exports
exports.locals = {
	"collectionGridGap": "15px",
	"collectionGridItemHeight": "211px",
	"collectionGridItemWidth": "225px",
	"collectionListHeight": "84px",
	"greyLightest": "#f7f9fa",
	"greyLight2": "#d8d8d8",
	"neuVeryLightBlue": "#e9ebec",
	"muBlue": "#4298b5",
	"gridBreakPoints_-xs": "0",
	"gridBreakPoints_-sm": "576px",
	"gridBreakPoints_-md": "768px",
	"gridBreakPoints_-lg": "992px",
	"gridBreakPoints_-xl": "1440px"
};