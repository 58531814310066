import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSubscription, selectUser } from '../../../reducers/user.selectors';
import ScreenStepContainer, { ScreenStepProps } from '../ScreenModal/ScreenStepContainer';
import '../ScreenModal/ScreenStepContainer.scss';
import { format as formatDate, addMonths, parseISO, toDate } from 'date-fns';
import { externalLinks, emailAddresses, suspensionOptions, dateFormat } from '../../../constants';
import useAction from '../../../utils/useAction';
import { updateExperiment as updateExperimentAction } from '../../../actions/user.actions';
import services from '../../../services';
import { SubscriptionResponse } from '@klab-berlin/api-sdk/lib/types/responses/Billing';
import Radio from '../../common/Radio/Radio';
import { getDateInGermanFormat } from '../../../utils/format';
import { ampliTrackSubscriptionPauseSubmittedEvent } from '../../../services/track.service';

const CancellationStepPauseSelection: React.FC<ScreenStepProps> = (props) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const subscription = useSelector(getSubscription);

  const updateExperiment = useAction(updateExperimentAction);
  const [pauseSelection, setPauseSelection] = useState<number | undefined>(undefined);
  const [suspensionEndDate, setSuspensionEndDate] = useState<Date| undefined>();

  useEffect(() => {
    if (!subscription || !pauseSelection) return;

    const startDate = getSuspensionStartDate(subscription);
    setSuspensionEndDate(getSuspensionEndDate(startDate, pauseSelection));
  },[pauseSelection, subscription]);

  const getSuspensionEndDate = (suspensionStartDate: Date, pauseSelection: number) => {
    return addMonths(toDate(suspensionStartDate), pauseSelection);
  };

  const getSuspensionStartDate = (subscription: SubscriptionResponse) => {
    const nextChargeDate = subscription.paymentPeriod.nextChargeDate;

    if (subscription.paymentPeriod.type === 'fullAmount' || !nextChargeDate) {
      return new Date();
    } 

    return parseISO(nextChargeDate);
  };

  const onSelection = (value: string) => {
    setPauseSelection(parseInt(value));
  };
  if (!user || !subscription) return null;

  const requestPauseButton = {
    action: () => {
      updateExperiment({
        name: 'GRO-6',
        vwoId: 6,
        variation: '2',
        data: {
          isNotVwo: true,
          suspension: {
            status: 'requested',
            duration: pauseSelection,
            date: Date.now()
          }
        }
      });

      services.notification.sendSupportNotification({
        data: {
          email: user.email,
          userId: user.id,
          date: formatDate(new Date(), dateFormat),
          type: 'suspension',
          duration: pauseSelection,
          startDate: formatDate(getSuspensionStartDate(subscription), dateFormat)
        }, template: 'pausing'
      });

      if (pauseSelection && user && subscription) {
        ampliTrackSubscriptionPauseSubmittedEvent(pauseSelection);
      }

      props.setScreen('finishPausing');
    },
    disabled: pauseSelection === undefined,
    title: t('Pause Subscription'),
  };

  return (
    <ScreenStepContainer
      trackingId='pauseSelection'
      title={t('Pausing Premium')}
      primaryButton={requestPauseButton}
      onClose={props.onClose}
    >
      <Trans i18nKey={'If you are not going to use meinUnterricht premium for a while, you can pause it'}><p>
          If you are not going to use meinUnterricht for a while, you can pause your subscription. More details in our
        <a href={externalLinks.tac} target='_blank' rel='noopener noreferrer'>
          {{ agb: t('AGB') }}
        </a>.
      </p></Trans>
      <Trans i18nKey={'You would like to pause for longer?'}><p>
          You would like to pause for longer because of maternity/paternity leave? Send us an email to
        <a href={`mailto:${emailAddresses.support}`}>
          {{ email: emailAddresses.support }}
        </a>.
      </p></Trans>
      <div className='screen-step__pause-select'>
        <div className='screen-step__pause-label'>
          {t('Choose the Pause Period')}
        </div>
        <div className='screen-step__pause-options'>
          <Radio
            options={suspensionOptions}
            name='pause-selection'
            radioClass='screen-step__pause-option-radio'
            labelClass='screen-step__pause-option-label'
            handleChange={onSelection}
          />
        </div>
        {pauseSelection && suspensionEndDate && <label
          data-testid='screen-step__pause-label'
          className='screen-step__pause-label'>
          {t('Pausing from {Start} to {End}', {
            startDate: getDateInGermanFormat(getSuspensionStartDate(subscription)),
            endDate: getDateInGermanFormat(suspensionEndDate)
          })}
        </label>}
        <div className='screen-step__img screen-step__empty-desk' />
      </div>
    </ScreenStepContainer>
  );
};

export default CancellationStepPauseSelection;
