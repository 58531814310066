exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".top-bar__container{background-color:#4298b5;display:flex;align-items:center;height:48px;padding:0 16px}@media only screen and (min-width: 1440px){.top-bar__container{padding:0 32px}}.top-bar__container--isPublicPage{background-color:#3381ff}.top-bar__icon{color:#fff}.top-bar__icon__link{display:inline-block;padding:4px 4px;outline-color:rgba(0,0,0,0)}.top-bar__icon-container{margin-right:8px}.top-bar__icon-container:last-child{margin-right:0}.top-bar__icon svg{fill:#fff}.top-bar__premium-link{font-size:16px;line-height:24px;font-weight:500;display:flex;align-items:center}.top-bar__premium-pill{font-size:8px;line-height:16px;font-weight:500;line-height:8px;letter-spacing:.75px;background-color:#fff;color:#4298b5;border-radius:30px;padding:4px 8px;margin-left:8px}.search-page-container{position:relative;background:#f7f9fa;padding-left:95px;padding-right:95px;padding-top:64px;padding-bottom:21px;min-height:calc(100vh - 48px)}@media only screen and (max-width: 575px){.search-page-container{padding-left:5px;padding-right:5px;overflow:hidden}}", ""]);
// Exports
exports.locals = {
	"collectionGridGap": "15px",
	"collectionGridItemHeight": "211px",
	"collectionGridItemWidth": "225px",
	"collectionListHeight": "84px",
	"greyLightest": "#f7f9fa",
	"greyLight2": "#d8d8d8",
	"neuVeryLightBlue": "#e9ebec",
	"muBlue": "#4298b5",
	"gridBreakPoints_-xs": "0",
	"gridBreakPoints_-sm": "576px",
	"gridBreakPoints_-md": "768px",
	"gridBreakPoints_-lg": "992px",
	"gridBreakPoints_-xl": "1440px",
	"medium": "768px",
	"large": "1024px",
	"xlarge": "1440px"
};