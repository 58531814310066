import React, { useEffect, useRef, useState } from 'react';
import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@mui/material';
import { tooltipEnterDelay, tooltipLeaveDelay } from '../constants';
import { debounce } from 'lodash';

interface TooltipProps {
  label: string;
  placement?: MuiTooltipProps['placement']
  children: React.ReactElement;
  open?: boolean;
  hideWithoutOverflow?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({ label, placement, children, open, hideWithoutOverflow = true }) => {
  const inputRef = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(open ? open : false);
  const [isOpenDisabled, setIsOpenDisabled] = useState(open !== undefined);

  useEffect(() => {
    const debounceFn = debounce((entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        const newIsOverflowing =
          (entry.target.scrollHeight > entry.target.clientHeight) ||
          (entry.target.scrollWidth > entry.target.clientWidth);
        setIsOpenDisabled(open !== undefined || !newIsOverflowing);
      }
    }, 50);
    const observer = new ResizeObserver(debounceFn);
    // @ts-ignore
    if (hideWithoutOverflow && inputRef.current?.children) {
      // @ts-ignore
      observer.observe(inputRef.current?.children[0]);
    }

    return () => {
      // @ts-ignore
      if (hideWithoutOverflow && inputRef.current?.children) {
        // @ts-ignore
        observer.unobserve( inputRef.current?.children[0]);
      }
      debounceFn.cancel();
    };
  }, [inputRef.current, open, hideWithoutOverflow]);

  const onOpen = () => {
    if (!isOpenDisabled) {
      setIsOpen(true);
    }
  };
  const onClose = () => {
    if (!open) {
      setIsOpen(false);
    }
  };

  return (
    <MuiTooltip
      title={label}
      placement={placement}
      arrow={true}
      enterDelay={tooltipEnterDelay}
      leaveDelay={tooltipLeaveDelay}
      PopperProps={{
        disablePortal: false,
      }}
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <div ref={inputRef}>
        {children}
      </div>
    </MuiTooltip>
  );
};

export default Tooltip;
