import api from '../clients/api';
import { handleUnauthorized } from '../utils/apiWrapper';
import {
  ExistsQuery,
  ExistsByTokenQuery,
  UpdateNewsletterPayload,
  UpdatePayload as UpdateUserPayload,
  UpgradePayload,
} from '@klab-berlin/api-sdk/lib/types/requests/User';
import { PaginationRequest } from '@klab-berlin/api-sdk/lib/types/common';
import { notifyBugsnagNetwork } from '../utils/bugsnagClient';
import store from '../store';
import { ExperimentData } from '@klab-berlin/api-sdk/lib/types/responses/User';

const service = {
  canCreateSubscription,
  confirmNewEmail,
  changeEmail,
  existsByQuery,
  getUserByToken,
  followCollection,
  getCollections,
  getDownloadContents,
  getLibraryIpRange,
  getMe,
  getPinnedContents,
  getRecommendedCollections,
  hasValidLibraryDomain,
  unfollowCollection,
  updateNewsletter,
  updateUser,
  upgrade,
  initPasswordReset,
  resetPassword,
  changePassword,
  getExperiment,
  updateExperiment,
};

export function getUserId() {
  const user = store.getState().user.me;
  const userId = user && user._id;

  if (!userId) {
    throw new Error('Missing userId');
  }
  return userId;
}

function changeEmail(
  oldEmail: string,
  newEmail: string,
  password: string,
  isLibraryEmail?: boolean
) {
  notifyBugsnagNetwork('user', 'changeEmail');
  return api.user.changeEmail(oldEmail, newEmail, password, isLibraryEmail);
}

function confirmNewEmail(token: string) {
  notifyBugsnagNetwork('user', 'confirmNewEmail', { token });
  return api.user.confirmChangeEmail(token);
}

async function getMe() {
  notifyBugsnagNetwork('user', 'getMe');
  return handleUnauthorized(() => api.user.getMe());
}

async function getCollections() {
  const userId = getUserId();
  notifyBugsnagNetwork('user', 'getCollections', { userId });
  return handleUnauthorized(() => api.user.getCollections(getUserId()));
}

async function getDownloadContents(pagination: PaginationRequest) {
  const userId = getUserId();
  notifyBugsnagNetwork('user', 'getDownloadContents', { userId });
  return handleUnauthorized(() => api.user.getDownloadContents(userId, pagination));
}

async function getPinnedContents(pagination: PaginationRequest) {
  const userId = getUserId();
  notifyBugsnagNetwork('user', 'getPinnedContents', { userId });
  return handleUnauthorized(() => api.user.getPinnedContents(userId, pagination));
}

async function getRecommendedCollections() {
  const userId = getUserId();
  return handleUnauthorized(() => api.user.getRecommendedCollections(userId));
}

function followCollection(collectionId: string) {
  notifyBugsnagNetwork('user', 'addFollower', { collectionId });
  return handleUnauthorized(() =>
    api.collection.addFollower(collectionId, getUserId())
  );
}

function unfollowCollection(collectionId: string) {
  notifyBugsnagNetwork('user', 'unfollowCollection', { collectionId });
  return handleUnauthorized(() =>
    api.collection.deleteFollower(collectionId, getUserId())
  );
}

function updateNewsletter(userId: string, payload: UpdateNewsletterPayload) {
  notifyBugsnagNetwork('user', 'updateNewsletter', { userId, payload });
  return handleUnauthorized(() => api.user.updateNewsletter(userId, payload));
}

function updateUser(userId: string, payload: UpdateUserPayload) {
  notifyBugsnagNetwork('user', 'updateUser', { userId, payload });
  return handleUnauthorized(() => api.user.update(userId, payload));
}

function upgrade(userId: string, payload: UpgradePayload) {
  notifyBugsnagNetwork('user', 'upgrade', { userId, payload });
  return handleUnauthorized(() => api.user.upgrade(userId, payload));
}

function existsByQuery(query: ExistsQuery) {
  notifyBugsnagNetwork('user', 'existsByQuery', { query });
  return handleUnauthorized(() => api.user.existsByQuery(query));
}

function getUserByToken(query: ExistsByTokenQuery) {
  notifyBugsnagNetwork('user', 'getUserByToken', { query });
  return handleUnauthorized(() => api.user.getUserByToken(query));
}

function initPasswordReset(email: string) {
  notifyBugsnagNetwork('user', 'initPasswordReset', { email });
  return api.user.initPasswordReset(email);
}

function getExperiment(userId: string, experiment: string) {
  notifyBugsnagNetwork('user', 'getExperiment', { experiment });
  return api.user.getExperimentData(userId, experiment);
}

function updateExperiment(userId: string, experiment: ExperimentData) {
  notifyBugsnagNetwork('user', 'updateExperiment', { experiment });
  return api.user.trackExperiment(userId, experiment);
}

function resetPassword(
  password: string,
  passwordRepeat: string,
  token: string
) {
  notifyBugsnagNetwork('user', 'initPasswordReset', {
    password: !!password,
    isPasswordRepeatTheSame: password === passwordRepeat,
    token,
  });

  return api.user.resetPassword(password, passwordRepeat, token);
}

function changePassword(password: string, newPassword: string, newPasswordRepeat: string) {
  return api.user.changePassword(password, newPassword, newPasswordRepeat);
}

function canCreateSubscription(email: string) {
  notifyBugsnagNetwork('user', 'canCreateSubscription', { email });
  return handleUnauthorized(() => api.user.canCreateSubscription(email));
}

function hasValidLibraryDomain(email: string, library: string) {
  notifyBugsnagNetwork('user', 'hasValidLibraryDomain', { email, library });
  return handleUnauthorized(() => api.user.hasValidLibraryDomain(email, library));
}

function getLibraryIpRange(library: string) {
  notifyBugsnagNetwork('user', 'getLibraryIpRange', { library });
  return handleUnauthorized(() => api.user.getLibraryIpRange(library));
}

export default service;
