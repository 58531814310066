import { StoreType } from '../../reducers';
import { selectPinnedContents } from '../../reducers/pinned.selectors';
import Icon from '../Icon';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import EmptyState from '../../components/EmptyState';
import { AsyncActionStatus } from '../../actions/common.actionTypes';
import Skeleton from 'react-loading-skeleton';
import { Collection } from '../../types/AppContent';
import { ContentItem } from '../../types/AppContent';
import PinnedContentsContextItem from './PinnedContentsContextItem';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ItemVariation } from '@klab-berlin/api-sdk/lib/types/responses/Collection';

interface PinnedContentsContextOwnProps {
  collection: Collection;
  addItemToCollection: (collection: string, type: ItemVariation, id: string) => void;
  deleteItemFromCollection: (collection: string, type: ItemVariation, id: string) => void;
}

interface PinnedContentsContextStoreProps {
  pinnedContents: ContentItem[];
  pinnedStatus: AsyncActionStatus;
}

type PinnedContentsContextProps = PinnedContentsContextOwnProps & PinnedContentsContextStoreProps;

const PinnedContentsContext: React.FC<PinnedContentsContextProps> =
  ({ collection, pinnedContents, pinnedStatus, ...props }) => {
    const { t } = useTranslation();
    return (
      <>
        <Row className='py-4 border-bottom'>
          <Col>
            <h2 className='font-weight-normal my-0'>
              <Icon icon='star-solid' className='text-warning mr-2' />
              {t('Pinned Documents')}
            </h2>
          </Col>
        </Row>
        {pinnedContents.length === 0 && status !== 'loading' && <EmptyState
          iconName='star-solid'
          text={[t('no pinned documents yet')]}
        />}
        {pinnedStatus === 'loading' &&
          <>
            <Skeleton height={211} />
            <Skeleton height={211} />
            <Skeleton height={211} />
          </>
        }
        {pinnedContents.length > 0 && (
          <>
            {pinnedContents.map((item) => {
              const isInCollection = !!collection.contents.find(c => c.id === item.id);
              return <PinnedContentsContextItem
                key={item.id}
                collectionId={collection.id}
                item={item}
                isInCollection={isInCollection}
                addItemToCollection={props.addItemToCollection}
                deleteItemFromCollection={props.deleteItemFromCollection}
              />;
            })}
          </>
        )}
      </>
    );
  };

export default compose<React.FC<PinnedContentsContextOwnProps>>(
  connect(
    (state: StoreType): PinnedContentsContextStoreProps => ({
      pinnedContents: selectPinnedContents(state),
      pinnedStatus: state.pinned.status,
    })
  ),
)(PinnedContentsContext);
