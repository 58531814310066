import React from 'react';

interface CreateProfileProgressBarProps {
  currentStep: number;
  numberOfSteps: number;
}

const profileBarClassName = 'profile__bar rounded-pill flex-grow-1';

const CreateProfileProgressBar: React.FC<CreateProfileProgressBarProps> = ({ currentStep, numberOfSteps }) => (
  <div className='pt-3 pb-3 pl-4 pr-4 d-flex'>
    {Array(currentStep).fill(0).map((_, index) => (
      <div key={index} className={`${profileBarClassName} bg-primary`} />
    ))}

    {Array(numberOfSteps - currentStep).fill(0).map((_, index) => (
      <div key={index} className={`${profileBarClassName} bg-light`} />
    ))}
  </div>
);

export default CreateProfileProgressBar;
