import React, { useEffect, useState } from 'react';
import { PaymentPeriod } from '@klab-berlin/api-sdk/lib/types/common';
import { DiscountInformation } from '@klab-berlin/api-sdk/lib/types/responses/Billing';
import { useTranslation } from 'react-i18next';
import ActiveLayout from './ActiveLayout';
import './promoCodeForm.scss';
import Icon from '../../Icon';
import { currencyFormat } from '../../../utils/format';

enum FormLayout {
  initial,
  active,
  confirmed,
}

interface OwnProps {
  licenceId: number;
  paymentRhythm: PaymentPeriod;
  onPromoCodeValidation: (discountInformation: DiscountInformation, promoCode: string) => void;
  onActiveLayoutInit: () => void;
  initPromoCode?: string;
  isSmallerWidth?: boolean;
}

const PromoCodeForm: React.FC<OwnProps> = (props) => {
  const { t } = useTranslation();
  const [formLayout, setFormLayout] = useState<FormLayout>(FormLayout.initial);
  const [discountInformation, setDiscountInformation] = useState<DiscountInformation>();
  const [submittedPromoCode, setSubmittedPromoCode] = useState<string>();
  const [initPromoCode, setInitPromoCode] = useState<string>();

  // We need to make sure that the promo code supports the payment rythm selected by the user.
  const [isPaymentRythmValid, setIsPaymentRythmValid] = useState(true);

  useEffect(() => {
    if (discountInformation?.billingPeriods) {
      setIsPaymentRythmValid(discountInformation.billingPeriods.includes(props.paymentRhythm));
    }
  }, [props.paymentRhythm, discountInformation]);

  useEffect(() => {
    if (props.initPromoCode) {
      setInitPromoCode(props.initPromoCode);
      setFormLayout(FormLayout.active);
    }
  }, []);

  const InitialLayout = () => {
    const onClick = () => setFormLayout(FormLayout.active);

    return (
      <div 
        role='button' 
        tabIndex={0}
        className='promo-code-form__initial-layout'
        data-testid='promo-code-form-initial-layout'
        onClick={onClick}
      >
        {t('Add discount code')}
      </div>
    );
  };

  const ConfirmedLayout = () => {
    if (!isPaymentRythmValid) {
      setFormLayout(FormLayout.active);
    }

    const onDeleteClick = () => {
      setInitPromoCode(undefined);
      setFormLayout(FormLayout.active);
    };

    return (
      <div 
        tabIndex={0}
        data-testid='promo-code-form__confirmed-layout'
        className='promo-code-form__confirmed-layout'
      >
        <div className='promo-code-form__submitted-promo-code-container'>
          {`${t('Discount')}`}
          <div className='promo-code-form__submitted-promo-code' data-testid='submitted-promo-code'>
            {submittedPromoCode}
            <div 
              onClick={onDeleteClick} 
              data-testid='promo-code-form__remove-code' 
              className='promo-code-form__remove-code'
            >
              <Icon icon='cancel' size='m' />
            </div>
          </div>
        </div>

        <div 
          data-testid='promo-code-form__price-reduction' 
          className='promo-code-form__price-reduction'
        >
          {
            discountInformation?.priceReduction 
              ? `- ${currencyFormat(discountInformation?.priceReduction)}`
              : undefined
          }
        </div>
      </div>    
    );
  };

  return (
    <div className='promo-code-container'>
      {formLayout === FormLayout.initial && <InitialLayout />}
      {
        formLayout === FormLayout.active && 
        <ActiveLayout 
          isPaymentRythmValid={isPaymentRythmValid}
          submittedPromoCode={submittedPromoCode}
          setSubmittedPromoCode={setSubmittedPromoCode}
          setDiscountInformation={setDiscountInformation}
          setFormLayout={setFormLayout}
          licenceId={props.licenceId}
          paymentRhythm={props.paymentRhythm}
          onPromoCodeValidation={props.onPromoCodeValidation}
          onActiveLayoutInit={props.onActiveLayoutInit}
          initPromoCode={initPromoCode}
          isSmallerWidth={props.isSmallerWidth}
        />
      }
      {formLayout === FormLayout.confirmed && <ConfirmedLayout />}
    </div>
  );
};

export default PromoCodeForm;
