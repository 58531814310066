import byteSize from 'byte-size';

const formatByteSize = (bytes: number): string => {
  const byteSizeResult = byteSize(bytes);
  if (byteSizeResult.unit === 'B') byteSizeResult.unit = 'Bytes';
  else byteSizeResult.unit = byteSizeResult.unit.toUpperCase();

  return `${byteSizeResult.value} ${byteSizeResult.unit}`;
};

export default formatByteSize;
