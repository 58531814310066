import { noop } from 'lodash';
import React, { useCallback, useState } from 'react';
import {
  ContactModal,
  ImprintModal,
  PrivacyPolicyModal,
  PremiumContentModal
} from '../components/modals';

export type ModalType = 'contact' | 'imprint' | 'privacyPolicy' | 'premiumContent' | 'none';

interface Context {
  closeModal: () => void;
  openModal: (type: ModalType) => void;
}

const defaultContext: Context = {
  closeModal: noop,
  openModal: noop,
};

const ModalsContext = React.createContext(defaultContext);

const ModalsProvider: React.FC = (props) => {
  const [modal, setModal] = useState<ModalType>('none');

  const openModal = useCallback((type: ModalType) => {
    setModal(type);
  }, [setModal]);

  const closeModal = useCallback(() => {
    setModal('none');
  }, [setModal]);

  Object.assign(defaultContext, { closeModal, openModal });

  return (
    <ModalsContext.Provider value={defaultContext}>
      <ContactModal show={modal === 'contact'} onClose={closeModal} />
      <ImprintModal show={modal === 'imprint'} onClose={closeModal} />
      <PrivacyPolicyModal show={modal === 'privacyPolicy'} onClose={closeModal} />
      <PremiumContentModal show={modal === 'premiumContent'} onClose={closeModal} />
      {props.children}
    </ModalsContext.Provider>
  );
};

export { ModalsContext, ModalsProvider };
