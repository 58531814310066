import React from 'react';
import { paymentMethods } from '../../../constants';
import { Accordion, Card, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { ZuoraPaymentMethod } from '@klab-berlin/api-sdk/lib/services/billing';
import cssVariables from '../../../assets/styles/deprecated/variables';

interface PaymentCardProps {
  loadingError: boolean;
  isLoaded: boolean;
  children: React.ReactNode;
  method: ZuoraPaymentMethod;
  isSelected: boolean;
  icon: React.ReactNode;
}

const PaymentCard: React.FC<PaymentCardProps> = ({
  isSelected,
  method,
  icon,
  loadingError,
  isLoaded,
  children
}) => {

  const { t } = useTranslation();
  return (
    <Card className={classnames('card--white-edge mb-3 border-0 box-shadow', {
      'box-shadow--bigger card--white-edge--active': isSelected
    })}
    >
      <Accordion.Toggle
        className='card-header__accordion-toggle cursor-pointer bg--white py-0 d-flex align-items-center'
        as={Card.Header}
        variant='link'
        eventKey={method}>  
        <h4 className='mb-0'>
          {t(paymentMethods[method].name)}
        </h4>
        <div className='card-header__icon--right'>
          {icon}
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={method}>
        <Card.Body>
          {loadingError &&
            <Alert variant='warning'>
              {t('Error while loading payment form, please refresh.')}
            </Alert>
          }
          {!loadingError && !isLoaded &&
            <SkeletonTheme color={cssVariables.greyLightest} highlightColor='#ffffff'>
              <Skeleton height={130} />
            </SkeletonTheme>
          }
          {children}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default PaymentCard;
