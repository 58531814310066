import React, { useCallback } from 'react';
import Login from '../../components/Login/Login';
import { withRouter, WithRouter } from 'found';
import { routes, LoginSources, errorCodes } from '../../constants';
import services from '../../services';
import { trackingEvents } from '../../services/tracking/trackConfig';
import LoginGradientModalAppLayout from '../../components/Login/LoginGradientAppLayout';
import RedirectIfLoggedIn from '../../router/redirect/RedirectIfLoggedIn';

interface OwnProps {
  match: {
    location: {
      query: {
        redirect?: string;
      };
    };
  };
}
type LoginPageProps = OwnProps & WithRouter;

const determineLoginFailureEvent = (source: LoginSources, errorCode?: number) => {
  if (source === LoginSources.facebook) {
    if (errorCode === errorCodes.fbEmailDoesntExist) {
      return trackingEvents.loginFailure.facebookIncorrectEmail;
    }

    return trackingEvents.loginFailure.facebookCancelled;
  }

  if (errorCode === errorCodes.ipRestriction) {
    return trackingEvents.loginFailure.incorrectIP;
  }

  if (errorCode === errorCodes.userNotValidated) {
    return trackingEvents.loginFailure.notVerified;
  }
  return trackingEvents.loginFailure.incorrectEmail;
};

const LoginPage: React.FC<LoginPageProps> = (props) => {
  const handleLoginSuccess = useCallback((source: LoginSources) => {
    const redirect = props.match.location.query.redirect;
    if (redirect) {
      props.router.push(redirect);
    } else {
      props.router.push(`/${routes.dashboard.root}`);
    }

    if (source !== LoginSources.facebook) {
      services.track.eventTrack(trackingEvents.loginSuccess.email);
    }
  }, [props.router, props.match]);

  const handleLoginFailure = useCallback((source: LoginSources, statusCode: number, errorCode?: number) => {
    if (errorCode === errorCodes.ipRestriction) {
      props.router.push(`/${routes.ipRangeError.root}`);
    }
    const event = determineLoginFailureEvent(source, errorCode);

    const payload = {
      other: {
        'login_type': source === LoginSources.facebook ? 'FB' : 'Email',
        'status_code': statusCode
      },
    };
    services.track.eventTrack(event, payload);
  }, [1]);

  return (
    <RedirectIfLoggedIn>
      <LoginGradientModalAppLayout>
        <Login onLoginSuccess={handleLoginSuccess} onLoginFailure={handleLoginFailure} />
      </LoginGradientModalAppLayout>
    </RedirectIfLoggedIn>
  );
};

export default withRouter(LoginPage);
