import immutable from 'object-path-immutable';
import {
  ADD_ITEM_TO_COLLECTION,
  AddItemToCollectionAction,
  DELETE_ITEM_FROM_COLLECTION,
  DeleteItemFromCollectionAction,
  ADD_COLLECTION,
  AddCollectionAction,
  DELETE_COLLECTION,
  DeleteCollectionAction,
  EDIT_COLLECTION,
  EditCollectionAction,
  FETCH_COLLECTIONS,
  FETCH_COLLECTION_CONTENTS,
  FETCH_CURATED_COLLECTIONS,
  FETCH_RECOMMENDED_COLLECTIONS,
  FOLLOW_COLLECTION,
  FetchCollectionContentsAction,
  FetchCollectionsAction,
  FetchCuratedCollectionsAction,
  FetchRecommendedCollectionsAction,
  FollowCollectionAction,
  UNFOLLOW_COLLECTION,
  UnfollowCollectionAction,
  MANAGE_MINDITEM_IN_MULTIPLE_COLLECTIONS,
  MANAGE_PRODUCT_IN_MULTIPLE_COLLECTIONS,
  ManageMindItemInMultipleCollectionsAction,
  ManageProductInMultipleCollectionsAction,
} from '../actions/collections.actionTypes';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import { pagination } from '../constants';
import {
  Collection,
  CollectionProperties,
  Pagination,
} from '../types/AppContent';
import { ItemVariation } from '@klab-berlin/api-sdk/lib/types/responses/Collection';
import { arrayToObject } from '../utils/arrayToObject';
import { mapCollectionContents } from '../utils';
import { uniqueArrayOfObjects } from '../utils/uniqueArrayOfObjects';
import { LogoutAction, LOGOUT } from '../actions/auth.actionTypes';

type CollectionsActionTypes =
  | FetchCollectionContentsAction
  | AddCollectionAction
  | EditCollectionAction
  | FetchCollectionsAction
  | FetchCuratedCollectionsAction
  | FetchRecommendedCollectionsAction
  | DeleteCollectionAction
  | FollowCollectionAction
  | ManageMindItemInMultipleCollectionsAction
  | ManageProductInMultipleCollectionsAction
  | UnfollowCollectionAction
  | AddItemToCollectionAction
  | DeleteItemFromCollectionAction
  | LogoutAction;

export interface CollectionsInitialStateType {
  curated: {
    items: string[];
    status: AsyncActionStatus;
  };
  followed: {
    items: string[];
    status: AsyncActionStatus;
  };
  own: {
    items: string[];
    status: AsyncActionStatus;
  };
  recommendations: {
    items: string[];
    status: AsyncActionStatus;
  };
  byId: {
    [key: string]: {
      contents: Array<{ id: string; type: ItemVariation }>;
      data: CollectionProperties;
      rollbackData?: CollectionProperties;
      error?: string;
      status: AsyncActionStatus;
      pagination: Pagination;
    };
  };
}

const initialPagination: () => Pagination = () => ({
  total: -1,
  limit: pagination.itemsOnPage,
  offset: 0,
  sortOrder: 'desc',
});

const initialState: CollectionsInitialStateType = {
  curated: {
    items: [],
    status: AsyncActionStatus.LOADING,
  },
  followed: {
    items: [],
    status: AsyncActionStatus.LOADING,
  },
  own: {
    items: [],
    status: AsyncActionStatus.LOADING,
  },
  recommendations: {
    items: [],
    status: AsyncActionStatus.LOADING,
  },
  byId: {},
};

const collections = (
  state = initialState,
  action: CollectionsActionTypes
): CollectionsInitialStateType => {
  switch (action.type) {
    case LOGOUT.SUCCESS:
      return initialState;

    case EDIT_COLLECTION.START:
      return immutable(state)
        .set(
          ['byId', action.payload.collectionId, 'rollbackData'],
          state.byId[action.payload.collectionId].data
        )
        .merge(
          ['byId', action.payload.collectionId, 'data'],
          action.payload.data
        )
        .value();

    case EDIT_COLLECTION.ERROR:
      return immutable(state)
        .set(
          ['byId', action.payload.collectionId, 'data'],
          state.byId[action.payload.collectionId].rollbackData as Collection
        )
        .value();

    case EDIT_COLLECTION.SUCCESS: {
      const isPublic = action.result.isPublic;

      const obj = immutable(state)
        .merge(['byId', action.payload.collectionId, 'data'], action.result)
        .set(['byId', action.payload.collectionId, 'rollbackData'], undefined);

      const index = state.curated.items.indexOf(action.result.id);
      if (isPublic) {
        if (index === -1) {
          obj.push(['curated', 'items'], action.result.id);
        }
      } else {
        obj.del(['curated', 'items', index]);
      }

      return obj.value();
    }

    case ADD_COLLECTION.SUCCESS: {
      const { mindItems, products } = action.result;

      return immutable(state)
        .insert(['own', 'items'], action.result.id, 0)
        .set(['byId', action.result.id], {
          data: action.result,
          status: AsyncActionStatus.READY,
          contents: mapCollectionContents(mindItems, products),
          pagination: initialPagination(),
        })
        .value();
    }

    case FETCH_COLLECTION_CONTENTS.START: {
      const collectionId = action.payload.collectionId;
      return immutable(state)
        .set(['byId', collectionId, 'status'], AsyncActionStatus.LOADING)
        .merge(['byId', collectionId, 'pagination'], action.payload.pagination)
        .value();
    }

    case FETCH_COLLECTION_CONTENTS.ERROR: {
      const collectionId = action.payload.collectionId;
      return immutable(state)
        .set(['byId', collectionId, 'status'], AsyncActionStatus.ERROR)
        .value();
    }

    case FETCH_COLLECTION_CONTENTS.SUCCESS: {
      const collectionId = action.payload.collectionId;
      const pagination = action.payload.pagination;
      const mappedContentsResponse = action.result.data.map((r) => ({
        id: r.id,
        type: r.type,
      }));
      let newState = immutable(state);

      for (const [key, value] of Object.entries(mappedContentsResponse)) {
        const index = (pagination.offset || 0) + parseInt(key);

        newState = newState.set(
          ['byId', action.payload.collectionId, 'contents', index],
          value
        );
      }

      return newState
        .set(['byId', collectionId, 'status'], AsyncActionStatus.READY)
        .set(
          ['byId', collectionId, 'pagination', 'total'],
          action.result.meta.pagination.total
        )
        .update(['byId', action.payload.collectionId, 'contents'], (value) =>
          uniqueArrayOfObjects(value, (item) => item.id)
        )
        .value();
    }

    case ADD_ITEM_TO_COLLECTION.START: {
      const collectionId = action.payload.collectionId;
      const newContents = state.byId[collectionId].contents;
      if (!newContents.find((item) => item.id == action.payload.itemId)) {
        newContents.push({
          id: action.payload.itemId,
          type: action.payload.itemType,
        });
      }
      return immutable(state)
        .set(['byId', collectionId, 'contents'], newContents)
        .value();
    }
    case DELETE_ITEM_FROM_COLLECTION.START:
      return immutable(state)
        .set(
          ['byId', action.payload.collectionId, 'contents'],
          state.byId[action.payload.collectionId].contents.filter(
            (item) => item.id != action.payload.itemId
          )
        )
        .value();

    case FETCH_COLLECTIONS.START:
      return immutable(state)
        .set(['own', 'status'], AsyncActionStatus.LOADING)
        .set(['followed', 'status'], AsyncActionStatus.LOADING)
        .value();

    case FETCH_COLLECTIONS.ERROR:
      return immutable(state)
        .set(['own', 'status'], AsyncActionStatus.ERROR)
        .set(['followed', 'status'], AsyncActionStatus.ERROR)
        .value();

    case FETCH_COLLECTIONS.SUCCESS: {
      const userId = action.payload.ownerId;

      const collections = arrayToObject(
        action.result.map((r) => ({
          data: r,
          status: AsyncActionStatus.READY,
          contents: mapCollectionContents(r.mindItems, r.products),
          pagination:
            (state.byId[r.id] && state.byId[r.id].pagination) ||
            initialPagination(),
        })),
        (v) => v.data.id
      );

      return immutable(state)
        .set(['own', 'status'], AsyncActionStatus.READY)
        .set(['followed', 'status'], AsyncActionStatus.READY)
        .merge('byId', collections)
        .set(
          ['own', 'items'],
          action.result.filter((r) => r.owner === userId).map((r) => r.id)
        )
        .set(
          ['followed', 'items'],
          action.result.filter((r) => r.owner !== userId).map((r) => r.id)
        )
        .value();
    }

    case FETCH_CURATED_COLLECTIONS.START:
      return immutable(state)
        .set(['curated', 'status'], AsyncActionStatus.LOADING)
        .value();

    case FETCH_CURATED_COLLECTIONS.ERROR:
      return immutable(state)
        .set(['curated', 'status'], AsyncActionStatus.ERROR)
        .value();

    case FETCH_CURATED_COLLECTIONS.SUCCESS: {
      const curatedCollections = arrayToObject(
        action.result.map((r) => ({
          data: r,
          status: AsyncActionStatus.READY,
          contents: mapCollectionContents(r.mindItems, r.products),
          pagination: initialPagination(),
        })),
        (v) => v.data.id
      );

      return immutable(state)
        .set(['curated', 'status'], AsyncActionStatus.READY)
        .merge('byId', curatedCollections)
        .set(
          ['curated', 'items'],
          action.result.map((r) => r.id)
        )
        .value();
    }

    case FETCH_RECOMMENDED_COLLECTIONS.START:
      return immutable(state)
        .set(['recommendations', 'status'], AsyncActionStatus.LOADING)
        .value();

    case FETCH_RECOMMENDED_COLLECTIONS.ERROR:
      return immutable(state)
        .set(['recommendations', 'status'], AsyncActionStatus.ERROR)
        .value();

    case FETCH_RECOMMENDED_COLLECTIONS.SUCCESS: {
      const recommendations = arrayToObject(
        action.result.map((r) => ({
          data: r,
          status: AsyncActionStatus.READY,
          contents: mapCollectionContents(r.mindItems, r.products),
          pagination: initialPagination(),
        })),
        (v) => v.data.id
      );

      return immutable(state)
        .set(['recommendations', 'status'], AsyncActionStatus.READY)
        .merge('byId', recommendations)
        .set(
          ['recommendations', 'items'],
          action.result.map((r) => r.id)
        )
        .value();
    }

    case DELETE_COLLECTION.SUCCESS:
      return immutable(state)
        .set(
          ['own', 'items'],
          state.own.items.filter((id) => id !== action.payload.collectionId)
        )
        .del(['byId', action.payload.collectionId])
        .value();

    case FOLLOW_COLLECTION.START:
    case UNFOLLOW_COLLECTION.ERROR: {
      return immutable(state)
        .push(['followed', 'items'], action.payload.collectionId)
        .set(['byId', action.payload.collectionId, 'data', 'isFollowing'], true)
        .value();
    }

    case FOLLOW_COLLECTION.ERROR:
    case UNFOLLOW_COLLECTION.START: {
      const index = state.followed.items.indexOf(action.payload.collectionId);
      return immutable(state)
        .del(['followed', 'items', index])
        .set(
          ['byId', action.payload.collectionId, 'data', 'isFollowing'],
          false
        )
        .value();
    }

    case MANAGE_PRODUCT_IN_MULTIPLE_COLLECTIONS.SUCCESS:
    case MANAGE_MINDITEM_IN_MULTIPLE_COLLECTIONS.SUCCESS: {
      const { collections, itemId, itemType } = action.payload;
      const immutableState = immutable(state);

      for (const collection of collections) {
        const itemIndex = state.byId[
          collection.collectionId
        ].contents.findIndex((i) => i.id === itemId);
        const isInCollection = itemIndex !== -1;

        if (!isInCollection && collection.shouldContainItem) {
          immutableState.push(['byId', collection.collectionId, 'contents'], {
            id: itemId,
            type: itemType,
          });
          immutableState.update(
            ['byId', collection.collectionId, 'data', 'contentsCount'],
            (v) => ++v
          );
        }

        if (isInCollection && !collection.shouldContainItem) {
          immutableState.del([
            'byId',
            collection.collectionId,
            'contents',
            itemIndex,
          ]);
          immutableState.update(
            ['byId', collection.collectionId, 'data', 'contentsCount'],
            (v) => --v
          );
        }
      }

      return immutableState.value();
    }
  }

  return state;
};

export default collections;
