import React, { ReactNode } from 'react';
import Container from 'react-bootstrap/Container';
import TopBar from './TopBar';
import UpgradeBanner from '../UpgradeBanner';

interface AppLayoutProps {
  menu?: ReactNode;
  main?: ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({ menu, main }) => (
  <div className='layout-grid'>
    <div className='layout-grid__nav-container'>
      <UpgradeBanner />
      <TopBar />
    </div>
    {menu && <aside
      className='layout-grid__menu-container bg--grey-lightest border-right d-none d-md-block'
      children={<Container fluid={true}>{menu}</Container>}
    />}
    <section className='layout-grid__main-container' children={main} />
  </div>
);

export default AppLayout;
