import { CreateCollectionRequest } from '@klab-berlin/api-sdk/lib/types/requests/Collection';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import constants, { collectionsSettings, NONE_OF_THE_ABOVE_OPTION } from '../../constants';
import {
  Collection,
  CollectionFormModalContext,
  CollectionFormModalContextState,
} from '../../state/CollectionFormModalProvider';
import { CollectionColor } from '@klab-berlin/api-sdk/lib/types/responses/Collection';
import { useForm } from 'react-hook-form';
import FormCheckboxArray from '../forms/FormCheckboxArray';
import FormInput from '../forms/FormInput';
import FormTextArea from '../forms/FormTextArea';
import FormSelect from '../forms/FormSelect';
import Checkbox from '../common/Checkbox';
import { selectSchoolTypes } from '../../reducers/schoolTypes.selectors';

interface CollectionFormModalProps {
  contextData: CollectionFormModalContextState;
}

const getDisplayedColor = (color: CollectionColor) => {
  if (collectionsSettings.colors.options.includes(color)) {
    return color;
  }
  return collectionsSettings.colors.default;
};

interface FormData extends Omit<Collection, 'color'> {
  color: string;
}

const validationSchema = yup.object<FormData>({
  color: yup.string().oneOf(collectionsSettings.colors.options),
  description: yup.string(),
  schoolType: yup.string(),
  subjects: yup.array().of(yup.string()),
  title: yup.string().required('Title is required'),
  isPublic: yup.boolean(),
});

const CollectionFormModalInner: React.FC<CollectionFormModalProps> = (props) => {
  const { collectionId, isOpen, hide, data, save, status, error, canPublish } = props.contextData;
  const { t } = useTranslation();
  const schoolTypes = useSelector(selectSchoolTypes);
  const initialState = (data || {}) as Collection;
  const { formState, errors, handleSubmit, register, reset, watch } = useForm<FormData>({
    mode: 'onBlur',
    validationSchema,
  });
  const watchColor = watch('color');
  const watchDescription = watch('description', initialState.description) || '';
  const descriptionMaxLength = 1500;

  const submit = React.useCallback((data: FormData) => {
    return save(collectionId, data as CreateCollectionRequest);
  }, [collectionId, save]);

  useEffect(() => {
    reset({
      ...initialState,
      color: getDisplayedColor(initialState.color),
    });
  }, [collectionId]);

  const getSchoolTypeOptions = () => {
    return [
      { value: '', label: '...' },
      ...schoolTypes
        .filter(school => school.profileId && school.profileId !== NONE_OF_THE_ABOVE_OPTION)
        .map((school) => (
          {
            label: school.name,
            value: school.name,
          }))
    ];
  };

  return (
    <Modal show={isOpen} onHide={hide} className='collection-modal'>
      <form onSubmit={handleSubmit(submit)}>
        <fieldset disabled={formState.isSubmitting}>
          <Modal.Header closeButton={true}>
            <Modal.Title>
              {collectionId ? t('Edit Collection') : t('Add Collection')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <FormInput
                  id='collection-form-modal-title'
                  title={`${t('Title')}*`}
                  name='title'
                  errors={errors}
                  ref={register}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormTextArea
                  containerClassName='field__description form-group mb-0'
                  id='collection-form-modal-description'
                  maxLength={descriptionMaxLength}
                  name='description'
                  ref={register}
                  title={t('Description')}
                />
                <div className='input-used-characters mt-1 mb-2 ml-1'>
                  {t('<remaining> characters remaining', { remaining: descriptionMaxLength - watchDescription.length })}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {t('Subjects')}<br />
                <FormCheckboxArray
                  className='collection-modal__subjects-container overflow-auto'
                  ref={register}
                  idPrefix='collection-form-modal-subjects'
                  name='subjects'
                  items={constants.subjects.map(subject => ({
                    label: subject.text,
                    value: subject.text,
                    className: 'subjects-container__checkbox'
                  }))}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <br />
                <FormSelect
                  ref={register}
                  id='collection-form-modal-school-type'
                  name='schoolType'
                  title={t('SchoolType')}
                  items={getSchoolTypeOptions()}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {t('Color')}
              </Col>
            </Row>
            <Row>
              <Col xs={2}>
                <div
                  className={`
                      h-100
                      w-100
                      rounded
                      collection__bg--default
                      collection__bg--${watchColor}
                    `}
                />
              </Col>
              <Col>
                <FormSelect
                  ref={register}
                  containerClassName='form-group mb-0'
                  id='collection-form-modal-color'
                  name='color'
                  items={collectionsSettings.colors.options.map(color => ({
                    label: t(color),
                    value: color,
                  }))}
                />
              </Col>
            </Row>
            {canPublish && (
              <Row>
                <Col>
                  <br />
                  <Checkbox
                    ref={register}
                    id='collection-form-modal-can-publish'
                    name='isPublic'
                    label={t('Public')}
                  />
                </Col>
              </Row>
            )}
            <Row>
              {status === 'error' && <small className='text-danger mt-3 ml-3 mr-3'>
                {t('Please try again')}
                {error}
              </small>}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={hide}>
              {t('Cancel')}
            </Button>
            <Button variant='primary' type='submit' disabled={status === 'loading'}>
              {collectionId ? t('Save') : t('Add')}
            </Button>
          </Modal.Footer>
        </fieldset>
      </form>
    </Modal>
  );
};

const CollectionFormModal = CollectionFormModalInner;

const CollectionFormModalConsumer = () => (
  <CollectionFormModalContext.Consumer>
    {data => (
      <CollectionFormModal contextData={data} />
    )}
  </CollectionFormModalContext.Consumer>
);

export default CollectionFormModalConsumer;
