import { withRouter, WithRouter } from 'found';
import React, { useEffect, useState } from 'react';
import { routes } from '../constants';
import { logout } from '../actions/auth.actions';
import GradientModalAppLayout from '../components/AppLayout/GradientModalLayout';
import MuLogoSvg from '../assets/MuLogo.svg';
import Button from 'react-bootstrap/Button';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import welcomePngPath from '../assets/welcome.png';
import { connect } from 'react-redux';
import { ProfileCreationStarted } from '../ampli';
import services from '../services';
import { UserByTokenResponse } from '@klab-berlin/api-sdk/lib/types/responses/User';
interface DispatchProps {
  logout: () => Promise<void>;
}
interface CreateProfilePageProps extends WithRouter, DispatchProps {
  location: {
    query: {
      token?: string;
    };
  };
}
const CreateProfilePage: React.FC<CreateProfilePageProps> = (props) => {
  const { token } = props.location.query;
  const [user, setUser] = useState<UserByTokenResponse | undefined>();

  useEffect(() => {
    props.logout();
  }, [1]);

  useEffect(() => {
    if (token) {
      services.user.getUserByToken({ optInToken: token })
        .then((response) => setUser(response))
        .catch(() => setUser({ hasValidToken: false }));
    }
  }, [token]);

  const { t } = useTranslation();

  const clickHandler = () => {
    if (user?.hasValidToken && user?.basicUserInfo) {
      services.track.ampliEventTrack({
        event: new ProfileCreationStarted(),
        userProperties: {
          id: user.basicUserInfo.id,
          type: user.basicUserInfo.type,
          licenceId: user.basicUserInfo.licenceModelId,
          createdAt: user.basicUserInfo.createdAt,
          signUpMethod: user.basicUserInfo.registrationMethod,
        }
      });
    }

    props.router.push({
      pathname: `/${routes.createProfile.root}/${routes.createProfilePassword.root}`,
      query: { token: props.location.query.token || '' },
    });
  };

  return (
    <GradientModalAppLayout>
      <div className='profile__page'>
        <h1 className='profile__page-title'>{t('Welcome to')}</h1>
        <MuLogoSvg className='profile__page-mu-logo mu-logo blue' />
        <img className='profile__page-welcome_image' src={welcomePngPath} alt='' />
        <Button size='lg' onClick={clickHandler}>{t('Next')}</Button>
      </div>
    </GradientModalAppLayout>
  );
};

export default compose<React.FC>(
  connect(undefined, { logout }),
  withRouter,
)(CreateProfilePage);
