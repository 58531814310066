import { StoreType } from './index';

export const getLernsets = (state: StoreType) => {
  return Object.values(state.lernsets.byId)
    .sort((a,b) => new Date(b.updatedDate).getTime() - new Date(a.updatedDate).getTime());
};

export const getLernsetById = (state: StoreType, id: string) => {
  return state.lernsets.byId[id];
};

export const getLernsetsStatus = (state: StoreType) => {
  return state.lernsets.status;
};

export const getLastAnnotatedPartId = (state: StoreType) => {
  return state.lernsets.lastAnnotatedPartId;
};
