import { AsyncAction } from './common.actionTypes';
import {
  CollectionContentsPaginatedData,
  ItemVariation,
} from '@klab-berlin/api-sdk/lib/types/responses/Collection';
import { MindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import { Product } from '@klab-berlin/api-sdk/lib/types/responses/Product';
import { PaginationRequest } from '@klab-berlin/api-sdk/lib/types/common';

export enum FETCH_PINNED_CONTENTS {
  START = 'FETCH_PINNED_CONTENTS_START',
  SUCCESS = 'FETCH_PINNED_CONTENTS_SUCCESS',
  ERROR = 'FETCH_PINNED_CONTENTS_ERROR',
}

export enum PIN_MIND_ITEM {
  START = 'PIN_MIND_ITEM_START',
  SUCCESS = 'PIN_MIND_ITEM_SUCCESS',
  ERROR = 'PIN_MIND_ITEM_ERROR',
}

export enum PIN_PRODUCT {
  START = 'PIN_PRODUCT_START',
  SUCCESS = 'PIN_PRODUCT_SUCCESS',
  ERROR = 'PIN_PRODUCT_ERROR',
}

export enum UNPIN_DOCUMENT {
  START = 'UNPIN_DOCUMENT_START',
  SUCCESS = 'UNPIN_DOCUMENT_SUCCESS',
  ERROR = 'UNPIN_DOCUMENT_ERROR',
}

export type FetchPinnedContentsAction = AsyncAction<
  typeof FETCH_PINNED_CONTENTS.START,
  typeof FETCH_PINNED_CONTENTS.SUCCESS,
  typeof FETCH_PINNED_CONTENTS.ERROR,
  CollectionContentsPaginatedData,
  { pagination: PaginationRequest }
>;

export type PinMindItemAction = AsyncAction<
  typeof PIN_MIND_ITEM.START,
  typeof PIN_MIND_ITEM.SUCCESS,
  typeof PIN_MIND_ITEM.ERROR,
  MindItem,
  { id: string; type: 'mindItem' }
>;

export type PinProductAction = AsyncAction<
  typeof PIN_PRODUCT.START,
  typeof PIN_PRODUCT.SUCCESS,
  typeof PIN_PRODUCT.ERROR,
  Product,
  { id: string; type: 'product' }
>;

export type UnpinDocumentAction = AsyncAction<
  typeof UNPIN_DOCUMENT.START,
  typeof UNPIN_DOCUMENT.SUCCESS,
  typeof UNPIN_DOCUMENT.ERROR,
  undefined,
  { id: string; type: ItemVariation }
>;
