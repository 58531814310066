import React from 'react';
import { IconButton, SvgIcon, Tooltip, Box } from '@mui/material';
import { borderRadius, boxShadows, palette, spaces } from '../../../assets/styles/themes/tokens';
import { colors } from '../../../assets/styles/themes/variables';

type BorderStyle = 'rounded' | 'rounded-bottom' | 'rounded-top' | undefined;

export interface ControlButtonProps {
  icon: React.ComponentType;
  dataTestId: string;
  title: string
  sx?: object;
  borderStyle?: BorderStyle;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ControlButton: React.FC<ControlButtonProps> = (props) => {
  const { icon, borderStyle, disabled, onClick, title, dataTestId } = props;

  const getBorderRadius = (borderStyle: BorderStyle) => {
    switch (borderStyle) {
      case 'rounded':
        return borderRadius.sm;
      case 'rounded-bottom':
        return `0 0 ${borderRadius.sm} ${borderRadius.sm}`;
      case 'rounded-top':
        return `${borderRadius.sm} ${borderRadius.sm} 0 0`;
      default:
        return borderRadius.sm;
    }
  };

  const curBorderRadius = getBorderRadius(borderStyle);
  const svgFilter = disabled ? 'opacity(0.3)' : 'unset';

  return (
    <Tooltip 
      title={title}
      arrow
      placement='right'
      PopperProps={{ 
        disablePortal: true, 
        sx: { width: 'max-content' } 
      }}
      enterDelay={1000}
    >
      <Box>
        <IconButton
          data-testid={dataTestId}
          onClick={onClick}
          disableRipple 
          disabled={disabled} 
          sx={{
            borderRadius: curBorderRadius,
            padding: spaces.xs,
            border: `1px solid ${colors.neutral200}`,
            '&:hover': {
              boxShadow: boxShadows.elevation3,
              backgroundColor: colors.neutral0,
              svg: {
                fill: palette.text.primary,
              },
              borderColor: 'transparent',
            },
            '&:hover:active': {
              boxShadow: 'unset',
              backgroundColor: palette.text.selected,
              borderColor: 'transparent',
            },
            '&:focus-visible': {
              border: `1px solid ${palette.primary.main}`,
              svg: {
                fill: palette.text.primary,
              },
            },
            ...props.sx,
          }}>
          <SvgIcon component={icon} sx={{ fill: palette.text.secondary, filter: svgFilter }}/>
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export default ControlButton;
