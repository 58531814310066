import React from 'react';
import { Alert, Snackbar, AlertColor } from '@mui/material';

interface OwnProps {
    severity: AlertColor;
    message: string;
    isOpen: boolean;
    onCloseAlert: () => void;
}

const LernsetSnackbar: React.FC<OwnProps> = (props) => {
  const { message, isOpen, onCloseAlert, severity = 'success' } = props;

  return (
    <Snackbar
      open={isOpen}
      onClose={onCloseAlert}
      sx={{
        '&.MuiSnackbar-root': {
          left: '50%',
          transform: 'translateX(-50%)',
        },
      }}
      autoHideDuration={5000}
    >
      <Alert 
        severity={severity}
        variant='standard'
        color={severity}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default LernsetSnackbar;
