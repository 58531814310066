import React from 'react';
import { Address as PersonalAddress, InvoiceAddress } from '../../../../types/User';

type AddressFields = PersonalAddress & InvoiceAddress;

interface OwnProps {
  address: { [key in keyof AddressFields]?: string };
}

const Address: React.FC<OwnProps> = ({ address }) => {

  interface addressDisplayData {
    [key: string]: string | undefined;
  }

  const getAddressLine = (values: Array<string | undefined>) => {
    const existingValues = values.filter(value => value);
    return existingValues.join(' ');
  };

  const addressDisplayData: addressDisplayData = {
    name: getAddressLine([address.firstName, address.lastName]),
    institution: address.institution,
    street: address.street,
    zipAndCity: getAddressLine([address.areaCode, address.city]),
    country: address.country
  };

  return (
    <address>
      {Object.keys(addressDisplayData).map((key) => {
        return (
          <span key={key}>
            {addressDisplayData[key] &&
              <span data-testid='address__address-line'>
                {addressDisplayData[key]}
                <br />
              </span>
            }
          </span>
        );
      })}
    </address>
  );

};

export default Address;
