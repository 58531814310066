import { Instance, ViewState } from 'pspdfkit';

const pspdfEventListeners = {
  addCopyToClipboardListener: (instance: Instance, callback: (text: string) => void) => {
    // Documentation https://pspdfkit.com/api/web/PSPDFKit.Instance.html#getTextSelection
    instance.contentDocument.addEventListener('copy', () => {
      const textSelection = instance.getTextSelection();
      if (textSelection) {
        textSelection.getText().then((text: string) => {
          callback(text);
        });
      }
    });
  },
  addTextSelectionListener: (instance: Instance, callback: (text: string) => void) => {
    // Documentation https://pspdfkit.com/api/web/PSPDFKit.Instance.html#~TextSelectionChangeEvent
    instance.addEventListener('textSelection.change', (textSelection) => {
      if (textSelection) {
        textSelection.getText().then((text: string) => {
          callback(text);
        });
      }
    });
  },
  addSearchStateChangeListener: (instance: Instance, callback: (arg: boolean) => void) => {
    // Documentation https://pspdfkit.com/api/web/PSPDFKit.Instance.html#~ViewStateChangeEvent
    instance.addEventListener('viewState.change', (viewState: ViewState) => {
      const isChangedToSearch = viewState.interactionMode === window.PSPDFKit.InteractionMode.SEARCH;
      callback(isChangedToSearch);
    });
  }
};

export default pspdfEventListeners;
