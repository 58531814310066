import React, { useState, useEffect } from 'react';
import { withRouter, WithRouter } from 'found';
import GradientModalAppLayout from '../components/AppLayout/GradientModalLayout';
import ChangeEmailLoading from '../components/ChangeEmail/ChangeEmailLoading';
import services from '../services';
import ChangeEmailSuccess from '../components/ChangeEmail/ChangeEmailSuccess';
import ChangeEmailError from '../components/ChangeEmail/ChangeEmailError';

type ChangeEmailProps = WithRouter;

const ChangeEmailConfirm: React.FC<ChangeEmailProps> = (props) => {
  const { match: { location: { query: { token } } } } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    services.user.confirmNewEmail(token)
      .catch(() => setHasError(true))
      .finally(() => setIsLoading(false));
  }, [token]);

  return (
    <GradientModalAppLayout>
      <div className='m-5 h-100'>
        {isLoading && <ChangeEmailLoading />}
        {hasError && <ChangeEmailError />}
        {!isLoading && !hasError && <ChangeEmailSuccess />}
      </div>
    </GradientModalAppLayout>
  );
};

export default withRouter(ChangeEmailConfirm);
