import { UserResponseData } from '@klab-berlin/api-sdk/lib/types/responses/User';
import { Link, withRouter, WithRouter } from 'found';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import { routes } from '../constants';
import GradientModalAppLayout from '../components/AppLayout/GradientModalLayout';
import LoginGradientModalAppLayout from '../components/Login/LoginGradientAppLayout';
import services from '../services';
import { compose } from 'redux';
import Alert from 'react-bootstrap/Alert';
import * as yup from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CreateProfileFrame from '../components/CreateProfileFrame';
import { password, ascii } from '../validators';
import { useForm } from 'react-hook-form';
import FormInput from '../components/forms/FormInput';
import { login } from '../actions/auth.actions';
import { connect } from 'react-redux';
import { NameAndPasswordSubmitted } from '../ampli';

interface DispatchProps {
  login: typeof login;
}

interface CreateProfilePasswordPageProps extends WithRouter, DispatchProps {
  location: {
    query: {
      token: string;
    };
  };
}

interface CreatePasswordPayload {
  firstName: string;
  lastName?: string;
  password: string;
  passwordRepeat: string;
}

const createPasswordFormValidator = yup.object<CreatePasswordPayload>({
  firstName: ascii,
  lastName: ascii,
  password: password(),
  passwordRepeat: password()
    .oneOf([yup.ref('password')], 'Passwords must match'),
});

const CreateProfilePasswordPage: React.FC<CreateProfilePasswordPageProps> = (props) => {
  const { t } = useTranslation();
  const { token } = props.location.query;
  const [isValidToken, setValidToken] = useState<boolean | null>(null);
  const [isConfirmSuccessful, setConfirmSuccessful] = useState<boolean | null>(null);
  const {
    errors,
    handleSubmit,
    register,
    formState,
  } = useForm<CreatePasswordPayload>({ mode: 'onBlur', validationSchema: createPasswordFormValidator });

  useEffect(() => {
    services.user.getUserByToken({ optInToken: token })
      .then((user) => setValidToken(user.hasValidToken))
      .catch(() => setValidToken(false));
  }, [token]);

  if (isValidToken === null) {
    return (
      <GradientModalAppLayout>
        <div className='d-flex justify-content-center align-items-center h-100'>
          <Spinner animation='border' size='sm' className='mr-2'/>
          <b>{t('Loading')}...</b>
        </div>
      </GradientModalAppLayout>
    );
  }

  if (isValidToken === false) {
    return (
      <LoginGradientModalAppLayout title='Confirm email'>
        <Row>
          <Col className='text-center my-3'>
            {t('Your link is invalid or email address already confirmed')}
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col>
            <Col className='d-flex justify-content-between border-top py-4 px-0'>
              <Link to={`/${routes.login.root}`}>
                {t('Back to login')}
              </Link>
            </Col>
          </Col>
        </Row>
      </LoginGradientModalAppLayout>
    );
  }

  const onSubmit = handleSubmit(async (data: CreatePasswordPayload) => {
    setConfirmSuccessful(null);

    const payload = {
      data: {
        firstName: data.firstName,
        lastName: data.lastName,
      },
      password: data.password,
      passwordRepeat: data.passwordRepeat,
    };
   
    services.registration.confirm(token, payload)
      .then(async (result: UserResponseData) => {
        await props.login(result.email, data.password);
        services.track.ampliEventTrack({
          event: new NameAndPasswordSubmitted(), 
          userProperties: {
            id: result._id
          }
        });
        
        props.router.push(`/${routes.createProfile.root}/${routes.createProfileSubjects.root}`);
      })
      .catch(() => {
        setConfirmSuccessful(false);
      });
  });

  return (
    <GradientModalAppLayout>
      <CreateProfileFrame
        next={{
          action: onSubmit,
          disabled: formState.isSubmitting,
          text: t('Next'),
        }}
      >
        {isConfirmSuccessful === false && (
          <Alert variant='danger'>
            <Alert.Heading>{t('Ups, something went wrong')}</Alert.Heading>
            <p>{t('Please try to refresh the page or set your password again')}</p>
          </Alert>
        )}
        <fieldset disabled={formState.isSubmitting}>
          <Row>
            <Col className='my-3'>
              <h1>{t('What shall we call you?')}</h1>
            </Col>
          </Row>
          <Row>
            <Col className='my-2'>
              <FormInput
                className='form-control-lg rounded-pill'
                placeholder={t('First Name')}
                name='firstName'
                id='firstName'
                errors={errors}
                ref={register}
              />
            </Col>
          </Row>
          <Row>
            <Col className='my-2'>
              <FormInput
                className='form-control-lg rounded-pill'
                name='lastName'
                placeholder={t('Last Name')}
                id='lastName'
                errors={errors}
                ref={register}
              />
            </Col>
          </Row>
          <Row>
            <Col className='my-3'>
              <h1>{t('Set your password')}</h1>
            </Col>
          </Row>
          <Row>
            <Col className='my-2'>
              <FormInput
                className='form-control-lg rounded-pill'
                required={true}
                name='password'
                placeholder={t('Set Password')}
                id='password'
                type='password'
                errors={errors}
                ref={register}
              />
            </Col>
          </Row>
          <Row>
            <Col className='my-2'>
              <FormInput
                className='form-control-lg rounded-pill'
                required={true}
                name='passwordRepeat'
                placeholder={t('Repeat Password')}
                id='passwordRepeat'
                errors={errors}
                ref={register}
                type='password'
              />
            </Col>
          </Row>
        </fieldset>
      </CreateProfileFrame>
    </GradientModalAppLayout>
  );
};

const mapDispatch = {
  login,
};

export default compose<React.FC>(
  connect(undefined, mapDispatch),
  withRouter,
)(CreateProfilePasswordPage);
