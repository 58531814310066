import TagManager from 'react-gtm-module';
import React, { useContext } from 'react';
import constants from './../../constants';
import { CookiebotContext } from '../../state/CookiebotContextProvider';
import { isFeatureEnabled } from '../../utils/feature';

const tagManagerArgs = {
  gtmId: constants.gtmId,
};

const GoogleTagManager: React.FC = () => {
  const consent = useContext(CookiebotContext);

  if (isFeatureEnabled('googleAnalytics') && consent.statistics) {
    TagManager.initialize(tagManagerArgs);
  }

  return null;
};

export default GoogleTagManager;
