import React, { useEffect, useRef } from 'react';
import { studyflix } from '../../../../../constants';
import { useTranslation } from 'react-i18next';
import './videoPlayerStudyflix.scss';

interface VideoPlayerStudyflixProps {
  studyflixId: number;
  setIsReady?: (arg: boolean) => void;
}

const VideoPlayerStudyflix: React.FC<VideoPlayerStudyflixProps> = ({
  studyflixId, setIsReady
}) => {
  const containerRef = useRef<HTMLInputElement>(null);

  const renderStudyflixVideo = () => {
    const dataId = studyflixId.toString();

    const createScriptElement = (url: string) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = url;
      scriptElement.setAttribute('data-id', dataId);
      scriptElement.setAttribute('data-partner', studyflix.dataPartner);
      scriptElement.async = true;

      return scriptElement;
    };

    useEffect(() => {
      const studyFlixIframe = createScriptElement(studyflix.url);
      containerRef?.current?.appendChild(studyFlixIframe);

      if (setIsReady) {
        setIsReady(true);
      }

      return () => {
        // This removes the player on unmount
        if (containerRef?.current?.children[0]) containerRef.current.removeChild(containerRef.current.children[0]);
      };

    }, [dataId]);

    return (
      <div className='studyflix-player-container'>
        <div
          className='studyflix-player'
          data-testid='video-player'
          ref={containerRef}
        />
      </div>
    );
  };

  return <>
    {renderStudyflixVideo()}
  </>;
};

export const StudyflixError: React.FC<{ thumbnail: string }>= ({ thumbnail }) => {  
  const { t } = useTranslation();

  return <div className='studyflix-error'>
    <img
      src={thumbnail}
      className='studyflix-error__thumbnail'
      data-testid={'studyflix-error__thumbnail'} />
    <div className='studyflix-error__message'>
      { t('This video can only be played with a premium membership.') }
    </div>
  </div>;
};

export default VideoPlayerStudyflix;
