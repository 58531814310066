exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".custom-toast{right:0;bottom:0;position:absolute;z-index:5;max-width:480px}@media only screen and (max-width: 767px){.custom-toast{top:0;bottom:unset;z-index:5}.custom-toast__cancel-button{padding:0}.custom-toast__header{font-size:16px;line-height:24px;font-weight:bold;padding:8px 16px 8px 32px}}.custom-toast--mobile{top:0;bottom:unset;z-index:5}.custom-toast--mobile__cancel-button{padding:0}.custom-toast--mobile__header{font-size:16px;line-height:24px;font-weight:bold;padding:8px 16px 8px 32px}.custom-toast__cancel-button{color:#fff}.custom-toast__title{margin:0}.custom-toast__header{font-size:20px;line-height:32px;font-weight:bold;padding:16px 32px 16px 40px;background-color:#727e82;color:#fff;display:flex;flex-direction:row;align-items:center;justify-content:space-between}.custom-toast__body{font-size:16px;line-height:24px;font-weight:inherit;color:#2a2a2a;padding:16px 80px 24px 40px;background-color:#fff}", ""]);
// Exports
exports.locals = {
	"medium": "768px",
	"large": "1024px",
	"xlarge": "1440px",
	"collectionGridGap": "15px",
	"collectionGridItemHeight": "211px",
	"collectionGridItemWidth": "225px",
	"collectionListHeight": "84px",
	"greyLightest": "#f7f9fa",
	"greyLight2": "#d8d8d8",
	"neuVeryLightBlue": "#e9ebec",
	"muBlue": "#4298b5",
	"gridBreakPoints_-xs": "0",
	"gridBreakPoints_-sm": "576px",
	"gridBreakPoints_-md": "768px",
	"gridBreakPoints_-lg": "992px",
	"gridBreakPoints_-xl": "1440px"
};