import React, { useContext, useRef, useState } from 'react';
import Button from '../../common/Button';
import FormControl from 'react-bootstrap/FormControl';
import { useTranslation } from 'react-i18next';
import services from '../../../services';
import { trackingEvents } from '../../../services/tracking/trackConfig';
import { copyToClipboard } from '../../../utils';
import Modal from '../../common/Modal';
import { ReaderContext } from '../../Reader/ReaderContext';
import { WithPromptedTooltip } from '../../WithTooltip';
import './shareVideoModal.scss';

interface ShareVideoModalProps {
  publicVideoLink: string;
  show: boolean;
  onClose: () => void;
  showError: boolean;
}

const ShareVideoModal: React.FC<ShareVideoModalProps> = ({ publicVideoLink, show, showError, onClose }) => {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);
  const sharedLinkInput = useRef<HTMLInputElement>(null);
  const { mindItemId } = useContext(ReaderContext);

  const handleClose = () => {
    setIsCopied(false);
    onClose();
  };

  const handleCopyClick = () => {
    copyToClipboard(publicVideoLink);
    setIsCopied(true);
    services.track.eventTrack(trackingEvents.reader.videoCopyPublicLink, { other: { mind_item_id: mindItemId } });

    if (sharedLinkInput.current) {
      sharedLinkInput.current.select();
    }
  };

  return (
    <Modal
      className='share-video-modal'
      show={show}
      onClose={handleClose}
      title={t('Unlock for students')}
      body={
        <>
          {showError
            ? <div className='share-video-modal__error'>
              {t('Oops, the link could not be created. Please try it again or talk to us in chat')}
            </div>
            : <>
              <div className='share-video-modal__info'>
                {t('This link is active for 14 days')}
              </div>
              <div>Link</div>
              <WithPromptedTooltip
                text={t('Copied to clipboard')}
                show={isCopied}
                onClose={() => setIsCopied(false)}
                placement='top'
              >
                <FormControl
                  onClick={handleCopyClick}
                  readOnly={true}
                  value={publicVideoLink}
                  id={'shared-video-link-input'}
                  data-testid='shared-video-link-input'
                  ref={sharedLinkInput as React.RefObject<any>}
                  as='input'
                />
              </WithPromptedTooltip>
            </>
          }
        </>
      }
      footer={!showError && <Button variant='primary' onClick={handleCopyClick}>
        {t('Copy link')}
      </Button>
      }
    />
  );
};

export default ShareVideoModal;
