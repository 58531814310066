import './videoPlayer.scss';
import React, { useEffect, SyntheticEvent, useRef } from 'react';
import Player from '@vimeo/player';

export interface TimeUpdateProps {
  seconds: number;
  percent: number;
  duration: number;
}

interface VideoPlayerProps {
  url: string;
  onReady?: (value: boolean) => void;
  onPause?: (e: SyntheticEvent) => void;
  onPlay?: (e: SyntheticEvent) => void;
  onStreamed?: (data: TimeUpdateProps) => void;
}

const VideoPlayer: React.FC<VideoPlayerProps> = (props) => {
  const videoPlayer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const player = new Player('video-player', {
      url: props.url,
      responsive: true,
    });
    mountVideoPlayer(player);

    return () => {
      unmountVideoPlayer(player);
    };
  }, [props.url, props.onPause]);

  const mountVideoPlayer = (player: Player) => {

    props.onPause && player.on('pause', props.onPause);
    props.onPlay && player.on('play', props.onPlay);
    props.onStreamed && player.on('timeupdate', props.onStreamed);
    player.ready().then(() => {
      props.onReady && props.onReady(true);
    });
  };

  const unmountVideoPlayer = (player: Player) => {
    props.onReady && props.onReady(false);
    player.destroy().then(() => { 
      if (videoPlayer?.current?.children[0]) videoPlayer.current.removeChild(videoPlayer.current.children[0]);
    });
  };

  return <div className='w-100 video-player' id='video-player' data-testid='video-player' ref={videoPlayer}/>;
};

export default VideoPlayer;
