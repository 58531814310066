import React from 'react';
import { useTranslation } from 'react-i18next';
import OfferPoints from '../OfferPoints';
import Button from '../../../common/Button';
import './BasisCard.scss';
import { routes } from '../../../../constants';
import useRouter from '../../../../utils/useRouter';

const basisPoints = [
  'Only five downloads',
  'Documents with watermarks',
];
const basisNonPoints = [
  'Editable documents',
  'Videos and webinars',
  'New materials ever week'
];

const BasisCard: React.FC = () => {
  const { t } = useTranslation();
  const { router } = useRouter();

  const onButtonClick = () => {
    router.push(`/${routes.dashboard.root}`);
  };

  return (
    <div className='basis-card'>
      <div className='basis-card__header'>
        <div className='basis-card__title'>Basis</div>
        <div className='basis-card__big-subtitle'>{t('Free')}</div>
        <div>{t('For ever')}</div>
      </div>
      <div className='basis-card__content'>
        <OfferPoints points={basisPoints} areAvailable={true} />
        <OfferPoints points={basisNonPoints} areAvailable={false} />
      </div>
      <Button
        variant='subtle'
        onClick={onButtonClick}
        className='basis-card__button'>
        {t('Keep Basis')}
      </Button>
    </div>
  );
};

export default BasisCard;
