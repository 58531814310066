import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './successModal.scss';

const RegisterSuccessModal: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Modal show={true} backdrop='static' centered={true}>
      <div className='success-modal' data-testid='register-success-modal'>
        <h1 className='success-modal__emoji'>📬</h1>
        <h1>{t('Just one step')}</h1>
        <p className='success-modal__body'>
          {t('Your registration was successful. Just click on the link in your confirmation email to get started')}
        </p>
      </div>
    </Modal>
  );
};

export default RegisterSuccessModal;
