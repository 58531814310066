import React, { memo, useState } from 'react';
import { Typography, IconButton, Card, CardContent, Box, SvgIcon } from '@mui/material';
import trashIcon from '../../../assets/lernsets/icons/redTrash.svg';
import { Lernset } from '@klab-berlin/api-sdk/lib/types/responses/Lernsets';
import { borderRadius, boxShadows, palette, spaces } from '../../../assets/styles/themes/tokens';
import { colors } from '../../../assets/styles/themes/variables';
import Truncate from '../../Truncate';
import { useTranslation } from 'react-i18next';
import timeSinceLastUpdate from './timeSinceLastUpdate';

interface LernsetLessonCardProps {
    lernset: Lernset;
    onClick: () => void;
    onDelete: (id: string) => void;
}

interface LernsetFooterProps {
  partsLength: number;
  updatedDate: string;
}

const LernsetFooter: React.FC<LernsetFooterProps> = memo(({ partsLength, updatedDate }) => {
  const { t } = useTranslation();

  return (
    <Typography 
      variant='body1'
      sx={{ 
        color: palette.text.secondary,
        padding: `${spaces.sm} ${spaces.md}`,
      }}
      data-testid='lernset-lesson-card-description'
    >
      {partsLength} {t('Pages')} • {timeSinceLastUpdate(updatedDate)} {t('Updated (date)')}
    </Typography>
  );
});

const LernsetLessonCard = (props: LernsetLessonCardProps) => {
  const { lernset, onClick, onDelete } = props;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Card 
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: borderRadius.md,
        border: 1,
        borderColor: colors.neutral200,
        backgroundColor: colors.neutral0,
        width: 425,
        overflow: 'hidden',
        cursor: 'pointer',
        boxShadow: boxShadows.elevation1,
        '&:hover': {
          boxShadow: boxShadows.elevation3,
        },
      }}
      data-testid='lernset-lesson-card'
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <CardContent sx={{ 
        ':last-child': {
          paddingBottom: spaces.sm,
        },
        overflow: 'hidden',
        width: '100%',
        padding: `${spaces.sm} ${spaces.md}` 
      }}>
        <Box 
          display='flex' 
          flexDirection='row'
          alignItems='center'
          sx={{ overflow: 'hidden' }}
          data-testid={`lessonCard-${lernset.id}`} 
        >
          <Box 
            flex={1} 
            display='flex' 
            flexDirection='row' 
            sx={{ overflow: 'hidden' }}
          >
            <Typography 
              variant='h5' 
              sx={{ 
                color: palette.text.primary,
                padding: `${spaces.xs} ${spaces.xxs} ${spaces.xs} ${spaces.md}`,
              }}
              data-testid='lernset-lesson-card-title'
            >
              <Truncate lines={1} text={lernset.title} showTitle />
            </Typography>
          </Box>
          <Box flex={0} display='flex' flexDirection='row'>
            {isHovered && (
              <IconButton
                color='error'
                size='small' 
                sx={{
                  padding: spaces.xxs,
                  borderRadius: borderRadius.xs,
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  onDelete(lernset.id);
                }}
                data-testid='lernset-lesson-card-delete-button'
              >
                <SvgIcon component={trashIcon} />
              </IconButton>
            )}     
          </Box>
        </Box>
        <LernsetFooter partsLength={lernset.parts.length} updatedDate={lernset.updatedDate} />
      </CardContent>
    </Card>
  );
};

export default LernsetLessonCard;
