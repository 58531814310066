// @ts-nocheck
import { useState } from 'react';

// Set the name of the hidden property and the change event for visibility
let hidden:string;
let visibilityChange:string;
if (document.hidden !== undefined) { // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (document.mozHidden !== undefined) {
  hidden = 'mozHidden';
  visibilityChange = 'mozvisibilitychange';
} else if (document.msHidden !== undefined) {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (document.webkitHidden !== undefined) {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

export const useTabVisibility = () => {
  const [isVisible, setVisibility] = useState<boolean>(!document[hidden]);
  document.addEventListener(visibilityChange, () => setVisibility(!document[hidden]));
  return isVisible;
};
