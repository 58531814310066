import React, {  } from 'react';
import './searchPageContainer.scss';
import { useNewDesign } from '../../state/DesignPreferenceContextProvider';
import { palette, spaces } from '../../assets/styles/themes/tokens';
import { Box, Theme, useMediaQuery } from '@mui/material';

// This component is a container for the SearchPage. 
// It's a bit tricky because old and new design (with Material-UI) are mixed in the SearchPage.

export const getNewUiSearchPagePadding = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobile'));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between('mobile', 'tablet'));

  if (isMobile) return spaces.md;
  if (isTablet) return spaces.lg;
  return spaces.xl;
};

export const SearchPageContainer: React.FC<{ showNewFilters: boolean}> = (props) => {
  const { children, showNewFilters } = props;
  const { prefersNewDesign } = useNewDesign();
  const newUiStyles = {
    paddingBottom: spaces.xxxxl,
    paddingTop: getNewUiSearchPagePadding(),
    backgroundColor: palette.common.white,
    minHeight: 'calc(100vh - 48px)',  // 48px is $top-bar-height
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    gap: showNewFilters ? spaces.xl : spaces.md,
    display: 'flex',
    flexDirection: 'column',
  };

  if (prefersNewDesign) return <Box sx={newUiStyles}>{children}</Box>;
  return <div className='search-page-container'>{children}</div>;
};
