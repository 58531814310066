import { createTheme } from '@mui/material/styles';
import { spacing, palette, borderRadius, spaces, typography, getFontProps, boxShadows } from './tokens';
import { tooltipEnterDelay, tooltipLeaveDelay } from '../../../constants';

// Material UI theme documentation: https://mui.com/material-ui/customization/default-theme/
// Note that some properties do not work in theme component, but they do in Material-UI components.
// For example, the `paddingY` property is an invalid theme property, but it works in the `MuiButton` component.

const lernsetsTheme = createTheme({
  spacing,
  palette,
  typography,
  breakpoints: {
    values: {
      // Default MUI values:
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      // Custom values: (used in our design-system)
      mobile: 744,
      tablet: 1024,
      desktop: 1920,
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          textTransform: 'none',
          ...(ownerState.size === 'large' && {
            fontSize: '1rem',
            borderRadius: borderRadius.sm,
            padding: `${spaces.xs} ${spaces.lg}`
          }),
          ...(ownerState.size === 'large' && ownerState.variant === 'text' && {
            padding: `${spaces.xs} ${spaces.sm}`
          }),
          ...(ownerState.size === 'medium' && {
            fontSize: '0.875rem',
            borderRadius: borderRadius.xs,
            boxShadow: boxShadows.elevation4,
            '& .MuiButton-startIcon': { display: 'inline-block' }, 
          }),
          ...(ownerState.size === 'small' && {
            fontSize: '0.75rem',
            borderRadius: borderRadius.xs
          }),
          ...(ownerState.variant === 'text' && {
            boxShadow: 'none',
          }),
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: borderRadius.xs,
        },
        sizeLarge: {
          padding: spaces.sm,
        },
        sizeMedium: {
          padding: spaces.xs,
          width: '40px',
          height: '40px',
        },
        sizeSmall: {
          padding: spaces.xxs,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            borderRadius: borderRadius.sm,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          ...getFontProps('Borna, sans-serif', 600, '1.3rem')
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        enterDelay: tooltipEnterDelay,
        leaveDelay: tooltipLeaveDelay,
      }
    }
  },
});

export default lernsetsTheme;
