import { StoreType } from './index';
import { createSelector } from 'reselect';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import { MindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';

export const getMindItems = (state: StoreType) => state.mindItems.byId;

const getSiblings = (state: StoreType) =>
  state.mindItems.siblingsByProductItemId;

const getMindItem = (state: StoreType, mindItemId: string) =>
  state.mindItems.byId[mindItemId];

const getPublicMindItemAccess = (state: StoreType, accessToken: string) =>
  state.mindItems.byAccessToken[accessToken];

export const isMindItemInvalid = createSelector([getMindItem], (mindItem) => {
  if (!mindItem) return;

  return mindItem.invalid;
});

export const selectMindItem = createSelector([getMindItem], (mindItem) => {
  if (!mindItem) return;

  return mindItem.data;
});

export const selectPublicMindItem = createSelector(
  [getPublicMindItemAccess, getMindItems], (publicMindItem, mindItemList) => {
    if (!publicMindItem || publicMindItem.status !== AsyncActionStatus.READY || !publicMindItem.mindItem) {
      return;
    }
    return mindItemList[publicMindItem.mindItem]?.data;
  });

export const isPublicMindItemInvalid = createSelector(
  [getPublicMindItemAccess, getMindItems], (publicMindItem, mindItemList) => {
    if (publicMindItem?.status === AsyncActionStatus.NOTFOUND) return true;
    if (!publicMindItem?.mindItem) return;
    return mindItemList[publicMindItem.mindItem].invalid;
  }
);

interface SiblingQuery {
  id: string,
  type: 'product' | 'mindItem'
}

const getSiblingProductId = (state: StoreType, query: SiblingQuery) => {
  if (query.type === 'product') {
    return state.products.byId[query.id]?.data?.id;
  }
  else {
    return state.mindItems.byId[query.id]?.data?.product;
  }
};

export const selectSiblingsStatus = createSelector(
  [getSiblingProductId, getSiblings],
  (siblingProductId, siblingsObject) => {
    if (!siblingProductId) return AsyncActionStatus.LOADING;
    const mindItemSiblings = siblingsObject[siblingProductId] || {};
    return mindItemSiblings.status || AsyncActionStatus.LOADING;
  }
);

export const selectSiblings = createSelector(
  [getSiblingProductId, getSiblings, getMindItems],
  (siblingProductId, siblingsObject, allMindItems) => {
    if (!siblingProductId) return [];
    const mindItemSiblings = siblingsObject[siblingProductId];
    if (!mindItemSiblings || mindItemSiblings.status != AsyncActionStatus.READY) {
      return [];
    }
    return mindItemSiblings.siblings
      .map(
        (siblingId) => allMindItems[siblingId] && allMindItems[siblingId].data
      )
      .filter((v) => v) as MindItem[];
  }
);

type AdjacentSibling = { mindItem: MindItem; position: number } | undefined;

interface AdjacentSiblings {
  next: AdjacentSibling,
  previous: AdjacentSibling
}

const getMindItemBySiblingQuery = (state: StoreType, query: SiblingQuery) =>  state.mindItems.byId[query.id];

export const selectAdjacentSiblings = createSelector(
  [getMindItemBySiblingQuery, selectSiblings, getMindItems, selectSiblingsStatus],
  (mindItem, mindItemSiblings, allMindItems, siblingsStatus): AdjacentSiblings | undefined => {
    if (siblingsStatus !== AsyncActionStatus.READY) return;
    if (!mindItem || !mindItem.data) return;

    const siblingIndexOfCurrentMindItem = mindItemSiblings?.findIndex((sibling) => mindItem.data?.id === sibling.id);
    const previousSibling = allMindItems[mindItemSiblings[siblingIndexOfCurrentMindItem - 1]?.id]?.data;
    const nextSibling = allMindItems[mindItemSiblings[siblingIndexOfCurrentMindItem + 1]?.id]?.data;

    return {
      next: nextSibling && {
        mindItem: nextSibling,
        position: siblingIndexOfCurrentMindItem + 2
      },
      previous: previousSibling && {
        mindItem: previousSibling,
        position: siblingIndexOfCurrentMindItem
      }
    };
  }
);
