import React from 'react';
import { AsyncActionStatus } from '../../actions/common.actionTypes';
import { routes } from '../../constants';
import { CarouselTrackingProperties, CollectionProperties } from '../../types/AppContent';
import { SlideDefinition } from '../Carousel/Carousel';
import CarouselSkeleton from '../Carousel/CarouselSkeleton';
import DashboardCarousel from './DashboardCarousel';
import DashboardSlideCollection from './DashboardSlideCollection';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from '../../reducers/user.selectors';

interface OwnProps {
  carouselPosition: number;
  collections: CollectionProperties[];
  numberOfSlides: SlideDefinition;
  status: AsyncActionStatus;
}

const DashboardCuratedCollectionsCarousel: React.FC<OwnProps> = ({ collections, ...props }) => {
  const user = useSelector(selectUser);
  const { t } = useTranslation();

  const collectionIds = collections.map(collection => collection.id);
  const carouselTracking: CarouselTrackingProperties = {
    carouselSchoolTypes: user?.schoolType ? [user.schoolType] : undefined,
    carouselSubjectTexts: user?.subjectsFlattened.map(subject => subject.text),
    carouselDocumentIds: collectionIds,
    carouselPosition: props.carouselPosition,
    carouselType: 'recommendations',
  };

  return (
    <DashboardCarousel
      {...carouselTracking}
      title={t('New curated collections')}
      uri={`/${routes.curatedCollections.root}`}
      numberOfSlides={props.numberOfSlides}
      infinite={true}
      slides={collections.map(collection => (
        <DashboardSlideCollection
          {...carouselTracking}
          key={collection.id}
          collection={collection}
          linkRoot={routes.curatedCollections.root}
        />
      ))}
      isLoading={props.status === 'loading'}
      skeleton={
        <CarouselSkeleton
          height={210}
          numberOfSlides={props.numberOfSlides}
          width={260}
        />
      }
    />
  );
};

export default React.memo(DashboardCuratedCollectionsCarousel);
