import Icon from './Icon';
import React from 'react';
import { collectionsSettings } from '../constants';

interface CollectionIconProps {
  className?: string;
  color?: string;
}

const CollectionIcon: React.FC<CollectionIconProps> = (
  {
    className = '',
    color = collectionsSettings.colors.default,
  }
) => {
  return (
    <Icon
      icon='kollektion-tag'
      className={`collection__color--default collection__color--${color} ${className}`}
    />
  );
};

export default CollectionIcon;
