import {
  Product,
  CollectionProduct,
  AttachmentsData,
} from '@klab-berlin/api-sdk/lib/types/responses/Product';
import immutable from 'object-path-immutable';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import {
  FETCH_PRODUCTS_BY_QUERY,
  FetchProductsByQueryAction,
  GET_PRODUCT,
  GetProductAction,
  GetAttachmentsAction,
  GET_ATTACHMENTS,
} from '../actions/product.actionTypes';
import {
  FETCH_COLLECTION_CONTENTS,
  FetchCollectionContentsAction,
} from '../actions/collections.actionTypes';
import { FetchDownloadContentsAction } from '../actions/download.actionTypes';
import { arrayToObject, filteredArrayToObject } from '../utils/arrayToObject';
import {
  FETCH_PINNED_CONTENTS,
  FetchPinnedContentsAction,
  PinProductAction,
  UnpinDocumentAction,
  UNPIN_DOCUMENT,
  PIN_PRODUCT,
} from '../actions/pinned.actionTypes';
import {
  FETCH_USER_PRODUCT_RECOMMENDATIONS,
  FetchUserProductRecommendationsAction
} from '../actions/recommendation.actionTypes';
import { FETCH_DOWNLOAD_CONTENTS } from '../actions/download.actionTypes';
import { LogoutAction, LOGOUT } from '../actions/auth.actionTypes';
import { StoreItem } from '.';

type ProductActionTypes =
  | FetchPinnedContentsAction
  | FetchUserProductRecommendationsAction
  | FetchDownloadContentsAction
  | FetchCollectionContentsAction
  | PinProductAction
  | UnpinDocumentAction
  | FetchProductsByQueryAction
  | GetProductAction
  | GetAttachmentsAction
  | LogoutAction;

export interface ProductState {
  byId: {
    [key: string]: StoreItem<Product>;
  };
  attachmentsByProductId: {
    [key: string]: StoreItem<AttachmentsData>;
  };
}

const initialState: ProductState = {
  byId: {},
  attachmentsByProductId: {}
};

const products = (
  state = initialState,
  action: ProductActionTypes
): ProductState => {
  switch (action.type) {
    case LOGOUT.SUCCESS:
      return initialState;

    case GET_PRODUCT.START: {
      return immutable(state)
        .set(['byId', action.payload.productId, 'status'], AsyncActionStatus.LOADING)
        .value();
    }
    case GET_PRODUCT.SUCCESS: {
      const productId = action.payload.productId;
      return immutable(state)
        .set(['byId', productId, 'data'], action.result)
        .set(['byId', productId, 'status'], AsyncActionStatus.READY)
        .value();
    }
    case GET_PRODUCT.ERROR: {
      return immutable.merge(state, `byId.${action.payload.productId}`, {
        error: action.error.message,
        status: 'error',
      });
    }

    case FETCH_PRODUCTS_BY_QUERY.SUCCESS: {
      const fetchedProducts = arrayToObject(
        action.result.map((r) => ({
          data: r,
          status: AsyncActionStatus.READY,
        })),
        (v) => v.data.id
      );

      return immutable(state).merge('byId', fetchedProducts).value();
    }

    case FETCH_PINNED_CONTENTS.SUCCESS:
    case FETCH_COLLECTION_CONTENTS.SUCCESS:
    case FETCH_DOWNLOAD_CONTENTS.SUCCESS: {
      const fetchedProducts = filteredArrayToObject(
        action.result.data.map(item => ({
          data: item as CollectionProduct,
          status: AsyncActionStatus.READY,
        })),
        (item) => item.data.id,
        (item) => item.data.type === 'product'
      );

      return immutable(state).merge('byId', fetchedProducts).value();
    }

    case FETCH_USER_PRODUCT_RECOMMENDATIONS.SUCCESS: {
      const fetchedProducts = filteredArrayToObject(
        action.result.map(item => ({
          data: item as CollectionProduct,
          status: AsyncActionStatus.READY,
        })),
        (item) => item.data.id,
        (item) => item.data.type === 'product'
      );

      return immutable(state).merge('byId', fetchedProducts).value();
    }

    case PIN_PRODUCT.START:
    case UNPIN_DOCUMENT.ERROR: {
      if (action.payload.type === 'mindItem' || !state.byId[action.payload.id]) {
        return state;
      }
      const newlyPinnedItem = { ...state.byId[action.payload.id].data, isPinned: true };
      return immutable(state)
        .merge(['byId', action.payload.id, 'data'], newlyPinnedItem)
        .value();
    }

    case PIN_PRODUCT.SUCCESS: {
      return immutable(state)
        .merge(['byId', action.payload.id, 'data'], action.result)
        .set(['byId', action.payload.id, 'status'], AsyncActionStatus.READY)
        .value();
    }

    case PIN_PRODUCT.ERROR:
    case UNPIN_DOCUMENT.START: {
      if (action.payload.type === 'mindItem' || !state.byId[action.payload.id]) {
        return state;
      }
      const newlyUnpinnedItem = { ...state.byId[action.payload.id].data, isPinned: false };
      return immutable(state)
        .merge(['byId', action.payload.id, 'data'], newlyUnpinnedItem)
        .value();
    }

    case GET_ATTACHMENTS.START: {
      return immutable(state)
        .set(['attachmentsByProductId',action.payload.productId, 'status'], AsyncActionStatus.LOADING)
        .value();
    }
    case GET_ATTACHMENTS.SUCCESS: {
      const productId = action.payload.productId;
      return immutable(state)
        .set(['attachmentsByProductId', productId, 'data'], action.result)
        .set(['attachmentsByProductId', productId, 'status'], AsyncActionStatus.READY)
        .value();
    }
    case GET_ATTACHMENTS.ERROR: {
      return immutable.merge(state, `attachmentsByProductId.${action.payload.productId}`, {
        error: action.error.message,
        status: 'error',
      });
    }

  }
  return state;
};

export default products;
