import React from 'react';
import { routes } from '../../constants';
import { useTranslation } from 'react-i18next';
import useRouter from '../../utils/useRouter';
import Button, { ButtonVariant } from '../common/Button';
import { selectUser, getSubscription } from '../../reducers/user.selectors';
import { useSelector } from 'react-redux';
import services from '../../services';

interface PremiumUpgradeButtonProps {
  onClick?: () => void;
  className?: string;
  variant?: ButtonVariant;
  conversionPath?: string;
}

const PremiumUpgradeButton: React.FC<PremiumUpgradeButtonProps> = ({ className, onClick, variant, conversionPath }) => {
  const { t } = useTranslation();
  const { router } = useRouter();
  const user = useSelector(selectUser);
  const subscription = useSelector(getSubscription);

  const handleClick = () => {
    if (user && conversionPath) {
      services.track.ampliTrackPricingPlansViewedEvent(
        conversionPath, 
        subscription?.status
      );  
    }

    if (onClick) onClick();
    router.push(`/${routes.upgradePackageSelection.root}`);
  };
  return (
    <Button
      icon='premium'
      variant={variant}
      onClick={handleClick}
      type='button'
      className={className}>
      {t('Unlock Premium')}
    </Button>
  );
};

export default PremiumUpgradeButton;
