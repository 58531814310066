import React from 'react';
import { useTranslation } from 'react-i18next';
import { routes } from '../../../constants';
import useRouter from '../../../utils/useRouter';
import { IconName } from '../../Icon';
import ScreenStepContainer, { ScreenStepProps } from '../ScreenModal/ScreenStepContainer';
import '../ScreenModal/ScreenStepContainer.scss';

const TrialCancellationStepFinishCancelling:React.FC<ScreenStepProps> = (props) => {
  const { router } = useRouter();
  const { t } = useTranslation();
  const goToSearchButton = {
    action: () => {
      props.onClose();
      router.push(`/${routes.search.root}`);
    },
    title: t('Go to Search next'),
    icon: 'search' as IconName,
  };
  return (
    <ScreenStepContainer
      trackingId='finishCancelling'
      title={t('Trial Cancelled')}
      primaryButton={goToSearchButton}
      onClose={props.onClose}
    >
      <p>{t('You can enjoy your premium benefits until the end of your trial')}</p>
      <div className='screen-step__img screen-step__park-bench-small' />
    </ScreenStepContainer>
  );
};

export default TrialCancellationStepFinishCancelling;
