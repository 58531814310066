import { SortByOption } from '@klab-berlin/api-sdk/lib/types/requests/Common';
import { ContentType } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import * as yup from 'yup';
import { searchSettings } from '../../constants';
import { SearchFilters } from '../../types/search';

// Note, yup has an `ensure` function that uses this behavior, however
// it also has the non optional side effect of converting undefined to
// an empty array, which then creates problems
const stringArray = () => yup.array().of(yup.string())
  .transform((value, originalValue) => {
    if (originalValue && !Array.isArray(originalValue)) {
      return [originalValue];
    }
    return value;
  });

const stringValue = <T extends string = string>(values?: T[], defaultValue?: T) => {
  let stringSchema = values ? yup.string().oneOf(values) : yup.string();
  if (defaultValue) {
    stringSchema = stringSchema.default(defaultValue);
  }
  return yup.lazy(value => value === null ? yup.mixed().strip(true) : stringSchema) as yup.Schema<T>;
};

const searchQuerySchema = yup.object<SearchFilters>({
  contentType: stringValue<ContentType>(['document', 'video']),
  learningFormat: stringArray(),
  maxClassYear: yup.number(),
  minClassYear: yup.number(),
  publisher: stringArray(),
  schoolType: stringArray(),
  subject: stringArray(),
  subTopic: stringArray(),
  sortBy: stringValue<SortByOption>(['relevance', 'createdDate'], searchSettings.defaultSortBy),
  teachingFormat: stringArray(),
  term: stringValue(),
  workload: stringArray(),
}).noUnknown(true);

export default searchQuerySchema;
