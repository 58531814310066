import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import ControlButton from '../ControlButton';
import trashIcon from '../../../assets/lernsets/icons/trash.svg';
import arrowUp from '../../../assets/lernsets/icons/arrow-up.svg';
import arrowDown from '../../../assets/lernsets/icons/arrow-down.svg';
import editIcon from '../../../assets/lernsets/icons/edit.svg';
import duplicateIcon from '../../../assets/lernsets/icons/duplicate.svg';
import moreIcon from '../../../assets/lernsets/icons/more.svg';
import rotateIcon from '../../../assets/lernsets/icons/rotate.svg';
import { spaces } from '../../../assets/styles/themes/tokens';
import ControlButtonWithMenu from '../ControlButtonWithMenu';
import { OnboardingHint } from '../../OnboardingHint';
import { onboardingHints } from '../../../constants';
import { isFeatureEnabled } from '../../../utils/feature';
import { PartType } from '@klab-berlin/api-sdk/lib/types/responses/Lernsets';

export type DisabledArrowStatus = 'up' | 'down' | 'both' | undefined;
export type ArrowDirection = 'up' | 'down';

interface OwnProps {
  disabledArrow?: DisabledArrowStatus;
  disabled?: boolean;
  onEditIconClick: () => void;
  onArrowClick: (direction: ArrowDirection) => void;
  onTrashIconClick: () => void;
  onDuplicateIconClick: () => void;
  onRotateIconClick: () => void;
  onGenerateWorksheetIconClick: () => void;
  onAddRichTextPartIconClick: () => void;
  pageCardType: PartType;
  shouldShowHints?: boolean;
}

const PageCardControls: React.FC<OwnProps> = (props) => {
  const { t } = useTranslation();
  const { 
    disabledArrow, 
    disabled, 
    onArrowClick, 
    onTrashIconClick, 
    onEditIconClick, 
    onDuplicateIconClick,
    onRotateIconClick,
    onGenerateWorksheetIconClick,
    onAddRichTextPartIconClick,
    pageCardType,
    shouldShowHints = false,
  } = props;

  const menuItemsData = useMemo(() => {
    const baseItemActions = [
      {
        icon: trashIcon,
        text: t('Delete'),
        onClick: onTrashIconClick,
        dataTestId: 'card-control__delete',
      }
    ];

    const pdfItemActions = [
      {
        icon: duplicateIcon,
        text: t('Duplicate'),
        onClick: onDuplicateIconClick,
        dataTestId: 'card-control__duplicate',
      },
      {
        icon: rotateIcon,
        text: t('Rotate'),
        onClick: onRotateIconClick,
        dataTestId: 'card-control__rotate',
      },
    ];

    return pageCardType === 'pdf' ? [...baseItemActions, ...pdfItemActions] : baseItemActions;
  }, [pageCardType, onDuplicateIconClick, onRotateIconClick, onTrashIconClick]);

  if (isFeatureEnabled('generateLernsetWorksheet')) {
    menuItemsData.push({
      icon: editIcon,
      text: 'Generate worksheet',
      onClick: onGenerateWorksheetIconClick,
      dataTestId:  'card-control__generate-worksheet',
    });
  }

  if (isFeatureEnabled('addRichTextPart')) {
    menuItemsData.push({
      icon: duplicateIcon,
      text: 'Rich text document',
      onClick: onAddRichTextPartIconClick,
      dataTestId:  'card-control__add-rich-text-part'
    });
  }

  const renderEditButton = (options: { withOnboardingHint: boolean }) => {
    const editButton  = (
      <ControlButton 
        title={t('Edit')}
        dataTestId='card-control__edit'
        icon={editIcon} 
        onClick={onEditIconClick}
        disabled={disabled}
        sx={{ 
          marginBottom: spaces.sm,
        }}
      />
    );

    return options.withOnboardingHint ? (
      <OnboardingHint
        id={onboardingHints.lernsetsOverviewEdit}
        placement='bottom' 
        description={t('Adapt this page to your needs.')}
        maxWidth={280}
        disablePortal={true}
      >
        <Box>
          {editButton}
        </Box>
      </OnboardingHint>
    ) : editButton;
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
    }}>
      <ControlButtonWithMenu
        controlButtonProps={{
          title: t('More'),
          dataTestId: 'card-control__more',
          icon: moreIcon,
          disabled,
          sx:{ 
            marginBottom: spaces.sm,
          }
        }}
        menuItemsData={menuItemsData}
      />
      <ControlButton icon={arrowUp}
        title={t('Move up')}
        dataTestId='card-control__move-up'
        onClick={() => onArrowClick('up')}
        borderStyle='rounded-top'
        disabled={['up', 'both'].includes(disabledArrow || '') || disabled} />
      <ControlButton icon={arrowDown}
        title={t('Move down')}
        dataTestId='card-control__move-down'
        onClick={() => onArrowClick('down')}
        borderStyle='rounded-bottom'
        disabled={['down', 'both'].includes(disabledArrow || '') || disabled}
        sx={{ 
          marginBottom: spaces.sm,
        }} />
      {pageCardType === 'pdf' && renderEditButton({ withOnboardingHint: shouldShowHints })}
    </Box>
  );
};

export default PageCardControls;
