import React, { useState } from 'react';
import UpgradeDetailsModal from '../components/modals/UpgradeDetailsModal';

type ShowFunc = () => void;
const noop = () => undefined;

interface Context {
  isOpen: boolean;
  show: ShowFunc;
}

const defaultContext: Context = {
  isOpen: false,
  show: noop as ShowFunc,
};

const UpgradeDetailsModalContext = React.createContext(defaultContext);

const UpgradeDetailsModalProvider: React.FC = (props) => {

  const setModalVisibility = (open: boolean) => {
    setModalState((prevState) => {
      return {
        ...prevState,
        isOpen: open,
      };
    });
  };

  const defaultState = Object.assign(defaultContext, {
    show: () => setModalVisibility(true)
  });

  const [modalState, setModalState] = useState(defaultState);

  return (
    <UpgradeDetailsModalContext.Provider value={modalState}>
      <UpgradeDetailsModal
        show={modalState.isOpen}
        onClose={() => setModalVisibility(false)}
      />
      {props.children}
    </UpgradeDetailsModalContext.Provider>
  );
};

export { UpgradeDetailsModalContext, UpgradeDetailsModalProvider };
