import { LoginAction, LOGOUT, LogoutAction, LOGIN } from './auth.actionTypes';
import { ThunkAction } from './common.actionTypes';
import authService from '../services/auth.service';

export function loginFB(facebookId: string, facebookToken: string, tracking: {
  formCode?: string;
  lastUrl?: string;
}): ThunkAction<LoginAction> {
  return async (dispatch) => {
    const payload = { type: 'facebook' as const, facebookId };
    dispatch({ type: LOGIN.START, payload });

    return authService.loginFB(facebookId, facebookToken, tracking)
      .then((result) => dispatch({ type: LOGIN.SUCCESS, payload, result }))
      .catch((error) => { dispatch({ type: LOGIN.ERROR, payload, error }); throw error; });
  };
}

export function login(username: string, password: string): ThunkAction<LoginAction> {
  return async (dispatch) => {
    const payload = { type: 'password' as const, username, password };
    dispatch({ type: LOGIN.START, payload });

    return authService.login(username, password)
      .then((result) => dispatch({ type: LOGIN.SUCCESS, payload, result }))
      .catch((error) => { dispatch({ type: LOGIN.ERROR, payload, error }); throw error; });
  };
}

export function logout(): ThunkAction<LogoutAction> {
  return async (dispatch) => {
    dispatch({ type: LOGOUT.START });

    return authService.logout()
      .then(() => dispatch({ type: LOGOUT.SUCCESS, result: undefined }))
      .catch((error) => dispatch({ type: LOGOUT.ERROR, error }));
  };
}
