import { Link } from 'found';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import LoginGradientModalAppLayout from '../components/Login/LoginGradientAppLayout';
import { routes, emailAddresses } from '../constants';
import appContext from '../services/appContext.service';
import TeacherWithBlob from '../assets/TeacherWithBlob.svg';

const ResetPasswordConfirmedPage: React.FC = () => {
  const { t } = useTranslation();
  const email = appContext.getResetPasswordEmail();

  const footer = (
    <a
      className='link--white'
      href={`mailto:${emailAddresses.support}`}>
      {t('Contact support')}
    </a>
  );

  return (
    <LoginGradientModalAppLayout title='E-Mail sent' footer={footer}>
      <Row>
        <Col className='text-center my-3'>
          <p>{t('The link to reset was sent to this address')}</p>
          <p>
            {t(
              'Please contact support, if you did not get an email after multiple attempts'
            )}
          </p>
        </Col>
      </Row>
      <Row>
        <Col className='text-center my-3 color--grey-dark'>
          <h3>{email}</h3>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex justify-content-center reset-pwd-confirmed-img'>
          <TeacherWithBlob className='h-100 w-100' />
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col className='d-flex border-top'>
          <Col className='col-6 d-flex justify-content-start py-4 px-0'>
            <Link to={`/${routes.login.root}`}>{t('Back to login')}</Link>
          </Col>
          {email && (
            <Col className='col-6 d-flex justify-content-end py-4 px-0 text-right cursor-pointer color--mu-blue'>
              <Link to={`/${routes.resetPassword.root}`}>
                {t('Resend reset link')}
              </Link>
            </Col>
          )}
        </Col>
      </Row>
    </LoginGradientModalAppLayout>
  );
};

export default ResetPasswordConfirmedPage;
