import { StarterKit } from '@tiptap/starter-kit';
import { Placeholder } from '@tiptap/extension-placeholder';
import { Underline } from '@tiptap/extension-underline';
import { CodeBlockLowlight } from '@tiptap/extension-code-block-lowlight';
import { TextAlign } from '@tiptap/extension-text-align';
import { lowlight } from 'lowlight';
import { Heading } from '@tiptap/extension-heading';
import { TaskItem } from '@tiptap/extension-task-item';
import { TaskList } from '@tiptap/extension-task-list';

export const TipTapExtensionKit = [
  StarterKit.configure({
    codeBlock: false,
    heading: false,
  }), 
  Underline,
  Placeholder.configure({
    placeholder: 'Click here to start writing...',
  }),
  CodeBlockLowlight.configure({
    lowlight,
  }),
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Heading.configure({
    levels: [1, 2, 3],
  }),
  TaskList,
  TaskItem.configure({
    nested: true,
  }),
];

export default TipTapExtensionKit;
