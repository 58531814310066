import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import ModalBody from '../common/ModalBody';
import ModalToggleButton from '../common/ModalToggleButton';
import { CustomSelectDefaultProps, CustomSelectOptionValueType } from '../Select';
import ModalSelectOption from './ModalSelectOption';

const ModalSelect = <T extends CustomSelectOptionValueType,>(props: CustomSelectDefaultProps<T>) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState<typeof props.value>([]);
  const [isButtonsEnabled, setIsButtonsEnabled] = useState(false);

  useEffect(() => {
    setSelectedValues(props.value);
  }, [props.value]);

  useEffect(() => {
    setIsButtonsEnabled(buttonsEnabled());
  }, [props.value, selectedValues]);

  const handleSelectionConfirm = () => {
    if (!selectedValues) {
      return;
    }
    setModalOpen(false);
    props.onSelectionConfirm?.(selectedValues);
  };

  const handleSelectionReset = () => {
    setModalOpen(false);
    if (props.defaultValue) {
      setSelectedValues(props.defaultValue);
    } else {
      setSelectedValues([]);
    }
    props.onSelectionClear?.();
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedValues(props.value);
  };

  const toggleSelection = (value: T) => {
    props.onItemClick && props.onItemClick(value.toString());
    if (!props.multi) {
      setSelectedValues([value]);
    } else {
      const values = [...selectedValues || []];
      const index = values.indexOf(value);
      
      if (index !== -1) {
        values.splice(index, 1);
      } else {
        values.push(value);
      }
  
      setSelectedValues(values);
    }

    if (!props.hasButtons) { 
      setModalOpen(false);
    }
  };

  const isSelectedValue = (value: T) => {
    return !!selectedValues && selectedValues.includes(value);
  };

  const getToggleButtonLabel = () => {
    if (props.alwaysShowLabel || !selectedValues || selectedValues.length === 0) {
      let label = props.label;
      if (props.multi && selectedValues && selectedValues.length > 0) {
        label = `${label} (${selectedValues.length})`;
      }
      return label;
    }

    const currentItems = selectedValues.map(value => {
      return props.options.find(opt => opt.value === value);
    });

    const labels = currentItems?.map(item => item?.name).join(', ');
    return labels;
  };

  const buttonsEnabled = () => {
    if (!props.value && (!selectedValues || selectedValues.length === 0)) {
      return false;
    }

    return true;
  };

  const handleToggleButtonClick = () => {
    setModalOpen(true);
    props.onToggle && props.onToggle();
  };

  return (
    <div className='w-100 d-flex flex-column'>
      <ModalToggleButton
        label={getToggleButtonLabel()}
        icon={props.icon}
        onClick={handleToggleButtonClick}
        disabled={props.disabled}
        selected={!!props.value}
      />
      <label className={classnames('color--grey-light pt-1 align-self-end font-size-12',
        { 'sr-only': !props.showLabel })}
      >
        {props.label}
      </label>
      <ModalBody
        title={props.label}
        modalOpen={modalOpen}
        onModalClose={closeModal}
        enableSaveButton={isButtonsEnabled}
        showResetButton={isButtonsEnabled}
        onSave={handleSelectionConfirm}
        onReset={handleSelectionReset}
        hasButtons={props.hasButtons}
      >
        {props.options.map((option) => <ModalSelectOption
          key={option.value}
          label={option.name}
          selected={isSelectedValue(option.value)}
          onClick={() => toggleSelection(option.value)}
        />)}
      </ModalBody>
    </div>
  );
};

export default ModalSelect;
