import immutable from 'object-path-immutable';
import {
  FETCH_PRODUCTS_BY_QUERY,
  FetchProductsByQueryAction,
} from '../actions/product.actionTypes';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import { LOGOUT, LogoutAction } from '../actions/auth.actionTypes';

type ActionTypes = FetchProductsByQueryAction | LogoutAction;

export interface DashboardInitialStateType {
  productsBySubjectId: {
    [key: string]: {
      ids: string[];
      error?: string;
      status: AsyncActionStatus;
    };
  };
}

const initialState: DashboardInitialStateType = {
  productsBySubjectId: {}
};

const collections = (state = initialState, action: ActionTypes): DashboardInitialStateType => {
  switch (action.type) {
    case LOGOUT.SUCCESS:
      return initialState;

    case FETCH_PRODUCTS_BY_QUERY.START: {
      const { query } = action.payload;
      if (!query.subjects || query.subjects.length === 0) {
        return state;
      }

      let key: string = query.subjects.join(',');
      if (query.keyPrefix) {
        key = `${query.keyPrefix}-${key}`;
      }

      return immutable(state)
        .set(['productsBySubjectId', key, 'status'], AsyncActionStatus.LOADING)
        .set(['productsBySubjectId', key, 'ids'], [])
        .value();
    }
    case FETCH_PRODUCTS_BY_QUERY.ERROR: {
      const { query } = action.payload;
      if (!query.subjects || query.subjects.length === 0) {
        return state;
      }

      let key: string  = query.subjects.join(',');
      if (query.keyPrefix) {
        key = `${query.keyPrefix}-${key}`;
      }

      return immutable(state)
        .set(['productsBySubjectId', key, 'status'], AsyncActionStatus.ERROR)
        .value();
    }
    case FETCH_PRODUCTS_BY_QUERY.SUCCESS: {
      const { query } = action.payload;
      if (!query.subjects || query.subjects.length === 0) {
        return state;
      }
      
      let key: string  = query.subjects.join(',');
      if (query.keyPrefix) {
        key = `${query.keyPrefix}-${key}`;
      }

      return immutable(state)
        .set(['productsBySubjectId', key, 'status'], AsyncActionStatus.READY)
        .set(['productsBySubjectId', key, 'ids'], action.result.map(r => r.id))
        .value();
    }
  }

  return state;
};

export default collections;
