import React from 'react';
import InputLabel from '../InputLabel';
import './labeledText.scss';

interface LabeledTextProps {
  label: string;
  text: string | React.ReactNode;
  id: string;
}

const LabeledText: React.FC<LabeledTextProps> = ((props) => {
  return (
    <div className='labeled-text' aria-labelledby={props.id}>
      <InputLabel id={props.id} >{props.label}</InputLabel>
      <div data-testid='labeled-text__text' className='labeled-text__text'>{props.text}</div>
    </div>
  );
});

export default LabeledText;
