import { format, getTime, isValid, parse, toDate } from 'date-fns';
import numeral from 'numeral';
import 'numeral/locales/de';
import { dateFormat } from '../constants';

export const currencyFormat = (number: number, hideCurrency?: boolean) => {
  numeral.locale('de');
  if (hideCurrency) {
    return numeral(number).format('0.00');
  }
  return numeral(number).format('0[.]00 $');
};

export const isGermanDateString = (date: string) => /[0-9]{2}\.[0-9]{2}\.[0-9]{4}/.test(date);

export const getDateInGermanFormat = (date: string | number | Date): string | undefined => {
  if (typeof date === 'string' && isGermanDateString(date)) return date;
  const inputDate = typeof date === 'string' ? new Date(date) : toDate(date);
  if (!isValid(inputDate)) return undefined;
  return format(inputDate, dateFormat);
};

export const getTimestampFromGermanDate = (date: string): number | undefined => {
  if (!isGermanDateString(date)) return undefined;
  const germanDate = parse(date, dateFormat, new Date());
  if (!isValid(germanDate)) return undefined;
  return getTime(germanDate);
};

