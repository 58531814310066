/**
 * Returns an array of unique objects.
 *
 * @param array - array of object to unique
 * @param getter - a function that returns a unique string from an object
 */
export function uniqueArrayOfObjects<T extends Record<string, unknown>>(array: T[], getter: (item: T) => string): T[] {
  const arrayToReturn: T[] = [];
  const itemsInArray: { [key: string]: boolean } = {};

  for (const item of array) {
    if (!item) {
      continue;
    }

    const objectUniqueValue = getter(item);

    if (itemsInArray[objectUniqueValue]) {
      continue;
    }

    arrayToReturn.push(item);
    itemsInArray[objectUniqueValue] = true;
  }

  return arrayToReturn;
}

export function uniqueArray<T extends string | number>(array: T[]): T[] {
  const set = new Set(array);
  return [...set];
}
