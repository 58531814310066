import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import classnames from 'classnames';
import Icon from './Icon';

interface Props {
  items: { id: string; item: React.ReactNode }[];
  className?: string;
  listId: string;
  testId?: string
}

const DnDList: React.FC<Props> = ({ items, listId, className, testId }) => (
  <Droppable droppableId={listId}>
    {(provided, snapshot) => (<div
      {...provided.droppableProps}
      ref={provided.innerRef}
      className={classnames(className, { 'bg-light': snapshot.isDraggingOver })}
      data-testid={testId}
    >
      {items
        .map((item, i) => {
          return <Draggable key={item.id} draggableId={item.id} index={i}>
            {(provided, snapshot) => {
              return (
                <div
                  className={classnames('d-flex align-items-center', { 'opacity-50': snapshot.isDragging })}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <Icon icon='grip-lines' size='lg' className='mr-3 color--grey-medium'/>
                  {item.item}
                </div>
              );
            }}
          </Draggable>;
        })}
      {provided.placeholder}
    </div>
    )}
  </Droppable>
);

export default DnDList;
