import React, { useCallback, useEffect, useState } from 'react';
import LoginGradientModalAppLayout from '../components/Login/LoginGradientAppLayout';
import SetPassword from '../components/Login/SetPassword';
import { withRouter, WithRouter, Link } from 'found';
import { routes } from '../constants';
import services from '../services';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { login } from '../actions/auth.actions';
import { connect } from 'react-redux';
import RedirectIfLoggedIn from '../router/redirect/RedirectIfLoggedIn';

interface DispatchProps {
  login: typeof login;
}

interface SetPasswordPageProps extends WithRouter, DispatchProps {
  location: {
    query: {
      token?: string;
    };
  };
}

const SetPasswordPage: React.FC<SetPasswordPageProps> = (props) => {
  const [isValidToken, setValidToken] = useState<boolean | null>(null);
  const { t } = useTranslation();
  const { token } = props.location.query;

  useEffect(() => {
    if (!token) {
      setValidToken(false);
      return;
    }
    services.user.getUserByToken({ resetPasswordToken: token })
      .then((user) => setValidToken(user.hasValidToken))
      .catch((user) => setValidToken(user.hasValidToken));
  }, [token]);

  const handleSetPasswordSuccess = useCallback(async (email: string, password: string) => {
    await props.login(email, password);
    props.router.push(`/${routes.login.root}`);
  }, [props.router]);

  return (
    <RedirectIfLoggedIn>
      <LoginGradientModalAppLayout title='Set Password'>
        {isValidToken === null && (
          <>
            <Row>
              <Col className='text-center my-3'>
                <Spinner animation='border' />
              </Col>
            </Row>
          </>
        )}
        {!isValidToken && isValidToken !== null &&
        <>
          <Row>
            <Col className='text-center my-3'>
              {t('Your password reset link is invalid')}
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col>
              <Col className='d-flex justify-content-between border-top py-4 px-0'>
                <Link to={`/${routes.resetPassword.root}`}>
                  {t('Forgot Password?')}
                </Link>
              </Col>
            </Col>
          </Row>
        </>
        }
        {isValidToken &&
        <SetPassword onSetPasswordSuccess={handleSetPasswordSuccess} token={token} />
        }
      </LoginGradientModalAppLayout>
    </RedirectIfLoggedIn>
  );
};

const mapDispatch = {
  login,
};

export default compose<React.FC>(
  connect(undefined, mapDispatch),
  withRouter,
)(SetPasswordPage);
