import React from 'react';
import './readerControls.scss';

const ReaderControls: React.FC = (props) => {
  return (
    <div className='reader-controls'>
      {props.children}
    </div>
  );
};

export default ReaderControls;
