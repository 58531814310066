import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { externalLinks } from '../../../constants';
import './licenceAgreement.scss';

const LicenceAgreementText = () => {
  const { t } = useTranslation();
  
  return (
    <div className='licence-agreement'>
      <Trans i18nKey='I hereby declare that I have read and accepted the General Terms and Conditions'>
        <p>
          I hereby declare that I have read and accepted the        
          <a href={externalLinks.tac} target='_blank' rel='noopener noreferrer'>
            {{ agb: t('AGB') }}
          </a>
          and
          <a href={externalLinks.widerrufsbelehrung} target='_blank' rel='noopener noreferrer'>
            {{ widerrufsbelehrung: t('Widerrufsbelehrung') }}
          </a>
          I regularly receive recommendations for teaching materials, 
          but can unsubscribe at any time to stop receiving emails.
        </p>
      </Trans>
      <Trans i18nKey='Our current terms apply'>
        <p>
          Our current terms apply
          <a href={externalLinks.dataPolicy} target='_blank' rel='noopener noreferrer'>
            {{ privacyPolicy: t('Datenschutzbestimmungen') }}
          </a>.
        </p>
      </Trans>
    </div>
  );
};

export default LicenceAgreementText;
