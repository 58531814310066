import React, { useContext, useState } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { routes, externalLinks } from '../../../../constants';
import { ModalsContext } from '../../../../state/ModalsProvider';
import useRouter from '../../../../utils/useRouter';
import Logout from '../../../Logout';
import Icon from '../../../Icon';
import { InternalLink, ExternalLink, MenuItem, IntercomItem, PremiumLink } from './TopBarMenuItems';
import './topBarMobileMenu.scss';
import { CookiebotContext } from '../../../../state/CookiebotContextProvider';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../reducers/user.selectors';
import { isFeatureEnabled } from '../../../../utils/feature';

interface TopBarMobileMenuToggleProps {
  variant: 'open' | 'close';
  className?: string;
  onClick: () => void;
}

const TopBarMobileMenuToggle: React.FC<TopBarMobileMenuToggleProps> = (props) => {
  return (
    <div onClick={props.onClick} className={props.className}>
      {props.variant === 'open' && <Icon icon='bars' size='lg' className='text-white' />}
      {props.variant === 'close' && <Icon icon='cancel' size='lg' className='text-white' />}
    </div>
  );
};

interface TopBarMobileMenuProps {
  hideWalkInItems?: boolean;
}

const TopBarMobileMenu: React.FC<TopBarMobileMenuProps> = ({ hideWalkInItems = false }) => {
  const { t } = useTranslation();
  const { router, match } = useRouter();
  const [menuOpen, setMenuOpen] = useState(false);
  const { openModal } = useContext(ModalsContext);
  const consent = useContext(CookiebotContext);
  const user = useSelector(selectUser);

  const isActive = (route: string) => {
    const location = router.createLocation(route);
    return router.isActive(match, location, { exact: false });
  };

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const itemClasses = 'py-2 px-3 mb-1';
  const showIntercomItem = isFeatureEnabled('intercom') && consent.marketing;

  return (
    <div className='top-bar-mobile'>
      <TopBarMobileMenuToggle
        variant='open'
        onClick={toggleMenu}
        className='d-block d-md-none top-bar-mobile__toggle-button'
      />
      <Nav className={classNames('flex-column top-bar-mobile__menu shadow d-block d-md-none', { hidden: !menuOpen })}>
        <div className='top-bar-mobile__menu__head'>
          <TopBarMobileMenuToggle variant='close' onClick={toggleMenu} />
        </div>
        <div className='mb-2 top-bar-mobile__menu__body'>
          <PremiumLink
            to={`/${routes.upgradePackageSelection.root}`}
            classNames={itemClasses}
            hide={user?.isPremium}
          />
          <InternalLink
            label={t('Start')}
            iconName='home'
            to={`/${routes.dashboard.root}`}
            hide={hideWalkInItems}
            isActive={isActive(`/${routes.dashboard.root}`)}
            classNames={itemClasses}
          />
          <InternalLink
            label={t('Search')}
            iconName='search'
            to={`/${routes.search.root}`}
            isActive={isActive(`/${routes.search.root}`)}
            classNames={itemClasses}
          />
          <InternalLink
            label={t('Collections')}
            iconName='collection'
            to={`/${routes.collections.root}`}
            hide={hideWalkInItems}
            isActive={isActive(`/${routes.collections.root}`)}
            classNames={itemClasses}
          />
          <InternalLink
            label={t('Pinned Documents')}
            iconName='star-outline'
            to={`/${routes.pinned.root}`}
            hide={hideWalkInItems}
            isActive={isActive(`/${routes.pinned.root}`)}
            classNames={itemClasses}
          />
          <InternalLink
            label={t('Profile')}
            iconName='profile'
            to={`/${routes.personalDetails.root}`}
            hide={hideWalkInItems}
            classNames={itemClasses}
          />
          <NavDropdown.Divider />
          <InternalLink
            label={t('Downloads')}
            iconName='download'
            to={`/${routes.downloads.root}`}
            hide={hideWalkInItems}
            isActive={isActive(`/${routes.downloads.root}`)}
            classNames={itemClasses}
          />
          <InternalLink
            label={t('I follow')}
            iconName='follow'
            to={`/${routes.followed.root}`}
            hide={hideWalkInItems}
            isActive={isActive(`/${routes.followed.root}`)}
            classNames={itemClasses}
          />
          <InternalLink
            label={t('Public Collections')}
            iconName='mu-logo'
            to={`/${routes.curatedCollections.root}`}
            hide={hideWalkInItems}
            isActive={isActive(`/${routes.curatedCollections.root}`)}
            classNames={itemClasses}
          />
          <NavDropdown.Divider />
          {showIntercomItem && <IntercomItem classNames={itemClasses} />}
          <MenuItem
            label={t('Contact')}
            iconName='subscription'
            onClick={() => openModal('contact')}
            classNames={itemClasses}
          />
          <Logout
            render={(handleLogout) => (
              <MenuItem
                label={t('Sign out')}
                iconName='sign-out'
                onClick={handleLogout}
                classNames={itemClasses}
              />
            )}
          />
          <ExternalLink
            label={t('FAQ')}
            iconName='help'
            href={externalLinks.faq}
            newTab={true}
            classNames={itemClasses}
          />
          <MenuItem
            label={t('Imprint')}
            iconName='legal'
            onClick={() => openModal('imprint')}
            classNames={itemClasses}
          />
          <ExternalLink
            label={t('AGB')}
            iconName='legal'
            href={externalLinks.tac}
            newTab={true}
            classNames={itemClasses}
          />
          <MenuItem
            label={t('Data protection')}
            iconName='legal'
            onClick={() => openModal('privacyPolicy')}
            classNames={itemClasses}
          />
        </div>
      </Nav>
    </div>
  );
};

export default React.memo(TopBarMobileMenu);
