import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner, Container, Row } from 'react-bootstrap';

const ChangeEmailLoading = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Row className='justify-content-center'>
        <Spinner animation='border' variant='primary' />
      </Row>
      <Row className='my-5'>
        <span className='font-size-larger font-weight-bolder'>
          {t('Please be patient')} — {t('Your email is being changed')}.
        </span>
      </Row>
    </Container>
  );
};

export default ChangeEmailLoading;
