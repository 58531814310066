import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectMindItem, selectSiblings } from '../../reducers/mindItems.selectors';
import ReaderInformation from './ReaderInformation/ReaderInformation';
import { ReaderContentViewVariant } from '../../types/reader';

interface OwnProps {
  mindItemId: string;
  variant: ReaderContentViewVariant;
  onClose: () => void;
}

const ReaderInfoSidebar: React.FC<OwnProps> = (props) => {

  const mindItem = useSelector(state => selectMindItem(state, props.mindItemId));
  const siblings = useSelector(state => selectSiblings(state, { id: props.mindItemId, type: 'mindItem' }));

  const mindItemPosition = useMemo(() =>
    siblings && mindItem ? siblings.indexOf(mindItem) + 1 : 0,
  [siblings, mindItem]);

  if (!mindItem) return null;

  return (
    <ReaderInformation
      variant={props.variant}
      isReadOnly={mindItem.isReadOnly}
      classYears={mindItem.classYears}
      licence={mindItem.licence}
      workload={mindItem.workload}
      learningFormat={mindItem.learningFormat}
      teachingFormat={mindItem.teachingFormat}
      contentType={mindItem.fileType}
      id={mindItem.id}
      position={mindItemPosition}
      publisher={mindItem.publisher}
      subjects={mindItem.subjects}
      title={mindItem.title}
      subtitle={mindItem.subtitle}
      description={mindItem.description}
      author={mindItem.author}
      publisherReleaseDate={mindItem.publisherReleaseDate}
      onClose={props.onClose}
    />
  );
};

export default ReaderInfoSidebar;
