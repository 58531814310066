import React, { useState } from 'react';
import CancellationModal from '../components/modals/CancellationModal';

type ShowFunc = () => void;
const noop = () => undefined;

interface Context {
  isOpen: boolean;
  show: ShowFunc;
}

const defaultContext: Context = {
  isOpen: false,
  show: noop as ShowFunc,
};

const CancellationModalContext = React.createContext(defaultContext);

const CancellationModalProvider: React.FC = (props) => {

  const [isOpen, setIsOpen] = useState(false);

  return (
    <CancellationModalContext.Provider value={{
      show: () => setIsOpen(true),
      isOpen,
    }}>
      {isOpen && <CancellationModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />}
      {props.children}
    </CancellationModalContext.Provider>
  );
};

export { CancellationModalContext, CancellationModalProvider };
