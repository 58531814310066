import React from 'react';
import Checkbox from '../common/Checkbox';

interface CheckboxArrayProps {
  className?: string;
  idPrefix: string;
  name: string;
  items: Array<{
    label: string;
    value: string;
    className?: string;
  }>;
}

const FormCheckboxArray = React.forwardRef<HTMLInputElement, CheckboxArrayProps>(
  (props, ref) => {
    return (
      <div className={props.className}>
        {props.items.map(({ label, value, className }) => {
          const id = `${props.idPrefix}-${value}`;
          return (
            <Checkbox
              key={id}
              id={id}
              name={props.name}
              label={label}
              value={value}
              ref={ref}
              className={className}
            />
          );
        })}
      </div>
    );
  }
);

export default FormCheckboxArray;
