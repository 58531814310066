import React from 'react';

interface CurrentCollection {
  collectionId?: string;
  ownedByCurrentUser: boolean;
}

export const CurrentCollection = React.createContext<CurrentCollection>({
  collectionId: undefined,
  ownedByCurrentUser: false,
});
