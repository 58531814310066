import { useState, useEffect } from 'react';
import debounce from 'debounce';

const getWidth = () => window.innerWidth 
  || document.documentElement.clientWidth 
  || document.body.clientWidth;

const getHeight = () => window.innerHeight 
  || document.documentElement.clientHeight 
  || document.body.clientHeight;

const useDisplaySizes = () => {
  const [width, setWidth] = useState(getWidth());
  const [height, setHeight] = useState(getWidth());

  useEffect(() => {
    const handleResize = debounce(() => {
      setWidth(getWidth());
      setHeight(getHeight());
    }, 150);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return [width, height];
};

export default useDisplaySizes;
