import React from 'react';
import { Box, Button, DialogActions, Divider } from '@mui/material';
import { spaces } from '../../../../assets/styles/themes/tokens';
import { useTranslation } from 'react-i18next';

interface OwnProps {
  isBackEnabled?: boolean;
  onBackClick?: () => void;
}

const ExportDialogActions: React.FC<OwnProps> = (props) => {
  const { t } = useTranslation();
  const { isBackEnabled, onBackClick } = props;

  return (
    <>
      <Divider />
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: spaces.lg,
        }}
      >
        <Button
          variant='text'
          color='secondary'
          size='large'
          disabled={!isBackEnabled}
          onClick={() => {
            onBackClick && onBackClick();
          }}
        >
          {t('Back')}
        </Button>
        <Box sx={{
          display: 'flex',
        }}>
          { props.children }
        </Box>
      </DialogActions>
    </>
  );
};

export default ExportDialogActions;
