import { AttachmentsData, Product } from '@klab-berlin/api-sdk/lib/types/responses/Product';
import { MindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import { AsyncAction } from './common.actionTypes';
import {
  GetProductsQuery,
  GetProductsOptions,
} from '@klab-berlin/api-sdk/lib/services/product';

export enum GET_PRODUCT {
  START = 'GET_PRODUCT_START',
  SUCCESS = 'GET_PRODUCT_SUCCESS',
  ERROR = 'GET_PRODUCT_ERROR',
}

export type GetProductAction = AsyncAction<
  typeof GET_PRODUCT.START,
  typeof GET_PRODUCT.SUCCESS,
  typeof GET_PRODUCT.ERROR,
  Product,
  { productId: string }
>;

export enum GET_PRODUCT_MIND_ITEMS {
  START = 'GET_PRODUCT_MIND_ITEMS_START',
  SUCCESS = 'GET_PRODUCT_MIND_ITEMS_SUCCESS',
  ERROR = 'GET_PRODUCT_MIND_ITEMS_ERROR',
}

export type GetProductMindItemsAction = AsyncAction<
  typeof GET_PRODUCT_MIND_ITEMS.START,
  typeof GET_PRODUCT_MIND_ITEMS.SUCCESS,
  typeof GET_PRODUCT_MIND_ITEMS.ERROR,
  MindItem[],
  { productId: string }
>;

export enum FETCH_PRODUCTS_BY_QUERY {
  START = 'FETCH_PRODUCTS_BY_QUERY_START',
  SUCCESS = 'FETCH_PRODUCTS_BY_QUERY_SUCCESS',
  ERROR = 'FETCH_PRODUCTS_BY_QUERY_ERROR',
}

export type FetchProductsByQueryAction = AsyncAction<
  typeof FETCH_PRODUCTS_BY_QUERY.START,
  typeof FETCH_PRODUCTS_BY_QUERY.SUCCESS,
  typeof FETCH_PRODUCTS_BY_QUERY.ERROR,
  Product[],
  {
    query: GetProductsQuery;
    options: GetProductsOptions;
  }
>;

export enum GET_ATTACHMENTS {
  START = 'GET_ATTACHMENTS_START',
  SUCCESS = 'GET_ATTACHMENTS_SUCCESS',
  ERROR = 'GET_ATTACHMENTS_ERROR',
}

export type GetAttachmentsAction = AsyncAction<
  typeof GET_ATTACHMENTS.START,
  typeof GET_ATTACHMENTS.SUCCESS,
  typeof GET_ATTACHMENTS.ERROR,
  AttachmentsData,
  { productId: string }
>;
