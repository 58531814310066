import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { dateFormat, paymentRhythmDisplayNames } from '../../../../constants';
import { getSubscription } from '../../../../reducers/user.selectors';
import LabeledText from '../../../common/LabeledText';
import './paymentRhythmView.scss';

interface PaymentRhythmViewProps {
  showRequestInformation?: boolean;
}

const PaymentRhythmView: React.FC<PaymentRhythmViewProps> = ({ showRequestInformation }) => {
  const { t } = useTranslation();
  const subscription = useSelector(getSubscription);
  const paymentRhythm = subscription?.paymentPeriod?.type;

  const followingPaymentRhythmMessage = () => {
    if (subscription?.paymentPeriod?.following && subscription?.paymentPeriod?.nextChargeDate) {
      return t('Your payment rhythm will be changed',
        {
          following: t(paymentRhythmDisplayNames[subscription.paymentPeriod.following]),
          nextChargeDate: format(new Date(subscription.paymentPeriod.nextChargeDate), dateFormat)
        });
    }
    if (showRequestInformation) {
      return t('We have received your payment rhythm change request.');
    }
    return;
  };

  return (
    <>
      <LabeledText
        label={t('Payment rhythm')}
        id='payment-rhythm'
        text={paymentRhythm ? t(paymentRhythmDisplayNames[paymentRhythm]) : ''}
      />
      <p className='payment-rhythm-view__following-payment-rhythm-message'>
        {followingPaymentRhythmMessage()}
      </p>
    </>
  );
};

export default PaymentRhythmView;
