export const ADD_ERROR = 'ADD_ERROR';
export const DISMISS_ERROR = 'DISMISS_ERROR';

export interface Error {
  id: number;
  message: string;
  time: string;
}

export interface AddError {
  type: typeof ADD_ERROR;
  payload: Error;
}

export interface DismissError {
  type: typeof DISMISS_ERROR;
  payload: {
    id: number;
  };
}
