import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Divider,
  Typography,
  DialogContent,
  Stack,
  FormLabel,
  Box,
  TextField,
} from '@mui/material';
import { borderRadius, customSizes, palette, spaces } from '../../../../assets/styles/themes/tokens';
import LernsetsList from '../LernsetsList/LernsetsList';
import { colors } from '../../../../assets/styles/themes/variables';
import { Lernset } from '@klab-berlin/api-sdk/lib/types/responses/Lernsets';
import ExportDialogTitle from './ExportDialogTitle';
import ExportDialogActions from './ExportDialogActions'; 
import { OperationStatus } from '../ExportDialog';

export type LernsetSelectionStageActions = 'save-and-close' | 'save-and-open' | 'create-new-lernset' | undefined;

export interface LernsetSelectionStageProps {
  handleClose: () => void;
  changeStage: () => void;
  handleAddNewLernsetClick: (title?: string) => void;
  handleSaveAndCloseClick: () => void;
  handleSaveAndOpenClick: () => void;
  handleLernsetClick: (id: string) => void;
  selectedLernset: string | undefined;
  lernsets: Lernset[];
  processingAction?: LernsetSelectionStageActions;
  addOperationStatus?: OperationStatus;
}

const LernsetSelectionStage = (props: LernsetSelectionStageProps) => {
  const { t } = useTranslation();
  const [isCreatingNewLernset, setIsCreatingNewLernset] = useState(false);
  const {
    changeStage,
    handleClose,
    handleLernsetClick,
    selectedLernset,
    handleAddNewLernsetClick,
    handleSaveAndCloseClick,
    handleSaveAndOpenClick,
    lernsets,
    processingAction,
    addOperationStatus,
  } = props;

  const isSaveDisabled = !selectedLernset || !!processingAction || addOperationStatus === 'error';

  const AddNewLernsetButton = () => (
    <Button
      variant='outlined'
      color='primary'
      size='large'
      onClick={() => setIsCreatingNewLernset(true)}
      disabled={!!processingAction}
      fullWidth
    >
      { processingAction === 'create-new-lernset' ? `${t('Creating')}...` : `+ ${t('New Lernset')}` }
    </Button>
  );

  const CreateNewLernsetInputBox = () => {
    const [newLernsetTitle, setNewLernsetTitle] = useState<string>('');

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { key } = event;
      if (key === 'Enter') {
        handleAddNewLernsetClick(newLernsetTitle);
        setIsCreatingNewLernset(false);
      }
    };

    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: spaces.xs,
        padding: spaces.lg,
        borderRadius: borderRadius.md,
        border: `1px solid ${palette.primary.outlinedBorder}`,
        background: palette.primary.hover,
      }}>
        <TextField
          size='medium'
          value={newLernsetTitle}
          onChange={({ target }) => setNewLernsetTitle(target.value)}
          label={t('New Lernset')}
          placeholder={t('e.g. subject, year and/or topic')}
          onKeyDown={handleKeyDown}
          InputProps={{
            style: {
              borderRadius: borderRadius.xs,
            }
          }}
          InputLabelProps={{ 
            shrink: true,
            style: {
              color: colors.violet700,
              fontWeight: 400,
              fontSize: 18,
            }
          }}
          inputProps={{
            style: {
              borderRadius: borderRadius.xs,
              background: colors.neutral0,
            }
          }}
        />
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: spaces.xs,
        }}>
          <Button
            variant='contained'
            color='primary'
            size='small'
            onClick={() => {
              handleAddNewLernsetClick(newLernsetTitle);
              setIsCreatingNewLernset(false);
            }}
            sx={{
              padding: `${spaces.xxs} ${spaces.sm}`,
            }}
          >
            {t('Create lesson')}
          </Button>
          <Button
            variant='text'
            color='primary'
            size='small'
            onClick={() => setIsCreatingNewLernset(false)}
            sx={{
              padding: `${spaces.xxs} ${spaces.sm}`,
            }}
          >
            {t('Cancel')}
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <ExportDialogTitle handleClose={handleClose}>
        <Typography variant='h5'>
          {t('Add materials to Lernsets')}
        </Typography>
      </ExportDialogTitle>
      <DialogContent sx={{
        width: '100%',
        display: 'inline-flex',
        position: 'relative',
        paddingTop: `${spaces.md}!important`,
        paddingBottom: spaces.md,
      }}>
        <Stack sx={{
          width: '100%'
        }}>
          <FormLabel sx={{ color: colors.violet800 }}>
            {t('Select Lernset')}
          </FormLabel>
          {
            !isCreatingNewLernset ? <AddNewLernsetButton /> : <CreateNewLernsetInputBox />
          }
        </Stack>
      </DialogContent>
      <Divider sx={{
        marginRight: spaces.lg,
        marginLeft: spaces.lg,
      }}/>
      <DialogContent sx={{
        padding: spaces.md + ' ' + spaces.lg,
        height: customSizes.exportDialog.lernsetsListHeight,
      }}>
        <LernsetsList lernsets={lernsets} handleLernsetClick={handleLernsetClick} selectedLernsetId={selectedLernset}/>
      </DialogContent>
      <ExportDialogActions isBackEnabled={true} onBackClick={changeStage}>
        <Box sx={{ display: 'flex', gap: spaces.xs }}>
          <Button
            variant='outlined'
            color='primary'
            size='large'
            disabled={isSaveDisabled}
            sx={{
              width: '268px'
            }}
            onClick={handleSaveAndCloseClick}
          >
            {processingAction === 'save-and-close' ? `${t('Adding')}...` : t('Save and close')}
          </Button>
          <Button
            variant='contained'
            color='primary'
            size='large'
            disabled={isSaveDisabled}
            sx={{
              width: '240px' // Keeping the width fixed to avoid jumping around in "loading" state
            }}
            onClick={handleSaveAndOpenClick}
          >
            {processingAction === 'save-and-open' ? `${t('Adding')}...` : t('Add and open')}
          </Button>
        </Box>
      </ExportDialogActions>
    </>
  );
};

export default LernsetSelectionStage;
