import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectMindItem } from '../../../reducers/mindItems.selectors';
import PagesSelectionStage from './Stages/PagesSelectionStage';
import LernsetSelectionStage, { LernsetSelectionStageActions } from './Stages/LernsetSelectionStage';
import { Dialog } from '@mui/material';
import { notifyBugsnagHandledError } from '../../../utils/bugsnagClient';
import useAction from '../../../utils/useAction';
import { addLernset, getLernsets, addPagesToLernset } from '../../../actions/lernsets.actions';
import { getLernsets as getLernsetsSelector } from '../../../reducers/lernsets.selectors';
import { MindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import { customSizes } from '../../../assets/styles/themes/tokens';
import useRouter from '../../../utils/useRouter';
import { getNewLernsetTitle } from '../../../utils/lernsets';
import { getAmpliContentData } from '../../../utils/ampli';
import services from '../../../services';
import { LernsetsContentAdded, SetCreated, ContentPagesSelected } from '../../../ampli';
import { SnackbarContext } from '../../../state/SnackbarContextProvider';

type ExportDialogStages = 'pagesSelection' | 'lernsetSelection';
export type OperationStatus = 'success' | 'error' | undefined;
interface LernsetsExportDialogProps {
  open: boolean;
  onClose: () => void;
  mindItemId: string;
}

const ExportDialog = (props: LernsetsExportDialogProps) => {
  const { t } = useTranslation();
  const { openSnackbar } = useContext(SnackbarContext);
  const { onClose, open, mindItemId } = props;
  const mindItem = useSelector(state => selectMindItem(state, mindItemId)) as MindItem<'document'>;
  const { router } = useRouter();
  const [processingAction, setProcessingAction] = useState<LernsetSelectionStageActions>(undefined);
  const [addOperationStatus, setAddOperationStatus] = useState<OperationStatus>(undefined);

  if (!mindItem) {
    notifyBugsnagHandledError(new Error(`MindItem is not found in the ExportDialog: ${mindItemId}`));
    return <></>;
  }

  const [currentStage, setCurrentStageState] = useState<ExportDialogStages>('pagesSelection');
  const [selectedPages, setSelectedPages] = useState<number[]>([]);
  const [selectedLernsetId, setSelectedLernsetId] = useState<string | undefined>(undefined);
  const lernsetsList = useSelector(getLernsetsSelector);
  const getUserLernsets = useAction(getLernsets);
  const createNewLernset = useAction(addLernset);
  const addPages = useAction(addPagesToLernset);

  useEffect(() => {
    getUserLernsets();
  }, []);

  useEffect(() => {
    // Reset the dialog state when it is re-opened
    if (open === true) {
      setCurrentStageState('pagesSelection');
      setSelectedPages([]);
      setSelectedLernsetId(undefined);
    }
  }, [open]);

  const handleClose = () => {
    onClose();
  };

  const trackSetCreatedEvent = () => {
    if (mindItem && selectedLernsetId) {
      const eventProperties = {
        content: getAmpliContentData(mindItem, 'mindItem') as any,
        'lernset id': selectedLernsetId,
        'lernset title': lernsetsList.find(lernset => lernset.id === selectedLernsetId)?.title ?? '',
        'page amount': selectedPages.length,
      };

      services.track.ampliEventTrack({ 
        event: new LernsetsContentAdded(eventProperties)
      });
    } 
  };

  const handlePageClick = (pageNumber: number) => {
    const newSelectedPages = selectedPages.includes(pageNumber)
      ? selectedPages.filter(page => page !== pageNumber)
      : [...selectedPages, pageNumber];

    setSelectedPages(newSelectedPages);
  };

  const handleLernsetClick = (id: string) => {
    setAddOperationStatus(undefined);
    selectedLernsetId === id ? setSelectedLernsetId(undefined) : setSelectedLernsetId(id);
  };
   
  const handleAddNewLernsetClick = async (title?: string) => {
    setProcessingAction('create-new-lernset');
    setAddOperationStatus(undefined);
    const createLernsetResponse = await createNewLernset(title ? title : getNewLernsetTitle(t('Untitled')));
    if (createLernsetResponse?.result) setSelectedLernsetId(createLernsetResponse.result.id);
    setProcessingAction(undefined);
    setAddOperationStatus('success');

    // Track the amplitude event
    services.track.ampliEventTrack({ 
      event: new SetCreated({
        'lernset id': createLernsetResponse.result.id,
        'lernset title': createLernsetResponse.result.title,
        'page source': 'Reader'
      })
    });
  };

  const handleAddPagesToLernsetClick = async (options?: { openOverview: boolean}) => {
    if (selectedLernsetId) {
      setAddOperationStatus(undefined);
      setProcessingAction(options?.openOverview ? 'save-and-open' : 'save-and-close');

      try {
        await addPages(selectedLernsetId, mindItemId, selectedPages);
        // Track the event
        trackSetCreatedEvent();
        handleClose();
        if (options?.openOverview) {
          openSnackbar({
            type: 'success',
            message: t('The material has been added successfully!'),
          });
          router.push(`/lernsets/${selectedLernsetId}`);
        } 
       
        setAddOperationStatus('success');
      } catch (error: any) {
        setAddOperationStatus('error');
        if (error.statusCode === 400 && error.data?.code === 'PAGE_LIMIT_EXCEEDED') {
          openSnackbar({
            type: 'error',
            message: t('You can add a limited number of pages to a lernset.', {
              partsLengthLimit: error.data?.data?.partsLengthLimit
            }),
          });
        }
      }

      setProcessingAction(undefined);
    } 
  };

  const getDialogWidth = (stage: ExportDialogStages) => {
    if (stage === 'pagesSelection') {
      return customSizes.exportDialog.pagesSelectionStageWidth;
    } else {
      return customSizes.exportDialog.lernsetSelectionStageWidth;
    }
  };

  const trackContentPagesSelected = () => {
    if (mindItem) {
      services.track.ampliEventTrack({ 
        event: new ContentPagesSelected({
          content: getAmpliContentData(mindItem, 'mindItem') as any,
          'page amount': selectedPages.length,
        })
      });
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} sx={{ 
      '& .MuiDialog-paper': { maxWidth: getDialogWidth(currentStage) }
    }}>
      {
        currentStage === 'pagesSelection'
          ?  <PagesSelectionStage
            handleClose={handleClose}
            handlePageClick={handlePageClick}
            mindItemPages={mindItem.pages}
            selectedPages={selectedPages}
            changeStage={() => {
              setCurrentStageState('lernsetSelection');
              trackContentPagesSelected();
            }}
          /> 
          : <LernsetSelectionStage 
            handleClose={handleClose}
            changeStage={() => {
              setAddOperationStatus(undefined);
              setCurrentStageState('pagesSelection');
            }}
            handleLernsetClick={handleLernsetClick}
            selectedLernset={selectedLernsetId}
            handleAddNewLernsetClick={handleAddNewLernsetClick}
            lernsets={lernsetsList}
            handleSaveAndCloseClick={handleAddPagesToLernsetClick}
            handleSaveAndOpenClick={() => handleAddPagesToLernsetClick({ openOverview: true })}
            processingAction={processingAction}
            addOperationStatus={addOperationStatus}
          />
      }
    </Dialog>
  );
};

export default ExportDialog;
