import { useDispatch, useStore } from 'react-redux';
import { StoreType } from '../reducers';
import { ThunkAction } from 'redux-thunk';
import { Dispatch } from 'redux';

const useAction = <Args extends Array<any>, ReturnVal>(
  action: (...params: Args) => ThunkAction<ReturnVal, StoreType, Record<string, unknown>, any>
) => {
  const dispatch = useDispatch();
  const store = useStore();

  return (...params: Args) => {
    return action(...params)(dispatch, store.getState, {});
  };
};

type ActionFunc = (dispatch: Dispatch, ...params: any) => void;

export const useSyncAction = (action: ActionFunc) => {
  const dispatch = useDispatch();

  return (...params: any) => {
    return action(dispatch, ...params);
  };
};

export const useNonThunkAction = <Args extends Array<any>>(
  action: (...params: Args) => any
) => {
  const dispatch = useDispatch();

  return (...params: Args) => {
    return dispatch(action(...params));
  };
};

export default useAction;
