import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import TrialCancellationStepStart from './TrialCancellationStepStart';
import TrialCancellationStepErrorRetry from './TrialCancellationStepErrorRetry';
import TrialCancellationStepFinishCancelling from './TrialCancellationStepFinishCancelling';
import '../ScreenModal/ScreenStepContainer.scss';

export type DownloadPreselect = 'mindItem' | 'attachments';
interface TrialCancellationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const TrialCancellationModal: React.FC<TrialCancellationModalProps> = (props) => {
  const { onClose, isOpen } = props;
  const [screenName, setScreenName] = useState('start');
  const stepProps = { onClose, setScreen: setScreenName };
  const stepMap: { [key in string]: React.ReactNode } = {
    start: <TrialCancellationStepStart {...stepProps} />,
    finishCancelling: <TrialCancellationStepFinishCancelling {...stepProps} />,
    errorRetry: <TrialCancellationStepErrorRetry {...stepProps} />
  };

  return (
    <Modal show={isOpen} onHide={onClose} className='screen-step__modal'>
      {stepMap[screenName]}
    </Modal>
  );
};

export default TrialCancellationModal;
