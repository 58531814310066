import React from 'react';
import { spaces } from '../../assets/styles/themes/tokens';
import { Box, SxProps, Typography } from '@mui/material';

interface PageHeaderProps {
  text: string;
  sx?: SxProps;
}

const PageHeader: React.FC<PageHeaderProps> = ({ text, sx }) => {
  return <Box sx={{
    paddingY: spaces.md,
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    ...sx
  }}>
    <Typography variant='h5'>{text}</Typography>
  </Box>; 
};

export default PageHeader;
