import React from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentPeriod } from '@klab-berlin/api-sdk/lib/types/common';
import FormSelect from '../forms/FormSelect';
import { paymentRhythmDisplayNames } from '../../constants';

interface OwnProps {
  paymentRhythm: PaymentPeriod;
  onPaymentRhythmChange: (value: PaymentPeriod) => void;
  defaultPaymentRhythm: PaymentPeriod;
  hideLabel?: boolean;
  isSmall?: boolean;
}

const PaymentRhythmSelect: React.FC<OwnProps> = (
  { paymentRhythm, onPaymentRhythmChange, defaultPaymentRhythm }
) => {
  const { t } = useTranslation();
  const possibleValues: PaymentPeriod[] = ['monthly', 'quarterly', 'fullAmount'];
  const items = possibleValues.map(value => (
    {
      value: value,
      label: t(paymentRhythmDisplayNames[value])
    }
  ));

  return (
    <FormSelect
      containerClassName='payment-rhythm-select__container'
      items={items}
      defaultValue={paymentRhythm || defaultPaymentRhythm}
      id='payment-rhythm'
      name='payment-rhythm'
      onChoose={(value) => onPaymentRhythmChange(value as PaymentPeriod)}
    />
  );
};

export default PaymentRhythmSelect;
