import { MindItemSearchResult } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import { ProductSearchResult } from '@klab-berlin/api-sdk/lib/types/responses/Product';
import { StoreType } from '.';
import { AsyncActionStatus } from '../actions/common.actionTypes';

export const getPaginationSelector = (elements: SearchItems) => (store: StoreType) => {
  return store.search[elements].pagination;
};

export const getUnifiedPaginationSelector = (store: StoreType) => {
  return store.search.unified.pagination;
};

export enum SearchLoadingStatus {
  NONE = 'none',
  INITIAL = 'initial',
  PAGINATION = 'pagination',
  LOADED = 'loaded',
}

type SearchItems = 'products' | 'mindItems' | 'videoMindItems';

export const getSubTopics = (state: StoreType) => {
  return state.search.subTopics.terms;
};

export const getSubTopicLoadingStatus = (state: StoreType) =>
  state.search.subTopics.status;

export const selectWorkloads = (state: StoreType) => 
  state.search.workloads.data;

export const selectWorkloadLoadingStatus = (state: StoreType) =>
  state.search.workloads.status;

export const selectTeachingFormats = (state: StoreType) => 
  state.search.teachingFormats.data;

export const selectTeachingFormatLoadingStatus = (state: StoreType) =>
  state.search.teachingFormats.status;

export const getProductLoadingState = (state: StoreType) => {
  const pages = state.search.products.pages;
  if (pages.length === 0) {
    return SearchLoadingStatus.NONE;
  }
  if (pages[0].status === AsyncActionStatus.LOADING) {
    return SearchLoadingStatus.INITIAL;
  }
  if (pages[pages.length - 1].status === AsyncActionStatus.LOADING) {
    return SearchLoadingStatus.PAGINATION;
  }
  return SearchLoadingStatus.LOADED;
};

export const getMindItemLoadingState = (state: StoreType) => {
  const pages = state.search.mindItems.pages;
  if (pages.length === 0) {
    return SearchLoadingStatus.NONE;
  }
  if (pages[0].status === AsyncActionStatus.LOADING) {
    return SearchLoadingStatus.INITIAL;
  }
  if (pages[pages.length - 1].status === AsyncActionStatus.LOADING) {
    return SearchLoadingStatus.PAGINATION;
  }
  return SearchLoadingStatus.LOADED;
};

export const getUnifiedLoadingState = (state: StoreType) => {
  const pages = state.search.unified.pages;
  if (pages.length === 0) {
    return SearchLoadingStatus.NONE;
  }
  if (pages[0].status === AsyncActionStatus.LOADING) {
    return SearchLoadingStatus.INITIAL;
  }
  if (pages[pages.length - 1].status === AsyncActionStatus.LOADING) {
    return SearchLoadingStatus.PAGINATION;
  }
  return SearchLoadingStatus.LOADED;
};

export const getVideoMindItemsLoadingState = (state: StoreType) => {
  const pages = state.search.videoMindItems.pages;
  if (pages.length === 0) {
    return SearchLoadingStatus.NONE;
  }
  if (pages[0].status === AsyncActionStatus.LOADING) {
    return SearchLoadingStatus.INITIAL;
  }
  if (pages[pages.length - 1].status === AsyncActionStatus.LOADING) {
    return SearchLoadingStatus.PAGINATION;
  }
  return SearchLoadingStatus.LOADED;
};

type SearchReturnType<T> = T extends 'products'
  ? ProductSearchResult
  : MindItemSearchResult;

export const getSearchResults = 
 <T extends SearchItems>(state: StoreType, type: T):  SearchReturnType<T>[] => {
   const pages = state.search[type].pages;
  
   return pages.reduce((searchResults, page) => {
     const pageResults = page.data && page.data.map(id => state.search[type].byId[id]);
  
     if (!pageResults) {
       return searchResults;
     }

     return searchResults.concat(pageResults as SearchReturnType<T>[]);
   }, [] as SearchReturnType<T>[]);
 };

export const getUnifiedSearchResults = (state: StoreType): (ProductSearchResult | MindItemSearchResult)[] => {
  const pages = state.search.unified.pages;

  return pages.reduce((searchResults, page) => {
    const pageResults = page.data && page.data.map(id => state.search.unified.byId[id]);

    if (!pageResults) {
      return searchResults;
    }

    return searchResults.concat(pageResults as (ProductSearchResult | MindItemSearchResult)[] );
  }, [] as (ProductSearchResult | MindItemSearchResult)[]);
};

export const isMindItemPageLoading = (state: StoreType) => {
  const pages = state.search.mindItems.pages;
  const pageStatus = pages.map(page => page.status);
  return pageStatus.some(status => status === AsyncActionStatus.LOADING);
};

export const isVideoMindItemPageLoading = (state: StoreType) => {
  const pages = state.search.videoMindItems.pages;
  const pageStatus = pages.map(page => page.status);
  return pageStatus.some(status => status === AsyncActionStatus.LOADING);
};

export const isProductPageLoading = (state: StoreType) => {
  const pages = state.search.products.pages;
  const pageStatus = pages.map(page => page.status);
  return pageStatus.some(status => status === AsyncActionStatus.LOADING);
};

export const isUnifiedPageLoading = (state: StoreType) => {
  const pages = state.search.unified.pages;
  const pageStatus = pages.map(page => page.status);
  return pageStatus.some(status => status === AsyncActionStatus.LOADING);
};

export const selectSearchFilters = (state: StoreType) => state.search.searchFilters;
