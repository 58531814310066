import mindItemService from '../services/mindItem.service';
import { ThunkAction, AsyncActionStatus } from './common.actionTypes';
import {
  FetchMindItemAction,
  FETCH_MIND_ITEM,
  FetchPublicMindItemAction,
  FETCH_PUBLIC_MIND_ITEM,
  FetchSiblingsAction,
  FETCH_SIBLINGS,
} from './mindItems.actionTypes';

export function fetchMindItem(
  mindItemId: string
): ThunkAction<FetchMindItemAction> {
  const payload = { mindItemId };

  return async (dispatch, getState) => {
    const state = getState();
    const mindItemStatus =
      state.mindItems.byId[mindItemId] &&
      state.mindItems.byId[mindItemId].status;
    if (mindItemStatus === AsyncActionStatus.READY) {
      return;
    }

    dispatch({ type: FETCH_MIND_ITEM.START, payload });

    return mindItemService
      .getMindItem(mindItemId)
      .then((result) =>
        dispatch({ type: FETCH_MIND_ITEM.SUCCESS, result, payload })
      )
      .catch((error) =>
        dispatch({ error, payload, type: FETCH_MIND_ITEM.ERROR })
      );
  };
}

export function fetchPublicMindItem(
  accessToken: string
): ThunkAction<FetchPublicMindItemAction> {
  const payload = { accessToken };

  return async (dispatch, getState) => {
    const state = getState();
    const mindItemId = state.mindItems.byAccessToken[accessToken]?.mindItem;

    if (mindItemId && state.mindItems.byId[mindItemId].status === AsyncActionStatus.READY) {
      return;
    }

    dispatch({ type: FETCH_PUBLIC_MIND_ITEM.START, payload });

    return mindItemService
      .getPublicMindItem(accessToken)
      .then((result) =>
        dispatch({ type: FETCH_PUBLIC_MIND_ITEM.SUCCESS, result, payload })
      )
      .catch((error) =>
        dispatch({ error, payload, type: FETCH_PUBLIC_MIND_ITEM.ERROR })
      );
  };
}

export function fetchSiblings(
  mindItemId: string,
  productItemId: string,
): ThunkAction<FetchSiblingsAction> {
  const payload = { mindItemId, productItemId };

  return async (dispatch, getState) => {
    const state = getState();
    const siblingsStatus =
      state.mindItems.siblingsByProductItemId[productItemId] &&
      state.mindItems.siblingsByProductItemId[productItemId].status;
    if (siblingsStatus === AsyncActionStatus.READY) {
      return;
    }

    dispatch({ type: FETCH_SIBLINGS.START, payload });

    return mindItemService
      .getSiblings(mindItemId)
      .then((result) =>
        dispatch({ type: FETCH_SIBLINGS.SUCCESS, result, payload })
      )
      .catch((error) =>
        dispatch({ type: FETCH_SIBLINGS.ERROR, payload, error })
      );
  };
}
