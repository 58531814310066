import { useTranslation } from 'react-i18next';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { isTraineeOptions } from '../../constants';
import ProfilePill from './ProfilePill';
import './isTraineeSelection.scss';

interface IsTraineeSelectionProps {
  onChange: (value: boolean|undefined) => void;
  value: boolean|undefined;
}

const IsTraineeSelection: React.FC<IsTraineeSelectionProps> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const optionElements = isTraineeOptions.map((option) => {
    const columnSize = option.value === undefined ? 'big' : 'small';
    return (
      <Col key={option.text} className={`is-trainee-selection__column-${columnSize}`}>
        <ProfilePill selected={value === option.value}>
          <Button
            className='w-100'
            type='button'
            onClick={() => onChange(option.value)}
          >
            {t(option.text)}
          </Button>
        </ProfilePill>
      </Col>
    );
  });
  return (
    <div className='mb-5'>
      <h2 className='mb-3'>
        {t('Are you a trainee?')}
      </h2>
      <Row className='is-trainee-selection'>
        {optionElements}
      </Row>
    </div>
  );
};

export default IsTraineeSelection;
