import React from 'react';
import { useTranslation } from 'react-i18next';

interface ItemMetadata {
  schoolTypes: string[];
  classYears: number[];
  subjects: string[];
}

const ItemMetadata: React.FC<ItemMetadata> = (props) => {
  const { t } = useTranslation();
  return (
    <div className='item-metadata text-truncate'>
      {props.schoolTypes && !!props.schoolTypes.length && <span>
        {props.schoolTypes.join(', ')}
      </span>}
      {props.subjects && !!props.subjects.length && <span>
        {props.subjects.join(', ')}
      </span>}
      {!!props.classYears?.length && <span>
        {t('Class years')}: {props.classYears.join(', ')}
      </span>}
    </div>
  );
};

export default ItemMetadata;
