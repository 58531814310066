import { ThunkAction } from './common.actionTypes';
import userService from '../services/user.service';
import billingService from '../services/billing.service';
import bugsnagClient from '../utils/bugsnagClient';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import {
  FETCH_ME,
  FetchMeAction,
  UPDATE_ME,
  UpdateMeAction,
  GetPaymentMethodAction,
  GET_PAYMENT_METHOD,
  GET_SUBSCRIPTION,
  GetSubscriptionAction,
  CANCEL_SUBSCRIPTION,
  CancelSubscriptionAction,
  UPGRADE,
  UpgradeAction,
  INCREMENT_DOWNLOADS_COUNTER,
  IncrementDownloadsCounterAction,
  UpdateNewsletterAction,
  UPDATE_NEWSLETTER,
  UpdatePaymentMethodAction,
  UPDATE_PAYMENT_METHOD,
  GetInvoicesAction,
  GET_INVOICES,
  GET_EXPERIMENT,
  GetExperimentAction,
  TRACK_EXPERIMENT,
  UpdateExperimentAction,
} from './user.actionTypes';
import {
  PaymentMethod,
  UpdatePayload as UserUpdatePayload,
  UpgradePayload
} from '@klab-berlin/api-sdk/lib/types/requests/User';
import api from '../clients/api';
import { userType } from '../constants';
import cookies from '../clients/cookies';
import { ExperimentData } from '@klab-berlin/api-sdk/lib/types/responses/User';
import { isEmpty } from 'lodash';

export function fetchMe(): ThunkAction<FetchMeAction> {
  return async (dispatch, getState) => {
    if (getState().user.me) {
      return;
    }

    dispatch({ type: FETCH_ME.START });

    await userService.getMe()
      .then((result) => {
        dispatch({ type: FETCH_ME.SUCCESS, result });
        return result;
      })
      .then((result) => {
        if (result) {
          bugsnagClient.user = {
            id: result._id,
          };
        }
      })
      .catch((error) => {
        if (error.statusCode === 401) {
          cookies.deleteAuthToken();
          dispatch({ type: FETCH_ME.EXPIRED, error });
        }
        dispatch({ type: FETCH_ME.ERROR, error });
      });
  };
}

export function updateMe(payload: UserUpdatePayload): ThunkAction<UpdateMeAction> {
  return async (dispatch, getState) => {
    const me = getState().user.me;

    if (!me) { return; }
    if (isEmpty(payload)) { return; }
    const userId = me._id;

    dispatch({ type: UPDATE_ME.START, payload });

    return userService.updateUser(userId, payload)
      .then((result) => dispatch({ type: UPDATE_ME.SUCCESS, result, payload }))
      .catch(error => { dispatch({ error, type: UPDATE_ME.ERROR, payload }); throw error; });
  };
}

export function updateNewsletter(optIn: boolean): ThunkAction<UpdateNewsletterAction> {
  return async (dispatch, getState) => {
    const me = getState().user.me;

    if (!me) { return; }
    const userId = me._id;

    dispatch({ type: UPDATE_NEWSLETTER.START, payload: { optIn } });

    return userService.updateNewsletter(userId, { optin: optIn })
      .catch((error) => dispatch({ type: UPDATE_NEWSLETTER.ERROR, payload: { optIn }, error }));
  };
}

export function updateExperiment(experiment: ExperimentData): ThunkAction<UpdateExperimentAction> {
  return async (dispatch, getState) => {
    const me = getState().user.me;
    if (!me) { return; }
    const userId = me._id;
    const payload = { experiment };

    dispatch({ type: TRACK_EXPERIMENT.START, payload });
    return userService.updateExperiment(userId, experiment)
      .then((result) => dispatch({ type: TRACK_EXPERIMENT.SUCCESS, result, payload }))
      .catch((error) => dispatch({ type: TRACK_EXPERIMENT.ERROR, payload, error }));
  };
}

export function getExperiment(experiment: string): ThunkAction<GetExperimentAction> {
  return async (dispatch, getState) => {
    const me = getState().user.me;
    if (!me) { return; }
    const userId = me._id;
    const payload = { experiment };

    dispatch({ type: GET_EXPERIMENT.START, payload });
    return userService.getExperiment(userId, experiment)
      .then((result) => dispatch({ type: GET_EXPERIMENT.SUCCESS, result, payload }))
      .catch((error) => dispatch({ type: GET_EXPERIMENT.ERROR, payload, error }));
  };
}

export function getSubscription(): ThunkAction<GetSubscriptionAction> {
  return async (dispatch, getState) => {
    const me = getState().user.me;

    if (!me) { return; }

    const userId = me._id;
    const payload = { userId };

    dispatch({ type: GET_SUBSCRIPTION.START, payload });

    return billingService.getSubscription(userId)
      .then((result) => dispatch({ type: GET_SUBSCRIPTION.SUCCESS, result, payload }))
      .catch((error) => {

        // We get a 404 for basis-users without a subscription (not suspended or paused)
        // Don't treat it as an error
        // Set subscription to undefined
        if (error.statusCode === 404) {
          dispatch({ 
            type: GET_SUBSCRIPTION.SUCCESS,
            result: undefined,
            payload: { ...payload, status: AsyncActionStatus.NOTFOUND }
          });
          return;
        }
        
        dispatch({ type: GET_SUBSCRIPTION.ERROR, payload, error });
      });
  };
}

export function cancelSubscription(): ThunkAction<CancelSubscriptionAction> {
  return async (dispatch, getState) => {
    const me = getState().user.me;
    if (!me || me.type === userType.basis) { return; }
    const userId = me._id;
    const payload = { userId };
    dispatch({ type: CANCEL_SUBSCRIPTION.START, payload });
    return billingService.cancelSubscription(userId)
      .then(() => dispatch(getSubscription()))
      .then((result) => dispatch({ type: CANCEL_SUBSCRIPTION.SUCCESS, result, payload }))
      .catch((error) => { dispatch({ type: CANCEL_SUBSCRIPTION.ERROR, payload, error }); throw error; });
  };
}

export function getPaymentMethod(): ThunkAction<GetPaymentMethodAction> {
  return async (dispatch, getState) => {
    const me = getState().user.me;

    if (!me || me.type === userType.basis) { return; }

    const userId = me._id;
    const payload = { userId };

    dispatch({ type: GET_PAYMENT_METHOD.START, payload });
    return api.billing.getPaymentMethod(userId)
      .then((result) => {
        return dispatch({ type: GET_PAYMENT_METHOD.SUCCESS, result, payload });
      })
      .catch((error) => dispatch({ type: GET_PAYMENT_METHOD.ERROR, payload, error }));
  };
}

export function updatePaymentMethod(paymentMethod: PaymentMethod): ThunkAction<UpdatePaymentMethodAction> {
  return async (dispatch, getState) => {
    const me = getState().user.me;

    if (!me || me.type === userType.basis) { return; }

    const userId = me._id;
    const payload = { paymentMethod, userId };

    dispatch({ type: UPDATE_PAYMENT_METHOD.START, payload });
    return api.billing.updatePaymentMethod(userId, paymentMethod)
      .then((result) => dispatch({ type: UPDATE_PAYMENT_METHOD.SUCCESS, result, payload }))
      .catch((error) => dispatch({ type: UPDATE_PAYMENT_METHOD.ERROR, payload, error }));
  };
}

export function getInvoices(): ThunkAction<GetInvoicesAction> {
  return async (dispatch, getState) => {
    const me = getState().user.me;
    const invoiceHistory = getState().user.invoiceHistory;

    if (!me || invoiceHistory) { return; }

    const userId = me._id;

    dispatch({ type: GET_INVOICES.START });

    return billingService.getInvoices(userId)
      .then((result) => dispatch({ type: GET_INVOICES.SUCCESS, result }))
      .catch((error) => dispatch({ type: GET_INVOICES.ERROR, error }));
  };
}

export function upgrade(userId: string, payload: UpgradePayload): ThunkAction<UpgradeAction> {
  return async (dispatch) => {
    dispatch({ type: UPGRADE.START, payload });

    return userService.upgrade(userId, payload)
      .then((user) => dispatch({ type: UPGRADE.SUCCESS, result: user, payload }));
  };
}

export function incrementDownloadsCounter(): ThunkAction<IncrementDownloadsCounterAction> {
  return async (dispatch) => {
    dispatch({ type: INCREMENT_DOWNLOADS_COUNTER });
  };
}
