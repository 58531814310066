import React, { useEffect, useState } from 'react';
import { Tab } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useSelector } from 'react-redux';
import _, { debounce } from 'lodash';
import { selectUser } from '../../../reducers/user.selectors';
import {
  getSearchResults as getSearchResultsSelector,
  getUnifiedSearchResults as getUnifiedSearchResultsSelector,
  getProductLoadingState as getProductLoadingStateSelector,
  getMindItemLoadingState as getMindItemLoadingStateSelector,
  getVideoMindItemsLoadingState as getVideoMindItemsLoadingStateSelector,
  getUnifiedLoadingState as getUnifiedLoadingStateSelector,
  SearchLoadingStatus,
  getPaginationSelector,
  getUnifiedPaginationSelector,
} from '../../../reducers/search.selectors';
import MindItemCard from '../../cards/MindItemCard';
import ProductCard from '../../cards/ProductCard';
import { hasNext } from '../../../utils/pagination';
import './searchResults.scss';
import { Link } from 'found';
import { onboardingHints, routes, searchSettings } from '../../../constants';
import EmptySearchResults from './EmptySearchResults';
import classNames from 'classnames';
import { useViewMode } from '../../ViewSwitch/ViewModeContext';
import MindItemListItem from '../../cards/MindItemListItem';
import ProductListItem from '../../cards/ProductListItem';
import MuTabs from '../../common/MuTabs';
import MuTabsNewUI from '../../common/MuTabsNewUI';
import { SearchFilters, SearchResultsTab } from '../../../types/search';
import SearchResultsHeader from './SearchResultsHeader';
import VideoMindItemCard from '../../cards/VideoMindItemCard';
import SortByFilter from '../../SearchFilters/SortByFilter';
import useQueryModification from '../../../routes/SearchPage/useQueryModification';
import useRouter from '../../../utils/useRouter';
import { MindItemSearchResult } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import { cardDimensions, spaces } from '../../../assets/styles/themes/tokens';
import { Box, CircularProgress, Divider, Theme, Typography, useMediaQuery } from '@mui/material';
import { trackingEvents } from '../../../services/tracking/trackConfig';
import WithTracking from '../../utils/WithTracking';
import ProductMuiCard, { ProductMuiCardProps } from '../../cards/MuiCard/ProductMuiCard/ProductMuiCard';
import MindItemMuiCard, { MindItemMuiCardProps } from '../../cards/MuiCard/MindItemMuiCard/MindItemMuiCard';
import VideoMuiCard, { VideoMuiCardProps } from '../../cards/MuiCard/VideoMuiCard/VideoMuiCard';
import { MuiCardCommonProps } from '../../cards/MuiCard/MuiCardBase/MuiCardBase';
import { ProductSearchResult } from '@klab-berlin/api-sdk/lib/types/responses/Product';
import { useNewDesign } from '../../../state/DesignPreferenceContextProvider';
import OnboardingDialog, { OnboardingDialogProps } from '../../OnboardingDialog/OnboardingDialog';
import newUiDialogImage from '../../../assets/new-ui-dialog.png';
import newUiDialogImageMobile from '../../../assets/new-ui-dialog-mobile.png';
import config from 'config';
import { SearchResultsTabType } from '../../common/MuTabsNewUI/MuTabsNewUI';
import SortByFilterNewUI from '../../SearchFilters/SortByFilterNewUI';
import searchResultsStorageManager from '../../../utils/searchResultsStorageManager';
import EmptySearchResultsNewUI from './EmptySearchResultsNewUI';
import { getNewUiSearchPagePadding } from '../../../routes/SearchPage/SearchPageContainer';
import { SearchCompleted } from '../../../ampli';
import { getAmpliSearchEventPayload } from '../../../utils/ampli';
import services from '../../../services';
import getTextTruncationSx from '../../../utils/getTextTruncationSx';

interface SearchResultsProps {
  searchMindItems: () => void;
  searchProducts: () => void;
  searchUnified: () => void;
  searchVideoMindItems: () => void;
  currentTab: SearchResultsTab;
  setCurrentTab: (tab: SearchResultsTab) => void;
  isNewSearch: boolean;
  requestFilters: SearchFilters;
  trackingPayload: { other: Record<string, unknown> };
  searchTerm?: string;
}

const mapCurrentTabToSearchStateKey = (currentTab: SearchResultsTab) => {
  switch (currentTab) {
    case SearchResultsTab.MINDITEMS:
      return 'mindItems';
    case SearchResultsTab.PRODUCTS:
      return 'products';
    case SearchResultsTab.VIDEOS:
      return 'videoMindItems';
    case SearchResultsTab.ALL:
      return 'all';
  }
};

const debounceTime = 500;

const SearchResults: React.FC<SearchResultsProps> = (props) => {
  const { t } = useTranslation();
  const { viewMode } = useViewMode();
  const mindItems = useSelector(state => getSearchResultsSelector(state, 'mindItems'));
  const mindItemsPagination = useSelector(getPaginationSelector('mindItems'));
  const videoMindItems = useSelector(state => getSearchResultsSelector(state, 'videoMindItems'));
  const videoMindItemsPagination = useSelector(getPaginationSelector('videoMindItems'));
  const products = useSelector(state => getSearchResultsSelector(state, 'products'));
  const productsPagination = useSelector(getPaginationSelector('products'));
  const unified = useSelector(state => getUnifiedSearchResultsSelector(state));
  const unifiedPagination = useSelector(getUnifiedPaginationSelector);
  const mindItemLoadingState = useSelector(getMindItemLoadingStateSelector);
  const videoMindItemsLoadingState = useSelector(getVideoMindItemsLoadingStateSelector);
  const productLoadingState = useSelector(getProductLoadingStateSelector);
  const unifiedLoadingState = useSelector(getUnifiedLoadingStateSelector);
  const [ sentSearchCompleted, setSentSearchCompleted] = useState(false);
  const user = useSelector(selectUser);
  const {
    prefersNewDesign,
    setNewDesignPreference,
    setNewUIOnboardingComponentState,
    shouldShowDesignPreferenceSwitch,
  } = useNewDesign();

  const userActionConfig = {
    hideCollectionAction: user?.hideCollectionAction,
    hidePinAction: user?.hidePinAction,
  };

  const mapTabToPagination = {
    [SearchResultsTab.MINDITEMS]: mindItemsPagination,
    [SearchResultsTab.PRODUCTS]: productsPagination,
    [SearchResultsTab.VIDEOS]: videoMindItemsPagination,
    [SearchResultsTab.ALL]: unifiedPagination,
  };

  const mapTabToEventName:{[key: string]: 'document' | 'product' | 'video'} = {
    [SearchResultsTab.MINDITEMS]: 'document',
    [SearchResultsTab.PRODUCTS]: 'product',
    [SearchResultsTab.VIDEOS]: 'video',
    [SearchResultsTab.ALL]: 'document',
  };

  const searchStateKey = mapCurrentTabToSearchStateKey(props.currentTab);
  const saveScrollProperties = (currentTab: SearchResultsTab) => {
    searchResultsStorageManager.setSearchStateInstance(
      mapCurrentTabToSearchStateKey(currentTab),
      mapTabToPagination[currentTab].offset,
      window.scrollY
    );
  };

  const scroll = () => {
    const searchStateInstance = searchResultsStorageManager.getSearchStateInstance(searchStateKey);
    if (!_.isEmpty(searchStateInstance)) {
      const { scrollPosition } = searchStateInstance;
      window.scrollTo(0, scrollPosition);
    } else {
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    const resetStorageManager = () => {
      searchResultsStorageManager.clearSearchState();
    };

    window.addEventListener('beforeunload', resetStorageManager);

    return () => {
      window.removeEventListener('beforeunload', resetStorageManager);
    };
  }, []);

  useEffect(() => {
    const handleScroll = debounce(() => {
      saveScrollProperties(props.currentTab);
    }, debounceTime);

    window.addEventListener('scroll', handleScroll);
    scroll();

    return () => {
      handleScroll.cancel();
      window.removeEventListener('scroll', handleScroll);
    };
  }, [props.currentTab]);

  useEffect(() => {
    const someInitialStates = mindItemLoadingState === SearchLoadingStatus.INITIAL ||
      videoMindItemsLoadingState === SearchLoadingStatus.INITIAL ||
      productLoadingState === SearchLoadingStatus.INITIAL;
    if (!sentSearchCompleted) {
      const fullyLoaded = mindItemLoadingState === SearchLoadingStatus.LOADED &&
        videoMindItemsLoadingState === SearchLoadingStatus.LOADED &&
        productLoadingState === SearchLoadingStatus.LOADED;

      if (fullyLoaded) {
        const ampliSearchEventPayload = getAmpliSearchEventPayload(props.trackingPayload.other);

        const event = new SearchCompleted({
          'search results tab': mapTabToEventName[props.currentTab],
          ui: prefersNewDesign ? 'new' : 'classic',
          'nr of minditem search results': mapTabToPagination[SearchResultsTab.MINDITEMS].total,
          'nr of product search results': mapTabToPagination[SearchResultsTab.PRODUCTS].total,
          'nr of video search results': mapTabToPagination[SearchResultsTab.VIDEOS].total,
          'search term': ampliSearchEventPayload['search term'],
          'search filter': ampliSearchEventPayload['search filter'],
        } as any);

        services.track.ampliEventTrack({ event });
        setSentSearchCompleted(true);
      }
    } else if (someInitialStates) {
      setSentSearchCompleted(false);
    }
  }, [sentSearchCompleted, mindItemLoadingState, videoMindItemsLoadingState, productLoadingState]);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobile'));
  const { router, match } = useRouter();

  const showNewFilters = match.location.query['showNewFilters'] === 'true';

  const { updateQueryParams } = useQueryModification<SearchFilters>(router, match);
  const searchEngineVersion = props.isNewSearch ? 2 : 1;

  const getContainerTabs = (): SearchResultsTabType[] => {
    const tabs: SearchResultsTabType[] = [
      {
        key: SearchResultsTab.MINDITEMS,
        name: t('Worksheets'),
        icon: 'mindItemTab',
      },
      {
        key: SearchResultsTab.PRODUCTS,
        name: t('E-books'),
        icon: 'productTab',
      },
      {
        key: SearchResultsTab.VIDEOS,
        name: t('Videos'),
        icon: 'videoTab'
      }
    ];

    if (user?.isCoreMember) {
      tabs.unshift({ key: SearchResultsTab.ALL, name: t('All'), icon: 'alleTab' });
    }

    return tabs;
  };

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    props.setCurrentTab(getContainerTabs()[newValue].key);
  };

  const SearchResultsTopbarNewUI: React.FC<{
    total: number;
    searchTerm?: string;
    isLoaded: boolean;
  }> = ({ total, searchTerm, isLoaded = false }) => {
    if (!isLoaded) return null;
    const { t } = useTranslation();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobile'));
    const searchTermText = searchTerm ? `“${searchTerm}”` : t('all results');
    const totalText = total > 0 ? `${total} ` : null;
  
    return (
      <Box
        data-testid='search-results-topbar'
        sx={{
          display: 'inline-flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginY: spaces.md,
          color: 'text.primary',
          ...(isMobile ? { 
            flexDirection: 'column',
          } : {}),
        }}
      >
        <Box data-testid='search-results-topbar__total' sx={{
          ...(isMobile ? { marginRight: 'auto' } : {}),
        }}>
          <Typography
            data-testid='search-results-header-new-ui'
            variant='subtitle1'
            sx={{
              maxWidth: isMobile ? '92vw' : '58vw', // We need that line to crop the long search term
              color: 'text.primary',
              whiteSpace: 'nowrap',
              ...getTextTruncationSx()
            }}
          >
            <Trans i18nKey={'<totalText> search results for "<searchTermText>"'} values={{ totalText, searchTermText }}>
              <Box data-testid='search-results-header-new-ui__query'
                component='span' sx={{ fontWeight: '600' }}>{searchTermText}</Box>
            </Trans>
          </Typography>
        </Box>
        {
          (!isMobile || !showNewFilters) && <Box data-testid='sort-by-new-ui-filter' sx={{
            ...(isMobile ? { marginLeft: 'auto' } : {}),
          }}>
            <SortByFilterNewUI
              currentValue={props.requestFilters.sortBy}
              updateFilter={(sortBy) => updateQueryParams({ sortBy })}
              clearFilter={() => updateQueryParams({ 'sortBy': searchSettings.defaultSortBy })}
            />
          </Box>
        }
      </Box>
    );
  };

  const SearchResultsTopbarOldUI: React.FC<{
    total: number;
    loadedCount: number;
    isLoaded: boolean;
  }> = ({ total, loadedCount, isLoaded = false }) => {
    const text = total === 1 ? t('Result') : t('Results');
    return (
      <div className='search-results__topbar'>
        <div className='search-results__header' data-testid='search-results-header-old-ui'>
          {
            !isLoaded ?
              null :
              <SearchResultsHeader
                totalText={`${total} ${text}`}
                loadedCount={loadedCount}
              />
          }
        </div>
        <div className='search-results__sort' data-testid='sort-by-filter'>
          <SortByFilter
            currentValue={props.requestFilters.sortBy}
            updateFilter={(sortBy) => updateQueryParams({ sortBy })}
            clearFilter={() => updateQueryParams({ 'sortBy': searchSettings.defaultSortBy })}
          />
        </div>
      </div>
    );
  };
  
  const getCommonCardProps = (item: MindItemSearchResult | ProductSearchResult): MuiCardCommonProps => {
    return {
      id: item.id,
      publisher: item.publisher || '',
      description: item.description || '',
      subjects: item.subjects,
      cover: item.thumbnail,
      title: item.title,
      classYears: item.classYears,
      isNew: item.isNew,
      date: item.publisherReleaseDate,
      isReadOnly: item.isReadOnly,
      isPinned: item.isPinned,
      itemData: item,
    };
  };

  const getVideoMuiCardProps = (item: MindItemSearchResult): VideoMuiCardProps => {
    return {
      ...getCommonCardProps(item),
      product: item.populatedProduct,
    };
  };

  const getMindItemMuiCardProps = (item: MindItemSearchResult): MindItemMuiCardProps => {
    return {
      ...getCommonCardProps(item),
      numberOfPages: item.pageCount || undefined,
      product: item.populatedProduct,
    };
  };

  const getProductMuiCardProps = (item: ProductSearchResult): ProductMuiCardProps => {
    return {
      ...getCommonCardProps(item),
      numberOfAttachments: item.attachmentsCount,
      numberOfMindItems: item.mindItemsCount,
    };
  };

  const newUILaunchDialogBody = () => (
    <Box sx={{ paddingTop: !isMobile ? 0 : spaces.md }}>
      <Typography variant='body1'>
        { t('Test the new design of meinLesson and enjoy an improved overview and handling.') }
      </Typography>
      <Typography variant='body1' sx={{ paddingTop: spaces.xl }}>
        { t('Do you prefer to still use the old design? Then simply close this message.') }
      </Typography>
    </Box>
  );

  let showMediumOnboardingComponent: boolean = true;
  const newUILaunchDialogBodyProps: OnboardingDialogProps = {
    id: onboardingHints.newUIBanner,
    title: t('Welcome to the new design'),
    image: !isMobile ? newUiDialogImage : newUiDialogImageMobile,
    imageStyles: { height: 250, marginBottom: '0px !important', marginTop: `-${spaces.md}!important` },
    primaryCta: {
      label: t('Try it now!'),
      onClick: () => {
        showMediumOnboardingComponent = false;

        setNewDesignPreference(true);
        setNewUIOnboardingComponentState('closed');
        return;
      },
      dismissOnAction: true,
    },
    secondaryCta: {
      label: t('Learn more'),
      onClick: async () => {
        setNewUIOnboardingComponentState('visible');
        window.open(config.newUIOnboardingModal.url, '_blank');
        return;
      },
      dismissOnAction: true,
    },
    onClose: () => {
      if (showMediumOnboardingComponent) {
        setNewUIOnboardingComponentState('visible');
      }
    },
    htmlBody: newUILaunchDialogBody(),
  };

  const LoadingAnimation = () => (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    }}>
      <CircularProgress
        sx={{
          '& .MuiCircularProgress-svg': {
            height: 'auto',
            width: 'auto',
          },
        }} />
    </Box>
  );

  return (
    <Box className='search-results'>
      {shouldShowDesignPreferenceSwitch && !prefersNewDesign && <OnboardingDialog {...newUILaunchDialogBodyProps} />}
      <Box>
        <Tab.Container
          activeKey={props.currentTab}
          onSelect={(key) => props.setCurrentTab(key as SearchResultsTab)}
        >
          <Box className={
            prefersNewDesign ?
              'search-results__tabs-container search-results__tabs-container-new-ui' :
              'search-results__tabs-container'}
          >
            {
              prefersNewDesign ?
                <Box sx={{
                  maxWidth: cardDimensions.desktop.maxWidth,
                  marginX: 'auto',
                  paddingX: getNewUiSearchPagePadding(),
                }}>
                  <MuTabsNewUI tabs={getContainerTabs()} handleChange={handleChange} value={props.currentTab} />
                </Box> :
                <MuTabs tabs={getContainerTabs()} />
            }
          </Box>
          {
            prefersNewDesign && 
            <Divider sx={{ 
              position: 'relative',
            }} />
          }
          <Box className='search-results__items' sx={prefersNewDesign ? { 
            maxWidth: cardDimensions.desktop.maxWidth,
            marginX: 'auto',
            paddingX: getNewUiSearchPagePadding(),
          } : {}}>
            <Tab.Content>
              {user?.isCoreMember && props.currentTab === 'all' &&
              <Tab.Pane eventKey={SearchResultsTab.ALL} data-testid='new-ui-all-tab'>
                {
                  prefersNewDesign 
                    ? <SearchResultsTopbarNewUI
                      total={unifiedPagination.total}
                      searchTerm={props.searchTerm}
                      isLoaded={unifiedLoadingState === SearchLoadingStatus.LOADED}
                    />
                    : <SearchResultsTopbarOldUI
                      total={unifiedPagination.total}
                      loadedCount={unified.length}
                      isLoaded={unifiedLoadingState === SearchLoadingStatus.LOADED}
                    />
                }
                {unifiedLoadingState === SearchLoadingStatus.INITIAL
                  ? <LoadingAnimation />
                  : <>
                    {unified.length > 0
                      ?
                      <InfiniteScroll
                        loadMore={props.searchUnified}
                        hasMore={hasNext(unifiedPagination)}
                        className='search-results__infinite-scroll-box'
                      >
                        { prefersNewDesign ?
                          <NewResultsContainer>
                            {
                              unified.map((item: any, index) => (
                                <NewResultFragment key={item.id}>
                                  {
                                    item.fileType === 'video' ?
                                      <WithTracking
                                        events={{
                                          onClick: trackingEvents.itemOpen,
                                          onMiddleClick: trackingEvents.itemOpen,
                                          onContextMenu: trackingEvents.itemOpen,
                                        }}
                                        eventPayload={{ other: {
                                          'minditem_id': item.id,
                                          index,
                                          searchEngineVersion
                                        } }}
                                      >
                                        <div>
                                          <VideoMuiCard {...getVideoMuiCardProps(item)} index={index} />
                                        </div>
                                      </WithTracking> :
                                      item.type === 'mindItem' ?
                                        <WithTracking
                                          events={{
                                            onClick: trackingEvents.itemOpen,
                                            onMiddleClick: trackingEvents.itemOpen,
                                            onContextMenu: trackingEvents.itemOpen,
                                          }}
                                          eventPayload={{ other: {
                                            'minditem_id': item.id,
                                            index,
                                            searchEngineVersion
                                          } }}
                                        >
                                          <div>
                                            <MindItemMuiCard {...getMindItemMuiCardProps(item)} index={index} />
                                          </div>
                                        </WithTracking> :
                                        <WithTracking
                                          events={{
                                            onClick: trackingEvents.itemOpen,
                                            onMiddleClick: trackingEvents.itemOpen,
                                            onContextMenu: trackingEvents.itemOpen,
                                          }}
                                          eventPayload={{
                                            other: {
                                              'product_id': item.id,
                                              index,
                                              searchEngineVersion
                                            }
                                          }}
                                        >
                                          <div>
                                            <ProductMuiCard {...getProductMuiCardProps(item)} index={index} />
                                          </div>
                                        </WithTracking>
                                  }
                                </NewResultFragment>
                              ))
                            }
                          </NewResultsContainer>
                          :
                          <div
                            className={classNames({
                              'search-results__item-grid': viewMode === 'grid',
                              'search-results__item-list': viewMode === 'list',
                            })}
                          >
                            {unified.map((item, index) => (<Link
                              key={item.id}
                              to={item.type !== 'product'
                                ? `/${routes.reader.root}/${item.id}`
                                : `/${routes.productPage.root}/${item.id}`}
                              className='search-results__link'
                            >
                              {viewMode === 'grid'
                                ? <MindItemCard
                                  {...(item as MindItemSearchResult)}
                                  {...userActionConfig}
                                  index={index}
                                  searchEngineVersion={searchEngineVersion}
                                />
                                : <MindItemListItem
                                  {...(item as MindItemSearchResult)}
                                  {...userActionConfig}
                                  index={index}
                                  searchEngineVersion={searchEngineVersion}
                                />}
                            </Link>))}
                          </div>
                        }
                      </InfiniteScroll> :
                      prefersNewDesign ?
                        <NewResultsContainer>
                          <EmptySearchResultsNewUI searchTerm={props.searchTerm} />
                        </NewResultsContainer> :
                        <EmptySearchResults />
                    }
                    {unifiedLoadingState === SearchLoadingStatus.PAGINATION  && <LoadingAnimation />}
                  </>
                }
              </Tab.Pane>
              }
              <Tab.Pane eventKey={SearchResultsTab.PRODUCTS} data-testid='new-ui-product-tab'>
                {
                  prefersNewDesign 
                    ? <SearchResultsTopbarNewUI
                      total={productsPagination.total}
                      searchTerm={props.searchTerm}
                      isLoaded={productLoadingState === SearchLoadingStatus.LOADED}
                    />
                    : <SearchResultsTopbarOldUI
                      total={productsPagination.total}
                      loadedCount={products.length}
                      isLoaded={productLoadingState === SearchLoadingStatus.LOADED}
                    />
                }
                {productLoadingState === SearchLoadingStatus.INITIAL
                  ? <LoadingAnimation />
                  : <>
                    {products.length > 0
                      ?
                      <InfiniteScroll
                        loadMore={props.searchProducts}
                        hasMore={hasNext(productsPagination)}
                        className='search-results__infinite-scroll-box'
                      >
                        {
                          prefersNewDesign ?
                            <NewResultsContainer>
                              {
                                products.map((product, index) => (
                                  <NewResultFragment key={product.id}>
                                    <WithTracking
                                      events={{
                                        onClick: trackingEvents.itemOpen,
                                        onMiddleClick: trackingEvents.itemOpen,
                                        onContextMenu: trackingEvents.itemOpen,
                                      }}
                                      eventPayload={{ other: { 'product_id': product.id, index, searchEngineVersion } }}
                                    >
                                      <div>
                                        <ProductMuiCard {...getProductMuiCardProps(product)} index={index} />
                                      </div>
                                    </WithTracking>
                                  </NewResultFragment>
                                ))
                              }
                            </NewResultsContainer>
                            :
                            <div
                              className={classNames({
                                'search-results__item-grid': viewMode === 'grid',
                                'search-results__item-list': viewMode === 'list',
                              })}
                            >
                              {products.map((product, index) => (
                                <Link
                                  key={product.id}
                                  to={`/${routes.productPage.root}/${product.id}`}
                                  className='search-results__link'
                                >
                                  {viewMode === 'grid'
                                    ? <ProductCard
                                      {...product}
                                      {...userActionConfig}
                                      index={index}
                                      searchEngineVersion={searchEngineVersion}
                                    />
                                    : <ProductListItem
                                      {...product}
                                      {...userActionConfig}
                                      index={index}
                                      searchEngineVersion={searchEngineVersion}
                                    />}
                                </Link>
                              ))}
                            </div>
                        }
                      </InfiniteScroll> :
                      prefersNewDesign ?
                        <NewResultsContainer>
                          <EmptySearchResultsNewUI searchTerm={props.searchTerm} />
                        </NewResultsContainer> :
                        <EmptySearchResults />
                    }
                    {productLoadingState === SearchLoadingStatus.PAGINATION && <LoadingAnimation />}
                  </>
                }
              </Tab.Pane>
              <Tab.Pane eventKey={SearchResultsTab.MINDITEMS} data-testid='new-ui-mindItem-tab'>
                {
                  prefersNewDesign 
                    ? <SearchResultsTopbarNewUI
                      total={mindItemsPagination.total}
                      searchTerm={props.searchTerm}
                      isLoaded={mindItemLoadingState === SearchLoadingStatus.LOADED}
                    />
                    : <SearchResultsTopbarOldUI
                      total={mindItemsPagination.total}
                      loadedCount={mindItems.length}
                      isLoaded={mindItemLoadingState === SearchLoadingStatus.LOADED}
                    />
                }
                {mindItemLoadingState === SearchLoadingStatus.INITIAL 
                  ? <LoadingAnimation />
                  : <>
                    {mindItems.length > 0
                      ? <InfiniteScroll
                        loadMore={props.searchMindItems}
                        hasMore={hasNext(mindItemsPagination)}
                        className='search-results__infinite-scroll-box'
                      >
                        {
                          prefersNewDesign ?
                            <NewResultsContainer>
                              {mindItems.map((mindItem, index) => (
                                <NewResultFragment key={mindItem.id}>
                                  <WithTracking
                                    events={{
                                      onClick: trackingEvents.itemOpen,
                                      onMiddleClick: trackingEvents.itemOpen,
                                      onContextMenu: trackingEvents.itemOpen,
                                    }}
                                    eventPayload={{ other: { 'minditem_id': mindItem.id, index, searchEngineVersion } }}
                                  >
                                    <div>
                                      {
                                        mindItem.fileType === 'video'
                                          ? <VideoMuiCard {...getVideoMuiCardProps(mindItem)} index={index} />
                                          : <MindItemMuiCard {...getMindItemMuiCardProps(mindItem)} index={index} />
                                      }
                                    </div>
                                  </WithTracking>
                                </NewResultFragment>
                              ))
                              }
                            </NewResultsContainer>
                            :
                            <div
                              className={classNames({
                                'search-results__item-grid': viewMode === 'grid',
                                'search-results__item-list': viewMode === 'list',
                              })}
                            >
                              {mindItems.map((mindItem, index) => (
                                <Link
                                  key={mindItem.id}
                                  to={`/${routes.reader.root}/${mindItem.id}`}
                                  className='search-results__link'
                                >
                                  {viewMode === 'grid'
                                    ? <MindItemCard
                                      {...mindItem}
                                      {...userActionConfig}
                                      index={index}
                                      searchEngineVersion={searchEngineVersion}
                                    />
                                    : <MindItemListItem
                                      {...mindItem}
                                      {...userActionConfig}
                                      index={index}
                                      searchEngineVersion={searchEngineVersion}
                                    />}
                                </Link>
                              ))}
                            </div>
                        }

                      </InfiniteScroll> :
                      prefersNewDesign ?
                        <NewResultsContainer>
                          <EmptySearchResultsNewUI searchTerm={props.searchTerm} />
                        </NewResultsContainer> :
                        <EmptySearchResults />
                    }
                    {mindItemLoadingState === SearchLoadingStatus.PAGINATION && <LoadingAnimation />}
                  </>
                }
              </Tab.Pane>
              <Tab.Pane eventKey={SearchResultsTab.VIDEOS} data-testid='new-ui-video-tab'>
                {
                  prefersNewDesign 
                    ? <SearchResultsTopbarNewUI
                      total={videoMindItemsPagination.total}
                      searchTerm={props.searchTerm}
                      isLoaded={videoMindItemsLoadingState === SearchLoadingStatus.LOADED}
                    />
                    : <SearchResultsTopbarOldUI
                      total={videoMindItemsPagination.total}
                      loadedCount={videoMindItems.length}
                      isLoaded={videoMindItemsLoadingState === SearchLoadingStatus.LOADED}
                    />
                }
                {videoMindItemsLoadingState === SearchLoadingStatus.INITIAL
                  ? <LoadingAnimation />
                  : <>
                    {videoMindItems.length > 0
                      ? <InfiniteScroll
                        loadMore={props.searchVideoMindItems}
                        hasMore={hasNext(videoMindItemsPagination)}
                        className='search-results__infinite-scroll-box'
                      >
                        {
                          prefersNewDesign ?
                            <NewResultsContainer>
                              {videoMindItems.map((videoMindItem, index) => (
                                <NewResultFragment key={videoMindItem.id}>
                                  <WithTracking
                                    events={{
                                      onClick: trackingEvents.itemOpen,
                                      onMiddleClick: trackingEvents.itemOpen,
                                      onContextMenu: trackingEvents.itemOpen,
                                    }}
                                    eventPayload={{ other: {
                                      'minditem_id': videoMindItem.id,
                                      index,
                                      searchEngineVersion
                                    } }}
                                  >
                                    <div>
                                      <VideoMuiCard {...getVideoMuiCardProps(videoMindItem)} index={index} />
                                    </div>
                                  </WithTracking>
                                </NewResultFragment>
                              ))
                              }
                            </NewResultsContainer>
                            :
                            <div
                              className={classNames({
                                'search-results__video-item-grid': viewMode === 'grid',
                                'search-results__item-list': viewMode === 'list',
                              })}
                            >
                              {videoMindItems.map((videoMindItem, index) => (
                                <Link
                                  key={videoMindItem.id}
                                  to={`/${routes.reader.root}/${videoMindItem.id}`}
                                  className='search-results__link'
                                >
                                  {viewMode === 'grid'
                                    ?
                                    <VideoMindItemCard
                                      {...videoMindItem}
                                      {...userActionConfig}
                                      index={index}
                                      searchEngineVersion={searchEngineVersion}
                                    />
                                    : <MindItemListItem
                                      {...videoMindItem}
                                      {...userActionConfig}
                                      index={index}
                                      searchEngineVersion={searchEngineVersion}
                                      hasWideThumbnail={true}
                                    />}
                                </Link>
                              ))}
                            </div>
                        }
                      </InfiniteScroll> :
                      prefersNewDesign ?
                        <NewResultsContainer>
                          <EmptySearchResultsNewUI searchTerm={props.searchTerm} />
                        </NewResultsContainer> :
                        <EmptySearchResults />
                    }
                    {videoMindItemsLoadingState === SearchLoadingStatus.PAGINATION && <LoadingAnimation />}
                  </>
                }
              </Tab.Pane>
            </Tab.Content>
          </Box >
        </Tab.Container>
      </Box>
    </Box>
  );
};

const NewResultsContainer: React.FC = (props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobile'));

  return <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    gap: isMobile ? spaces.sm : spaces.md,
    maxWidth: cardDimensions.desktop.maxWidth,
    marginX: 'auto',
  }}>
    { props.children }
  </Box>;
};

const NewResultFragment: React.FC = (props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobile'));
  return <React.Fragment>
    { props.children }
    { isMobile && <Box>
      <Divider component='div' sx={{
        minWidth: 'min(100%, 743px)',
        position: 'absolute',
        left: 0,
      }}/>
    </Box> }
  </React.Fragment>;
};

export default SearchResults;
