import { Editor } from '@tiptap/react';
import { useCallback } from 'react';

export interface TextmenuCommandsProps {
  onBold: () => void;
  onItalic: () => void;
  onUnderline: () => void;
  onStrike: () => void;
  onCode: () => void;
  onCodeBlock: () => void;
  onAlignLeft: () => void;
  onAlignCenter: () => void;
  onAlignRight: () => void;
  onAlignJustify: () => void;
}

export const getTextMenuCommands = (editor: Editor): TextmenuCommandsProps => {
  const onBold = useCallback(() => editor.chain().focus().toggleBold().run(), [editor]);
  const onItalic = useCallback(() => editor.chain().focus().toggleItalic().run(), [editor]);
  const onUnderline = useCallback(() => editor.chain().focus().toggleUnderline().run(), [editor]);
  const onStrike = useCallback(() => editor.chain().focus().toggleStrike().run(), [editor]);
  const onCode = useCallback(() => editor.chain().focus().toggleCode().run(), [editor]);
  const onCodeBlock = useCallback(() => editor.chain().focus().toggleCodeBlock().run(), [editor]);
  const onAlignLeft = useCallback(() => editor.chain().focus().setTextAlign('left').run(), [editor]);
  const onAlignCenter = useCallback(() => editor.chain().focus().setTextAlign('center').run(), [editor]);
  const onAlignRight = useCallback(() => editor.chain().focus().setTextAlign('right').run(), [editor]);
  const onAlignJustify = useCallback(() => editor.chain().focus().setTextAlign('justify').run(), [editor]);

  return {
    onBold,
    onItalic,
    onUnderline,
    onStrike,
    onCode,
    onCodeBlock,
    onAlignLeft,
    onAlignCenter,
    onAlignRight,
    onAlignJustify,
  };
};
