import { WithRouter, withRouter } from 'found';
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { fetchCollectionContents, fetchCollections } from '../actions/collections.actions';
import Jumbotron from '../components/Jumbotron';
import CollectionsAppLayout from '../components/CollectionsAppLayout';
import ContextControlButton from '../components/contexts/ContextControlButton';
import ItemContext from '../components/contexts/ItemContext';
import ItemsList from '../components/ItemsList';
import PinnedContentsContext from '../components/contexts/PinnedContentsContext';
import withAsyncData from '../components/utils/withAsyncData';
import { routes } from '../constants';
import { StoreType } from '../reducers';
import Breadcrumbs from '../components/Breadcrumbs';
import { Collection, Pagination } from '../types/AppContent';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import {
  selectCollectionWithContents,
  selectCollectionStatus,
  CollectionTypes
} from '../reducers/collections.selectors';
import { CurrentCollection } from '../state/CurrentCollection';
import {
  addItemToCollection,
  deleteItemFromCollection,
} from '../actions/collections.actions';
import { createPaginationRequest, hasNext } from '../utils/pagination';
import { ampliTrackCollectionViewedEvent } from '../services/track.service';
import { selectUser } from '../reducers/user.selectors';

interface StateProps {
  collection?: Collection;
  collectionStatus: AsyncActionStatus;
  collectionPagination: Pagination;
}

interface DispatchProps {
  fetchCollectionContents: typeof fetchCollectionContents;
  addItemToCollection: typeof addItemToCollection;
  deleteItemFromCollection: typeof deleteItemFromCollection;
}

interface OwnProps {
  match: {
    params: {
      collectionId: string;
    };
  };
  router: {
    push: (location: string) => void;
  };
}

type OwnCollectionItemListPageProps = StateProps & DispatchProps & OwnProps & WithRouter;

const OwnCollectionItemListPage: React.FC<OwnCollectionItemListPageProps> = ({
  match,
  collection,
  collectionStatus,
  router,
  ...props
}) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const collectionId = match.params.collectionId;

  if (!collection && collectionStatus === AsyncActionStatus.NOTFOUND) {
    router.replace(`/${routes.collections.root}`);
  }

  if (!collection) {
    return null;
  }

  useEffect(() => {
    props.fetchCollectionContents(collectionId, createPaginationRequest(props.collectionPagination));
    if (user && collection) {
      ampliTrackCollectionViewedEvent(collection);
    }
  }, [collectionId]);

  const [isPinnedDocumentsContextVisible, setPinnedDocumentsContextVisible] = useState(true);
  const [activeId, setActiveId] = useState('');
  const [collectionContext] = useState({ collectionId, ownedByCurrentUser: true });

  const starClickHandler = (visibility: boolean) => {
    setPinnedDocumentsContextVisible(visibility);
    setActiveId('');
  };

  const setActiveItemHandler = useCallback((id: string) => {
    setPinnedDocumentsContextVisible(false);
    setActiveId(id);
  }, [1]);

  const items = collection.contents;
  const activeItem = items.find((item) => item.id === activeId);

  return (
    <CollectionsAppLayout
      header={
        <>
          <Breadcrumbs breadcrumbs={[
            { to: `/${routes.collections.root}`, title: t('My Collections') },
            { to: `/${routes.collections.root}/${collectionId}`, title: collection.title },
          ]}/>
          <ContextControlButton className='ml-4'
            onStarClick={starClickHandler}
            contextState={isPinnedDocumentsContextVisible}
          />
        </>
      }
      mainBody={
        <>
          <CurrentCollection.Provider value={collectionContext}>
            <Jumbotron collection={collection} />
            <ItemsList
              isLoading={collectionStatus === 'loading'}
              hasNext={hasNext(props.collectionPagination)}
              total={props.collectionPagination.total}
              loadNext={() => {
                return props.fetchCollectionContents(collectionId, createPaginationRequest({
                  ...props.collectionPagination,
                  offset: props.collectionPagination.offset + props.collectionPagination.limit,
                }));
              }}
              activeId={activeId}
              items={items}
              linkRoot={[routes.collections.root, collectionId]}
              setActiveId={setActiveItemHandler}
            />
          </CurrentCollection.Provider>
        </>
      }
      context={
        isPinnedDocumentsContextVisible
          ? <PinnedContentsContext
            collection={collection}
            addItemToCollection={props.addItemToCollection}
            deleteItemFromCollection={props.deleteItemFromCollection}
          />
          : <ItemContext item={activeItem} />
      }
      setActiveId={setActiveId}
    />
  );
};

const mapState = (state: StoreType, props: OwnProps): StateProps => ({
  collection: selectCollectionWithContents(state, props.match.params.collectionId, CollectionTypes.OWN),
  collectionStatus: selectCollectionStatus(state, props.match.params.collectionId, CollectionTypes.OWN),
  collectionPagination: state.collections.byId[props.match.params.collectionId] &&
    state.collections.byId[props.match.params.collectionId].pagination,
});

export default compose<React.FC<Record<string,never>>>(
  withRouter,
  connect(
    mapState,
    {
      addItemToCollection,
      deleteItemFromCollection,
      fetchCollections,
      fetchCollectionContents
    },
  ),
  withAsyncData((props) => ({
    refetch: () => props.fetchCollections(),
    dataProp: 'collection',
    statusProp: 'collectionStatus',
  })),
)(OwnCollectionItemListPage);
