import { WithRouter, withRouter } from 'found';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { fetchCollectionContents, fetchCollections } from '../actions/collections.actions';
import Jumbotron from '../components/Jumbotron';
import CollectionsAppLayout from '../components/CollectionsAppLayout';
import ItemContext from '../components/contexts/ItemContext';
import ItemsList from '../components/ItemsList';
import withAsyncData from '../components/utils/withAsyncData';
import { StoreType } from '../reducers';
import { createPaginationRequest, hasNext } from '../utils/pagination';
import Breadcrumbs from '../components/Breadcrumbs';
import { Collection, Pagination } from '../types/AppContent';
import { selectCollectionWithContents, selectCollectionStatus } from '../reducers/collections.selectors';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import { CurrentCollection } from '../state/CurrentCollection';
import { ampliTrackCollectionViewedEvent } from '../services/track.service';
import { selectUser } from '../reducers/user.selectors';

interface StateProps {
  collection?: Collection;
  collectionStatus: AsyncActionStatus;
  collectionPagination: Pagination;
  user: StoreType['user'];
}
interface DispatchProps {
  fetchCollectionContents: typeof fetchCollectionContents;
}
interface OwnProps {
  match: {
    params: {
      collectionId: string;
    };
  };
}

type CuratedCollectionItemListPageProps = DispatchProps & StateProps & OwnProps & WithRouter;

const CuratedCollectionItemListPage: React.FC<CuratedCollectionItemListPageProps> = ({
  match,
  collection,
  collectionStatus,
  ...props
}) => {
  const collectionId = match.params.collectionId;
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  useEffect(() => {
    props.fetchCollectionContents(collectionId, createPaginationRequest(props.collectionPagination));
    if (user && collection) {
      ampliTrackCollectionViewedEvent(collection);
    }
  }, [collectionId]);

  const [activeId, setActiveId] = useState('');

  if (!collection) {
    return null;
  }

  const items = collection.contents;
  const activeItem = items.find((item) => item.id === activeId);

  const ownedByCurrentUser = user && (user.id === collection.owner) || false;
  const collectionContext = { collectionId, ownedByCurrentUser };

  return (
    <CollectionsAppLayout
      header={
        <Breadcrumbs breadcrumbs={[
          { to: '/kuratierte-kollektionen', title: t('Curated Collections') },
          { to: `/kuratierte-kollektionen/${collection.id}`, title: collection.title },
        ]} />
      }
      mainBody={
        <>
          <CurrentCollection.Provider value={collectionContext}>
            <Jumbotron collection={collection} />
            <ItemsList
              isLoading={collectionStatus === 'loading'}
              hasNext={hasNext(props.collectionPagination)}
              total={props.collectionPagination.total}
              loadNext={() => {
                return props.fetchCollectionContents(collectionId, createPaginationRequest({
                  ...props.collectionPagination,
                  offset: props.collectionPagination.offset + props.collectionPagination.limit,
                }));
              }}
              activeId={activeId}
              items={items}
              linkRoot={['kuratierte-kollektionen', collectionId]}
              setActiveId={setActiveId}
            />
          </CurrentCollection.Provider>
        </>
      }
      context={<ItemContext item={activeItem} />}
      setActiveId={setActiveId}
    />
  );
};

const mapState = (state: StoreType, props: OwnProps): StateProps => ({
  collection: selectCollectionWithContents(state, props.match.params.collectionId),
  collectionStatus: selectCollectionStatus(state, props.match.params.collectionId),
  collectionPagination: state.collections.byId[props.match.params.collectionId] &&
    state.collections.byId[props.match.params.collectionId].pagination,
  user: state.user,
});

export default compose<React.FC<Record<string, unknown>>>(
  withRouter,
  connect(
    mapState,
    { fetchCollections, fetchCollectionContents },
  ),
  withAsyncData((props) => ({
    refetch: () => props.fetchCollections(),
    dataProp: 'collection',
    statusProp: 'collectionStatus'
  })),
)(CuratedCollectionItemListPage);
