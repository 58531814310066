import classNames from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';
import WithTooltip from '../WithTooltip';
import { IconSize } from '../Icon/IconTypes';
import './ViewSwitch.scss';
import { ItemViewMode } from '../../types/AppContent';
import { useViewMode } from './ViewModeContext';

interface ViewSwitchProps {
  listSize: number;
  iconSize?: IconSize;
}

const ViewSwitch: React.FC<ViewSwitchProps> = ({ iconSize = 'xl' }) => {
  const { t } = useTranslation();
  const { viewMode, setViewMode } = useViewMode();

  const handleClick = (vm: ItemViewMode) => {
    if (vm === viewMode) {
      return;
    }

    setViewMode(vm);
  };
  
  return (
    <div className='view-switch'>
      <WithTooltip text={t('Gridview')}>
        <Button
          onClick={() => handleClick('grid')}
          className={classNames(
            { 'color--grey-light': viewMode !== 'grid' },
            { 'color--highlight-blue': viewMode === 'grid' },
            'view-switch__button'
          )}
          variant='link'
          data-testid='grid-view-button'
        >
          <Icon icon='grid' size={iconSize} />
        </Button>
      </WithTooltip>
      <WithTooltip text={t('Listview')}>
        <Button
          onClick={() => handleClick('list')}
          className={classNames(
            { 'color--grey-light': viewMode !== 'list' },
            { 'color--highlight-blue': viewMode === 'list' },
            'view-switch__button'
          )}
          variant='link'
          data-testid='list-view-button'
        >
          <Icon icon='list' size={iconSize} />
        </Button>
      </WithTooltip>
    </div>
  );
};

export default ViewSwitch;
