import React from 'react';
import { Trans } from 'react-i18next';
import DownloadLimitExceededBasis from './DownloadLimitExceededBasis';

interface DownloadLimitExceededProps {
  isPremium: boolean;
  downloadCount: number;
}

const DownloadLimitExceededPremium: React.FC = () => {
  return (
    <div>
      <Trans i18nKey='Download limit exceeded for a premium user'>
        <p>
          Unfortunately this does not work at the moment,
          because you have already used up your 100 possible downloads this month. 😕
        </p>
        <p>
          Tip: It&#39;s best to add this content to your watchlist
          so that you can download it directly in the next calendar month!
        </p>
      </Trans>
    </div>
  );
};

const DownloadLimitExceeded: React.FC<DownloadLimitExceededProps> = (props) => {
  return (
    <>
      {!props.isPremium && <DownloadLimitExceededBasis downloadCount={props.downloadCount} />}
      {props.isPremium && <DownloadLimitExceededPremium />}
    </>
  );
};

export default DownloadLimitExceeded;
