import { trackingEvents } from '../services/tracking/trackConfig';
import { EventInputData } from '../types/tracking';
import { CollectionProperties } from '../types/AppContent';
import { ItemVariation } from '@klab-berlin/api-sdk/lib/types/responses/Collection';

type FromType = 'menu' | 'context';

export const createPinTrackingConfig = (
  isPinned: boolean,
  itemType: ItemVariation,
  from: FromType,
  payload: any
) => {
  const eventPayload: EventInputData = {};
  const events = {
    onClick: {},
  };

  if (itemType === 'mindItem') {
    eventPayload.document = payload;
    if (from === 'context') {
      events.onClick = isPinned
        ? trackingEvents.mindItemContextUnpin
        : trackingEvents.mindItemContextPin;
    }

    if (from === 'menu') {
      events.onClick = isPinned
        ? trackingEvents.mindItemMenuUnpin
        : trackingEvents.mindItemMenuPin;
    }
  }

  if (itemType === 'product') {
    eventPayload.product = payload;

    if (from === 'context') {
      events.onClick = isPinned
        ? trackingEvents.productContextUnpin
        : trackingEvents.productContextPin;
    }

    if (from === 'menu') {
      events.onClick = isPinned
        ? trackingEvents.productMenuUnpin
        : trackingEvents.productMenuPin;
    }
  }

  return {
    eventPayload,
    events,
  };
};

export const createFollowCuratedCollectionTrackingConfig = (
  isFollowed: boolean,
  collection: CollectionProperties
) => {
  const events = {
    onClick: isFollowed
      ? trackingEvents.curatedCollectionUnfollow
      : trackingEvents.curatedCollectionFollow,
  };

  const eventPayload: EventInputData = { collection };

  return {
    eventPayload,
    events,
  };
};

export type NavItemType =
  | 'start'
  | 'search'
  | 'pinnedDocuments'
  | 'premium'
  | 'menu'
  | 'profile'
  | 'schoolOffer'
  | 'contact'
  | 'logout'
  | 'faq'
  | 'imprint'
  | 'agb'
  | 'privacyPolicy'
  | 'intercom'
  | 'collections'
  | 'downloads'
  | 'followedCollections'
  | 'publicCollections';

export type CarouselTrackingPropertiesGTM = {
  carousel_school_types?: string[];
  carousel_subject_texts?: string[];
  carousel_document_ids: string[];
  carousel_type:
    | 'placeholder'
    | 'carousel'
    | 'pinnedDocuments'
    | 'recommendedProducts'
    | 'recommendations';
  carousel_position: number;
  carousel_class_years?: number[];
  carousel_subject?: string;
};
