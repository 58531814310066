import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ScopedCssBaseline, ThemeProvider } from '@mui/material';
import ExportDialog from '../../lernsets/ExportDialog/ExportDialog';
import lernsetsTheme from '../../../assets/styles/themes/lernsetsTheme';
import Button from '../../common/Button';
import { useSelector } from 'react-redux';
import { selectMindItem } from '../../../reducers/mindItems.selectors';
import { getAmpliContentEventPayload } from '../../../utils/ampli';
import services from '../../../services';
import { ExportButtonClicked } from '../../../ampli';
import './lernsetsCta.scss';
import { OnboardingHint } from '../../OnboardingHint';
import { onboardingHints } from '../../../constants';

interface LernsetsCTAProps {
  mindItemId: string;
}

const LernsetsCTA = (props: LernsetsCTAProps) => {
  const { t } = useTranslation();
  const { mindItemId } = props;
  const [isExportDialogVisible, setIsExportDialogVisible] = useState(false);
  const mindItemData = useSelector(state => selectMindItem(state, mindItemId));

  const onClick = () => {
    setIsExportDialogVisible(true);

    if (mindItemData) {
      const eventProperties = getAmpliContentEventPayload(mindItemData, 'mindItem');

      services.track.ampliEventTrack({ 
        event: new ExportButtonClicked(eventProperties as any)
      });
    }
  };

  return (
    <ThemeProvider theme={lernsetsTheme}>
      <ScopedCssBaseline />
      <OnboardingHint 
        id={onboardingHints.lernsetsReaderCTA}
        placement='bottom' 
        description={t('With Lernsets you can easily adapt and combine materials.')}
        maxWidth={317}
        disablePortal={false}
      >
        <Box>
          <Button
            icon='edit'
            variant='subtle'
            onClick={onClick}
            type='button'
            className='lernsets-reader-cta'
          >
            {t('Edit in Lernsets')}
          </Button>
        </Box>
      </OnboardingHint>
      <ExportDialog
        open={isExportDialogVisible}
        onClose={() => setIsExportDialogVisible(false)}
        mindItemId={mindItemId}
      />
    </ThemeProvider>
  );
};

export default LernsetsCTA;
