import React from 'react';
import { useTranslation } from 'react-i18next';
import { routes } from '../../../../constants';
import MuiCardBase, { MuiCardCommonProps } from '../MuiCardBase/MuiCardBase';

export interface MindItemMuiCardProps extends MuiCardCommonProps {
  numberOfPages: number | undefined;
  product?: {
    name: string;
    id: string;
  }
}

const MindItemMuiCard: React.FC<MindItemMuiCardProps> = (props) => {
  const { t } = useTranslation();
  return <MuiCardBase
    {...props}
    contentType='document'
    chipText={
      props.numberOfPages ? `${props.numberOfPages} ${props.numberOfPages === 1 ? t('Page') : t('Pages')}` : undefined
    }
    link={props.product? {
      to: `/${routes.productPage.root}/${props.product.id}`,
      label: props.product.name,
      icon: 'book',
      hasTooltip: true,
    } : undefined}
  />;
};

export default MindItemMuiCard;
