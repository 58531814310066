import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SinglePagePaymentInfoAreaBackground from '../../../assets/single-page-payment-info-area-background.svg';
import PromoCodeForm from '../../../components/Upgrade/PromoCodeForm';
import AmountDueToday from '../../upgrade/UpgradePaymentPage/AmountDueToday';
import TotalPrice from './TotalPrice';
import { PaymentPeriod } from '@klab-berlin/api-sdk/lib/types/common';
import { DiscountInformation, LicenceModel } from '@klab-berlin/api-sdk/lib/types/responses/Billing';
import { currencyFormat } from '../../../utils/format';
import PaymentRhythmSelect from '../../../components/Upgrade/PaymentRhythmSelect';
import './licenceInfoArea.scss';
import PremiumTag from '../../../components/cards/common/PremiumTag';
import ToggleSwitch from '../../../components/common/ToggleSwitch';
import classnames from 'classnames';

interface LicenceInfoAreaProps {
  library?: string;
  licenceModel: LicenceModel;
  toggleSelectedLicenceModel: () => void;
  promoCode: string;
  setPromoCode: (promoCode: string) => void;
  paymentRhythm: PaymentPeriod;
  setPaymentRhythm: (paymentPeriod: PaymentPeriod) => void;
  isPublicPage?: boolean;  // TODO: Clean up (RED-26)
  setPromoCodeDiscountInfo: (discountInfo?: DiscountInformation) => void;
}

const LicenceInfoArea: React.FC<LicenceInfoAreaProps> = (
  { library, licenceModel, toggleSelectedLicenceModel, setPromoCode, paymentRhythm,
    promoCode, setPaymentRhythm, isPublicPage, setPromoCodeDiscountInfo: setDiscountInfo
  }
) => {
  const { t } = useTranslation();
  const [newTotalPrice, setNewTotalPrice] = useState<number|undefined>(undefined);
  const [promoCodeDiscountInfo, setPromoCodeDiscountInfo] = useState<DiscountInformation>();
  const defaultPaymentRhythm: PaymentPeriod = 'monthly';
  const hidePromoCode = licenceModel.isTrial && !promoCode;
  const isTrialLicence = licenceModel.isTrial;

  const handlePromoCodeValidated = (discountInformation: DiscountInformation, promoCode: string) => {
    setPromoCodeDiscountInfo(discountInformation);
    setDiscountInfo(discountInformation);
    setNewTotalPrice(discountInformation.newTotalPrice);
    setPromoCode(promoCode);
  };

  const handlePromoCodeActiveLayoutInit = () => {
    setPromoCodeDiscountInfo(undefined);
    setDiscountInfo(undefined);
    setNewTotalPrice(undefined);
  };

  const getPricePerMonth = () => promoCodeDiscountInfo?.newPricePerMonth 
    ? currencyFormat(promoCodeDiscountInfo?.newPricePerMonth)
    : currencyFormat(licenceModel.price);

  const renderLandingPageTitle = (landingPageTitle?: string) => {
    
    return (
      <div className='licence-info-area__content__title-container'>
        <h1 className={classnames(
          'licence-info-area__content__title',
          { 'licence-info-area__content__title--isPublicPage': isPublicPage }
        )}>
          {landingPageTitle || t('meinUnterricht')}
        </h1>
        <PremiumTag 
          isTrainee={licenceModel.isRef} 
          isPublicPage={isPublicPage}  
          className='licence-info-area__content__premium-tag'
        />
      </div>
    );
  };

  return (
    <div className='licence-info-area'>
      <div className='licence-info-area__content-container'>
        <div className='licence-info-area__content'>
          {renderLandingPageTitle(licenceModel.landingPageTitle)}
          {licenceModel.price <= 0 &&
            <h1 className={classnames(
              'licence-info-area__content__title',
              'licence-info-area__content__title--free',
              { 'licence-info-area__content__title--isPublicPage': isPublicPage }
            )}>
              {t('Free Licence')}
            </h1>}
          {
            isTrialLicence && !!licenceModel.displayTextLines &&
            <div
              data-testid='licence-info-area__display-text'
              className='licence-info-area__content__display-text-container'
            >
              {
                licenceModel.displayTextLines?.map(textLine => {
                  return (
                    <p key={textLine} className='licence-info-area__content__display-text'>
                      {`${textLine} `}
                    </p>
                  );
                })
              }
            </div>
          }
          {
            isTrialLicence &&
            !promoCode &&
            <div className='licence-info-area__trainee-switch'>
              <label>{t('Trainee Discount')}</label>
              <ToggleSwitch
                name='isTrainee'
                isToggledByDefault={licenceModel.isRef}
                onChange={toggleSelectedLicenceModel}
              />
            </div>
          }
          <p className='licence-info-area__content__subtitle'>
            {isTrialLicence ? t('After your trial') : t('Your chosen package')}
          </p>
          <p 
            className='licence-info-area__content__monthly-price' 
            data-testid='licence-info-area__monthly-price'
          >
            {t('<price> per month', { price: getPricePerMonth() })}
          </p>
          <p className='licence-info-area__content__duration'>
            {!library && t('Duration <period> months', { period: licenceModel.period })}
          </p>
        </div>
        {!library && <div className='licence-info-area__payment-details'>
          <hr />
          <div className='licence-info-area__payment-details__row'>
            <div>
              {t('Payment rhythm')}
            </div>
            <div>
              <PaymentRhythmSelect
                onPaymentRhythmChange={setPaymentRhythm}
                paymentRhythm={paymentRhythm}
                defaultPaymentRhythm={defaultPaymentRhythm}
                hideLabel={true}
                isSmall={false}
              />
            </div>
          </div>
          {
            hidePromoCode ? null :
              <>
                <hr />
                <PromoCodeForm
                  licenceId={licenceModel.licenceModelId}
                  initPromoCode={promoCode}
                  paymentRhythm={paymentRhythm}
                  onPromoCodeValidation={handlePromoCodeValidated}
                  onActiveLayoutInit={handlePromoCodeActiveLayoutInit}
                />
              </>
          }
          <hr />
          <AmountDueToday
            licenceModel={licenceModel}
            paymentRhythm={paymentRhythm}
            promoCodeDiscountInfo={promoCodeDiscountInfo}
          />
          <hr />
          <TotalPrice newTotalPrice={newTotalPrice} licenceModel={licenceModel} />
          <hr />
        </div>}
      </div>
      {
        !isPublicPage &&
      <div className='licence-info-area__image-container'>
        <SinglePagePaymentInfoAreaBackground className='licence-info-area__image' />
      </div>
      }
    </div>
  );
};

export default LicenceInfoArea;
