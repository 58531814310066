import React from 'react';
import { useSelector } from 'react-redux';
import { routes } from '../../constants';
import { selectIsAuthenticated } from '../../reducers/user.selectors';
import useRouter from '../../utils/useRouter';

const RedirectIfLoggedIn: React.FC = (props) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { router } = useRouter();

  if (isAuthenticated) {
    router.push(`/${routes.dashboard.root}`);
    return <></>;
  }

  return (
    <>
      {props.children}
    </>
  );
};

export default RedirectIfLoggedIn;
