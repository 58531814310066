exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".dashboard-search-bar{font-size:16px;line-height:24px;font-weight:inherit;height:46px}.dashboard-search-bar__subject-dropdown-toggle{background-color:#fff}@media only screen and (max-width: 575px){.dashboard-search-bar__subject-dropdown-toggle{display:none}}.dashboard-search-bar__subject-dropdown-toggle>.dropdown-toggle{border-color:#c0c8cc;border-right:0;border-radius:4px 0 0 4px !important;display:flex;align-items:center}.dashboard-search-bar__subject-dropdown-toggle>.dropdown-menu{z-index:1050}.dashboard-search-bar__subject-dropdown-list{max-height:90vh;display:flex;flex-direction:column}.dashboard-search-bar__subject-dropdown-list-section{overflow-y:auto}.dashboard-search-bar .z-index-dropdown{z-index:1050 !important}", ""]);
// Exports
exports.locals = {
	"collectionGridGap": "15px",
	"collectionGridItemHeight": "211px",
	"collectionGridItemWidth": "225px",
	"collectionListHeight": "84px",
	"greyLightest": "#f7f9fa",
	"greyLight2": "#d8d8d8",
	"neuVeryLightBlue": "#e9ebec",
	"muBlue": "#4298b5",
	"gridBreakPoints_-xs": "0",
	"gridBreakPoints_-sm": "576px",
	"gridBreakPoints_-md": "768px",
	"gridBreakPoints_-lg": "992px",
	"gridBreakPoints_-xl": "1440px",
	"medium": "768px",
	"large": "1024px",
	"xlarge": "1440px"
};