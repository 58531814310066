import React from 'react';
import { useTranslation } from 'react-i18next';
import TopBar from '../../components/AppLayout/TopBar';
import SchoolLicenceCard from '../../components/Upgrade/PackageCards/SchoolLicenceCard';
import './schoolInfoPage.scss';

const SchoolInfoPage = () => {
  const { t } = useTranslation();

  return (
    <div className='school-info-page'>
      <div className='school-info-page__top-bar'>
        <TopBar
          hideSearch={true}
          hideUpgradeToPremiumButton={true}
          hideUpgradeToSchoolLicenceButton={true}
          hideOpenCollections={true}
          hidePinnedDocuments={true}
          hideMenu={true}
        />
      </div>
      <div>
        <h1 className='school-info-page__title'>
          {t('meinUnterricht for schools')}
        </h1>
        <h2 className='school-info-page__subtitle'>
          {t('Secure access to the entire my-lessons library for you and your colleagues')}
        </h2>
      </div>
      <div className='school-info-page__school-licence-card-container'>
        <SchoolLicenceCard />
      </div>
    </div>
  );
};

export default SchoolInfoPage;
