import { useState, useEffect } from 'react';
import useMedia from '../useMedia';

export const useNavigationLayout = () => {
  const { isSmallViewport, isMediumViewport } = useMedia();
  const [renderNavigationMobileLayout, setRenderNavigationMobileLayout] = useState(false);
  const [showSidebarRight, setShowSidebarRight] = useState(true);

  useEffect(() => {
    setRenderNavigationMobileLayout(isSmallViewport);
    setShowSidebarRight(!isSmallViewport && !isMediumViewport);
  },[isSmallViewport, isMediumViewport]);

  return {
    renderNavigationMobileLayout,
    showSidebarRight,
    setShowSidebarRight
  };
};
