import React, { useState, useEffect } from 'react';
import { ZuoraPaymentMethod, ZuoraSignature } from '@klab-berlin/api-sdk/lib/services/billing';
import { Col, Row, Nav, Tab, Collapse } from 'react-bootstrap';
import services from '../../services';
import config from 'config';
import SepaSVG from '../../assets/payment_sepa.svg';
import PaypalSVG from '../../assets/payment_paypal.svg';
import CreditCardSVG from '../../assets/payment_creditcard.svg';
import PaypalForm from '../PaypalForm';
import PaymentTabPanel from './PaymentTabPanel/PaymentTabPanel';
import './singlePagePaymentMethods.scss';
import { ZuoraPaymentPageClientSideErrorHandler, ZuoraPaymentPageResponse, ZuoraPaymentPageResponseHandler }
  from '../../types/ZuoraPayments';
import { isZuoraPaymentObjectDefined, isEveryPayPalPaymentObjectDefined } from '../../utils/payment';
import { notifyBugsnagHandledError } from '../../utils/bugsnagClient';
import { sleepUntil } from '../../utils/sleep';

const PAYMENT_METHOD_CREDIT_CARD: ZuoraPaymentMethod = 'creditCard';
const PAYMENT_METHOD_SEPA: ZuoraPaymentMethod = 'sepa';
const PAYMENT_METHOD_PAYPAL: ZuoraPaymentMethod = 'braintree';

const renderPaymentPage = (
  signature: ZuoraSignature,
  paymentMethod: ZuoraPaymentMethod,
  onResponseHandler: ZuoraPaymentPageResponseHandler,
  onClientSideErrorHandler?: ZuoraPaymentPageClientSideErrorHandler,
) => {
  const params = {
    locale: 'de_DE',
    style: 'inline',
    submitEnabled: 'true',
    url: config.zuoraHostedPagesUri,
    id: signature.pageId,
    key: signature.key,
    signature: signature.signature,
    token: signature.token,
    tenantId: signature.tenantId,
  };

  const onClientSideErrorHandlerProxy = (key: string, code: string, message: string) => {
    // Send the error to the UI
    window.Z.sendErrorMessageToHpm(key, message);
    // Invoke our custom client-side-error-handler
    if (onClientSideErrorHandler) onClientSideErrorHandler(key, code, message);
  };

  window.Z.renderWithErrorHandler(
    params,
    {},
    (response: ZuoraPaymentPageResponse) => onResponseHandler(paymentMethod, response),
    onClientSideErrorHandlerProxy
  );
};

interface SinglePagePaymentMethodsProps {
  onSelect: (paymentMethod: ZuoraPaymentMethod) => void;
  onResponse: ZuoraPaymentPageResponseHandler;
  onClientSideErrorHandler?: ZuoraPaymentPageClientSideErrorHandler;
  selectedMethod: ZuoraPaymentMethod;
  showPaymentForm: boolean;
  onChange?: (clicked: boolean) => void;  // This prop is passed in by <Controller />
  zuoraHostedPagesSource: string;
  paymentProviderLoadTimeoutMs?: number;
}

const SinglePagePaymentMethods: React.FC<SinglePagePaymentMethodsProps> = (props) => {
  const [loadedPage, setLoadedPage] = useState<ZuoraPaymentMethod | undefined>();
  const [loadingError, setLoadingError] = useState<ZuoraPaymentMethod | undefined>();
  const [paypalToken, setPaypalToken] = useState('');
  const { paymentProviderLoadTimeoutMs = 12000 } = props;

  useEffect(() => {
    setupPaymentMethod(props.selectedMethod);
  }, [props.selectedMethod]);

  const handleSelect = (paymentMethod: ZuoraPaymentMethod) => {
    props.onSelect(paymentMethod);
    props.onChange?.(true);
  };

  const setupPaymentMethod = async (paymentMethod: ZuoraPaymentMethod) => {
    setLoadedPage(undefined);
    setLoadingError(undefined);

    if (paymentMethod === PAYMENT_METHOD_PAYPAL) {
      if (!isEveryPayPalPaymentObjectDefined()) {
        const error = `Not every PayPal payment object is defined. 
          !!window.braintree.client: ${!!window.braintree?.client}, 
          !!window.braintree.paypalCheckout: ${!!window.braintree?.paypalCheckout},
          !!window.paypal.Button: ${!!window.paypal?.Button},`.replace(/(\r\n|\n|\r)/gm, '').replace(/\s{2,}/g, ' ');

        notifyBugsnagHandledError(new Error(error));

        setLoadingError(paymentMethod);
        return;
      }

      services.billing.getBraintreeSignature()
        .then(data => setPaypalToken(data.token))
        .then(() => setLoadedPage(paymentMethod))
        .catch(() => setLoadingError(paymentMethod));
      return;
    }

    await sleepUntil(isZuoraPaymentObjectDefined, paymentProviderLoadTimeoutMs)
      .then(() => {
        services.billing.getSignature(paymentMethod, props.zuoraHostedPagesSource)
          .then((signature) =>
            renderPaymentPage(signature, paymentMethod, props.onResponse, props.onClientSideErrorHandler)
          )
          .then(() => setLoadedPage(paymentMethod))
          .catch(() => setLoadingError(paymentMethod));
      })
      .catch(() => {
        if (!isZuoraPaymentObjectDefined()) {
          const error = `Zuora payment object is not defined. !!window.Z: ${!!window.Z}`;
          notifyBugsnagHandledError(new Error(error));
        }

        setLoadingError(paymentMethod);
      });
  };

  return (
    <Tab.Container
      id='left-tabs-example'
      onSelect={(eventKey: any) => handleSelect(eventKey)}
      transition={false}
    >
      <Row className='payment-form__nav'>
        <Col>
          <Nav variant='pills' className='payment-form__nav'>
            <Nav.Item>
              <Nav.Link
                eventKey='sepa'
                data-testid='sepa-tab-button'
              >
                <SepaSVG className='svg--sepa' />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey='braintree'
                data-testid='paypal-tab-button'>
                <PaypalSVG className='svg--paypal' />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey='creditCard'
                data-testid='creditcard-tab-button'
              >
                <CreditCardSVG className='svg--creditcard' />
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>
      <Collapse in={props.showPaymentForm}>
        <Row className='payment-form__content'>
          <Col>
            <Tab.Content className={`${props.selectedMethod.toLocaleLowerCase()}-payment-form`}>
              <PaymentTabPanel
                method={PAYMENT_METHOD_SEPA}
                loadingError={loadingError === PAYMENT_METHOD_SEPA}
                isLoaded={loadedPage === PAYMENT_METHOD_SEPA}
                skeletonHeight={196}
              >
                {
                  props.selectedMethod === PAYMENT_METHOD_SEPA &&
                  <div id='zuora_payment' data-testid='sepa-payment-page' />
                }
              </PaymentTabPanel>
              <PaymentTabPanel
                method={PAYMENT_METHOD_PAYPAL}
                loadingError={loadingError === PAYMENT_METHOD_PAYPAL}
                isLoaded={loadedPage === PAYMENT_METHOD_PAYPAL}
                skeletonHeight={109}
              >
                {
                  props.selectedMethod === PAYMENT_METHOD_PAYPAL && !loadingError && loadedPage &&
                  <PaypalForm
                    token={paypalToken}
                    licenceId={1}
                    onResponse={props.onResponse}
                  />
                }
              </PaymentTabPanel>
              <PaymentTabPanel
                method={PAYMENT_METHOD_CREDIT_CARD}
                loadingError={loadingError === PAYMENT_METHOD_CREDIT_CARD}
                isLoaded={loadedPage === PAYMENT_METHOD_CREDIT_CARD}
                skeletonHeight={380}
              >
                {
                  props.selectedMethod === PAYMENT_METHOD_CREDIT_CARD &&
                  <div id='zuora_payment' data-testid='credit-card-payment-page' />
                }
              </PaymentTabPanel>
            </Tab.Content>
          </Col>
        </Row>
      </Collapse>
    </Tab.Container>
  );
};

export default SinglePagePaymentMethods;
