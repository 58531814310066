
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import './premiumTag.scss';

interface PremiumTagProps {
  isTrainee?: boolean;
  className?: string;
  isPublicPage?: boolean;  // TODO: Clean up (RED-26)
}

const PremiumTag:React.FC<PremiumTagProps> = ({ isTrainee, className, isPublicPage }) => {
  const { t } = useTranslation();
  return (
    <div 
      data-testid='premium-tag'
      className={
        classNames(
          'premium-tag',
          { 'premium-tag--isPublicPage': isPublicPage },
          className
        )
      }
    >
      { t(isTrainee ? 'For trainees' : 'Premium') }    
    </div>
  );
};

export default PremiumTag;
