import React, { useContext } from 'react';
import Intercom from 'react-intercom';
import config from 'config';
import mappers from '../../services/tracking/propertyMappers';
import { useSelector } from 'react-redux';
import { CookiebotContext } from '../../state/CookiebotContextProvider';
import useMedia from '../../utils/useMedia';
import { getSubscription, selectUser } from '../../reducers/user.selectors';
import useRouter from '../../utils/useRouter';
import { routes } from '../../constants';
import { isFeatureEnabled } from '../../utils/feature';

const IntercomButton = () => {
  const user = useSelector(selectUser);
  const subscription = useSelector(getSubscription);
  const { isMobile } = useMedia();
  const consent = useContext(CookiebotContext);

  const { match: { location: { pathname } } } = useRouter();

  const useIntercom = isFeatureEnabled('intercom') && consent.marketing;
  const isPublicReaderPage = pathname.includes(`/${routes.publicReader.root}/`);
  if (!useIntercom || isMobile || isPublicReaderPage) return null;

  return (
    <div className='absolute-top-0-left-0'>
      <div className='intercom-wrapper' data-testid='intercom'>
        <Intercom
          appID={config.intercom.appId}
          api_base={config.intercom.baseUrl}
          custom_launcher_selector='.intercom-custom-launcher'
          {...mappers.intercom(user, subscription)}
        />
      </div>
    </div>
  );
};

export default IntercomButton;
