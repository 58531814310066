import React from 'react';
import { Route, RouteProps } from 'found';
import RedirectIfWalkIn from './redirect/RedirectIfWalkIn';

export default class WalkInRestrictedRoute extends Route {
  public render(routeProps?: RouteProps) {
    if (!routeProps) {
      return null;
    }

    return (
      <RedirectIfWalkIn {...routeProps.props} />
    );
  }
}
