import { Box, Button, Stack, Typography, alpha } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../../assets/styles/themes/variables';
import getMuiIcon, { IconName } from '../../../../utils/getMuiIcon';
import useMedia from '../../../../utils/useMedia';
import { spaces } from '../../../../assets/styles/themes/tokens';
import config from 'config';

type DeviceSpecificBoxProps = {
  width: string;
  height: string;
  marginTop: string;
  iconType: IconName;
};
interface EmptySearchResultsNewUIProps {
  searchTerm?: string;
}

const EmptySearchResultsNewUI: React.FC<EmptySearchResultsNewUIProps> = ({ searchTerm }) => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useMedia();

  const baseStyleForZeroResultsImage = {
    position: 'absolute',
    overflow: 'hidden',
    right: '0',
    '&::before': {
      content: '""',
      display: 'block',
      paddingTop: '100%',
    },
    '& > svg': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  };

  const DeviceSpecificBox = ({ width, height, marginTop, iconType }: DeviceSpecificBoxProps) => (
    <Box
      sx={{
        ...baseStyleForZeroResultsImage,
        width,
        height,
        marginTop,
      }}
    >
      <Box
        component={getMuiIcon(iconType)}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      />
    </Box>
  );

  return (
    <Box data-testid='zero-results-new-ui' sx={{
      marginTop: isMobile ? `-${spaces.xxl}` : `-${spaces.xs}`,
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: isMobile ? spaces.xl : isTablet ? spaces.xxl: spaces.xxxl,
      }}>
        <Box component={getMuiIcon('invalidLink')} sx={{
          width: '375px',
          height: '300px',
        }}/>
        <Typography variant='h6' sx={{
          marginBottom: spaces.xs,
          color: colors.violet800,
        }}>
          {t('No results found')}
        </Typography>
        <Typography variant='body2' sx={{
          color: colors.violet700,
          maxWidth: '448px',
          textAlign: 'center',
        }}>
          {t('Your search for')} <Box component='span' sx={{
            fontWeight: '500',
            color: colors.violet800,
          }}> {`"${searchTerm}"`} </Box> {t('yielded no results')}
        </Typography>
      </Box>
      <Stack direction='column' sx={{
        padding: spaces.lg,
        border: `1px solid ${alpha(colors.violet800, 0.48)}`,
        borderRadius: spaces.lg,
      }}>
        <Typography variant='h6' sx={{
          color: colors.violet800,
          marginBottom: spaces.xs,
        }}>{t('Search tips:')}</Typography>
        <Box sx={{
          '& > div': {
            position: 'relative',
            paddingLeft: spaces.md,
            '&::before': {
              content: '"•"',
              position: 'absolute',
              left: 0,
              color: colors.violet700
            }
          }
        }}>
          <div><Typography variant='body2'>
            {t('Use different search criteria such as subject, topic, grade level, or type of material')}
          </Typography></div>
          <div><Typography variant='body2'>
            {t('Check your spelling')}
          </Typography></div>
          <div><Typography variant='body2'>
            {t('Broaden your search by using less specific or more general words')}
          </Typography></div>
          <div><Typography variant='body2'>
            {t('Reduce the number of filters set to get more search results')}
          </Typography></div>
        </Box>
      </Stack>
      <Stack direction='row' sx={{
        backgroundColor: colors.blue500,
        padding: spaces.lg,
        border: `1px solid ${colors.blue500}`,
        borderRadius: spaces.lg,
        marginTop: isMobile ? spaces.xs : isTablet ? spaces.sm : spaces.md,
        display: 'flex',
        ...(!isMobile ? { maxHeight: '186px' } : {}),
      }}>
        <Stack direction='column' sx={{ maxWidth: '540px' }}>
          <Typography variant='h6' sx={{
            marginBottom: spaces.xs,
            color: colors.neutral50,
          }}>{t('Help us improve our search')}</Typography>
          <Typography variant='body2' sx={{
            color: colors.blue50,
            alignSelf: 'flex-start',
            marginBottom: spaces.lg,
          }}>
            {t('Tell us about your difficulties and wishes so that we can improve my lessons and help you better.')}
          </Typography>
          <Button
            href={config.newUIZeroResultsModal.url}
            target='_blank'
            color='inherit'
            size='large'
            sx={{
              color: colors.neutral50,
              padding: `${spaces.xs} ${spaces.lg}`,
              border: `1px solid ${colors.neutral50}`,
              borderRadius: spaces.sm,
              width: 'auto',
              alignSelf: 'flex-start',
              '&:hover': {
                color:  colors.neutral50,
              }
            }}
          >
            {t('Send feedback')}
          </Button>
        </Stack>
      </Stack>
      {
        isMobile ? (
          <DeviceSpecificBox width='50px' height='auto' marginTop='-60px' iconType='zeroResultsMobile' />
        ) :
          isTablet ? (
            <DeviceSpecificBox width='250px' height='250px' marginTop='-200px' iconType='zeroResultsTablet' />
          ) :(
            <Box
              component={getMuiIcon('userTesting')}
              sx={{
                width: '250px',
                height: '250px',
                marginTop: '-200px',
                left: '68%',
                position: 'relative',
              }}
            />
          )
      }
    </Box>
  );
};

export default EmptySearchResultsNewUI;
