import merge from 'lodash.merge';
import {
  EventInputData,
  RequiredProperty,
  TrackingEvent,
  AmpliEvent,
  AmpliEventUserProperties
} from '../types/tracking';
import propertyMappers from './tracking/propertyMappers';
import { IntercomAPI } from 'react-intercom';
import api from '../clients/api';
import { isFeatureEnabled } from '../utils/feature';
import {
  CollectionCreated,
  CollectionDeleted,
  CollectionViewed,
  CollectionEdited,
  CollectionFollowed,
  CollectionUnfollowed,
  PricingPlansViewed,
  SubscriptionCancellationSelected,
  SubscriptionPauseSelected,
  SubscriptionPauseSubmitted,
  VideoUnlocked,
  ampli
} from '../ampli';
import { mapAmpliUserProperties } from '../utils/ampli';
import { notifyBugsnagNetwork } from '../utils/bugsnagClient';
import { AmplitudeTrackPayload } from '@klab-berlin/api-sdk/lib/types/requests/Track';
import { SubscriptionResponse } from '@klab-berlin/api-sdk/lib/types/responses/Billing';
import * as ampliUtils from '../utils/ampli';
import { CollectionProperties } from '../types/AppContent';
import { MindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import { Collection } from '../types/AppContent';
import { Collection as CollectionResponse } from '@klab-berlin/api-sdk/lib/types/responses/Collection';
import store from '../store';
import TagManager from 'react-gtm-module';
import { trackingEvents } from './tracking/trackConfig';

const populateTrackingData = async (requiredProperties: RequiredProperty[] = [], inputData: EventInputData) => {
  const promises = requiredProperties.map((property) => {
    const mapperFunc = propertyMappers[property];
    if (!mapperFunc) {
      return;
    }

    return mapperFunc(inputData[property]);
  });

  let flatResult = {};
  const result = await Promise.all(promises);
  requiredProperties.forEach((_, index) => {
    flatResult = Object.assign(flatResult, result[index]);
  });

  return flatResult;
};

const pageTrack = (location: string, eventInputData: EventInputData) => {
  const eventConfig: TrackingEvent = {
    requiredData: ['user'],
  };

  return populateTrackingData(eventConfig.requiredData, eventInputData)
    .then((requiredData) => {
      gtmPageTrack(location, requiredData);
    });
};

const eventTrack = (eventConfig: TrackingEvent, eventInputData: EventInputData = {}) => {
  const DEFAULT_FROM = window.location.pathname;

  const defaultPayload = {
    from: eventInputData.from || DEFAULT_FROM,
  };

  return populateTrackingData(eventConfig.requiredData, merge(eventConfig.defaultPayload, eventInputData))
    .then((requiredData) => {
      const payload = Object.assign(defaultPayload, requiredData);

      if (isFeatureEnabled('intercom') && eventConfig.intercom) {
        intercomEventTrack(eventConfig.intercom, payload);
      }
      
      if (
        isFeatureEnabled('googleAnalytics') && 
          eventConfig.gtm && eventConfig.gtm === trackingEvents.registerPremiumFinish.gtm
      ) {        
        gtmEventTrack(eventConfig.gtm, Object.assign(payload));
      }
    });
};

const gtmEventTrack = (eventName: string, eventPayload: any) => {
  const utm = propertyMappers.utm(eventPayload.user);
  const dataLayer = Object.assign(eventPayload, utm);
  dataLayer.event = 'GAevent';
  dataLayer.event_name = eventName;

  TagManager.dataLayer({ dataLayer });
};

const gtmPageTrack = (location: string, payload: any) => {
  const utm = propertyMappers.utm(payload.user);
  const dataLayer = Object.assign(payload, utm);
  dataLayer.event = 'VirtualPageview';
  dataLayer.virtualPageURL = location;

  TagManager.dataLayer({ dataLayer });
};

const intercomEventTrack = (eventName: string, eventPayload: any) => {
  IntercomAPI('trackEvent', eventName, { ...eventPayload });
};

const getUserAndSubscriptionForAmplitudeEvents = (userOptions?: AmpliEventUserProperties) => {  
  const { me, subscription } = store.getState()?.user ?? null;

  // Need to overwrite some values here, because values from the userOptions have priority.
  // We use "userOptions' object in the profile creation step and some values can be a result
  // of data processing in the previous steps.
  const result = {
    user: {
      ...me,
      id: userOptions?.id || me?._id,
      signUpMethod: userOptions?.signUpMethod || me?.registration?.method,
      createdAt: userOptions?.createdAt || me?.created?.timestamp,
      licenceId: userOptions?.licenceId || subscription?.licence?.licenceModelId,
      isTrainee: userOptions?.isTrainee || me?.isTrainee,
      classes: userOptions?.classes || me?.classes,
      type: userOptions?.type || me?.type,
      subjects: userOptions?.subjects || me?.subjects,
      schoolType: userOptions?.schoolType || me?.schoolType,
      isSubjectSelected: userOptions?.isSubjectSelected,
    },
    subscription
  };
  
  return result;
};

export const ampliEventTrack = (options: AmpliEvent) => {
  if (isFeatureEnabled('ampli')) {
    const { event, userProperties } = options;
    const { user, subscription } = getUserAndSubscriptionForAmplitudeEvents(userProperties);   

    if (user?.id) {
      const userProperties = {
        ...mapAmpliUserProperties(user),
        ...ampliUtils.getAmpliSubscriptionUserProperties(subscription as SubscriptionResponse)
      };

      ampli.identify(user.id, userProperties);
    } 

    ampli.track(event);
    ampli.client.flush();  // Call flush() to immediately send any pending events.
  }
};

export const ampliTrackSubscriptionCancellationSelectedEvent = (
  isTrial = false,
  source: string
) => {
  const eventProperties = {
    'page source': source,
    trial: isTrial === true ? 'yes' : 'no',
  };

  ampliEventTrack({
    event: new SubscriptionCancellationSelected(eventProperties as any)
  });
};

export const trackCollectionEventToAmplitude = (
  collection: Collection | CollectionResponse,
  eventType: 'created' | 'edited'
) => {
  const EventType = eventType === 'created' ? CollectionCreated : CollectionEdited;
  const eventProperties = ampliUtils.getAmpliCommonCollectionProperties(collection);
  ampliEventTrack({
    event: new EventType(eventProperties)
  });
};

export const ampliTrackCollectionViewedEvent = (
  collection: Collection | CollectionResponse,
) => {
  const eventProperties = ampliUtils.getAmpliCommonCollectionProperties(collection);
  ampliEventTrack({
    event: new CollectionViewed({
      ...eventProperties,
      'collection type': collection.isPublic ? 'curated' : 'private'
    })
  });
};

export const ampliTrackCollectionDeletedEvent = (
  collection: CollectionProperties,
) => {
  const eventProperties = ampliUtils.getAmpliCommonCollectionProperties(collection);
  ampliEventTrack({
    event: new CollectionDeleted(eventProperties),
  });
};

export const ampliTrackSubscriptionPauseSelectedEvent = (
  source: string
) => {
  ampliEventTrack({
    event: new SubscriptionPauseSelected({
      'page source': source,
    })
  });
};

export const ampliTrackSubscriptionPauseSubmittedEvent = (
  pauseDuration: number
) => {
  ampliEventTrack({
    event: new SubscriptionPauseSubmitted({
      'pause duration': `${pauseDuration}mo`
    })
  });
};

export const ampliTrackPricingPlansViewedEvent = (
  conversionPath: string,
  subscriptionStatus?: string,
) => {
  if (subscriptionStatus === 'Suspended') {
    return;
  }
  
  ampliEventTrack({
    event: new PricingPlansViewed({
      'conversion path': conversionPath,
    })
  });
};

/**
	 * Tracks a collection follow or unfollow event in Amplitude.
	 *
	 * @param {Object} collection - The collection being followed or unfollowed.
	 * @param {User} [user] - The user performing the follow or unfollow action.
	 * @param {SubscriptionResponse} [subscription] - The user's subscription information.
	 * @param {boolean} [isFollowed=true] - Whether the collection is being followed or unfollowed.
	 */
export const ampliTrackCollectionFollowEvent = (
  collection: CollectionProperties,
  isFollowed = true,
) => {
  const eventProperties = ampliUtils.getAmpliCommonCollectionProperties(collection);
  const event = isFollowed ? new CollectionFollowed(eventProperties) : new CollectionUnfollowed(eventProperties);
  ampliEventTrack({ 
    event
  });
};

/**
	 * Tracks a video unlocked event in Amplitude.
	 *
	 * @param {MindItem} mindItem - The content that should be tracked.
	 * @param {User} [user] - The user performing the follow or unfollow action.
	 * @param {SubscriptionResponse} [subscription] - The user's subscription information.
	 */
export const ampliTrackVideoUnlockedEvent = (
  mindItem: MindItem,
) => {
  const eventProperties = {
    ...ampliUtils.getAmpliContentEventPayload(mindItem, 'mindItem')
  };

  ampliEventTrack({
    event: new VideoUnlocked(eventProperties as any),
  });
};

export const ampliServerSideEventTrack = (isPublicMindItem: boolean, payload: AmplitudeTrackPayload) => {
  const { user, subscription } = getUserAndSubscriptionForAmplitudeEvents();  

  payload = {
    ...payload,
    user_id: user?.id,
    user_properties: user?.id ? {
      ...mapAmpliUserProperties(user),
      ...ampliUtils.getAmpliSubscriptionUserProperties(subscription)
    } : undefined,
  };

  notifyBugsnagNetwork('track', 'ampliServerSideEventTrack', { isPublicMindItem, payload });
  api.track.trackEvent(isPublicMindItem, payload);
};

const service = {
  eventTrack,
  pageTrack,
  ampliEventTrack,
  ampliServerSideEventTrack,
  ampliTrackPricingPlansViewedEvent,
  ampliTrackSubscriptionCancellationSelectedEvent,
  ampliTrackCollectionDeletedEvent
};

export default service;
