import React from 'react';
import { TrackingEvent } from '../../../types/tracking';
import WithTracking from '../../utils/WithTracking';
import './readerControlsValueStepper.scss';

interface Props {
  increaseButton: React.ReactNode;
  decreaseButton: React.ReactNode;
  value: number | string;
  unit?: string;
  valueTestId?: string;
  valueEvent?: TrackingEvent;
  valueEventPayload?: Record<string, unknown>;
}

const ReaderControlsValueStepper: React.FC<Props> = (props) => {
  return (
    <>
      {props.decreaseButton}
      <WithTracking events={{ onClick: props.valueEvent }}
        eventPayload={props.valueEventPayload}
      >
        <div
          data-testid={props.valueTestId}
          className='reader-controls__value-stepper'>
          {props.value}{props.unit}
        </div>
      </WithTracking>
      {props.increaseButton}
    </>
  );
};

export default ReaderControlsValueStepper;
