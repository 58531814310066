import { useCallback, useState, useEffect } from 'react';
import { padValue } from '../../../../utils';
import { RangeSliderValue } from './RangeSlider/RangeSlider';

const useRangeSelect = (
  range: RangeSliderValue,
  label: string,
  value?: RangeSliderValue,
  alwaysShowLabel?: boolean,
  ref?: React.RefObject<HTMLDivElement>) => {

  const [isOpen, setOpen] = useState(false);
  const [savedValues, _setSavedValues] = useState<RangeSliderValue>(range);
  const [potentialValues, setPotentialValues] = useState<RangeSliderValue | undefined>(undefined);
  const [, toggleDragged] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  const setSavedValues = (values: RangeSliderValue) => {
    toggleDragged(true);
    _setSavedValues(values);
  };

  useEffect(() => {
    _setSavedValues(value || range);
  }, [value]);

  const _close = useCallback(() => {
    setOpen(false);
  }, [value]);

  const open = useCallback(() => {
    setOpen(true);
    if (ref) {
      document.addEventListener('click', close, { capture: true });
    }
  }, [ref]);

  const close = useCallback((event?: MouseEvent) => {
    // We need to call the toggleDragged function like this,
    // (with a callback as a argument; previous state being its parameter)
    // because otherwise we would not get the previous state.
    // This is because `close` is called outside of this component,
    // and `isDragged` is not changed even when we use toggleDragged(true)
    toggleDragged(isDragged => {
      if (!isDragged) {
        if (ref && ref.current && event) {
          if (!ref.current.contains(event.target as Node)) {
            document.removeEventListener('click', close);
            _close();
          }
        } else {
          _close();
        }
      }
      return false;
    });
  }, [ref]);

  const isDefaultState =
    !value  // Checks that no range has been saved and that selectedValues is the default range
    && savedValues.min === range.min
    && savedValues.max === range.max;

  const isCurrentlySavedRange =
    value  // Checks that some range has been saved and that it is the same as the selectedValues range
    && savedValues.min === value.min 
    && savedValues.max === value.max;

  const isSaveButtonDisabled = (isDefaultState || isCurrentlySavedRange) && !isTouched;

  const handleClearSelection = (onSelectionClear: (
    (() => void) | undefined), closeDropdown: boolean = true
  ) => {   
    if (isOpen && closeDropdown) {
      close();
    }

    onSelectionClear?.();
    setIsTouched(false);
    // Reset values even if selection is not saved
    savedValues.min = range.min;
    savedValues.max = range.max;
  };

  const handleSaveSelection = (onSaveSelection: (((value: RangeSliderValue) => void) | undefined)) => {
    close();
    const values = {
      min: Math.min(savedValues.min, savedValues.max),
      max: Math.max(savedValues.min, savedValues.max)
    };
    setPotentialValues(undefined);
    setIsTouched(false);
    onSaveSelection?.(values);
  };

  const getLabel = () => {
    if (!alwaysShowLabel && value && !isOpen) {
      return `${padValue(value.min)}-${padValue(value.max)}`;
    }

    let labelShown = label;
    if (value) {
      labelShown = `${label} (1)`;
    }

    return labelShown;
  };

  return {
    isOpen,
    savedValues,
    setSavedValues,
    potentialValues,
    setPotentialValues,
    open,
    close,
    getLabel,
    isDefaultState,
    isCurrentlySavedRange,
    handleSaveSelection,
    handleClearSelection,
    isSaveButtonDisabled,
    setIsTouched,
  };
};

export default useRangeSelect;

