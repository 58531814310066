import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { routes } from '../../../constants';
import { selectUser, getSubscription } from '../../../reducers/user.selectors';
import useRouter from '../../../utils/useRouter';
import { IconName } from '../../Icon';
import ScreenStepContainer, { ScreenStepProps } from '../ScreenModal/ScreenStepContainer';
import { SubscriptionResponse } from '@klab-berlin/api-sdk/lib/types/responses/Billing';
import { ampliTrackSubscriptionCancellationSelectedEvent } from '../../../services/track.service';
import '../ScreenModal/ScreenStepContainer.scss';
import { ampliSubscriptionPageSource } from '../../../constants';

const CancellationStepStart:React.FC<ScreenStepProps> = (props) => {
  const { router } = useRouter();
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const subscription: SubscriptionResponse | undefined = useSelector(getSubscription);

  if (!user) return null;
  const goToSearchButton = {
    action: () => {
      props.onClose();
      router.push(`/${routes.search.root}`);
    },
    title: t('Discover new Material'),
    icon: 'search' as IconName,
  };
  const continueCancellationButton = {
    action: () => {
      if (user && subscription) {
        ampliTrackSubscriptionCancellationSelectedEvent(
          user.isTrial,
          ampliSubscriptionPageSource.DIVERSE_MATERIAL_RECOMMENDATION
        );
      }

      props.setScreen('offerPause');
    },
    title: t('Continue Cancellation')
  };
  return (
    <ScreenStepContainer
      trackingId='start'
      title={`Hi, ${user.firstName}!`}
      primaryButton={goToSearchButton}
      secondaryButton={continueCancellationButton}
      onClose={props.onClose}
    >
      <p>{t('The amount of materials at meinUnterricht keep growing')}</p>
      <div className='screen-step__img screen-step__start-img' />
      <p>{t('Dont miss these and many other books, magazines, and work sheets')}</p>
    </ScreenStepContainer>
  );
};

export default CancellationStepStart;
