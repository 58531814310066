import React, { ReactNode } from 'react';
import AppLayout from '../AppLayout';
import CollectionsMenu from '../CollectionsMenu';
import MainGridArea from '../AppLayout/MainGridArea';

interface CollectionAppLayoutProps {
  mainBody: ReactNode;
  header: ReactNode;
  context: ReactNode;
  setActiveId?: React.Dispatch<React.SetStateAction<string>>;
}

const CollectionAppLayout: React.FC<CollectionAppLayoutProps> = ({
  mainBody,
  header,
  context,
  setActiveId,
}) => (
  <AppLayout
    menu={<CollectionsMenu />}
    main={<MainGridArea
      header={header}
      body={mainBody}
      backgroundClick={() => {
        if (setActiveId) {
          setActiveId('');
        }
      }}
      context={context}
    />}
  />
);

export default CollectionAppLayout;
