import React from 'react';
import constants from '../../constants';
import SubjectChoices from './SubjectChoices';
import SubjectPriorities from './SubjectPriorities';

export interface SelectedSubjects {
  majors: string[];
  minors: string[];
  isPrioritized: boolean;
}

interface OwnProps {
  selectedSubjects: SelectedSubjects;
  isPrioritizationStep: boolean;
  onChange: (subjects: SelectedSubjects) => void;
  onToggleHierarchy?: () => void;
}

const SubjectSelection: React.FC<OwnProps> = (props) => {
  
  const updateSubjects = (changes: { majors?: string[], minors?: string[], isPrioritized?: boolean }) => {
    props.onChange(Object.assign({}, props.selectedSubjects, changes));
  };

  const enterSubjectIntoHierarchy = (subjectId: string) => {
    const { majors, minors } = props.selectedSubjects;

    if (majors.length === 0) {
      return updateSubjects({ majors: [subjectId] });
    } 
    
    updateSubjects({ minors: [...minors, subjectId] });
  };

  const subjectSelectHandler = (subjectId: string) => {
    
    const { majors, minors } = props.selectedSubjects;
    const isInMajors = majors.indexOf(subjectId) > -1;
    const isInMinors = minors.indexOf(subjectId) > -1;

    if (isInMinors) {
      const newMinors = minors.filter(id => id !== subjectId);
      return updateSubjects({ minors: newMinors });
    }
    
    if (isInMajors) {
      const newMajors = majors.filter(id => id !== subjectId); 
      return updateSubjects({ majors: newMajors });
    }

    enterSubjectIntoHierarchy(subjectId);
  };

  const toggleHierarchy = () => {
    const { majors, minors, isPrioritized } = props.selectedSubjects;
    
    const allSubjects = majors.concat(minors);
    
    const subjects = {
      isPrioritized: !isPrioritized,
      majors: isPrioritized ? allSubjects : [allSubjects[0]],
      minors: isPrioritized ? [] : allSubjects.slice(1)
    };
    
    updateSubjects(subjects);
    props.onToggleHierarchy?.();
  };

  const onOrder = (subjectId: string, isMajor: boolean, index: number) => {
    const { majors, minors } = props.selectedSubjects;
    const newMajors = majors.filter(s => s !== subjectId);
    const newMinors = minors.filter(s => s !== subjectId);
    const destinationList = isMajor ? newMajors : newMinors;

    destinationList.splice(index, 0, subjectId);

    updateSubjects({ majors: newMajors, minors: newMinors });
  };

  const isSubjectSelected = (subjectId: string) => {
    const { majors, minors } = props.selectedSubjects;
    return majors.indexOf(subjectId) > -1 || minors.indexOf(subjectId) > -1;
  };

  const subjectList = constants.subjects
    .filter((subject) => (subject.isTeachable))
    .sort((a, b) => a.popularity > b.popularity ? 1 : -1);

  return (
    <>
      {!props.isPrioritizationStep ?
        <SubjectChoices
          onSubjectClick={subjectSelectHandler}
          subjects={subjectList.map(
            (s) => ({
              id: s.id,
              isSelected: isSubjectSelected(s.id),
            })
          )}
        /> :
        <SubjectPriorities
          majorSubjects={props.selectedSubjects.majors}
          minorSubjects={props.selectedSubjects.minors}
          onOrder={onOrder}
          toggleHierarchy={toggleHierarchy}
          isPrioritized={props.selectedSubjects.isPrioritized}
        />
      }
    </>
  );
};

export default SubjectSelection;
