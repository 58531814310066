import React from 'react';
import { Button, ButtonOwnProps, CircularProgress } from '@mui/material';

interface OwnProps {
    label: string;
    color: ButtonOwnProps['color'];
    variant: ButtonOwnProps['variant'];
    onClick: () => void;
    isDisabled: boolean;
    isLoading: boolean;
    SvgIcon: React.FC;
    dataTestId?: string;
    styles?: ButtonOwnProps['sx'];
}

const LoadingButton: React.FC<OwnProps> = (props) => {
  const { label, variant, color, styles, onClick, isDisabled, isLoading, SvgIcon, dataTestId } = props;
  return (
    <Button
      data-testid={dataTestId}
      sx={{ ...styles }}
      onClick={onClick}
      variant={variant}
      size='large'
      color={color}
      disabled={isDisabled}
      startIcon={
        isLoading ? (
          <CircularProgress
            size={22}
            sx={{
              color: 'inherit',
              '& .MuiCircularProgress-svg': {
                width: 'auto',
                height: 'auto',
              }
            }}
          />
        ) : (
          <SvgIcon/>
        )
      }
    >
      {label}
    </Button>
  );
};

export default LoadingButton;
