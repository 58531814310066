exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".reader-head__container{display:flex;align-items:center;background-color:#f5f7f7;border:1px solid #e9ebec;height:64px;padding:0 16px}@media only screen and (max-width: 767px){.reader-head__container{height:100%}}.reader-head__mobile-product-button{display:none;color:#2a2a2a;margin-right:16px}@media only screen and (max-width: 767px){.reader-head__mobile-product-button{display:block}}.reader-head__header{font-size:20px;line-height:32px;font-weight:bold;padding-right:16px;width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}@media only screen and (max-width: 767px){.reader-head__header{font-size:20px;line-height:32px;font-weight:normal}}.reader-head__info-button{padding-left:24px}.reader-head__search-button{padding-right:24px;border-right:1px solid #e9ebec}", ""]);
// Exports
exports.locals = {
	"medium": "768px",
	"large": "1024px",
	"xlarge": "1440px",
	"collectionGridGap": "15px",
	"collectionGridItemHeight": "211px",
	"collectionGridItemWidth": "225px",
	"collectionListHeight": "84px",
	"greyLightest": "#f7f9fa",
	"greyLight2": "#d8d8d8",
	"neuVeryLightBlue": "#e9ebec",
	"muBlue": "#4298b5",
	"gridBreakPoints_-xs": "0",
	"gridBreakPoints_-sm": "576px",
	"gridBreakPoints_-md": "768px",
	"gridBreakPoints_-lg": "992px",
	"gridBreakPoints_-xl": "1440px"
};