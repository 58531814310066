import React, { useContext, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { AsyncActionStatus } from '../../../actions/common.actionTypes';
import { fetchProduct } from '../../../actions/product.actions';
import { ShareModalContext } from '../../../state/ShareModalProvider';
import { ShareModalItemTypeEnum } from '../../../types/sharing';
import { getIconForFileType } from '../../../utils';
import { CtxButton, CtxIconText, CtxMetas, CtxTop } from '../ContextUtils';
import OpenItemContextButton from './OpenItemContextButton';
import PinItemContextButton from './PinItemContextButton';
import { Product } from '@klab-berlin/api-sdk/lib/types/responses/Product';

interface StateProps {
  status: AsyncActionStatus;
}

interface DispatchProps {
  getMindItemData: (productId: string) => any;
}

interface OwnProps {
  product: Product;
}

type ProductContextProps = StateProps & DispatchProps & OwnProps;

const ProductContext: React.FC<ProductContextProps> = ({ product, ...props }) => {
  useEffect(() => {
    props.getMindItemData(product.id);
  }, [product.id]);

  const { show: openShareModal } = useContext(ShareModalContext);
  const { t } = useTranslation();

  return (
    <>
      <CtxTop title={product.title} description={product.description} />
      <Row className='py-4 border-bottom'>
        <Col>
          <OpenItemContextButton item={{ ...product, type: 'product' }} />
          <PinItemContextButton item={{ ...product, type: 'product' }} />
          <CtxButton
            icon='share'
            onClick={() =>
              openShareModal(product.id, ShareModalItemTypeEnum.PRODUCT)
            }
          >
            {t('Share')}
          </CtxButton>
        </Col>
      </Row>
      <Row as={'dl'} className='py-4 border-bottom'>
        <CtxMetas
          data={[
            ['Publisher', product.publisher],
            ['Author', product.author],
            ['Subjects', product.subjects],
            ['Class years', product.classYears],
            ['SchoolTypes', product.schoolTypes],
          ]}
        />
        {product.mindItems && (
          <CtxMetas
            data={[
              ['MindItems', product.mindItems.length],
              ['Attachments', product.attachments.length],
            ]}
          />
        )}
      </Row>
      {product.mindItems && (
        <Row>
          <Col xs={12}>
            <h6 className='h6 text-secondary font-weight-normal'>
              {t('Attachments')}
            </h6>
            {product.attachments.map((attachment, index) => (
              <CtxIconText
                key={index}
                icon={getIconForFileType(attachment.contentType)}
              >
                {attachment.name}
              </CtxIconText>
            ))}
          </Col>
        </Row>
      )}
    </>
  );
};

export default compose<React.FC<OwnProps>>(
  connect(
    undefined,
    { getMindItemData: fetchProduct },
  ),
)(ProductContext);
