import { CollectionResponseData } from '@klab-berlin/api-sdk/lib/types/responses/Collection';
import { MindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import { Product } from '@klab-berlin/api-sdk/lib/types/responses/Product';
import { SubscriptionResponse } from '@klab-berlin/api-sdk/lib/types/responses/Billing';
import { flattenUserSubjects, getSeconds } from '../../utils';
import { userType } from '../../constants';
import { User } from '../../types/User';

const mapDocumentData = (document: MindItem) => {
  if (!document) {
    return;
  }

  return {
    doc_id: document.id,
  };
};

const mapProductData = (product: Product) => {
  if (!product) {
    return;
  }

  return {
    product_id: product.id,
    product_publishinghouse: product.publisher,
    product_classyears: product.classYears,
    product_schooltype: product.schoolTypes,
    product_subjects: product.subjects,
    product_attachments: product.hasAttachments,
  };
};

const generateUtmData = (user?: User) => {
  if (!user) {
    return;
  }

  return {
    account_created_ts:
      user.createdAt && new Date(user.createdAt).getTime().toString(),
    form_code: '',
    utm_campaign: '',
    utm_content: '',
    utm_medium: '',
    utm_source: '',
    utm_term: '',
  };
};

const mapUserData = (user?: User) => {
  if (!user) {
    return;
  }
  
  const flatUserSubjects = user.subjectsFlattened;

  return {
    user_id: user.id || '',
    user_subject1: flatUserSubjects[0] && flatUserSubjects[0].text || '',
    user_subject2: flatUserSubjects[1] && flatUserSubjects[1].text || '',
    user_subject3: flatUserSubjects[2] && flatUserSubjects[2].text || '',
    user_type: user.isPremium ? userType.premium : userType.basis,
    user_is_in_trial: user.isInTrial
  };
};

const mapCollectionData = (collection: CollectionResponseData) => {
  if (!collection) {
    return;
  }

  return {
    collection_id: collection._id,
    collection_public: collection.isPublic,
    collection_schooltype: collection.schoolType,
    collection_subject: collection.subjects.join(';'),
  };
};

const mapOther = (data: any) => {
  return data;
};

const mapIntercomUserData = (user?: User, subscription?: SubscriptionResponse) => {
  if (!user) {
    return;
  }

  const userSubjects = user.subjects;
  const flatUserSubjects = flattenUserSubjects(userSubjects).map((s) => s.id);

  const mapped = {
    email: user.email,
    user_id: user.id,
    name: [user.firstName, user.lastName]
      .filter(function (field) {
        return !!field;
      })
      .join(' '),
    created_at: getSeconds(user.createdAt),
    gender: user.gender || '',
    account_type: user.isAdmin
      ? 'admin'
      : user.isPremium
        ? 'premium'
        : 'basic',
    country:
      (user.address && user.address.country) ||
      (user.invoiceAddress && user.invoiceAddress.country) ||
      '',
    school_type: user.schoolType,
    subjects: flatUserSubjects.join(', '),
    is_trainee: user.isTrainee || false,
    is_parent: user.isParent || false,
    downloads: user.downloads.counter,
    even: Date.parse(user.createdAt) % 2 === 0,
    state: user.address && user.address.state,
    last_invoiced_at: null,
    account_balance: null,
    bank_account_type: null,
    product_name: subscription?.products[0].name,
    rate_plan_name: subscription?.products[0].plan.name,
    sub_status: subscription?.status,
    sub_start_at: subscription?.startDate,
    sub_end_at: subscription?.endDate,
    had_trial: subscription?.licence.hasTrial,
    licence_model_id: subscription?.licence.licenceModelId,
    seo_doc_id: user.redirectTo,
  };

  return mapped;
};

const requiredPropertyMappers = {
  collection: mapCollectionData,
  document: mapDocumentData,
  other: mapOther,
  product: mapProductData,
  user: mapUserData,
  utm: generateUtmData,
  intercom: mapIntercomUserData,
};

export default requiredPropertyMappers;
