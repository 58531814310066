exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".product-attribute-category{display:flex;flex-direction:row}@media only screen and (max-width: 767px){.product-attribute-category__attribute-key{min-width:unset !important}.product-attribute-category__attribute-key span{display:none !important}}.product-attribute-category__attribute-values{font-size:16px;line-height:24px;font-weight:inherit;margin-right:24px}.product-attribute-category__attribute-key{font-size:12px;line-height:16px;font-weight:inherit;height:24px;color:#727e82;display:flex;flex-direction:row;align-items:center}.product-attribute-category__comma-list{display:inline;list-style:none;padding:0}.product-attribute-category__comma-list__comma-list-item::after{content:\", \"}.product-attribute-category__comma-list__comma-list-item:last-child::after{content:\"\"}", ""]);
// Exports
exports.locals = {
	"medium": "768px",
	"large": "1024px",
	"xlarge": "1440px",
	"collectionGridGap": "15px",
	"collectionGridItemHeight": "211px",
	"collectionGridItemWidth": "225px",
	"collectionListHeight": "84px",
	"greyLightest": "#f7f9fa",
	"greyLight2": "#d8d8d8",
	"neuVeryLightBlue": "#e9ebec",
	"muBlue": "#4298b5",
	"gridBreakPoints_-xs": "0",
	"gridBreakPoints_-sm": "576px",
	"gridBreakPoints_-md": "768px",
	"gridBreakPoints_-lg": "992px",
	"gridBreakPoints_-xl": "1440px"
};