import React from 'react';
import InputError from '../InputError/InputError';
import InputLabel from '../InputLabel';

interface OwnProps {
  label?: string;
  labelFor?: string;
  error?: string;
  required?: boolean;
}

const FormInputWrapper: React.FC<OwnProps> = (props) => {
  return (
    <>
      <InputLabel required={props.required} htmlFor={props.labelFor}>
        {props.label}
      </InputLabel>
      { props.children}
      <InputError error={props.error} />
    </>
  );
};

export default FormInputWrapper;
