exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".personal-details-page-layout{height:100%;display:flex}@media only screen and (max-width: 767px){.personal-details-page-layout{flex-direction:column}}.personal-details-page-layout__nav{flex-basis:248px;background:#f5f7f7;border-right:1px solid #e9ebec;padding-top:24px}@media only screen and (max-width: 767px){.personal-details-page-layout__nav{padding-top:32px;flex-basis:auto;background:inherit;border-right:0}}.personal-details-page-layout__nav--tablet-mobile{display:none}@media only screen and (max-width: 767px){.personal-details-page-layout__nav--tablet-mobile{display:block}}.personal-details-page-layout__nav--desktop{display:block;width:248px}@media only screen and (max-width: 767px){.personal-details-page-layout__nav--desktop{display:none}}.personal-details-page-layout__content{flex-grow:1;overflow-y:auto}", ""]);
// Exports
exports.locals = {
	"collectionGridGap": "15px",
	"collectionGridItemHeight": "211px",
	"collectionGridItemWidth": "225px",
	"collectionListHeight": "84px",
	"greyLightest": "#f7f9fa",
	"greyLight2": "#d8d8d8",
	"neuVeryLightBlue": "#e9ebec",
	"muBlue": "#4298b5",
	"gridBreakPoints_-xs": "0",
	"gridBreakPoints_-sm": "576px",
	"gridBreakPoints_-md": "768px",
	"gridBreakPoints_-lg": "992px",
	"gridBreakPoints_-xl": "1440px"
};