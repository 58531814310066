import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  amplitudeContentTypes,
  amplitudeEvents,
  readPageTime,
} from '../../constants';
import { getSubscription, selectUser } from '../../reducers/user.selectors';
import services from '../../services';
import { useTabVisibility } from './useTabVisibility';
import { selectMindItem } from '../../reducers/mindItems.selectors';
import { getContentEventPayload, mapDesignPreference } from '../../utils/ampli';
import { AmplitudeTrackPayload } from '@klab-berlin/api-sdk/lib/types/requests/Track';
import { SubscriptionResponse } from '@klab-berlin/api-sdk/lib/types/responses/Billing';
import { MindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import { useNewDesign } from '../../state/DesignPreferenceContextProvider';

export const usePageReadTracking = (mindItemId: string, currentPage: number, isLoaded: boolean) => {
  const timeoutRef = useRef<number>();
  const isForegroundTab = useTabVisibility();
  const user = useSelector(selectUser);
  const subscription: SubscriptionResponse | undefined = useSelector(getSubscription);
  const { shouldShowDesignPreferenceSwitch, prefersNewDesign } = useNewDesign();

  const mindItem = useSelector(state => selectMindItem(state, mindItemId));
  
  const trackPageRead = ( 
    mindItem: MindItem, 
  ) => {
    const readEventPayload: AmplitudeTrackPayload | null = getContentEventPayload({
      content: mindItem,
      currentPage,
      event_type: amplitudeEvents.PageRead,
      contentType: mindItem?.isReadOnly ? amplitudeContentTypes.readOnlyDocument : amplitudeContentTypes.document
    });

    if (readEventPayload) {
      if (shouldShowDesignPreferenceSwitch) {
        readEventPayload.event_properties['ui'] = mapDesignPreference(prefersNewDesign);
      }
      services.track.ampliServerSideEventTrack(false, readEventPayload);
    }
  };

  useEffect(() => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }
    if (isForegroundTab && isLoaded) {
      timeoutRef.current = window.setTimeout(() => {
        if (user && mindItem && subscription) trackPageRead(mindItem);
      }, readPageTime);
    }
    return () => window.clearTimeout(timeoutRef.current);
  }, [currentPage, isForegroundTab, isLoaded]);
  return;
};
