import React, { useState } from 'react';
import classnames from 'classnames';
import './toggleSwitch.scss';

interface ToggleSwitchProps {
  name: string;
  onChange: (value: boolean) => void;
  isToggledByDefault?: boolean;
  isPublicPage?: boolean;  // TODO: Clean up (RED-26)
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ name, onChange, isToggledByDefault, isPublicPage }) => {
  const [checked, setChecked] = useState<boolean>(isToggledByDefault || false);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.currentTarget.checked);
    setChecked(checked => !checked);
  };

  return (
    <label className='switch'>
      <input
        type='checkbox'
        checked={checked}
        name={name}
        onChange={event => onChangeHandler(event)}
      />
      <span 
        data-testid='toggle-switch'
        className={classnames('slider', { 'slider--isPublicPage': isPublicPage })}
      ></span>
    </label>
  );
};

export default ToggleSwitch;
