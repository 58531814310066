import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import config from 'config';
import { routes } from '../../../constants';
import { Box, Button, SvgIcon } from '@mui/material';
import add from '../../../assets/lernsets/icons/add.svg';
import useAction, { useSyncAction } from '../../../utils/useAction';
import { getLernsets, deleteLernset, addLernset, clearAllLernsetsPartsImages } from '../../../actions/lernsets.actions';
import { AsyncActionStatus } from '../../../actions/common.actionTypes';
import { getLernsets as getLernsetsSelector, getLernsetsStatus } from '../../../reducers/lernsets.selectors';
import { selectUser } from '../../../reducers/user.selectors';
import LernsetsMasterLayout from '../../../components/lernsets/LernsetsMasterLayout';
import { palette, spaces } from '../../../assets/styles/themes/tokens';
import LernsetLessonCard from '../../../components/lernsets/LernsetLessonCard';
import DashboardHeader from '../../../components/lernsets/DashboardHeader';
import LernsetCreationCTA from '../../../components/lernsets/LernsetCreationCTA';
import { useAmplitudeExperiment } from '../../../utils/ampli';
import useRouter from '../../../utils/useRouter';
import { getNewLernsetTitle } from '../../../utils/lernsets';
import ConfirmDialog from '../../../components/lernsets/ConfirmDialog';
import services from '../../../services';
import { SetCreated } from '../../../ampli';
import { SnackbarContext } from '../../../state/SnackbarContextProvider';

interface LernsetToDelete {
  id: string;
  title: string;
}

const LernsetsDashboardPage = () => {
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { openSnackbar } = useContext(SnackbarContext);
  const lernsetToDelete = useRef<LernsetToDelete | null>(null);
  const getUserLernsets = useAction(getLernsets);
  const removeLernset = useAction(deleteLernset);
  const clearAllPartsImages = useSyncAction(clearAllLernsetsPartsImages);
  const lernsetsList = useSelector(getLernsetsSelector);
  const lernsetActionStatus = useSelector(getLernsetsStatus);
  const createNewLernset = useAction(addLernset);
  const user = useSelector(selectUser);
  const { router } = useRouter();
  const {
    variant: lernsetVariant,
    isReady: isClientReady,
  } = useAmplitudeExperiment(user, config.amplitudeExperiments.enableLearnsetsFeature);
  const isLernsetFeaturesActive = isClientReady && lernsetVariant === 'on';

  const lernsetsReady = lernsetActionStatus === AsyncActionStatus.READY;
  const shouldRenderCreateLernsetInTopbar = lernsetsList.length > 0 && lernsetsReady;

  useEffect(() => {
    if (user?.id) {
      clearAllPartsImages();
      getUserLernsets();
    }
  }, [user?.id]);

  const onDelete = async () => {
    if (lernsetToDelete?.current) {
      await removeLernset(lernsetToDelete.current.id);
    }
  
    lernsetToDelete.current = null;
    setIsDeleteModalOpen(false);
    openSnackbar({
      type: 'success',
      message: t('Lesson has been deleted successfully!')
    });
  };

  const onCreateLernsetClick = async () => {
    const { result: lernset } = await createNewLernset(getNewLernsetTitle(t('Untitled')));
    // Track the amplitude event
    services.track.ampliEventTrack({ 
      event: new SetCreated({
        'lernset id': lernset.id,
        'lernset title': lernset.title,
        'page source': 'Dashboard'
      })
    });

    router.push(`/${routes.lernsetsOverview.root}/${lernset.id}`);
  };

  const onLernsetLessonClick = (lernsetId: string) => {
    router.push(`/${routes.lernsetsOverview.root}/${lernsetId}`);
  };

  const CreateLernsetButton = () => {
    return (
      <Button 
        variant='contained'
        size='large'
        color='primary'
        key='create-lernset-button'
        onClick={onCreateLernsetClick}
        startIcon={<SvgIcon component={add} sx={{ stroke: palette.primary.contrastText }}/>}
      >
        {t('Create lesson')}
      </Button>
    );
  };

  const LernsetListContent = useMemo(() => {
    if (lernsetsList.length === 0) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: spaces.xxxl }}>
          <LernsetCreationCTA onCtaClick={onCreateLernsetClick} />
        </Box>
      );
    }
  
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: spaces.md, paddingTop: spaces.lg }}>
        {lernsetsList.map((lernset) => (
          <LernsetLessonCard
            key={lernset.id} 
            lernset={lernset} 
            onDelete={ () => {
              lernsetToDelete.current = {
                id: lernset.id,
                title: lernset.title,
              };
              setIsDeleteModalOpen(true);
            }} 
            onClick={() => onLernsetLessonClick(lernset.id)}
          />
        ))}
      </Box>
    );
  }, [lernsetsList]);

  if (!isClientReady) {
    return <>Loading...</>;
  } else if (lernsetActionStatus === AsyncActionStatus.READY && !isLernsetFeaturesActive) {
    router.replace(`/${routes.notFound.root}`);
    return <></>;
  }

  return (
    <LernsetsMasterLayout 
      title={{ text: t('Overview') }}
      topRightComponent={shouldRenderCreateLernsetInTopbar ? <CreateLernsetButton /> : <></>}
    >
      {lernsetsReady && (
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          width: '100%',
          paddingTop: spaces.md,
        }}>
          <Box sx={{ display:'flex', flexDirection: 'column', paddingX: spaces.lg }}>
            <DashboardHeader lernsetCount={lernsetsList.length} />
            {LernsetListContent}
            <ConfirmDialog
              title={t('Delete Lesson')}
              message={t(
                // eslint-disable-next-line max-len
                'Are you sure you want to delete the lesson? This will also delete any materials in this lesson. Deleting lessons is permanent.', 
                { title: lernsetToDelete.current?.title }
              )}
              confirmButtonText={t('Yes, delete')}  
              isOpen={isDeleteModalOpen}
              confirmButtonColor='error'
              onCloseModal={() => setIsDeleteModalOpen(false)}
              onConfirm={onDelete}
            />
          </Box>
        </Box>
      )}
    </LernsetsMasterLayout>
  );
};

export default LernsetsDashboardPage;

