/**
 * capitalizes a string (turns the first letter into upper case)
 * @param str string to capitalize
 * @returns string
 */
export function capitalize(str:string){
  return str.substr(0,1).toUpperCase() + str.substr(1, str.length - 1);
}

/**
 * checks if a string is a positive integer
 * @param str string to check
 * @returns boolean
 */
export function isPositiveInteger(str:string){
  return /^\d+$/.test(str);
}
