import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSubscription } from '../../../../reducers/user.selectors';
import CustomToast from '../../../CustomToast/CustomToast';
import PersonalDetailsSection from '../../Common/PersonalDetailsSection';
import PaymentRhythmForm from '../PaymentRhythmForm';
import PaymentRhythmView from '../PaymentRhythmView';

const PaymentRhythmSection: React.FC = () => {
  const { t } = useTranslation();
  const subscription = useSelector(getSubscription);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const paymentRhythmOptions = subscription?.paymentPeriod?.possibleValues || [];
  const [canEdit, setCanEdit] = useState(false);
  const [showRequestInformation, setShowRequestInformation] = useState(false);

  useEffect(() => {
    // TODO: FD-2519: remove the promoCode condition
    const _canEdit: boolean =
      subscription?.status === 'Active' &&
      !subscription?.trial?.isInTrial &&
      paymentRhythmOptions.length > 0 &&
      !subscription?.paymentPeriod.following &&
      !subscription?.promoCode;

    if (_canEdit) {
      setCanEdit(true);
    }

    return () => setCanEdit(false);
  }, [
    subscription?.status,
    subscription?.trial?.isInTrial,
    paymentRhythmOptions,
    subscription?.paymentPeriod.following,
    subscription?.promoCode,
  ]);

  return (
    <div data-testid='payment-rhythm-section'>
      <PersonalDetailsSection
        title={t('Payment rhythm')}
        isEditDisabled={!canEdit}
        renderNormalView={() =>
          <PaymentRhythmView showRequestInformation={showRequestInformation} />}
        renderFormView={(closeEditMode) =>
          canEdit ?
            <PaymentRhythmForm onCancel={closeEditMode} onSuccess={() => {
              setShowSuccessToast(true);
              setShowRequestInformation(true);
              closeEditMode();
              setCanEdit(false);
            }} />
            : null}
      />
      <CustomToast
        delay={8000}
        show={showSuccessToast}
        onClose={() => setShowSuccessToast(false)}
        title={t('Payment rhythm')}
        bodyText={t('We have received your payment rhythm change request.')}
      />
    </div>
  );
};

export default PaymentRhythmSection;
