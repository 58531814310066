
import React from 'react';
import { useTranslation } from 'react-i18next';
import './newTag.scss';

const NewTag = () => {
  const { t } = useTranslation();

  return (
    <div className='new-tag'>
      { t('New').toLocaleLowerCase() }    
    </div>
  );
};

export default NewTag;
