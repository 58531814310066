import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../Icon';
import './OfferPoints.scss';

interface OfferPointsProps {
  areAvailable: boolean;
  points: string[];
}

const OfferPoints: React.FC<OfferPointsProps> = ({ areAvailable, points }) => {
  const { t } = useTranslation();
  return (
    <>
      {
        points.map(point => <div key={point}
          className={classNames('offer-point', !areAvailable && 'offer-point--unavailable')}>
          <Icon icon='check' /> {t(point)}</div>
        )
      }
    </>);
};

export default OfferPoints;
