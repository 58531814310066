import React from 'react';
import classnames from 'classnames';
import Icon from '../../../Icon';
import './modalToggleButton.scss';

interface ModalToggleButtonProps {
  label: string;
  onClick: () => void;
  icon?: React.ReactNode;
  disabled?: boolean;
  selected?: boolean;
}

const ModalToggleButton: React.FC<ModalToggleButtonProps> = (props) => {
  return (
    <button
      type='button'
      onClick={props.onClick}
      disabled={props.disabled}
      data-testid='modal-toggle-button'
      className={classnames(`
            modal-toggle-button
            rounded
            px-2
            py-1
          `,
      { 'modal-toggle-button--enabled': !props.disabled ,
        'modal-toggle-button--disabled': props.disabled,
        'modal-toggle-button--selected': props.selected && !props.disabled
      })}>
      <span className={classnames(
        'text-truncate mr-2',
      )}>
        {(props.icon) && <>{props.icon}&nbsp;</>}{props.label}
      </span>
      <Icon
        size='lg'
        className={classnames(
          'modal-toggle-button__icon',
          {
            'color--grey-medium': !props.disabled,
            'color--cool-grey': props.disabled,
          })}
        icon='arrow-small-down'
      />
    </button>
  );
};

export default ModalToggleButton;
