import React, { useState, useEffect } from 'react';
import { ItemVariation } from '@klab-berlin/api-sdk/lib/types/responses/Collection';
import { selectCollectionsWithContents, CollectionWithContents } from '../reducers/collections.selectors';
import {
  manageMindItemInMultipleCollections,
  manageProductInMultipleCollections
} from '../actions/collections.actions';
import { connect } from 'react-redux';
import { StoreType } from '../reducers';
import { ManageCollectionRequest } from '@klab-berlin/api-sdk/lib/types/requests/Common';
import { ModalCollectionItem } from '../constants';
type SetItemFunc = (itemId: string, itemType: ItemVariation) => void;
type ToggleCollectionFunc = (collectionId: string) => void;
type SaveFunc = () => Promise<void>;
type SetSelectedCollectionsFunc = (data: ModalCollectionItem[]) => void;

const noop = () => undefined;

export type CollectionWithContainsItemFlag = CollectionWithContents & { containsItem: boolean };

interface Context {
  collections: CollectionWithContainsItemFlag[];
  save: SaveFunc;
  toggleCollection: ToggleCollectionFunc;
  setItem: SetItemFunc;
  selectedCollections: ModalCollectionItem[];
  setSelectedCollections: SetSelectedCollectionsFunc;
}

const defaultContext: Context = {
  collections: [],
  save: () => Promise.resolve(),
  toggleCollection: noop,
  setItem: noop,
  selectedCollections: [],
  setSelectedCollections: noop,
};

export const ManageItemInCollectionContext = React.createContext(defaultContext);

interface StateProps {
  collections: CollectionWithContents[];
}

interface DispatchProps {
  manageProductInMultipleCollections: (
    itemId: string,
    actionPayload: ManageCollectionRequest['collections']) => Promise<any>;
  manageMindItemInMultipleCollections: (
    itemId: string,
    actionPayload: ManageCollectionRequest['collections']) => Promise<any>;
}

const ManageItemInCollectionProviderInner: React.FC<StateProps & DispatchProps> = (props) => {
  const [collections, setCollections] = useState<CollectionWithContainsItemFlag[]>([]);
  const [currentItem, setCurrentItem] = useState<{id: string; type: ItemVariation} | null>(null);
  const [selectedCollections, setSelectedCollections] = useState<ModalCollectionItem[]>([]);
  
  useEffect(() => {
    if (!currentItem) {
      return;
    }

    const initialState = props.collections.map(c => {
      const containsItem = c.contents.findIndex(
        (item) => {
          return item.id === currentItem.id && item.type === currentItem.type;
        }
      ) !== -1;

      const collection: CollectionWithContainsItemFlag = {
        ...c,
        containsItem,
      };

      return collection;
    });

    setCollections(initialState);
  }, [props.collections, currentItem]);

  const setItem = (itemId: string, itemType: ItemVariation) => {
    setCurrentItem({ id: itemId, type: itemType });
  };

  const save = () => {
    if (!currentItem) {
      return Promise.reject();
    }

    const actionPayload = collections.map(c => ({ collectionId: c.id, shouldContainItem: c.containsItem }));
    if (currentItem.type == 'mindItem') {
      return props.manageMindItemInMultipleCollections(
        currentItem.id,
        actionPayload
      );
    }

    if (currentItem.type === 'product') {
      return props.manageProductInMultipleCollections(
        currentItem.id,
        actionPayload
      );
    }
    return Promise.reject();
  };

  const toggleCollection = (collectionId: string) => {
    const updatedCollections = collections.map((collection: CollectionWithContainsItemFlag) => {
      if (collection.id !== collectionId) {
        return collection;
      }

      setListOfSelectedCollections(collectionId, collection);
      
      return {
        ...collection,
        containsItem: !collection.containsItem,
      };
    });
    setCollections(updatedCollections);
  };
  
  const setListOfSelectedCollections = (collectionId: string, collection: CollectionWithContainsItemFlag) => {
    // The logic required for getting the list of selected collections. 
    // Used int the amplitude events
    const collectionIndex: number = selectedCollections
      .findIndex((checkbox: ModalCollectionItem) => checkbox.id === collectionId);

    if (collectionIndex !== -1) {
      selectedCollections.splice(collectionIndex, 1);
    } else {
      selectedCollections.push({ id: collectionId, isSelected: !collection.containsItem });
    }
    
    setSelectedCollections(selectedCollections);
  };

  return (
    <ManageItemInCollectionContext.Provider value={{ 
      setItem, 
      save, 
      collections, 
      toggleCollection,
      selectedCollections,
      setSelectedCollections
    }}>
      {props.children}
    </ManageItemInCollectionContext.Provider>
  );
};

const mapState = (state: StoreType): StateProps => {
  return {
    collections: selectCollectionsWithContents(state)
  };
};

const mapDispatch = { manageMindItemInMultipleCollections, manageProductInMultipleCollections };

export const ManageItemInCollectionProvider = connect(mapState, mapDispatch)(ManageItemInCollectionProviderInner);
