import { Box, Button } from '@mui/material';
import { spaces } from '../../../assets/styles/themes/tokens';
import getMuiIcon from '../../../utils/getMuiIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import WithSearchFilterNumberBadge from './SearchFilterButtonNumberBadge';

const MoreFiltersButton: React.FC<{ numberOfSelections?: number, onClick: () => void  }> = 
  ({ numberOfSelections, onClick }) => {
    const { t } = useTranslation();

    const button = <Button
      variant='outlined'
      size='large'
      color='primary'
      onClick={onClick}
      sx={{
        gap: spaces.xs,
        width: '100%',
      }}
    >
      <Box component={getMuiIcon('sort')} /> {t('Filters')}      
    </Button>;

    return <WithSearchFilterNumberBadge number={numberOfSelections}>
      {button} 
    </WithSearchFilterNumberBadge>;
  };

export default MoreFiltersButton;

