import { AttachmentFileType } from '@klab-berlin/api-sdk/lib/types/responses/Common';
import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getIconForFileType } from '../../../utils';
import formatByteSize from '../../../utils/formatByteSize';
import ActionButton from '../../ActionButton';
import Checkbox from '../../common/Checkbox';
import Icon from '../../Icon';
import Truncate from '../../Truncate';
import './attachmentItem.scss';

interface OwnProps {
    id: number;
    name: string,
    type: AttachmentFileType,
    size: number, // In bytes
    isChecked: boolean,
    contentPath: string,
    numberOfContainedFiles?: number, // Used only when 'type' is 'folder'
    onCheckboxClick: (attachmentPath: string, checked: boolean) => void,
    onDownloadBtnClick: (attachmentPath: string) => void,
    onBodyClick?: (attachmentName: string, attachmentType: AttachmentFileType) => void
}

const AttachmentItem: React.FC<OwnProps> = (props) => {
  const { t } = useTranslation();
  
  const checkboxId = 'checkbox__' + props.contentPath;

  const extractFilePrefix = (fullFileName: string) => {
    return fullFileName.split('.').pop();
  };

  // The prefix is a small detail item appearing under the attachment name
  const prefix = props.type == 'folder'
    ? `${props.numberOfContainedFiles} ${t('Files')}` 
    : extractFilePrefix(props.contentPath)?.toUpperCase();

  const onCheckboxClick = (checked: boolean) => {
    props.onCheckboxClick(props.contentPath, checked);
  };

  const onDownloadBtnClick = () => {
    props.onDownloadBtnClick(props.contentPath);
  };

  const onBodyClick = () => {
    props.onBodyClick?.(props.name, props.type);
  };

  return (
    <div 
      className={classnames('attachment-item', { 'attachment-item--folder': props.type === 'folder' })} 
      data-testid={'attachment-item'}
    >
      <Checkbox className='attachment-item__checkbox' id={checkboxId}
        checked={props.isChecked} onChange={onCheckboxClick} />
      <div className='attachment-item__body' onClick={onBodyClick}>
        <Icon
          className={
            classnames(
              'attachment-item__type-icon',
              { 'attachment-item__type-icon--file': props.type !== 'folder' }
            )}
          icon={getIconForFileType(props.type)}
          testId='attachment-item-icon' />
        <div className='attachment-item__details'>
          <div className={
            classnames(
              'attachment-item__details__name',
              { 'attachment-item__details__name--folder': props.type === 'folder' }
            )}>
            <Truncate text={props.name} lines={2} />
          </div>
          <div className='attachment-item__details__finer'>
            {prefix}, {formatByteSize(props.size)}
          </div>
        </div>
        <ActionButton className='attachment-item__download-btn'
          testId='attachment-item-download-btn'
          icon='download' size='lg'
          onClick={onDownloadBtnClick} />
      </div>
    </div>
  );
};

export default AttachmentItem;
