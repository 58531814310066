import { Match } from 'found';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fetchCollections } from '../actions/collections.actions';
import CollectionContext from '../components/contexts/CollectionContext';
import CollectionsAppLayout from '../components/CollectionsAppLayout';
import CollectionsList from '../components/CollectionsList';
import withAsyncData from '../components/utils/withAsyncData';
import { routes } from '../constants';
import { StoreType } from '../reducers';
import Breadcrumbs from '../components/Breadcrumbs';
import { selectOwnCollections, selectOwnCollectionStatus } from '../reducers/collections.selectors';
import { CollectionProperties } from '../types/AppContent';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import EmptyState from '../components/EmptyState';

interface StateProps {
  collections: CollectionProperties[];
  collectionsStatus: AsyncActionStatus;
}

type OwnListPageProps = Match & StateProps;

const OwnCollectionListPage: React.FC<OwnListPageProps> = ({ collections, collectionsStatus: collectionStatus }) => {

  const [activeId, setActiveId] = useState('');
  const { t } = useTranslation();
  const activeCollection = collections.find((collection) => collection.id === activeId);

  return (
    <CollectionsAppLayout
      header={
        <Breadcrumbs breadcrumbs={[
          { to: `/${routes.collections.root}`, title: t('My Collections') },
        ]}/>
      }
      mainBody={
        <CollectionsList
          activeId={activeId}
          emptyText={<OwnCollectionsEmptyState />}
          collectionsStatus={collectionStatus}
          collections={collections}
          linkRoot={routes.collections.root}
          setActiveId={setActiveId}
          showNewCollectionButton={true}
        />
      }
      context={
        collections.length ?
          <CollectionContext
            ownCollection={true}
            collection={activeCollection}
            linkRoot={routes.collections.root}
          /> : <></>
      }
      setActiveId={setActiveId}
    />
  );
};

const OwnCollectionsEmptyState = () => {
  const { t } = useTranslation();

  return (
    <EmptyState
      iconName='cabinet'
      heading={t('no collections yet')}
      text={
        <Trans i18nKey='create your own collection' key={1}>
          <p>
            <a href={routes.curatedCollections.root}>folge kuratierten Kollektionen</a>
          </p>
        </Trans>
      }
    />
  );
};

const mapState = (state: StoreType): StateProps => ({
  collections: selectOwnCollections(state),
  collectionsStatus: selectOwnCollectionStatus(state),
});

export default compose<React.FC<Record<string,never>>>(
  connect(
    mapState,
    { fetchCollections },
  ),
  withAsyncData((props) => ({
    refetch: () => props.fetchCollections(),
    dataProp: 'collections',
    statusProp: 'collectionsStatus',
  })),
)(OwnCollectionListPage);
