import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import config from 'config';
import { LicenceModel } from '@klab-berlin/api-sdk/lib/types/responses/Billing';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { StoreType } from '../../reducers';
import Spinner from 'react-bootstrap/Spinner';
import './paypalForm.scss';
import { Row, Col } from 'react-bootstrap';
import { LicenceId } from '../../types/licences';
import { ZuoraPaymentPageResponseHandler } from '../../types/ZuoraPayments';
import { notifyBugsnagHandledError } from '../../utils/bugsnagClient';

interface PaypalFormProps {
  licenceId: LicenceId;
  token?: string;
  onResponse: ZuoraPaymentPageResponseHandler;
}

type State = LicenceModel;

const checkoutPaymentTemplate = {
  displayName: 'meinUnterricht',
  flow: 'vault',
  intent: 'authorize',
  currency: 'EUR',
  locale: 'de_DE',
};

const PaypalForm: React.FC<PaypalFormProps & State> = (props) => {
  const { token, onResponse } = props;
  const { price, name } = props  || { price: 0, name: '' };
  const [buttonIsLoaded, setButtonIsLoaded] = useState(false);
  const { t } = useTranslation();

  const createPaypalButton = (token: string, productPrice: number, productName: string) => {
    const paypalRenderOptions = (checkout: any) => ({
      env: config.braintreeEnvironment,
      payment: () => checkout.createPayment({
        ...checkoutPaymentTemplate,
        amount: productPrice,
        billingAgreementDescription: productName
      }),
      onAuthorize: (data: any) => checkout.tokenizePayment(data)
        .then((payload: any) => {
          onResponse('braintree', { auth: payload.nonce, success: true });
        }),
      onCancel: () => {},
      validate: () => {
        setButtonIsLoaded(true);
      },
      onError: () => {},
      style: {
        color: 'gold',
        size: 'medium',
        shape: 'rect',
        tagline: false
      }
    });

    window.braintree.client.create({ authorization: token })
      .then((client: any) => window.braintree.paypalCheckout.create({ client }))
      .then((braintreeCheckout: any) => {
        return window.paypal.Button.render(paypalRenderOptions(braintreeCheckout), '#paypal-button-container');
      })
      .catch((error: Error) => notifyBugsnagHandledError(error));
  };

  useEffect(() => {
    if (token) {
      createPaypalButton(token, price, name);
    }
  }, [token]);

  return (
    <>
      <Row className='font-size-12 mb-3'>
        <Col>{t('You allow meinUnterricht to take money from your paypal account.')}</Col>
      </Row>
      <Row className='my-2 mx-2 justify-content-center'>
        <div 
          id='paypal-button-container' 
          data-testid='paypal-button-container' 
          className={classnames({ 'd-none': !buttonIsLoaded })}>
        </div>
        {!buttonIsLoaded && <Spinner animation='border' />}
      </Row>
    </>
  );
};

export default connect(
  (state: StoreType, props: PaypalFormProps): State => ({
    ...(state.licenceModels.byId[props.licenceId] || {})
  }),
)(PaypalForm);
