import Icon from './Icon';
import React from 'react';
import Button from 'react-bootstrap/Button';
import { ItemVariation } from '@klab-berlin/api-sdk/lib/types/responses/Collection';
import { useSelector } from 'react-redux';
import { selectMindItem } from '../reducers/mindItems.selectors';
import { selectProduct } from '../reducers/products.selectors';
import { getAmpliContentEventPayload, mapDesignPreference } from '../utils/ampli';
import services from '../services';
import { CollectionContentAdded, CollectionContentRemoved } from '../ampli';
import { selectUser } from '../reducers/user.selectors';
import { DataType } from '../constants';
import { MindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import { Product } from '@klab-berlin/api-sdk/lib/types/responses/Product';
import { useNewDesign } from '../state/DesignPreferenceContextProvider';

interface AddPinnedItemToCollectionProps {
  className?: string;
  itemId: string;
  itemType: ItemVariation;
  isInCollection: boolean;
}

type Item = Partial<MindItem> | Partial<Product>;

const AddPinnedItemToCollection: React.FC<AddPinnedItemToCollectionProps> = ({
  isInCollection,
  ...props
}) => {
  const user = useSelector(selectUser);
  const item = useSelector((state) => {
    if (props.itemType === 'mindItem') {
      return selectMindItem(state, props.itemId);
    }

    if (props.itemType === 'product') {
      return selectProduct(state, props.itemId);
    }

    return undefined;
  });
  const { prefersNewDesign, shouldShowDesignPreferenceSwitch } = useNewDesign();

  const sendCollectionContentAddedEvent = (item: Item, type: DataType) => {
    if (user) {
      const eventProperties = getAmpliContentEventPayload(item, type,
        {
          collectionPreexistence: 'existing collection',
        }
      );

      const parsedEventProperties = JSON.parse(JSON.stringify(eventProperties));
      if (shouldShowDesignPreferenceSwitch) {
        parsedEventProperties.ui = mapDesignPreference(prefersNewDesign);
      }

      services.track.ampliEventTrack({
        event: new CollectionContentAdded(parsedEventProperties as any)
      });
    }
  };

  const sendCollectionContentEvents = () => {
    if (item) {
      const sendCollectionContentEvent =
        isInCollection ? sendCollectionContentRemovedEvent : sendCollectionContentAddedEvent;

      sendCollectionContentEvent(item, props.itemType);
    }
  };

  const sendCollectionContentRemovedEvent = (item: Item, type: DataType) => {
    if (user) {
      const eventProperties = getAmpliContentEventPayload(item, type);

      services.track.ampliEventTrack({
        event: new CollectionContentRemoved(eventProperties as any)
      });
    }
  };

  return (
    <Button className={props.className} variant='light' onClick={sendCollectionContentEvents}>
      <Icon icon={isInCollection ? 'check' : 'plus'} size='xl' />
    </Button>
  );
};

export default AddPinnedItemToCollection;
