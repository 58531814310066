import React, { useContext } from 'react';
import classNames from 'classnames';
import { ReaderContext } from '../../ReaderContext';

export type ImageClarity = 'faint' | 'very-faint';

const imageClassMapper = {
  faint: 'opacity-faint',
  'very-faint': 'opacity-very-faint',
};

interface ReaderPageProps {
  pageSrc: string;
  id: string;
  zoom: number;
  imageClarity?: ImageClarity;
}

const ReaderPageItem: React.FC<ReaderPageProps> = (props) => {
  const { onPageLoad } = useContext(ReaderContext);
  const imageClass = props.imageClarity && imageClassMapper[props.imageClarity];

  return (
    <div
      className={
        `page-container mx-auto align-self-center position-relative py-2 zoom_${props.zoom}`
      }
      id={props.id}
      data-testid={`reader__${props.id}`}
    >
      {props.children}
      <img className={classNames('page w-100', imageClass)}
        data-testid={`reader__${props.id}__image`}
        src={props.pageSrc}
        onLoad={onPageLoad}
      />
    </div>
  );
};

export default ReaderPageItem;
