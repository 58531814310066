import React, { useEffect, useContext, useRef, useState } from 'react';
import { Instance, ViewState } from 'pspdfkit';
import { ReaderContext } from '../../Reader/ReaderContext';
import classnames from 'classnames';
import ReaderActions from '../ReaderControls/ReaderActions';
import ReaderContentControls from '../ReaderControls/ReaderContentControls';
import fscreen from 'fscreen';
import { zoomOptions } from '../../../constants';
import services from '../../../services';
import { useTranslation } from 'react-i18next';
import CustomToast from '../../CustomToast/CustomToast';
import { addDragListener, addTouchHoldListener } from '../../utils/addCustomEventListeners';
import pspdfEventListeners from './utils/pspdfEventListeners';
import useRouter from '../../../utils/useRouter';
import { ReaderContentViewVariant } from '../../../types/reader';
import './pdfReader.scss';
import { isMobileAgent } from '../../../utils/userAgent';
import variables from '../../../assets/styles/deprecated/variables';
import { notifyBugsnagHandledError } from '../../../utils/bugsnagClient';
import { usePageReadTracking } from '../../utils/usePageReadTracking';
import { sleepUntil } from '../../../utils/sleep';

interface OwnProps {
  isSearchVisible: boolean;
  setIsSearchVisible: (arg: boolean) => void;
  onSearchClick: () => void;
  variant: ReaderContentViewVariant;
  onScroll: (scrollTop: number) => void;
}

const PdfReader: React.FC<OwnProps> = (props) => {
  const {
    isSearchVisible,
    setIsSearchVisible,
  } = props;
  const [instance, setInstance] = useState<Instance | undefined>(undefined);
  const [muContainerZoom, setMUContainerZoom] = useState(zoomOptions.defaultZoom);
  const [zoomContainerWidthRatio, setZoomContainerWidthRatio] = useState(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const [isFullscreenMode, setIsFullscreenMode] = useState(false);
  const [isNoTextEditToastVisible, setNoTextEditToastVisible] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [hasDocumentLoadError, setHasDocumentLoadError] = useState(false);
  const [showReadOnlyToast, setShowReadOnlyToast] = useState(false);

  const { router } = useRouter();

  const { 
    mindItemId, 
    isReadOnly,
    currentPage, 
    setCurrentPage, 
    isTextBased, 
    initialPage, 
    nextSibling,
    hasAttachments,
  } = useContext(ReaderContext);

  usePageReadTracking(mindItemId, currentPage, isLoaded);

  const { t } = useTranslation();

  const pdfContainer = useRef<HTMLElement>();
  const fullscreenContainer = useRef<HTMLElement>();

  useEffect(() => {
    unload();

    sleepUntil(() => window.PSPDFKit !== undefined, 15000)
      .then(() => {
        load();
      })
      .catch((err) => {
        notifyBugsnagHandledError(err);
        unload();
        setHasDocumentLoadError(true);
      });

    return () => unload();
  }, [mindItemId]);

  useEffect(() => {
    setShowReadOnlyToast(isReadOnly);
  }, [isReadOnly]);

  useEffect(() => {
    fullscreenContainer.current = document.getElementById('reader-document-content') || undefined;

    const handler = () => {
      setIsFullscreenMode(!!fscreen.fullscreenElement);
    };
    fscreen.addEventListener('fullscreenchange', handler);
    return () => {
      fscreen.removeEventListener('fullscreenchange', handler);
    };
  }, []);

  useEffect(() => {
    const _currentPage = instance && instance.viewState.currentPageIndex + 1;
    if (_currentPage !== initialPage) {
      instance && instance.setViewState(viewState => viewState.set('currentPageIndex', initialPage - 1));
    }
    setCurrentPage(initialPage || 1);
  }, [initialPage, instance]);

  const load = async () => {
    setIsLoaded(false);
    const { token } = await services.mindItem.getMindItemToken(mindItemId);

    const configuration = {
      container: pdfContainer.current,
      documentId: mindItemId,
      authPayload: { jwt: token },
      instant: false,
      toolbarItems: [],
      initialViewState: new window.PSPDFKit.ViewState({
        showToolbar: false,
        enableAnnotationToolbar: false,
        currentPageIndex: initialPage ? initialPage - 1 : currentPage - 1,
        allowPrinting: false,
        viewportPadding: {
          horizontal: 0,
          vertical: props.variant === 'mobile' ? variables.navigationLayoutMobileHeaderHeight : 0
        },
      }),
      styleSheets: ['/pdfReader.pspdfkit.css']
    };

    const _instance = await window.PSPDFKit.load(configuration);
    setInstance(_instance);
    setPageCount(_instance.totalPageCount);

    _instance.addEventListener('viewState.currentPageIndex.change', (newCurrentPageIndex: number) => {
      setCurrentPage(newCurrentPageIndex + 1);
    });

    const scrollContainer = _instance.contentDocument.querySelector('.PSPDFKit-Scroll');
    scrollContainer?.addEventListener('scroll', (event: React.UIEvent<HTMLElement>) => {
      props.onScroll(event.currentTarget.scrollTop);
    });

    if (!isTextBased) {
      if (isMobileAgent()) {
        addTouchHoldListener(_instance, () => {
          setNoTextEditToastVisible(true);
        });
      } else {
        addDragListener(_instance, () => {
          setNoTextEditToastVisible(true);
        });
      }
      pspdfEventListeners.addSearchStateChangeListener(_instance, (isChangedToSearch: boolean) => {
        if (isChangedToSearch) {
          setIsSearchVisible(false);
          setNoTextEditToastVisible(true);
          _instance.setViewState((viewState: ViewState) =>
            viewState.set('interactionMode', null));
        }
      });
    } else {
      pspdfEventListeners.addSearchStateChangeListener(_instance, (isChangedToSearch: boolean) => {
        if (isChangedToSearch) {
          const searchContainer = _instance.contentDocument.querySelector('.PSPDFKit-Search');
          if (searchContainer && props.variant === 'mobile') {
            searchContainer.style.top = `${variables.navigationLayoutMobileHeaderHeight}px`;
          }
        }
        setIsSearchVisible(isChangedToSearch);
      });
    }

    const pageInfo = _instance && _instance.pageInfoForIndex(0);
    const pspdfKitContainerWidth = pageInfo && pageInfo.width;
    const pdfContainerElement = pdfContainer && pdfContainer.current;
    const muContainerWidth = pdfContainerElement ? pdfContainerElement.clientWidth : pspdfKitContainerWidth;
    const _zoomContainerWidthRatio = pspdfKitContainerWidth / muContainerWidth;
    setZoomContainerWidthRatio(_zoomContainerWidthRatio);

    _instance.setViewState((viewState: ViewState) =>
      viewState.set('zoom', translateFromMuToPspdfKitZoom(muContainerZoom, _zoomContainerWidthRatio))
    );

    setIsLoaded(true);
  };

  useEffect(() => {
    if (props.variant === 'mobile') return;
    if (nextSibling && instance) {
      addNextChapterButton(instance);
    }
  }, [instance, nextSibling, props.variant]);

  const addNextChapterButton = (pspdfInstance: Instance) => {
    const lastPageInfo = pspdfInstance && pspdfInstance.pageInfoForIndex(pspdfInstance.totalPageCount - 1);
    if (!lastPageInfo) return;

    const btnDiv = document.createElement('div');
    const btnWidth = lastPageInfo.width;
    const btnStyle = `width:${btnWidth}px`;
    btnDiv.innerHTML = `
      <button id="pdf-reader__next-chapter-btn" style="${btnStyle}" 
        onclick="window.top.postMessage('nextChapterClicked', '*')">
        ${t('Explore next chapter')} →
      </button>`;

    const btnItem = new window.PSPDFKit.CustomOverlayItem({
      id: 'next-chapter-btn',
      node: btnDiv,
      pageIndex: pspdfInstance.totalPageCount - 1,
      position: new window.PSPDFKit.Geometry.Point({
        x: (lastPageInfo.width / 2) - (btnWidth / 2),
        y: lastPageInfo.height - 24
      })
    });
    pspdfInstance.setCustomOverlayItem(btnItem);
  };

  const unload = () => {
    if (instance) {
      window.PSPDFKit.unload(instance);
      setInstance(undefined);
    }
  };

  const translateFromMuToPspdfKitZoom = (newMUContainerZoom: number, _zoomContainerWidthRatio: number) => {
    return (newMUContainerZoom / 100) / _zoomContainerWidthRatio;
  };

  useEffect(() => {
    window.addEventListener('message', handleIframeMessage);
    return () => window.removeEventListener('message', handleIframeMessage);
  }, [nextSibling]);

  const handleIframeMessage = (event: any) => {
    if (event.data == 'nextChapterClicked' && nextSibling) {
      router.push(`/dokument/${nextSibling.id}`);
    }
  };

  const zoomIn = () => {
    const newMUContainerZoom = muContainerZoom + 10;
    if (newMUContainerZoom <= zoomOptions.maxZoom) {
      const newPspdfZoom = translateFromMuToPspdfKitZoom(newMUContainerZoom, zoomContainerWidthRatio);
      instance && instance.setViewState(viewState => viewState.set('zoom', newPspdfZoom));
      setMUContainerZoom(newMUContainerZoom);
    }
  };

  const zoomOut = () => {
    const newMUContainerZoom = muContainerZoom - 10;
    if (newMUContainerZoom >= zoomOptions.minZoom) {
      const newPspdfZoom = translateFromMuToPspdfKitZoom(newMUContainerZoom, zoomContainerWidthRatio);
      instance && instance.setViewState(viewState => viewState.set('zoom', newPspdfZoom));
      setMUContainerZoom(newMUContainerZoom);
    }
  };

  const fitToWidth = () => {
    instance && instance.setViewState(viewState => viewState.set('zoom', 'FIT_TO_WIDTH'));
    setMUContainerZoom(100);
  };

  const goToNextPage = () => {
    instance && instance.setViewState(viewState => viewState.goToNextPage());
  };

  const goToPreviousPage = () => {
    instance && instance.setViewState(viewState => viewState.goToPreviousPage());
  };

  const toggleFullscreen = () => {
    if (!fullscreenContainer.current) return;

    if (!fscreen.fullscreenElement) {
      fscreen.requestFullscreen(fullscreenContainer.current);
    }
    else {
      fscreen.exitFullscreen();
    }
  };

  const handleSearchBarVisibility = () => {
    if (isSearchVisible) {
      instance?.setViewState((viewState: ViewState) =>
        viewState.set('interactionMode', window.PSPDFKit.InteractionMode.SEARCH));
    }
    else {
      instance?.setViewState((viewState: ViewState) =>
        viewState.set('interactionMode', null));
    }
  };

  useEffect(() => {
    handleSearchBarVisibility();
  }, [isSearchVisible]);

  const LoadingErrorToast = () => <CustomToast
    show={hasDocumentLoadError}
    title={t('Ups, something went wrong')}
    bodyText={t('Document could not be loaded - Please try to refresh the page.')}
  />;

  const ReadOnlyToast = () => <CustomToast
    show={showReadOnlyToast}
    onClose={() => setShowReadOnlyToast(false)}
    delay={5000}
    title={t('Read Only Content')}
    bodyText={t('This content can be read exclusively by premium users.')}
  />;

  return (
    <>
      {
        props.variant === 'mobile' &&
        <>
          <div
            ref={pdfContainer as React.RefObject<any>}
            className='pdf-reader__mobile-container'
            data-testid={isLoaded && 'reader__content--loaded'}
          />
          <CustomToast
            delay={8000}
            show={isNoTextEditToastVisible}
            onClose={() => setNoTextEditToastVisible(false)}
            title={t('Image-based data')}
            bodyText={t('Unfortunately, text marking is not possible in this document.')}
            variant='mobile'
          />
          <ReadOnlyToast />
          <LoadingErrorToast />
        </>
      }
      {props.variant === 'desktop' &&
        <div
          id='reader-document-content'
          className={classnames(
            'h-100 justify-content-center',
            { 'reader-document-content--full-screen': isFullscreenMode }
          )}
        >
          <div className='content__controls bg--grey-medium-10'>
            <ReaderContentControls
              fullscreenMode={isFullscreenMode}
              currentPage={currentPage}
              pageCount={pageCount}
              currentZoom={muContainerZoom}
              zoomIn={zoomIn}
              zoomOut={zoomOut}
              fitToWidth={fitToWidth}
              goToNextPage={goToNextPage}
              goToPreviousPage={goToPreviousPage}
              toggleFullscreen={toggleFullscreen}
              fullscreenContainer={fullscreenContainer.current}
            />
          </div>
          <div
            className='content__body h-100 w-100 position-absolute'
          >
            <div
              ref={pdfContainer as React.RefObject<any>}
              className='h-100 w-100 position-absolute'
              data-testid={isLoaded && 'reader__content--loaded'}
            />
            {!isTextBased &&
              <CustomToast
                delay={1600}
                show={isNoTextEditToastVisible}
                onClose={() => setNoTextEditToastVisible(false)}
                title={t('Image-based data')}
                bodyText={t('Unfortunately, text marking is not possible in this document.')}
              />
            }
            <ReadOnlyToast />
            <LoadingErrorToast />
          </div>
          <div className='content__actions bg--grey-medium-10'>
            <ReaderActions
              fullscreenContainer={fullscreenContainer.current}
              hideDownloadAction={isReadOnly && !hasAttachments}
            />
          </div>
        </div>
      }
    </>
  );
};

export default PdfReader;
