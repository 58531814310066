import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { cancelSubscription as cancelSubscriptionAction } from '../../../actions/user.actions';
import useAction from '../../../utils/useAction';
import ScreenStepContainer, { ScreenStepProps } from '../ScreenModal/ScreenStepContainer';
import '../ScreenModal/ScreenStepContainer.scss';
import './cancellationStepArguments.scss';
import { getDateInGermanFormat } from '../../../utils/format';
import { selectUser, getSubscription } from '../../../reducers/user.selectors';
import { SubscriptionResponse } from '@klab-berlin/api-sdk/lib/types/responses/Billing';
import {
  ampliTrackSubscriptionCancellationSelectedEvent,
  ampliTrackSubscriptionPauseSelectedEvent 
} from '../../../services/track.service';
import { ampliSubscriptionPageSource } from '../../../constants';

const CancellationStepArguments: React.FC<ScreenStepProps> = (props) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const user = useSelector(selectUser);
  const subscription: SubscriptionResponse | undefined = useSelector(getSubscription);
  const cancelSubscription = useAction(cancelSubscriptionAction);

  if (!subscription) {
    return null;
  }

  const isBeyondNoticePeriod = subscription.cancellation.isBeyondNoticePeriod;

  const getFormattedEndDate = () => {
    const followingSubscriptionEndDate = subscription.followingSubscription.endDate;

    const endDate = isBeyondNoticePeriod && followingSubscriptionEndDate
      ? followingSubscriptionEndDate
      : subscription.endDate;
    return getDateInGermanFormat(endDate);
  };
  
  const goToPauseSelectionButton = {
    action: () => {
      if (user && subscription) {
        ampliTrackSubscriptionPauseSelectedEvent(ampliSubscriptionPageSource.LAST_CONFIRMATION);
      }

      props.setScreen('pauseSelection');
    },
    disabled: isSubmitting,
    title: t('Pause Subscription'),
  };

  const cancellationButton = {
    action: () => {
      if (user && subscription) {
        ampliTrackSubscriptionCancellationSelectedEvent(
          user.isTrial,
          ampliSubscriptionPageSource.LAST_CONFIRMATION
        );
      }
      
      setIsSubmitting(true);
      return cancelSubscription()
        .then(() => props.setScreen('finishCancelling'))
        .catch(() => props.setScreen('errorRetry'));
    },
    disabled: isSubmitting,
    title: t('Continue Cancellation')
  };

  const getTitle = (): string => {
    const transKey = isBeyondNoticePeriod ?
      'If you cancel your premium subscription, you will lose on the {endDate}' :
      'If you cancel your premium subscription, you will lose at the end of your current contract period {endDate}';
    return t(transKey, {
      endDate: getFormattedEndDate()
    });
  };

  return (
    <ScreenStepContainer
      trackingId='arguments'
      title={t('You really want to lose all premium benefits?')}
      primaryButton={goToPauseSelectionButton}
      secondaryButton={cancellationButton}
      onClose={props.onClose}
    >
      <div className='screen-step__img screen-step__sad-faces' />
      <h3>{getTitle()}</h3>
      <ol className='cancellation-step__arguments'>
        <li>{t('Cancellation Con-Argument 1')}</li>
        <li>{t('Cancellation Con-Argument 2')}</li>
        <li>{t('Cancellation Con-Argument 3')}</li>
      </ol>
      <p>{t('Cancellation Pause-Argument')}</p>
    </ScreenStepContainer>
  );
};

export default CancellationStepArguments;
