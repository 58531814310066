import React, { useCallback, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import * as yup from 'yup';
import { routes } from '../../constants';
import Spinner from 'react-bootstrap/Spinner';
import services from '../../services';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { password } from '../../validators';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import { Link } from 'found';
import i18next from 'i18next';
import { useForm } from 'react-hook-form';
import FormInput from '../forms/FormInput';

export interface ChangeEmailPayload {
  oldEmail: string;
  password: string;
  newEmail: string;
  isLibraryUser?: boolean;
}

interface ChangeEmailProps {
  onChangeEmailSuccess: (payload: ChangeEmailPayload) => void;
}

const ChangeEmailFormValidator = yup.object<ChangeEmailPayload>({
  oldEmail: yup.string().email('Invalid email address'),
  newEmail: yup.string().email('Invalid email address').notOneOf([yup.ref('oldEmail')], 'E-Mails must not match'),
  password: password()
});

const getErrorMessage = (t: i18next.TFunction, error: Error) => {
  if (error.message.match(/Invalid username or password/)) {
    return t('Invalid username or password');
  }

  return t('Please try to refresh the page or set your password again');
};

const ChangeEmail: React.FC<ChangeEmailProps> = (props) => {
  const [error, setError] = useState<Error | null>(null);
  const { t } = useTranslation();
  const {
    errors,
    handleSubmit,
    register,
    formState,
  } = useForm<ChangeEmailPayload>({ mode: 'onBlur', validationSchema: ChangeEmailFormValidator });

  const onSubmit = handleSubmit(useCallback(async (payload: ChangeEmailPayload) => {
    try {
      const { oldEmail, newEmail, password } = payload;
      await services.user.changeEmail(oldEmail, newEmail, password, true);
      props.onChangeEmailSuccess({ ...payload, isLibraryUser:true });
    } catch (error: any) {
      setError(error);
    }
  }, []));

  return (
    <>
      <Row>
        <Col>
          {error && (
            <Alert variant='danger'>
              <Alert.Heading>{t('Ups, something went wrong')}</Alert.Heading>
              <p>{getErrorMessage(t, error)}</p>
            </Alert>
          )}
          <form onSubmit={onSubmit} className='py-3 login-form'>
            <fieldset disabled={formState.isSubmitting}>
              <Row>
                <Col className='my-2'>
                  {t('With a free basis account you can search for content and access your collections')}
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormInput
                    required={true}
                    name='oldEmail'
                    placeholder={t('Old E-Mail')}
                    id='oldEmail'
                    type='email'
                    errors={errors}
                    ref={register}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormInput
                    required={true}
                    name='password'
                    placeholder={t('Password')}
                    id='password'
                    type='password'
                    errors={errors}
                    ref={register}
                  />
                </Col>
              </Row>
              <Row>
                <Col className='my-2'>
                  {t('Please enter your new E-Mail Address')}
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormInput
                    required={true}
                    name='newEmail'
                    placeholder={t('New E-Mail')}
                    id='newEmail'
                    type='email'
                    errors={errors}
                    ref={register}
                  />
                </Col>
              </Row>
              <Button variant='primary' type='submit' className='login-button w-100'>
                {formState.isSubmitting ? <Spinner animation='border' /> : t('Next')}
              </Button>
            </fieldset>
          </form>
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col>
          <Col className='d-flex justify-content-between border-top py-4 px-0'>
            <Link to={`/${routes.login.root}`}>
              {t('Back to login')}
            </Link>
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default ChangeEmail;
