import { Link } from 'found';
import React from 'react';

interface Props {
  linkTo: string;
  isSelected: boolean;
}

const RouterMenuItem: React.FC<Props> = (props) =>
  <Link
    className={`text-decoration-none px-2 d-block menu-link ${props.isSelected ? 'active' : ''}`}
    to={props.linkTo}
  >
    {props.children}
  </Link>;

export default RouterMenuItem;
