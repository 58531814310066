import React from 'react';
import useAction from '../utils/useAction';
import useRouter from '../utils/useRouter';
import { logout as logoutAction } from '../actions/auth.actions';
import { routes } from '../constants';

interface LogoutProps {
  render: (handleLogout: () => void) => React.ReactNode;
}

const Logout: React.FC<LogoutProps> = (props) => {

  const { router } = useRouter();
  const logout = useAction(logoutAction);

  const handleLogout = async () => {
    await logout();
    router.push({
      pathname: `/${routes.login.root}`,
      query: {
        out: 'true'
      },
    });
  };

  return (
    <>
      {props.render(handleLogout)}
    </>
  );
};

export default Logout;
