import React, { memo } from 'react';
import { alpha } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';
import { palette, spaces } from '../../../../../assets/styles/themes/tokens';
import { MenuOption } from '../ContextMenu';
interface TextFormatMenuProps {
    options: MenuOption[];
    onMenuItemClick: () => void;
}

const TextFormatMenu: React.FC<TextFormatMenuProps> = ({ options, onMenuItemClick }) => {

  const handleTextFormatClick = (onClick: () => void) => {
    onMenuItemClick();
    onClick();
  };
      
  return (
    <Box 
      data-testid='text-context-menu'
      sx={{
        display: 'flex',
        gap: spaces.xxs,
      }}
    >
      {options.map((option) => (
        <IconButton
          data-testid={option.dataTestId}
          key={option.id}
          color='secondary'
          size='small'
          onClick={() => handleTextFormatClick(option.onClick)}
          sx={{
            backgroundColor: option.isActive ? alpha(palette.primary.main, 0.08) : 'transparent',
            '&:hover': {
              backgroundColor: option.isActive ? alpha(palette.primary.main, 0.08) : alpha(palette.primary.main, 0.06),
            },
          }}
        >
          {option.icon}
        </IconButton>
      ))}
    </Box>
  );
};

export default memo(TextFormatMenu);
