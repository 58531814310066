import { Editor } from '@tiptap/react';
import { FormatTextdirectionLToR, FormatListBulleted, FormatListNumbered } from '@mui/icons-material';
import { SvgIcon } from '@mui/material';
import { getTextFormatStates } from './getTextFormatStates';

export type TextFormatOption = {
    label: string
    id: string
    type: 'option'
    disabled: () => boolean
    isActive: boolean
    onClick: () => void
    icon: typeof SvgIcon | string
  };

export const getTextFormatOptions = (editor: Editor) => {
  const states = getTextFormatStates(editor);
  
  const options: TextFormatOption[] = [
    {
      icon: FormatTextdirectionLToR,
      onClick: () => editor.chain().focus().lift('taskItem').liftListItem('listItem').setParagraph().run(),
      id: 'paragraph',
      disabled: () => !editor.can().setParagraph(),
      isActive: states.isParagraph,
      label: 'Paragraph',
      type: 'option',
    },
    {
      icon: 'H1',
      onClick: () => editor.chain().focus().lift('taskItem').liftListItem('listItem').setHeading({ level: 1 }).run(),
      id: 'heading1',
      disabled: () => !editor.can().setHeading({ level: 1 }),
      isActive: states.isHeading(1),
      label: 'Heading 1',
      type: 'option',
    },
    {
      icon: 'H2',
      onClick: () => editor.chain().focus().lift('taskItem').liftListItem('listItem').setHeading({ level: 2 }).run(),
      id: 'heading2',
      disabled: () => !editor.can().setHeading({ level: 2 }),
      isActive: states.isHeading(2),
      label: 'Heading 2',
      type: 'option',
    },
    {
      icon: 'H3',
      onClick: () => editor.chain().focus().lift('taskItem').liftListItem('listItem').setHeading({ level: 3 }).run(),
      id: 'heading3',
      disabled: () => !editor.can().setHeading({ level: 3 }),
      isActive: states.isHeading(3),
      label: 'Heading 3',
      type: 'option',
    },
    {
      icon: FormatListBulleted,
      onClick: () => editor.chain().focus().toggleBulletList().run(),
      id: 'bulletList',
      disabled: () => !editor.can().toggleBulletList(),
      isActive: states.isBulletList,
      label: 'Bullet list',
      type: 'option',
    },
    {
      icon: FormatListNumbered,
      onClick: () => editor.chain().focus().toggleOrderedList().run(),
      id: 'orderedList',
      disabled: () => !editor.can().toggleOrderedList(),
      isActive: states.isOrderedList,
      label: 'Numbered list',
      type: 'option',
    },
  ];

  return options;
};
