import Icon from './Icon';
import React from 'react';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  followCollection,
  unfollowCollection,
} from '../actions/collections.actions';
import { createFollowCuratedCollectionTrackingConfig } from '../utils/tracking';
import WithTracking from './utils/WithTracking';
import { CollectionProperties } from '../types/AppContent';

interface FollowButtonOwnProps {
  collection: CollectionProperties;
}

interface FollowButtonProps extends FollowButtonOwnProps {
  followCollection: typeof followCollection;
  unfollowCollection: typeof unfollowCollection;
}

const FollowButton: React.FC<FollowButtonProps> = ({ collection, ...props }) => {
  const isFollowed = collection.isFollowing;
  const { t } = useTranslation();
  
  const followHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    return isFollowed
      ? props.unfollowCollection(collection)
      : props.followCollection(collection);
  };

  return (
    <WithTracking {...createFollowCuratedCollectionTrackingConfig(isFollowed, collection)}>
      <Button
        onClick={followHandler}
        variant='primary'
        className='d-flex align-items-center'
      >
        <>
          {!isFollowed &&
            <Icon icon='arrow-right' />
          }
          {isFollowed &&
            <Icon icon='check' />
          }
        </>
        <div className='d-block d-md-none d-lg-block text-uppercase ml-2'>
          {t(isFollowed ? 'I follow' : 'Follow')}
        </div>
      </Button>
    </WithTracking>
  );
};

export default compose<React.FC<FollowButtonOwnProps>>(
  connect(
    null,
    {
      followCollection,
      unfollowCollection,
    },
  ),
)(FollowButton);
