import React from 'react';
import './readerContentControls.scss';
import ReaderControls from './ReaderControls';
import ActionButton from '../../ActionButton';
import ReaderControlsValueStepper from './ReaderControlsValueStepper';
import WithTooltip from '../../WithTooltip';
import { useReaderContext } from '../ReaderContext';
import { useTranslation } from 'react-i18next';
import { Placement } from 'react-bootstrap/Overlay';

interface ReaderContentControlsProps {
  currentPage: number;
  currentZoom: number;
  pageCount: number;
  zoomIn: () => void;
  zoomOut: () => void;
  fitToWidth: () => void;
  goToNextPage: () => void;
  goToPreviousPage: () => void;
  toggleFullscreen: () => void;
  fullscreenMode: boolean;
  fullscreenContainer?: HTMLElement;
}

const ReaderContentControls: React.FC<ReaderContentControlsProps> = (props) => {
  const { mindItemId } = useReaderContext();
  const { fullscreenMode } = props;
  const { t } = useTranslation();

  const tooltipPlacement: Placement = 'right';

  return (
    <ReaderControls>
      <WithTooltip text={fullscreenMode ? t('Close fullscreen') : t('Open fullscreen')}
        placement={tooltipPlacement} container={props.fullscreenContainer}>
        <ActionButton
          onClick={props.toggleFullscreen}
          icon={fullscreenMode ? 'cancel' : 'full-screen'}
        />
      </WithTooltip>
      <WithTooltip text={t('Fit to width')} placement={tooltipPlacement} container={props.fullscreenContainer}>
        <ActionButton onClick={props.fitToWidth} icon='arrow-split' />
      </WithTooltip>
      <ReaderControlsValueStepper
        value={props.currentPage.toString().padStart(2, '0')}
        unit=''
        valueTestId='reader__page-number'
        valueEventPayload={{
          other: {
            'minditem_id': mindItemId,
            'current_page': props.currentPage,
            'page_count': props.pageCount,
            'is_fullscreen': fullscreenMode
          }
        }}
        increaseButton={
          <WithTooltip text={t('Next page')} placement={tooltipPlacement} container={props.fullscreenContainer}>
            <ActionButton onClick={props.goToNextPage} icon='arrow-small-down' testId='next-page-button' />
          </WithTooltip>
        }
        decreaseButton={
          <WithTooltip text={t('Previous page')} placement={tooltipPlacement} container={props.fullscreenContainer}>
            <ActionButton onClick={props.goToPreviousPage} icon='arrow-small-up' testId='previous-page-button' />
          </WithTooltip>
        }
      />
      <ReaderControlsValueStepper
        value={props.currentZoom}
        unit='%'
        valueEventPayload={{
          other: {
            'minditem_id': mindItemId,
            'zoom_level': props.currentZoom,
            'is_fullscreen': fullscreenMode
          }
        }}
        increaseButton={
          <WithTooltip text={t('Zoom in')} placement={tooltipPlacement} container={props.fullscreenContainer}>
            <ActionButton onClick={props.zoomIn} icon='zoom-in' />
          </WithTooltip>
        }
        decreaseButton={
          <WithTooltip text={t('Zoom out')} placement={tooltipPlacement} container={props.fullscreenContainer}>
            <ActionButton onClick={props.zoomOut} icon='zoom-out' />
          </WithTooltip>
        }
      />
    </ReaderControls>
  );
};

export default ReaderContentControls;
