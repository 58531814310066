import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectSchoolTypes, selectSchoolTypesLoadingStatus } from '../../../reducers/schoolTypes.selectors';
import { BasicFilterProps } from '../../../types/search';
import { Select } from '../../common/Select';
import SearchFilterSkeleton from '../SearchFilterSkeleton';
import useFilterStatus from '../useFilterStatus';

const FilterBySchoolType: React.FC<BasicFilterProps<string[]>> = (
  { updateFilter, clearFilter, currentValue, onToggle, onItemClick }
) => {
  const { t } = useTranslation();
  const schoolTypes = useSelector(selectSchoolTypes);
  const { isReady, isError } = useFilterStatus(selectSchoolTypesLoadingStatus);

  type Option = {
    name: string;
    value: string;
  };

  if (isError) {
    return null;
  }

  if (!isReady) {
    return <SearchFilterSkeleton />;
  }

  const options: Option[] = schoolTypes
    .filter(schoolType => schoolType.searchId)
    .map(schoolType => ({
      name: schoolType.name,
      value: schoolType.searchId as string,
    }));

  return (
    <Select
      onSelectionConfirm={(values) => {
        updateFilter(values);
      }}
      onSelectionClear={() => {
        clearFilter();
      }}
      alwaysShowLabel={true}
      testId={'dropdown-schooltype'}
      label={t('SchoolType')}
      options={options}
      value={currentValue}
      multi={true}
      onToggle={onToggle}
      onItemClick={onItemClick}
      saveButtonLabel='Anwenden'
      resetButtonLabel='Löschen'
    />
  );
};

export default FilterBySchoolType;
