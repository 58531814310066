import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectTeachingFormats, selectTeachingFormatLoadingStatus } from '../../reducers/search.selectors';
import { BasicFilterProps } from '../../types/search';
import { Select } from '../common/Select';
import SearchFilterSkeleton from './SearchFilterSkeleton';
import useFilterStatus from './useFilterStatus';

const FilterByTeachingFormat: React.FC<BasicFilterProps<string[]>> = (props) => {
  const { t } = useTranslation();
  const { isReady, isError } = useFilterStatus(selectTeachingFormatLoadingStatus);
  const teachingFormats = useSelector(selectTeachingFormats);

  type Option = {
    name: string;
    value: string;
  };

  const options: Option[] = teachingFormats.map((format) => ({ name: format.name, value: format.id }));
  
  if (isError) {
    return null;
  }

  if (!isReady) {
    return <SearchFilterSkeleton />;
  }
  
  return (
    <Select
      onSelectionConfirm={(lessonFormats) => {
        props.updateFilter(lessonFormats);
      }}
      onSelectionClear={() => {
        props.clearFilter();
      }}
      label={t('Teaching Format')}
      options={options}
      value={props.currentValue}
      multi={true}
      alwaysShowLabel={true}
      onToggle={props.onToggle}
      onItemClick={props.onItemClick}
      saveButtonLabel='Anwenden'
      resetButtonLabel='Löschen'
    />
  );
};

export default FilterByTeachingFormat;
