import immutable from 'object-path-immutable';
import {
  ADD_ERROR,
  AddError,
  DISMISS_ERROR,
  DismissError,
  Error,
} from '../actions/errors.actionTypes';
import { LogoutAction, LOGOUT } from '../actions/auth.actionTypes';

type ProductActionTypes = AddError | DismissError | LogoutAction;

export interface ErrorsState {
  list: Error[];
}

const initialState: ErrorsState = {
  list: [],
};

const errors = (state = initialState, action: ProductActionTypes): ErrorsState => {
  switch (action.type) {
    case LOGOUT.SUCCESS:
      return initialState;

    case ADD_ERROR:
      return immutable.push(state, 'list', action.payload);

    case DISMISS_ERROR: {
      const errorIndex = state.list.findIndex((error) => error.id === action.payload.id);

      return immutable.del(state, `list.${errorIndex}`);
    }
  }
  return state;
};

export default errors;
