import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Truncate from '../../../Truncate';

interface TruncateNoExpandProps {
  lines: number;
  text: string;
  showMoreClassName?: string;
}

const TruncateNoExpand: React.FC<TruncateNoExpandProps> = (props) => {
  const { t } = useTranslation();
  const [truncated, setTruncated] = useState(false);

  return (
    <div>
      <Truncate lines={props.lines} text={props.text} onTruncationChange={setTruncated} />
      { truncated && <div className={`${props.showMoreClassName}`}>
        {t('show more')}
      </div> }
    </div>
  );
};

export default TruncateNoExpand;
