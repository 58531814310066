import { StoreType } from '.';

export const selectPublishers = (state: StoreType) => {
  if (!state.publishers.data) {
    return [];
  }
  return state.publishers.data;
};

export const selectPublisherLoadingStatus = (state: StoreType) =>
  state.publishers.status;
