import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { emailAddresses } from '../../../constants';
import { selectUser } from '../../../reducers/user.selectors';
import LabeledText from '../../common/LabeledText';
import PersonalDetailsInputGroup from '../PersonalDetailsInputGroup';
import Caption from '../../common/Caption';

const ChangeEmailView = () => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const isSchoolUser = user?.isSchoolUser;

  if (!user) {
    return <></>;
  }

  return (
    <PersonalDetailsInputGroup>
      <div>
        <LabeledText label={t('Email address')} text={user.email} id='email' />
        {isSchoolUser &&
          <Caption>
            <Trans i18nKey={'Your email address is no longer active or has changed? Please write us an email to'}>
              <a href={`mailto:${emailAddresses.schoolSupport}`}>
                {{ email: emailAddresses.schoolSupport }}
              </a>
            </Trans>
          </Caption>
        }
      </div>
    </PersonalDetailsInputGroup>
  );
};

export default ChangeEmailView;
