import { ThunkAction } from './common.actionTypes';
import lernsetsService from '../services/lernsets.service';
import { 
  ADD_LERNSET, 
  AddLernsetAction, 
  AddPagesLernsetAction,
  GET_LERNSETS, 
  GetLernsetsAction, 
  ADD_PAGES_LERNSET, 
  DeleteLernsetAction,
  DELETE_LERNSET,
  UpdateLernsetAction,
  UPDATE_LERNSET,
  DUPLICATE_LERNSET,
  DuplicateLernsetAction,
  FETCH_PART_IMAGES,
  FetchPartImagesAction,
  SetLastAnnotatedPartIdActionType,
  ClearPartsImagesActionType,
  ADD_BLANK_PAGE,
  AddBlankPageAction,
  ADD_RICH_TEXT_PART,
  AddRichTextPartAction,
} from './lernsets.actionTypes';
import { 
  AddBlankPageToLernsetRequest, 
  UpdateLernsetRequest, 
  AddRichTextPartRequest 
} from '@klab-berlin/api-sdk/lib/types/requests/Lernsets';
import { Dispatch } from 'redux';
import { createBlankImageObjectUrl } from '../utils/lernsets';

const a4ratio = 1.426;
export const imageMaxSideLength = 840;
export const defaultImageHeight = imageMaxSideLength;
export const defaultImageWidth = defaultImageHeight/a4ratio;

export function getLernsets(): ThunkAction<GetLernsetsAction> {
  return async (dispatch, getState) => {
    const me = getState().user.me;
    const lernsets = getState().lernsets.byId;
    if (!me || Object.keys(lernsets).length > 0) { return; }

    const userId = me._id;
    const payload = { userId };

    dispatch({ type: GET_LERNSETS.START, payload });

    return lernsetsService.getLernsets(userId)
      .then(result => dispatch({ type: GET_LERNSETS.SUCCESS, result }))
      .catch(error => dispatch({ error, type: GET_LERNSETS.ERROR, payload }));
  };
}

export function addLernset(title: string): ThunkAction<AddLernsetAction> {
  return async (dispatch, getState) => {   
    const me = getState().user.me;
    if (!me) { return; }

    const userId = me._id;
    dispatch({ type: ADD_LERNSET.START });

    const payload = { title };

    return lernsetsService.addLernset(userId, payload)
      .then(result => dispatch({ type: ADD_LERNSET.SUCCESS, result, payload  }))
      .catch(error => dispatch({ error, type: ADD_LERNSET.ERROR, payload }));
  };
}

export function setLastAnnotatedPartId(dispatch: Dispatch, partId: string) {
  dispatch({ type: SetLastAnnotatedPartIdActionType, payload: { partId } });
}

export function clearAllLernsetsPartsImages(dispatch: Dispatch) {
  dispatch({ type: ClearPartsImagesActionType });
}

export function addPagesToLernset(lernsetId: string, mindItemId: string, pages: number[]): 
  ThunkAction<AddPagesLernsetAction> {
  return async (dispatch, getState) => {
    const me = getState().user.me;
    if (!me) { return; }

    const userId = me._id;
    dispatch({ type: ADD_PAGES_LERNSET.START });

    const payload = { pages, mindItemId };

    return lernsetsService.addPagesToLernset(userId, lernsetId, payload)
      .then(result => dispatch({ type: ADD_PAGES_LERNSET.SUCCESS, result, payload  }))
      .catch((error) => { dispatch({ type: ADD_PAGES_LERNSET.ERROR, payload, error }); throw error; });
  };
}

export function fetchPartImages(lernsetId: string, partIndices: number[]): 
  ThunkAction<FetchPartImagesAction> {
  return async (dispatch) => {

    dispatch({ type: FETCH_PART_IMAGES.START, payload: { lernsetId } });

    return lernsetsService.getPartsImages(lernsetId, partIndices, imageMaxSideLength)
      .then(result => { dispatch({ type: FETCH_PART_IMAGES.SUCCESS, result: result, payload: { lernsetId } }); })
      .catch(error => dispatch({ error, type: FETCH_PART_IMAGES.ERROR, payload: { lernsetId } }));
  };
}

export function deleteLernset(lernsetId: string): ThunkAction<DeleteLernsetAction> {
  return async (dispatch) => {   
    dispatch({ type: DELETE_LERNSET.START });

    return lernsetsService.deleteLernset(lernsetId)
      .then(() => dispatch({ type: DELETE_LERNSET.SUCCESS, result: { id: lernsetId } }))
      .catch(error => dispatch({ error, type: DELETE_LERNSET.ERROR }));
  };
}

export function duplicateLernset(lernsetId: string, partId: string): ThunkAction<DuplicateLernsetAction> {
  return async (dispatch) => {   
    dispatch({ type: DUPLICATE_LERNSET.START, payload: { lernsetId, partId } });

    const payload = { lernsetId, partId };

    return lernsetsService.duplicateLernset(lernsetId, partId)
      .then(result => dispatch({ type: DUPLICATE_LERNSET.SUCCESS, result, payload }))
      .catch((error) => { throw error; });
  };
}

export function updateLernset(lernsetId: string, payload: UpdateLernsetRequest): 
  ThunkAction<UpdateLernsetAction> {
  return async (dispatch, getState) => {   
    const me = getState().user.me;
    if (!me) { return; }

    const newPayload = { ...payload, id: lernsetId };
    dispatch({ type: UPDATE_LERNSET.START, payload: newPayload });

    return lernsetsService.updateLernset(lernsetId, payload)
      .then(() => dispatch({ type: UPDATE_LERNSET.SUCCESS, payload: newPayload, result: {} }))
      .catch(error => dispatch({ error, type: UPDATE_LERNSET.ERROR, payload: newPayload }));
  };
}

export function addBlankPageToLernset(
  lernsetId: string, 
  payload: AddBlankPageToLernsetRequest,
): 
  ThunkAction<AddBlankPageAction> {
  return async (dispatch) => {   
    const newPayload = { ...payload, lernsetId };
    dispatch({ type: ADD_BLANK_PAGE.START, payload: newPayload });

    return lernsetsService.addBlankPage(lernsetId, payload)
      .then(async (result) => {
        const imageUrl = await createBlankImageObjectUrl(defaultImageWidth, defaultImageHeight);
        dispatch({ type: ADD_BLANK_PAGE.SUCCESS, result: { ...result, imageUrl }, payload: newPayload });
      })
      .catch((error) => { throw error; });
  };
}

export function addRichTextPart(lernsetId: string, payload: AddRichTextPartRequest): 
  ThunkAction<AddRichTextPartAction> {
  return async (dispatch) => {
    const newPayload = { ...payload, lernsetId };
    dispatch({ type: ADD_RICH_TEXT_PART.START, payload: newPayload });
    
    return lernsetsService.addRichTextPart(lernsetId, payload)
      .then(async (result) => {
        dispatch({ type: ADD_RICH_TEXT_PART.SUCCESS, result: { ...result }, payload: newPayload });
      })
      .catch((error) => { throw error; });
  };
}
