import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from '../../reducers/user.selectors';
import { bannerContext } from '../../state/BannerContextProvider';
import { routes } from '../../constants';
import './upgradeBanner.scss';
import { Link } from 'found';
import config from 'config';
import { isFeatureEnabled } from '../../utils/feature';
const UpgradeBanner: React.FC = () => {
  const { t } = useTranslation();
  const { bannerIsOpen } = useContext(bannerContext);
  const user = useSelector(selectUser);
  const shouldShowBanner = isFeatureEnabled('upgradeBanner') && user && !user.isPremium;
  const activeDates = config.features['upgradeBanner'].activeDates;

  // If there are no active dates provided, then the banner should be visible
  let isActiveDatesCorrect: boolean = activeDates ? false : true;
  const today: number = new Date().setHours(0, 0, 0, 0);
  if (activeDates) {
    const startDate: number = new Date(activeDates.startDate).setHours(0, 0, 0, 0);
    const endDate: number = new Date(activeDates.endDate).setHours(0, 0, 0, 0);

    if (today >= startDate && today <= endDate) {
      isActiveDatesCorrect = true;
    }
  }

  const isVisible = bannerIsOpen && shouldShowBanner && isActiveDatesCorrect;
  if (!isVisible) {
    return null;
  }

  // Distinction of two upgrade banner ranges
  const earlyBirdEnd = new Date('2024-08-16').setHours(0, 0, 0, 0);
  const isTodayEarlyBird = today <= earlyBirdEnd;
  let promoCode, i18nKey;
  if (isTodayEarlyBird) {
    promoCode = 'fruehstark30';
    i18nKey = '30% early-bird discount: Start early and stronger than ever with the new school year';
  } else {
    promoCode = 'smartstart20';
    i18nKey = 'Save 20% with premium: Start strong now and prepare smartly for a successful school year';
  }

  const pathname = `/${routes.upgradePayment.root}`;
  return (
    <div className='upgrade-banner py-2 px-4 d-flex'>
      <div className='flex-grow-1 py-2'>
        <Trans i18nKey={i18nKey} key={1}>
          <strong>🎁 % Discount:</strong> For a successful school year.
        </Trans>
        {/* t('Sorry. We are having server problems. We are working on a solution.') */}
      </div>
      <div className='upgrade-banner__cta-wrapper'>
        <Link
          className='upgrade-banner__cta upgrade-banner__cta-primary'
          to={{
            pathname,
            query: {
              licenceId: '2830',
              promoCode
            }
          }}>
          {t('Go to teacher discount')}
        </Link>
        <Link
          className='upgrade-banner__cta upgrade-banner__cta-secondary'
          to={{
            pathname,
            query: {
              licenceId: '2850',
              promoCode
            }
          }}>
          {t('Go to trainee discount')}
        </Link>
        {/* <button className='btn-none' onClick={closeBanner}>
          <Icon icon='cancel' className='color--white' size='m' />
        </button> */}
      </div>
    </div>
  );
};

export default UpgradeBanner;
