import services from '../services';

let timeout: number;

const isFakeEmail = async (email?: string): Promise<boolean> => {
  if (!email) return false;

  if (timeout) {
    window.clearTimeout(timeout);
  }

  return new Promise(resolve => {
    timeout = window.setTimeout(async () => {
      const { isFakeEmail } = await services.email.isFakeEmail(email);
      resolve(!isFakeEmail);
    }, 200);
  });
};

export default isFakeEmail;
