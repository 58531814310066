import classnames from 'classnames';
import React, { useState } from 'react';
import FormInputWrapper from '../../FormInputWrapper';
import Icon from '../../../Icon';
import '../generalInput.scss';
import './passwordInput.scss';

interface PasswordInputProps {
  label: string;
  name: string;
  error?: string
  required?: boolean;
  placeholder?: string;
  autoComplete?: string;
}

const PasswordInput = React.forwardRef<HTMLInputElement, PasswordInputProps>((props, ref) => {
  const [isRevealed, setRevealed] = useState(false);

  const inputProps = {
    className: classnames({
      'general-input__field--normal': !props.error,
      'general-input__field--error': !!props.error
    }),
    type: isRevealed ? 'text' : 'password',
    id: props.name,
    name: props.name,
    placeholder: props.placeholder,
    autoComplete: props.autoComplete
  };

  const toggleReveal = () => {
    setRevealed(!isRevealed);
  };

  return (
    <div className='general-input'>
      <FormInputWrapper
        label={props.label}
        error={props.error}
        required={props.required}
        labelFor={props.name}
      >
        <div className='general-input__input-container'>
          <input {...inputProps} ref={ref} />
          <div className='general-input__overlay-icons'>
            <ViewButton onToggle={toggleReveal} isToggled={isRevealed} />
            {props.error && <CautionIcon />}
          </div>
        </div>
      </FormInputWrapper>
    </div>
  );
});

const CautionIcon = () => {
  return <Icon size='lg' className='general-input__caution-icon' icon='caution' />;
};

const ViewButton: React.FC<{ onToggle: () => void, isToggled: boolean }> = (props) => {
  return (
    <button
      data-testid='password-input-view-icon'
      className='password-input__reveal-button'
      onClick={props.onToggle}
      type='button'
    > 
      <Icon size='lg' className={classnames(
        'password-input__view-icon',
        { 'password-input__view-icon--toggled': props.isToggled }
      )}
      icon='view' />
    </button>
  );
};

export default PasswordInput;
