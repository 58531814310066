import React from 'react';
import './checkbox.scss';
import classnames from 'classnames';

interface FormCheckboxProps {
  id: string;
  name?: string;
  error?: string | boolean;
  label?: React.ReactNode | string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  value?: string;
  disabled?: boolean;
  className?: string;
  testId?: string;
}

const Checkbox = React.forwardRef<HTMLInputElement, FormCheckboxProps>((props, ref) => {
  return (
    <div className='new-checkbox'>
      <input
        className='new-checkbox__input'
        type='checkbox'
        id={props.id}
        ref={ref}
        name={props.name}
        checked={props.checked}
        onChange={() => props.onChange?.(!props.checked)}
        disabled={props.disabled}
        value={props.value}
      />
      <label
        htmlFor={props.id}
        className={classnames('custom-checkbox', props.className )}
        data-testid={props.testId ? props.testId : 'custom-checkbox'}
      >
        <span className='custom-checkbox__checkbox'></span>
        <span className='custom-checkbox__label'>{props.label}</span>
      </label>
      {props.error && <div className='new-checkbox__error'>{props.error}</div>}
    </div>
  );
});

export default Checkbox;
