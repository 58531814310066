import { SearchFilters } from '../types/search';
import hash from './hash';

export const getRecentSearches = (userId?: string): SearchFilters[] => {
  if (!userId) return [];
  try {
    const searches = window.localStorage.getItem(`${hash(userId)}-searches`);
    return searches && JSON.parse(searches) || [];
  } catch (error) {
    return [];
  }
};

export const setRecentSearches = (userId: string, searches: SearchFilters[]): boolean => {
  try {
    window.localStorage.setItem(
      `${hash(userId)}-searches`,
      JSON.stringify(searches)
    );
    return true;
  } catch (error) {
    return false;
  }
};
