import { noop } from 'lodash';
import React, { createContext, useState } from 'react';

interface BannerContext {
  bannerIsOpen: boolean;
  closeBanner: () => void;
}

export const bannerContext = createContext<BannerContext>({
  bannerIsOpen: false,
  closeBanner: noop,
});

const BannerContextProvider: React.FC = ({ children }) => {
  const shouldShowBanner = true;
  const [bannerIsOpen, setBannerIsOpen] = useState(shouldShowBanner);

  return ( 
    <bannerContext.Provider
      value={{
        bannerIsOpen: bannerIsOpen,
        closeBanner: () => setBannerIsOpen(false),
      }}
    >
      {children}
    </bannerContext.Provider>
  );
};

export default BannerContextProvider;
