import React from 'react';
import { useSelector } from 'react-redux';
import { routes } from '../../constants';
import { selectIsAuthenticated } from '../../reducers/user.selectors';
import useRouter from '../../utils/useRouter';

const RedirectIfLoggedOut: React.FC = (props) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { router, match } = useRouter();

  if (!isAuthenticated) {
    const redirectUrl = `${match.location.pathname}${match.location.search}`;
    router.push(`/${routes.login.root}?redirect=${encodeURIComponent(redirectUrl)}`);
    return <></>;
  }

  return (
    <>
      {props.children}
    </>
  );
};

export default RedirectIfLoggedOut;
