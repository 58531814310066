import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../Icon';
import '../readerProductNavigation.scss';
import './readerNavigationSibling.scss';
import classnames from 'classnames';
import { DownloadModalContext } from '../../../../state/DownloadModalProvider';
import { MindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import { Link } from 'found';
import { routes } from '../../../../constants';
import { useReaderContext } from '../../ReaderContext';
import WithTooltip from '../../../WithTooltip';
import { ExpandedSibling, ThumbnailClick } from '../ReaderProductNavigation';

interface ReaderNavigationSiblingProps {
  onThumbnailClick: ThumbnailClick;
  setExpandedSibling: (key: ExpandedSibling) => void;
  sibling: MindItem;
  siblingIndex: number;
  isSiblingExpanded: boolean;
  isSidebarExpanded: boolean;
}

const ReaderNavigationSibling: React.FC<ReaderNavigationSiblingProps> = (props) => {
  const {
    onThumbnailClick,
    setExpandedSibling,
    sibling,
    siblingIndex,
    isSiblingExpanded,
    isSidebarExpanded
  } = props;
  const { t } = useTranslation();
  const { currentPage, mindItemId } = useReaderContext();
  const { isOpen: downloadModalIsOpen } = useContext(DownloadModalContext);
  const handleCaretClick = (siblingId: string) => {
    isSiblingExpanded ? setExpandedSibling(undefined) : setExpandedSibling(siblingId);
  };
  const isCurrentMindItem = sibling.id === mindItemId;

  return (
    <div>
      <div
        data-testid='table-of-contents__item'
        className={classnames('table-of-contents__item pt-2 mb-3', {
          'table-of-contents__item--highlighted': isCurrentMindItem && !downloadModalIsOpen
        })}
      >
        <WithTooltip text={isSiblingExpanded ? t('Close') : t('Open')} placement='right'>
          <div
            className='show-on-expand table-of-contents__item-icon'
            onClick={() => handleCaretClick(sibling.id)}
          >
            <Icon
              className='arrow'
              icon={isSiblingExpanded ? 'arrow-small-down' : 'arrow-small-right'}
              testId={`reader__toggle-sibling-${sibling.id}`}
            />
          </div>
        </WithTooltip>
        <Link to={`/${routes.reader.root}/${sibling.id}`}
          data-testid={`reader__sibling__${sibling.id}__link`}
          className={classnames('w-100', { 'd-flex justify-content-center': !isSidebarExpanded })}>
          <div className={classnames('color--grey-dark table-of-contents__item-name', {
            'color--white': isCurrentMindItem && !downloadModalIsOpen
          })}
          >
            <span>{siblingIndex + 1}</span>
            <span className='show-on-expand text-truncate'>{`: ${sibling.title}`}</span>
          </div>
        </Link>
      </div>

      <div className='d-flex flex-column show-on-expand'>
        {sibling.fileType === 'document' && sibling.pages.map((page, imageIndex) => {
          const isActive = isSiblingExpanded && (imageIndex + 1) === currentPage;
          return (
            <div key={imageIndex} className={classnames('sibling-thumbnail mb-0', {
              'sibling-thumbnail--expanded': isSiblingExpanded
            })}>
              <Link
                to={{
                  pathname: `/${routes.reader.root}/${sibling.id}`,
                  query: { page: `${imageIndex + 1}` }
                }}
                key={imageIndex}
                onClick={(e) => onThumbnailClick(e, sibling.id, imageIndex)}
              >
                <div className={classnames('d-flex flex-column align-items-center', {
                  'sibling-thumbnail--active': isCurrentMindItem && isActive
                })}>
                  <img data-testid={`reader__sibling__${sibling.id}__image__${imageIndex}`} src={page.w120} />
                  <div className='color--grey-dark'>{imageIndex + 1}</div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ReaderNavigationSibling;
