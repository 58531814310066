import {
  ZuoraPaymentMethod
} from '@klab-berlin/api-sdk/lib/services/billing';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { updatePaymentMethod } from '../../../actions/user.actions';
import { selectUser } from '../../../reducers/user.selectors';
import { getPaymentMethodData } from '../../../utils/payment';
import useAction from '../../../utils/useAction';
import PaymentForm from '../../PaymentForm/PaymentForm';
import { ZuoraPaymentPageResponse } from '../../../types/ZuoraPayments';
import './personalDetailsPaymentMethodForm.scss';

interface PersonalDetailsPaymentMethodFormProps {
  onResponse: () => void;
}

const PersonalDetailsPaymentMethodForm: React.FC<PersonalDetailsPaymentMethodFormProps> = (props) => {
  
  const user = useSelector(selectUser);

  if (!user) {
    return <></>;
  }

  const [paymentMethod, setPaymentMethod] = useState<ZuoraPaymentMethod>('sepa');

  const updatePaymentMethodAction = useAction(updatePaymentMethod);

  const onSelect = (paymentMethod: ZuoraPaymentMethod) => {
    setPaymentMethod(paymentMethod);
  };

  const onResponseHandler = (paymentMethod: ZuoraPaymentMethod, response: ZuoraPaymentPageResponse) => {
    const paymentData = {
      type: paymentMethod,
      paymentMethodId: response.refId,
      braintreeToken: response.auth,
      licenceId: user.licenceId
    };

    if (!paymentData) {
      return;
    }
    const paymentMethodId = paymentData?.paymentMethodId || paymentData?.braintreeToken;
    if (!paymentMethodId) {
      return;
    }
    const newPaymentMethod = getPaymentMethodData(paymentData?.type, paymentMethodId);
    updatePaymentMethodAction(newPaymentMethod).then(props.onResponse);
  };

  return (
    <div className='personal-details-payment-method-form'>
      <PaymentForm
        onResponse={onResponseHandler}
        onSelect={onSelect}
        selectedMethod={paymentMethod}
      />
    </div>
  );
};

export default PersonalDetailsPaymentMethodForm;
