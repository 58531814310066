import { Action } from 'redux';
import { ThunkAction as _ThunkAction } from 'redux-thunk';
import { StoreType } from '../reducers';

type PayloadInfo<Payload> =
  Payload extends undefined ? Record<string, unknown>
  : { payload: Payload };

type ResultInfo<Result> =
  Result extends undefined ? Record<string, unknown>
  : { result: Result };

type PayloadAction<Type, Payload> = Action<Type> & PayloadInfo<Payload>;

type StartAsyncAction<Type, Payload> = PayloadAction<Type, Payload>;

type SuccessAsyncAction<Type, Response, Payload> = PayloadAction<Type, Payload> & {
  result: Response;
};

type ErrorAsyncAction<Type, Payload> = PayloadAction<Type, Payload> & {
  error: Error;
};

export enum AsyncActionStatus {
  INITIAL = 'initial',
  READY = 'ready',
  LOADING = 'loading',
  ERROR = 'error',
  NOTFOUND = 'notfound',
}

export type AsyncAction<Start, Success, Error, Result, Payload = undefined> =
  StartAsyncAction<Start, Payload> | SuccessAsyncAction<Success, Result, Payload> | ErrorAsyncAction<Error, Payload>;

export type ThunkAction<AsyncActionDefinition extends Action<any>>
  = _ThunkAction<Promise<any>, StoreType, Record<string, unknown>, AsyncActionDefinition>;

export type SyncAction<Type, Result = undefined, Payload = undefined> =
  PayloadAction<Type, Payload> & ResultInfo<Result>;
