import React from 'react';
import ModalBody from '../common/ModalBody';
import ModalToggleButton from '../common/ModalToggleButton';
import { RangeSelectDefaultProps } from '../RangeSelect';
import useRangeSelect from '../common/useRangeSelect';
import { RangeSlider, RangeSliderValues } from '../common/RangeSlider';
import RangeInputBoxes from '../DropdownRangeSelect/RangeInputBoxes';

const ModalRangeSelect: React.FC<RangeSelectDefaultProps> = (props) => {
  const {
    isOpen,
    savedValues,
    setSavedValues,
    open,
    close,
    getLabel,
    isDefaultState,
    handleClearSelection,
    handleSaveSelection,
    isSaveButtonDisabled,
    setIsTouched,
    potentialValues,
    setPotentialValues,
  } = useRangeSelect(props.range, props.label, props.value, props.alwaysShowLabel);

  const handleToggleButtonClick = () => {
    open();
    props.onToggle && props.onToggle();
  };

  return (
    <div className='w-100 d-flex flex-column'>
      <ModalToggleButton
        label={getLabel()}
        icon={props.icon}
        onClick={handleToggleButtonClick}
        selected={!!props.value}
      />
      <ModalBody
        title={props.label}
        modalOpen={isOpen}
        onModalClose={close} 
        enableSaveButton={!isSaveButtonDisabled}
        showResetButton={!isDefaultState}
        onSave={() => handleSaveSelection(props.onSelectionConfirm)}
        onReset={() => handleClearSelection(props.onSelectionClear)}
        hasButtons={true}
      >
        <div className='py-2'>
          <RangeSliderValues
            min={potentialValues ? potentialValues.min : savedValues.min}
            max={potentialValues ? potentialValues.max : savedValues.max}
          />
          <RangeSlider
            min={props.range.min}
            max={props.range.max}
            values={potentialValues || savedValues}
            onChange={setSavedValues}
          />
          <RangeInputBoxes
            min={Math.min(savedValues.min, savedValues.max)} 
            max={Math.max(savedValues.min, savedValues.max)}
            save={setSavedValues}
            setPotentialValues={setPotentialValues}
            onFocus={() => setIsTouched(true)}
          /> 
        </div>
      </ModalBody>
    </div>
  );
};

export default ModalRangeSelect;
