import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../reducers/user.selectors';
import { compressClassYearArray } from '../../../utils/compressClassYearArray';
import LabeledText from '../../common/LabeledText';
import PersonalDetailsInputGroup from '../PersonalDetailsInputGroup';

const TeachingProfileView = () => {
  const user = useSelector(selectUser);
  const { t } = useTranslation();

  if (!user) {
    return <></>;
  }

  return (
    <div>
      <PersonalDetailsInputGroup fullWidthItems={true}>
        <LabeledText
          label={t('Subjects')}
          text={user.subjectsFlattened.map(s => s.text).join(', ')}
          id='subjects'
        />
      </PersonalDetailsInputGroup>
      <PersonalDetailsInputGroup>
        {user.classes && <LabeledText
          label={t('Class years')}
          text={compressClassYearArray(user.classes).join(', ')}
          id='classyears'
        />}
        {user.schoolType && <LabeledText
          label={t('SchoolType')}
          text={user.schoolType}
          id='schooltype'
        />}
        <LabeledText
          label={t('Traineeship')}
          text={user.isTrainee === undefined ? t('Not specified') : user.isTrainee ? t('Yes') : t('No')}
          id='traineeship'
        />
      </PersonalDetailsInputGroup>
    </div>
  );
};

export default TeachingProfileView;
