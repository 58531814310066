import { alpha } from '@mui/material';
import { colors } from './variables';

export const spacing = 4;

const getSpace = (multiplier: number) => multiplier * spacing + 'px';

export const getFontProps = (fontFamily: string, fontWeight: number, fontSize: string) => ({
  fontFamily,
  fontWeight,
  fontSize
});

export const spaces = {
  xxxs: getSpace(0.5), // 0.5 * 4 = 2px
  xxs: getSpace(1),  // 1 * 4 = 4px
  xs: getSpace(2),  // 2 * 4 = 8px
  sm: getSpace(3), // 3 * 4 = 12px
  md: getSpace(4), // 4 * 4 = 16px
  lg: getSpace(6), // 6 * 4 = 24px
  xl: getSpace(8), // 8 * 4 = 32px
  xxl: getSpace(10), // 10 * 4 = 40px
  xxxl: getSpace(12), // 12 * 4 = 48px
  xxxxl: getSpace(16), // 16 * 4 = 64px
  xxxxxl: getSpace(20), // 20 * 4 = 80px
};

export const borderRadius = {
  xs: getSpace(2),
  sm: getSpace(3),
  md: getSpace(4),
  lg: getSpace(6),
  full: getSpace(90),
};

export const palette= {
  common: {
    black: colors.neutral900,
    white: colors.neutral50,
  },
  primary: {
    main: colors.blue500,
    dark: colors.blue600,
    light: colors.blue400,
    contrastText: colors.blue50,
    hover: alpha(colors.blue500, 0.04),
    outlinedBorder: alpha(colors.blue500, 0.48),
  },
  secondary: {
    main: colors.violet800,
    dark: colors.violet900,
    light: colors.violet700,
    hover: alpha(colors.violet800, 0.04),
    contrastText: colors.violet50
  },
  error: {
    main: colors.red600,
    dark: colors.red700,
    light: colors.red500,
    contrastText: colors.red50
  },
  warning: {
    main: colors.orange600,
    dark: colors.orange700,
    light: colors.orange500,
    contrastText: colors.orange50
  },
  info: {
    main: colors.lightblue500,
    dark: colors.lightblue600,
    light: colors.lightblue400,
    contrastText: colors.lightblue50
  },
  success: {
    main: colors.green600,
    dark: colors.green700,
    light: colors.green500,
    contrastText: colors.green50
  },
  text: {
    primary: colors.violet800,
    secondary: colors.violet700,
    disabled: alpha(colors.violet800, 0.48),
    focusVisible: alpha(colors.violet800, 0.32),
    selected: alpha(colors.violet800, 0.08),
    visited: colors.violet500,
    outlineBorder: colors.blue550,
  },
  background: {
    default: colors.neutral100,
    paperElevation1: colors.neutral50,
    paperElevation2: colors.neutral0,
  },
  action: {
    active: alpha(colors.neutral900, 0.56),
    hover: alpha(colors.neutral800, 0.04),
    selected: alpha(colors.neutral900, 0.08),
    disabled: alpha(colors.neutral900, 0.40),
    disabledBackground: alpha(colors.neutral900, 0.12),
    focus: alpha(colors.neutral900, 0.12),
  },
  components: {
    tooltip: {
      fill: colors.neutral500,
    }
  },
  divider: alpha(colors.neutral900, 0.12),
  elevation: {
    outlined: colors.neutral200,
  }
};

// Todo: use this token as a typography when designers implement the alert/description typography
export const alertDescriptionTypography = {
  ...getFontProps('Poppins, sans-serif', 500, '0.875rem'),
  lineHeight: '1.25rem',
  letterSpacing: '0.25px',
  fontStyle: 'normal',
};

export const typography = {
  fontFamily: 'Poppins, sans-serif', // Default font for all text
  h1: getFontProps('Borna, sans-serif', 500, '4.5rem'),
  h2: getFontProps('Borna, sans-serif', 400, '2.5rem'),
  h3: getFontProps('Borna, sans-serif', 400, '2rem'),
  h4: getFontProps('Borna, sans-serif', 700, '1.75rem'),
  h5: {
    ...getFontProps('Borna, sans-serif', 600, '1.5rem'),
    lineHeight: '2rem',
  },
  h6: {
    ...getFontProps('Borna, sans-serif', 600, '1.125rem'),
    lineHeight: '1.5rem',
    letterSpacing: '0.15px',
  },
  subtitle1: getFontProps('Borna, sans-serif', 400, '1rem'),
  subtitle2: {
    ...getFontProps('Borna, sans-serif', 400, '0.875rem'),
    lineHeight: '1.5rem',
    letterSpacing: '0.1px',
  },
  body1: {
    ...getFontProps('Poppins, sans-serif', 300, '1rem'),
    color: palette?.text?.secondary,
    lineHeight: '1.5rem',
    letterSpacing: '0.5px',
  },
  body2: {
    ...getFontProps('Poppins, sans-serif', 300, '0.875rem'),
    color: palette?.text?.secondary,
    lineHeight: '1.25rem',
    letterSpacing: '0.25px',
  },
  overline: {
    ...getFontProps('Poppins, sans-serif', 400, '0.75rem'),
    letterSpacing: '1.5px',
    lineHeight: '1rem',
  },
  caption: {
    ...getFontProps('Poppins, sans-serif', 400, '0.75rem'),
    lineHeight: '1rem',
    letterSpacing: '0.4px',
  },
};

export const topBarHeight = 16 * spacing + 'px';
export const sideBarWidth = 16 * spacing + 'px';

export const cardDimensions = {
  desktop: {
    minWidth: 896,
    maxWidth: 912,
  },
  tablet: {
    minWidth: 696,
    maxWidth: 912,
  },
  mobile: {
    minWidth: 288,
    maxWidth: 695,
  },
  short: {
    height: 132,
  },
  long: {
    height: 280,
  },
};

export const tooltipArrow = {
  width: 15,
  height: 11,
};

export const customSizes = {
  exportDialog: {
    pagesSelectionStageWidth: '960px',
    lernsetSelectionStageWidth: '700px',
    lernsetsListHeight: '274px',
  },
  filtersDialog: {
    desktopWidth: '900px',
    desktopMargin: spaces.xxl,
    tabletMargin: spaces.lg,
    mobileTopMargin: '44px',
  }
};

interface BoxShadowsType {
  [key: string]: string;
}

export const boxShadows: BoxShadowsType = {
  elevation1: '0px 1px 2px 0px rgba(17, 24, 39, 0.08)',
  elevation2: '0px 3px 2px 0px rgba(17, 24, 39, 0.08)',
  elevation3: '0px 2px 4px -2px rgba(17, 24, 39, 0.05), 0px 4px 6px -1px rgba(17, 24, 39, 0.10)',
  elevation4: '0px 7px 10px -2px rgba(17, 24, 39, 0.10), 0px 3px 5px -1px rgba(17, 24, 39, 0.05)',
  elevation6: '0px 4px 6px 0px rgba(17, 24, 39, 0.05), 0px 10px 15px -3px rgba(17, 24, 39, 0.10)',
  elevation8: '0px 7px 8px 0px rgba(17, 24, 39, 0.05), 0px 15px 20px -4px rgba(17, 24, 39, 0.10)',
  elevation10:
  '0px 6px 6px -3px rgba(0, 0, 0, 0.20), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)',
  elevation12: '0px 10px 10px 0px rgba(17, 24, 39, 0.04), 0px 20px 25px -5px rgba(17, 24, 39, 0.10)',
  elevation16: '0px 13px 18px 0px rgba(17, 24, 39, 0.08), 0px 23px 36px -8px rgba(17, 24, 39, 0.10)',
  elevation24: '0px 16px 25px 0px rgba(17, 24, 39, 0.10), 0px 25px 50px -12px rgba(17, 24, 39, 0.25)',
  elevation26: '0px 10px 15px -3px rgba(17, 24, 39, 0.10), 0px 4px 6px 0px rgba(17, 24, 39, 0.05)',
};

export const customColors = {
  lernsetListItem: {
    border: alpha(colors.violet800, 0.48),
    selectedBorder: alpha(colors.blue500, 0.48),
    selectedBackground: alpha(colors.blue500, 0.08),
  },
};
