import React from 'react';
import { useTranslation } from 'react-i18next';
import { PersonalDataForm, PersonalDataView } from '.';
import PersonalDetailsSection from '../Common/PersonalDetailsSection';

const PersonalData: React.FC = () => {
  const { t } = useTranslation();
  return (
    <PersonalDetailsSection 
      title={t('Personal Information')}
      renderNormalView={() =>
        <PersonalDataView />}
      renderFormView={(closeEditMode) =>
        <PersonalDataForm onCancel={closeEditMode} onSave={closeEditMode} />}
    />
  );
};

export default PersonalData;
