import React from 'react';
import { useTranslation } from 'react-i18next';
import constants from '../../../constants';
import { BasicFilterProps } from '../../../types/search';
import { Select } from '../../common/Select';
import sortAlphabetically from '../../../utils/sortAlphabetically';
import filterByTaxonomy from '../../utils/filterByTaxonomy';

const FilterBySubject: React.FC<BasicFilterProps<string[]>> = (
  { updateFilter, clearFilter, currentValue, onToggle, onItemClick }
) => {
  const { t } = useTranslation();

  type Item = {
    name: string;
    value: string;
  };

  const sortedSubjects = sortAlphabetically(constants.subjects, { field: 'text' });

  const items: Item[] = filterByTaxonomy(
    sortedSubjects,
    (subject) => {
      return {
        value: subject.id,
        name: subject.text,
      };
    }
  );

  return (
    <Select
      onSelectionConfirm={(values) => {
        updateFilter(values);
      }}
      onSelectionClear={() => {
        clearFilter();
      }}
      label={t('Subjects')}
      multi={true}
      alwaysShowLabel={true}
      options={items}
      onToggle={onToggle}
      onItemClick={onItemClick}
      testId='dropdown-subject'
      value={currentValue}
      saveButtonLabel='Anwenden'
      resetButtonLabel='Löschen'
    />
  );
};

export default FilterBySubject;
