exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".top-bar-menu__dropdown-container .dropdown-toggle::after,.dropdown-custom .dropdown-toggle::after{content:none !important}.dropdown-custom .dropdown-menu{max-width:95vw}@media only screen and (min-width: 768px){.dropdown-custom .dropdown-menu{right:0;left:auto}}.dropdown-custom .navbar-nav .nav-link,.dropdown-custom .navbar-nav .nav-link:hover{padding:0}.top-bar-menu__menu-container{min-width:230px;border-radius:0 0 4px 4px;top:36.5px;right:-8px;box-shadow:0 2px 4px 0 #e9ebec}.top-bar-menu__menu-container .dropdown-item:hover{color:#44555d;background-color:#e9ebec}", ""]);
// Exports
exports.locals = {
	"collectionGridGap": "15px",
	"collectionGridItemHeight": "211px",
	"collectionGridItemWidth": "225px",
	"collectionListHeight": "84px",
	"greyLightest": "#f7f9fa",
	"greyLight2": "#d8d8d8",
	"neuVeryLightBlue": "#e9ebec",
	"muBlue": "#4298b5",
	"gridBreakPoints_-xs": "0",
	"gridBreakPoints_-sm": "576px",
	"gridBreakPoints_-md": "768px",
	"gridBreakPoints_-lg": "992px",
	"gridBreakPoints_-xl": "1440px"
};