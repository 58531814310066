import { Link } from 'found';
import React from 'react';
import classnames from 'classnames';
import Icon from '../../../../components/Icon';
import { IconName } from '../../../../components/Icon/IconTypes';
import './personalDetailsPageNavItem.scss';

interface PersonalDetailsPageNavItemProps {
  url: string;
  iconName: IconName;
  text: string;
  isActive: boolean;
  onClick?: () => void;
}

const PersonalDetailsPageNavItem: React.FC<PersonalDetailsPageNavItemProps> = (props) => {

  return (
    <Link to={props.url} onClick={props.onClick}>
      <div className={
        classnames(
          'personal-details-page-nav-item__container',
          { 'personal-details-page-nav-item__container--active': props.isActive }
        )}>
        <Icon size='lg' icon={props.iconName}></Icon>
        <span className={
          classnames(
            'personal-details-page-nav-item__name',
            { 'personal-details-page-nav-item__name--active': props.isActive }
          )}>
          {props.text}
        </span>
      </div>
    </Link>
  );
};

export default PersonalDetailsPageNavItem;
