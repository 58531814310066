import React from 'react';
import { Range, getTrackBackground } from 'react-range';
import variables from '../../../../../assets/styles/deprecated/variables';
import './rangeSlider.scss';

export type RangeSliderValue = { min: number; max: number };

interface RangeSliderProps {
  min: number;
  max: number;
  values: RangeSliderValue;
  onChange: (values: RangeSliderValue) => void;
}

const RangeSlider: React.FC<RangeSliderProps> = (props) => {
  const minVal = props.values.min || 1;
  const maxVal = props.values.max || 13;

  return (
    <Range
      step={1}
      min={props.min}
      max={props.max}
      allowOverlap={true}
      values={[minVal, maxVal]}
      onChange={([min, max]) => props.onChange({ min, max })}
      renderThumb={({ props }) => (
        <div
          {...props}
          className='range-slider-thumb rounded-circle bg-primary focus--highlight-blue'
        />
      )}
      renderTrack={({ props: trackProps, children }) => {
        return (
          <div
            className='range-slider-track mx-auto rounded'
            {...trackProps}
            // eslint-disable-next-line react/forbid-dom-props
            style={{
              background: getTrackBackground({
                values: [minVal, maxVal],
                colors: [variables.greyLight2, variables.muBlue, variables.greyLight2],
                min: props.min,
                max: props.max,
              })
            }}
          >
            { children}
          </div>
        );
      }
      }
    />
  );
};

export default RangeSlider;
