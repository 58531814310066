import React from 'react';
import { MediaQueryProvider } from 'react-media-query-hoc';
import variables from '../assets/styles/deprecated/variables';
import breakpoints from '../assets/styles/style-guide/media.scss';

interface Breakpoints {
  medium: string;
  large: string;
  xlarge: string;
}

const deprecatedMediaQueries = (() => {
  const tmpMediaQueries: { [key: string]: string } = {};
  const entries = Object.entries(variables.gridBreakPoints);

  for (let i = 1; i <= entries.length; i++) {
    const [prevKey, prevValue] = entries[i - 1];
    const [, currentValue] = (entries[i] || [null, null]);
    const queryParts = ['screen', `(min-width: ${prevValue}px)`];

    if (currentValue) {
      queryParts.push(`(max-width: ${currentValue - 1}px)`);
    }

    tmpMediaQueries[prevKey] = queryParts.join(' and ');
  }

  return tmpMediaQueries;
})();

export const getMediaQueries = ((breakpoints: Breakpoints) => {
  return {
    viewportSmall:
      `screen and (max-width: ${parseInt(breakpoints.medium) - 1}px)`,
    viewportMedium:
      `screen and (min-width: ${breakpoints.medium}) and (max-width: ${parseInt(breakpoints.large) - 1}px)`,
    viewportLarge:
      `screen and (min-width: ${breakpoints.large}) and (max-width: ${parseInt(breakpoints.xlarge) - 1}px)`,
    viewportXLarge:
      `screen and (min-width: ${breakpoints.xlarge})`,
  };
});

const MediaQueries: React.FC = (props) => (
  <MediaQueryProvider queries={{ ...deprecatedMediaQueries, ...getMediaQueries(breakpoints) }}>
    {props.children}
  </MediaQueryProvider>
);

export default MediaQueries;
