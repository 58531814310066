import services from '../services';

let timeout: number;

const canUserCreateSubscription = async (email : string): Promise<boolean> => {
  if (!email) return false;

  if (timeout) {
    window.clearTimeout(timeout);
  }

  return new Promise(resolve => {
    timeout = window.setTimeout(async () => {
      const { canCreateSubscription } = await services.user.canCreateSubscription(email);
      
      resolve(canCreateSubscription);
    }, 200);
  });
};

export default canUserCreateSubscription;
