import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { AsyncActionStatus } from '../../../actions/common.actionTypes';
import { selectProductStatus } from '../../../reducers/products.selectors';
import { uniqueArray } from '../../../utils/uniqueArrayOfObjects';
import Icon, { IconName } from '../../Icon';
import WithTooltip from '../../WithTooltip';
import { CurrentProductContext } from '../CurrentProductContext';
import './productAttributeCategory.scss';

interface ProductAttributeCategoryProps {
  iconName: IconName;
  text: string;
  category: string;
  className?: string;
  items?: string[];
}

const ProductAttributeCategory: React.FC<ProductAttributeCategoryProps> = (props) => {
  const { t } = useTranslation();
  const { productId } = useContext(CurrentProductContext);
  const isLoading =
    useSelector(state => selectProductStatus(state, productId)) === AsyncActionStatus.LOADING;

  return (
    <div className={`product-attribute-category ${props.className ? props.className : ''}`}>
      <WithTooltip text={t(props.text)}>
        <div className='product-attribute-category__attribute-key'>
          <Icon icon={props.iconName} className='mr-2' size='lg' />
        </div>
      </WithTooltip>
      <div className='product-attribute-category__attribute-values'>
        {
          isLoading
            ? <Skeleton width='200px' />
            : <div className='product-attribute-category__comma-list'>
              {
                uniqueArray(props.items || []).map(item => (
                  <span className='product-attribute-category__comma-list__comma-list-item' key={item}>{item}</span>
                ))
              }
            </div>}
      </div>
    </div>
  );
};

export default ProductAttributeCategory;
