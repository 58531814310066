import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { borderRadius, boxShadows, cardDimensions, palette, spaces } from '../../../../assets/styles/themes/tokens';
import { Chip, Fab, Typography, alpha, Link as MuiLink } from '@mui/material';
import { compressClassYearArray } from '../../../../utils/compressClassYearArray';
import { useTranslation } from 'react-i18next';
import { AddMenu, CoverImage, Metadata, MuiCardBaseProps, mapContentTypeName } from './MuiCardBase';
import Tooltip from '../../../Tooltip';
import getMuiIcon from '../../../../utils/getMuiIcon';
import { Link } from 'found';
import { routes } from '../../../../constants';
import { colors } from '../../../../assets/styles/themes/variables';
import IsNewChip from './IsNewChip';
import MuiCardBaseLongDescription from './MuiCardBaseLongDescription';
import createNonPropagatingEventHandler from '../../../../utils/createNonPropagatingEventHandler';
import useRouter from '../../../../utils/useRouter';
import getTextTruncationSx from '../../../../utils/getTextTruncationSx';

export const MuiCardBaseLong: React.FC<MuiCardBaseProps> = (props) => {
  const {
    id,
    contentType,
    publisher,
    index,
    isReadOnly,
    cover,
    title,
    description,
    subjects,
    classYears,
    link,
    linkFunction,
    date,
    chipText,
    isPinned,
    isNew,
    itemData
  } = props;

  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const itemLink: string = contentType === 'product' ?
    `/${routes.productPage.root}/${id}` :
    `/${routes.reader.root}/${id}`;

  return <Box
    tabIndex={0}
    sx={theme => ({
      display: 'flex',
      gap: spaces.md,
      height: isExpanded ? 'unset' : cardDimensions.long.height,
      marginX: 'auto',
      alignItems: 'flex-start',
      [theme.breakpoints.between('mobile', 'tablet')]: {
        maxWidth: cardDimensions.tablet.maxWidth,
      },
      [theme.breakpoints.up('tablet')]: {
        maxWidth: cardDimensions.desktop.maxWidth,
      },
      position: 'relative',
      ':focus': {
        borderRadius: spaces.sm,
        outline: `1px solid ${colors.blue700}`,
      }
    })}
    data-testid={`${contentType}-mui-card`}
    onKeyDown={(event) => {
      if (event.key === 'Enter' && document.activeElement === event.currentTarget) {
        window.location.href = itemLink;
      }
    }}
    onMouseDown={createNonPropagatingEventHandler(() => { })}
  >
    <AddMenu isPinned={isPinned} type={contentType} id={id} itemData={itemData} index={index} />
    <Link to={itemLink}>
      <Box
        sx={{
          borderRadius: borderRadius.sm,
          width: contentType === 'video' ? 376 : 198,
          minWidth: contentType === 'video' ? 376 : 198,
          height: '100%',
          position: 'relative',
          ':hover': {
            boxShadow: boxShadows.elevation26,
          },
        }}
        data-testid='mui-card-desktop__left-section'
      >
        <CoverImage cover={cover} data-testid='mui-card-desktop__cover-image' />
        {
          contentType === 'video' && <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              height: cardDimensions.long.height,
              'z-index': 0,
              ':hover': {
                borderRadius: spaces.lg,
                boxShadow: boxShadows.elevation26,
              },
            }}
          >
            <Fab size='large' color='default' sx={{
              background: alpha(palette.common.white, 0.32),
            }}>
              <Box
                component={getMuiIcon('play')} />
            </Fab>
          </Box>
        }
        <Box
          data-testid='mui-card-desktop__pages-chip'
          sx={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            bottom: spaces.md,
          }}
        >
          {
            contentType !== 'video' && <Chip
              label={chipText}
              color='secondary'
              size='small'
            />
          }
        </Box>
      </Box>
    </Link>
    { isNew && <IsNewChip size='medium' /> }
    <Box
      data-testid='mui-card-desktop__right-section'
      sx={{
        paddingTop: spaces.md,
        display: 'flex',
        flexDirection: 'column',
        gap: spaces.lg,
        flex: '1 0 0',
        alignSelf: 'stretch',
      }}
    >
      <Box
        data-testid='mui-card-desktop__right-content'
        sx={{
          gap: spaces.md,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            gap: spaces.sm,
            display: 'flex',
            alignItems: 'baseline',
            paddingRight: spaces.xxxl,
          }}
          data-testid='mui-card-desktop__right-content-header'
        >
          <Typography variant='overline' color='text.secondary' textTransform='uppercase'>
            {t(mapContentTypeName(contentType))}
          </Typography>
          <Typography variant='caption' color='text.secondary'>
            •
          </Typography>
          <Typography variant='caption' color='text.secondary'>
            {publisher}
          </Typography>
          {isReadOnly && <Chip label={t('Read only')} sx={{ marginY: '-5.5px' }} />}
        </Box>
        <Box sx={{ display: 'flex', flexFlow: 'column', gap: spaces.xs }}>
          <Link to={itemLink}>
            <DesktopTitle title={title}/>
          </Link>
          <MuiCardBaseLongDescription
            isExpanded={isExpanded}
            onShowToggleClick={() => setIsExpanded(!isExpanded)}
            description={description}
          />
        </Box>
      </Box>
      <DesktopMetadataSection 
        link={link}
        linkFunction={linkFunction}
        subjects={subjects}
        classYears={classYears}
        date={date}
      />
    </Box>
  </Box>;
};

const DesktopMetadataSection: React.FC<{
  link?: MuiCardBaseProps['link'];
  linkFunction?: () => void;
  subjects?: MuiCardBaseProps['subjects'];
  classYears?: MuiCardBaseProps['classYears'];
  date?: MuiCardBaseProps['date'];
}> = ({ link, linkFunction, subjects, classYears, date }) => {
  const { t } = useTranslation();
  const { router } = useRouter();

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>, to: string) => {
    e.stopPropagation();
    if (linkFunction) {
      linkFunction();
      return;
    } else {
      router.push(to);
    }
  };

  const LinkSection = ({ link }: { link: NonNullable<MuiCardBaseProps['link']> }) => (
    <Tooltip label={link.label} open={link.hasTooltip ? undefined : false} placement='top-start'>
      <MuiLink underline='none' onClick={e => handleLinkClick(e, link.to)} data-testid='mui-card-link' >
        <Metadata
          icon={link.icon}
          label={link.label}
          sx={{ color: undefined, stroke: undefined }}
        />
      </MuiLink>
    </Tooltip>
  );

  const SubjectsSection = ({ subjects }: { subjects: NonNullable<MuiCardBaseProps['subjects']> }) => (
    <Tooltip label={subjects.join(', ')} placement='top-start'>
      <Metadata
        icon='tag'
        sx={{ color: palette.text.secondary, maxWidth: 218 }}
        label={subjects.join(', ')}
      />
    </Tooltip>
  );

  const ClassYearsSection = ({ classYears }: { classYears: NonNullable<MuiCardBaseProps['classYears']> }) => (
    <Metadata
      icon='teacher'
      sx={{ color: palette.text.secondary }}
      label={compressClassYearArray(classYears).join(', ') + ' ' + t('Class year')}
    />
  );

  const containerSx = {
    display: 'flex',
    flexDirection: 'row',
    gap: spaces.sm,
    flexWrap: 'wrap',
  };

  return (
    <Box className='mui-card-desktop__metadata-section' sx={{ ...containerSx }}>
      { subjects && subjects.length > 0 && <SubjectsSection subjects={subjects} /> }
      { classYears && classYears.length > 0 && <ClassYearsSection classYears={classYears} /> }
      { date && <Metadata icon='calendar' label={date}/> }
      { link && <LinkSection link={link} /> }
    </Box>
  );
};

const DesktopTitle: React.FC<{
  title: MuiCardBaseProps['title'];
}> = ({ title }) => {
  return <Tooltip label={title} placement='top' hideWithoutOverflow={true}>
    <Typography variant='h5'
      data-testid='mui-card-desktop__title'
      sx={{
        ...getTextTruncationSx(2),
        color: palette.text.primary,
        transition: 'color 300ms ease-out',
        ':hover': {
          color: colors.blue500,
        },
      }}
    >
      {title}
    </Typography>
  </Tooltip>;
};

export default MuiCardBaseLong;
