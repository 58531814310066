import {
  Route,
} from 'found';
import React from 'react';
import RedirectIfLoggedOut from './redirect/RedirectIfLoggedOut';

interface AuthenticatedRouteProps {
  Component: React.ComponentType;
  props: any;
}

class AuthenticatedRoute extends Route {
  public render(routeProps?: AuthenticatedRouteProps) {

    if (!routeProps) {
      return null;
    }

    const { props } = routeProps;

    return <RedirectIfLoggedOut {...props} />;
  }
}

export default AuthenticatedRoute;
