import React from 'react';
import './dropdownBody.scss';

interface DropdownBodyProps {
  main: React.ReactNode;
  footer: React.ReactNode;
}

const DropdownBody: React.FC<DropdownBodyProps> = (props) => {
  return (
    <div className='box-shadow pb-3 px-3 pt-3 dropdown-body' data-testid='dropdown-body'>
      <div className='dropdown-body__main w-100 overflow-auto-y'>
        {props.main}
      </div>
      {props.footer &&
        <div className='px-3 pt-4 d-flex'>
          {props.footer}
        </div>
      }
    </div>
  );
};

export default DropdownBody;
