import { Subject } from '../../constants';

const filterByTaxonomy = <T>(
  subjects: Subject[],
  callbackFunction: (subject: Subject) => T,
) => {
  return subjects.reduce((result: Array<T>, subject) => {
    if (subject.taxonomy) {
      result.push(callbackFunction(subject));
    }
    return result;
  }, []);
};

export default filterByTaxonomy;
