import React from 'react';
import useMedia from '../../../utils/useMedia';
import DropdownSelect from './DropdownSelect';
import { DropdownSelectProps } from './DropdownSelect/DropdownSelect';
import ModalSelect from './ModalSelect';

export interface CustomSelectOption<T> { name: string; value: T }
export type CustomSelectOptionValueType = string | number;

export interface CustomSelectDefaultProps<T extends CustomSelectOptionValueType> {
  options: CustomSelectOption<T>[];
  label: string;
  value?: T[];
  showLabel?: boolean;
  multi?: boolean;
  defaultValue?: T[];
  testId?: string;
  alwaysShowLabel?: boolean;
  showClearButton?: boolean;
  onSelectionConfirm?: (value: T[]) => void;
  onSelectionClear?: () => void;
  icon?: React.ReactNode;
  hideIconOnMobile?: boolean;
  disabled?: boolean;
  onToggle?: () => void;
  onItemClick?: (item: string) => void;
  onChange?: (value?: T[]) => void;
  hasButtons?: boolean;
  align?: 'left' | 'right';
  saveButtonLabel?: string;
  resetButtonLabel?: string;
}

type CustomSelectProps<T extends CustomSelectOptionValueType> = DropdownSelectProps<T>;

const CustomSelect =
  <T extends CustomSelectOptionValueType,>({
    showClearButton = true, 
    hasButtons = true,
    ...props }:CustomSelectProps<T>) => {
    let onItemClick = props.onItemClick;

    const handleSelectionConfirm = (values: T[]) => {
      props.onSelectionConfirm?.(values);
      props.onChange?.(values);
    };

    const handleSelectionClear = () => {
      props.onSelectionClear?.();
      props.onChange?.();
    };

    if (!hasButtons) {
      onItemClick = (item: string) => {
        handleSelectionConfirm([item as unknown as T]);
      };
    }

    // remove duplications from options
    const options = props.options.filter((option, index, self) =>
      self.findIndex((o) => o.name === option.name && o.value === option.value) === index
    );

    const { isCoarsePointer } = useMedia();

    const modalComponent = <ModalSelect<T>
      options={options}
      value={props.value}
      label={props.label}
      icon={(!props.hideIconOnMobile) && props.icon}
      showLabel={props.showLabel}
      alwaysShowLabel={props.alwaysShowLabel}
      onSelectionConfirm={handleSelectionConfirm}
      onSelectionClear={handleSelectionClear}
      defaultValue={props.defaultValue}
      multi={props.multi}
      disabled={props.disabled}
      onToggle={props.onToggle}
      onItemClick={onItemClick}
      hasButtons={hasButtons}
    />;

    if (isCoarsePointer) {
      return modalComponent;
    }

    return (
      <div>
        <div className='d-flex'>
          <DropdownSelect<T>
            onSelectionConfirm={handleSelectionConfirm}
            onSelectionClear={handleSelectionClear}
            alwaysShowLabel={props.alwaysShowLabel}
            label={props.label}
            icon={props.icon}
            options={options}
            value={props.value}
            showClearButton={showClearButton}
            defaultValue={props.defaultValue}
            showLabel={props.showLabel}
            isSmall={props.isSmall}
            multi={props.multi}
            disabled={props.disabled}
            onToggle={props.onToggle}
            onItemClick={onItemClick}
            testId={props.testId}
            hasButtons={hasButtons}
            align={props.align}
            saveButtonLabel={props.saveButtonLabel}
            resetButtonLabel={props.resetButtonLabel}
          />
        </div>
      </div>
    );
  };

export default CustomSelect;
