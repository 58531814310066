import React from 'react';
import Slider from 'react-slick';
import variables from '../../assets/styles/deprecated/variables';
import useMedia from '../../utils/useMedia';
import CarouselButton from './CarouselButton';

export interface SlideDefinition {
  xl: number;
  lg: number;
  md: number;
  sm: number;
  xs: number;
  xxs: number;
}

interface CarouselProps {
  className?: string;
  infinite?: boolean;
  slides: React.ReactNode[];
  numberOfSlides: SlideDefinition;
}

const Carousel: React.FC<CarouselProps> = ({ className, infinite = true, slides, numberOfSlides }) => {
  const { isFinePointer, isSmallViewport } = useMedia();
  const isArrowsVisible =  !isSmallViewport && isFinePointer;
  // The react-slick component is re-calculating a reference to an inline styling
  // property on each re-render, causing re-renders of each child component. Wrapping
  // them in a span acts as a buffer for this styling, allowing us to memoize the
  // slide components, and avoid re-rendering of the slides themselves
  const wrappedSlides = slides.map((s, i) => (
    <span key={i}>
      {s}
    </span>
  ));

  const settings = {
    infinite: numberOfSlides.xl <= (slides && slides.length) ? infinite : false,
    speed: 500,
    slidesToShow: numberOfSlides.xl,
    slidesToScroll: numberOfSlides.lg,
    nextArrow: isArrowsVisible ?
      <CarouselButton direction='right' /> : undefined,
    prevArrow: isArrowsVisible ?
      <CarouselButton direction='left' /> : undefined,
    responsive: [
      {
        breakpoint: variables.gridBreakPoints.xl,
        settings: {
          slidesToShow: numberOfSlides.lg,
          slidesToScroll: numberOfSlides.lg
        }
      },
      {
        breakpoint: variables.gridBreakPoints.lg,
        settings: {
          infinite: false,
          slidesToShow: numberOfSlides.md,
          slidesToScroll: numberOfSlides.md
        }
      },
      {
        breakpoint: variables.gridBreakPoints.md,
        settings: {
          infinite: false,
          slidesToShow: numberOfSlides.sm,
          slidesToScroll: Math.floor(numberOfSlides.sm)
        }
      },
      {
        breakpoint: variables.gridBreakPoints.sm,
        settings: {
          infinite: false,
          slidesToShow: numberOfSlides.xs,
          slidesToScroll: Math.floor(numberOfSlides.xs)
        }
      },
      {
        breakpoint: 380,
        settings: {
          infinite: false,
          slidesToShow: numberOfSlides.xxs,
          slidesToScroll: Math.floor(numberOfSlides.xxs)
        }
      }
    ]
  };
  return (
    <div className={`carousel w-100 d-flex ${className}`}>
      <Slider {...settings}>
        {wrappedSlides}
      </Slider>
    </div>
  );
};

export default Carousel;
