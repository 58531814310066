import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterChip from './FilterChip';
import { spaces } from '../../../assets/styles/themes/tokens';

type FilterOption = {
  name: string;
  value: string;
  isSelected?: boolean;
};

interface SearchFiltersAccordionProps {
  title: string;
  options: FilterOption[];
  isExpanded: boolean;
  isDisabled?: boolean;
  toggleAccordion: () => void;
  selectOption: (option: FilterOption['value']) => void;
}

const SearchFiltersAccordion: React.FC<SearchFiltersAccordionProps> = 
  ({ title, options, isExpanded, toggleAccordion, selectOption, isDisabled }) => {

    return (
      <Accordion 
        disabled={isDisabled} 
        onChange={toggleAccordion} 
        expanded={isExpanded} 
        data-testid={`filters-accordion-${title}`}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ display: 'flex', gap: spaces.xs, flexWrap: 'wrap' }}>
          {
            options.map(
              (option) => (
                <FilterChip
                  key={option.value}
                  value={option.value}
                  label={option.name}
                  onClick={() => selectOption(option.value)}
                />
              )
            )
          }
        </AccordionDetails>
      </Accordion>
    );
  };

export { SearchFiltersAccordion, FilterOption };
