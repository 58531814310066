import { Link } from 'found';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './dashboardCarouselHeader.scss';

interface OwnProps {
  title: string;
  uri?: string;
}

const DashBoardCarouselHeader: React.FC<OwnProps> = (props) => {
  const { t } = useTranslation();
  return (
    <div
      data-testid='dashboard-carousel-header__container'
      className='dashboard-carousel-header__container'
    >
      <h2 className='dashboard-carousel-header__title'>
        {props.title}
      </h2>
      {props.uri &&
        <Link
          to={props.uri}
          className='dashboard-carousel-header__link'>
          {t('show more')}
        </Link>
      }
    </div>
  );
};

export default DashBoardCarouselHeader;
