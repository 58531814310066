import React from 'react';
import ProfilePill from '../Profile/ProfilePill';
import ProfileSubject from '../Profile/ProfileSubject';
interface Subject {
  isSelected: boolean;
  id: string;
}

interface OwnProps {
  onSubjectClick: (subjectId: string) => void;
  subjects: Subject[];
}

const PersonalizationSubjectChoices: React.FC<OwnProps> = (props) => {
  return (
    <>
      {props.subjects.map(subject => (
        <ProfilePill
          key={subject.id}
          selected={subject.isSelected}
          selectedClass={'btn-info'}
        >
          <button
            type='button'
            className='w-100 mx-auto my-2'
            onClick={() => props.onSubjectClick(subject.id)}
            aria-pressed={subject.isSelected}
          >
            <ProfileSubject subjectId={subject.id} />
          </button>
        </ProfilePill>
      ))}
    </>
  );
};

export default PersonalizationSubjectChoices;
