import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Divider } from '@mui/material';
import { 
  FormatBold, 
  FormatItalic, 
  FormatUnderlined, 
  StrikethroughS, 
  Code, 
  CodeOff,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FormatAlignCenter,
} from '@mui/icons-material';
import { BubbleMenu, Editor } from '@tiptap/react';
import { 
  getTextMenuCommands, 
  getTextFormatStates, 
  getTextFormatOptions, 
  isTextSelected 
} from '../../../../utils/tipTapEditor';
import TextFormatMenu from './TextFormatMenu';
import MoreFormatOptionsMenu from './MoreFormatOptionsMenu';
import TextFormatSelect from './TextFormatSelect';
import { borderRadius, palette, spaces } from '../../../../assets/styles/themes/tokens';

interface ContextMenuProps {
    editor: Editor;
}

export interface MenuOption {
  id: string;
  dataTestId: string;
  icon: JSX.Element;
  isActive: boolean;
  onClick: () => void;
}

const StyledBubbleMenu = styled(BubbleMenu)(() => ({
  padding: spaces.xxs,
  borderRadius: borderRadius.sm,
  backgroundColor: 'white',
  border: `1px solid ${palette.divider}`,
}));

const ContextMenu = ({ editor }: ContextMenuProps) => {
  const commands = getTextMenuCommands(editor);
  const states = getTextFormatStates(editor);
  const textFormatOptions = getTextFormatOptions(editor);

  const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] = React.useState<null | HTMLElement>(null);
  const [contentTypePickerAnchorEl, setContentTypeAnchorEl] = useState<null | HTMLElement>(null);
  
  const handleMoreOptionsClick = (event: React.MouseEvent<HTMLElement>) => {
    if (contentTypePickerAnchorEl) handleContentTypePickerClose();
    setMoreOptionsAnchorEl(event.currentTarget);
  };

  const handleTextFormatSelectClick = (event: React.MouseEvent<HTMLElement>) => {
    if (moreOptionsAnchorEl) handleMoreOptionsClose();
    setContentTypeAnchorEl(event.currentTarget);
  };

  const handleMoreOptionsClose = () => {
    setMoreOptionsAnchorEl(null);
  };

  const handleContentTypePickerClose = () => {
    setContentTypeAnchorEl(null);
  };

  const closeSubMenus = () => {
    if (moreOptionsAnchorEl) handleMoreOptionsClose();
    if (contentTypePickerAnchorEl) handleContentTypePickerClose();
  };

  const textFormatMenuOptions = [
    { 
      id: 'bold', 
      icon: <FormatBold />, 
      onClick: commands.onBold, 
      isActive: states.isBold,
      dataTestId: 'bold'
    },
    { 
      id: 'italic', 
      icon: <FormatItalic />, 
      onClick: commands.onItalic, 
      isActive: states.isItalic,
      dataTestId: 'italic'
    },
    { 
      id: 'underline', 
      icon: <FormatUnderlined />, 
      onClick: commands.onUnderline, 
      isActive: states.isUnderline,
      dataTestId: 'underline'
    },
    { 
      id: 'strike', 
      icon: <StrikethroughS />, 
      onClick: commands.onStrike, 
      isActive: states.isStrike,
      dataTestId: 'strike'
    },
    { 
      id: 'code', 
      icon: <Code />, 
      onClick: commands.onCode, 
      isActive: states.isCode,
      dataTestId: 'code'
    },
    { 
      id: 'codeBlock', 
      icon: <CodeOff />, 
      onClick: commands.onCodeBlock, 
      isActive: states.isCodeBlock,
      dataTestId: 'code-block'
    }
  ];

  const moreFormatOptions = [
    { 
      id: 'left', 
      icon: <FormatAlignLeft />, 
      onClick: commands.onAlignLeft, 
      isActive: states.isAlignLeft,
      dataTestId: 'align-left'
    },
    { 
      id: 'center', 
      icon: <FormatAlignCenter />, 
      onClick: commands.onAlignCenter, 
      isActive: states.isAlignCenter,
      dataTestId: 'align-center'
    },
    { 
      id: 'right', 
      icon: <FormatAlignRight />, 
      onClick: commands.onAlignRight, 
      isActive: states.isAlignRight,
      dataTestId: 'align-right'
    },
    { 
      id: 'justify', 
      icon: <FormatAlignJustify />, 
      onClick: commands.onAlignJustify, 
      isActive: states.isAlignJustify,
      dataTestId: 'align-justify'
    },
  ];

  return (
    <StyledBubbleMenu
      tippyOptions={{ popperOptions: { placement: 'top-start' } }}
      editor={editor}
      pluginKey='textMenu'
      shouldShow={() => isTextSelected({ editor })}
      updateDelay={100}
    > 
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: spaces.xxs,
      }}>
        <TextFormatSelect
          anchorEl={contentTypePickerAnchorEl}
          textFormatOptions={textFormatOptions}
          handleClick={handleTextFormatSelectClick}
          handleClose={handleContentTypePickerClose}
        />
        <Divider orientation='vertical' flexItem />
        <TextFormatMenu
          options={textFormatMenuOptions}
          onMenuItemClick={closeSubMenus}
        />
        <MoreFormatOptionsMenu 
          options={moreFormatOptions}
          anchorEl={moreOptionsAnchorEl}
          handleClick={handleMoreOptionsClick}
          handleClose={handleMoreOptionsClose}
        />
      </Box>
    </StyledBubbleMenu>
  );
};

export default ContextMenu;
