import { ampli, Environment } from '../ampli';
import { 
  amplitude, 
  amplitudeContentTypes, 
  amplitudeSearchSchoolTypes, 
  amplitudeSearchSortedByTypes,
  AdditionalContentProperties,
  DataType,
  AmpliContentPayload,
  AmpliPremiumPlanSelected,
  ampliSubscriptionType,
  AmpliPremiumPlanSelectedPayload,
  AmpliSubscriptionSubmitted,
  AmpliSubscriptionSubmittedPayload,
  amplitudePaymentMethodTypes,
  amplitudeBilingCicleTypes,
  mapNodeEnvToAmpliEnv,
  AmpliUserSubscriptionProperties,
  UI
} from '../constants';
import {
  AmplitudeTrackPayload, ContentType, EventType
} from '@klab-berlin/api-sdk/lib/types/requests/Track';
import { SearchPayload } from '../types/tracking';
import { User } from '../types/User';
import { SubscriptionResponse } from '@klab-berlin/api-sdk/lib/types/responses/Billing';
import { isFeatureEnabled } from './feature';
import { MindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import { Product } from '@klab-berlin/api-sdk/lib/types/responses/Product';
import { useEffect, useState } from 'react';
import { Experiment, ExperimentClient } from '@amplitude/experiment-js-client';
import { CollectionProperties } from '../types/AppContent';

const ExperimentKey: Record<Environment, string> = {
  production: 'client-lRP88RCfsPHYyhULhyZlMBoofdX7wj0S',
  development: 'client-lfl0groDXkD6rICz0pPglMfIhjmSBuAi'
};

const ExperimentUrl = 'https://api.lab.eu.amplitude.com';

interface ExperimentVariants {
  [key: string]: {
    [key: string]: string;
  };
}

interface Experiment {
  client?: ExperimentClient;
  variants: ExperimentVariants;
  key?: string;
  loaded: boolean;
}

const experiment: Experiment = { loaded: false, variants: {} };

/**
 * Initializes the experimentClient if it's not done already
 * @returns experimentClient
 */
const getExperimentClient = () => {
  if (!experiment.key) {
    if (process.env.NODE_ENV && process.env.NODE_ENV in mapNodeEnvToAmpliEnv) {
      experiment.key = ExperimentKey[mapNodeEnvToAmpliEnv[process.env.NODE_ENV]];
    }
  }
  if (experiment.key && !experiment.client) {
    experiment.client = Experiment.initializeWithAmplitudeAnalytics(experiment.key, {
      automaticExposureTracking: true,
      automaticFetchOnAmplitudeIdentityChange: true,
      serverUrl: ExperimentUrl
    });
  }
  experiment.loaded = !!experiment.key && !!experiment.client;
  return experiment.client;
};

/**
 * Fetch variation for experiment
 * 
 * @param user_id
 * @param device_id
 * @param experimentKey
 * @returns variation
 */
export const fetchExperimentVariation = async (user: User, experimentKey: string): Promise<string> => {
  const experimentClient = experiment.loaded ? experiment.client : getExperimentClient();
  experiment.variants[user.id] = experiment.variants[user.id] || {};
  const userVariants = experiment.variants[user.id];
  if (userVariants && userVariants[experimentKey]) {
    return userVariants[experimentKey];
  }
  if (!!ampli.client && experimentClient) {
    const newUser = mapAmpliUserProperties(user);
    ampli.identify(user.id, newUser);
    const experiment = await experimentClient.fetch();
    const variant = experiment.variant(experimentKey).value || 'off';
    userVariants[experimentKey] = variant;
    return variant;
  }
  return 'control';
};

export const useAmplitudeExperiment = (user?:User, experimentKey?:string) => {
  const device_id = getAmpliDeviceId() || '0';
  const [isReady, setIsReady] = useState(false);
  const [variant, setVariant] = useState('control');
  useEffect(() => {
    if (user && user.id && experimentKey) {
      fetchExperimentVariation(user, experimentKey)
        .then(variant => {
          setVariant(variant === 'off' ? 'control' : variant);
          setIsReady(true);
        });
    }
  }, [user?.id, device_id, experimentKey]);
  return { isReady, variant };
};

export const convertArrayToString = (array: string[]|number[]): string => {
  return array.join(', ');
};

export const mapTeacherType = (isTrainee: boolean | undefined): string =>{
  if (isTrainee === true){
    return amplitude.teacherType.ref;
  } else if (isTrainee === false){
    return amplitude.teacherType.regular;
  } else {
    return amplitude.teacherType.keineAngabe;
  }
};

export const mapDesignPreference = (prefersNewDesign: boolean): UI => {
  return prefersNewDesign ? amplitude.designPreference.new : amplitude.designPreference.classic;
};

export const mapAmpliPayloadProperties = (
  listOfProperties: Record<string, any>,
  source: Record<string, any>
): Record<string, any> => { 
  const properties: Record<string, any> = {};

  for (const property in listOfProperties) {
    const value = listOfProperties[property];

    if (value) {
      const keyMapped = source[property];
      if (keyMapped) properties[keyMapped] = value;
    }
  }

  return properties;
};

export const mapAmpliUserProperties = (userProperties: Record<string, any>): Record<string, any> => {
  const properties = { ...userProperties };

  if (properties.type) properties.type = amplitude.mapUserType[properties.type];

  if (properties.signUpMethod) properties.signUpMethod = amplitude.mapSignupMethod[properties.signUpMethod];

  if (properties.subjects){
    const majors = properties.subjects.majors.map((major: any) => major.subject);
    const minors = properties.subjects.minors.map((minor: any) => minor.subject);
    properties.subjects = convertArrayToString([...majors, ...minors]);

    if (!properties.isSubjectSelected) properties.firstSubject = convertArrayToString(majors);
  }

  if (properties.classes) properties.classYear = convertArrayToString(properties.classes);

  if ('isTrainee' in properties && properties.isTrainee !== undefined) {
    properties.teacherType = mapTeacherType(properties.isTrainee);
  }

  return mapAmpliPayloadProperties(properties, amplitude.userAmpliMap);
};

export const mapAmpliSearchProperties = (searchProperties: Record<string, any>): Record<string, any> => {
  const amplitudeSearchProperties: Record<string, any> = {};
  const { searchAmpliMap } = amplitude;
  const { 
    schoolType: schoolTypes, 
    minClassYear,
    maxClassYear,
    subject,
    subTopic,
    publisher,
    learningFormat,
    teachingFormat,
    workload
  } = searchProperties;

  if (schoolTypes) {   
    amplitudeSearchProperties[searchAmpliMap.schoolType] = schoolTypes
      .split(',')
      .map((schoolType: string) => amplitudeSearchSchoolTypes[schoolType]);
  }

  if (minClassYear && maxClassYear) {
    amplitudeSearchProperties[searchAmpliMap.classYears] = 
      getAmpliClassYearsArrayFromMinAndMaxYearValues(+minClassYear, +maxClassYear); 
  }  

  if (subject) amplitudeSearchProperties[searchAmpliMap.subject] = subject;

  const arrayProperties = {  
    subTopic,
    publisher,
    learningFormat,
    teachingFormat,
    workload 
  };

  for (const property in arrayProperties) { 
    const propertyValue: string = searchProperties[property];
    if (propertyValue) {
      const keyMapped: string = amplitude.searchAmpliMap[property];
      amplitudeSearchProperties[keyMapped] = propertyValue.split(',');
    }
  }
  
  return amplitudeSearchProperties;
};

export const getAmpliSessionId = (): number|undefined => {
  return isFeatureEnabled('ampli') ? ampli.client.getSessionId() : undefined;
}; 

export const getAmpliDeviceId = (): string|undefined => {
  return isFeatureEnabled('ampli') ? ampli.client.getDeviceId() : undefined;
};

export const getContentEventPayload = (
  data: {
    content?: any,
    contentType: ContentType
    currentPage?: number,
    event_type: EventType,
  }
): AmplitudeTrackPayload | null => {
  const sessionId: number | undefined = getAmpliSessionId();
  const deviceId: string | undefined = getAmpliDeviceId();
  const {
    event_type,
    currentPage,
    content,
    contentType,
  } = data;

  if (sessionId && content) {
    let payload: AmplitudeTrackPayload = {
      event_properties: {
        content: [
          mapAmpliPayloadProperties({ ...content, contentType }, amplitude.contentAmpliMap)
        ],
        'page number': currentPage,
      },
      event_type: event_type,
      'session_id': sessionId,
    };

    if (deviceId) {
      payload = {
        ...payload,
        'device_id': deviceId
      };
    }

    return payload;
  }

  return null;
};

export const getAmpliSearchEventPayload = (data: any) => {
  const { query } = data;
  const searchFilter: Record<string, any>[] = [ mapAmpliSearchProperties(query) ];
  const payload: SearchPayload = {
    'search term': data.term,
    'search sorted by': query['sortBy'] ? 
      amplitudeSearchSortedByTypes[query['sortBy']] : 
      amplitudeSearchSortedByTypes.relevance,
    'search filter': searchFilter,
  };

  if (searchFilter?.length) {
    const appliedFilters: string[] = getAmpliSearchFilterAppliedProperty(searchFilter[0]);
    if (appliedFilters.length && payload['search filter']) {
      payload['search filter'][0]['search filters applied'] = appliedFilters;
    }
  }

  return payload;
};

export const getLicenceIdFromSubscription = (subscription?: SubscriptionResponse): number | undefined => {
  return subscription?.licence?.licenceModelId ?
    +subscription?.licence?.licenceModelId :
    undefined;
};

const getAmpliClassYearsArrayFromMinAndMaxYearValues = (minClassYear: number, maxClassYear: number): number[] => {   
  const numberArray: number[] = [];
  for (let i = minClassYear; i <= maxClassYear; i++) {
    numberArray.push(i);
  }
  
  return numberArray;
};

const getAmpliSearchFilterAppliedProperty = (data: any): string[] => {
  const avaliableSearchFilters: string[] = [
    'search filter subject', 
    'search filter topics', 
    'search filter school types', 
    'search filter publishers', 
    'search filter class years', 
    'search filter material formats', 
    'search filter education formats', 
    'search filter material scopes'
  ];

  const appliedFilters: string[] = [];
  avaliableSearchFilters.forEach((filter) => data[filter] && appliedFilters.push(filter));

  return appliedFilters;
};

export const getAdditionalAmpliContentProperties = {
  mindItem: (data: Partial<MindItem> | Partial<Product>): AdditionalContentProperties => {
    let content;
    if ('fileType' in data && data.fileType === 'video') {
      content = {
        contentType: amplitudeContentTypes.video,
        publicVideo: 'No'
      };
    } else {
      content = { 
        contentType: data.isReadOnly ? amplitudeContentTypes.readOnlyDocument : amplitudeContentTypes.document,
      };
    }
    return content;
  },
  product: () => ({
    contentType: amplitudeContentTypes.product,
  }),
  blankPage: () => ({
    contentType: amplitudeContentTypes.blankPage,
  }),
};

export const getAmpliContentData = (data: Partial<MindItem> | Partial<Product>, dataType: DataType): any => {
  const additionalContentProperties = getAdditionalAmpliContentProperties[dataType](data);
  return [mapAmpliPayloadProperties({ ...data, ...additionalContentProperties }, amplitude.contentAmpliMap)];
};

export const getAmpliContentEventPayload = 
  (data: Partial<MindItem> | Partial<Product>, dataType: DataType, props: any = {}): AmpliContentPayload => {
    const additionalEventProperties = { ...getAdditionalAmpliContentProperties[dataType](data), ...props };
 
    return {
      content: [
        mapAmpliPayloadProperties({ 
          ...data,
          ...additionalEventProperties 
        }, amplitude.contentAmpliMap)
      ],
    };
  };

export const getAmpliPremiumPlanSelectedPayload = ({ 
  isTrainee, 
  traineeLicenceDetails,
  selectedTermLength,
  licenceDetailsByTermLength
}: AmpliPremiumPlanSelected): AmpliPremiumPlanSelectedPayload => {
  return {
    ...mapAmpliPayloadProperties({ 
      licenseId: isTrainee ? 
        +traineeLicenceDetails.licenceId : 
        +licenceDetailsByTermLength[selectedTermLength]?.licenceId,
      subscriptionType: isTrainee ? ampliSubscriptionType.REF : ampliSubscriptionType.TEACHER,
      subscriptionInterval: `${isTrainee ? traineeLicenceDetails.period : selectedTermLength}mo`,
    },  amplitude.premiumPlanSelectedMap) as AmpliPremiumPlanSelectedPayload
  };
};

export const getAmpliSubscriptionSubmittedPayload = ({ 
  licence,
  billingCycle,
  promoCode,
  paymentMethod
}: AmpliSubscriptionSubmitted): AmpliSubscriptionSubmittedPayload  => {
  return {
    ...mapAmpliPayloadProperties({ 
      licenseId: licence.licenceModelId,
      subscriptionType: licence.isRef ? ampliSubscriptionType.REF : ampliSubscriptionType.TEACHER,
      subscriptionInterval: `${licence.period}mo`,
      billingCycle: amplitudeBilingCicleTypes[billingCycle],
      promoCode,
      paymentMethod: amplitudePaymentMethodTypes[paymentMethod],
    }, amplitude.premiumSubscriptionSubmittedMap) as AmpliSubscriptionSubmittedPayload
  };
};

// Modifying date format to YY/MM/DD
const getCorrectDateFormatForSubscriptionProperties = (date?: string): string | undefined => {
  if (!date?.length) return undefined;

  const newDate: Date = new Date(date);
  return newDate.toLocaleDateString('en-GB', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit'
  }).split('/').reverse().join('/');
};

export const getAmpliSubscriptionUserProperties = (
  subscription?: SubscriptionResponse
): AmpliUserSubscriptionProperties => {
  if (!subscription) return {};
  
  let subscriptionRenewed: string | undefined;
  let subscriptionStatus: string | undefined;
  // The trial subscription cannot be renewed.
  if (subscription?.trial?.isInTrial || subscription?.licence?.isSchoolTrial) {
    subscriptionRenewed = undefined;
    subscriptionStatus = 'trial';
  } else {
    subscriptionStatus = subscription?.status?.toLocaleLowerCase();
    subscriptionRenewed = subscription.startDate === subscription.originalCreatedDate ?
      undefined :
      subscription.startDate;
  }

  const getSubscriptionTypeForSubscriptionProperties: Record<string, 'ref' | 'teacher' | 'school' | 'library'>  = {
    trainee: 'ref',
    teacher: 'teacher',
    school: 'school',
    free: 'teacher',
    library: 'library',
    student: 'library' 
  };

  const subscriptionType: string = subscription.licence?.isSchoolTrial ? 
    getSubscriptionTypeForSubscriptionProperties.school : 
    getSubscriptionTypeForSubscriptionProperties[subscription.licence.category.toLowerCase()];

  return {
    'subscription type': subscriptionType,
    'subscription status': subscriptionStatus,
    'subscription interval': `${subscription.licence.term}mo`,
    'subscription end': getCorrectDateFormatForSubscriptionProperties(subscription.endDate),
    'subscription start': getCorrectDateFormatForSubscriptionProperties(subscription.originalCreatedDate),
    'promo code': subscription.promoCode || undefined,
    'subscription mmr': subscription.price,
    'license id': getLicenceIdFromSubscription(subscription),
    'school id': subscription?.schoolId || undefined,
    'subscription renewed': getCorrectDateFormatForSubscriptionProperties(subscriptionRenewed),
  };
};

export const getAmpliCommonCollectionProperties = (collection: CollectionProperties) => {
  return {
    'collection id': collection.id,
    'collection name': collection.title,
    'collection description': collection.description,
    'collection school type': collection.schoolType,
    'collection subjects': collection.subjects && collection.subjects.join(', ')
  };
};
