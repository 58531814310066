import immutable from 'object-path-immutable';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import {
  FETCH_ME,
  FetchMeAction,
  UPDATE_ME,
  UpdateMeAction,
  GET_SUBSCRIPTION,
  GetSubscriptionAction,
  UPGRADE,
  UpgradeAction,
  INCREMENT_DOWNLOADS_COUNTER,
  IncrementDownloadsCounterAction,
  UPDATE_NEWSLETTER,
  UpdateNewsletterAction,
  GetPaymentMethodAction,
  GET_PAYMENT_METHOD,
  UpdatePaymentMethodAction,
  UPDATE_PAYMENT_METHOD,
  GET_INVOICES,
  GetInvoicesAction,
  GET_EXPERIMENT,
  GetExperimentAction,
  TRACK_EXPERIMENT,
  UpdateExperimentAction,
} from '../actions/user.actionTypes';
import { ExperimentData, UserResponseData } from '@klab-berlin/api-sdk/lib/types/responses/User';
import {
  PaymentMethodResponse,
  SubscriptionResponse,
  InvoicesResponse
} from '@klab-berlin/api-sdk/lib/types/responses/Billing';
import {
  LOGIN,
  LoginAction,
  LOGOUT,
  LogoutAction,
  REFRESH_TOKEN,
  RefreshTokenExpiredAction,
} from '../actions/auth.actionTypes';
import cookies from '../clients/cookies';

type UserActionTypes =
  FetchMeAction |
  UpdateMeAction |
  UpdateNewsletterAction |
  GetSubscriptionAction |
  GetPaymentMethodAction |
  UpdatePaymentMethodAction |
  GetInvoicesAction |
  LogoutAction |
  LoginAction |
  RefreshTokenExpiredAction |
  UpgradeAction |
  IncrementDownloadsCounterAction |
  GetExperimentAction |
  UpdateExperimentAction;

export interface UserInitialStateType {
  error: null | string;
  me?: UserResponseData;
  subscription?: SubscriptionResponse;
  invoiceHistory?: InvoicesResponse;
  subscriptionStatus? : AsyncActionStatus;
  status: AsyncActionStatus;
  paymentMethod?: PaymentMethodResponse;
  experiments: {
    [key: string]: ExperimentData
  }
  isAuthenticated: boolean;
}

const initialState: UserInitialStateType = {
  error: null,
  status: AsyncActionStatus.LOADING,
  subscriptionStatus: AsyncActionStatus.LOADING,
  experiments: {},
  isAuthenticated: !!cookies.getAuthToken() || !!cookies.getRefreshToken(),
};

const userIsDefined = (state: UserInitialStateType):
  state is UserInitialStateType & { me: UserResponseData } =>
  !!state.me;

const pinned = (state = initialState, action: UserActionTypes): UserInitialStateType => {
  switch (action.type) {
    case UPDATE_ME.START:
    case FETCH_ME.START:
      return immutable(state)
        .set('status', AsyncActionStatus.LOADING)
        .value();
    case UPDATE_ME.ERROR:
    case FETCH_ME.ERROR:
    case GET_PAYMENT_METHOD.ERROR:
    case UPDATE_PAYMENT_METHOD.ERROR:
      return immutable(state)
        .set('status', AsyncActionStatus.ERROR)
        .set('error', action.error.message)
        .value();
    case FETCH_ME.EXPIRED:
      return immutable(state)
        .set('me', undefined)
        .set('experiments', {})
        .value();
    case UPDATE_ME.SUCCESS:
    case FETCH_ME.SUCCESS:
      return immutable(state)
        .set('status', AsyncActionStatus.READY)
        .set('me', {
          ...action.result,
        })
        .value();
    case GET_SUBSCRIPTION.SUCCESS: {
      const { status =  AsyncActionStatus.READY } = action.payload;

      return immutable(state)
        .set('subscriptionStatus', status)
        .set('subscription', action.result)
        .value();
    }
    case GET_PAYMENT_METHOD.SUCCESS:
    case UPDATE_PAYMENT_METHOD.SUCCESS:
      return immutable(state)
        .set('paymentMethod', action.result)
        .value();
    case GET_INVOICES.SUCCESS:
      return immutable(state)
        .set('invoiceHistory', action.result)
        .value();
    case LOGIN.START:
    case LOGOUT.START:
    case REFRESH_TOKEN.EXPIRED:
      return immutable(initialState)
        .set('isAuthenticated', false)
        .value();
    case LOGIN.SUCCESS:
      return immutable(state)
        .set('isAuthenticated', true)
        .value();
    case UPGRADE.START:
    case GET_PAYMENT_METHOD.START:
    case UPDATE_PAYMENT_METHOD.START:
      return immutable(state)
        .set('status', AsyncActionStatus.LOADING)
        .value();
    case UPGRADE.SUCCESS:
      return immutable(state)
        .set('status', AsyncActionStatus.READY)
        .set('me', {
          ...action.result,
        })
        .value();
    case INCREMENT_DOWNLOADS_COUNTER: {
      if (!userIsDefined(state)) {
        return state;
      }
      return immutable(state)
        .update(['me', 'downloads', 'counter'], (value) => ++value)
        .value();
    }
    case UPDATE_NEWSLETTER.START: {
      if (!userIsDefined(state)) {
        return state;
      }
      const newsletterData = {
        status: action.payload.optIn,
        doubleOptIn: action.payload.optIn ? 'confirmed' : 'rejected' as 'confirmed' | 'rejected',
        updated: Date.now().toString(),
      };
      return immutable(state)
        .set(
          ['me', 'marketing', 'mailings', 'newsletter'],
          newsletterData,
        )
        .value();
    }
    case UPDATE_NEWSLETTER.ERROR: {
      if (!userIsDefined(state)) {
        return state;
      }
      const newsletterData = {
        status: !action.payload.optIn,
        doubleOptIn: !action.payload.optIn ? 'confirmed' : 'rejected' as 'confirmed' | 'rejected',
        updated: Date.now().toString(),
      };
      return immutable(state)
        .set(
          ['me', 'marketing', 'mailings', 'newsletter'],
          newsletterData,
        )
        .value();
    }
    case TRACK_EXPERIMENT.SUCCESS:
    case GET_EXPERIMENT.SUCCESS: {
      if (!userIsDefined(state) || !action.result.name) {
        return state;
      }
      return immutable(state)
        .set(['experiments', action.result.name], action.result)
        .value();
    }
  }

  return state;
};

export default pinned;
