import { PaymentPeriod } from '@klab-berlin/api-sdk/lib/types/common';
import { CollectionColor } from '@klab-berlin/api-sdk/lib/types/responses/Collection';
import config from 'config';
import { arrayToObject } from './utils/arrayToObject';
import { ZuoraPaymentMethod } from '@klab-berlin/api-sdk/lib/services/billing';
import { SortByOption } from '@klab-berlin/api-sdk/lib/types/requests/Common';
import * as isoCountries from 'i18n-iso-countries';
import deCountries from 'i18n-iso-countries/langs/de.json';
import { Environment as AmplitudeProjectEnvironment } from './ampli';
import { ContentInterface } from '@klab-berlin/api-sdk/lib/types/requests/Track';
import { SearchFilters } from './types/search';

export interface RouteDescriptor {
  documentTitle?: string;
  optionalParam?: string;
  param?: string;
  root: string;
}

const neuHost = config.urls.neu;

// date-fns uses Unicode tokens for formatting (https://date-fns.org/v2.25.0/docs/format)
export const dateFormat = 'dd.MM.yyyy';

export const subscriptionDateFormat = 'd. MMMM yyyy';

export const readPageTime = 30000; // 30 seconds

export const zipRetryTime = 1000; // 1 second

export const tooltipEnterDelay = 400; // 0.4 seconds
export const tooltipLeaveDelay = 500; // 0.5 seconds

export const defaultSearchFilters: SearchFilters = {
  term: '',
  sortBy: 'relevance',
};

export const externalLinks = {
  dataPolicy: 'https://www.meinunterricht.de/datenschutz/',
  faq: config.links.faq,
  tac: 'https://www.meinunterricht.de/agb/',
  premiumPackageSelection: 'https://www.meinunterricht.de/registrierung/',
  tacPdf: 'https://www.meinunterricht.de/wp-content/uploads/2019/02/media/pdf/2019-02-28_AGB_mU.pdf',
  schoolLicenceOffer: 'https://schulen.meinunterricht.de/premium',
  widerrufsbelehrung: 'https://www.meinunterricht.de/widerrufsbelehrung/',
  licenceLinks: {
    'CC0 1.0': 'https://creativecommons.org/publicdomain/zero/1.0/deed.de',
    'CC BY 1.0': 'https://creativecommons.org/licenses/by/1.0/deed.de',
    'CC BY 2.0': 'https://creativecommons.org/licenses/by/2.0/deed.de',
    'CC BY 3.0': 'https://creativecommons.org/licenses/by/3.0/deed.de',
    'CC BY 4.0': 'https://creativecommons.org/licenses/by/4.0/deed.de',
    'CC BY-SA 1.0': 'https://creativecommons.org/licenses/by-sa/1.0/deed.de',
    'CC BY-SA 2.0': 'https://creativecommons.org/licenses/by-sa/2.0/deed.de',
    'CC BY-SA 3.0': 'https://creativecommons.org/licenses/by-sa/3.0/deed.de',
    'CC BY-SA 4.0': 'https://creativecommons.org/licenses/by-sa/4.0/deed.de',
    'CC BY-ND 1.0': 'https://creativecommons.org/licenses/by-nd/1.0/deed.de',
    'CC BY-ND 2.0': 'https://creativecommons.org/licenses/by-nd/2.0/deed.de',
    'CC BY-ND 3.0': 'https://creativecommons.org/licenses/by-nd/3.0/deed.de',
    'CC BY-ND 4.0': 'https://creativecommons.org/licenses/by-nd/1.0/deed.de'
  }
};

export const emailAddresses = {
  support: 'support@meinunterricht.de',
  schoolSupport: 'schulen@meinunterricht.de'
};

export enum LoginSources {
  facebook = 'facebook',
  email = 'email',
}

export const paymentMethods: { [key in ZuoraPaymentMethod]: { name: string } } = {
  sepa: {
    name: 'SEPA'
  },
  creditCard: {
    name: 'CreditCard'
  },
  braintree: {
    name: 'Paypal'
  },
  invoice: {
    name: 'Rechnung'
  },
};

export const licenceCategory = {
  BASIS: 'basis',
  FREE: 'free',
  LIBRARY: 'library',
  SCHOOL: 'school',
  STUDENT: 'student',
  TEACHER: 'teacher',
  TRAINEE: 'trainee',
  TUITION: 'tuition',
};

export const SCHOOL_TRIAL_LICENCE = '24';

export const paymentRhythmDisplayNames: { [key in PaymentPeriod]: string } = {
  fullAmount: 'Total amount',
  quarterly: 'Quarterly',
  monthly: 'Monthly',
  annually: 'Yearly'
};

export const suspensionOptions = [
  { label: '1 Monat', value: '1' },
  { label: '2 Monate', value: '2' },
  { label: '3 Monate', value: '3' }
];

export const errorCodes = {
  ipRestriction: 1003,
  trialAlreadyUsed: 1106,
  userNotValidated: 1204,
  fbEmailDoesntExist: -1,
  emailAlreadyUsed: 1101
};

export const routes = {
  lernsetsEditor: {
    documentTitle: 'meinUnterricht - Lernset Editor',
    root: 'lernsets/:lernsetId/editor/:partId',
  },
  learnsetsDashboard: {
    documentTitle: 'meinUnterricht - Lernset Dashboard',
    root: 'lernsets',
  },
  lernsetsOverview: {
    documentTitle: 'meinUnterricht - Lernset Overview',
    root: 'lernsets',
    param: 'lernsetId',
  },
  lernsetsPublicView: {
    documentTitle: 'meinUnterricht — Öffentliche Ansicht',
    root: 'set-oeffentlich',
    param: 'accessToken',
  },
  collections: {
    documentTitle: 'meinUnterricht - Kollektionen',
    root: 'kollektion',
  },
  changeEmailConfirm: {
    documentTitle: 'meinUnterricht - Neue Email',
    root: 'neue-email-bestaetigen',
  },
  curatedCollections: {
    documentTitle: 'meinUnterricht - Kuratierte Kollektionen',
    root: 'kuratierte-kollektionen',
  },
  dashboard: {
    documentTitle: 'meinUnterricht - Startseite',
    root: 'start'
  },
  downloads: {
    documentTitle: 'meinUnterricht - Downloads',
    root: 'downloads',
  },
  followed: {
    documentTitle: 'meinUnterricht - Folge ich',
    root: 'folge-ich',
  },
  pinned: {
    documentTitle: 'meinUnterricht - Merkliste',
    root: 'merkliste',
  },
  createProfile: {
    documentTitle: 'Profil erstellen',
    root: 'profil-erstellen',
  },
  createProfilePassword: {
    documentTitle: 'Profil erstellen',
    root: 'passwort'
  },
  createProfileSubjects: {
    documentTitle: 'Profil erstellen',
    root: 'faecher'
  },
  createProfileClasses: {
    documentTitle: 'Profil erstellen',
    root: 'klassen'
  },
  createProfileSchoolType: {
    documentTitle: 'Profil erstellen',
    root: 'schulform'
  },
  createProfileFinish: {
    documentTitle: 'Profil erstellen',
    root: 'fertig'
  },
  login: {
    documentTitle: 'Anmelden',
    root: 'login'
  },
  loginExternal: {
    documentTitle: 'Anmelden',
    root: 'login_external'
  },
  maintenance: {
    documentTitle: 'Wartung',
    root: 'wartung'
  },
  personalDetails: {
    documentTitle: 'Meine Daten',
    root: 'meine-daten',
    optionalParam: 'section'
  },
  productPage: {
    root: 'dokument-uebersicht',
    param: 'productId',
  },
  productAttachmentsPage: {
    documentTitle: 'meinUnterricht - Zusatzmaterialien',
    root: 'zusatzmaterialien',
  },
  resetPassword: {
    documentTitle: 'Erinnere mich an mein Passwort',
    root: 'passwort-vergessen',
  },
  resetPasswordConfirmed: {
    documentTitle: 'E-Mail gesendet',
    root: 'email-mit-link-versendet',
  },
  setPassword: {
    documentTitle: 'Passwort festlegen',
    root: 'neues-passwort-festlegen',
  },
  deprecatedUpgradeConfirm: {
    documentTitle: 'Bestellung',
    root: 'bestellung'
  },
  deprecatedUpgradeOfferCode: {
    documentTitle: 'Angebot',
    root: 'angebote',
    param: 'upgradeCode',
  },
  upgradePackageSelection: {
    documentTitle: 'Paketauswahl',
    root: 'premium',
  },
  upgradePayment: {
    documentTitle: 'Zahlungsdaten',
    root: 'zahlungsdaten',
  },
  deprecatedUpgradeRef: {
    documentTitle: 'Premium für Referendare',
    root: 'ref',
  },
  upgradeToSchoolLicence: {
    documentTitle: 'Schullizenz Info',
    root: 'schulinfo',
  },
  deprecatedPremiumTrialRegistration: {
    documentTitle: 'meinUnterricht | Gratis Registrierung',
    root: 'registrierung-gratis',
  },
  register: {
    documentTitle: 'Registrierung',
    root: 'registrierung'
  },
  singlePagePayment: {
    documentTitle: 'Zahlungsdetails',
    root: 'Zahlungsdetails'
  },
  deprecatedRegisterPremiumPackageSelection: {
    documentTitle: 'Premium',
    root: 'premium',
  },
  registerPremiumCreateAccount: {
    documentTitle: 'Konto erstellen',
    root: 'konto-erstellen'
  },
  deprecatedRegisterPremiumPayment: {
    documentTitle: 'Zahlungsart',
    root: 'zahlungsart',
  },
  deprecatedRegisterPremiumConfirm: {
    documentTitle: 'Bestätigung',
    root: 'bestaetigung'
  },
  ipRangeError: {
    documentTitle: 'Außerhalb der Bibliotheks-IPs',
    root: 'ip-error'
  },
  setNewEmail: {
    documentTitle: 'E-Mail Adresse ändern',
    root: 'email-aendern'
  },
  reader: {
    root: 'dokument',
  },
  publicReader: {
    root: 'dokument-schueleransicht',
  },
  search: {
    documentTitle: 'Suche',
    root: 'suche',
    optionalParam: 'tab'
  },
  notFound: {
    root: '404'
  }
};

export const sharingLinks = {
  curatedCollection: `${neuHost}/${routes.curatedCollections.root}`,
  mindItem: `${neuHost}/${routes.reader.root}`,
  product: `${neuHost}/${routes.productPage.root}`,
  lernsets: `${neuHost}/${routes.lernsetsPublicView.root}`,
};

export const userType = {
  basis: 1,
  premium: 2,
};

export const defaultTermLength = '12';

// TODO: FD-2549 Remove this constant.
export const singlePageRegistrationLicences = [
  283,
  285,
  292,
  293
];

export const classOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

export const isTraineeOptions = [
  {
    value: true,
    text: 'Yes'
  },
  {
    value: false,
    text: 'No'
  },
  {
    value: undefined,
    text: 'Not specified'
  }
];

export type Subject = {
  id: string;
  isTeachable: boolean;
  text: string;
  taxonomy: string;
  popularity: number;
  englishTranslation?: string;
};

export const NONE_OF_THE_ABOVE_OPTION = 'Andere';

const defaultConstants = {
  gtmId: config.gtm.id,
  loginUrl: `${neuHost}/${routes.login.root}`,
  learningFormats: [
    {
      value: 'Experimentieren',
      text: 'Experimentieren'
    },
    {
      value: 'Gespräch führen',
      text: 'Gespräch führen'
    },
    {
      value: 'Gestalten',
      text: 'Gestalten'
    },
    {
      value: 'Gruppenarbeit/Partnerarbeit',
      text: 'Gruppenarbeit / Partnerarbeit'
    },
    {
      value: 'Kontrollieren / Prüfen',
      text: 'Kontrollieren/prüfen'
    },
    {
      value: 'Konzentrieren / Meditieren / Entspannen',
      text: 'Konzentrieren / meditieren / entspannen'
    },
    {
      value: 'Lernspiel spielen',
      text: 'Lernspiel spielen'
    },
    {
      value: 'Recherchieren / Dokumentieren',
      text: 'Recherchieren / dokumentieren'
    },
    {
      value: 'Sich bewegen',
      text: 'Sich bewegen'
    },
    {
      value: 'Simulationsspiel durchführen',
      text: 'Simulationsspiel durchführen'
    },
    {
      value: 'Still arbeiten',
      text: 'Still arbeiten'
    },
    {
      value: 'Szenisch arbeiten',
      text: 'Szenisch arbeiten'
    },
    {
      value: 'Vortragen / Präsentieren',
      text: 'Vortragen / präsentieren'
    }
  ],
  subjects: [
    {
      id: 'Biologie',
      text: 'Biologie',
      taxonomy: 'Biologie_neu',
      isTeachable: true,
      popularity: 6,
      englishTranslation: 'Biology'
    },
    {
      id: 'Chemie',
      text: 'Chemie',
      taxonomy: 'Chemie_neu',
      isTeachable: true,
      popularity: 12,
      englishTranslation: 'Chemistry'
    },
    {
      id: 'Deutsch',
      text: 'Deutsch',
      taxonomy: 'Deutsch_neu',
      isTeachable: true,
      popularity: 1,
      englishTranslation: 'German'
    },
    // Here we're using a double url encoded value for DaZ, as Roboto is expecting this in the search url
    {
      id: 'DaZ',
      text: 'Deutsch als Zweitsprache',
      taxonomy: 'Deutsch%2520als%2520Zweitsprache',
      isTeachable: true,
      popularity: 16,
      englishTranslation: 'German as a second language'
    },
    {
      id: 'Englisch',
      text: 'Englisch',
      taxonomy: 'Englisch_neu',
      isTeachable: true,
      popularity: 7,
      englishTranslation: 'English'
    },
    {
      id: 'Philosophie',
      text: 'Ethik & Philosophie',
      taxonomy: 'Religion-Ethik_neu',
      isTeachable: true,
      popularity: 5,
      englishTranslation: 'Ethics & Philosophy'
    },
    {
      id: 'Fächerübergreifend',
      text: 'Fächerübergreifend',
      taxonomy: 'Fächerübergreifend',
      isTeachable: false,
      popularity: 23,
      englishTranslation: 'Interdisciplinary'
    },
    {
      id: 'Französisch',
      text: 'Französisch',
      taxonomy: 'Französisch_neu',
      isTeachable: true,
      popularity: 19,
      englishTranslation: 'French'
    },
    {
      id: 'Erdkunde',
      text: 'Geografie / Erdkunde',
      taxonomy: 'Erdkunde_neu',
      isTeachable: true,
      popularity: 11,
      englishTranslation: 'Geography'
    },
    {
      id: 'Geschichte',
      text: 'Geschichte',
      taxonomy: 'Geschichte_neu',
      isTeachable: true,
      popularity: 3,
      englishTranslation: 'History'
    },
    {
      id: 'Hauswirtschaft',
      text: 'Hauswirtschaft',
      taxonomy: 'Hauswirtschaft',
      isTeachable: true,
      popularity: 22,
      englishTranslation: 'Home Economics'
    },
    {
      id: 'Informatik',
      text: 'Informatik',
      taxonomy: 'Informatik',
      isTeachable: true,
      popularity: 25,
      englishTranslation: 'Computer Science'
    },
    {
      id: 'Kunst',
      text: 'Kunst',
      taxonomy: 'Kunst',
      isTeachable: true,
      popularity: 10,
      englishTranslation: 'Art'
    },
    {
      id: 'Latein',
      text: 'Latein',
      taxonomy: 'Latein_neu',
      isTeachable: true,
      popularity: 21,
      englishTranslation: 'Latin'
    },
    {
      id: 'Mathematik',
      text: 'Mathematik',
      taxonomy: 'Mathematik_neu',
      isTeachable: true,
      popularity: 2,
      englishTranslation: 'Mathematics'
    },
    {
      id: 'Didaktik-Methodik',
      text: 'Methodik / Didaktik',
      taxonomy: 'Didaktik-Methodik_neu',
      isTeachable: false,
      popularity: 24,
      englishTranslation: 'Didactics'
    },
    {
      id: 'Musik',
      text: 'Musik',
      taxonomy: 'Musik_neu',
      isTeachable: true,
      popularity: 14,
      englishTranslation: 'Music'
    },
    {
      id: 'Naturwissenschaften',
      text: 'Naturwissenschaften',
      taxonomy: 'Naturwissenschaften',
      isTeachable: true,
      popularity: 18,
      englishTranslation: 'Natural Sciences'
    },
    {
      id: 'Physik',
      text: 'Physik',
      taxonomy: 'Physik_neu',
      isTeachable: true,
      popularity: 15,
      englishTranslation: 'Physics'
    },
    {
      id: 'Politik',
      text: 'Politik',
      taxonomy: 'Politik_neu',
      isTeachable: true,
      popularity: 9,
      englishTranslation: 'Politics'
    },
    {
      id: 'Religion-Ethik',
      text: 'Religion',
      taxonomy: 'Religion-Ethik_neu',
      isTeachable: true,
      popularity: 4,
      englishTranslation: 'Religion'
    },
    {
      id: 'Sachkunde',
      text: 'Sachunterricht',
      taxonomy: 'Sachunterricht_neu',
      isTeachable: true,
      popularity: 8,
      englishTranslation: 'General studies'
    },
    {
      id: 'Spanisch',
      text: 'Spanisch',
      taxonomy: 'Spanisch_neu',
      isTeachable: true,
      popularity: 20,
      englishTranslation: 'Spanish'
    },
    {
      id: 'Sport',
      text: 'Sport',
      taxonomy: 'Sport_neu',
      isTeachable: true,
      popularity: 13,
      englishTranslation: 'Physical Education'
    },
    {
      id: 'Wirtschaft',
      text: 'Wirtschaft',
      taxonomy: 'Wirtschaft',
      isTeachable: true,
      popularity: 17,
      englishTranslation: 'Economics'
    },
    {
      id: NONE_OF_THE_ABOVE_OPTION,
      text: 'Keine dieser Optionen',
      isTeachable: true,
      popularity: 26
    },
  ] as Subject[]
};

export default {
  ...defaultConstants,
  subjectsById: arrayToObject(defaultConstants.subjects, s => s.id),
};

export const collectionsSettings = {
  colors: {
    default: 'red' as const,
    options: ['blue', 'green', 'yellow', 'orange', 'red', 'purple'] as CollectionColor[],
  },
};

export const CAROUSEL_DEFAULT_LENGTH = 25;

export const PINNED_CAROUSEL_LOWER_LIMIT = 2;

export const pagination = {
  itemsOnPage: 25,
};

export const loadOnRefreshLimit = 60;

type SearchSettings = {
  itemsPerPage: number;
  defaultSortBy: SortByOption;
  maxOffset: number;
};

export const searchSettings: SearchSettings = {
  itemsPerPage: 12,
  defaultSortBy: 'relevance',
  maxOffset: 9950,
};

export const zoomOptions = {
  zoomStep: 10,
  maxZoom: 200,
  minZoom: 50,
  defaultZoom: 90,
};

export const studyflix = {
  url: 'https://studyflix.de/embed',
  dataPartner: 'cG72Ik5A'
};

isoCountries.registerLocale(deCountries);
export const countries = isoCountries.getNames('de', { select: 'official' });

// Maps Node Environment to Amplitude Project Environment
export const mapNodeEnvToAmpliEnv: Record<string, AmplitudeProjectEnvironment> = {
  production: 'production',
  development: 'development',
  staging: 'development'
};

// Maps Signup Method to Amplitude Event Property
const mapSignupMethod: {
  [key: string]: string;
} = {
  password: 'Email',
  facebook: 'Facebook',
  sso: 'LuxSSO',
};

// Maps User Property to Amplitude Event Property
const userAmpliMap: Record<string, string> = {
  licenceId: 'license id',
  type: 'user type',
  subjects: 'subject',
  firstSubject: 'first subject',
  createdAt: 'signup date',
  teacherType: 'teacher type',
  classYear: 'class year',
  schoolId: 'school id',
  schoolType: 'school type',
  signUpMethod: 'signup method'
};

export interface AmpliUserSubscriptionProperties {
  'subscription type'?: string;
  'subscription status'?: string;
  'subscription interval'?: string;
  'subscription end'?: string;
  'subscription start'?: string;
  'promo code'?: string;
  'subscription mmr'?: number;
  'subscription renewed'?: string;
  'school id'?: string;
  'license id'?: number;
}

// Maps Content Property to Amplitude Event Property
const contentAmpliMap: Record<string, string> = {
  classYears: 'content class years',
  id: 'content id',
  title: 'content name',
  publicVideo: 'content public video',
  publisher: 'content publisher',
  schoolTypes: 'content school types',
  subjects: 'content subjects',
  // topics: 'content topics', // TODO: will be added later
  contentType: 'content type',
  collectionPreexistence: 'collection preexistence',
  ui: 'ui'
};

// Maps Search Property to Amplitude Event Property
const searchAmpliMap: Record<string, string> = {
  subject: 'search filter subject',
  subTopic: 'search filter topics',
  schoolType: 'search filter school types',
  publisher: 'search filter publishers',
  applied: 'search filters applied',
  classYears: 'search filter class years',
  learningFormat: 'search filter material formats',
  teachingFormat: 'search filter education formats',
  workload: 'search filter material scopes'
};

// Maps User Type to Amplitude Event Property
const mapUserType: {
  [key: number]: string;
} = {
  1: 'Free',
  2: 'Premium',
};

// Maps Teacher Type to Amplitude Event Property
const teacherType = {
  ref: 'Ref',
  regular: 'Regular',
  keineAngabe: 'Keine Angabe',
};

export const premiumPlanSelectedMap: Record<string, string> = {
  licenseId: 'license id',
  subscriptionType: 'subscription type',
  subscriptionInterval: 'subscription interval',
};

export const premiumSubscriptionSubmittedMap: Record<string, string> = {
  licenseId: 'license id',
  subscriptionType: 'subscription type',
  subscriptionInterval: 'subscription interval',
  billingCycle: 'billing cycle',
  promoCode: 'promo code',
  paymentMethod: 'payment method'
};

export const designPreference: {[key: string]: UI} = {
  classic: 'classic',
  new: 'new',
};

export const amplitude = {
  mapSignupMethod,
  userAmpliMap,
  contentAmpliMap,
  searchAmpliMap,
  premiumPlanSelectedMap,
  premiumSubscriptionSubmittedMap,
  mapUserType,
  teacherType,
  designPreference,
};

export enum amplitudeEvents {
  PageRead = 'Page Read',
  ContentCompleted = 'Content Completed',
  ContentDownloaded = 'Content Downloaded',
  ContentViewed = 'Content Viewed',
}

export enum amplitudeContentTypes {
  document = 'Document',
  video = 'Video',
  readOnlyDocument = 'Read-only document',
  product = 'Product',
  blankPage = 'Blank page',
}

export const amplitudeSearchSortedByTypes: Record<string, string> = {
  relevance: 'Relevanz',
  createdDate: 'Kürzlich hinzugefügt',
  publisherReleaseDate: 'Erscheinungsdatum',
  popularity: 'Beliebtheit',
};

export const amplitudeSearchSchoolTypes: Record<string, string> = {
  beruflicheschule: 'Berufliche Schule',
  foerderschuleinklusion: 'Förderschule/ Inklusion',
  gesamtschule: 'Gesamtschule',
  grundschule: 'Grundschule',
  gymnasium: 'Gymnasium',
  hauptschule: 'Hauptschule',
  mittlereschule: 'Mittlere Schule',
  realschule: 'Realschule',
};

export type AdditionalContentProperties = {
  contentType: amplitudeContentTypes;
  publicVideo?: string;
};

export type DataType = 'mindItem' | 'product' | 'blankPage';
export interface AmpliContentPayload {
  content: ContentInterface[];
}

export type UI = 'classic' | 'new';
export interface UIProperties {
  ui?: UI;
}

export interface AmpliPremiumPlanSelectedPayload {
  'license id': number,
  'subscription interval': string,
  'subscription type': string
}

export interface AmpliSubscriptionSubmittedPayload {
  'license id': number,
  'subscription interval': string,
  'subscription type': string,
  'billing cycle': string,
  'promo code': string,
  'payment method': string,
}

export const amplitudePaymentMethodTypes: Record<string, string> = {
  'sepa': 'SEPA',
  'creditCard': 'credit card',
  'braintree': 'Paypal'
};

export const amplitudeBilingCicleTypes: Record<string, string> = {
  fullAmount: 'total',
  monthly: 'monthly',
  quarterly: 'quarterly'
};

export interface ModalCollectionItem {
  id: string,
  isSelected: boolean
}

export interface LicenceInfoByTermLengthType {
  [key: string]: {
    price: string;
    licenceId: string;
  }
}

export interface TrainingLicenceDetails {
  discountPercentage: string,
  period: string,
  price: string,
  licenceId: number
}

export interface AmpliSubscriptionSubmitted {
  licence: {
    licenceModelId: number,
    isRef: boolean,
    period: string,
  },
  billingCycle: string,
  promoCode: string,
  paymentMethod: string
}

export const ampliSubscriptionType = {
  TEACHER: 'teacher',
  REF: 'ref',
};

export enum ampliConversionPath {
  NavigationBar = 'navigation bar',
  MyAccount = 'my account',
  DownloadLimitReached = 'download limit reached',
  PremiumContent = 'premium content',
  DocumentOverlay = 'document overlay',
  ContentDownload = 'content download',
  PremiumInfoPopup = 'premium info popup',
}

export interface AmpliPremiumPlanSelected {
  isTrainee: boolean,
  traineeLicenceDetails: TrainingLicenceDetails,
  selectedTermLength: string,
  licenceDetailsByTermLength: LicenceInfoByTermLengthType
}

export const ampliSubscriptionPageSource = {
  LAST_CONFIRMATION: 'last confirmation',
  PAUSE_SUGGESTION: 'pause suggestion',
  DIVERSE_MATERIAL_RECOMMENDATION: 'diverse material recommendation',
};

export const onboardingHints = {
  newUIBanner: 'new-ui-banner',
  newUISmallBanner: 'new-ui-small-banner',
  feedBackBanner: 'feedback-banner',
  welcomeBanner: 'welcome-banner-for-lernsets-beta-testers',
  lernsetsTopBarCTA: 'lernsets-topbar-cta',
  lernsetsReaderCTA: 'lernsets-reader-cta',
  lernsetsOverviewEdit: 'lernsets-overview-edit',
  lernsetsShareButton: 'lernsets-share-button',
};
