import appContext from './appContext.service';
import auth from './auth.service';
import billing from './billing.service';
import collection from './collection.service';
import configuration from './configuration.service';
import email from './email.service';
import lernsets from './lernsets.service';
import notification from './notification.service';
import mindItem from './mindItem.service';
import product from './product.service';
import registration from './registration.service';
import text from './text.service';
import track from './track.service';
import user from './user.service';
import search from './search.service';
import ai from './ai.service';

export default {
  appContext,
  ai,
  auth,
  billing,
  collection,
  configuration,
  email,
  lernsets,
  mindItem,
  notification,
  product,
  registration,
  text,
  track,
  user,
  search,
};
