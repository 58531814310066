import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import MasterLayout from '../../../components/AppLayout/MasterLayout';
import Button from '../../../components/common/Button';
import { emailAddresses, routes } from '../../../constants';
import { CookiebotContext } from '../../../state/CookiebotContextProvider';
import useRouter from '../../../utils/useRouter';
import SuspensionImage from '../../../assets/suspension.svg';
import './suspendedPage.scss';
import { isFeatureEnabled } from '../../../utils/feature';

export enum SuspendedPageType {
  SUSPENDED,
  PAUSED
}

interface OwnProps {
  type: SuspendedPageType;
}

const SuspendedPage: React.FC<OwnProps> = (props) => {
  const { t } = useTranslation();
  const consent = useContext(CookiebotContext);
  const { router } = useRouter();

  const getMessage = (type: SuspendedPageType) => {
    if (type === SuspendedPageType.SUSPENDED) {
      return <Trans i18nKey={'Your subscription is suspended'}>
        <a href={`mailto:${emailAddresses.support}`}></a>
      </Trans>;
    }
    if (type === SuspendedPageType.PAUSED) {
      return <Trans i18nKey={'Your subscription is paused'}>
        <a href={`mailto:${emailAddresses.support}`}></a>
      </Trans>;
    }

    return '';
  };

  const showIntercomButton = isFeatureEnabled('intercom') &&  consent.marketing;

  return (
    <MasterLayout>
      <div className='suspended-page__container'>
        <h1 className='suspension-title'>{t('Opps!')}</h1>
        <SuspensionImage className='suspension-image' />
        <div className='suspension-message'>{getMessage(props.type)}</div>
        <div className='suspension-buttons'>
          {showIntercomButton &&
            <Button className='intercom-custom-launcher'>{t('Open Chat')}</Button>
          }
          <Button className='back-to-search-btn' variant='subtle' onClick={() => router.push(`/${routes.search.root}`)}>
            {t('Back to search')}
          </Button>
        </div>
      </div>
    </MasterLayout>
  );
};

export default SuspendedPage;
