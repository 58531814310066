import React from 'react';
import '../readerGlobals.scss';
import './readerInformation.scss';
import { useTranslation } from 'react-i18next';
import { compressClassYearArray } from '../../../utils/compressClassYearArray';
import { capitalize } from '../../../utils/string';
import WithTooltip from '../../WithTooltip';
import Icon, { IconName } from '../../Icon';
import ActionButton from '../../ActionButton';
import PublisherImage from '../../PublisherImage';
import TruncateWithExpand from '../../TruncateWithExpand/TruncateWithExpand';
import { ReaderContentViewVariant } from '../../../types/reader';
import classnames from 'classnames';
import { getDateInGermanFormat } from '../../../utils/format';
import LicenceCategory from '../../LicenceCategory';
import { ContentLicence } from '@klab-berlin/api-sdk/lib/types/common';
import { useSelector } from 'react-redux';
import { selectMindItem, selectSiblings } from '../../../reducers/mindItems.selectors';
import { isValidVideoItem } from '../../../utils/subdomain';

export interface ReaderInformationProps {
  contentType: string;
  variant: ReaderContentViewVariant;
  workload: string[];
  learningFormat: string[];
  teachingFormat: string[];
  id: string;
  position: number;
  publisher: string;
  title: string;
  subtitle: string;
  subjects: string[];
  classYears: number[];
  licence?: ContentLicence;
  description?: string;
  author: string;
  publisherReleaseDate?: string;
  isReadOnly: boolean;
  onClose: () => void;
}

interface ReaderInformationCategoryProps {
  iconName: IconName;
  text: string;
  children?: React.ReactNode;
}

const ReaderInformationCategory: React.FC<ReaderInformationCategoryProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div className='my-4'>
      <div className='mb-2 color--grey-medium'>
        <Icon icon={props.iconName} className='mr-2' />
        {t(props.text)}
      </div>
      <div>
        {props.children}
      </div>
    </div>
  );
};

const ReaderInformation: React.FC<ReaderInformationProps> = ({
  classYears,
  licence,
  contentType,
  workload,
  learningFormat,
  teachingFormat,
  id,
  position,
  publisher,
  title,
  subtitle,
  description,
  author,
  publisherReleaseDate,
  onClose,
  subjects,
  isReadOnly,
  variant,
}) => {
  const { t } = useTranslation();
  const mindItem = useSelector(state => selectMindItem(state, id));
  let siblings = [];
  if (mindItem) {
    siblings = useSelector(state => selectSiblings(state, { id: mindItem.id, type: 'mindItem' }));
  }
  const compressedClassYears = compressClassYearArray(classYears);

  const mapTrackingEvents = (array: string[]) => (
    <div className='comma-list'>
      {array.filter((value, index) => array.indexOf(value) === index).map(item => (
        <span className='comma-list-item' key={item}>{item}</span>
      ))}
    </div>
  );

  const getInformationTitle = () => {
    if (siblings.length > 1) {
      return position > 0 ? `${t('Ch.')} ${position}: ${title}` : `${title}`;
    } else {
      return title;
    }
  };

  const shouldHideInformation = mindItem?.fileType === 'video' && !isValidVideoItem(mindItem?.publisher);

  return (
    <div
      className={classnames('reader-information', variant === 'mobile' && 'reader-information--mobile')}
      data-testid='reader__infobar'>
      <div className='reader-information__header-bar'>
        {isReadOnly
          ? (
            <div className='reader-information__header' data-testid='reader-information__header'>
              <Icon icon='view' />
              <span className='reader-information__header-text'>{t('Read Only Content')}</span>
            </div>
          )
          : (
            <div className='reader-information__header'>
              <Icon icon='information' />
              <span className='reader-information__header-text'>{t('Details')}</span>
            </div>
          )
        }

        <div>
          <WithTooltip text={t('Close')} placement='bottom'>
            <ActionButton
              icon='arrow-bar-right'
              testId='reader__infobar__close-button'
              onClick={onClose}
            />
          </WithTooltip>
        </div>
      </div>
      {!shouldHideInformation &&
        <div className='reader-information__body' data-testid='reader-information__body'>
          {publisher &&
            <div className='reader-information__publisher-logo w-100 d-flex justify-content-center my-4'>
              <PublisherImage publisher={publisher} className='h-100 w-auto' />
            </div>
          }
          <div
            className='pb-3 pt-2'
            data-testid='reader-information__title'
          >
            <TruncateWithExpand
              className='reader-information__title'
              text={getInformationTitle()}
              lines={5}
              expandTrackingPayload={{
                'minditem_id': id
              }}
            />
          </div>
          {subtitle &&
            <div className='pb-3'>
              <TruncateWithExpand
                text={subtitle}
                lines={5}
                className='color--grey-dark font-size-16'
                expandTrackingPayload={{
                  'minditem_id': id
                }}
              />
            </div>
          }
          {description && (
            <div className='pb-3 color--grey-medium'>
              <TruncateWithExpand
                text={description}
                lines={8}
                expandTrackingPayload={{
                  'minditem_id': id
                }}
              />
            </div>
          )}
          {subjects &&
            <ReaderInformationCategory
              iconName='subjects'
              text='Subjects'
            >
              {mapTrackingEvents(subjects)}
            </ReaderInformationCategory>
          }
          {classYears &&
            <ReaderInformationCategory
              iconName='classes'
              text='Class years'
            >
              {mapTrackingEvents(compressedClassYears)}
            </ReaderInformationCategory>
          }
          {
            licence &&
            <LicenceCategory licence={licence} />
          }
          {publisherReleaseDate &&
            <ReaderInformationCategory
              iconName='time'
              text='Release date'
            >
              {mapTrackingEvents([getDateInGermanFormat(publisherReleaseDate) || 'Invalid Date'])}
            </ReaderInformationCategory>
          }
          {author &&
            <ReaderInformationCategory
              iconName='profile'
              text='Author'
            >
              {mapTrackingEvents([author])}
            </ReaderInformationCategory>
          }
          {learningFormat && learningFormat.length > 0 &&
            <ReaderInformationCategory
              iconName='filter'
              text='Learning format'
            >
              {mapTrackingEvents(learningFormat)}
            </ReaderInformationCategory>
          }
          {workload && workload.length > 0 &&
            <ReaderInformationCategory
              iconName='filter'
              text='Workload'
            >
              {mapTrackingEvents(workload)}
            </ReaderInformationCategory>
          }
          {contentType &&
            <ReaderInformationCategory
              iconName='filter'
              text='Content Type'
            >
              {mapTrackingEvents([t(capitalize(contentType))])}
            </ReaderInformationCategory>
          }
          {teachingFormat && teachingFormat.length > 0 &&
            <ReaderInformationCategory
              iconName='filter'
              text='Teaching Format'
            >
              {mapTrackingEvents(teachingFormat)}
            </ReaderInformationCategory>
          }
        </div>
      }
    </div>
  );
};

export default ReaderInformation;
