import React, { memo } from 'react';
import { CarouselTrackingPropertiesGTM } from '../../../utils/tracking';
import { CarouselTrackingProperties } from '../../../types/AppContent';
import { ItemVariation } from '@klab-berlin/api-sdk/lib/types/responses/Collection';
import { EventInputData } from '../../../types/tracking';
import { openItem, itemLink, isModifiedMouseClick } from '../../../utils';
import useRouter from '../../../utils/useRouter';
import { Product } from '@klab-berlin/api-sdk/lib/types/responses/Product';
import { MindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import MindItemCard from '../../cards/MindItemCard';
import ProductCard from '../../cards/ProductCard';
import './dashboardSlideItem.scss';

export interface OwnProps extends CarouselTrackingProperties {
  item: Product | MindItem;
  type: ItemVariation;
  redirectToMindItemId?: string;
}

const DashboardSlideItem: React.FC<OwnProps> = memo(({ item, ...props }) => {
  const { router } = useRouter();

  // In some cases, for instance, for the Studyflix carousel, by clicking on the carousel item - 
  // the user should be redirected to the mindItem page. 
  // To implement that, you need to send the "redirectToMindItemId" value. 
  // See an example in the DashboardStudyflixCarousel component.
  const itemId: string = props.redirectToMindItemId ? props.redirectToMindItemId : item.id;
  const type: ItemVariation = props.redirectToMindItemId ? 'mindItem' : props.type;

  const onClickHandler = (event: React.MouseEvent) => {
    if (isModifiedMouseClick(event)) return;

    event.preventDefault();
    event.stopPropagation();

    openItem(router, itemId, type);
  };

  const renderCard = (type: ItemVariation, item: Product | MindItem) => {
    if (type === 'mindItem') {
      return <MindItemCard {...item as MindItem} />;
    }

    if (type === 'product') {
      return <ProductCard {...item as Product} />;
    }

    return null;
  };

  const carouselTracking: CarouselTrackingPropertiesGTM = {
    carousel_document_ids: props.carouselDocumentIds,
    carousel_position: props.carouselPosition,
    carousel_school_types: props.carouselSchoolTypes,
    carousel_subject_texts: props.carouselSubjectTexts,
    carousel_type: props.carouselType,
  };

  const trackingPayload: EventInputData = { other: carouselTracking };
  if (props.type === 'mindItem') {
    trackingPayload.document = item as MindItem;
  } else {
    trackingPayload.product = item as Product;
  }

  return <a href={itemLink(itemId, type)} onClick={onClickHandler} key={item.id}>
    <div className='dashboard-slide-item__card-container'>
      {renderCard(props.type, item)}
    </div>
  </a>;
});

export default DashboardSlideItem;
