import API from '@klab-berlin/api-sdk';
import nprogress from 'nprogress';
import config from 'config';
import cookies from './cookies';

nprogress.configure({ showSpinner: false });

const api = new API({
  progress: nprogress,
  realm: 'mu',
  tokenProvider: {
    deleteToken: cookies.deleteAuthToken,
    getToken: cookies.getAuthToken,
    setToken: cookies.setAuthToken,
    type: 'token',
  },
  url: config.urls.api,
});

export default api;
