import { Link } from 'found';
import classNames from 'classnames';
import IntercomSVG from '../../../../assets/intercom.svg';
import React, { useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { IconName } from '../../../Icon/IconTypes';
import Icon from '../../../Icon';
import './topBarMenuItems.scss';
import { useTranslation } from 'react-i18next';
import { IntercomAPI } from 'react-intercom';
import { noop } from 'lodash';

interface TopBarMenuItemProps {
  hide?: boolean;
  label: string;
  iconName: IconName;
  classNames?: string;
}

export const InternalLink: React.FC<{ to: string; isActive?: boolean } & TopBarMenuItemProps> = (props) => {
  if (props.hide) return null;

  return (
    <Nav.Link
      as={Link}
      to={props.to}
      className={classNames('top-bar-menu-item', { 'active': props.isActive }, props.classNames)}
    >
      <Icon icon={props.iconName} className='top-bar-menu-item__icon' size='lg' />
      <span className='font-size-16'>{props.label}</span>
    </Nav.Link>
  );
};

export const ExternalLink: React.FC<{ href: string; newTab?: boolean } & TopBarMenuItemProps> = (props) => {
  if (props.hide) return null;

  return (
    <Nav.Link
      href={props.href}
      target={props.newTab ? '_blank' : undefined}
      className={classNames('top-bar-menu-item', props.classNames)}
    >
      <Icon icon={props.iconName} className='top-bar-menu-item__icon' size='lg' />
      <span className='font-size-16'>{props.label}</span>
    </Nav.Link>
  );
};

type PremiumLinkProps = {
  hide?: boolean;
  classNames?: string;
  to: string,
};

export const PremiumLink: React.FC<PremiumLinkProps> = (props) => {
  const { t } = useTranslation();

  if (props.hide) return null;

  return (
    <Nav.Link
      as={Link}
      to={props.to}
      className={classNames('top-bar-menu-item__premium-link', props.classNames)}
    >
      {t('meinUnterricht')}
      <span data-testid='top-bar__premium' className='top-bar-menu-item__premium-pill'>{t('PREMIUM')}</span>
    </Nav.Link>
  );
};

export const MenuItem: React.FC<{ onClick: () => void } & TopBarMenuItemProps> = (props) => {
  if (props.hide) return null;

  return (
    <Nav.Link
      onClick={props.onClick}
      className={classNames('top-bar-menu-item', props.classNames)}
    >
      <Icon icon={props.iconName} className='top-bar-menu-item__icon' size='lg' />
      <span className='font-size-16'>{props.label}</span>
    </Nav.Link>
  );
};

export const IntercomItem: React.FC<{ classNames: string }> = (props) => {
  const { t } = useTranslation();
  const [notificationCount, setNotificationCount] = useState(0);

  // Use window.Intercom as a dependency because react-intercom adds it
  // and it might not be available when the component is initially rendered
  useEffect(() => {
    if (!window.Intercom) return;

    IntercomAPI('onUnreadCountChange', (unreadCount: number) => setNotificationCount(unreadCount));

    return () => IntercomAPI('onUnreadCountChange', noop);
  }, [window.Intercom]);

  return (
    <div className='intercom-custom-launcher top-bar-menu-item__intercom'>
      <Nav.Link className={classNames('top-bar-menu-item', props.classNames)}>
        <span className='top-bar-menu-item__intercom-icon top-bar-menu-item__icon'>
          <IntercomSVG />
        </span>
        <span className='font-size-16'>{t('Open Chat')}</span>
        {notificationCount > 0 && <div className='top-bar-menu-item__intercom-notification-container'>
          <div className='top-bar-menu-item__intercom-notification'>{notificationCount}</div>
        </div>}
      </Nav.Link>
    </div>
  );
};
