import React, { ReactNode, useState, useRef, useEffect } from 'react';
import { Tooltip, Overlay } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/Overlay';
import { tooltipEnterDelay, tooltipLeaveDelay } from '../../constants';

interface WithTooltipProps {
  children: ReactNode[] | ReactNode;
  text: string;
  placement?: Placement;
  container?: HTMLElement;
  className?: string;
}

const WithTooltip: React.FC<WithTooltipProps> = ({ children, text, placement, container, className }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const timer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  });

  const onMouseEnter = () => {
    timer.current = setTimeout(() => setShow(true), tooltipEnterDelay);
  };

  const onMouseLeave = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => setShow(false), tooltipLeaveDelay);
  };

  return (
    <>
      <div 
        className={className ? className : 'd-inline'} 
        data-testid='with-tooltip' 
        ref={target}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave} 
      >
        {children}
      </div>
      <Overlay target={target.current} show={show} placement={placement}
        container={container} >
        {(props: any) => (
          <Tooltip id={text} {...props} show={show.toString()}>
            {text}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

const PROMPTED_TOOLTIP_DISAPPEAR_DELAY = 3000;

interface WithPromptedTooltip {
  children: ReactNode[] | ReactNode;
  text: string;
  placement?: Placement;
  show: boolean;
  onClose: () => void;
}

export const WithPromptedTooltip: React.FC<WithPromptedTooltip> = ({ children, show, onClose, text, placement }) => {
  const timer = useRef<NodeJS.Timeout>();
  const target = useRef(null);

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  });

  useEffect(() => {
    if (show) {
      timer.current = setTimeout(onClose, PROMPTED_TOOLTIP_DISAPPEAR_DELAY);
    }
  }, [show]);

  return (
    <>
      <div className='d-inline' ref={target}>
        {children}
      </div>
      <Overlay target={target.current} show={show} placement={placement}>
        {(props: any) => (
          <Tooltip id={text} {...props} show={show.toString()}>
            {text}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default WithTooltip;
