import {
  Box,
  Button,
  Dialog as MuiDialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { borderRadius, boxShadows, spaces } from '../../assets/styles/themes/tokens';

export interface DialogProps {
  title: string;
  image?: string;
  imageStyles?: React.CSSProperties;
  primaryCta: {
    label: string;
    onClick: () => void;
  };
  secondaryCta?: {
    label: string;
    onClick: () => void;
  };
  onClose?: () => void;
  open: boolean;
  htmlBody?: React.ReactNode;
  position?: 'center' | 'right-bottom'
}

const Dialog : React.FC<DialogProps> = ({ position = 'center', ...props }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobile'));
  const isLocatedBottom: boolean = position === 'right-bottom';

  const onClose = (_event: object, reason: string) => {
    if (reason === 'backdropClick') {
      return;
    }
    props.onClose?.();
  };

  return <MuiDialog
    open={props.open}
    onClose={onClose}
    hideBackdrop={isLocatedBottom}
    disableEnforceFocus={isLocatedBottom}
    disableScrollLock={isLocatedBottom}
    PaperProps={{
      sx: theme => {
        let styles = {
          boxShadow: boxShadows.elevation6,
          [theme.breakpoints.up('mobile')]: {
            width: '600px',
          },
          [theme.breakpoints.down('mobile')]: {
            width: '343px',
          }
        };

        if (isLocatedBottom) {
          styles = {
            ...styles,
            position: 'fixed',
            right: '0px',
            bottom: spaces.xxxl,
          };
        }

        return styles;
      }
    }}
    sx={isLocatedBottom ? {
      pointerEvents: 'none!important',
      '& .MuiDialogContent-root, & .MuiDialogTitle-root, & .MuiDialogActions-root': {
        pointerEvents: 'all!important',
      },
    } : {}}
  >
    <DialogTitle
      sx={theme => {
        return {
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          [theme.breakpoints.up('mobile')]: {
            paddingTop: spaces.lg,
            paddingBottom: spaces.lg,
            '& + .MuiDialogContent-root': {
              paddingTop: spaces.md + ' !important',
            }
          },
          [theme.breakpoints.down('mobile')]: {
            paddingTop: spaces.md,
            paddingBottom: spaces.md,
            '& + .MuiDialogContent-root': {
              paddingTop: 0 + ' !important',
            },
          },
          gap: spaces.md,
        };
      }}
      variant={isMobile ? 'h6' : 'h5'}
    >
      { props.title }
      <IconButton
        color='secondary'
        aria-label='close'
        data-testid='dialog-dismiss-button'
        onClick={props.onClose}
        sx={{
          padding: spaces.xxs,
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    {
      props.image &&
      <DialogContent
        sx={theme => {
          return {
            paddingBottom: spaces.md,
            [theme.breakpoints.down('mobile')]: {
              paddingTop: 0,
              paddingBottom: 0,
            }
          };
        }}
      >
        <Box
          component='img'
          sx={theme => {
            return {
              width: '1',
              [theme.breakpoints.up('mobile')]: {
                aspectRatio: '16/9',
              },
              [theme.breakpoints.down('mobile')]: {
                aspectRatio: '4/3',
              },
              marginBottom: spaces.md,
              borderRadius: borderRadius.xs,
              ...props.imageStyles,
            };
          }}
          src={props.image}
        />
      </DialogContent>
    }
    <DialogContent sx={{
      paddingTop: 0,
      paddingBottom: isLocatedBottom ? spaces.md : 0,
    }}>
      { props.htmlBody }
    </DialogContent>
    <DialogContent
      sx={theme => {
        return {
          display: 'flex',
          paddingTop: spaces.lg,
          paddingBottom: spaces.lg,
          [theme.breakpoints.up('tablet')]: {
            justifyContent: 'flex-end',
          },
          [theme.breakpoints.between('mobile', 'tablet')]: {
            justifyContent: 'space-between',
            '& > button': {
              flexGrow: 1,
            }
          },
          [theme.breakpoints.down('mobile')]: {
            flexDirection: 'column-reverse',
            paddingTop: spaces.lg,
          },
        };
      }}
    >
      {
        props.secondaryCta && (
          <Button
            variant='outlined'
            size='large'
            data-testid='dialog-secondary-cta-button'
            onClick={props.secondaryCta.onClick}
            sx={theme => {
              return {
                [theme.breakpoints.up('mobile')]: {
                  marginRight: spaces.xs,
                },
                [theme.breakpoints.down('mobile')]: {
                  marginTop: spaces.xs,
                }
              };
            }}
          >
            { props.secondaryCta.label }
          </Button>
        )
      }
      <Button
        variant='contained'
        size='large'
        data-testid='dialog-primary-cta-button'
        onClick={props.primaryCta.onClick}
      >
        { props.primaryCta.label }
      </Button>
    </DialogContent>
  </MuiDialog>;
};

export default Dialog;
