import React from 'react';
import Icon from '../../../Icon';
import classnames from 'classnames';
import './dropdownToggleButton.scss';

interface ControlledSelectToggleButtonProps {
  UIState: {
    isOpen?: boolean;
    isSelected?: boolean;
    isDisabled?: boolean;
  };
  icon?: React.ReactNode;
  label: string;
  showClearIcon: boolean;
  onClearIconClick: () => void;
  toggleButtonProps: Record<string, unknown>;
}

const ControlledSelectToggleButton: React.FC<ControlledSelectToggleButtonProps> = ({
  UIState,
  icon,
  label,
  showClearIcon,
  onClearIconClick,
  toggleButtonProps,
}) => {
  return (
    <>
      <button
        {...toggleButtonProps}
        disabled={UIState.isDisabled}
        className={classnames('dropdown-toggle-button py-2 px-3 box-shadow',
          {
            'dropdown-toggle-button--open rounded-top': UIState.isOpen || UIState.isDisabled,
            'rounded': !UIState.isOpen,
            'dropdown-toggle-button--selected': UIState.isSelected && !UIState.isOpen && !UIState.isDisabled,
            'dropdown-toggle-button--enabled': !UIState.isDisabled,
            'dropdown-toggle-button--disabled': UIState.isDisabled,
          })}
        data-testid='dropdown-toggle-button'
        type='button'
      >
        <span className={classnames(
          'text-truncate mr-2',
          {
            'font-weight-medium': UIState.isSelected && !UIState.isOpen
          }
        )}>
          {icon}
          {label}
        </span>
        <Icon
          size='lg'
          className={classnames(
            'dropdown-toggle-button__icon',
            {
              'color--grey-medium': !UIState.isDisabled,
              'color--cool-grey': UIState.isDisabled,
              'dropdown-toggle-button__icon--hidden': showClearIcon && UIState.isSelected && !UIState.isOpen
            })}
          icon={UIState.isOpen ? 'arrow-small-up' : 'arrow-small-down'}
        />
      </button>

      {UIState.isSelected && showClearIcon &&
      <button
        className={classnames('dropdown-clear-button absolute-top-0-right-0 py-2 pr-3 rounded', {
          'dropdown-clear-button--open': UIState.isOpen || UIState.isDisabled,
        })}
        disabled={UIState.isDisabled}
        type='button'
        data-testid='dropdown-toggle-clear'
        onClick={onClearIconClick}>
        <Icon className={classnames({
          'color--grey-dark': !UIState.isDisabled,
          'color--cool-grey': UIState.isDisabled
        })} size='lg' icon={'cancel'} />
      </button>
      }
    </>
  );
};

export default ControlledSelectToggleButton;
