const sortAlphabetically = (
  list: Array<any>,
  {
    locale='de',
    field='',
  }: {
    locale?: string,
    field?: string,
  }) => {
  if (field !== '') {
    return [...list].sort((a, b) => new Intl.Collator(locale).compare(a[field], b[field]));
  } else {
    return [...list].sort((a, b) => new Intl.Collator(locale).compare(a, b));
  }
};

export default sortAlphabetically;
