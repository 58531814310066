import { PaginationRequest } from '@klab-berlin/api-sdk/lib/types/common';
import { CreateCollectionRequest, EditCollectionRequest } from '@klab-berlin/api-sdk/lib/types/requests/Collection';
import { ItemVariation } from '@klab-berlin/api-sdk/lib/types/responses/Collection';
import api from '../clients/api';
import { notifyBugsnagNetwork } from '../utils/bugsnagClient';
import { handleUnauthorized } from '../utils/apiWrapper';

const service = {
  addItemToCollection,
  createCollection,
  deleteCollection,
  deleteItemFromCollection,
  getCollectionContentsByCollectionId,
  getCuratedCollections,
  updateCollection,
};

function _mapCollectionPayloadData<T extends { subjects?: Array<string> }>(payload: T) {
  if (payload.subjects) {
    payload.subjects = payload.subjects.filter((value) => !!value);
  }

  return payload;
}

function addItemToCollection(collectionId: string, type: ItemVariation, itemId: string) {
  notifyBugsnagNetwork('collection', 'addItemToCollection', { collectionId, type, itemId });
  return handleUnauthorized(() => api.collection.addItemToCollection(collectionId, type, itemId));
}

function createCollection(payload: CreateCollectionRequest) {
  notifyBugsnagNetwork('collection', 'createCollection', { payload });
  return handleUnauthorized(() => api.collection.createCollection(_mapCollectionPayloadData(payload)));
}

function deleteCollection(collectionId: string) {
  notifyBugsnagNetwork('collection', 'deleteCollection', { collectionId });
  return handleUnauthorized(() => api.collection.deleteCollection(collectionId));
}

function deleteItemFromCollection(collectionId: string, type: ItemVariation, itemId: string) {
  notifyBugsnagNetwork('collection', 'deleteItemFromCollection', { collectionId, type, itemId });
  return handleUnauthorized(() => api.collection.deleteItemFromCollection(collectionId, type, itemId));
}

function getCuratedCollections() {
  notifyBugsnagNetwork('collection', 'getCuratedCollections', {});
  return handleUnauthorized((() => api.collection.getCuratedCollections()));
}

function getCollectionContentsByCollectionId(collectionId: string, pagination: PaginationRequest) {
  notifyBugsnagNetwork('collection', 'getCuratedCollections', { collectionId, pagination });
  return handleUnauthorized((() => api.collection.getCollectionContentsByCollectionId(collectionId, pagination)));
}

function updateCollection(collectionId: string, payload: EditCollectionRequest) {
  notifyBugsnagNetwork('collection', 'updateCollection', { collectionId, payload });
  return handleUnauthorized(() => api.collection.updateCollection(collectionId, _mapCollectionPayloadData(payload)));
}

export default service;
