import React from 'react';
import classnames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CollectionIcon from '../CollectionIcon';
import { CollectionWithContainsItemFlag } from '../../state/ManageItemInCollectionProvider';
import Checkbox from '../common/Checkbox';

interface CollectionSelectProps {
  collections: CollectionWithContainsItemFlag[];
  onChange: (collectionId: string) => void;
}

interface CollectionSelectorProps {
  id: string;
  title: string;
  color: string;
  containsItem: boolean;
  onChange: (collectionId: string) => void;
}

const CollectionSelector: React.FC<CollectionSelectorProps> = (props) => {
  return (
    <Row
      className={classnames('mb-1 mx-1 py-2 rounded cursor-pointer', {
        'bg-light': props.containsItem,
      })}
      onClick={() => (props.onChange(props.id))}
    >
      <Col xs={10} className='text-truncate'>
        <CollectionIcon color={props.color} className='mr-2' />
        <span>{props.title}</span>
      </Col>
      <Col className='text-right' xs={2}>
        <Checkbox
          id={props.id}
          checked={props.containsItem}
          disabled={true}
        />
      </Col>
    </Row>
  );
};

const CollectionSelect: React.FC<CollectionSelectProps> = (props) => {
  return (
    <>
      {props.collections.map((c) => (
        <CollectionSelector
          key={c.id}
          id={c.id}
          title={c.title}
          color={c.color}
          containsItem={c.containsItem}
          onChange={props.onChange}
        />
      ))}
    </>
  );
};

export default CollectionSelect;
