exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".empty-results .empty-image{max-width:100%;width:720px}.empty-results .empty-results__no-results-text{max-width:480px;font-size:1.1em}@media only screen and (max-width: 767px){.empty-results .empty-results__no-results-text{max-width:350px}}@media only screen and (max-width: 575px){.empty-results .empty-results__no-results-text{max-width:250px}}.empty-results .empty-results__no-results-text h2{font-size:1.4em;margin-top:1em}", ""]);
// Exports
exports.locals = {
	"collectionGridGap": "15px",
	"collectionGridItemHeight": "211px",
	"collectionGridItemWidth": "225px",
	"collectionListHeight": "84px",
	"greyLightest": "#f7f9fa",
	"greyLight2": "#d8d8d8",
	"neuVeryLightBlue": "#e9ebec",
	"muBlue": "#4298b5",
	"gridBreakPoints_-xs": "0",
	"gridBreakPoints_-sm": "576px",
	"gridBreakPoints_-md": "768px",
	"gridBreakPoints_-lg": "992px",
	"gridBreakPoints_-xl": "1440px"
};