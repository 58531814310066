import { ContentItem } from '../types/AppContent';
import { StoreType } from './index';
import { ItemVariation } from '@klab-berlin/api-sdk/lib/types/responses/Collection';

export const getContent = (
  type: ItemVariation,
  id: string,
  mindItems: StoreType['mindItems']['byId'],
  products: StoreType['products']['byId']
): ContentItem | undefined => {
  if (type === 'mindItem') {
    const mindItem = mindItems[id];
    return (
      mindItem &&
      mindItem.data && {
        ...mindItem.data,
        type: 'mindItem',
      }
    );
  } else {
    const product = products[id];
    return (
      product &&
      product.data && {
        ...product.data,
        type: 'product',
      }
    );
  }
};
