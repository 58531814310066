import React from 'react';
import { useTranslation } from 'react-i18next';
import constants from '../../constants';
import { BasicFilterProps } from '../../types/search';
import { Select } from '../common/Select';

const FilterByLearningFormat: React.FC<BasicFilterProps<string[]>> = (
  { updateFilter, clearFilter, currentValue, onToggle, onItemClick }
) => {
  const { t } = useTranslation();

  type Option = {
    name: string;
    value: string;
  };

  const options: Option[] = constants.learningFormats.map(learningFormat => ({
    name: learningFormat.text,
    value: learningFormat.value
  }));

  return (
    <Select
      onSelectionConfirm={(values) => {
        updateFilter(values);
      }}
      onSelectionClear={() => {
        clearFilter();
      }}
      label={t('Learning format')}
      options={options}
      alwaysShowLabel={true}
      value={currentValue}
      multi={true}
      onToggle={onToggle}
      onItemClick={onItemClick}
      saveButtonLabel='Anwenden'
      resetButtonLabel='Löschen'
    />
  );
};

export default FilterByLearningFormat;
