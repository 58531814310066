import { MindItemFileType } from '@klab-berlin/api-sdk/lib/types/responses/Common';
import { DownloadLink } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import React from 'react';
import { getIconForFileType } from '../../utils';
import Icon from '../Icon';
import Truncate from '../Truncate';
import './downloadItem.scss';

interface OwnProps {
  data: DownloadLink<MindItemFileType>;
}

const DownloadItem: React.FC<OwnProps> = ({ data }) => {
  const { name, type } = data;
  return (
    <div className='download-item__wrapper'>
      <div className='download-item' data-testid='download-item'>
        <div className='download-item__body'>
          <Icon className='download-item__type-icon' icon={getIconForFileType(type)}
            testId='download-item-icon' />
          <div className='download-item__details'>
            <Truncate lines={1} text={name} />
            <div className='download-item__details__finer'>
              {type}
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default DownloadItem;
