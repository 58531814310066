import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import WithTracking from '../utils/WithTracking';
import { TrackingEvent } from '../../types/tracking';
import Truncate from '../Truncate';

interface TruncateWithExpandTrackingPayload {
  product_id?: string;
  minditem_id?: string;
  is_expanded?: boolean;
}
interface TruncateWithExpandProps {
  text: string;
  lines?: number;
  expandTrackingEvent?: TrackingEvent;
  expandTrackingPayload?: TruncateWithExpandTrackingPayload;
  className?: string;
}

const TruncateWithExpand: React.FC<TruncateWithExpandProps> = props => {
  const { lines, text } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
    
  const { t } = useTranslation();

  const onExpandClick = () => setIsExpanded(!isExpanded);

  const toggleExpandButton = <div>
    <WithTracking
      events={{ onClick: props.expandTrackingEvent }}
      eventPayload={{
        other: {
          ...props.expandTrackingPayload,
          'is_expanded': isExpanded
        }
      }}
    >
      <Button
        className='p-0'
        variant='link'
        onClick={onExpandClick}
      >
        {isExpanded ? t('show less') : t('show more')}
      </Button>
    </WithTracking>
  </div>;
  
  if (!lines) {
    return <span>{text}</span>;
  }
  
  return <>
    { isExpanded
      ? <div className={props.className}>{text}</div>
      : <Truncate
        className={props.className}
        lines={lines}
        text={text}
        onTruncationChange={setIsTruncated} /> }
    {(isTruncated || isExpanded) && toggleExpandButton}
  </>;
};

export default TruncateWithExpand;
