import React from 'react';
import { Button } from 'react-bootstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

interface ResetButtonProps {
  label?: string;
  size?: 'sm' | 'lg';
  onClick?: () => void;
  className?: string;
  hidden?: boolean;
}

const ResetButton: React.FC<ResetButtonProps> = ({ className = '', ...props }) => {
  const { t } = useTranslation();

  return (
    <Button
      variant='link'
      size={props.size}
      className={classnames(
        className,
        'color--grey-dark focus-visible-only--highlight-blue',
        { 'invisible': !!props.hidden }
      )}
      onClick={props.onClick}
    >
      {props.label || t('Reset')}
    </Button>
  );
};

export default ResetButton;
