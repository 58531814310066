import React from 'react';
import { useTranslation } from 'react-i18next';
import UpgradeImg from '../../assets/UpgradeImage.svg';
import Icon from '../Icon';

interface OwnProps {
  downloadCount: number;
}

const DownloadLimitExceededBasis: React.FC<OwnProps> = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <p className='font-size-16'>
        {t(
          'You have already downloaded <downloadCount> documents and exceeded your free download limit.',
          { downloadCount: props.downloadCount }
        )}
      </p>
      <p className='font-size-16'>
        {t('Get premium now and download up to 100 documents per month.')}
      </p>
      <UpgradeImg className='h-auto w-100' />
      <ul className='font-size-16 list-unstyled mt-3 mb-3'>
        <li className='mb-3 d-flex'>
          <div><Icon className='color--mu-blue' icon='check' /></div>
          <span className='pl-3'>{t('Access to over 100,000 documents')}</span>
        </li>
        <li className='mb-3 d-flex'>
          <div><Icon className='color--mu-blue' icon='check' /></div>
          <span className='pl-3'>{t('100 downloads per month')}</span>
        </li>
        <li className='d-flex'>
          <div><Icon className='color--mu-blue' icon='check' /></div>
          <span className='pl-3'>
            {t('Exclusive Premium content like learning videos, audio, editable documents and much more')}
          </span>
        </li>
      </ul>
    </div>);
};

export default DownloadLimitExceededBasis;
