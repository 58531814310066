import React from 'react';
import { useTranslation } from 'react-i18next';
import './emptySearchResults.scss';
import searchEmptyDesktopPNG from '../../../../assets/searchEmptyDesktop.png';
import searchEmptyMobilePNG from '../../../../assets/searchEmptyMobile.png';

const EmptySearchResults: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className='empty-results' data-testid='zero-results-old-ui'>
      <img src={searchEmptyDesktopPNG} className='m-auto empty-image d-none d-sm-block'/>
      <img src={searchEmptyMobilePNG} className='m-auto empty-image d-sm-none'/>
      <div className='text-center m-auto empty-results__no-results-text' >
        <h2>{t('Sorry, No results found.')}</h2>
        <p>{t('What to do to get search results')}</p>
      </div>
    </div>
  );
};

export default EmptySearchResults;
