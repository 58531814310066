import React from 'react';
import services from '../../services';
import { EventInputData, TrackingEvent } from '../../types/tracking';
import merge from 'lodash.merge';

export interface Events {
  onClick?: TrackingEvent;
  onDoubleClick?: TrackingEvent;
  onChange?: TrackingEvent;
  onFocus?: TrackingEvent;
  onMiddleClick?: TrackingEvent;
  onContextMenu?: TrackingEvent;
}

interface WithTrackingProps {
  events: Events;
  eventPayload?: EventInputData;
  eventsOptions?: {
    onClick?: {
      debounce?: boolean;
    };
  };
  children: React.ReactNode | React.ReactNode[];
}

interface ChildProps {
  onClick?: any;
  onContextMenu?: any;
  onDoubleClick?: any;
  onChange?: any;
  onFocus?: any;
  onMouseDown?: any;
}

const eventsOptionsDefaultValue: WithTrackingProps['eventsOptions'] = {
  onClick: {
    debounce: true,
  }
};

const track = (event: TrackingEvent, eventPayload?: EventInputData) => {
  services.track.eventTrack(event, eventPayload);
};

const WithTracking: React.FC<WithTrackingProps> = ({
  children,
  eventPayload,
  events,
  eventsOptions = eventsOptionsDefaultValue,
}) => {
  const opts = merge(eventsOptionsDefaultValue, eventsOptions);
  let clickTimeout: null | number = null;

  return (<>
    {React.Children.map(children, ((child) => {

      if (!React.isValidElement(child)) {
        return child;
      }
      const props = child.props as ChildProps;

      const childProps = {
        ...props,
        onChange: (event: React.SyntheticEvent) => {
          if (props.onChange) {
            props.onChange(event);
          }

          if (!events.onChange) {
            return;
          }

          track(events.onChange, eventPayload);
        },
        onContextMenu: (event: React.SyntheticEvent) => {
          if (props.onContextMenu) {
            props.onContextMenu(event);
          }

          if (!events.onContextMenu) {
            return;
          }

          track(events.onContextMenu, eventPayload);
        },
        onMouseDown: (event: React.MouseEvent) => {
          if (props.onMouseDown) {
            props.onMouseDown(event);
          }

          if (!events.onMiddleClick) {
            return;
          }

          // event.button refers to the button index in js mouse event object: 
          // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
          if (event.button === 1) {

            track(events.onMiddleClick, eventPayload);
          }
        },
        onClick: (event: React.SyntheticEvent) => {
          const savedFrom = window.location.pathname;
          const callTrack = () => {
            if (!events.onClick) {
              return;
            }

            track(events.onClick, Object.assign({}, eventPayload, { from: savedFrom }));
          };
          if (props.onClick) {
            props.onClick(event);
          }

          if (opts.onClick && !opts.onClick.debounce) {
            return callTrack();
          }

          if (clickTimeout !== null) {
            clearTimeout(clickTimeout);
          }

          clickTimeout = window.setTimeout(() => {
            clickTimeout = null;

            callTrack();
          }, 300);

        },
        onDoubleClick: (event: React.SyntheticEvent) => {
          if (props.onDoubleClick) {
            props.onDoubleClick(event);
          }

          if (!events.onDoubleClick) {
            return;
          }

          if (clickTimeout !== null) {
            clearTimeout(clickTimeout);
          }

          track(events.onDoubleClick, eventPayload);
        },
        onFocus: (event: React.SyntheticEvent) => {
          if (props.onFocus) {
            props.onFocus(event);
          }

          if (!events.onFocus) {
            return;
          }

          track(events.onFocus, eventPayload);
        }
      };
      return React.cloneElement(child, childProps);
    }))}
  </>);
};

export default WithTracking;
