exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".reader-video-content{display:flex;overflow:hidden}@media only screen and (max-width: 767px){.reader-video-content{position:relative;display:flex;flex-direction:column;width:100%;margin-top:57px;overflow:initial}}.reader-video-content--mobile{position:relative;display:flex;flex-direction:column;width:100%;margin-top:57px;overflow:initial}.reader-video-wrapper{height:100%;background:#fafafa}.video-overflow{height:100%;overflow-y:scroll}.video-flex{height:100%;align-items:center;flex-direction:column;display:flex}.video-content__actions{width:56px}.video-content__body{width:100%}.public-share-button{margin:32px auto 0;display:block}#video-container{margin-top:32px;max-width:896px;width:100%;padding-right:16px;padding-left:16px;display:flex;flex-direction:column;align-items:flex-start}#video-container--public{padding-top:32px;width:100%;padding-right:16px;padding-left:16px;display:flex;flex-direction:column;align-items:center;height:100%;background-color:#fafafa}#video-container--public div{max-width:896px}.video-overlay-container{position:absolute;width:100%;min-width:420px;height:100%;min-height:360px;padding-top:16px}@media only screen and (max-width: 767px){.video-overlay-container{min-width:340px}}", ""]);
// Exports
exports.locals = {
	"medium": "768px",
	"large": "1024px",
	"xlarge": "1440px",
	"collectionGridGap": "15px",
	"collectionGridItemHeight": "211px",
	"collectionGridItemWidth": "225px",
	"collectionListHeight": "84px",
	"greyLightest": "#f7f9fa",
	"greyLight2": "#d8d8d8",
	"neuVeryLightBlue": "#e9ebec",
	"muBlue": "#4298b5",
	"gridBreakPoints_-xs": "0",
	"gridBreakPoints_-sm": "576px",
	"gridBreakPoints_-md": "768px",
	"gridBreakPoints_-lg": "992px",
	"gridBreakPoints_-xl": "1440px",
	"navigationLayoutMobileHeaderHeight": "57px"
};