import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../reducers/user.selectors';
import { getDateInGermanFormat } from '../../../utils/format';
import LabeledText from '../../common/LabeledText';
import PersonalDetailsInputGroup from '../PersonalDetailsInputGroup';

const PersonalDataView: React.FC = () => {
  const user = useSelector(selectUser);
  const { t } = useTranslation();

  const name = `${user?.firstName} ${user?.lastName}`;
  const birthday = user?.birthday ? getDateInGermanFormat(user.birthday as string) : null;

  return (
    <PersonalDetailsInputGroup>
      <LabeledText label={t('Name')} text={name} id='name' />
      {birthday && <LabeledText label={t('Birthday')} text={birthday} id='birthday' />}
    </PersonalDetailsInputGroup>
  );
};

export default PersonalDataView;
