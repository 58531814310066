import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { 
  AmpliPremiumPlanSelectedPayload, 
  LicenceInfoByTermLengthType, 
  defaultTermLength,
  routes
} from '../../../../constants';
import { currencyFormat } from '../../../../utils/format';
import { LicenceModel, LicenceModelsById } from '@klab-berlin/api-sdk/lib/types/responses/Billing';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../reducers/user.selectors';
import PremiumTag from '../../../cards/common/PremiumTag';
import OfferPoints from '../OfferPoints';
import DiscountCircle from '../DiscountCircle';
import Button from '../../../common/Button';
import useRouter from '../../../../utils/useRouter';
import FormSelect from '../../../forms/FormSelect';
import './PremiumCard.scss';
import { PremiumPlanSelected } from '../../../../ampli';
import services from '../../../../services';
import * as ampliUtils from '../../../../utils/ampli';

const offerPoints = [
  '100 downloads per month',
  'Limitless reading content online',
  'New materials ever week',
  'Editable documents',
  'Webinars for your further education',
  'Videos and audio files'
];

interface PremiumCardProps {
  isTrainee: boolean;
  licences: LicenceModelsById;
}

const PremiumCard: React.FC<PremiumCardProps> = ({ isTrainee, licences }) => {
  const [selectedTermLength, setSelectedTermLength] = useState<string>(defaultTermLength);
  const user = useSelector(selectUser);
  const { t } = useTranslation();
  const { router } = useRouter();

  const { licenceDetailsByTermLength, traineeLicenceDetails } = extractLicenceInformation(licences);

  const linkQuery = {
    licenceId: isTrainee
      ? traineeLicenceDetails.licenceId
      : licenceDetailsByTermLength[selectedTermLength].licenceId,
  };

  const onButtonClick = () => {
    const eventProperties: AmpliPremiumPlanSelectedPayload = ampliUtils.getAmpliPremiumPlanSelectedPayload({
      isTrainee, 
      traineeLicenceDetails, 
      selectedTermLength,
      licenceDetailsByTermLength
    });   

    if (user && eventProperties) {      
      services.track.ampliEventTrack({
        event: new PremiumPlanSelected(eventProperties)
      });
    }

    router.push({ pathname: `/${routes.upgradePayment.root}`, query: linkQuery });
  };

  return (
    <div className='premium-card'>
      {isTrainee && <DiscountCircle
        discountAmount={traineeLicenceDetails.discountPercentage}
        discountType='Percentage' />}
      <div className='premium-card__tag'><PremiumTag isTrainee={isTrainee} /></div>
      <div className='premium-card__container'>
        <div className='premium-card__top'>
          <div className='price__container'>
            <div className='price__number' data-testid='price__number'>{isTrainee
              ? currencyFormat(traineeLicenceDetails.price, true)
              : licenceDetailsByTermLength[selectedTermLength].price}</div>
            <div className='price__currency'>€</div>
          </div>
          <div>{t('per month')}</div>
          {isTrainee
            ? <div className='term-length__text'>
              {t('<termLength> months duration', { termLength: traineeLicenceDetails.period })}
            </div>
            : <TermLengthDropdown
              licenceDetailsByTermLength={licenceDetailsByTermLength}
              onChange={setSelectedTermLength}
              selectedTermLength={selectedTermLength} />
          }
        </div>
        <div className='premium-card__main'>
          <OfferPoints points={offerPoints} areAvailable={true} />
          <Button
            className='premium-card__button'
            emoji='🚀'
            onClick={onButtonClick}
            variant='black'>
            {t('Select package')}
          </Button>
        </div>
      </div>
    </div>
  );
};

/**
 * Extract relevant information from the licences and assign them to the given termLengths
 * @returns {any, any} {licenceDetailsByTermLength, traineeLicenceDetails}
 */
export const extractLicenceInformation = (licences: LicenceModelsById) => {
  let traineeLicenceDetails: any;
  const licenceDetailsByTermLength: LicenceInfoByTermLengthType = {};

  Object.values(licences).forEach((licence: LicenceModel) => {
    if (licence.isTrial) return;
    if (licence.isLibrary) return;
    if (!licence.isRef) {
      licenceDetailsByTermLength[licence.period] = {
        price: currencyFormat(licence.price, true),
        licenceId: String(licence.licenceModelId)
      };
    } else {
      traineeLicenceDetails = {
        discountPercentage: licence.discountPercentage,
        period: licence.period,
        price: currencyFormat(licence.price, true),
        licenceId: String(licence.licenceModelId)
      };
    }
  });
  return {
    licenceDetailsByTermLength,
    traineeLicenceDetails,
  };
};

interface TermLengthDropdownProps {
  onChange: (key: string) => void;
  licenceDetailsByTermLength: LicenceInfoByTermLengthType;
  selectedTermLength: string;
}

const TermLengthDropdown: React.FC<TermLengthDropdownProps> = (props) => {
  const { t } = useTranslation();
  const items = Object.keys(props.licenceDetailsByTermLength)
    // ensure that options are sorted by termLength
    .sort((termLengthA, termLengthB) => parseInt(termLengthA) - parseInt(termLengthB))
    .map(termLength => (
      {
        value: termLength as string,
        label: t('<termLength> months duration', { termLength })
      }
    ));
  return (
    <FormSelect
      containerClassName='term-length-dropdown__container'
      id='term-length__dropdown'
      name='term-length__dropdown'
      onChoose={props.onChange}
      defaultValue={props.selectedTermLength}
      items={items}
    />
  );
};

export default PremiumCard;
