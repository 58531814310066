import React, { useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { DownloadModalContext } from '../../../state/DownloadModalProvider';
import { ShareModalContext } from '../../../state/ShareModalProvider';
import { ShareModalItemTypeEnum } from '../../../types/sharing';
import { getIconForFileType } from '../../../utils';
import { CtxButton, CtxIconText, CtxMetas, CtxTop } from '../ContextUtils';
import OpenItemContextButton from './OpenItemContextButton';
import PinItemContextButton from './PinItemContextButton';
import { MindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';

interface MindItemContextProps {
  mindItem: MindItem;
}

const MindItemContext: React.FC<MindItemContextProps> = ({ mindItem }) => {
  const { show: openShareModal } = useContext(ShareModalContext);
  const { show: openDownloadModal } = useContext(DownloadModalContext);
  const { t } = useTranslation();
  const translatedFileType: string = t(mindItem.fileType);
  const publisher: string = mindItem.publisher ? t(mindItem.publisher) : '';

  const hideDownloadAction = mindItem.isReadOnly && !mindItem.hasAttachments;

  return (
    <>
      <CtxTop title={mindItem.title} description={mindItem.description} />
      <Row className='py-4 border-bottom'>
        <Col>
          <OpenItemContextButton item={{ ...mindItem, type: 'mindItem' }} />
          {mindItem.fileType === 'document' && !hideDownloadAction &&
            <CtxButton icon='download' onClick={() => openDownloadModal(mindItem.id)}>
              {t('Download')}
            </CtxButton>
          }
          <PinItemContextButton item={{ ...mindItem, type: 'mindItem' }} />
          <CtxButton
            icon='share'
            onClick={() => openShareModal(mindItem.id, ShareModalItemTypeEnum.MINDITEM)}
          >
            {t('Share')}
          </CtxButton>
        </Col>
      </Row>
      <Row as={'dl'} className='py-4 border-bottom'>
        <CtxMetas
          data={[
            ['FileType', translatedFileType],
            ['Publisher', publisher],
            ['Author', mindItem.author],
            ['Subject', mindItem.subjects],
            ['Class years', mindItem.classYears],
            ['SchoolType', mindItem.schoolTypes],
            ['Pages', mindItem.fileType === 'document' ? mindItem.pageCount : undefined],
          ]}
        />
      </Row>
      {mindItem.hasAttachments &&
        <Row className='py-4'>
          <Col xs={12}>
            <h6 className='h6 text-secondary font-weight-normal'>
              {t('Attachments')}
            </h6>
            {mindItem.attachments.map((attachment, index) => (
              <CtxIconText key={index} icon={getIconForFileType(attachment.contentType)}>
                {attachment.name}
              </CtxIconText>
            ))}
          </Col>
        </Row>}
    </>
  );
};

export default MindItemContext;
