import React from 'react';
import { useTranslation } from 'react-i18next';
import EmptyState from '../../EmptyState';

const CollectionContextUnselected: React.FC = () => {
  const { t } = useTranslation();
  return (
    <EmptyState
      iconName='collection'
      text={[t('Please select a collection from the left')]}
    />
  );
};

export default CollectionContextUnselected;
