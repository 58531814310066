import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { AsyncActionStatus } from '../../actions/common.actionTypes';
import { selectProductStatus } from '../../reducers/products.selectors';
import Icon from '../Icon';
import WithTooltip from '../WithTooltip';
import { CurrentProductContext } from '../Product/CurrentProductContext';
import { externalLinks } from '../../constants';
import { ContentLicence } from '@klab-berlin/api-sdk/lib/types/common';
import './licenceCategory.scss';

interface LicenceCategoryProps {
  licence?: ContentLicence;
}

// This component is used to display licence for Products and the Mind Items
// since materials under a creative commons license need to be linked to the creative commons website.
const LicenceCategory: React.FC<LicenceCategoryProps> = (props) => {
  const { t } = useTranslation();
  const { productId } = useContext(CurrentProductContext);
  const isLoading =
    useSelector(state => selectProductStatus(state, productId)) === AsyncActionStatus.LOADING;

  if (!props.licence) return <></>;

  return (
    <div className='licence-category'>
      <WithTooltip text={t('Licence')}>
        <div className='licence-category__icon'>
          <Icon icon='premium' className='mr-2' size='lg' />
        </div>
      </WithTooltip>
      <div className='licence-category__licence'>
        {
          isLoading
            ? <Skeleton width='200px' />
            :
            <div>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={externalLinks.licenceLinks[props.licence]}
              >
                {props.licence}
              </a>
            </div>
        }
      </div>
    </div>
  );
};

export default LicenceCategory;
