import React, { useEffect } from 'react';
import MasterLayout from '../../components/AppLayout/MasterLayout';
import NavigationLayoutDesktop from '../../components/AppLayout/NavigationLayout/NavigationLayoutDesktop';
import NavigationLayoutMobile from '../../components/AppLayout/NavigationLayout/NavigationLayoutMobile';
import ReaderProductNavigation from '../../components/Reader/ReaderProductNavigation';
import AttachmentsView from '../../components/AttachmentsView';
import ReaderHead from '../../components/Reader/ReaderHead';
import { useNavigationLayout } from '../../utils/useNavigationLayout';
import {
  fetchAttachments as fetchAttachmentsAction,
  fetchProduct as fetchProductAction,
  fetchProductMindItems as fetchProductMindItemsAction
} from '../../actions/product.actions';
import useAction from '../../utils/useAction';
import useRouter from '../../utils/useRouter';
import { notifyBugsnagHandledError } from '../../utils/bugsnagClient';
import { routes } from '../../constants';
import { useSelector } from 'react-redux';
import { selectProduct } from '../../reducers/products.selectors';
import { selectUser } from '../../reducers/user.selectors';
import ProductAttachmentsBreadcrumbs from '../../components/ProductAttachmentsHead/ProductAttachmentsHead';
import { useTranslation } from 'react-i18next';

const ProductAttachmentsPage: React.FC = () => {
  const { renderNavigationMobileLayout } = useNavigationLayout();
  const { router, match, match: { params: { productId, attachmentsPath } } } = useRouter();
  const fetchProduct = useAction(fetchProductAction);
  const fetchProductMindItems = useAction(fetchProductMindItemsAction);
  const fetchAttachments = useAction(fetchAttachmentsAction);
  const product = useSelector(state => selectProduct(state, productId));
  const user = useSelector(selectUser);
  const { t } = useTranslation();

  const navigatedFoldersList = attachmentsPath ? attachmentsPath.split('/') : undefined;
  const isParentPageAttachmentsRootPage = !!navigatedFoldersList && navigatedFoldersList.length === 1;

  useEffect(() => {
    fetchProduct(productId);
    fetchProductMindItems(productId);
    fetchAttachments(productId);
  }, [productId]);

  if (user && !user.isPremium) {
    router.push(`/${routes.productPage.root}/${productId}`);
  }

  const getCurrentFolderName = () => {
    if (!navigatedFoldersList) return;
    return navigatedFoldersList.slice(-1).toString();
  };
  
  const getParentPageName = () => {
    if (navigatedFoldersList === undefined) return product?.title;
    if (isParentPageAttachmentsRootPage) return t('Attachments');
    return navigatedFoldersList.slice(-2, -1).toString();
  };
  
  const getParentFolderPathEncoded = () => {
    if (navigatedFoldersList === undefined) return `/${routes.productPage.root}/${productId}`;
    if (isParentPageAttachmentsRootPage) return `/${routes.productAttachmentsPage.root}/${productId}`;
    const parentFolder = navigatedFoldersList.slice(0, - 1).join('/');
    return `/${routes.productAttachmentsPage.root}/${productId}/${encodeURIComponent(parentFolder)}`;
  };

  const onFolderClick = (folderName: string) => {
    const encodedName = encodeURIComponent(folderName);
    router.push(`${match.location.pathname}/${encodedName}`);
  };

  const onError = (errorMessage: string) => {
    notifyBugsnagHandledError(new Error(errorMessage));
    router.replace(`/${routes.notFound.root}`);
  };
  
  return (
    <MasterLayout>
      {
        renderNavigationMobileLayout ?
          <NavigationLayoutMobile
            head={
              <ReaderHead
                productId={productId}
                header={
                  <ProductAttachmentsBreadcrumbs
                    productId={productId}
                    isProductActive={!!(product && product.active)}
                    productTitle={product?.title}
                    isCurrentPageAttachmentsRootPage={navigatedFoldersList === undefined}
                    isParentPageAttachmentsRootPage={isParentPageAttachmentsRootPage}
                    currentFolderName={getCurrentFolderName()}
                    parentFolderPath={getParentFolderPathEncoded()}
                    parentPageName={getParentPageName()}
                    variant='mobile'
                  />
                }
              />
            }
            main={
              <AttachmentsView
                attachmentsPath={match.params.attachmentsPath}
                onFolderClick={onFolderClick}
                onError={onError}
                productId={productId}
                variant={'mobile'}
              />
            } 
          />
          :
          <NavigationLayoutDesktop
            head={
              <ReaderHead
                productId={productId} 
                header={
                  <ProductAttachmentsBreadcrumbs
                    productId={productId}
                    isProductActive={!!(product && product.active)}
                    productTitle={product?.title}
                    isCurrentPageAttachmentsRootPage={navigatedFoldersList === undefined}
                    isParentPageAttachmentsRootPage={isParentPageAttachmentsRootPage}
                    currentFolderName={getCurrentFolderName()}
                    parentFolderPath={getParentFolderPathEncoded()}
                    parentPageName={getParentPageName()}
                    variant='desktop'
                  />
                }
              />
            }
            main={
              <AttachmentsView
                attachmentsPath={match.params.attachmentsPath}
                onFolderClick={onFolderClick}
                onError={onError}
                productId={productId}
                variant={'desktop'}
              />
            }
            sidebarLeft={
              <ReaderProductNavigation highlightAttachmentsButton disableExpandButton productId={productId} />
            }
          />
      }
    </MasterLayout>
  );
};

export default ProductAttachmentsPage;
