import { LicenceModel } from '@klab-berlin/api-sdk/lib/types/responses/Billing';
import immutable from 'object-path-immutable';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import { 
  FETCH_ACTIVE_LICENCE_MODELS, 
  FetchActiveLicenceModelsAction 
} from '../actions/licenceModels.actionTypes';
import { LogoutAction, LOGOUT } from '../actions/auth.actionTypes';

type LicenceModelsActionTypes = FetchActiveLicenceModelsAction | LogoutAction;

export interface LicenceModelsStateType {
  byId: {
    [key: number]: LicenceModel;
  };
  status: AsyncActionStatus;
}

const initialState: LicenceModelsStateType = {
  byId: {},
  status: AsyncActionStatus.INITIAL
};

const licenceModels = (state = initialState, action: LicenceModelsActionTypes): LicenceModelsStateType => {
  switch (action.type) {
    case LOGOUT.SUCCESS:
      return initialState;
    case FETCH_ACTIVE_LICENCE_MODELS.START:
      return immutable(state)
        .set('status', AsyncActionStatus.LOADING)
        .value();

    case FETCH_ACTIVE_LICENCE_MODELS.SUCCESS:
      return immutable(state)
        .set('status', AsyncActionStatus.READY)
        .merge('byId', action.result)
        .value();

    case FETCH_ACTIVE_LICENCE_MODELS.ERROR: {
      const { error } = action;
      const notFound = /Not Found/.test(error.message);
      return immutable(state)
        .set('status', notFound ? AsyncActionStatus.NOTFOUND : AsyncActionStatus.ERROR)
        .value();
    }

  }
  return state;
};

export default licenceModels;
