import { ZuoraPaymentMethod, ZuoraSignature } from '@klab-berlin/api-sdk/lib/services/billing';
import { Accordion } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import services from '../../services';
import config from 'config';
import SepaSVG from '../../assets/payment_sepa.svg';
import PaypalSVG from '../../assets/payment_paypal.svg';
import CreditCardSVG from '../../assets/payment_creditcard.svg';
import PaypalForm from '../PaypalForm';
import PaymentCard from './PaymentCard/PaymentCard';
import './paymentForm.scss';
import { ZuoraPaymentPageResponse, ZuoraPaymentPageResponseHandler } from '../../types/ZuoraPayments';
import { isEveryPayPalPaymentObjectDefined, isZuoraPaymentObjectDefined } from '../../utils/payment';
import { notifyBugsnagHandledError } from '../../utils/bugsnagClient';

const PAYMENT_METHOD_CREDIT_CARD: ZuoraPaymentMethod = 'creditCard';
const PAYMENT_METHOD_SEPA: ZuoraPaymentMethod = 'sepa';
const PAYMENT_METHOD_PAYPAL: ZuoraPaymentMethod = 'braintree';

const renderPaymentPage = (
  signature: ZuoraSignature,
  paymentMethod: ZuoraPaymentMethod,
  onResponseHandler: ZuoraPaymentPageResponseHandler
) => {
  const params = {
    locale: 'de_DE',
    style: 'inline',
    submitEnabled: 'true',
    url: config.zuoraHostedPagesUri,
    id: signature.pageId,
    key: signature.key,
    signature: signature.signature,
    token: signature.token,
    tenantId: signature.tenantId,
  };

  window.Z.render(
    params,
    {},
    (response: ZuoraPaymentPageResponse) => onResponseHandler(paymentMethod, response)
  );
};

interface PaymentFormProps {
  onSelect: (paymentMethod: ZuoraPaymentMethod) => void;
  onResponse: ZuoraPaymentPageResponseHandler;
  selectedMethod: ZuoraPaymentMethod;
}

const PaymentForm: React.FC<PaymentFormProps> = (props) => {
  const [loadedPage, setLoadedPage] = useState<ZuoraPaymentMethod|undefined>();
  const [loadingError, setLoadingError] = useState<ZuoraPaymentMethod|undefined>();
  const [paypalToken, setPaypalToken] = useState('');

  useEffect(() => {
    setupPaymentMethod(props.selectedMethod);
  }, [props.selectedMethod]);

  const handleSelect = (paymentMethod: ZuoraPaymentMethod) => {
    props.onSelect(paymentMethod);
  };

  const setupPaymentMethod = (paymentMethod: ZuoraPaymentMethod) => {
    setLoadedPage(undefined);
    setLoadingError(undefined);

    if (paymentMethod === PAYMENT_METHOD_PAYPAL) {
      if (!isEveryPayPalPaymentObjectDefined()) {
        const error = `Not every PayPal payment object is defined. 
          !!window.braintree.client: ${!!window.braintree?.client}, 
          !!window.braintree.paypalCheckout: ${!!window.braintree?.paypalCheckout},
          !!window.paypal.Button: ${!!window.paypal?.Button},`.replace(/(\r\n|\n|\r)/gm, '').replace(/\s{2,}/g, ' ');

        notifyBugsnagHandledError(new Error(error));

        setLoadingError(paymentMethod);
        return;
      }

      services.billing.getBraintreeSignature()
        .then(data => setPaypalToken(data.token))
        .then(() => setLoadedPage(paymentMethod))
        .catch(() => setLoadingError(paymentMethod));
      return;
    }

    if (!isZuoraPaymentObjectDefined()) {
      const error = `Zuora payment object is not defined. !!window.Z: ${!!window.Z}`;
      notifyBugsnagHandledError(new Error(error));

      setLoadingError(paymentMethod);
      return;
    }

    services.billing.getSignature(paymentMethod)
      .then((signature) => renderPaymentPage(signature, paymentMethod, props.onResponse))
      .then(() => setLoadedPage(paymentMethod))
      .catch(() => setLoadingError(paymentMethod));
  };

  return (
    <Accordion
      onSelect={(eventKey: any) => handleSelect(eventKey)}
      defaultActiveKey={props.selectedMethod}
    >
      <PaymentCard
        method={PAYMENT_METHOD_SEPA}
        isSelected={props.selectedMethod === PAYMENT_METHOD_SEPA}
        icon={<SepaSVG className='svg--sepa' />}
        loadingError={loadingError === PAYMENT_METHOD_SEPA}
        isLoaded={loadedPage === PAYMENT_METHOD_SEPA}
      >
        {
          props.selectedMethod === PAYMENT_METHOD_SEPA && 
          <div id='zuora_payment' data-testid='sepa-payment-page'/>
        }
      </PaymentCard>
      <PaymentCard
        method={PAYMENT_METHOD_PAYPAL}
        isSelected={props.selectedMethod === PAYMENT_METHOD_PAYPAL}
        icon={<PaypalSVG className='svg--paypal'/>}
        loadingError={loadingError === PAYMENT_METHOD_PAYPAL}
        isLoaded={loadedPage === PAYMENT_METHOD_PAYPAL}
      >
        {
          props.selectedMethod === PAYMENT_METHOD_PAYPAL && !loadingError && loadedPage &&
          <PaypalForm   
            token={paypalToken} 
            licenceId={1} 
            onResponse={props.onResponse} 
          />
        }
      </PaymentCard>
      <PaymentCard
        method={PAYMENT_METHOD_CREDIT_CARD}
        isSelected={props.selectedMethod === PAYMENT_METHOD_CREDIT_CARD}
        icon={<CreditCardSVG className='svg--creditcard' />}
        loadingError={loadingError === PAYMENT_METHOD_CREDIT_CARD}
        isLoaded={loadedPage === PAYMENT_METHOD_CREDIT_CARD}
      >
        {
          props.selectedMethod === PAYMENT_METHOD_CREDIT_CARD &&
          <div id='zuora_payment'  data-testid='credit-card-payment-page'/>
        }
      </PaymentCard>
    </Accordion>
  );
};

export default PaymentForm;
