import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchMe } from '../../actions/user.actions';
import { selectUser, selectIsAuthenticated } from '../../reducers/user.selectors';
import Login from '../../components/Login/Login';
import { withRouter, WithRouter } from 'found';
import LoginGradientModalAppLayout from '../../components/Login/LoginGradientAppLayout';
import cookies from '../../clients/cookies';
import useAction from '../../utils/useAction';
import Spinner from 'react-bootstrap/Spinner';
import _ from 'lodash';

interface OwnProps {
  match: {
    location: {
      query: {
        redirect?: string;
      };
    };
  };
}
type LoginPageProps = OwnProps & WithRouter;

const LoginPage: React.FC<LoginPageProps> = (props) => {
  const host = window.location.hostname;
  if (!['staging-app.meinunterricht.de', 'dev-local.meinunterricht.de'].includes(host)) {
    window.close();
    props.router.replace('/');
  }
  const user = useSelector(selectUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const fetchMeAction = useAction(fetchMe);
  const handleLoginSuccess = useCallback(async () => {
    if (user) {
      window.opener.postMessage({
        value: user
      }, '*');
      window.close();
    }
    // 
  }, [props.router, props.match, user]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchMeAction();
      if (user) {
        const userData = {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          schoolType: user.schoolType,
          subjects: user.subjectsFlattened.map(s => s.text)
        };
        window.opener.postMessage({ value: userData }, '*');
        window.close();
      }
    }
  }, [cookies.getAuthToken(), user]);

  return (
    <LoginGradientModalAppLayout>
      {!isAuthenticated
        ? <Login onLoginSuccess={handleLoginSuccess} onLoginFailure={_.noop} />
        // eslint-disable-next-line react/forbid-dom-props
        : <div style={{ margin: 'auto' }}>
          <Spinner animation='border'/>
        </div>
      }
    </LoginGradientModalAppLayout>
  );
};

export default withRouter(LoginPage);
