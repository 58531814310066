import { Link } from 'found';
import React, { memo } from 'react';
import CollectionCard from '../CollectionCard';
import { CollectionProperties } from '../../types/AppContent';

interface OwnProps {
  collection: CollectionProperties;
  linkRoot: string;
}

type Props = OwnProps;

const DashboardSlideCollection: React.FC<Props> = memo(({ collection, linkRoot }) => {

  return (
    <div className='dashboard-collection-item p-2 m-auto'>
      <Link to={`/${linkRoot}/${collection.id}`} key={collection.id}>
        <CollectionCard collection={collection} active={false} canPublish={false} />
      </Link>
    </div>
  );
});

export default DashboardSlideCollection;
