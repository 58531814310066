import React, { useContext } from 'react';
import DownloadLimitExceededOverlay from './DownloadLimitExceededOverlay';
import DownloadInfoOverlay from './DownloadInfoOverlay';
import UpgradeInfoOverlay from './UpgradeInfoOverlay';
import { ReaderContext } from '../../../ReaderContext';
import ExclusiveContentOverlay from './ExclusiveContentOverlay';
import { useSelector } from 'react-redux';
import { selectMindItem } from '../../../../../reducers/mindItems.selectors';
import { selectUser } from '../../../../../reducers/user.selectors';

interface NewBasisReaderOverlayProps {
  downloadCounter: number;
  downloadMax: number;
  currentPage: number;
  pageLimit: number;
  mindItemId: string;
}

export interface WatermarkTrackingObject {
  minditem_id: string;
  current_page?: number;
  page_count?: number;
}

const NewBasisReaderOverlay: React.FC<NewBasisReaderOverlayProps> = (props) => {
  const downloadsRemaining = props.downloadMax - props.downloadCounter;
  const { isReadOnly, pagesLoaded, mindItemId } = useContext(ReaderContext);
  const mindItem = useSelector(state => selectMindItem(state, props.mindItemId));
  const user = useSelector(selectUser);
  const showDownloadLimitModal: boolean | undefined = user?.type === 1 && mindItem?.isFreePublishingHouse;

  const trackingObject = {
    'minditem_id': mindItemId,
    'current_page': props.currentPage,
    'page_count': props.pageLimit,
  };

  if (!pagesLoaded) {
    // As the images take some time to render, without this, the browser renders the
    // overlays initially over one another at the start of the page, causing a
    // flashing effect
    return <></>;
  }

  if (props.pageLimit === 1) {
    if (isReadOnly) {
      return <ExclusiveContentOverlay category='read-only' trackingObject={trackingObject} />;
    }
    else if (downloadsRemaining <= 0) {
      return <DownloadLimitExceededOverlay
        trackingObject={trackingObject}
      />;
    }

    return showDownloadLimitModal ? <></> : <DownloadInfoOverlay
      downloadsRemaining={downloadsRemaining}
      trackingObject={trackingObject}
    />;
  } else {
    if (props.currentPage === 1) {
      return <></>;
    } else if (props.currentPage === 2) {
      if (isReadOnly) {
        return <ExclusiveContentOverlay category='read-only' trackingObject={trackingObject} />;
      } else if (downloadsRemaining <= 0) {
        return <DownloadLimitExceededOverlay
          trackingObject={trackingObject}
        />;
      }

      return <DownloadInfoOverlay
        downloadsRemaining={downloadsRemaining}
        trackingObject={trackingObject}
      />;
    }

    return <UpgradeInfoOverlay trackingObject={trackingObject} />;
  }
};

export default NewBasisReaderOverlay;
