import { MindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import { createSelector } from 'reselect';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import { StoreType } from './index';
import { getMindItems } from './mindItems.selectors';

export const getProducts = (state: StoreType) => state.products.byId;

const getProduct = (state: StoreType, productId: string) => state.products.byId[productId]?.data;

const getAttachments = (state: StoreType, productId: string) => state.products.attachmentsByProductId[productId]?.data;

export const selectProductStatus = (state: StoreType, productId: string) =>
  (state.products.byId[productId]?.status || AsyncActionStatus.INITIAL);

export const selectProduct = (state: StoreType, productId: string) =>
  getProduct(state, productId);

export const selectAttachments = (state: StoreType, productId: string) => 
  getAttachments(state, productId);

export const selectAttachmentsStatus = (state: StoreType, productId: string) => 
  (state.products.attachmentsByProductId[productId]?.status || AsyncActionStatus.INITIAL);

const productWithMindItemsSelector = createSelector([getProduct, getMindItems], (product, mindItems) => {
  if (!product) {
    return;
  }

  return ({
    ...product,
    mindItems: (product.mindItems
      .map(mindItemId => mindItems[mindItemId] && mindItems[mindItemId].data)
      .filter(v => !!v) as MindItem[]),
  });
});

export const selectProductMindItemsLoadedStatus = (state: StoreType, productId: string) => {
  const product = getProduct(state, productId);
  if (!product) {
    return;
  }

  return product.mindItems.map((mindItemId) => {
    const mindItem = state.mindItems.byId[mindItemId];

    // Product might include ids of inactive mindItems that will never be fetched by other endpoints
    if (!mindItem) {
      return;
    }

    return mindItem.status || AsyncActionStatus.INITIAL;
  }).filter(mindItem => mindItem);
};

export const selectProductWithMindItems = (state: StoreType, productId: string) =>
  productWithMindItemsSelector(state, productId);
