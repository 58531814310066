import React from 'react';
import { borderRadius, customColors, palette, spaces } from '../../../../assets/styles/themes/tokens';
import { ListItem } from '@mui/material';
import { Lernset } from '@klab-berlin/api-sdk/lib/types/responses/Lernsets';

interface LernsetListItemProps {
  lernset: Lernset;
  selected: boolean;
  handleLernsetClick: (id: string) => void;
  sx?: React.CSSProperties;
}

const LernsetListItem: React.FC<LernsetListItemProps> = (props) => {
  const { lernset, selected, handleLernsetClick, sx } = props;
  const listItemColors = customColors.lernsetListItem;

  return (
    <ListItem sx={{ ...sx,
      padding: `${spaces.xs} ${spaces.md}`,
      border: `1px solid ${listItemColors.border}`,
      borderRadius: borderRadius.sm,
      cursor: 'pointer',
      ...(!selected && {
        '&:hover': {
          backgroundColor: palette.action.hover,
        }
      }),
      ...(selected && {
        backgroundColor: listItemColors.selectedBackground,
        borderColor: listItemColors.selectedBorder
      })
    }}
    onClick={() => {
      handleLernsetClick(lernset.id);
    }}
    data-testid={selected ? 'selected-lernset-list-item' : 'lernset-list-item'}
    >
      { props.children }
    </ListItem>
  );
};

export default LernsetListItem;
