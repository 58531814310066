import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { ampliConversionPath, routes } from '../../constants';
import PremiumUpgradeButton from '../PremiumButtons/PremiumUpgradeButton';
import useRouter from '../../utils/useRouter';

interface UpgradeDetailsModalProps {
  show: boolean;
  onClose: () => void;
}

const UpgradeDetailsModal: React.FC<UpgradeDetailsModalProps> = ({ show, onClose }) => {
  const { t } = useTranslation();
  const { router } = useRouter();

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton={true}>
        <Modal.Title>
          {t('What is meinUnterricht Premium?')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('mU allows lesson planning without limits')}</p>
        <p>{t('Unlimited access to a lot of pages and documents and 100 downloads')}</p>
        <p>{t('Explore cureated collections, videos and attachments')}</p>
        <PremiumUpgradeButton conversionPath={ampliConversionPath.PremiumInfoPopup} onClick={() => {
          router.push(`/${routes.upgradePackageSelection.root}`);
          onClose();
        }}/>
      </Modal.Body>
    </Modal>
  );
};

export default UpgradeDetailsModal;
