import {
  UserResponseData,
  UpdateNewsletterResponse,
  ExperimentData
} from '@klab-berlin/api-sdk/lib/types/responses/User';
import {
  PaymentMethod,
  UpdatePayload as UserUpdatePayload,
  UpgradePayload
} from '@klab-berlin/api-sdk/lib/types/requests/User';
import { AsyncAction, AsyncActionStatus, SyncAction } from './common.actionTypes';
import {
  PaymentMethodResponse,
  SubscriptionResponse,
  InvoicesResponse
} from '@klab-berlin/api-sdk/lib/types/responses/Billing';

export enum FETCH_ME {
  START = 'FETCH_ME_START',
  SUCCESS = 'FETCH_ME_SUCCESS',
  ERROR = 'FETCH_ME_ERROR',
  EXPIRED = 'FETCH_ME_EXPIRED'
}

export type FetchMeAction = AsyncAction<
  typeof FETCH_ME.START,
  typeof FETCH_ME.SUCCESS,
  typeof FETCH_ME.ERROR | typeof FETCH_ME.EXPIRED,
  UserResponseData
>;

export enum UPDATE_ME {
  START = 'UPDATE_ME_START',
  SUCCESS = 'UPDATE_ME_SUCCESS',
  ERROR = 'UPDATE_ME_ERROR',
}

export type UpdateMeAction = AsyncAction<
  typeof UPDATE_ME.START,
  typeof UPDATE_ME.SUCCESS,
  typeof UPDATE_ME.ERROR,
  UserResponseData,
  UserUpdatePayload
  >;

export enum UPDATE_NEWSLETTER {
  START = 'UPDATE_NEWSLETTER_START',
  SUCCESS = 'UPDATE_NEWSLETTER_SUCCESS',
  ERROR = 'UPDATE_NEWSLETTER_ERROR',
}

export type UpdateNewsletterAction = AsyncAction<
  typeof UPDATE_NEWSLETTER.START,
  typeof UPDATE_NEWSLETTER.SUCCESS,
  typeof UPDATE_NEWSLETTER.ERROR,
  UpdateNewsletterResponse,
  { optIn: boolean }
  >;

export enum GET_SUBSCRIPTION {
  START = 'GET_SUBSCRIPTION_START',
  SUCCESS = 'GET_SUBSCRIPTION_SUCCESS',
  ERROR = 'GET_SUBSCRIPTION_ERROR',
}

export type GetSubscriptionAction = AsyncAction<
  typeof GET_SUBSCRIPTION.START,
  typeof GET_SUBSCRIPTION.SUCCESS,
  typeof GET_SUBSCRIPTION.ERROR,
  SubscriptionResponse | undefined,
  { userId: string, status?: AsyncActionStatus }
  >;

export enum CANCEL_SUBSCRIPTION {
    START = 'CANCEL_SUBSCRIPTION_START',
    SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS',
    ERROR = 'CANCEL_SUBSCRIPTION_ERROR',
  }
  
export type CancelSubscriptionAction = AsyncAction<
    typeof CANCEL_SUBSCRIPTION.START,
    typeof CANCEL_SUBSCRIPTION.SUCCESS,
    typeof CANCEL_SUBSCRIPTION.ERROR,
    undefined,
    { userId: string }
    >;

export enum UPDATE_PAYMENT_METHOD {
  START = 'UPDATE_PAYMENT_METHOD_START',
  SUCCESS = 'UPDATE_PAYMENT_METHOD_SUCCESS',
  ERROR = 'UPDATE_PAYMENT_METHOD_ERROR',
}

export type UpdatePaymentMethodAction = AsyncAction<
  typeof UPDATE_PAYMENT_METHOD.START,
  typeof UPDATE_PAYMENT_METHOD.SUCCESS,
  typeof UPDATE_PAYMENT_METHOD.ERROR,
  PaymentMethodResponse,
  { paymentMethod: PaymentMethod, userId: string }
>;

export enum GET_PAYMENT_METHOD {
  START = 'GET_PAYMENT_METHOD_START',
  SUCCESS = 'GET_PAYMENT_METHOD_SUCCESS',
  ERROR = 'GET_PAYMENT_METHOD_ERROR',
}

export type GetPaymentMethodAction = AsyncAction<
  typeof GET_PAYMENT_METHOD.START,
  typeof GET_PAYMENT_METHOD.SUCCESS,
  typeof GET_PAYMENT_METHOD.ERROR,
  PaymentMethodResponse,
  { userId: string }
>;

export enum GET_INVOICES {
  START = 'GET_INVOICES_START',
  SUCCESS = 'GET_INVOICES_SUCCESS',
  ERROR = 'GET_INVOICES_ERROR',
}

export type GetInvoicesAction = AsyncAction<
  typeof GET_INVOICES.START,
  typeof GET_INVOICES.SUCCESS,
  typeof GET_INVOICES.ERROR,
  InvoicesResponse
>;

export enum UPGRADE {
  START = 'UPGRADE_START',
  SUCCESS = 'UPGRADE_SUCCESS',
  ERROR = 'UPGRADE_ERROR',
}

export type UpgradeAction = AsyncAction<
  typeof UPGRADE.START,
  typeof UPGRADE.SUCCESS,
  typeof UPGRADE.ERROR,
  UserResponseData,
  UpgradePayload
  >;

export enum GET_EXPERIMENT {
  START = 'GET_EXPERIMENT_START',
  SUCCESS = 'GET_EXPERIMENT_SUCCESS',
  ERROR = 'GET_EXPERIMENT_ERROR',
}

export type GetExperimentAction = AsyncAction<
  typeof GET_EXPERIMENT.START,
  typeof GET_EXPERIMENT.SUCCESS,
  typeof GET_EXPERIMENT.ERROR,
  ExperimentData
>;

export enum TRACK_EXPERIMENT {
  START = 'TRACK_EXPERIMENT_START',
  SUCCESS = 'TRACK_EXPERIMENT_SUCCESS',
  ERROR = 'TRACK_EXPERIMENT_ERROR',
}

export type UpdateExperimentAction = AsyncAction<
  typeof TRACK_EXPERIMENT.START,
  typeof TRACK_EXPERIMENT.SUCCESS,
  typeof TRACK_EXPERIMENT.ERROR,
  ExperimentData
  >;

type IncrementDownloadsCounter = 'INCREMENT_DOWNLOADS_COUNTER';

export const INCREMENT_DOWNLOADS_COUNTER: IncrementDownloadsCounter = 'INCREMENT_DOWNLOADS_COUNTER';

export type IncrementDownloadsCounterAction = SyncAction<
  IncrementDownloadsCounter
>;
