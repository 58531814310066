import React from 'react';
import './mindItemCard.scss';
import useMedia from '../../../utils/useMedia';
import WithTooltip from '../../WithTooltip';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ManageItemInCollectionsButton from '../../ManageItemInCollectionsButton';
import PinItemButton from '../../PinItemButton';
import { trackingEvents } from '../../../services/tracking/trackConfig';
import WithTracking from '../../utils/WithTracking';
import CardThumbnail from '../common/CardThumbnail';
import CardDescription from '../common/CardDescription';
import ItemCardFooter from '../common/ItemCardFooter';
import Truncate from '../../Truncate';
import TagContainer from '../common/TagContainer';
import { ContentType } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import PlayIcon from '../common/PlayIcon';

interface MindItemCardProps {
  classYears: number[];
  isReadOnly: boolean;
  isNew: boolean;
  description?: string;
  fileType: ContentType;
  id: string;
  isPinned: boolean;
  thumbnail: string;
  title: string;
  subjects: string[];
  publisher?: string;
  hideCollectionAction?: boolean;
  hidePinAction?: boolean;
  index?: number;
  searchEngineVersion?: number;
  schoolTypes: string[];
}

const MindItemCard: React.FC<MindItemCardProps> = (props) => {
  const {
    classYears,
    isNew,
    isReadOnly,
    description,
    fileType,
    thumbnail,
    title,
    subjects,
    publisher,
    id,
    isPinned,
    hideCollectionAction,
    hidePinAction,
    index,
    searchEngineVersion,
    schoolTypes,
  } = props;

  const { t } = useTranslation();
  const { isCoarsePointer } = useMedia();
  const itemData = {
    id,
    title,
    subjects,
    publisher,
    classYears,
    schoolTypes,
    fileType,
    isReadOnly,
  };

  return (
    <WithTracking
      events={{
        onClick: trackingEvents.itemOpen,
        onMiddleClick: trackingEvents.itemOpen,
        onContextMenu: trackingEvents.itemOpen,
      }}
      eventPayload={{ other: { 'minditem_id': id, index, searchEngineVersion } }}
    >
      <div className='mind-item-card' data-testid='mind-item-card'>
        <TagContainer isNew={isNew} isReadOnly={isReadOnly} />
        <div className='mind-item-card__content'>
          <div className='mind-item-card__header'>
            <div className='mind-item-card__actions'>
              {!hideCollectionAction &&
              <WithTooltip text={t('Add to collection')} placement='left'>
                <ManageItemInCollectionsButton
                  documentId={id}
                  type='mindItem'
                  iconClassName='mind-item-card__actions__collection'
                  itemData={itemData}
                />
              </WithTooltip>
              }
              {!hidePinAction &&
              <WithTooltip text={isPinned ? t('Unpin') : t('Pin')} placement='left'>
                <PinItemButton
                  isPinned={isPinned}
                  documentId={id}
                  itemData={itemData}
                  type='mindItem'
                  iconClassName='mind-item-card__actions__pin'
                />
              </WithTooltip>
              }
            </div>
            <div className='mind-item-card__head'>
              <div className='mind-item-card__thumbnail-wrapper-mobile'>
                {fileType === 'video' &&
                  <PlayIcon
                    isSmall={true}
                    testId='video-icon-mobile'
                    className={classNames('mind-item-card__play-icon-mobile', { 'hide-on-hover': !!description })}
                  />
                }
                <CardThumbnail
                  thumbnailUrl={thumbnail}
                  className='mind-item-card__thumbnail-mobile'
                />
              </div>
              <div className='mind-item-card__title font-weight-bold'>
                <Truncate text={title} lines={isCoarsePointer ? 5 : 3} />
              </div>
            </div>
          </div>
          <div className='mind-item-card__body'>
            {fileType === 'video' &&
              <PlayIcon
                testId='video-icon-desktop'
                className={classNames('mind-item-card__play-icon-desktop', { 'hide-on-hover': !!description })}
              />
            }
            <CardThumbnail
              thumbnailUrl={thumbnail}
              className={classNames(
                'mind-item-card__thumbnail',
                { 'hide-on-hover': !!description }
              )}
            />
            {description && 
          <CardDescription
            className={classNames({ 'show-on-hover': !isCoarsePointer })}
            description={description}
            numLines={isCoarsePointer ? 5 : 7}
          />}
          </div>
          <div className='mind-item-card__footer'>
            <ItemCardFooter
              publisher={publisher || ''}
              subject={subjects[0]}
              classYears={classYears}
            />
          </div>
        </div>
      </div>
    </WithTracking>
  );
};

export default React.memo(MindItemCard);
