import React, { useState } from 'react';
import TrialCancellationModal from '../components/modals/TrialCancellationModal';

type ShowFunc = () => void;
const noop = () => undefined;

interface Context {
  isOpen: boolean;
  show: ShowFunc;
}

const defaultContext: Context = {
  isOpen: false,
  show: noop as ShowFunc,
};

const TrialCancellationModalContext = React.createContext(defaultContext);

const TrialCancellationModalProvider: React.FC = (props) => {

  const [isOpen, setIsOpen] = useState(false);

  return (
    <TrialCancellationModalContext.Provider value={{
      show: () => setIsOpen(true),
      isOpen,
    }}>
      {isOpen && <TrialCancellationModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />}
      {props.children}
    </TrialCancellationModalContext.Provider>
  );
};

export { TrialCancellationModalContext, TrialCancellationModalProvider };
