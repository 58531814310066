import React from 'react';
import classnames from 'classnames';
import './navigationLayoutMobile.scss';

interface NavigationLayoutMobileProps {
  head: React.ReactNode;
  main: React.ReactNode;
  hideHeadAndActions?: boolean;
  actions?: React.ReactNode;
  showSidebarRight?: boolean;
  sidebarRight?: React.ReactNode;
}

const NavigationLayoutMobile: React.FC<NavigationLayoutMobileProps> = (props) => {
  const { 
    head, 
    main, 
    hideHeadAndActions, 
    actions, 
    showSidebarRight,
    sidebarRight
  } = props;  

  return (
    <div className='navigation-layout-mobile__wrapper'>
      <section className='navigation-layout-mobile__section'>
        <header
          className={
            classnames(
              'navigation-layout-mobile__header',
              { 'navigation-layout-mobile__header--hidden': hideHeadAndActions }
            )}
          data-testid='navigation-layout-mobile__header'
        >
          {head}
        </header>
        <div 
          className='navigation-layout-mobile__main' 
          data-testid='navigation-layout-mobile__main'
        >
          {main}
        </div>
        {
          actions && 
          <div role='menu' className={
            classnames(
              'navigation-layout-mobile__actions',
              { 'navigation-layout-mobile__actions--hidden': hideHeadAndActions }
            )}
          >
            {actions}
          </div>
        }
      </section>
      <aside className={
        classnames(
          'navigation-layout-mobile__sidebar',
          showSidebarRight && sidebarRight ? 
            'navigation-layout-mobile__sidebar--shown' 
            : 'navigation-layout-mobile__sidebar--hidden',
        )}
      >
        {sidebarRight}
      </aside>
    </div>
  );
};

export default NavigationLayoutMobile;
