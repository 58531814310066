import React from 'react';
import './navigationLayoutDesktop.scss';

interface NavigationLayoutDesktopProps {
  sidebarLeft: React.ReactNode;
  head: React.ReactNode;
  main: React.ReactNode;
  showSidebarRight?: boolean;
  sidebarRight?: React.ReactNode;
}

const NavigationLayoutDesktop: React.FC<NavigationLayoutDesktopProps> = (props) => {
  const { 
    sidebarLeft, 
    head, 
    main, 
    showSidebarRight, 
    sidebarRight 
  } = props;  

  return (
    <div className='navigation-layout'>
      <aside 
        className='navigation-layout__sidebar--left' 
        data-testid='navigation-layout__sidebar--left'
      >
        {sidebarLeft}
      </aside>

      <header
        className='navigation-layout__head'
        data-testid='navigation-layout__head'
      >
        {head}
      </header>

      <div className='navigation-layout__body'>
        <div 
          className='navigation-layout__content' 
          data-testid='navigation-layout__content'
        >
          {main}
        </div>

        {
          showSidebarRight && 
          sidebarRight &&
          <aside 
            className='navigation-layout__sidebar--right' 
            data-testid='navigation-layout__sidebar--right'
          >
            {sidebarRight}
          </aside>
        }

      </div>
    </div>
  );
};

export default NavigationLayoutDesktop;
