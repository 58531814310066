import React, { ReactElement } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import MuLogoSvg from '../../assets/MuLogo.svg';
import { externalLinks } from '../../constants';
import GradientModalAppLayout from '../AppLayout/GradientModalLayout';
import { TFunction } from 'i18next';

interface LoginGradientAppLayoutProps {
  title?: string;
  footer?: ReactElement;
}

const defaultFooter = (t: TFunction) => (
  <>
    <a
      className='link--white'
      href={externalLinks.dataPolicy}
      target='_blank'
      rel='noopener noreferrer'>
      {t('Data Policy')}
    </a>
    <span className='mx-2'>&bull;</span>
    <a
      className='link--white'
      href={externalLinks.tac}
      target='_blank'
      rel='noopener noreferrer'>
      {t('Terms and Conditions')}
    </a>
  </>
);

const LoginGradientAppLayout: React.FC<LoginGradientAppLayoutProps> = ({
  title,
  footer,
  children
}) => {
  const { t } = useTranslation();
  return (
    <GradientModalAppLayout
      className='login color--grey-medium p-4'
      footer={footer ? footer : defaultFooter(t)}
    >
      <Row className='h-100'>
        <Col className='d-flex flex-column'>
          <Row>
            <Col>
              <MuLogoSvg className='mb-4 fill--mu-blue w-100 mu-logo' />
              {title && <h3 className='text-center'>{t(title)}</h3>}
            </Col>
          </Row>
          {children}
        </Col>
      </Row>
    </GradientModalAppLayout>
  );
};

export default LoginGradientAppLayout;
