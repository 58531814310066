import { AuthLoginResponseData } from '@klab-berlin/api-sdk/lib/types/responses/Auth';
import CookiesJS from 'js-cookie';
import config from 'config';

const DOMAIN = config.cookies.domain;
const AUTH_TOKEN_COOKIE = `${DOMAIN}_authTokenData`;
const REFRESH_TOKEN_COOKIE = `${DOMAIN}_authRefreshTokenData`;
const VIEW_MODE_COOKIE = `${DOMAIN}_muViewModeData`;
const NEW_DESIGN_PREFERENCE_COOKIE = `${DOMAIN}_newDesignPreferenceData`;
const NEW_PIN_ONBOARDING_COMPONENT_COOKIE = `${DOMAIN}_newPinOnboardingComponentData`;
const NEW_UI_ONBOARDING_COMPONENT_COOKIE = `${DOMAIN}_newUIOnboardingComponentData`;
const IS_COOKIE_SECURE = window.location.protocol.startsWith('https');
const SAME_SITE = IS_COOKIE_SECURE ? 'none' as const : undefined;
const DEFAULT_COOKIE_OPTIONS = {
  secure: IS_COOKIE_SECURE,
  sameSite: SAME_SITE,
};
const ESSENTIAL_COOKIES = [AUTH_TOKEN_COOKIE, REFRESH_TOKEN_COOKIE];

const cookiesJS = CookiesJS.withConverter({
  read: (value) => {
    return decodeURIComponent(value);
  },
  write: (value) => {
    if (typeof value !== 'string') {
      return encodeURIComponent(JSON.stringify(value));
    }
    return encodeURIComponent(value);
  },
});

interface Cookies {
  deleteAuthToken: () => void;
  getAuthToken: () => string;
  getRefreshToken: () => string;
  getViewMode: () => 'list' | 'grid';
  getNewDesignPreference: () => boolean;
  getNewPinOnboardingComponent: () => string;
  getNewUIOnboardingComponent: () => string;
  isAuthTokenValid: () => boolean;
  setAuthToken: (tokenData: AuthLoginResponseData) => void;
  setViewMode: (viewMode: 'list' | 'grid') => void;
  setNewDesignPreference: (newDesignPreference: boolean) => void;
  setNewPinOnboardingComponent: (state: string) => void;
  setNewUIOnboardingComponent: (state: string) => void;
  getAllCookies: () => {[key: string]: string;};
  deleteNonEssential: () => void;
}

const cookies: Cookies = {
  deleteAuthToken: () => {
    cookiesJS.remove(AUTH_TOKEN_COOKIE, { domain: DOMAIN });
    cookiesJS.remove(REFRESH_TOKEN_COOKIE, { domain: DOMAIN });
  },
  getAuthToken: () => {
    const tokenData: AuthLoginResponseData = cookiesJS.getJSON(AUTH_TOKEN_COOKIE);
    return tokenData && tokenData.token;
  },
  getRefreshToken: () => {
    return cookiesJS.getJSON(REFRESH_TOKEN_COOKIE);
  },
  getViewMode: () => {
    return (cookiesJS.getJSON(VIEW_MODE_COOKIE) || {}).viewMode === 0 ? 'grid' : 'list';
  },
  getNewDesignPreference: () => {
    return (cookiesJS.getJSON(NEW_DESIGN_PREFERENCE_COOKIE) || {}).newDesignPreference || false;
  },
  getNewPinOnboardingComponent: () => {
    return (cookiesJS.getJSON(NEW_PIN_ONBOARDING_COMPONENT_COOKIE) || {}).state || 'hidden';
  },
  getNewUIOnboardingComponent: () => {
    return (cookiesJS.getJSON(NEW_UI_ONBOARDING_COMPONENT_COOKIE) || {}).state || 'hidden';
  },
  isAuthTokenValid: () => {
    return cookies.getAuthToken() ? true : false;
  },
  setAuthToken: (tokenData: AuthLoginResponseData) => {
    const { expiresIn, refreshToken, refreshTokenExpiresIn } = tokenData;
    const expiresInDays = expiresIn / 1000 / 60 / 60 / 24;
    const refreshTokenExpiresInDays = refreshTokenExpiresIn / 1000 / 60 / 60 / 24;
    const authCookieOptions = { ...DEFAULT_COOKIE_OPTIONS, domain: DOMAIN, expires: expiresInDays };
    const refreshCookieOptions = { ...DEFAULT_COOKIE_OPTIONS, domain: DOMAIN, expires: refreshTokenExpiresInDays };

    cookiesJS.set(AUTH_TOKEN_COOKIE, tokenData, authCookieOptions);
    cookiesJS.set(REFRESH_TOKEN_COOKIE, refreshToken, refreshCookieOptions);
  },
  setNewDesignPreference: (newDesignPreference) => {
    const cookieData = { newDesignPreference };
    const cookieOptions = { ...DEFAULT_COOKIE_OPTIONS, domain: DOMAIN, expires: new Date('2024-12-31') };
    cookiesJS.set(NEW_DESIGN_PREFERENCE_COOKIE, cookieData, cookieOptions);
  },
  setNewUIOnboardingComponent: (state) => {
    const cookieData = { state };
    const cookieOptions = { ...DEFAULT_COOKIE_OPTIONS, domain: DOMAIN, expires: new Date('2024-12-31') };
    cookiesJS.set(NEW_UI_ONBOARDING_COMPONENT_COOKIE, cookieData, cookieOptions);
  },
  setNewPinOnboardingComponent: (state) => {
    const cookieData = { state };
    const cookieOptions = { ...DEFAULT_COOKIE_OPTIONS, domain: DOMAIN, expires: new Date('2024-12-31') };
    cookiesJS.set(NEW_PIN_ONBOARDING_COMPONENT_COOKIE, cookieData, cookieOptions);
  },
  setViewMode: (viewMode) => {
    const cookieData = { viewMode: viewMode === 'list' ? 1 : 0 };
    const cookieOptions = { ...DEFAULT_COOKIE_OPTIONS, domain: DOMAIN };
    cookiesJS.set(VIEW_MODE_COOKIE, cookieData, cookieOptions);
  },
  getAllCookies: () => {
    return cookiesJS.get();
  },
  deleteNonEssential: () => {
    const currentCookies = cookiesJS.get();

    Object.keys(currentCookies).forEach(cookieName => {
      if (!ESSENTIAL_COOKIES.includes(cookieName)) {
        cookiesJS.remove(cookieName, { domain: DOMAIN });
      }
    });
  },
};

export default cookies;
