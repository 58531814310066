import add from '../assets/lernsets/icons/add.svg';
import alleTab from '../assets/lernsets/icons/alleTab.svg';
import alleTabBold from '../assets/lernsets/icons/alleTab-Bold.svg';
import arrowDropdownFilled from '../assets/lernsets/icons/arrow-dropdown-filled.svg';
import arrowDown from '../assets/lernsets/icons/arrow-down.svg';
import arrowUp from '../assets/lernsets/icons/arrow-up.svg';
import book from '../assets/lernsets/icons/book.svg';
import bookmark from '../assets/lernsets/icons/bookmark.svg';
import bookmarkAdded from '../assets/lernsets/icons/bookmark-added.svg';
import calendar from '../assets/lernsets/icons/calendar.svg';
import checkbox from '../assets/lernsets/icons/checkbox.svg';
import cog from '../assets/lernsets/icons/cog.svg';
import copy from '../assets/lernsets/icons/copy.svg';
import folderAdd from '../assets/lernsets/icons/folder-add.svg';
import folderAddPurple from '../assets/lernsets/icons/folder-add-purple.svg';
import folderRemove from '../assets/lernsets/icons/folder-remove.svg';
import folderRemovePurple from '../assets/lernsets/icons/folder-remove-purple.svg';
import invalidLink from '../assets/lernsets/icons/invalid-url.svg';
import mindItemTab from '../assets/lernsets/icons/mindItemTab.svg';
import mindItemTabBold from '../assets/lernsets/icons/mindItemTab-Bold.svg';
import notes from '../assets/lernsets/icons/notes.svg';
import paperclip from '../assets/lernsets/icons/paperclip.svg';
import play from '../assets/lernsets/icons/play.svg';
import printer from '../assets/lernsets/icons/printer.svg';
import productTab from '../assets/lernsets/icons/productTab.svg';
import productTabBold from '../assets/lernsets/icons/productTab-Bold.svg';
import redTrash from '../assets/lernsets/icons/redTrash.svg';
import selectedCheckbox from '../assets/lernsets/icons/selectedCheckbox.svg';
import share from '../assets/share.svg';
import sort from '../assets/lernsets/icons/sort.svg';
import star from '../assets/lernsets/icons/star.svg';
import starBold from '../assets/lernsets/icons/boldStar.svg';
import starFilled from '../assets/lernsets/icons/star-filled.svg';
import starFilledPurple from '../assets/lernsets/icons/star-filled-purple.svg';
import starPurple from '../assets/lernsets/icons/star-purple.svg';
import tag from '../assets/lernsets/icons/tag.svg';
import teacher from '../assets/lernsets/icons/teacher.svg';
import trash from '../assets/lernsets/icons/trash.svg';
import userTesting from '../assets/lernsets/icons/user-testing.svg';
import videoTab from '../assets/lernsets/icons/videoTab.svg';
import videoTabBold from '../assets/lernsets/icons/videoTab-Bold.svg';
import zeroResultsMobile from '../assets/lernsets/icons/zero-results-mobile.svg';
import zeroResultsTablet from '../assets/lernsets/icons/zero-results-tablet.svg';

const icons = {
  add,
  alleTab,
  alleTabBold,
  arrowDown,
  arrowDropdownFilled,
  arrowUp,
  book,
  bookmark,
  bookmarkAdded,
  calendar,
  checkbox,
  cog,
  copy,
  folderAdd,
  folderAddPurple,
  folderRemove,
  folderRemovePurple,
  invalidLink,
  mindItemTab,
  mindItemTabBold,
  notes,
  paperclip,
  play,
  printer,
  productTab,
  productTabBold,
  redTrash,
  selectedCheckbox,
  share,
  sort,
  star,
  starBold,
  starFilled,
  starFilledPurple,
  starPurple,
  tag,
  teacher,
  trash,
  userTesting,
  videoTab,
  videoTabBold,
  zeroResultsMobile,
  zeroResultsTablet,
};

export type IconName =
  'add' | 'alleTab' | 'alleTabBold' | 'arrowDown' | 'arrowDropdownFilled' | 'arrowUp' | 'book' | 'bookmark' | 
  'bookmarkAdded' | 'calendar' | 'checkbox' | 'cog' | 'copy' | 'folderAdd' | 'folderAddPurple' | 'folderRemove' | 
  'folderRemovePurple' | 'invalidLink' | 'mindItemTab' | 'mindItemTabBold' | 'notes' | 'paperclip' | 'play' | 
  'printer' | 'productTab' | 'productTabBold' | 'redTrash' | 'selectedCheckbox' | 'share' | 'sort' | 'star' |
  'starBold' | 'starFilled' | 'starFilledPurple' | 'starPurple' | 'tag' | 'teacher' | 'trash' | 'userTesting' |
  'videoTab' | 'videoTabBold' | 'zeroResultsMobile' | 'zeroResultsTablet';

const getMuiIcon = (icon: IconName) => icons[icon];

export default getMuiIcon;
