import React, { ReactNode } from 'react';
import { Button, Modal as BootstrapModal } from 'react-bootstrap';
import Icon from '../../Icon';
import './modal.scss';

interface ModalProps {
  className?: string;
  show: boolean;
  onClose?: () => void;
  title?: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
}

const Modal: React.FC<ModalProps> = ({
  className,
  title,
  body,
  footer,
  onClose,
  show
}) => {
  return <BootstrapModal onHide={onClose} show={show} className={className}>
    <div className='mu-modal'>
      {title &&
        <BootstrapModal.Header
          className='mu-modal__header'
          data-testid='mu-modal__header'
        >
          <div className='mu-modal__header__content'>
            <BootstrapModal.Title className='mu-modal__header__title'>
              {title}
            </BootstrapModal.Title>
            <Button
              className='mu-modal__header__close'
              variant='light'
              onClick={onClose}
            >
              <Icon icon='cancel' size='lg' />
            </Button>
          </div>
        </BootstrapModal.Header>
      }
      {
        body &&
        <BootstrapModal.Body
          className='mu-modal__body'
          data-testid='mu-modal__body'
        >
          {body}
        </BootstrapModal.Body>
      }
      {
        footer &&
        <BootstrapModal.Footer
          className='mu-modal__footer'
          data-testid='mu-modal__footer'
        >
          {footer}
        </BootstrapModal.Footer>
      }
    </div>
  </BootstrapModal>;
};

export default Modal;
