import { ADD_ERROR, DISMISS_ERROR } from './errors.actionTypes';

let errorId = 0;

export function addError(message: string, additionalData?: any) {
  return {
    payload: {
      additionalData,
      id: errorId++,
      message,
      time: (new Date()).toLocaleTimeString(),
    },
    type: ADD_ERROR,
  };
}

export function dismissError(id: number) {
  return {
    payload: { id },
    type: DISMISS_ERROR,
  };
}
