import Acker from './acker.png';
import AoGrenzen from './aog.png';
import Auer from './auer.png';
import Aulis from './aulis.png';
import Bange from './bange.png';
import Betzold from './betzold.png';
import CarlAuer from './carl-auer.png';
import DFS from './dfs.png';
import dpaInfografik from './dpa.png';
import Edeos from './edeos.png';
import elkVerlag from './elk-verlag.png';
import eDidact from './edidact.png';
import Freyspiel from './schoolgames.png';
import Friedrich from './friedrich.png';
import FSelbstkontrolleM from './fsm.png';
import Herolé from './herole.png';
import klettLerntraining from './klett-lerntraining.png';
import klettKallmeyer from './klett-kallmeyer.png';
import KlettMEX from './klettmex.png';
import Klinkhardt from './klinkhardt.png';
import Klippert from './klippert.png';
import Langenscheidt from './langenscheidt.png';
import Lernbiene from './lernbiene.png';
import LetsMINT from './lets-mint.png';
import Medienblau from './on-interaktiv.png';
import MedienLB from './medienlb.png';
import meinUnterricht from './mein-unterricht.png';
import Mildenberger from './mildenberger.png';
import Oxfam from './oxfam.png';
import Persen from './persen.png';
import pictureAlliance from './pa.png';
import PONS from './pons.png';
import Raabe from './raabe.png';
import SchilfAkademie from './schilf-akademie.png';
import Schulfilme from './schulfilme.png';
import scolix from './scolix.png';
import Studyflix from './studyflix.png';
import TerraX from './terraX.png';
import UNICEF from './unicef.png';
import UTB from './utb.png';
import VuR from './vur.png';
import Welttierschutz from './wtg.png';
import wheelmap from './wheelmap.png';
import YAEZ from './yaez.png';

const publisher = {
  Acker,
  AoGrenzen,
  Auer,
  Aulis,
  Bange,
  Betzold,
  'Carl-Auer': CarlAuer,
  DFS,
  'dpa-infografik': dpaInfografik,
  Edeos,
  'elk Verlag': elkVerlag,
  eDidact,
  EKS: klettLerntraining,
  Freyspiel,
  Friedrich,
  FSelbstkontrolleM,
  Herolé,
  'JPP Medien': Schulfilme,
  'Klett Lerntraining': klettLerntraining,
  KlettMEX,
  'Klett Kallmeyer': klettKallmeyer,
  Klinkhardt,
  Klippert,
  Langenscheidt,
  Lernbiene,
  LetsMINT,
  Medienblau,
  MedienLB,
  meinUnterricht,
  MGO: eDidact,
  Mildenberger,
  Oxfam,
  Persen,
  'picture-alliance': pictureAlliance,
  PONS,
  Raabe,
  SchilfAkademie,
  scolix,
  Studyflix,
  TerraX,
  UNICEF,
  UTB,
  VuR,
  Welttierschutz,
  wheelmap,
  YAEZ,
};

export default publisher;
