import { ThunkAction, AsyncActionStatus } from './common.actionTypes';
import userService from '../services/user.service';

import {
  FETCH_DOWNLOAD_CONTENTS,
  FetchDownloadContentsAction,
  STORE_DOWNLOADED_ITEM,
  StoreDownloadedItemAction,
} from './download.actionTypes';

export function fetchDownloadContents(): ThunkAction<FetchDownloadContentsAction> {
  return async (dispatch, getState) => {
    const state = getState();
    if (state.download.status === AsyncActionStatus.READY) {
      return;
    }
    else {
      dispatch({ type: FETCH_DOWNLOAD_CONTENTS.START });
      // TODO: FD-893 remove hard limit, part of a quick fix, FD-900
      return userService.getDownloadContents({
        limit: 250,
        version: 2,
        sortOrder: 'desc'
      })
        .then((result) => dispatch({ type: FETCH_DOWNLOAD_CONTENTS.SUCCESS, result }))
        .catch(error => dispatch({ error, type: FETCH_DOWNLOAD_CONTENTS.ERROR }));
    }
  };
}

export function storeDownloadedItem(id: string): ThunkAction<StoreDownloadedItemAction> {
  return async (dispatch) => {
    dispatch({ type: STORE_DOWNLOADED_ITEM, payload: { id } });
  };
}
