import React from 'react';
import { useTranslation } from 'react-i18next';
import PersonalDetailsSection from '../Common/PersonalDetailsSection';
import InvoiceAddressForm from './InvoiceAddressForm';
import InvoiceAddressView from './InvoiceAddressView';

const InvoiceAddress: React.FC = () => {

  const { t } = useTranslation();
  
  return (
    <div data-testid='invoice-address-section'>
      <PersonalDetailsSection
        title={t('Invoice address')}
        renderNormalView={() => <InvoiceAddressView />}
        renderFormView={(closeEditMode) => 
          <InvoiceAddressForm onCancel={closeEditMode} onSave={closeEditMode}/>
        }
      />
    </div>
  );
};

export default InvoiceAddress;
