import classNames from 'classnames';
import React from 'react';
import './personalDetailsInputGroup.scss';

interface PersonalDetailsInputGroupProps {
  fullWidthItems?: boolean;
}

const PersonalDetailsInputGroup: React.FC<PersonalDetailsInputGroupProps> = ((props) => {

  return (
    <div className='personal-details-input-group'>
      {React.Children.toArray(props.children).filter(child => child).map((child, i) => {
        return (
          <div className={classNames('personal-details-input-group__item', {
            'personal-details-input-group__full-width-item': props.fullWidthItems,
          })} key={i}>
            {child}
          </div>
        );
      })}
    </div>
  );
});

export default PersonalDetailsInputGroup;
