import immutable from 'object-path-immutable';
import { 
  ADD_LERNSET, 
  ADD_PAGES_LERNSET, 
  AddLernsetAction, 
  AddPagesLernsetAction, 
  DELETE_LERNSET, 
  GET_LERNSETS, 
  GetLernsetsAction,
  DeleteLernsetAction,
  UPDATE_LERNSET,
  UpdateLernsetAction,
  DuplicateLernsetAction,
  DUPLICATE_LERNSET,
  FetchPartImagesAction,
  FETCH_PART_IMAGES,
  SetLastAnnotatedPartIdAction,
  SetLastAnnotatedPartIdActionType,
  ClearPartsImagesAction,
  ClearPartsImagesActionType,
  ADD_BLANK_PAGE,
  AddBlankPageAction,
  ADD_RICH_TEXT_PART,
  AddRichTextPartAction,
} from '../actions/lernsets.actionTypes';
import { Lernset as OrgLernset, Part as OrgPart, PartsImages } from '@klab-berlin/api-sdk/lib/types/responses/Lernsets';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import { arrayToObject } from '../utils/arrayToObject';
import { 
  DeleteLernsetPartRequest,
  MovePageRequest, 
  UpdateTitleRequest 
} from '@klab-berlin/api-sdk/lib/types/requests/Lernsets';
import _ from 'lodash';
import { byteArrayToObjectUrl } from '../utils/byteArrayToObjectUrl';

export interface Part extends OrgPart { imageUrl?: string, content?: string; }
export interface Lernset extends OrgLernset { parts: Part[]; }

export interface LernsetsState {
  byId: {
    [key: string]: Lernset;
  };
  lastAnnotatedPartId?: string;
  status: AsyncActionStatus;
}

const initialState: LernsetsState = {
  byId: {},
  status: AsyncActionStatus.INITIAL,
};

type LernsetsActionTypes = GetLernsetsAction
  | AddLernsetAction
  | AddPagesLernsetAction
  | DeleteLernsetAction
  | DuplicateLernsetAction
  | UpdateLernsetAction
  | FetchPartImagesAction
  | SetLastAnnotatedPartIdAction
  | ClearPartsImagesAction
  | AddBlankPageAction
  | AddRichTextPartAction;

const updateTitle = (lernset: Lernset, action: { payload: UpdateTitleRequest}): Lernset => {
  return {
    ...lernset,
    title: action.payload.title || lernset.title,
  };
};

const movePart = (lernset: Lernset, action: { payload: MovePageRequest }): Lernset => {
  const parts = lernset.parts;
  const positionDelta = action.payload.direction === 'up' ? -1 : 1;

  const srcIndex = parts.findIndex(part => part.id === action.payload.partId);
  if (srcIndex < 0) return lernset;

  const dstIndex = srcIndex + positionDelta;

  if (dstIndex < 0 || dstIndex >= parts.length) return lernset;

  const newParts = [...parts];
  [newParts[srcIndex], newParts[dstIndex]] = [newParts[dstIndex], newParts[srcIndex]];

  return {
    ...lernset,
    parts: newParts,
  };
};

const deleteLernsetPart = (lernset: Lernset, action: { payload: DeleteLernsetPartRequest }): Lernset => {
  const parts = lernset.parts.filter(part => part.id !== action.payload.partId);
  return {
    ...lernset,
    parts,
  };
};

const duplicateLernsetPart = (lernset: Lernset, partId: string, duplicatedPartId: string) => {
  const parts = _.cloneDeep(lernset.parts);

  const partIndex = parts.findIndex(part => part.id === partId);
  const duplicatedPart = _.cloneDeep(parts[partIndex]);

  if (!duplicatedPart || partIndex === -1 || !duplicatedPartId) return lernset;

  duplicatedPart.id = duplicatedPartId;

  parts.splice(partIndex + 1, 0, duplicatedPart);
  
  return {
    ...lernset,
    parts,
    updateDate: new Date().toISOString()
  };
};

const clearPartsImagesFromLernset = (lernset: Lernset) => {
  lernset.parts.forEach(part => {
    if (part.imageUrl) {
      URL.revokeObjectURL(part.imageUrl);
      delete part.imageUrl;
    }
  });
};

const addPartsImagesToLernset = (lernset: Lernset, partsImages: PartsImages ) => {
  const parts = lernset.parts.map(part => {
    const partImage = partsImages[part.id];
    if (partImage) {
      const imageUrl = byteArrayToObjectUrl(partImage.data, 'image/webp');
      return { ...part, imageUrl: imageUrl };
    }
    return part;
  });

  return { ...lernset, parts };
};

const lernsets = (
  state = initialState,
  action: LernsetsActionTypes
) => {
  switch (action.type) {
    case GET_LERNSETS.START:
      return immutable(state)
        .set('status', AsyncActionStatus.LOADING)
        .value();

    case GET_LERNSETS.ERROR:
      return immutable(state)
        .set('status', AsyncActionStatus.ERROR)
        .value();

    case GET_LERNSETS.SUCCESS:
      return immutable(state)
        .set('byId', arrayToObject(action.result, lernsets => lernsets.id))
        .set('status', AsyncActionStatus.READY)
        .value();

    case ADD_LERNSET.SUCCESS:
      return immutable(state)
        .set('byId', { ...state.byId, [action.result.id]: action.result })
        .set('status', AsyncActionStatus.READY)
        .value();

    case ADD_PAGES_LERNSET.SUCCESS:
      return immutable(state)
        .set('byId', { ...state.byId, [action.result.id]: action.result })
        .set('status', AsyncActionStatus.READY)
        .value();

    case SetLastAnnotatedPartIdActionType:
      return immutable(state)
        .set('lastAnnotatedPartId', action.payload.partId)
        .value();
    
    case ClearPartsImagesActionType: {
      const byId = { ...state.byId };
      Object.keys(byId).forEach(lernsetId => {
        clearPartsImagesFromLernset(byId[lernsetId]);
      });

      return immutable(state)
        .set('byId', byId)
        .value();
    }

    case FETCH_PART_IMAGES.SUCCESS: {
      const lernset = state.byId[action.payload.lernsetId];
      const partsImages = action.result;

      const updatedLernset = addPartsImagesToLernset(lernset, partsImages);

      return immutable(state)
        .set('byId', { ...state.byId, [updatedLernset.id]: updatedLernset })
        .set('status', AsyncActionStatus.READY)
        .value();
    }

    case DELETE_LERNSET.SUCCESS: {
      const byId = { ...state.byId };
      delete byId[action.result.id];
      return immutable(state)
        .set('byId', byId)
        .set('status', AsyncActionStatus.READY)
        .value();
    }

    case DUPLICATE_LERNSET.SUCCESS: {
      const lernsetId = action.payload.lernsetId;
      const lernset = state.byId[lernsetId];
      const orgPartId = action.payload.partId;
      const { duplicatedLernsetPartId } = action.result;
      const updatedLernset = duplicateLernsetPart(lernset, orgPartId, duplicatedLernsetPartId);

      const byId = { ...state.byId, [lernsetId]: updatedLernset };

      return immutable(state)
        .set('byId', byId)
        .set('status', AsyncActionStatus.READY)
        .value();
    }

    case UPDATE_LERNSET.SUCCESS: {
      const lernset = state.byId[action.payload.id];
      let updatedLernset = state.byId[action.payload.id];

      if (action.payload.action === 'updateTitle') {
        const updateTitleAction = { payload: action.payload as UpdateTitleRequest };
        updatedLernset = updateTitle(lernset, updateTitleAction);
      } else if (action.payload.action === 'movePart'){ 
        const movePartAction = { payload: action.payload as MovePageRequest };
        updatedLernset = movePart(lernset,  movePartAction);
      } else if (action.payload.action === 'deletePart') {
        const deletePartAction = { payload: action.payload as DeleteLernsetPartRequest };
        updatedLernset = deleteLernsetPart(lernset, deletePartAction);
      }
 
      updatedLernset.updatedDate = new Date().toISOString();

      return immutable(state)
        .set('byId', { ...state.byId, [action.payload.id]: updatedLernset })
        .set('status', AsyncActionStatus.READY)
        .value();
    }

    case ADD_BLANK_PAGE.SUCCESS: {
      const lernsetId = action.payload.lernsetId;
      const lernset = state.byId[lernsetId];
      const { newPartId, imageUrl } = action.result;
      const partIndex = action.payload.partIndex;
      const parts = _.cloneDeep(lernset.parts);

      parts.splice(partIndex, 0, { id: newPartId, type: 'pdf', imageUrl });
      const updatedLernset = { ...lernset, parts, updateDate: new Date().toISOString() };
     
      return immutable(state)
        .set('byId', { ...state.byId, [lernsetId]: updatedLernset })
        .set('status', AsyncActionStatus.READY)
        .value();
    }

    case ADD_RICH_TEXT_PART.SUCCESS: {
      const lernsetId = action.payload.lernsetId;
      const lernset = state.byId[lernsetId];
      const { newPartId } = action.result;
      const partIndex = action.payload.partIndex;
      const parts = _.cloneDeep(lernset.parts);

      parts.splice(partIndex, 0, { id: newPartId, type: 'markup-text', content: '' });
      const updatedLernset = { ...lernset, parts, updatedDate: new Date().toISOString() };

      return immutable(state)
        .set('byId', { ...state.byId, [lernsetId]: updatedLernset })
        .set('status', AsyncActionStatus.READY)
        .value();
    }
  
    default:
      return state;
  }
};

export default lernsets;

