import React from 'react';
import Card from 'react-bootstrap/Card';
import FileItem from './FileItem';
import PdfItem from './PdfItem';
import ProductItem from './ProductItem';
import { ItemCardProps } from '../Item.props';

const getItemComponent = (props: ItemCardProps) => {
  if (props.item.type === 'product') {
    return <ProductItem {...props} />;
  }
  if (props.item.type === 'mindItem' && props.item.fileType === 'document') {
    return <PdfItem {...props} />;
  }
  return <FileItem {...props} item={props.item} />;
};

const GridItem: React.FC<ItemCardProps> = (props) => {
  return (
    <Card className='item item--card text-dark text-decoration-none'>
      {getItemComponent(props)}
    </Card>
  );
};

export default GridItem;
