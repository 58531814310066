import React from 'react';
import { toDate } from 'date-fns';
import { getDateInGermanFormat } from '../utils/format';

interface TimestampProps {
  value: string | number | Date | undefined;
}

const Timestamp: React.FC<TimestampProps> = ({ value }) => {
  if (!value) return null;
  const date = typeof value === 'string' ? 
    new Date(value).toDateString() : toDate(value).toDateString(); 
  const dateGermanFormat = getDateInGermanFormat(value);

  return <time dateTime={date}>{dateGermanFormat}</time>;
};

export default Timestamp;
