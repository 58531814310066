import React from 'react';
import './InputError.scss';

interface OwnProps {
  error?: string;
}

const InputError: React.FC<OwnProps> = ({ error }) => {
  return (
    <>
      { error && <div data-testid='input-error__message' className='input-error__message'>{error}</div> }
    </>
  );
};

export default InputError;
