import React, { useEffect } from 'react';
import GradientModalAppLayout from '../../components/AppLayout/GradientModalLayout';
import Button from 'react-bootstrap/Button';
import { routes } from '../../constants';
import bookstack from '../../assets/bookstack.png';
import { ButtonGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectUser } from '../../reducers/user.selectors';
import { openItem } from '../../utils';
import useRouter from '../../utils/useRouter';
import useAction from '../../utils/useAction';
import { updateMe as updateMeAction } from '../../actions/user.actions';
import { useTranslation } from 'react-i18next';
import { selectSchoolTypes } from '../../reducers/schoolTypes.selectors';

const CreateProfileFinishPage: React.FC = () => {

  const { router } = useRouter();
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const schoolTypes = useSelector(selectSchoolTypes);
  const updateMe = useAction(updateMeAction);

  // This effect is executed only once (at first rendering)
  useEffect(() => {
    if (!user) return;

    updateMe({
      data: {
        onboarding: {
          finished: true,
        },
      },
    });
  }, [user && user.id]);

  const searchMaterial = () => {
    if (!user) return;

    const { subjects, classes, schoolType: userSchoolType } = user;
    const { majors, minors } = subjects;

    const subject = (!!majors.length && majors[0].subject) || (!!minors.length && minors[0].subject);

    const schoolTypeSearchId = schoolTypes.find(schoolType => schoolType.profileId === userSchoolType)?.searchId;

    return router.replace({
      pathname: `/${routes.search.root}`,
      query: {
        minClassYear: String(Math.min(...(classes.length ? classes : [1]))),
        maxClassYear: String(Math.max(...(classes.length ? classes : [13]))),
        ...(!!subject) && { subject },
        ...(!!schoolTypeSearchId) && { schoolType: schoolTypeSearchId },
      }
    });
  };

  const toDocument = () => {
    if (!user || !user.redirectTo) {
      return;
    }
    openItem(router, user.redirectTo, 'mindItem');
  };

  return (
    <GradientModalAppLayout>
      <div className='bg-primary w-100 h-50 pt-5 px-5 d-flex flex-column justify-content-end'>
        <div className='w-100 d-flex flex-row justify-content-between'>
          <h1 className='text-white mt-5'>{t('You can now get going!')}</h1>
          <img src={bookstack} alt='' className='h-100' />
        </div>
      </div>
      <div className='m-auto align-middle'>
        <ButtonGroup className='w-100' vertical>
          {
            user && user.redirectTo
              ? (
                <Button
                  className='rounded-pill'
                  size='lg'
                  variant='success'
                  onClick={toDocument}
                >
                  {t('To Document')}
                </Button>
              )
              : (
                <Button
                  className='rounded-pill'
                  size='lg'
                  variant='success'
                  onClick={searchMaterial}
                >
                  {t('Search for material')}
                </Button>
              )
          }
        </ButtonGroup>
      </div>
    </GradientModalAppLayout>
  );
};

export default CreateProfileFinishPage;
