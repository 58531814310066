import React, { useEffect, useState } from 'react';
import './invoiceHistory.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getInvoices } from '../../../actions/user.actions';
import { selectUser, selectUserInvoiceHistory } from '../../../reducers/user.selectors';
import useAction from '../../../utils/useAction';
import initFileDownload from '../../utils/downloadIFrame';
import PersonalDetailsSection from '../Common/PersonalDetailsSection';
import ActionButton from '../../ActionButton';
import { format } from 'date-fns';
import de from 'date-fns/locale/de';
import useMedia from '../../../utils/useMedia';
import billingService from '../../../services/billing.service';
import { currencyFormat } from '../../../utils/format';
import classNames from 'classnames';
import { Invoice } from '@klab-berlin/api-sdk/lib/types/responses/Billing';
import { subscriptionDateFormat } from '../../../constants';

const InvoiceHistory: React.FC = () => {
  const getInvoicesAction = useAction(getInvoices);
  const user = useSelector(selectUser);
  const invoiceHistory = useSelector(selectUserInvoiceHistory);
  const [serverError, setServerError] = useState<Error | null>(null);
  const { t } = useTranslation();
  const { isMobile } = useMedia();

  useEffect(() => {
    if (user && !invoiceHistory) {
      getInvoicesAction();
    }
  }, [user]);

  const onClickDownload = async (invoiceId: string) => {
    if (!user) return;
    try {
      const invoiceLink = await billingService.getInvoiceDownloadLink(user.id, invoiceId);
      initFileDownload(invoiceLink.requestPath);
    } catch (error: any) {
      setServerError(error);
    }
  };

  // Relying on Zoura's invoice dates to be in format: yyyy-MM-dd
  const formatInvoiceDate = (invoiceDate: string) => {
    let newDate = '';
    try {
      newDate = format(
        new Date(invoiceDate),
        subscriptionDateFormat,
        { locale: de }
      );
    }
    catch {
      return '';
    }

    return newDate;
  };

  const invoicesToTableRows = (invoices: Invoice[]) => {
    const tableRows = invoices.map((invoice, index) => {
      if (!invoice.invoice_id || !invoice.invoice_date || !invoice.invoicesum) return;

      const cellClassName = index !== 0 ? 'invoice-history__table-cell'
        : 'invoice-history__table-cell--first-row';

      return <tr key={index}>
        <td className={cellClassName}>
          {formatInvoiceDate(invoice.invoice_date)}
        </td>
        {!isMobile &&
          <td className={cellClassName}>
            {currencyFormat(invoice.invoicesum)}
          </td>
        }
        <td className={classNames(cellClassName, 'invoice-history__download-cell')}>
          <ActionButton icon='download' size='m'
            onClick={() => onClickDownload(invoice.invoice_id)} />
        </td>
      </tr>;
    });

    return tableRows;
  };

  return (
    <div data-testid='invoice-history-section'>
      {invoiceHistory?.invoices && invoiceHistory?.invoices.length > 0 &&
        <PersonalDetailsSection title={t('Invoice history')}
          renderNormalView={() =>
            <div className='invoice-history'>
              {invoiceHistory &&
                <table className='invoice-history__table'>
                  <thead>
                    <tr>
                      <th>{t('Date')}</th>
                      {!isMobile && <th>{t('Amount')}</th>}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoicesToTableRows(invoiceHistory.invoices)}
                  </tbody>
                </table>
              }
              {serverError &&
                <div className='invoice-history__error'>
                  {t('Ups, something went wrong')}
                </div>
              }
            </div>
          }
        />
      }
    </div>
  );
};

export default InvoiceHistory;
