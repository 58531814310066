import { MindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import { AsyncAction } from './common.actionTypes';

export enum FETCH_MIND_ITEM {
  START = 'FETCH_MIND_ITEM_START',
  SUCCESS = 'FETCH_MIND_ITEM_SUCCESS',
  ERROR = 'FETCH_MIND_ITEM_ERROR',
}

export type FetchMindItemAction = AsyncAction<
  typeof FETCH_MIND_ITEM.START,
  typeof FETCH_MIND_ITEM.SUCCESS,
  typeof FETCH_MIND_ITEM.ERROR,
  MindItem,
  { mindItemId: string }
  >;

export enum FETCH_PUBLIC_MIND_ITEM {
  START = 'FETCH_PUBLIC_MIND_ITEM_START',
  SUCCESS = 'FETCH_PUBLIC_MIND_ITEM_SUCCESS',
  ERROR = 'FETCH_PUBLIC_MIND_ITEM_ERROR',
}

export type FetchPublicMindItemAction = AsyncAction<
  typeof FETCH_PUBLIC_MIND_ITEM.START,
  typeof FETCH_PUBLIC_MIND_ITEM.SUCCESS,
  typeof FETCH_PUBLIC_MIND_ITEM.ERROR,
  MindItem,
  { accessToken: string }
  >;

export enum FETCH_SIBLINGS {
  START = 'FETCH_SIBLINGS_START',
  SUCCESS = 'FETCH_SIBLINGS_SUCCESS',
  ERROR = 'FETCH_SIBLINGS_ERROR',
}

export type FetchSiblingsAction = AsyncAction<
  typeof FETCH_SIBLINGS.START,
  typeof FETCH_SIBLINGS.SUCCESS,
  typeof FETCH_SIBLINGS.ERROR,
  MindItem[],
  { mindItemId: string; productItemId: string }
  >;
