import React from 'react';
import ActionButton from '../ActionButton';
import { trackingEvents } from '../../services/tracking/trackConfig';
import { ItemVariation } from '@klab-berlin/api-sdk/lib/types/responses/Collection';
import services from '../../services';
import usePinAction from '../../utils/usePinAction';
import classnames from 'classnames';
import './pinItemButton.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from '../../reducers/user.selectors';
import { Product } from '@klab-berlin/api-sdk/lib/types/responses/Product';
import { MindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import { ContentFavoriteAdded, ContentFavoriteRemoved } from '../../ampli';
import { AmpliContentPayload, UIProperties } from '../../constants';
import * as ampliUtils from '../../utils/ampli';
import { useNewDesign } from '../../state/DesignPreferenceContextProvider';

interface PinItemButtonProps {
  documentId: string;
  type: ItemVariation;
  isPinned: boolean;
  iconClassName?: string;
  className?: string;
  labelledBy?: string;
  itemData?: Partial<MindItem> | Partial<Product>;
}

const PinItemButton: React.FC<PinItemButtonProps> = (props) => {
  const pinAction = usePinAction(props.documentId, props.isPinned, props.type);
  const user = useSelector(selectUser);
  const { prefersNewDesign, shouldShowDesignPreferenceSwitch } = useNewDesign();
  const { t } = useTranslation();

  const onClick = () => {
    let event;
    let eventPayload;
    let eventProperties: (AmpliContentPayload & UIProperties) | undefined;
    if (props.type === 'mindItem') {
      event = trackingEvents.mindItemPin;
      eventPayload = { 'minditem_id': props.documentId };
      eventProperties = props.itemData && ampliUtils.getAmpliContentEventPayload(props.itemData, 'mindItem');
    }

    if (props.type === 'product') {
      event = trackingEvents.productPin;
      eventPayload = { 'product_id': props.documentId };
      eventProperties = props.itemData && ampliUtils.getAmpliContentEventPayload(props.itemData as Product, 'product');
    }

    pinAction();

    if (event) {
      // Track intended pinned state
      services.track.eventTrack(event, { other: { ...eventPayload, isPinned: !props.isPinned } });
    }
    
    const ampliAction = !props.isPinned ? ContentFavoriteAdded : ContentFavoriteRemoved;   
    if (user && eventProperties) {
      if (shouldShowDesignPreferenceSwitch && !props.isPinned) {
        eventProperties.ui = ampliUtils.mapDesignPreference(prefersNewDesign);
      }
      services.track.ampliEventTrack({
        event: new ampliAction(eventProperties as any)
      });
    }
  };

  const iconClassName = classnames(
    props.iconClassName,
    props.isPinned && 'pin_item_button--active'
  );

  return (
    <ActionButton
      onClick={onClick}
      icon={props.isPinned ? 'star-solid' : 'star-outline'}
      iconClassName={iconClassName}
      className={props.className}
      testId={props.isPinned ? 'unpin-button' : 'pin-button'}
      label={props.isPinned ? t('Unpin') : t('Pin')}
      labelledBy={props.labelledBy}
    />
  );
};

export default PinItemButton;
