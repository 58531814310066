import { createSelector } from 'reselect';
import { StoreType } from './index';
import { getMindItems } from './mindItems.selectors';
import { getProducts } from './products.selectors';
import { getContent } from './selectorHelpers';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import { ContentItem } from '../types/AppContent';
import { PinnedContentArray } from './pinned.reducers';

const getPinnedContents = (state: StoreType): PinnedContentArray =>
  state.pinned.contents;

export const selectPinnedStatus = (state: StoreType): AsyncActionStatus => state.pinned.status;

export const selectPinnedContents = createSelector(
  [getPinnedContents, getMindItems, getProducts],
  (pinned, mindItems, products) =>
    pinned
      .map((i) => getContent(i.type, i.id, mindItems, products))
      .filter((v) => !!v) as ContentItem[]
);

export const selectPinnedCount = (state: StoreType) =>
  state.pinned.pagination.total < 0 &&
    state.pinned.status !== AsyncActionStatus.READY
    ? undefined
    : state.pinned.pagination.total;

