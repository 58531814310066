import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser, getSubscription } from '../../../reducers/user.selectors';
import { User } from '../../../types/User';
import { SubscriptionResponse } from '@klab-berlin/api-sdk/lib/types/responses/Billing';
import { currencyFormat } from '../../../utils/format';
import { format as formatDate } from 'date-fns';
import useMedia from '../../../utils/useMedia';
import de from 'date-fns/locale/de';
import LabeledText from '../../common/LabeledText';
import PersonalDetailsSection from '../Common/PersonalDetailsSection';
import PersonalDetailsInputGroup from '../PersonalDetailsInputGroup';
import './SubscriptionInformation.scss';
import PremiumUpgradeButton from '../../PremiumButtons/PremiumUpgradeButton';
import WhatIsPremiumButton from '../../PremiumButtons/WhatIsPremiumButton';
import Caption from '../../common/Caption';
import Button from '../../common/Button';
import { CancellationModalContext } from '../../../state/CancellationModalProvider';
import { TrialCancellationModalContext } from '../../../state/TrialCancellationModalProvider';
import { ampliConversionPath, emailAddresses, subscriptionDateFormat } from '../../../constants';
import services from '../../../services';
import { SubscriptionCancellationViewed, SubscriptionCancellationViewedProperties } from '../../../ampli';

const SubscriptionInformationSection: React.FC = () => {
  const user = useSelector(selectUser);
  const { isMobile } = useMedia();
  const subscription = useSelector(getSubscription);
  const { t } = useTranslation();
  const { show: openCancellationModal } = useContext(CancellationModalContext);
  const { show: openTrialCancellationModal } = useContext(TrialCancellationModalContext);

  if (!user) {
    return null;
  }

  const {
    discountText,
    discountMonths,
    endDate,
    endDateLabel,
    pauseEndDate,
    pauseStartDate,
    price,
    priceText,
    productName,
    reducedPrice,
    requestInformation,
    startDate,
    pausingInformation,
    suspensionMessage,
  } = getSubscriptionInformation(user, subscription, user.pauseExperimentStatus);

  const isSuspended = subscription?.status === 'Suspended' && !subscription.suspension?.isPause;
  const isPaused = subscription?.status === 'Suspended' && subscription.suspension?.isPause;

  const trackCancellationViewed = ({ trial }: SubscriptionCancellationViewedProperties) : void => {
    services.track.ampliEventTrack({ 
      event: new SubscriptionCancellationViewed({ trial })
    });
  };

  const onCancelButtonClick = () => {
    trackCancellationViewed({ trial: 'no' });
    openCancellationModal();
  };

  const onTrialCancelButtonClick = () => {
    trackCancellationViewed({ trial: 'yes' });
    openTrialCancellationModal();
  };

  const getCancellationButton = () => {
    if (!user.allowAutomaticCancellation) return null;
    if (user.isInTrial) {
      return <Button
        variant='subtle'
        onClick={onTrialCancelButtonClick}
        type='button'>
        {t('Cancel Trial Subscription')}
      </Button>;
    } else {
      return <Button
        variant='subtle'
        onClick={onCancelButtonClick}
        type='button'>
        {t('Cancel Subscription')}
      </Button>;
    }
  };

  return (
    <PersonalDetailsSection title={t('Subscription Information')}
      renderNormalView={() => (<>
        <PersonalDetailsInputGroup>
          <div>
            {productName && <LabeledText
              label={t('Subscription')}
              text={discountText ? `${t(productName)} — ${t('discount', { discountText })}` : t(productName)}
              id='name'
            />}
            {price && priceText && <div
              className='subscription-data__price'
              data-testid='subscription-data__price'>
              {t(priceText, { discountText, discountMonths, price, reducedPrice })}
            </div>}
            {pausingInformation && <Caption
              testId='subscription-data__info'
            >
              {t(pausingInformation, { pauseStartDate, pauseEndDate })}
            </Caption>}
            {requestInformation &&
              <Caption>
                <Trans i18nKey={requestInformation}>
                  Your request is being worked on. For questions you can contact
                  <a href={`mailto:${emailAddresses.support}`}>
                    {{ email: emailAddresses.support }}
                  </a>.
                </Trans>
              </Caption>
            }
          </div>
          {startDate && <LabeledText label={t('Start Date')} text={startDate} id='startDate' />}
          {endDate && <LabeledText label={t(endDateLabel)} text={endDate} id='endDate' />}
        </PersonalDetailsInputGroup>
        {suspensionMessage && <div className='subscription-data__suspension-message'>
          <Trans i18nKey={suspensionMessage}>
            <a href={`mailto:${emailAddresses.support}`}></a>
          </Trans>
        </div>}
        {!user.isPremium && !isPaused && !isSuspended &&
          <div
            className='subscription-data__upgrade-section'
            data-testid='subscription-data__upgrade-section'>
            <PremiumUpgradeButton
              variant='secondary'
              className='subscription-data__upgrade-button'
              conversionPath={ampliConversionPath.MyAccount} />
            <WhatIsPremiumButton
              variant={isMobile ? 'subtle' : 'ghost'}
              className='subscription-data__upgrade-info'
            />
          </div>}
        {getCancellationButton()}
      </>
      )}
    />
  );
};

const getSubscriptionInformation = (
  user: User,
  subscription?: SubscriptionResponse,
  pauseExperiment?: string
) => {
  let productName: string | undefined;
  let price: string | undefined;
  let priceText: string | undefined;
  let endDate: string | undefined;
  let startDate: string | undefined;
  let pauseStartDate: string | undefined;
  let pauseEndDate: string | undefined;
  let endDateLabel = 'Renewal Date';
  let pausingInformation: string | undefined;
  let requestInformation: string | undefined;
  let suspensionMessage: string | undefined;
  let discountText: string | undefined;
  let discountMonths: number | undefined;
  let reducedPrice: string | undefined;
  const pauseRequested = pauseExperiment === 'requested';

  // User Has Subscription
  if (subscription && subscription.products) {
    const isInTrial = subscription.trial?.isInTrial;
    const hasFollowingLicence = subscription.licence?.followingLicence !== null;
    const isCancelledSubscription = subscription.status === 'Cancelled';
    const isSuspendedSubscription = subscription.status === 'Suspended';
    const isScheduledToCancel = subscription.followingSubscription.isScheduledToCancel;
    const hasPromoCode = !!subscription.promoCode && !!subscription.discounts;

    if (user.isPremium) {
      const nonDiscountProducts = subscription.products.filter(product => !product.plan?.name.includes('PromoCode'));
      const lastProduct = nonDiscountProducts[nonDiscountProducts.length - 1];

      if (lastProduct.plan?.name) {
        productName = `${lastProduct.name}, ${lastProduct.plan.name}`;
      } else {
        productName = lastProduct.name;
      }

      price = currencyFormat(subscription.licence.pricePerMonth);
      priceText = 'price per month';
      endDate = subscription.endDate;

      const hasRenewal = !isCancelledSubscription && hasFollowingLicence;

      if (!hasRenewal && !isInTrial) {
        startDate = subscription.startDate;
        endDateLabel = 'End Date';
      }

      if (isScheduledToCancel) {
        endDateLabel = 'End Date';
        startDate = subscription.startDate;
        endDate = subscription.followingSubscription.endDate;
      }

      if (isInTrial) {
        endDate = subscription.trial?.endDate;
        endDateLabel = 'Trial End Date';
        priceText = isCancelledSubscription ? '' : 'price after trial';
      }

      if (hasPromoCode) {
        const firstDiscount = subscription.discounts[0];
        if (firstDiscount.discountPercentage) {
          discountText = `${firstDiscount.discountPercentage}%`;
        } else if (firstDiscount.discountAmount) {
          discountText = currencyFormat(firstDiscount.discountAmount);
        }
        if (firstDiscount.isFixedPeriod) {
          discountMonths = firstDiscount.upToPeriods;
          priceText = isInTrial
            ? isCancelledSubscription
              ? ''
              : `${priceText} after discount`
            : 'price after discount';
          reducedPrice = currencyFormat(subscription.reducedPrice);
        } else {
          price = currencyFormat(subscription.reducedPrice);
        }
      }

      // Pause approved but not in effect yet
      if (subscription.suspension && subscription.suspension.isPause) {
        pauseStartDate =
          formatDate(new Date(subscription.suspension.startDate), subscriptionDateFormat, { locale: de });
        pauseEndDate = formatDate(new Date(subscription.suspension.endDate), subscriptionDateFormat, { locale: de });
        pausingInformation = 'Your Subscription will be paused';
      }

      if (pauseRequested) {
        requestInformation = 'Your Pause request is being worked on';
      }
    }

    // Suspension in effect
    if (isSuspendedSubscription && !subscription.suspension?.isPause) {
      productName = 'Basis Product Suspended';
      suspensionMessage = 'Your premium-subscription is currently suspended';
    }

    // Pause in effect
    if (isSuspendedSubscription && subscription.suspension?.isPause) {
      productName = 'Basis Product Paused';
      suspensionMessage = 'Your premium-subscription is currently paused';
    }

  }

  // No subscription - normal basis
  else {
    productName = 'Basis Product';
  }

  if (startDate) {
    startDate = formatDate(new Date(startDate), subscriptionDateFormat, { locale: de });
  }

  if (endDate) {
    endDate = formatDate(new Date(endDate), subscriptionDateFormat, { locale: de });
  }

  return {
    discountText,
    discountMonths,
    endDate,
    endDateLabel,
    pauseEndDate,
    pauseStartDate,
    price,
    priceText,
    productName,
    reducedPrice,
    requestInformation,
    startDate,
    pausingInformation,
    suspensionMessage,
  };
};

export default SubscriptionInformationSection;
