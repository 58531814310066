import React from 'react';
import NewTag from './NewTag';
import ReadOnlyTag from './ReadOnlyTag';
import './tagContainer.scss';

interface TagContainerProps {
  isNew?: boolean;
  isReadOnly?: boolean;
}

const TagContainer: React.FC<TagContainerProps> = ({ isNew, isReadOnly }) => {
  return (
    <div className='tag-container' data-testid='tag-container'>
      { isNew && !isReadOnly && <NewTag /> }
      { isReadOnly && <ReadOnlyTag /> }
    </div>
  );
};

export default TagContainer;
