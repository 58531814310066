import React from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../reducers/user.selectors';
import SepaSVG from '../../../assets/payment_sepa.svg';
import PaypalSVG from '../../../assets/payment_paypal.svg';
import CreditCardSVG from '../../../assets/payment_creditcard.svg';
import LabeledText from '../../common/LabeledText';
import { useTranslation } from 'react-i18next';
import { paymentMethods } from '../../../constants';
import './personalDetailsPaymentMethodView.scss';
import PersonalDetailsInputGroup from '../PersonalDetailsInputGroup';
import useMedia from '../../../utils/useMedia';

const PersonalDetailsPaymentMethodView: React.FC = () => {
  const user = useSelector(selectUser);
  const { t } = useTranslation();
  const { isDesktop } = useMedia();

  const paymentMethod = user?.paymentMethod;

  if (!paymentMethod) {
    return <></>;
  }

  const getPaymentMethodText = () => {
    if (paymentMethod.type === 'sepa') {
      return paymentMethod.sepa.iban;
    } else if (paymentMethod.type === 'paypal') {
      return paymentMethod.paypal.email;
    } else if (paymentMethod.type === 'creditCard') {
      return paymentMethod.creditCard.number;
    } else if (paymentMethod.type === 'bankTransfer') {
      return paymentMethod.bankTransfer.iban;
    } else {
      return '';
    }
  };

  const getPaymentMethodLabel = () => {
    if (paymentMethod.type === 'sepa') {
      return t(paymentMethods.sepa.name);
    } else if (paymentMethod.type === 'paypal') {
      return paymentMethods.braintree.name;
    } else if (paymentMethod.type === 'creditCard') {
      return t(paymentMethods.creditCard.name);
    } else if (paymentMethod.type === 'bankTransfer') {
      return t(paymentMethods.sepa.name);
    } else if (paymentMethod.type === 'invoice') {
      return t(paymentMethods.invoice.name);
    } else {
      return '';
    }
  };

  const getPaymentMethodSVG = () => {
    if (paymentMethod.type === 'sepa') {
      return <SepaSVG />;
    } else if (paymentMethod.type === 'paypal') {
      return <PaypalSVG />;
    } else if (paymentMethod.type === 'creditCard') {
      return <CreditCardSVG />;
    } else if (paymentMethod.type === 'bankTransfer') {
      return <SepaSVG />;
    } else {
      return '';
    }
  };

  return (
    <PersonalDetailsInputGroup>
      <LabeledText id='payment-method' label={getPaymentMethodLabel()} text={getPaymentMethodText()} />
      {isDesktop && <div className='personal-details-payment-method-view__logo'> {getPaymentMethodSVG()} </div>}
    </PersonalDetailsInputGroup>
  );
};

export default PersonalDetailsPaymentMethodView;
