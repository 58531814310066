import services from '../services';
import { ThunkAction } from './common.actionTypes';
import {
  GET_PRODUCT,
  GetProductAction,
  FETCH_PRODUCTS_BY_QUERY,
  FetchProductsByQueryAction,
  GET_PRODUCT_MIND_ITEMS,
  GetProductMindItemsAction,
  GET_ATTACHMENTS,
  GetAttachmentsAction,
} from './product.actionTypes';
import {
  GetProductsOptions,
  GetProductsQuery,
} from '@klab-berlin/api-sdk/lib/services/product';

export function fetchProduct(productId: string): ThunkAction<GetProductAction> {
  const payload = { productId };
  return async (dispatch, getState) => {
    const state = getState();
    const productData = state.products.byId[productId];
    if (productData && productData.status === 'ready') {
      return;
    }

    dispatch({ type: GET_PRODUCT.START, payload });

    return services.product.getProduct(productId, { includeInactive: true })
      .then((result) => dispatch({ type: GET_PRODUCT.SUCCESS, payload, result }))
      .catch(error => dispatch({ error, type: GET_PRODUCT.ERROR, payload }));
  };
}

export function fetchProductMindItems(productId: string): ThunkAction<GetProductMindItemsAction> {
  const payload = { productId };

  return async (dispatch, getState) => {
    const state = getState();
    const children = state.mindItems.siblingsByProductItemId[productId];
    if (children && children.status === 'ready') {
      return;
    }
    dispatch({ type: GET_PRODUCT_MIND_ITEMS.START, payload });

    return services.product.getProductMindItems(productId)
      .then(result => dispatch({ type: GET_PRODUCT_MIND_ITEMS.SUCCESS, payload, result }))
      .catch(error => dispatch({ error, type: GET_PRODUCT_MIND_ITEMS.ERROR, payload }));
  };
}

export function fetchProductsByQuery(
  query: GetProductsQuery,
  options: GetProductsOptions
): ThunkAction<FetchProductsByQueryAction> {
  const payload = { query, options };
  return (dispatch) => {
    dispatch({ type: FETCH_PRODUCTS_BY_QUERY.START, payload });

    return services.product
      .getProductsByQuery(query, options)
      .then((result) =>
        dispatch({
          type: FETCH_PRODUCTS_BY_QUERY.SUCCESS,
          payload,
          result,
        })
      )
      .catch(error => dispatch({ error, type: FETCH_PRODUCTS_BY_QUERY.ERROR, payload }));
  };
}

export function fetchAttachments(productId: string): ThunkAction<GetAttachmentsAction> {
  const payload = { productId };

  return async (dispatch, getState) => {
    const state = getState();
    const attachments = state.products.attachmentsByProductId[productId];
    if (attachments && attachments.status === 'ready') {
      return;
    }

    dispatch({ type: GET_ATTACHMENTS.START, payload });

    return services.product.getAttachments(productId)
      .then((result) =>
        dispatch({
          type: GET_ATTACHMENTS.SUCCESS,
          payload,
          result,
        })  
      )
      .catch(error => dispatch({ error, type: GET_ATTACHMENTS.ERROR, payload }));
  };
}
