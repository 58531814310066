import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Button } from '@mui/material';
import { palette, spaces } from '../../../assets/styles/themes/tokens';

interface OwnProps {
  onCtaClick: () => void;
}
const LernsetCreationCTA = (props: OwnProps) => {
  const { t } = useTranslation();
  const { onCtaClick } = props;
  return (
    <>
      <Typography 
        variant='h5'
        color={palette.text.primary}
        sx={{ padding: spaces.xs }}
      >
        {t('Create your first lesson')}
      </Typography>
      <Typography
        variant='subtitle1'
        color={palette.text.secondary}  
      >
        {t('All your new lessons will be listed here')}
      </Typography>
      <Box sx={{ paddingTop: spaces.xxxl }}>
        <Button 
          variant='contained'
          size='large'
          color='primary'
          key='create-lernset-button'
          onClick={onCtaClick}
        >
          {t('Create new lesson')}
        </Button>
      </Box>
    </>
  );
};

export default LernsetCreationCTA;
