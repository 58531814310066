import React, { useContext } from 'react';
import ActionButton from '../ActionButton';
import { ItemVariation } from '@klab-berlin/api-sdk/lib/types/responses/Collection';
import services from '../../services';
import { ManageItemInCollectionModalContext } from '../../state/ManageItemInCollectionModalProvider';
import { useSelector } from 'react-redux';
import { selectIsItemInOwnCollections } from '../../reducers/collections.selectors';
import { selectUser } from '../../reducers/user.selectors';
import classnames from 'classnames';
import './manageItemInCollectionsButton.scss';
import { useTranslation } from 'react-i18next';
import { CollectionContentSelected } from '../../ampli';
import { getAmpliContentEventPayload } from '../../utils/ampli';
import { MindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import { Product } from '@klab-berlin/api-sdk/lib/types/responses/Product';

interface ManageItemInCollectionsButtonProps {
  documentId: string;
  itemData: Partial<MindItem> | Partial<Product> | undefined;
  type: ItemVariation;
  preOpenModal?: () => void | undefined;
  iconClassName?: string;
  className?: string;
  labelledBy?: string;
}

const ManageItemInCollectionsButton: React.FC<ManageItemInCollectionsButtonProps> = (props) => {
  const { t } = useTranslation();
  const { itemData, type, documentId, preOpenModal } = props;
  const {
    show: showMangeItemInCollectionsModal,
    isOpen: isManageModalOpen
  } = useContext(ManageItemInCollectionModalContext);
  const isInCollection = useSelector(state => selectIsItemInOwnCollections(state, documentId));
  const user = useSelector(selectUser);
  
  const onClick = () => {
    if (preOpenModal) {
      preOpenModal();
    }

    showMangeItemInCollectionsModal(documentId, type, itemData);

    if (user && itemData) {
      const eventProperties = getAmpliContentEventPayload(itemData, type);

      services.track.ampliEventTrack({ 
        event: new CollectionContentSelected(eventProperties as any)
      });
    } 
  };

  const iconClassName = classnames(
    props.iconClassName,
    isInCollection && 'manage_item_in_collections_button--active'
  );

  return (
    <ActionButton
      className={props.className}
      onClick={onClick}
      icon={isInCollection ? 'add-to-folder-filled' : 'add-to-folder'}
      highlight={isManageModalOpen}
      iconClassName={iconClassName}
      testId='add-to-collection-button'
      label={t('Add to collection')}
      labelledBy={props.labelledBy}
    />
  );
};

export default ManageItemInCollectionsButton;
