import React from 'react';
import GridItem from './GridItem';
import ListItem from './ListItem';
import { ItemCardProps } from './Item.props';
import { useViewMode } from './ViewSwitch/ViewModeContext';

const Item: React.FC<ItemCardProps> = (props) => {
  const { viewMode } = useViewMode();
  switch (viewMode) {
    case 'list':
      return <ListItem {...props} />;

    case 'grid':
    default:
      return <GridItem {...props} />;
  }
};

export default Item;
