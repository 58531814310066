import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../reducers/user.selectors';
import CustomToast from '../../CustomToast/CustomToast';
import PersonalDetailsSection from '../Common/PersonalDetailsSection';
import ChangeEmailForm from './ChangeEmailForm';
import ChangeEmailView from './ChangeEmailView';

const ChangeEmail = () => {
  const { t } = useTranslation();

  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const user = useSelector(selectUser);

  const canEditEmail = () => {
    return user && !user.isSchoolUser && !user.isLibraryUser;
  };

  return (
    <>
      <PersonalDetailsSection
        title={t('Email address')}
        isEditDisabled={!canEditEmail()}
        renderNormalView={() => <ChangeEmailView />}
        renderFormView={(closeEditMode) => 
          <ChangeEmailForm
            close={closeEditMode}
            onSuccess={ () => setShowSuccessToast(true) } 
          />
        }
      />
      <CustomToast
        delay={8000}
        show={showSuccessToast}
        onClose={() => setShowSuccessToast(false)}
        title={t('Email address')}
        bodyText={t('Please confirm the new email address by clicking on the link in the verification email.')}
      />
    </>
  );
};

export default ChangeEmail;
