import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import GradientModalAppLayout from '../../components/AppLayout/GradientModalLayout';
import CreateProfileFrame from '../../components/CreateProfileFrame';
import { useTranslation } from 'react-i18next';
import { routes } from '../../constants';
import { useSelector } from 'react-redux';
import { updateMe } from '../../actions/user.actions';
import Button from 'react-bootstrap/Button';
import ProfilePill from '../../components/Profile/ProfilePill';
import services from '../../services';
import useAction from '../../utils/useAction';
import { selectUser } from '../../reducers/user.selectors';
import { selectSchoolTypes } from '../../reducers/schoolTypes.selectors';
import useRouter from '../../utils/useRouter';
import { SchoolSelected } from '../../ampli';

const CreateProfileSchoolTypePage = () => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const schoolTypes = useSelector(selectSchoolTypes);
  const updateMeAction = useAction(updateMe);
  const { router } = useRouter();
  const existingSchool = user && user.schoolType;

  const [schoolType, setSchoolType] = useState(existingSchool);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [schoolTypeChoiceValidationError, setSchoolTypeChoiceValidationError] = useState('');

  const nextRoute = `/${routes.createProfile.root}/${routes.createProfileFinish.root}`;
  const previousRoute = `/${routes.createProfile.root}/${routes.createProfileClasses.root}`;

  const onSubmit = () => {
    if (schoolType === undefined) {
      return setSchoolTypeChoiceValidationError(`
        ${t('Please select a school type')}.
        ${t('If none of these fit, choose the button \'none of these options\' at the end')}.
      `);
    }

    setIsSubmitting(true);

    const schools = [{ type: schoolType }];

    if (user) {
      services.track.ampliEventTrack({ 
        event: new SchoolSelected(), 
        userProperties: {
          id: user.id,
          schoolType
        }
      });
    }

    updateMeAction({ schools })
      .then(() => {
        router.push(nextRoute);
      })
      .catch(() => {
        setIsSubmitting(false);
      });
  };

  const onBack = () => {
    router.push(previousRoute);
  };

  const isValid = schoolType !== undefined;

  return (
    <GradientModalAppLayout>
      <CreateProfileFrame
        progressBar={{
          numberOfSteps: 4,
          currentStep: 4,
        }}
        next={{
          action: onSubmit,
          disabled: isSubmitting,
          text: t('Next'),
        }}
        back={{
          action: onBack,
          disabled: isSubmitting,
          text: t('Back'),
        }}
      >
        {!isValid && schoolTypeChoiceValidationError && (
          <Alert variant='danger'>
            {schoolTypeChoiceValidationError}
          </Alert>
        )}
        <h1>{t('Which school type do you teach?')}</h1>
        {schoolTypes
          .filter(school => school.profileId)
          .map((school) => (
            <ProfilePill key={school.profileId as string} selected={school.profileId === schoolType}>
              <Button
                className='w-100 my-2'
                aria-pressed={school.profileId === schoolType}
                onClick={() => setSchoolType(school.profileId as string)}
              >
                {school.name}
              </Button>
            </ProfilePill>
          ))}
      </CreateProfileFrame>
    </GradientModalAppLayout>
  );
};

export default CreateProfileSchoolTypePage;
