import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Icon from '../Icon';
import ItemMeatballMenu from '../ItemMeatballMenu';
import ItemMetadata from '../ItemMetadata';
import { ContentType, MindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import PublisherImage from '../PublisherImage';
import Truncate from '../Truncate';

const FileTypes: { [key in ContentType]: React.FunctionComponent } = {
  document: () => <></>,
  video: () => <Icon icon='video' className='text-danger w-50 h-auto' />,
  'read-only': () => <></>,
};

interface FileItemProps {
  item: MindItem;
}

const FileItem: React.FC<FileItemProps> = (props) => {
  const FileImage = FileTypes[props.item.fileType];

  return (
    <>
      <div className='item-header'>
        <div className='icon-container'>
          <FileImage />
        </div>
      </div>
      <Card.Body className={'pt-2 pb-2 px-3 bg-white'}>
        <Row className='align-items-center'>
          <Col>
            <div className='item__title font-weight-bold'>
              <Truncate lines={2} text={props.item.title}/>
            </div>
          </Col>
          <Col
            xs={1}
            className='rounded-circle px-0 justify-content-center align-items-center mr-2'
          >
            <PublisherImage className='publisher-logo' publisher={props.item.publisher} />
          </Col>
        </Row>
        <Row>
          <Col xs={10} className='pt-2'>
            <ItemMetadata
              schoolTypes={props.item.schoolTypes}
              subjects={props.item.subjects}
              classYears={props.item.classYears}
            />
          </Col>
          <Col xs={2} className='p-0'>
            <ItemMeatballMenu itemId={props.item.id} type='mindItem' />
          </Col>
        </Row>
      </Card.Body>
    </>
  );
};

export default FileItem;
