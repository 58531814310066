import React, { useState } from 'react';

type ShowFunc = (itemId?: string) => void;
type ToggleFunc = (isOpen: boolean, itemId?: string) => void;
const noop = () => undefined;

interface MeatballMenuProviderContext {
  itemId?: string;
  show: ShowFunc;
  hide: () => void;
  toggle: ToggleFunc;
}

const defaultContext: MeatballMenuProviderContext = {
  show: noop as ShowFunc,
  hide: noop,
  toggle: noop as ToggleFunc,
};

const MeatballMenuContext = React.createContext(defaultContext);

const MeatballMenuProvider: React.FC = (props) => {
  const show = (itemId: string) => {
    setState((prevState) => ({
      ...prevState,
      itemId,
    }));
  };

  const hide = () => {
    setState((prevState => ({
      ...prevState,
      itemId: undefined,
    })));
  };

  const toggle = (isOpen: boolean, itemId: string) => {
    if (isOpen) {
      return show(itemId);
    }

    return hide();
  };

  const defaultState = Object.assign(defaultContext, { hide, show, toggle });
  const [state, setState] = useState(defaultState);

  return (
    <MeatballMenuContext.Provider value={state}>
      {state.itemId && <div className='meatball-menu-overlay' onClick={hide} />}
      {props.children}
    </MeatballMenuContext.Provider>
  );
};

// Without this .meatball-menu-overlay will prevent containers from scrolling
const MeatballMenuScrollableArea: React.FC = () => {
  const { hide, itemId } = React.useContext(MeatballMenuContext);

  if (!itemId) return null;

  return (
    <div onClick={hide} className='meatball-menu-scrollable-area' />
  );
};

export { MeatballMenuContext, MeatballMenuProvider, MeatballMenuScrollableArea };
