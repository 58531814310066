import React, { useState, useEffect, useContext, useRef } from 'react';
import classnames from 'classnames';
import fscreen from 'fscreen';
import { ImageResolutionsInterface } from '@klab-berlin/api-sdk/lib/types/responses/Common';
import ReaderContentControls from '../../ReaderControls/ReaderContentControls';
import ReaderActions from '../../ReaderControls/ReaderActions';
import useDisplaySizes from '../../../../utils/useDisplaySizes';
import ReaderPagesBasis from '../ReaderPagesBasis';
import { ReaderContext } from '../../ReaderContext';
import NextMindItemButton from '../NextMindItemButton';
import { zoomOptions } from '../../../../constants';
import { ReaderContentViewVariant } from '../../../../types/reader';
import ZoomContainer from './ZoomContainer';
import { usePageReadTracking } from '../../../utils/usePageReadTracking';
import './readerDocumentContent.scss';

interface ReaderDocumentContentProps {
  pages: ImageResolutionsInterface[];
  variant: ReaderContentViewVariant;
  onScroll: (scrollTop: number) => void;
}

const ReaderDocumentContent: React.FC<ReaderDocumentContentProps> = (props) => {
  const { pages } = props;
  const [pageYLocations, setPageYLocations] = useState<number[]>([]);
  const [readerReady, setReaderReady] = useState(false);
  const [futurePage, setFuturePage] = useState<number | null>(null);
  const {
    currentPage,
    setCurrentPage,
    pagesLoaded,
    mindItemId,
    initialPage,
    isReadOnly,
    hasAttachments,
  } = useContext(ReaderContext);

  const fullscreenContainerRef = useRef<Element | undefined>();
  const [isFullscreenMode, setIsFullscreenMode] = useState(false);

  const [zoom, setZoom] = useState<number>(zoomOptions.defaultZoom);
  const [width, height] = useDisplaySizes();
  const contentElem = useRef<HTMLElement>();
  const documentElem = useRef<HTMLElement>();

  usePageReadTracking(mindItemId, currentPage, readerReady);

  useEffect(() => {
    contentElem.current = document.getElementById('content-container') || undefined;
    documentElem.current = document.getElementById('reader-document-content') || undefined;
    fullscreenContainerRef.current = fscreen.fullscreenElement;

    const handler = () => {
      setIsFullscreenMode(!!fscreen.fullscreenElement);
    };
    fscreen.addEventListener('fullscreenchange', handler);
    return () => {
      fscreen.removeEventListener('fullscreenchange', handler);
    };
  }, []);

  const zoomIn = () => {
    const newZoom = zoom + zoomOptions.zoomStep;
    setZoom(Math.min(zoomOptions.maxZoom, newZoom));
  };
  const zoomOut = () => {
    const newZoom = zoom - zoomOptions.zoomStep;
    setZoom(Math.max(zoomOptions.minZoom, newZoom));
  };

  useEffect(() => {
    setReaderReady(false);
  }, [mindItemId]);

  useEffect(() => {
    if (pagesLoaded) {
      updatePageYLocations();
      setReaderReady(true);
    }
  }, [pagesLoaded, initialPage]);

  useEffect(() => {
    // intialPage is always 1 on mobile
    // moveToPage scrolling causes mobile head and actions to hide on initial load
    if (props.variant === 'mobile') return;

    if (readerReady && initialPage) {
      moveToPage(initialPage);
      setCurrentPage(initialPage);
    }
  }, [readerReady, initialPage]);

  const updatePageYLocations = () => {
    const pageContainer = document.querySelectorAll('.page-container');
    const tempOffsets: number[] = [];
    pageContainer.forEach(node => {
      const htmlElem = node as HTMLElement;
      tempOffsets.push(htmlElem.offsetTop);
    });
    setPageYLocations(tempOffsets);
  };

  // An update that takes place every time the Reader's layout is changing
  useEffect(() => {
    updatePageYLocations();
  }, [width, height, zoom, isFullscreenMode]);

  const calculateFullscreenZoom = () => {
    const pageElem = document.querySelector('.page');
    if (contentElem.current && pageElem) {
      const contentHeight = contentElem.current.getBoundingClientRect().height;
      const pageHeight = pageElem.getBoundingClientRect().height;
      const contentPageRatio = contentHeight / pageHeight;
      const newZoom = Math.round((zoom * contentPageRatio) / 10) * 10;
      return newZoom;
    }
    return zoom;
  };

  useEffect(() => {
    if (futurePage) {
      moveToPage(futurePage);
      setFuturePage(null);
    }
  }, [futurePage]);

  // This function is used to delay moveToPage until after all of the
  // layout changes take place (as sizes and locations of pages change)
  const delayedMoveToPage = (page: number, delay: number) => {
    setTimeout(() => setFuturePage(page), delay);
  };

  useEffect(() => {
    if (fullscreenContainerRef.current) {
      if (isFullscreenMode) {
        const newZoom = calculateFullscreenZoom();
        delayedMoveToPage(currentPage, 50);
        setZoom(Math.max(newZoom, zoomOptions.minZoom));
      }
      else {
        delayedMoveToPage(currentPage, 50);
        setZoom(zoomOptions.defaultZoom);
      }
    }
  }, [isFullscreenMode]);

  const calculateCurrentPage = () => {
    if (contentElem.current) {
      const middleLineY = contentElem.current.scrollTop + contentElem.current.getBoundingClientRect().height * 0.5;
      let currentPageTmp = 1;
      for (const top of pageYLocations) {
        if (middleLineY > top) {
          currentPageTmp++;
        }
        else { break; }
      }
      return --currentPageTmp;
    }
    return 1;
  };

  const toggleFullscreen = () => {
    if (!documentElem.current) return;

    if (!fscreen.fullscreenElement) {
      fscreen.requestFullscreen(documentElem.current);
    }
    else {
      fscreen.exitFullscreen();
    }
  };

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    props.onScroll(event.currentTarget.scrollTop);
    const newCurrentPage = calculateCurrentPage();
    setCurrentPage(newCurrentPage);
  };

  const moveToPage = (page: number) => {
    let targetPage = page;

    if (targetPage < 1) {
      targetPage = 1;
    } else if (targetPage > pages.length) {
      targetPage = pages.length;
    }

    if (contentElem.current) {
      contentElem.current.scrollTop = pageYLocations[targetPage - 1];
    }
  };

  const goToPreviousPage = () => {
    moveToPage(currentPage - 1);
  };

  const goToNextPage = () => {
    moveToPage(currentPage + 1);
  };

  return (
    <>
      {props.variant === 'mobile' &&
        <div id='content-container'
          className='reader-document-content__mobile-pages-wrapper'
          onScroll={handleScroll}
        >
          <ZoomContainer>
            <ReaderPagesBasis pages={pages} zoom={100} mindItemId={mindItemId} />
          </ZoomContainer>
        </div>
      }
      {props.variant === 'desktop' &&
        <div
          id='reader-document-content'
          className={classnames(
            'h-100 justify-content-center',
            { 'reader-document-content--full-screen': isFullscreenMode }
          )}
        >
          <div className='content__controls bg--grey-medium-10'>
            <ReaderContentControls
              fullscreenMode={isFullscreenMode}
              currentPage={currentPage}
              pageCount={pages.length}
              currentZoom={zoom}
              zoomIn={zoomIn}
              zoomOut={zoomOut}
              fitToWidth={() => setZoom(100)}
              goToNextPage={goToNextPage}
              goToPreviousPage={goToPreviousPage}
              toggleFullscreen={toggleFullscreen}
              fullscreenContainer={documentElem.current}
            />
          </div>
          <span className='position-relative content__body'>
            <div id='content-container'
              className='w-100 align-items-center h-100 overflow-auto bg--grey-medium-10 position-absolute'
              onScroll={handleScroll}
            >
              <ReaderPagesBasis pages={pages} zoom={zoom} mindItemId={mindItemId} />
              {
                pagesLoaded && <NextMindItemButton mindItemId={mindItemId} />
              }
            </div>
          </span>
          <div className='content__actions bg--grey-medium-10'>
            <ReaderActions
              fullscreenContainer={documentElem.current}
              hideDownloadAction={isReadOnly && !hasAttachments}
            />
          </div>
        </div>
      }
    </>
  );
};

export default ReaderDocumentContent;
