import React from 'react';
import classnames from 'classnames';
import Icon, { IconName } from '../Icon';
import { IconSize } from '../Icon/IconTypes';

interface ActionButtonProps {
  highlight?: boolean;
  icon: IconName;
  size?: IconSize;
  onClick: () => void;
  iconClassName?: string;
  className?: string;
  testId?: string;
  label?: string;
  labelledBy?: string;
}

const ActionButton: React.FC<ActionButtonProps> = props => {

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    props.onClick();
  };

  return (
    <button
      onClick={event => handleClick(event)}
      className={classnames('focus--highlight-blue btn-none p-0 border-0', props.className)}
      aria-label={props.label}
      aria-labelledby={props.labelledBy}
    >
      <Icon
        className={classnames({
          'color--grey-dark': !props.highlight,
          'color--highlight-blue': props.highlight,
        }, props.iconClassName)}
        testId={props.testId}
        icon={props.icon}
        size={props.size || 'lg'}
      />
      {props.children}
    </button>
  );
};

export default ActionButton;
