import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import {
  Drawer,
  List,
  ListItem,
  SvgIcon,
  IconButton,
  Tooltip,
} from '@mui/material';
import mU from '../../../assets/lernsets/logos/mU-briefcase.svg';
import notes from '../../../assets/lernsets/icons/notes.svg';
import LernsetsSidebarButton from './LernsetsSidebarButton';
import { sideBarWidth, spaces, topBarHeight } from '../../../assets/styles/themes/tokens';
import useRouter from '../../../utils/useRouter';
import { routes } from '../../../constants';

const LernsetsSidebar = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { router, match: { location: { pathname } } } = useRouter();

  const sidebarItems = [
    {
      title: t('Overview'),
      icon: notes,
      onClick: () => {
        selectedSidebarItem.current = sidebarItems[0].title;
        router.push(`/${routes.learnsetsDashboard.root}`);
      }
    },
  ];

  const selectedSidebarItem = useRef(pathname === '/lernsets' ? sidebarItems[0].title : '');

  return (
    <Drawer
      sx={{
        width: sideBarWidth,
        position: 'initial',
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          backgroundColor: theme.palette.common.white,
        },
      }}
      variant='permanent'
      anchor='left'
    >
      <Tooltip title={t('to meinUnterricht start page')} arrow placement='right'>
        <IconButton
          size='large'
          onClick={() => {
            selectedSidebarItem.current = sidebarItems[0].title;
            router.push(`/${routes.dashboard.root}`);
          }}
          sx={{
            borderRadius: 0,
            height: topBarHeight,
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}>
          <SvgIcon component={mU} fontSize='large' sx={{ height: 24, width: 24 }} />
        </IconButton>
      </Tooltip>
      <List
        disablePadding
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          flex: '1 0 0',
          alignSelf: 'stretch',
        }} >
        {sidebarItems.map((item) => (
          <ListItem key={item.title} sx={{ width: 'auto', padding: spaces.xs }}>
            <LernsetsSidebarButton 
              title={item.title} 
              icon={item.icon} 
              onClick={item.onClick} 
              selected={selectedSidebarItem.current === item.title} 
            />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default LernsetsSidebar;
