import React, { ReactNode } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import Icon, { IconName } from '../../components/Icon';

type CtxMetaFieldProp = string | number | null;
interface CtxMetaProps {
  children?: ReactNode;
  field: CtxMetaFieldProp | CtxMetaFieldProp[];
}

const CtxMeta: React.FC<CtxMetaProps> = ({ children, field }) => (
  <>
    <Col as={'dt'} xs={4} className='font-weight-normal text-muted font-size-12'>{field}</Col>
    <Col as={'dd'} xs={8} className='text-dark font-size-12'>{children}</Col>
  </>
);

type CtxMetasDataRecord = [string, CtxMetaFieldProp | CtxMetaFieldProp[] | undefined, ReactNode?];
interface CtxMetasProps {
  data: CtxMetasDataRecord[];
}

const shouldShowMeta = (value: any): boolean => {
  if (!value) {
    return value === 0;
  }

  return !!String(value).trim();
};

const CtxMetasInner: React.FC<CtxMetasProps> = ({ data }) => {
  const { t } = useTranslation();
  return (
    <>
      {data.map(([field, value, Component]) => (
        shouldShowMeta(value) && <CtxMeta
          children={Component || (Array.isArray(value) ? value.join(', ') : value)}
          field={t(field) as string}
          key={field}
        />
      ))}
    </>
  );
};

export const CtxMetas = CtxMetasInner;

interface CtxButtonProps {
  children?: ReactNode;
  icon: IconName;
  iconClassName?: string;
  onClick?: (e: React.SyntheticEvent) => void;
}

export const CtxButton: React.FC<CtxButtonProps> = ({ onClick, children, icon, iconClassName }) => (
  <div>
    <Button variant='link' className='px-0' type='button' onClick={onClick}>
      <Icon
        icon={icon}
        className={`mr-2 ${iconClassName || ''}`}
      />
      {children}
    </Button>
  </div>
);

interface CtxIconTextProps {
  children?: ReactNode;
  icon: IconName;
}

export const CtxIconText: React.FC<CtxIconTextProps> = ({ children, icon }) => (
  <div className='icon-text px-0 text-break'>
    <Icon className='mr-1' icon={icon} />
    {children}
  </div>
);

interface CtxTopProps {
  title: string;
  description?: string | null;
}

export const CtxTop: React.FC<CtxTopProps> = ({ title, description }) => (
  <>
    <Row className='py-4 border-bottom'>
      <Col as='h5' className='h5 font-weight-normal'>
        <Icon icon='text-file' />
        {title}
      </Col>
    </Row>
    {description && description !== title && (
      <Row className='py-4 border-bottom'>
        <Col as='h6' className='h6 font-weight-normal'>
          <p>{description}</p>
        </Col>
      </Row>
    )}
  </>
);
