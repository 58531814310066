import React from 'react';
import { useTranslation } from 'react-i18next';
import LabeledText from '../../common/LabeledText';

const PasswordDataView = () => {
  const { t } = useTranslation();

  return (
    <LabeledText label={t('Current Password')} text={'******'} id='password' />
  );
};

export default PasswordDataView;
