import React from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import DnDList from '../DnDList';
import ProfilePill from '../Profile/ProfilePill';
import ProfileSubject from '../Profile/ProfileSubject';

interface OwnProps {
  majorSubjects: string[];
  minorSubjects: string[];
  isPrioritized: boolean;
  onOrder: (subjectId: string, isMajor: boolean, index: number) => void;
  toggleHierarchy: () => void;
}

const SubjectPriorities: React.FC<OwnProps> = (props) => {
  const { t } = useTranslation();
  const onDrop = (result: DropResult) => {
    const { destination } = result;
    if (!destination) {
      return;
    }

    const isMajor = destination.droppableId === 'majors';
    const subjectId = result.draggableId;
    const index = destination.index;

    props.onOrder(subjectId, isMajor, index);
  };

  return <>
    <div className='w-100 text-right'>
      <button
        type='button'
        className='btn btn-link'
        onClick={props.toggleHierarchy}>
        {
          props.isPrioritized ?
            <span>{ t('Remove hierarchy') }</span>:
            <span>{ t('Add hierarchy') }</span>
        }
      </button>
    </div>

    {
      props.isPrioritized ?
        <DragDropContext onDragEnd={onDrop}>
          <h3>{t('Majors')}</h3>
          <DnDList
            listId='majors'
            testId='subject-priority-major'
            items={props.majorSubjects.map((subjectId) => ({
              id: subjectId,
              item: (
                <ProfilePill
                  className='w-100 my-2'
                  selected={true}
                  selectedClass='bg-info'
                >
                  <div>
                    <ProfileSubject subjectId={subjectId} />
                  </div>
                </ProfilePill>
              ),
            }))}
            className='profile__subject-list p-2 rounded'
          />

          <h3>{t('Minors')}</h3>
          <DnDList
            listId='minors'
            testId='subject-priority-minor'
            items={props.minorSubjects.map((subjectId) => ({
              id: subjectId,
              item: (
                <ProfilePill
                  className='w-100 my-2'
                  selected={true}
                  selectedClass='bg-info-pale'
                >
                  <div>
                    <ProfileSubject subjectId={subjectId} />
                  </div>
                </ProfilePill>
              ),
            }))}
            className='profile__subject-list p-2 rounded'
          />
        </DragDropContext>
        :
        <>
          {props.majorSubjects.concat(props.minorSubjects).map((subjectId) => (
            <ProfilePill key={subjectId} selected={true} selectedClass='bg-info'>
              <div className='w-100 mx-auto my-2'>
                <ProfileSubject subjectId={subjectId} />
              </div>
            </ProfilePill>
          ))}
        </>
    }
  </>;
};

export default SubjectPriorities;

