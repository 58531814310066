import React from 'react';
import './formTextArea.scss';

interface FormTextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  containerClassName?: string;
  id: string;
  name: string;
  title?: string;
}

const FormTextArea = React.forwardRef<HTMLTextAreaElement, FormTextAreaProps>(
  ({ containerClassName, id, title, name, ...props },
    ref,
  ) => {
    return (
      <div className={containerClassName ? containerClassName : 'form-group'}>
        {title && <label className='form-label' htmlFor={id}>{title}</label>}
        <textarea
          className='mu-form-text-area__input form-control'
          id={id}
          ref={ref}
          name={name}
          {...props}
        />
      </div>
    );
  });

export default FormTextArea;
