import React, { useContext } from 'react';
import WithTooltip from '../../WithTooltip';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../ActionButton';
import './readerHead.scss';
import { ReaderContext } from '../ReaderContext';
import { itemLink } from '../../../utils';
import Icon from '../../Icon';
import { Link } from 'found';
import { useSelector } from 'react-redux';
import { selectProduct } from '../../../reducers/products.selectors';
import LernsetsCTA from '../../lernsets/LernsetsCta/LernsetsCta';
import { selectMindItem } from '../../../reducers/mindItems.selectors';
import Button from '../../common/Button';
import { Box } from '@mui/material';
import { selectUser } from '../../../reducers/user.selectors';
import exitFullscreen from '../../utils/exitFullScreen';
import { DownloadModalContext } from '../../../state/DownloadModalProvider';
import { spaces } from '../../../assets/styles/themes/tokens';
import useMedia from '../../../utils/useMedia';
import config from 'config';
import { useAmplitudeExperiment } from '../../../utils/ampli';

interface ReaderHeadProps {
  header: string | React.ReactNode;
  showSearchIcon?: boolean;
  onSearchClick?: () => void;
  showInfoIcon?: boolean;
  onInfoClick?: () => void;
  isInfoIconActive?: boolean;
  productId: string;
}

const ReaderHead: React.FC<ReaderHeadProps> = (props) => {
  const user = useSelector(selectUser);
  const {
    showSearchIcon,
    onSearchClick,
    showInfoIcon,
    onInfoClick,
    isInfoIconActive,
    header,
    productId
  } = props;
  const { t } = useTranslation();
  const { 
    mindItemId, 
    isReadOnly,
    hasAttachments 
  } = useContext(ReaderContext);
  const {
    variant: lernsetVariant,
    isReady: isClientReady,
  } = useAmplitudeExperiment(user, config.amplitudeExperiments.enableLearnsetsFeature);
  const isLernsetsActive = isClientReady && lernsetVariant === 'on';
  const { show: showDownloadModal } = useContext(DownloadModalContext);
  const { isMobile } = useMedia();
  const product = useSelector(state => selectProduct(state, productId));
  const hasProductOverview = !!product?.active;
  const mindItem = useSelector(state => selectMindItem(state, mindItemId));
  const showLernsetsCTA = 
    mindItem?.fileType === 'document' 
    && !isReadOnly 
    && isLernsetsActive 
    && !isMobile 
    && user?.isPremium;
  const hideDownloadAction = (isReadOnly && !hasAttachments) || user?.hideDownloadAction || !isLernsetsActive;

  const headerWithTooltip = mindItem?.title 
    ? <WithTooltip text={mindItem.title} placement='bottom-start'>{header}</WithTooltip> 
    : header;

  return (
    <div className='reader-head__container'>
      {
        hasProductOverview &&
        <Link
          className='reader-head__mobile-product-button'
          aria-label={t('To overview')}
          to={itemLink(productId, 'product')}
        >
          <Icon icon='book' size='lg' />
        </Link>
      }
      <div 
        className='reader-head__header'
        data-testid='reader__header'
        id='product_tour_selector_reader_title'  // Used by intercom.
      >
        {headerWithTooltip}
      </div>
      <div className='d-flex align-items-center'>
        {showLernsetsCTA && (
          <Box sx={{
            display: 'flex',
            gap: spaces.md,
            width: 'max-content',
            marginRight: spaces.xxl,
          }}>
            <LernsetsCTA mindItemId={mindItemId} />
            {
              !hideDownloadAction && (
                <Button
                  icon='download'
                  variant='primary'
                  onClick={() => exitFullscreen(() =>
                    showDownloadModal(mindItemId))}
                  type='button'>
                  {t('Download')}
                </Button>
              )
            }
          </Box>
        )}  
        {
          showSearchIcon &&
          <div className='reader-head__search-button'>
            <WithTooltip text={t('Search document')} placement='left'>
              <ActionButton
                icon='search'
                onClick={() => onSearchClick?.()}
                testId='reader__search-button'
              />
            </WithTooltip>
          </div>
        }
        {
          showInfoIcon &&
          <div className='reader-head__info-button'>
            <WithTooltip text={t('Details')} placement='left'>
              <ActionButton
                icon='information'
                testId='reader__info-button'
                onClick={() => onInfoClick?.()}
                highlight={isInfoIconActive}
              />
            </WithTooltip>
          </div>
        }
      </div>
    </div>
  );
};

export default ReaderHead;
