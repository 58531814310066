import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import NavigationLayoutDesktop from '../components/AppLayout/NavigationLayout/NavigationLayoutDesktop';
import NavigationLayoutMobile from '../components/AppLayout/NavigationLayout/NavigationLayoutMobile';
import ReaderHead from '../components/Reader/ReaderHead';
import { fetchPublicMindItem as fetchPublicMindItemAction } from '../actions/mindItems.actions';
import {
  selectPublicMindItem,
  isPublicMindItemInvalid as getMindItemInvalidity,
} from '../reducers/mindItems.selectors';
import { ReaderContext } from '../components/Reader/ReaderContext';
import { UIProperties, amplitudeContentTypes, amplitudeEvents, routes } from '../constants';
import MasterLayout from '../components/AppLayout/MasterLayout';
import { useNavigationLayout } from '../utils/useNavigationLayout';
import ReaderInfoSidebar from '../components/Reader/ReaderInfoSidebar';
import { setCustomPageTitle } from '../utils/customTitle';
import useRouter from '../utils/useRouter';
import useAction from '../utils/useAction';
import ReaderProductNavigationWrapper
  from '../components/Reader/ReaderProductNavigation/ReaderProductNavigationWrapper';
import { noop } from 'lodash';
import ReaderVideoContent from '../components/Reader/ReaderContent/ReaderVideoContent/ReaderVideoContent';
import { AmplitudeTrackPayload } from '@klab-berlin/api-sdk/lib/types/requests/Track';
import { getContentEventPayload, mapDesignPreference } from '../utils/ampli';
import { ampliServerSideEventTrack } from '../services/track.service';
import { useNewDesign } from '../state/DesignPreferenceContextProvider';

const PublicVideoPlayerPage: React.FC = () => {
  const { setShowSidebarRight, showSidebarRight, renderNavigationMobileLayout } = useNavigationLayout();
  const { match: { params: { accessToken } }, router } = useRouter();
  const fetchPublicMindItem = useAction(fetchPublicMindItemAction);
  const mindItem = useSelector(state => selectPublicMindItem(state, accessToken));
  const isMindItemInvalid = useSelector(state => getMindItemInvalidity(state, accessToken));
  const { shouldShowDesignPreferenceSwitch, prefersNewDesign } = useNewDesign();

  useEffect(() => {
    fetchPublicMindItem(accessToken);
  }, [accessToken]);

  useEffect(() => {
    if (mindItem) {
      setCustomPageTitle(mindItem.title);

      const contentViewedPayload: (AmplitudeTrackPayload & UIProperties) | null = getContentEventPayload({      
        content: {
          ...mindItem,
          publicVideo: 'Yes'
        },
        event_type: amplitudeEvents.ContentViewed,
        contentType: amplitudeContentTypes.video
      });
      if (contentViewedPayload) {
        if (shouldShowDesignPreferenceSwitch) {
          contentViewedPayload.event_properties['ui'] = mapDesignPreference(prefersNewDesign);
        }
        ampliServerSideEventTrack(true, contentViewedPayload);
      }
      
    }
  }, [mindItem?.id]);

  if (isMindItemInvalid) {
    router.push(`/${routes.notFound.root}`);
  }
  if (!mindItem || mindItem?.fileType !== 'video') {
    return <></>;
  }

  const handleInfoClick = () => {
    setShowSidebarRight(!showSidebarRight);
  };

  return (
    <ReaderContext.Provider
      value={{
        currentPage: 0,
        setCurrentPage: noop,
        initialPage: 0,
        mindItemId: mindItem.id,
        isReadOnly: mindItem.isReadOnly,
        nextSibling: undefined,
        isPinned: mindItem.isPinned,
        isInCollection: false,
        isTextBased: mindItem.isTextBased,
        onPageLoad: noop,
        pagesLoaded: true,
        hasAttachments: mindItem.hasAttachments,
      }}
    >
      <MasterLayout isPublic={true}>
        {renderNavigationMobileLayout && <NavigationLayoutMobile
          head={
            <ReaderHead
              header={mindItem.title}
              showInfoIcon
              onInfoClick={handleInfoClick}
              isInfoIconActive={showSidebarRight}
              productId={mindItem.product}
            />
          }
          main={<ReaderVideoContent
            mindItemId={mindItem.id}
            variant={renderNavigationMobileLayout ? 'mobile' : 'desktop'}
            isPublic={true}
          />}
          sidebarRight={
            <ReaderInfoSidebar
              mindItemId={mindItem.id}
              variant='mobile'
              onClose={() => setShowSidebarRight(false)}
            />
          }
          showSidebarRight={showSidebarRight}
          hideHeadAndActions={false}
        />}
        {!renderNavigationMobileLayout && <NavigationLayoutDesktop
          head={
            <ReaderHead
              header={mindItem.title}
              showInfoIcon
              onInfoClick={handleInfoClick}
              isInfoIconActive={showSidebarRight}
              productId={mindItem.product}
            />
          }
          main={<ReaderVideoContent
            mindItemId={mindItem.id}
            variant={renderNavigationMobileLayout ? 'mobile' : 'desktop'}
            isPublic={true}
          />}
          sidebarRight={
            <ReaderInfoSidebar
              mindItemId={mindItem.id}
              variant='desktop'
              onClose={() => setShowSidebarRight(false)}
            />
          }
          showSidebarRight={showSidebarRight}
          sidebarLeft={<ReaderProductNavigationWrapper />}
        />
        }
      </MasterLayout>
    </ReaderContext.Provider>
  );
};

export default PublicVideoPlayerPage;
