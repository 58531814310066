import { Link } from 'found';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { routes } from '../../constants';
import { ReaderContentViewVariant } from '../../types/reader';
import Icon from '../Icon';
import './productAttachmentsHead.scss';

interface ProductAttachmentsBreadcrumbsProps {
  productId: string;
  parentFolderPath: string;
  parentPageName?: string;
  isProductActive: boolean;
  currentFolderName?: string;
  productTitle?: string;
  isCurrentPageAttachmentsRootPage: boolean;
  isParentPageAttachmentsRootPage: boolean;
  variant: ReaderContentViewVariant;
} 

const ProductAttachmentsBreadcrumbs: React.FC<ProductAttachmentsBreadcrumbsProps> = ({
  productTitle, 
  parentFolderPath, 
  parentPageName, 
  currentFolderName, 
  productId, 
  variant, 
  isProductActive, 
  isCurrentPageAttachmentsRootPage,
  isParentPageAttachmentsRootPage
}) => {
  const { t } = useTranslation();
  const isMobile = variant === 'mobile';

  const getMobileBreadcrumbs = () => {
    if (isCurrentPageAttachmentsRootPage && !isProductActive) {
      return <>
        <Icon icon='arrow-small-left' />
        { t('Attachments') }
      </>;
    }

    return (
      <Link
        className='product-attachments-head__mobile-breadcrumb'
        to={parentFolderPath}
      >
        <Icon icon='arrow-small-left' />
        {parentPageName}
      </Link>
    );
  };

  const getDesktopBreadcrumbs = () => (
    <>
      {
        isProductActive &&
      <>
        <Link
          to={`/${routes.productPage.root}/${productId}`}
          className='product-attachments-head__breadcrumb'
        >
          {productTitle}
        </Link>
        <Icon icon='arrow-small-right' />
      </>
      }
      {
        isCurrentPageAttachmentsRootPage ?
          <>{ t('Attachments') }</>
          :
          <Link
            to={`/${routes.productAttachmentsPage.root}/${productId}`}
            className='product-attachments-head__breadcrumb'
          >
            { t('Attachments') }
          </Link>
      }
      { currentFolderName && (
        <>
          <Icon icon='arrow-small-right' />
          { 
            !isCurrentPageAttachmentsRootPage && !isParentPageAttachmentsRootPage && (
              <>
                <Link
                  className='product-attachments-head__breadcrumb'
                  to={parentFolderPath}
                >
                  ...
                </Link>
                <Icon icon='arrow-small-right' />
              </>
            )
          }
          { currentFolderName }
        </> 
      )}
    </>
  );

  return (
    <div className='product-attachments__container'>
      { isMobile && getMobileBreadcrumbs() }
      { !isMobile && getDesktopBreadcrumbs()}
    </div>
  );
};

export default ProductAttachmentsBreadcrumbs;
