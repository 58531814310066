import productService from '../services/product.service';
import mindItemService from '../services/mindItem.service';
import userService from '../services/user.service';
import { ThunkAction } from './common.actionTypes';
import {
  FETCH_PINNED_CONTENTS,
  FetchPinnedContentsAction,
  PIN_MIND_ITEM,
  UNPIN_DOCUMENT,
  UnpinDocumentAction,
  PinMindItemAction,
  PinProductAction,
  PIN_PRODUCT,
} from './pinned.actionTypes';
import { ItemVariation } from '@klab-berlin/api-sdk/lib/types/responses/Collection';
import { PaginationRequest } from '@klab-berlin/api-sdk/lib/types/common';
import { StoreType } from '../reducers';
import { hasNext } from '../utils/pagination';

type GetStateType = () => StoreType;

const hasNextForRequest = (
  getState: GetStateType,
  paginationRequest: PaginationRequest
): boolean => {
  return hasNext({
    ...getState().pinned.pagination,
    ...paginationRequest
  });
};

export function fetchPinnedContents(
  pagination: PaginationRequest
): ThunkAction<FetchPinnedContentsAction> {
  const payload = {
    pagination
  };
  return async (dispatch, getState) => {
    if (!hasNextForRequest(getState, pagination)) {
      return;
    }
    dispatch({ type: FETCH_PINNED_CONTENTS.START, payload });
    userService
      .getPinnedContents(pagination)
      .then((result) =>
        dispatch({ type: FETCH_PINNED_CONTENTS.SUCCESS, payload, result })
      )
      .catch(error => dispatch({ error, type: FETCH_PINNED_CONTENTS.ERROR, payload }));
  };
}

export function pinMindItem(id: string): ThunkAction<PinMindItemAction> {
  const payload = { id, type: 'mindItem' as const };
  return (dispatch) => {
    dispatch({ type: PIN_MIND_ITEM.START, payload });

    return mindItemService.pinMindItem(id)
      .then(result => dispatch({ type: PIN_MIND_ITEM.SUCCESS, payload, result }))
      .catch(error => dispatch({ error, type: PIN_MIND_ITEM.ERROR, payload }));
  };
}

export function pinProduct(id: string): ThunkAction<PinProductAction> {
  const payload = { id, type: 'product' as const };
  return (dispatch) => {
    dispatch({ type: PIN_PRODUCT.START, payload });

    return productService.pinProduct(id)
      .then(result => dispatch({ type: PIN_PRODUCT.SUCCESS, payload, result }))
      .catch(error => dispatch({ error, type: PIN_PRODUCT.ERROR, payload }));
  };
}

export function unpinDocument(
  id: string,
  type: ItemVariation
): ThunkAction<UnpinDocumentAction> {
  return (dispatch) => {
    const payload = { id, type };
    dispatch({ type: UNPIN_DOCUMENT.START, payload });

    const promise = type === 'product' ? productService.unpinProduct(id) : mindItemService.unpinMindItem(id);

    return promise
      .then(() => dispatch({ type: UNPIN_DOCUMENT.SUCCESS, payload, result: undefined }))
      .catch(error => dispatch({ error, type: UNPIN_DOCUMENT.ERROR, payload }));
  };
}
