import React, { useEffect, useCallback } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import MuLogoStacked from '../../assets/MuLogoStacked.svg';
import { fetchRecommendedCollections as fetchRecommendedCollectionsAction } from '../../actions/collections.actions';
import DashboardAllSubjectsCarousels from '../../components/Dashboard/DashboardAllSubjectsCarousels';
import constants, {
  routes,
  PINNED_CAROUSEL_LOWER_LIMIT,
  Subject,
  onboardingHints
} from '../../constants';
import SearchBar from '../../components/DashboardSearchBar';
import { useSelector } from 'react-redux';
import {
  selectRecommendedCollections,
  selectRecommendedCollectionsStatus
} from '../../reducers/collections.selectors';
import { selectPinnedContents, selectPinnedStatus } from '../../reducers/pinned.selectors';
import {
  selectRecommendedUserProducts,
  selectRecommendedProductsStatus
} from '../../reducers/recommendation.selectors';
import DashboardRecommendedProductsCarousel from '../../components/Dashboard/DashboardRecommendedProductsCarousel';
import DashboardPinnedDocumentsCarousel from '../../components/Dashboard/DashboardPinnedDocumentsCarousel';
import DashboardCuratedCollectionsCarousel from '../../components/Dashboard/DashboardCuratedCollectionsCarousel';
import querystring from 'query-string';
import TopBar from '../../components/AppLayout/TopBar';
import UpgradeBanner from '../../components/UpgradeBanner';
import { useInView } from 'react-intersection-observer';
import { selectIsOnboardingCompleted, selectUser } from '../../reducers/user.selectors';
import useRouter from '../../utils/useRouter';
import useAction from '../../utils/useAction';
import { getRecentSearches } from '../../utils/recentSearches';
import { getAmpliSearchEventPayload, mapDesignPreference, useAmplitudeExperiment } from '../../utils/ampli';
import { SearchSubmitted } from '../../ampli';
import services from '../../services';
import DashboardStudyflixCarousel from '../../components/Dashboard/DashboardStudyflixCarousel';
import OnboardingDialog, { OnboardingDialogProps } from '../../components/OnboardingDialog/OnboardingDialog';
import { useTranslation } from 'react-i18next';
import lernsetsBetaWelcomeImage from '../../assets/lernsets-beta-welcome-dialog-image.png';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { colors } from '../../assets/styles/themes/variables';
import { spaces } from '../../assets/styles/themes/tokens';
import config from 'config';
import FeedbackDialog from '../../components/modals/FeedbackDialog';
import { useNewDesign } from '../../state/DesignPreferenceContextProvider';

const filterSubjectList = (allSubjects: Subject[], userSubjects: Subject[]) => {
  if (!userSubjects) {
    return allSubjects;
  }

  return allSubjects.filter(s => !userSubjects.find(us => s.id === us.id));
};

export const carouselBreakpoints = {
  product: {
    xl: 4,
    lg: 3,
    md: 2.2,
    sm: 1.5,
    xs: 1.1,
    xxs: 1
  },
  collection: {
    xl: 3,
    lg: 2,
    md: 1,
    sm: 1,
    xs: 1,
    xxs: 1
  }
};

const DashboardPage: React.FC = () => {
  const user = useSelector(selectUser);
  const userId = user && user.id;
  const isPremiumUser = !!(user && user.isPremium);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobile'));
  const { router } = useRouter();
  const { prefersNewDesign, shouldShowDesignPreferenceSwitch } = useNewDesign();
  const fetchRecommendedCollections = useAction(fetchRecommendedCollectionsAction);
  const isOnboardingCompleted = useSelector(selectIsOnboardingCompleted);
  const randomPinnedDocs = useSelector(selectPinnedContents);
  const pinnedStatus = useSelector(selectPinnedStatus);
  const recommendedProducts = useSelector(selectRecommendedUserProducts);
  const recommendedProductsStatus = useSelector(selectRecommendedProductsStatus);
  const recommendedCollectionsStatus = useSelector(selectRecommendedCollectionsStatus);
  const recommendedCollections = useSelector(selectRecommendedCollections);
  const { t } = useTranslation();

  const {
    variant: lernsetVariant,
    isReady: isClientReady,
  } = useAmplitudeExperiment(user, config.amplitudeExperiments.enableLearnsetsFeature);
  const isLernsetsActive = isClientReady && lernsetVariant === 'on';

  const {
    variant: setNewSearchFiltersAndSortingToDefaultVariant,
    isReady: isSetNewSearchFiltersAndSortingToDefaultVariantClientReady,
  } = useAmplitudeExperiment(user, config.amplitudeExperiments.setNewSearchFiltersAndSortingToDefault);
  const isSetFiltersToDefaultActive = 
    isSetNewSearchFiltersAndSortingToDefaultVariantClientReady 
    && setNewSearchFiltersAndSortingToDefaultVariant === 'on';

  useEffect(() => {
    if (!isOnboardingCompleted) {
      router.replace(`/${routes.createProfile.root}/${routes.createProfileSubjects.root}`);
    }
  }, [isOnboardingCompleted]);

  useEffect(() => {
    fetchRecommendedCollections();
  }, [user?.id]);

  const submitSearchCallback = useCallback((query: string, subject: string | null) => {
    const selectedSubject = subject && constants.subjectsById[subject];
    const term: string = query;
    if (!query && !selectedSubject) {
      return router.push(`/${routes.search.root}`);
    }

    const recentSearches = getRecentSearches(userId);

    const prevSearch = isSetFiltersToDefaultActive ? undefined : recentSearches.find(
      search => search.term?.toLowerCase() === query.toLowerCase()
    );

    const qs = querystring.stringify({
      term: query ? query : undefined,
      ...prevSearch,
      subject: (selectedSubject && selectedSubject.id) || prevSearch?.subject,
    }, {
      skipNull: true,
    });

    if (user) {
      const searchPayload = getAmpliSearchEventPayload({
        query: Object.fromEntries(new URLSearchParams(qs)),
        term,
      });

      if (searchPayload) {
        const event = new SearchSubmitted(searchPayload as any);
        if (shouldShowDesignPreferenceSwitch) {
          event.event_properties['ui'] = mapDesignPreference(prefersNewDesign);
        }
        services.track.ampliEventTrack({ event });
      }
    }

    return router.push(`/${routes.search.root}?${qs}`);
  }, [user?.id, shouldShowDesignPreferenceSwitch, isSetFiltersToDefaultActive]);

  const userSubjects = user?.subjectsFlattened;
  const allSubjectsList = filterSubjectList(constants.subjects, userSubjects || []);

  const { ref, inView } = useInView({
    // Due to the scroll behaviour of the dashboard, we were
    // unable to set the intersection observer from the navigation bar.
    // This height represents the height of the navigation bar and half the
    // height of the search bar
    rootMargin: '-70px',
    initialInView: true,
  });

  const showRecommendationCarousel = recommendedProducts.length >= PINNED_CAROUSEL_LOWER_LIMIT;
  const showPinnedCarousel = randomPinnedDocs.length >= PINNED_CAROUSEL_LOWER_LIMIT;
  const recommendedProductsPosition = 1;
  const subjectCarouselPositions = recommendedProductsPosition + (showRecommendationCarousel ? 1 : 0);
  const studyflixCarouselPosition = recommendedProductsPosition + 1;
  const pinnedCarouselPosition = subjectCarouselPositions + (userSubjects?.length || 0);
  const recommendedCollectionsPosition = pinnedCarouselPosition + (showPinnedCarousel ? 1 : 0);

  const onboardingDialogBody = () => (
    <Box sx={{ paddingTop: 0 }}>
      <Typography variant='body1'>
        {t('Learning sets allow you to combine different materials...')}
      </Typography>
      <Typography variant='h6' fontWeight={600} color={colors.violet700} sx={{ marginTop: spaces.md }}>
        {t('And this is how it works:')}
      </Typography>
      <Typography variant='body1'>✅ {t('Find a material in the library.')}</Typography>
      <Typography variant='body1'>✅ {t('Add it to learning sets.')}</Typography>
      <Typography variant='body1'>✅ {t('Customize it in learning sets.')}</Typography>
    </Box>
  );

  const onboardingDialogProps: OnboardingDialogProps = {
    id: onboardingHints.welcomeBanner,
    title: t('Become a beta tester for a new feature!'),
    image: lernsetsBetaWelcomeImage,
    imageStyles: { height: 250, marginBottom: '0px !important' },
    primaryCta: {
      label: t('Try it out'),
      onClick: () => {
        return router.push(`/${routes.search.root}`);
      },
      dismissOnAction: true,
    },
    htmlBody: onboardingDialogBody(),
  };

  return (
    <div className='dashboard-page'>
      <FeedbackDialog />
      <UpgradeBanner />
      {isLernsetsActive && !isMobile && <OnboardingDialog {...onboardingDialogProps} />}
      <TopBar hideSearch={inView} />
      <Container className='pb-4'>
        <Row>
          <Col xs={6} sm={5} lg={3} className='m-5 mx-auto logo-col'>
            <MuLogoStacked />
          </Col>
        </Row>
        <div ref={ref}>
          <Row className='mb-5'>
            <Col>
              <SearchBar
                mainSubjects={userSubjects || []}
                otherSubjects={allSubjectsList}
                onSubmit={submitSearchCallback}
              />
            </Col>
          </Row>
        </div>
        {isPremiumUser &&
          <DashboardStudyflixCarousel
            numberOfSlides={carouselBreakpoints.product}
            carouselPosition={studyflixCarouselPosition}
          />
        }
        {showRecommendationCarousel &&
          <DashboardRecommendedProductsCarousel
            carouselPosition={recommendedProductsPosition}
            status={recommendedProductsStatus}
            items={recommendedProducts}
            numberOfSlides={carouselBreakpoints.product}
          />
        }
        <DashboardAllSubjectsCarousels
          firstCarouselPosition={subjectCarouselPositions}
          numberOfSlides={carouselBreakpoints.product}
        />
        {showPinnedCarousel &&
          <DashboardPinnedDocumentsCarousel
            carouselPosition={pinnedCarouselPosition}
            status={pinnedStatus}
            items={randomPinnedDocs}
            numberOfSlides={carouselBreakpoints.product}
          />
        }
        <DashboardCuratedCollectionsCarousel
          carouselPosition={recommendedCollectionsPosition}
          numberOfSlides={carouselBreakpoints.collection}
          status={recommendedCollectionsStatus}
          collections={recommendedCollections}
        />
      </Container>
    </div>
  );
};

export default DashboardPage;
