import { createSelector } from 'reselect';
import { StoreType } from './index';
import { CollectionProperties, ContentItem } from '../types/AppContent';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import { getMindItems } from './mindItems.selectors';
import { getProducts } from './products.selectors';
import { getContent } from './selectorHelpers';

type CollectionType = 'own' | 'followed' | 'curated';
export enum CollectionTypes {
  FOLLOWED = 'followed',
  OWN = 'own',
  CURATED = 'curated',
}

export type CollectionWithContents = CollectionProperties & {
  contents: {
    id: string;
    type: string;
  }[];
};

const getCollections = (state: StoreType) => state.collections.byId;
const getOwnCollectionsIds = (state: StoreType) => state.collections.own.items;
const getFollowedCollectionsIds = (state: StoreType) =>
  state.collections.followed.items;
const getCuratedCollectionsIds = (state: StoreType) =>
  state.collections.curated.items;
const getRecommendedCollectionsIds = (state: StoreType) =>
  state.collections.recommendations.items;

/**
 * Selector combiner helper
 *
 * Using collection ids, collection object list will map ids to full
 * collection objects.
 */
const mapIdsToCollectionData = (
  ids: string[],
  items: StoreType['collections']['byId']
): CollectionProperties[] => {
  return ids.map((id: string) => items[id].data);
};

export const selectOwnCollections = createSelector(
  [getOwnCollectionsIds, getCollections],
  mapIdsToCollectionData
);

export const selectFollowedCollections = createSelector(
  [getFollowedCollectionsIds, getCollections],
  mapIdsToCollectionData
);

export const selectCuratedCollections = createSelector(
  [getCuratedCollectionsIds, getCollections],
  mapIdsToCollectionData
);

export const selectRecommendedCollections = createSelector(
  [getRecommendedCollectionsIds, getCollections],
  mapIdsToCollectionData
);

export const selectOwnCollectionStatus = (state: StoreType) =>
  (state.collections.own && state.collections.own.status) ||
  AsyncActionStatus.LOADING;

const getCollection = (
  state: StoreType,
  id: string,
  type?: CollectionType
): CollectionProperties | undefined =>
  !type || state.collections[type].items.includes(id)
    ? state.collections.byId[id] && state.collections.byId[id].data
    : undefined;

const getContents = (state: StoreType, id: string) =>
  (state.collections.byId[id] && state.collections.byId[id].contents) || [];

export const selectCollectionWithContents = createSelector(
  [getCollection, getContents, getMindItems, getProducts],
  (collection, contents, mindItems, products) =>
    collection && {
      ...collection,
      contents: contents
        .map((i) => getContent(i.type, i.id, mindItems, products))
        .filter((v) => !!v) as ContentItem[],
    }
);

export const selectCollectionStatus = (
  state: StoreType,
  id: string,
  type?: CollectionType
): AsyncActionStatus =>
  !type ||
    state.collections[type].status === AsyncActionStatus.LOADING ||
    state.collections[type].items.includes(id)
    ? (state.collections.byId[id] && state.collections.byId[id].status) ||
    AsyncActionStatus.LOADING
    : AsyncActionStatus.NOTFOUND;

export const selectCollectionsWithContents = (state: StoreType) => {
  return state.collections.own.items.map((collectionId) => {
    const collectionContents = state.collections.byId[collectionId].contents;

    const collection: CollectionWithContents = {
      ...state.collections.byId[collectionId].data,
      contents: collectionContents,
    };

    return collection;
  });
};

export const selectIsItemInOwnCollections = (state: StoreType, itemId: string) => {
  return getOwnCollectionsIds(state).some(collectionId =>
    getContents(state, collectionId).some(contentItem => contentItem.id === itemId)
  );
};

export const selectRecommendedCollectionsStatus = (state: StoreType): AsyncActionStatus =>
  state.collections.recommendations.status;
