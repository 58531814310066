import i18next from 'i18next';
import React, { useEffect } from 'react';
import { initReactI18next } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSubscription, fetchMe, getExperiment } from '../actions/user.actions';
import { fetchCollections, fetchCuratedCollections } from '../actions/collections.actions';
import { fetchPinnedContents } from '../actions/pinned.actions';
import { fetchUserProductRecommendations } from '../actions/recommendation.actions';
import { fetchPublishers } from '../actions/publishers.actions';
import { fetchSchoolTypes } from '../actions/schoolTypes.actions';
import {
  fetchTeachingFormats,
  fetchWorkloads,
} from '../actions/search.actions';
import {
  fetchActiveLicenceModels,
} from '../actions/licenceModels.actions';
import IntercomButton from '../components/IntercomButton';
import locales from '../locales';
import config from 'config';
import cookies from '../clients/cookies';
import services from '../services';
import { trackingEvents } from '../services/tracking/trackConfig';
import numeral from 'numeral';
import 'numeral/locales/de';
import GoogleTagManager from '../components/GoogleTagManager/GoogleTagManager';
import Contexts from './contexts';
import { selectIsAuthenticated, selectUser } from '../reducers/user.selectors';
import useAction from '../utils/useAction';
import { withRouter, WithRouter } from 'found';
import { compose } from 'redux';
import SubdomainChecker from '../components/SubdomainChecker';
import { isSchoolSubdomain } from '../utils/subdomain';
import { isFeatureEnabled } from '../utils/feature';
import { routes } from '../constants';

i18next
  .use(initReactI18next)
  .init({
    debug: config.i18next.debug,
    nsSeparator: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    lng: 'de',
    resources: {
      de: {
        translation: locales.de,
      },
    },
  });

numeral.locale('de');

const AppPage: React.FunctionComponent<WithRouter> = ({
  children,
  match: {
    location: {
      pathname
    }
  }
}) => {
  const user = useSelector(selectUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const fetchMeAction = useAction(fetchMe);

  useEffect(() => {
    if (isAuthenticated) {
      fetchMeAction();
    }
  }, [cookies.getAuthToken()]);

  const userId = user && user.id;

  const fetchCollectionsAction = useAction(fetchCollections);
  const fetchCuratedCollectionsAction = useAction(fetchCuratedCollections);
  const fetchPinnedContentsAction = useAction(fetchPinnedContents);
  const fetchUserProductRecommendationsAction = useAction(fetchUserProductRecommendations);
  const getSubscriptionAction = useAction(getSubscription);
  const fetchPublishersAction = useAction(fetchPublishers);
  const fetchSchoolTypesAction = useAction(fetchSchoolTypes);
  const fetchWorkloadsAction = useAction(fetchWorkloads);
  const fetchTeachingFormatsAction = useAction(fetchTeachingFormats);
  const getExperimentAction = useAction(getExperiment);
  const fetchActiveLicenceModelsAction = useAction(fetchActiveLicenceModels);

  const fetchStateDataForLoggedUser = () => {
    fetchCollectionsAction();
    fetchCuratedCollectionsAction();
    fetchPinnedContentsAction({ sortOrder: 'desc', version: 2 });
    fetchUserProductRecommendationsAction();
    getSubscriptionAction();
    fetchPublishersAction();
    fetchSchoolTypesAction();
    fetchWorkloadsAction();
    fetchTeachingFormatsAction();
    getExperimentAction('GRO-6');
  };

  const fetchGeneralStateData = () => {
    fetchActiveLicenceModelsAction();
  };

  useEffect(() => {
    if (user) {
      fetchStateDataForLoggedUser();
      services.track.eventTrack(trackingEvents.DLpush, { user });
    }
    fetchGeneralStateData();
  }, [userId]);

  useEffect(() => {
    if (isSchoolSubdomain(window.location.hostname)) {
      cookies.deleteNonEssential();
    }
  }, [cookies.getAllCookies()]);

  // Trial information is coming delayed from zuora
  // therefore we have a separate effect to trigger a data layer push again if isInTrial: true
  useEffect(() => {
    if (user?.isInTrial) {
      services.track.eventTrack(trackingEvents.DLpush, { user });
    }
  }, [user?.isInTrial]);

  useEffect(() => {
    if (pathname.split('/')[1] !== routes.search.root) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  useEffect(() => {
    if (isAuthenticated && !user) {
      // If the user is authenticated, but no user exists in the state
      // then the user is still in the process of being fetched, so we
      // should wait until the user has been created
      return;
    }
    services.track.pageTrack(pathname, { user });
  }, [userId, pathname]);

  return (
    <Contexts>
      {children}
      <SubdomainChecker />
      {isFeatureEnabled('intercom') && <IntercomButton />}
      {isFeatureEnabled('googleAnalytics') && <GoogleTagManager />}
    </Contexts>
  );
};

export default compose<React.FC>(withRouter)(AppPage);
