import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

interface PrivacyPolicyModalProps {
  show: boolean;
  onClose: () => void;
}

const PrivacyPolicyModal: React.FC<PrivacyPolicyModalProps> = ({ show, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={onClose} size='lg'>
      <Modal.Header closeButton={true}>
        <Modal.Title>
          {t('Data protection')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <iframe className='min-height-400' src='https://www.meinunterricht.de/datenschutz/' scrolling='auto' />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='outline-secondary'
          onClick={onClose}
        >
          {t('Ok')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PrivacyPolicyModal;
