import React from 'react';
import { ImageResolutions } from '@klab-berlin/api-sdk/lib/types/responses/Common';
import { useSelector } from 'react-redux';
import ReaderPageItem from '../ReaderPageItem';
import NewBasisReaderOverlay from './NewBasisReaderOverlay';
import { selectUser } from '../../../../reducers/user.selectors';
import { ImageClarity } from '../ReaderPageItem/ReaderPageItem';

interface ReaderPagesBasisProps {
  pages: ImageResolutions[];
  zoom: number;
  mindItemId: string;
}

const imageClarity = (pageIndex: number): ImageClarity | undefined => {
  if (pageIndex === 0) {
    return undefined;
  }
  if (pageIndex === 1) {
    return 'faint';
  }
  return 'very-faint';
};

const ReaderPagesBasis: React.FC<ReaderPagesBasisProps> = ({ pages, zoom, mindItemId }) => {
  const user = useSelector(selectUser);

  if (!user) {
    return <></>;
  }

  return (
    <>
      {pages.map((page, i) => (
        <ReaderPageItem
          key={i}
          imageClarity={imageClarity(i)}
          id={`page-container-${i}`}
          pageSrc={page.w2480}
          zoom={zoom}
        >
          <NewBasisReaderOverlay
            currentPage={i + 1}
            pageLimit={pages.length}
            downloadCounter={user.downloads.counter}
            downloadMax={user.downloads.max}
            mindItemId={mindItemId}
          />
        </ReaderPageItem>
      ))}
    </>
  );
};

export default ReaderPagesBasis;
