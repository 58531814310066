import Icon from '../Icon';
import React from 'react';

interface ContextControlButtonProps {
  onStarClick: (input: boolean) => void;
  contextState: boolean;
  className?: string;
}

const ContextControlButton: React.FC<ContextControlButtonProps> = ({ onStarClick, contextState, className }) => {

  return (
    <div className={`d-none d-md-flex justify-content-end align-items-center h-100 font-size-12 ${className}`}>
      <div
        className='cursor-pointer'
        onClick={() => onStarClick(!contextState)}
      >
        <Icon
          className='color--yellow'
          icon={contextState ? 'star-solid' : 'star-outline'}
          size='xl'
        />
      </div>
    </div>
  );
};

export default ContextControlButton;
