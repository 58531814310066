import React from 'react';
import { Route as FoundRoute } from 'found';

interface RouteProps {
  Component: React.ComponentType;
  title?: string;
  props: any;
}

export default class Route extends FoundRoute {
  public render(routeProps?: RouteProps) {
    if (!routeProps) {
      return null;
    }
    const { Component, props } = routeProps;

    if (Component && props) {
      // All props are available in 'this.' but of some reason typings are wrong
      // @ts-ignore
      if (this.title) {
        // @ts-ignore
        document.title = this.title;
      }

      return (
        <Component {...props} />
      );
    }
    return null;
  }
}
