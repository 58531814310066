import Icon from './Icon';
import { Link, withRouter, WithRouter } from 'found';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fetchCollections } from '../actions/collections.actions';
import { routes } from '../constants';
import { StoreType } from '../reducers';
import { trackingEvents } from '../services/tracking/trackConfig';
import RouterMenuItem from './RouterMenuItem';
import WithTracking from './utils/WithTracking';
import MuBag from '../assets/MuBag.svg';
import { CollectionFormModalContext } from '../state/CollectionFormModalProvider';
import { selectOwnCollections, selectOwnCollectionStatus } from '../reducers/collections.selectors';
import { CollectionProperties } from '../types/AppContent';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import { selectDownloadCount } from '../reducers/user.selectors';
import { selectPinnedCount } from '../reducers/pinned.selectors';
import CollectionsMenuItem from './GridItem/CollectionsMenuItem';

interface StateProps {
  ownCollections: CollectionProperties[];
  collectionsStatus: AsyncActionStatus;
  downloadCount?: number;
  pinnedCount?: number;
}

type CollectionMenuProps = StateProps & WithRouter;

const iconWidth = 1;
const badgeWidth = 2;

const Section: React.FC = (props) => (
  <div className='py-3 my-3'>
    {props.children}
  </div>
);

const Badge: React.FC = (props) => (
  <Col xs={badgeWidth} className='text-muted'>{props.children}</Col>
);

const Header: React.FC = (props) => (
  <div className='text-muted pb-3 px-2 font-size-12'>
    {props.children}
  </div>
);

const CollectionMenu: React.FC<CollectionMenuProps> = ({
  ownCollections,
  match: { location: { pathname } },
  ...props
}) => {
  const { t } = useTranslation();
  const { show: showFormModal } = React.useContext(CollectionFormModalContext);

  const followedLink = `/${routes.followed.root}`;
  const curatedLink = `/${routes.curatedCollections.root}`;
  const pinnedLink = `/${routes.pinned.root}`;
  const downloadLink = `/${routes.downloads.root}`;

  return (
    <Container className='bg-transparent collections-menu px-0'>
      <Section>
        <Header>
          <Link className='text-muted text-decoration-none' to={`/${routes.collections.root}`}>
            {t('My Collections')}
          </Link>
        </Header>
        <WithTracking events={{ onClick: trackingEvents.menuCreateCollectionModalOpen }}>
          <Row
            noGutters={true}
            className='cursor-pointer p-2 text-dark menu-link'
            onClick={() => showFormModal()}
          >
            <Col className='text-primary mr-1' xs={iconWidth}>
              <Icon icon='plus' size='sm' />
            </Col>
            <Col className='text-primary'>{t('Add Collection')}</Col>
          </Row>
        </WithTracking>
        {ownCollections.length === 0 && props.collectionsStatus === 'loading' &&
          (
            <div className='mb-1'>
              <Skeleton width={218} height={26} />
              <Skeleton width={218} height={26} />
              <Skeleton width={218} height={26} />
            </div>
          )
        }
        {ownCollections.length > 0 && ownCollections.map(collection => {
          const to = `/${routes.collections.root}/${collection.id}`;
          return <CollectionsMenuItem
            key={collection.id}
            title={collection.title}
            to={to}
            isSelected={to === pathname}
            color={collection.color}
          />;
        })}

      </Section>
      <hr />
      <Section>

        <RouterMenuItem linkTo={curatedLink} isSelected={pathname === curatedLink}>
          <Row noGutters={true} className='py-1 text-dark align-items-center'>
            <Col xs={iconWidth} className='mr-2'>
              <div className='mubag-small'>
                <MuBag />
              </div>
            </Col>
            <Col>{t('Public Collections')}</Col>
          </Row>
        </RouterMenuItem>
        <RouterMenuItem linkTo={followedLink} isSelected={pathname === followedLink}>
          <Row noGutters={true} className='py-1 text-dark'>
            <Col xs={iconWidth} className='mr-2'>
              <Icon icon='arrow-right' />
            </Col>
            <Col>{t('I follow')}</Col>
          </Row>
        </RouterMenuItem>
      </Section>
      <hr />
      <Section>
        <RouterMenuItem linkTo={pinnedLink} isSelected={pathname === pinnedLink}>
          <Row noGutters={true} className='py-1 text-dark'>
            <Col xs={iconWidth} className='mr-2'>
              <Icon icon='star-solid' />
            </Col>
            <Col>{t('Pinned Documents')}</Col>
            <Badge>{props.pinnedCount || '-'}</Badge>
          </Row>
        </RouterMenuItem>
        <RouterMenuItem linkTo={downloadLink} isSelected={pathname === downloadLink}>
          <Row noGutters={true} className='py-1 text-dark'>
            <Col xs={iconWidth} className='mr-2'>
              <Icon icon='download' />
            </Col>
            <Col>{t('Downloads')}</Col>
            <Badge>{props.downloadCount || '-'}</Badge>
          </Row>
        </RouterMenuItem>
      </Section>
    </Container>
  );
};

const mapState = (state: StoreType): StateProps => ({
  ownCollections: selectOwnCollections(state),
  collectionsStatus: selectOwnCollectionStatus(state),
  downloadCount: selectDownloadCount(state),
  pinnedCount: selectPinnedCount(state),
});

export default compose<React.FC>(
  withRouter,
  connect(
    mapState,
    { fetchCollections },
  ),
)(CollectionMenu);
