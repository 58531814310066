import React, { useContext } from 'react';
import { DownloadModalContext } from '../../../state/DownloadModalProvider';
import { ShareModalItemTypeEnum } from '../../../types/sharing';
import PinItemButton from '../../PinItemButton';
import { ReaderContext } from '../ReaderContext';
import WithTooltip from '../../WithTooltip';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../reducers/user.selectors';
import ActionButton from '../../ActionButton';
import ReaderControls from './ReaderControls';
import ManageItemInCollectionsButton from '../../ManageItemInCollectionsButton';
import ShareItemButton from '../../ShareItemButton';
import exitFullscreen from '../../utils/exitFullScreen';
import { selectMindItem } from '../../../reducers/mindItems.selectors';
import { useAmplitudeExperiment } from '../../../utils/ampli';
import config from 'config';

interface ReaderActionsProps {
  hideDownloadAction?: boolean;
  fullscreenContainer?: HTMLElement;
}

const ReaderActions: React.FC<ReaderActionsProps> = (props) => {
  const user = useSelector(selectUser);
  const {
    show: showDownloadModal,
    isOpen: isDownloadModalOpen
  } = useContext(DownloadModalContext);
  const {
    variant: lernsetVariant,
    isReady: isClientReady,
  } = useAmplitudeExperiment(user, config.amplitudeExperiments.enableLearnsetsFeature);
  const { isPinned, mindItemId } = useContext(ReaderContext);
  const mindItem = useSelector(state => selectMindItem(state, mindItemId));
  const { t } = useTranslation();
  const isLernsetsActive = user?.isPremium && isClientReady && lernsetVariant === 'on';
  const hideDownloadAction = props.hideDownloadAction || user?.hideDownloadAction || isLernsetsActive;
  
  return (
    <ReaderControls>
      {!user?.hideCollectionAction &&
        <WithTooltip text={t('Add to collection')} placement='left' container={props.fullscreenContainer}>
          <ManageItemInCollectionsButton
            itemData={mindItem}
            preOpenModal={exitFullscreen}
            documentId={mindItemId}
            type='mindItem'
          />
        </WithTooltip>
      }
      {!hideDownloadAction &&
        <WithTooltip text={t('Download')} placement='left' container={props.fullscreenContainer}>
          <ActionButton
            onClick={() => exitFullscreen(() =>
              showDownloadModal(mindItemId))}
            icon='download'
            testId='reader__download-button'
            highlight={isDownloadModalOpen}
          />
        </WithTooltip>
      }
      <WithTooltip text={t('Share')} placement='left' container={props.fullscreenContainer}>
        <ShareItemButton
          type={ShareModalItemTypeEnum.MINDITEM}
          itemId={mindItemId}
          preOpenModal={exitFullscreen}
        />
      </WithTooltip>
      {!user?.hidePinAction && <>
        <WithTooltip text={isPinned ? t('Unpin') : t('Pin')} placement='left' container={props.fullscreenContainer}>
          <PinItemButton
            isPinned={isPinned}
            documentId={mindItemId}
            itemData={mindItem}
            type='mindItem'
          />
        </WithTooltip>
      </>}
    </ReaderControls>
  );
};

ReaderActions.defaultProps = {
  hideDownloadAction: false
};

export default ReaderActions;
