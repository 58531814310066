import classNames from 'classnames';
import React from 'react';
import TruncateNoExpand from '../TruncateNoExpand';
import './cardDescription.scss';

interface CardDescriptionProps {
  description?: string;
  numLines: number;
  className?: string;
}

const CardDescription: React.FC<CardDescriptionProps> = (props) => {
  return (
    <div className={classNames('card-description__description', props.className)}>
      <TruncateNoExpand
        lines={props.numLines}
        text={props.description || ''}
        showMoreClassName='card-description__show-more color--highlight-blue'
      />
    </div>
  );
};

export default React.memo(CardDescription);
