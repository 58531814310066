import { useTranslation } from 'react-i18next';
import { SortByOption } from '@klab-berlin/api-sdk/lib/types/requests/Common';

export type Option = {
  name: string;
  value: SortByOption;
};

export const getSortFilterOptions = (): Option[] => {
  const { t } = useTranslation();

  return [
    {
      name: t('Relevance'),
      value: 'relevance'
    },
    {
      name: t('Recently added'),
      value: 'createdDate'
    },
    {
      name: t('Publisher Release Date'),
      value: 'publisherReleaseDate'
    },
    {
      name: t('Popularity'),
      value: 'popularity'
    }
  ] as Option[];
};
