
import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../Icon';
import './readOnlyTag.scss';

const ReadOnlyTag = () => {
  const { t } = useTranslation();

  return (
    <div className='read-only-tag'>
      <Icon icon='view' /> &nbsp;
      { t('Read Only Content') }    
    </div>
  );
};

export default ReadOnlyTag;
