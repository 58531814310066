exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "#reader-document-content{background-color:#fff;display:grid;grid-template-columns:56px 1fr 56px;grid-template-areas:\"controls body actions\";position:relative;overflow-y:auto;overflow-x:hidden}#reader-document-content .page-container{min-width:560px}#reader-document-content .page-container .page{box-shadow:0 3px 8px rgba(0,0,0,.14)}.reader-document-content--full-screen{width:100vw}.content__controls{grid-area:controls}.content__body{grid-area:body}.content__actions{grid-area:actions}.reader-document-content__mobile-pages-wrapper{height:100%;overflow-y:auto;padding-top:57px}", ""]);
// Exports
exports.locals = {
	"collectionGridGap": "15px",
	"collectionGridItemHeight": "211px",
	"collectionGridItemWidth": "225px",
	"collectionListHeight": "84px",
	"greyLightest": "#f7f9fa",
	"greyLight2": "#d8d8d8",
	"neuVeryLightBlue": "#e9ebec",
	"muBlue": "#4298b5",
	"gridBreakPoints_-xs": "0",
	"gridBreakPoints_-sm": "576px",
	"gridBreakPoints_-md": "768px",
	"gridBreakPoints_-lg": "992px",
	"gridBreakPoints_-xl": "1440px",
	"medium": "768px",
	"large": "1024px",
	"xlarge": "1440px",
	"navigationLayoutMobileHeaderHeight": "57px"
};