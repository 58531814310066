exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".product-content{padding-bottom:75px;width:100%}.product-content__container__mind-items-grid{display:grid;row-gap:64px}@media only screen and (max-width: 575px){.product-content__container__mind-items-grid{row-gap:32px}}.product-content__container__mind-items-list{display:grid;row-gap:0}@media only screen and (max-width: 575px){.product-content__container__mind-items-list{row-gap:32px}}.product-content__mind-items__grid{display:grid;row-gap:64px;justify-content:center;width:100%}@media only screen and (max-width: 575px){.product-content__mind-items__grid{row-gap:32px}}@media only screen and (max-width: 767px){.product-content__mind-items__grid{grid-template-columns:repeat(auto-fill, 288px);column-gap:96px}}@media only screen and (min-width: 768px){.product-content__mind-items__grid{grid-template-columns:repeat(auto-fill, 256px);column-gap:96px}}@media only screen and (min-width: 1440px){.product-content__mind-items__grid{grid-template-columns:repeat(auto-fill, 256px);column-gap:75px}}.product-content__mind-items__list>.product-content__mind_item{margin-bottom:40px}.product-content__view-switch{display:none;margin-right:16px}@media only screen and (min-width: 1024px){.product-content__view-switch{display:flex;align-items:center}}", ""]);
// Exports
exports.locals = {
	"collectionGridGap": "15px",
	"collectionGridItemHeight": "211px",
	"collectionGridItemWidth": "225px",
	"collectionListHeight": "84px",
	"greyLightest": "#f7f9fa",
	"greyLight2": "#d8d8d8",
	"neuVeryLightBlue": "#e9ebec",
	"muBlue": "#4298b5",
	"gridBreakPoints_-xs": "0",
	"gridBreakPoints_-sm": "576px",
	"gridBreakPoints_-md": "768px",
	"gridBreakPoints_-lg": "992px",
	"gridBreakPoints_-xl": "1440px",
	"medium": "768px",
	"large": "1024px",
	"xlarge": "1440px"
};