import { formatDistanceStrict } from 'date-fns';
import de from 'date-fns/locale/de';

const timeSinceLastUpdate = (lernsetUpdatedDate: string) => {
  const now = new Date();
  const updatedDate = new Date(lernsetUpdatedDate);

  return formatDistanceStrict(
    updatedDate, now, { addSuffix: true, locale: de }
  );
};

export default timeSinceLastUpdate;
