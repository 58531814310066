import React, { useCallback, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import * as yup from 'yup';
import { routes } from '../../constants';
import Spinner from 'react-bootstrap/Spinner';
import services from '../../services';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { password } from '../../validators';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import { Link } from 'found';
import i18next from 'i18next';
import { useForm } from 'react-hook-form';
import FormInput from '../forms/FormInput';

interface SetPasswordProps {
  onSetPasswordSuccess: (email: string, password: string) => void;
  token?: string;
}
interface SetPasswordPayload {
  password: string;
  passwordRepeat: string;
}

const setPasswordFormValidator = yup.object<SetPasswordPayload>({
  password: password(),
  passwordRepeat: password()
    .oneOf([yup.ref('password')], ('Passwords must match')),
});

const getErrorMessage = (t: i18next.TFunction, error: Error) => {
  if (error.message.match(/Unauthorized - Password is the same/)) {
    return t('You can\'t use the same password again');
  }

  return t('Please try to refresh the page or set your password again');
};

const SetPassword: React.FC<SetPasswordProps> = (props) => {
  const [error, setError] = useState<Error | null>(null);
  const { t } = useTranslation();
  const {
    errors,
    handleSubmit,
    register,
    formState,
  } = useForm<SetPasswordPayload>({ mode: 'onBlur', validationSchema: setPasswordFormValidator });

  const onSubmit = handleSubmit(useCallback(async (payload: SetPasswordPayload) => {
    try {
      const result = await services.user.resetPassword(payload.password, payload.passwordRepeat, props.token || '');
      props.onSetPasswordSuccess(result.email, payload.password);
    } catch (error: any) {
      setError(error);
    }
  }, [props.token]));

  return (
    <>
      <Row>
        <Col>
          {error && (
            <Alert variant='danger'>
              <Alert.Heading>{t('Ups, something went wrong')}</Alert.Heading>
              <p>{getErrorMessage(t, error)}</p>
            </Alert>
          )}
          <form onSubmit={onSubmit} className='py-3 login-form'>
            <fieldset disabled={formState.isSubmitting}>
              <Row>
                <Col>
                  <FormInput
                    required={true}
                    name='password'
                    placeholder={t('Password')}
                    id='password'
                    type='password'
                    errors={errors}
                    ref={register}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormInput
                    required={true}
                    name='passwordRepeat'
                    placeholder={t('Repeat Password')}
                    id='passwordRepeat'
                    type='password'
                    errors={errors}
                    ref={register}
                  />
                </Col>
              </Row>
              <Button variant='primary' type='submit' className='login-button w-100'>
                {formState.isSubmitting ? <Spinner animation='border' /> : t('Next')}
              </Button>
            </fieldset>
          </form>
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col>
          <Col className='d-flex justify-content-between border-top py-4 px-0'>
            <Link to={`/${routes.login.root}`}>
              {t('Back to login')}
            </Link>
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default SetPassword;
