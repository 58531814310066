import React, { useEffect } from 'react';
import { CarouselTrackingProperties } from '../../types/AppContent';
import { SlideDefinition } from '../Carousel/Carousel';
import CarouselSkeleton from '../Carousel/CarouselSkeleton';
import DashboardCarousel from './DashboardCarousel';
import DashboardSlideItem from './DashboardSlideItem';
import { useTranslation } from 'react-i18next';
import { getProductsBySubjectId, getProductsBySubjectIdStatus } from '../../reducers/dashboard.selectors';
import { useSelector } from 'react-redux';
import { selectUser } from '../../reducers/user.selectors';
import { routes } from '../../constants';
import { SearchFilters, SearchResultsTab } from '../../types/search';
import { AsyncActionStatus } from '../../actions/common.actionTypes';
import { CAROUSEL_DEFAULT_LENGTH } from '../../constants';
import querystring from 'query-string';
import { GetProductsOptions } from '@klab-berlin/api-sdk/lib/services/product';
import { fetchProductsByQuery as fetchProductsByQueryAction } from '../../actions/product.actions';
import useAction from '../../utils/useAction';

interface DashboardStudyflixCarouselProps {
  carouselPosition: number;
  numberOfSlides: SlideDefinition;
}

const DashboardStudyflixCarousel: React.FC<DashboardStudyflixCarouselProps> = (props) => { 
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const keyPrefix: string = 'Studyflix';
  const userSubjects = user?.subjectsFlattened || [];
  const subjectQueryParameter: string = userSubjects.map(sub => sub.id).join(','); 
  const loadingStatus = useSelector(state => getProductsBySubjectIdStatus(state, subjectQueryParameter, keyPrefix));  
  const queryOptions: GetProductsOptions = { 
    limit: CAROUSEL_DEFAULT_LENGTH, 
    sort: 'desc', 
    sortBy: 'createdDate' 
  };
  const fetchProductsByQuery = useAction(fetchProductsByQueryAction);
  const products = useSelector(state => getProductsBySubjectId(state, subjectQueryParameter, keyPrefix));
 
  useEffect(() => {
    if (!user) {
      return;
    }    

    fetchProductsByQuery({
      includePublishers: 'Studyflix',
      subjects: userSubjects.map(sub => sub.id),
      keyPrefix,
    }, queryOptions);
  }, [user?.subjects]);

  const carouselTrackingData: CarouselTrackingProperties = {
    carouselPosition: props.carouselPosition,
    carouselDocumentIds: products.map(product => product.id),
    carouselType: 'carousel',
  };

  const searchParams: SearchFilters = {
    sortBy: 'createdDate',
    publisher: ['Studyflix'],
  };

  const qs = querystring.stringify(searchParams, { skipNull: true });
  const uri = `/${routes.search.root}/${SearchResultsTab.PRODUCTS}?${qs}`;

  return (
    <DashboardCarousel
      {...carouselTrackingData}
      title={`${t('New: Share videos from Studyflix with your students')}`}
      uri={uri}
      numberOfSlides={props.numberOfSlides}
      slides={products.map(product => (
        product ? <DashboardSlideItem
          {...carouselTrackingData}
          key={product.id}
          item={product}
          redirectToMindItemId={product.mindItems[0]}
          type={'product'}
        /> : null
      ))}
      infinite={true}
      isLoading={!loadingStatus || loadingStatus === AsyncActionStatus.LOADING}
      skeleton={
        <CarouselSkeleton
          height={376}
          numberOfSlides={props.numberOfSlides}
          width={256}
        />
      }
    />
  );
};

export default DashboardStudyflixCarousel;
