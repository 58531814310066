import React from 'react';
import { useTranslation } from 'react-i18next';
import { trackingEvents } from '../../../services/tracking/trackConfig';
import { openItem } from '../../../utils';
import { CtxButton } from '../ContextUtils';
import WithTracking from '../../utils/WithTracking';
import { ContentItem } from '../../../types/AppContent';
import useRouter from '../../../utils/useRouter';

interface OpenItemContextButtonProps {
  item: ContentItem;
}

const OpenItemContextButton: React.FC<OpenItemContextButtonProps> = ({ item }) => {
  const { router } = useRouter();
  const { t } = useTranslation();
  return (
    <WithTracking events={{ onClick: trackingEvents.itemOpen }}>
      <CtxButton icon='view' onClick={() => openItem(router, item.id, item.type)}>
        {t('View')}
      </CtxButton>
    </WithTracking>
  );
};

export default OpenItemContextButton;
