import api from '../clients/api';
import { ManageCollectionRequest, SearchRequest } from '@klab-berlin/api-sdk/lib/types/requests/Common';
import {
  GetProductsOptions,
  GetProductsQuery,
  GetProductByIdQuery,
  RecommendationType,
} from '@klab-berlin/api-sdk/lib/services/product';
import { handleUnauthorized } from '../utils/apiWrapper';
import { notifyBugsnagNetwork } from '../utils/bugsnagClient';
import { AttachmentsDownloadRequest } from '@klab-berlin/api-sdk/lib/types/requests/Product';

const service = {
  getProduct,
  getProductsByQuery,
  getProductRecommendations,
  getProductMindItems,
  manageCollections,
  pinProduct,
  unpinProduct,
  getAttachments,
  getAttachmentZipLink, 
  handleProductAttachmentsDownload,
  search,
};

function getProduct(productId: string, query?: GetProductByIdQuery) {
  notifyBugsnagNetwork('product', 'getProduct', { productId });
  return handleUnauthorized(() => api.product.getProduct(productId, query));
}

function getProductsByQuery(
  query: GetProductsQuery,
  options: GetProductsOptions
) {
  notifyBugsnagNetwork('product', 'getProductsByQuery', { query, options });
  return handleUnauthorized(() =>
    api.product.getProductsByQuery(query, options)
  );
}

function getProductRecommendations(
  type: RecommendationType,
  referenceId: string,
  limit?: number
) {
  notifyBugsnagNetwork('product', 'getProductRecommendations', { type, referenceId, limit });
  return handleUnauthorized(() => 
    api.product.getProductRecommendations(type, referenceId, limit));
}

function getProductMindItems(productId: string) {
  notifyBugsnagNetwork('product', 'getProductMindItems', { productId });
  return handleUnauthorized(() => api.product.getProductMindItems(productId));
}

function manageCollections(
  productId: string,
  payload: ManageCollectionRequest
) {
  notifyBugsnagNetwork('product', 'manageCollections', { productId, payload });
  return handleUnauthorized(() =>
    api.product.manageItemInMultipleCollections(productId, payload)
  );
}

function pinProduct(productId: string) {
  notifyBugsnagNetwork('product', 'pinProduct', { productId });
  return handleUnauthorized(() => api.product.pinProduct(productId));
}

function unpinProduct(productId: string) {
  notifyBugsnagNetwork('product', 'unpinProduct', { productId });
  return handleUnauthorized(() => api.product.unpinProduct(productId));
}

function getAttachments(productId: string) {
  notifyBugsnagNetwork('product', 'getAttachments', { productId });
  return handleUnauthorized(() => api.product.getAttachments(productId));
}

function getAttachmentZipLink(zipID: string) {
  notifyBugsnagNetwork('product', 'getAttachmentZipLink', { zipID });
  return handleUnauthorized(() => api.product.getAttachmentZipLink(zipID));
}

function handleProductAttachmentsDownload(productId: string, payload: AttachmentsDownloadRequest[]) {
  notifyBugsnagNetwork('product', 'handleProductAttachmentsDownload', { productId, payload });
  return handleUnauthorized(() => api.product.handleProductAttachmentsDownload(productId, payload));
}

function search(searchParams: SearchRequest) {
  return handleUnauthorized(() => api.product.search(searchParams));
}

export default service;
