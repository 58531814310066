import React from 'react';
import { LicenceModel } from '@klab-berlin/api-sdk/lib/types/responses/Billing';
import { useTranslation } from 'react-i18next';
import { currencyFormat } from '../../../../utils/format';
import './../licenceInfoArea.scss';

interface OwnProps {
  newTotalPrice?: number;
  licenceModel: LicenceModel;
}

const TotalPrice:  React.FC<OwnProps> = ({ newTotalPrice, licenceModel }) => {
  const { t } = useTranslation();
  const hasNewPrice = newTotalPrice !== undefined;
  const currentPrice = hasNewPrice ? newTotalPrice : licenceModel.totalPrice;
  const previousPrice = hasNewPrice && licenceModel.totalPrice;

  return (
    <div className='licence-info-area__payment-details__row'>
      <div className='licence-info-area__payment-details__total'>
        {t('Total')}
      </div>
      <div 
        data-testid='payment-details__total-price'
        className='licence-info-area__payment-details__price'
      >
        {
          previousPrice && 
            <span 
              data-testid='payment-details__previous-price'
              className='licence-info-area__payment-details__previous-price'
            >
              {currencyFormat(previousPrice)}
            </span>
        } 
        {currencyFormat(Number(currentPrice))}
      </div>
    </div>
  );
};

export default TotalPrice;
