import React from 'react';
import { Chip } from '@mui/material';
import EmptyCircle from '../../../assets/empty-circle.svg';
import SelectedCircle from '../../../assets/selected-circle.svg';
import { colors } from '../../../assets/styles/themes/variables';

interface FilterChipProps {
  value: string;
  label: string;
  onClick: () => void;
}

const FilterChip: React.FC<FilterChipProps> = ({ value, label, onClick }) => {
  const [isSelected, setIsSelected] = React.useState(false);

  const handleClick = () => {
    setIsSelected(!isSelected);
    onClick();
  };

  return (  
    <Chip 
      key={value}
      label={label}
      onClick={handleClick}
      icon={isSelected ? <SelectedCircle /> : <EmptyCircle />}
      clickable
      sx={{
        border: `1px solid ${colors.neutral200}`,
        background: 'transparent',
        '&:hover': {
          background: 'transparent',
        }
      }}
    />
  );
};

export default FilterChip;
