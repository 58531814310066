import services from '../services';
import { AsyncActionStatus, ThunkAction } from './common.actionTypes';
import { FetchSchoolTypesAction, FETCH_SCHOOL_TYPES } from './schoolTypes.actionsTypes';

export function fetchSchoolTypes(): ThunkAction<FetchSchoolTypesAction> {
  return async (dispatch, getState) => {
    if (getState().schoolTypes.status === AsyncActionStatus.READY) {
      return;
    }

    dispatch({ type: FETCH_SCHOOL_TYPES.START });

    return services.text.fetchSchoolTypes()
      .then(result => dispatch({ type: FETCH_SCHOOL_TYPES.SUCCESS, result }))
      .catch(error => dispatch({ type: FETCH_SCHOOL_TYPES.ERROR, error }));
  };
}
