import React, { useState, useEffect } from 'react';
import { Box, Button, Dialog, DialogTitle, IconButton, Theme, useMediaQuery } from '@mui/material';
import { borderRadius, customSizes, palette, spaces } from '../../../assets/styles/themes/tokens';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../assets/styles/themes/variables';
import CloseIcon from '../../../assets/close.svg';
import { FilterOption, SearchFiltersAccordion } from './SearchFilterAccordion';
import { useSelector } from 'react-redux';
import { selectSchoolTypes } from '../../../reducers/schoolTypes.selectors';
import DialogActions from '../../Dialog/DialogActions';
import { selectPublishers } from '../../../reducers/publishers.selectors';
import { getSubTopics, selectTeachingFormats, selectWorkloads } from '../../../reducers/search.selectors';
import sortAlphabetically from '../../../utils/sortAlphabetically';
import constants from '../../../constants';
import { searchTopics } from '../../../actions/search.actions';
import useAction from '../../../utils/useAction';

interface SearchFiltersDialogProps {
  isOpen: boolean;
  handleClose?: () => void;
}

type ExpandedState = {
  subject: boolean;
  classYear: boolean;
  schoolType: boolean;
  publisher: boolean;
  subTopic: boolean;
  learningFormat: boolean;
  teachingFormat: boolean;
  workload: boolean;
};

type Options = {
  [K in keyof ExpandedState]: FilterOption[];
};

const SearchFiltersDialog: React.FC<SearchFiltersDialogProps> = ({ isOpen, handleClose }) => {
  const { t } = useTranslation();
  const publishers = useSelector(selectPublishers);
  const schoolTypes = useSelector(selectSchoolTypes);
  const teachingFormats = useSelector(selectTeachingFormats);
  const workloads = useSelector(selectWorkloads);
  const subtopicsSelector = useSelector(getSubTopics);
  const searchTopicsAction = useAction(searchTopics);

  const accordionsInitialState = {
    subject: true,
    classYear: true,
    schoolType: true,
    publisher: true,
    subTopic: false,
    learningFormat: false,
    teachingFormat: false,
    workload: false,
  };
 
  const optionsInitialState = {
    subject: [],
    classYear: [],
    schoolType: [],
    publisher: [],
    subTopic: [],
    learningFormat: [],
    teachingFormat: [],
    workload: [],
  };

  const [expanded, setExpanded] = useState<ExpandedState>(accordionsInitialState);
  const [filterOptions, setFilterOptions] = useState<Options>(optionsInitialState);

  const toggleAccordion = (accordion: keyof ExpandedState) => {
    setExpanded((prev) => ({ ...prev, [accordion]: !prev[accordion] }));
  };

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobile'));
  const isAnySubjectSelected = filterOptions.subject.some((subject) => subject.isSelected);
  const numberOfSelectedSubjects = filterOptions.subject.filter((subject) => subject.isSelected).length;

  useEffect(() => {
    const subjectOptions: FilterOption[] = sortAlphabetically(constants.subjects, { field: 'text' })
      .map(({ text, id }) => ({ name: text, value: id, isSelected: false }));
    setFilterOptions((prev) => ({ ...prev, subject: subjectOptions }));
  }, []);
  
  useEffect(() => {
    const classYearOptions = Array.from({ length: 13 }, (_, i) => i + 1).map((year) => ({
      name: year.toString(),
      value: year.toString(),
      isSelected: false,
    }));
    setFilterOptions((prev) => ({ ...prev, classYear: classYearOptions }));
  }, []);

  useEffect(() => {
    const schoolTypeOptions = schoolTypes
      .filter(schoolType => schoolType.searchId)
      .map(schoolType => ({
        name: schoolType.name,
        value: schoolType.searchId as string,
        isSelected: false,
      }));
    if (schoolTypeOptions.length > 0) {
      setFilterOptions((prev) => ({ ...prev, schoolType: schoolTypeOptions }));
    }
  }, [schoolTypes.length]);

  useEffect(() => {
    const publisherOptions = publishers.map(publisher => ({
      name: publisher.textValue,
      value: publisher.id,
      isSelected: false,
    }));
    if (publisherOptions.length > 0) {
      setFilterOptions((prev) => ({ ...prev, publisher: publisherOptions }));
    }
  }, [publishers.length]);

  useEffect(() => {
    const teachingFormatOptions = teachingFormats.map((format) => 
      ({ name: format.name, value: format.id, isSelected: false }));
    if (teachingFormatOptions.length > 0) {
      setFilterOptions((prev) => ({ ...prev, teachingFormat: teachingFormatOptions }));
    }
  }, [teachingFormats.length]);

  useEffect(() => {
    const learningFormatsOptions = constants.learningFormats.map((format) => 
      ({ name: format.text, value: format.value, isSelected: false }));
    if (learningFormatsOptions.length > 0) {
      setFilterOptions((prev) => ({ ...prev, learningFormat: learningFormatsOptions }));
    }
  }, []);

  useEffect(() => {
    const workloadOptions = workloads.map((workload) =>
      ({ name: workload.name, value: workload.id, isSelected: false }));
    if (workloadOptions.length > 0) {
      setFilterOptions((prev) => ({ ...prev, workload: workloadOptions }));
    }
  }, [workloads.length]);

  useEffect(() => {
    const selectedSubjects = filterOptions.subject.filter((subject) => subject.isSelected);
    const subTopicOptions: FilterOption[] = [];

    selectedSubjects.reduce((acc, subject) => {
      const subTopics = subtopicsSelector[subject.value];
      if (subTopics) {
        const subjectSubTopics = subTopics.map((subTopic) => ({
          name: subTopic.name,
          value: subTopic.value,
          isSelected: false,
        }));
        if (subjectSubTopics.length > 0) acc.push(...subjectSubTopics);
      }
      return acc;
    }, subTopicOptions);

    setFilterOptions((prev) => ({ ...prev, subTopic: subTopicOptions }));
  }, [Object.keys(subtopicsSelector).length, numberOfSelectedSubjects]);

  const selectOption = (accordion: keyof ExpandedState, value: FilterOption['value']) => {
    setFilterOptions((prev) => ({
      ...prev,  // Keep the other accordions as they are
      [accordion]: prev[accordion].map((filterOption) => {
        if (filterOption.value === value) {  // If this is the selected option
          return { ...filterOption, isSelected: !filterOption.isSelected };  // Toggle isSelected
        }
        return filterOption;  // Otherwise, return the option as it is
      }),
    }));
    
    if (accordion === 'subject') {
      searchTopicsAction(value);
    }
  };
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between('mobile', 'tablet'));
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('tablet'));

  const getMobileSx = () => {
    const mobileHeight = '100vh';
    return {
      width: '100vw',
      maxWidth: '100vw',
      margin: 0,
      maxHeight: mobileHeight,
      height : mobileHeight,
    };
  };

  const getTabletSx = () => {
    const height = `calc(100vh - ${2 * parseInt(customSizes.filtersDialog.tabletMargin) + 'px'})`;
    const width = `calc(100vh - ${2 * parseInt(customSizes.filtersDialog.tabletMargin) + 'px'})`;

    return {
      margin: customSizes.filtersDialog.tabletMargin,
      width,
      maxWidth: 'unset',
      height: height,
      maxHeight: height,
    };
  };

  const getDesktopSx = () => {
    return {
      height: `calc(100vh - ${2 * parseInt(customSizes.filtersDialog.desktopMargin) + 'px'})`,
      width: customSizes.filtersDialog.desktopWidth,
      maxWidth: 'unset',
    };
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='search-filters-dialog'
      data-testid='search-filters-dialog'
      fullWidth={true}
      disableScrollLock={true}
      sx={{
        '& .MuiDialog-container': {
          ...(isMobile && {
            alignItems: 'flex-start',
          }),
        }
      }}
      PaperProps={{
        sx: {
          borderRadius: borderRadius.md,
          ...(isMobile && getMobileSx()),
          ...(isTablet && getTabletSx()),
          ...(isDesktop && getDesktopSx()),
        },
        elevation: 3,
      }}>
      <Box sx={{
        borderBottom: `1px solid ${colors.neutral200}`,
        padding: isMobile ? spaces.md : spaces.lg,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: spaces.md,
      }}>
        <DialogTitle variant='h5' sx={{ display: 'inline', padding: 0 }}>{t('Filters')}</DialogTitle>
        <IconButton
          color='secondary'
          aria-label='close-dialog'
          data-testid='search-fitlers-dialog-close-button'
          onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <SearchFiltersAccordion
        title={t('Subjects')}
        isExpanded={expanded.subject}
        options={filterOptions.subject}
        selectOption={(subject) => selectOption('subject', subject)}
        toggleAccordion={() => toggleAccordion('subject')}
      />
      <SearchFiltersAccordion
        title={t('Class years')}
        isExpanded={expanded.classYear}
        options={filterOptions.classYear}
        selectOption={(classYear) => selectOption('classYear', classYear)}
        toggleAccordion={() => toggleAccordion('classYear')}
      />
      <SearchFiltersAccordion
        title={t('School Type')}
        isExpanded={expanded.schoolType}
        options={filterOptions.schoolType}
        selectOption={(schoolType) => selectOption('schoolType', schoolType)}
        toggleAccordion={() => toggleAccordion('schoolType')}
      />
      <SearchFiltersAccordion
        title={t('Publisher')}
        isExpanded={expanded.publisher}
        options={filterOptions.publisher}
        selectOption={(publisher) => selectOption('publisher', publisher)}
        toggleAccordion={() => toggleAccordion('publisher')}
      />
      <SearchFiltersAccordion
        title={t('SubTopic')}
        isExpanded={expanded.subTopic}
        options={filterOptions.subTopic}
        selectOption={(subTopic) => selectOption('subTopic', subTopic)}
        toggleAccordion={() => toggleAccordion('subTopic')}
        isDisabled={!isAnySubjectSelected}
      />
      <SearchFiltersAccordion
        title={t('Learning format')}
        isExpanded={expanded.learningFormat}
        options={filterOptions.learningFormat}
        selectOption={(learningFormat) => selectOption('learningFormat', learningFormat)}
        toggleAccordion={() => toggleAccordion('learningFormat')}
      />
      <SearchFiltersAccordion
        title={t('Teaching Format')}
        isExpanded={expanded.teachingFormat}
        options={filterOptions.teachingFormat}
        selectOption={(teachingFormat) => selectOption('teachingFormat', teachingFormat)}
        toggleAccordion={() => toggleAccordion('teachingFormat')}
      />
      <SearchFiltersAccordion
        title={t('Workload')}
        isExpanded={expanded.workload}
        options={filterOptions.workload}
        selectOption={(workload) => selectOption('workload', workload)}
        toggleAccordion={() => toggleAccordion('workload')}
      />
      <DialogActions
        sx={{
          borderTop: `1px solid ${palette.elevation.outlined}`,
          ...(isMobile ? {
            flexDirection: 'column-reverse',
            padding: spaces.md,
            '& > button': {
              alignSelf: 'stretch',
            },
          } : {
            justifyContent: 'space-between',
          }),
        }}
      >
        <Button variant='outlined' color='primary'>{t('Clear all')}</Button>
        <Button variant='contained' color='primary'>{t('Show results')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SearchFiltersDialog;
