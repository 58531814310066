import React from 'react';
import { useTranslation } from 'react-i18next';
import NewReaderOverlay from '../../../NewReaderOverlay';
import { WatermarkTrackingObject } from '../NewBasisReaderOverlay';
import UnlockPremium from '../UnlockPremium';
import OfferPoints from '../../../../../Upgrade/PackageCards/OfferPoints';
import { ampliConversionPath } from '../../../../../../constants';

interface UpgradeInfoOverlayProps {
  trackingObject: WatermarkTrackingObject;
}

const UpgradeInfoOverlay: React.FC<UpgradeInfoOverlayProps> = ({ trackingObject }) => {
  const { t } = useTranslation();
  const offerPoints = [
    '100 downloads per month',
    'Discover content without watermarks',
    'Every week new content',
    'Limitless reading content online',
    'Videos and audio files',
    'Webinars for your further education'
  ];

  return (
    <NewReaderOverlay emoji='🚀' title={`meinUnterricht ${t('Premium')}`}>
      <NewReaderOverlay.BodyText>
        <OfferPoints areAvailable={true} points={offerPoints} />
      </NewReaderOverlay.BodyText>
      <UnlockPremium
        emoji='🚀'
        text={t('Select package')}
        minditem_id={trackingObject.minditem_id}
        current_page={trackingObject.current_page}
        page_count={trackingObject.page_count}
        conversionPath={ampliConversionPath.DocumentOverlay}
      />
    </NewReaderOverlay>
  );
};

export default UpgradeInfoOverlay;
