import React from 'react';
import { useTranslation } from 'react-i18next';
import EmptyState from '../../EmptyState';

const NothingContext: React.FC = () => {
  const { t } = useTranslation();
  return (
    <EmptyState
      iconName='text-file'
      text={[t('Please select a document from the left')]}
    />
  );
};

export default NothingContext;
