import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import {
  useMultipleSelection,
  useSelect,
  UseSelectState,
  UseSelectStateChange,
  UseSelectStateChangeOptions,
} from 'downshift';
import DropdownBody from '../common/DropdownBody';
import DropdownSelectOption from './DropdownSelectOption';
import DropdownToggleButton from '../common/DropdownToggleButton';
import {
  CustomSelectDefaultProps,
  CustomSelectOption,
  CustomSelectOptionValueType
} from '../Select';
import ResetButton from '../common/ResetButton';
import SaveButton from '../common/SaveButton';
import _ from 'lodash';
import './dropdownSelect.scss';

export interface DropdownSelectProps<T extends CustomSelectOptionValueType> extends CustomSelectDefaultProps<T> {
  isSmall?: boolean;
  onToggle?: () => void;
  alwaysShowLabel?: boolean;
}

const DropdownSelect = <T extends CustomSelectOptionValueType>(props: DropdownSelectProps<T>) => {
  type SelectState = UseSelectState<CustomSelectOption<T>>;
  type SelectChangeOptions = UseSelectStateChangeOptions<CustomSelectOption<T>>;

  const onStateChange = (state: UseSelectStateChange<CustomSelectOption<T>>) => {
    switch (state.type) {
      case useSelect.stateChangeTypes.MenuBlur:
      case useSelect.stateChangeTypes.ToggleButtonClick:
        props.onToggle && props.onToggle();
        if (!state.isOpen) {
          return handleMenuClose();
        }
        break;
      // Save selection on ENTER key
      case useSelect.stateChangeTypes.MenuKeyDownEnter:
        handleSaveSelection();
    }
  };

  const stateReducer = (state: SelectState, actionAndChanges: SelectChangeOptions) => {
    const { type, changes } = actionAndChanges;

    switch (type) {
      // Select items with SPACE and keep menu open
      case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        if (changes.selectedItem) {
          toggleSelection(changes.selectedItem);
        }

        return {
          ...changes,
          isOpen: true,
          highlightedIndex: state.highlightedIndex,
        };
      // Highlights and scrolls to first item starting with the key (if available)
      case useSelect.stateChangeTypes.MenuKeyDownCharacter: {
        const key = actionAndChanges.key;
        const highlightedIndex = props.options.findIndex(
          option => option.name[0].toLocaleLowerCase() === key?.toLocaleLowerCase());
        return {
          ...changes,
          highlightedIndex,
        };
      }
      default:
        return changes;
    }
  };

  const getOptionName = (option: CustomSelectOption<T>) => option.name || '';
  const getOptionsByValues = (values: T[]) => props.options.filter(option => values.includes(option.value));

  const downshiftSelection = useMultipleSelection({
    itemToString: getOptionName,
    defaultSelectedItems: getOptionsByValues(props.defaultValue || []),
  });

  const downshiftSelect = useSelect({
    items: props.options,
    onStateChange,
    stateReducer,
  });

  const [isDefaultSelected, setIsDefaultSelected] = useState(false);

  useEffect(() => {
    if (props.defaultValue) {
      setIsDefaultSelected(_.isEqual(props.value, props.defaultValue));
    }

    if (!props.value) {
      downshiftSelection.reset();
      return;
    }

    downshiftSelection.setSelectedItems(getOptionsByValues(props.value));
  }, [props.value, props.defaultValue]);

  const handleClearSelection = () => {
    if (!downshiftSelect.isOpen) {
      downshiftSelection.reset();
      props.onSelectionClear?.();
    }
        
    downshiftSelection.setSelectedItems([]);
  };

  const handleSaveSelection = () => {
    downshiftSelect.closeMenu();

    if (!downshiftSelection.selectedItems) {
      return;
    }

    props.onSelectionConfirm?.(downshiftSelection.selectedItems.map(option => option.value));
  };

  const handleMenuClose = () => {
    const confirmedOptions = getOptionsByValues(props.value || []);
    if (confirmedOptions.length > 0) {
      downshiftSelection.setSelectedItems(confirmedOptions);
    } else {
      downshiftSelection.reset();
    }
  };

  const toggleSelection = (option: CustomSelectOption<T>) => {
    props.onItemClick && props.onItemClick(option.value.toString());

    if (!props.multi) {
      downshiftSelection.setSelectedItems([option]);
    } else {
      const values = [...downshiftSelection.selectedItems || []];
      const index = values.findIndex(value => value?.value === option.value);
  
      if (isSelectedOption(option)) {
        values.splice(index, 1);
      } else {
        values.push(option);
      }
  
      downshiftSelection.setSelectedItems(values);
    }

    if (!props.hasButtons) {
      downshiftSelect.closeMenu();
    }
  };

  const getToggleButtonLabel = () => {
    if (
      props.alwaysShowLabel ||
      downshiftSelect.isOpen ||
      !props.value ||
      props.value.length === 0
    ) {
      let label = props.label;
      if (props.multi && props.value && props.value.length > 0) {
        label = `${label} (${props.value.length})`;
      }
      return label;
    }

    const items = getOptionsByValues(props.value);
    return items.map(item => item?.name).join(', ');
  };

  const isSelectedOption = (option: CustomSelectOption<T>) => {
    return !!downshiftSelection.selectedItems.find(item => item?.value === option.value);
  };

  const onDropdownMouseLeave = () => downshiftSelect.setHighlightedIndex(-1);

  return (
    <div
      className={classnames('dropdown-select', { 'dropdown-select--small': props.isSmall })}
      data-testid={props.testId}
    >
      <div className='w-100'>
        <DropdownToggleButton
          UIState={{
            isOpen: downshiftSelect.isOpen,
            isSelected: downshiftSelection.selectedItems.length !== 0,
            isDisabled: props.disabled
          }}
          icon={props.icon}
          label={getToggleButtonLabel()}
          showClearIcon={(!!props.showClearButton) && (!isDefaultSelected && !downshiftSelect.isOpen)}
          onClearIconClick={handleClearSelection}
          toggleButtonProps={{
            ...downshiftSelect.getToggleButtonProps(downshiftSelection.getDropdownProps())
          }}
        />
      </div>
      <div>
        <div className={classnames('dropdown-select__options-container',
          { 'absolute-top-0-right-0': props.isSmall,
            [`dropdown-select__options-container--align-${props.align}`]: props.align
          })}
        {...downshiftSelect.getMenuProps()}
        >
          {downshiftSelect.isOpen &&
            <DropdownBody
              main={
                props.options.map((option, index) => (
                  <DropdownSelectOption
                    name={option.name}
                    isSelected={isSelectedOption(option)}
                    isHighlighted={index === downshiftSelect.highlightedIndex}
                    key={option.value}
                    itemProps={{
                      ...downshiftSelect.getItemProps({ item: option, index }),
                      onMouseLeave: onDropdownMouseLeave,
                    }}
                    isMultiselect={props.multi ? true : false}
                    onClick={() => toggleSelection(option)}
                  />
                ))
              }
              footer={
                props.hasButtons ?
                  <>
                    <SaveButton
                      onClick={handleSaveSelection}
                      className='mr-2'
                      size={props.isSmall ? 'sm' : undefined}
                      testId='dropdown-select-save-button'
                      label={props.saveButtonLabel}
                    />
                    <ResetButton
                      onClick={handleClearSelection}
                      size={props.isSmall ? 'sm' : undefined}
                      label={props.resetButtonLabel}
                    />
                  </> :
                  null
              }
            />
          }
        </div>
      </div>
      <label className={classnames('color--grey-light pt-1 align-self-end font-size-12',
        { 'sr-only': !props.showLabel })}
      {...downshiftSelect.getLabelProps()}
      >
        {props.label}
      </label>
    </div >
  );
};

export default DropdownSelect;
