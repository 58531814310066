import React, { ReactNode } from 'react';
import Div100vh from 'react-div-100vh';
import UpgradeBanner from '../../UpgradeBanner';
import TopBar from '../TopBar/TopBar';
import PublicTopBar from '../TopBar/PublicTopBar';
import './masterLayout.scss';
 
interface MasterLayoutProps {
  children: ReactNode[] | ReactNode;
  isPublic?: boolean;
}

const MasterLayout: React.FC<MasterLayoutProps> = ({ children, isPublic }) => {
  return (
    <Div100vh>
      <div className='master-layout__wrapper'>
        <header className='master-layout__header'>
          <UpgradeBanner />
          {isPublic ? <PublicTopBar /> : <TopBar />}
        </header>
        <main className='master-layout__main'>
          {children}
        </main>
      </div>
    </Div100vh>
  );
};

export default MasterLayout;
