import { WithRouter, withRouter } from 'found';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fetchCollectionContents, fetchCollections } from '../actions/collections.actions';
import Jumbotron from '../components/Jumbotron';
import CollectionsAppLayout from '../components/CollectionsAppLayout';
import ItemContext from '../components/contexts/ItemContext';
import ItemsList from '../components/ItemsList';
import withAsyncData from '../components/utils/withAsyncData';
import { routes } from '../constants';
import { StoreType } from '../reducers';
import Breadcrumbs from '../components/Breadcrumbs';
import { Collection, Pagination } from '../types/AppContent';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import {
  selectCollectionWithContents,
  selectCollectionStatus,
  CollectionTypes,
} from '../reducers/collections.selectors';
import { createPaginationRequest, hasNext } from '../utils/pagination';

interface DispatchProps {
  fetchCollectionContents: typeof fetchCollectionContents;
}

interface OwnProps {
  match: {
    params: {
      collectionId: string;
    };
  };
}

interface StateProps {
  collection?: Collection;
  collectionStatus: AsyncActionStatus;
  collectionPagination: Pagination;
}

type FollowedCollectionItemListPageProps = DispatchProps & StateProps & WithRouter & OwnProps;

const FollowedCollectionItemListPage: React.FC<FollowedCollectionItemListPageProps> = ({
  match,
  collection,
  collectionStatus,
  router,
  ...props
}) => {
  const collectionId = match.params.collectionId;
  const { t } = useTranslation();

  if (!collection && collectionStatus === AsyncActionStatus.NOTFOUND) {
    router.replace(`/${routes.curatedCollections.root}/${collectionId}`);
    return null;
  }
  if (!collection || !collection.isFollowing) {
    return null;
  }

  useEffect(() => {
    props.fetchCollectionContents(collectionId, createPaginationRequest(props.collectionPagination));
  }, [collectionId]);

  const [activeId, setActiveId] = useState('');

  const items = collection.contents;
  const activeItem = items.find((item) => item.id === activeId);

  return (
    <CollectionsAppLayout
      header={
        <Breadcrumbs breadcrumbs={[
          { to: `/${routes.followed.root}`, title: t('I follow') },
          { to: `/${routes.followed.root}/${collection.id}`, title: collection.title },
        ]}/>
      }
      mainBody={
        <>
          <Jumbotron collection={collection} />
          <ItemsList
            isLoading={collectionStatus === 'loading'}
            hasNext={hasNext(props.collectionPagination)}
            total={props.collectionPagination.total}
            loadNext={() => {
              return props.fetchCollectionContents(collectionId, createPaginationRequest({
                ...props.collectionPagination,
                offset: props.collectionPagination.offset + props.collectionPagination.limit,
              }));
            }}
            activeId={activeId}
            items={items}
            linkRoot={[routes.followed.root, collectionId]}
            setActiveId={setActiveId}
          />
        </>
      }
      context={<ItemContext item={activeItem} />}
      setActiveId={setActiveId}
    />
  );
};

const mapState = (state: StoreType, props: OwnProps): StateProps => ({
  collection: selectCollectionWithContents(state, props.match.params.collectionId, CollectionTypes.FOLLOWED),
  collectionStatus: selectCollectionStatus(state, props.match.params.collectionId, CollectionTypes.FOLLOWED),
  collectionPagination: state.collections.byId[props.match.params.collectionId] &&
    state.collections.byId[props.match.params.collectionId].pagination,
});

export default compose<React.FC<Record<string,never>>>(
  withRouter,
  connect(
    mapState,
    { fetchCollections, fetchCollectionContents },
  ),
  withAsyncData((props) => ({
    refetch: () => props.fetchCollections(),
    dataProp: 'collection',
    statusProp: 'collectionStatus'
  })),
)(FollowedCollectionItemListPage);
