import immutable from 'object-path-immutable';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import { uniqueArrayOfObjects } from '../utils/uniqueArrayOfObjects';
import {
  FETCH_PINNED_CONTENTS,
  FetchPinnedContentsAction,
  PIN_MIND_ITEM,
  PinMindItemAction,
  PIN_PRODUCT,
  PinProductAction,
  UNPIN_DOCUMENT,
  UnpinDocumentAction,
} from '../actions/pinned.actionTypes';
import { pagination } from '../constants';
import { ItemVariation } from '@klab-berlin/api-sdk/lib/types/responses/Collection';
import { LOGOUT, LogoutAction } from '../actions/auth.actionTypes';
import { Pagination } from '../types/AppContent';

type PinnedActionTypes =
  | FetchPinnedContentsAction
  | PinMindItemAction
  | PinProductAction
  | UnpinDocumentAction
  | LogoutAction;

type PinnedContent = { id: string; type: ItemVariation };
export type PinnedContentArray = Array<PinnedContent>;

export interface PinnedInitialStateType {
  contents: PinnedContentArray;
  error: null | string;
  status: AsyncActionStatus;
  pagination: Pagination;
}

const getInitialPagination: () => Pagination = () => ({
  total: -1,
  limit: pagination.itemsOnPage,
  offset: 0,
  sortOrder: 'desc',
});

const initialState: PinnedInitialStateType = {
  contents: [],
  error: null,
  status: AsyncActionStatus.LOADING,
  pagination: getInitialPagination(),
};

const pinned = (
  state = initialState,
  action: PinnedActionTypes
): PinnedInitialStateType => {
  switch (action.type) {
    case LOGOUT.SUCCESS:
      return initialState;

    case FETCH_PINNED_CONTENTS.START:
      return immutable(state)
        .set('status', AsyncActionStatus.LOADING)
        .merge('pagination', action.payload.pagination)
        .value();

    case FETCH_PINNED_CONTENTS.ERROR:
      return immutable(state)
        .set('status', AsyncActionStatus.ERROR)
        .set('error', action.error.message)
        .value();

    case FETCH_PINNED_CONTENTS.SUCCESS: {
      const pagination = action.payload.pagination;
      const mappedContentsResponse = action.result.data.map(r => ({
        id: r.id,
        type: r.type
      }));
      let newState = immutable(state);

      for (const [key, value] of Object.entries(mappedContentsResponse)) {
        const index = (pagination.offset || 0) + parseInt(key);

        newState = newState.set(
          ['contents', index],
          value
        );
      }
      return newState
        .set('status', AsyncActionStatus.READY)
        .set(['pagination', 'total'], action.result.meta.pagination.total)
        .update(['contents'], (value) =>
          uniqueArrayOfObjects(value, (item) => item.id)
        )
        .value();
    }
    case PIN_MIND_ITEM.START:
    case PIN_PRODUCT.START:
    case UNPIN_DOCUMENT.ERROR: {
      return immutable(state)
        .insert('contents', action.payload as PinnedContent, 0)
        .update(['pagination', 'offset'], value => ++value)
        .update(['pagination', 'total'], value => ++value)
        .value();
    }
    case PIN_MIND_ITEM.ERROR:
    case PIN_PRODUCT.ERROR:
    case UNPIN_DOCUMENT.START: {
      // Here, the unpinned may or may not be in the already fetched items.
      // If it was, then the new array should be shorter, and we should set the
      // offset for pagination accordingly
      const newArray = state.contents.filter(item => item.id !== action.payload.id);
      const difference = newArray.length - state.contents.length;

      return immutable(state)
        .set(['contents'], newArray)
        .update(['pagination', 'offset'], value => value + difference)
        .update(['pagination', 'total'], value => --value)
        .value();
    }
  }

  return state;
};

export default pinned;
