import React from 'react';
import './inputLabel.scss';
import classnames from 'classnames';

interface InputLabelProps {
  htmlFor?: string;
  required?: boolean;
  id?: string;
  className?: string;
}

const InputLabel: React.FC<InputLabelProps> = ((props) => {
  return (
    <label
      className={classnames('input-label__label', props.className)}
      htmlFor={props.htmlFor}
      id={props.id}
    >{props.children}{props.required ? '*' : ''}</label>
  );
});

export default InputLabel;
