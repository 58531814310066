import React, { useContext } from 'react';
import { trackingEvents } from '../services/tracking/trackConfig';
import { DownloadModalContext } from '../state/DownloadModalProvider';
import { ShareModalContext } from '../state/ShareModalProvider';
import { ShareModalItemTypeEnum } from '../types/sharing';
import { openItem } from '../utils';
import { createPinTrackingConfig } from '../utils/tracking';
import MeatballItem from './MeatballItem';
import WithTracking from './utils/WithTracking';
import MeatballMenu from './MeatballMenu';
import { CurrentCollection as CurrentCollectionContext } from '../state/CurrentCollection';
import { useSelector } from 'react-redux';
import { ItemVariation } from '@klab-berlin/api-sdk/lib/types/responses/Collection';
import { selectMindItem } from '../reducers/mindItems.selectors';
import { selectProduct } from '../reducers/products.selectors';
import { selectUser } from '../reducers/user.selectors';
import { deleteItemFromCollection as deleteItemFromCollectionAction } from '../actions/collections.actions';
import { MindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import useRouter from '../utils/useRouter';
import usePinAction from '../utils/usePinAction';
import useAction from '../utils/useAction';
import services from '../services';
import { CollectionContentRemoved } from '../ampli';
import { getAmpliContentEventPayload } from '../utils/ampli';
import { ContentFavoriteAdded, ContentFavoriteRemoved } from '../ampli';
import { AmpliContentPayload, UIProperties } from '../constants';
import * as ampliUtils from '../utils/ampli';
import { useNewDesign } from '../state/DesignPreferenceContextProvider';

interface ItemMeatballMenuProps {
  itemId: string;
  type: ItemVariation;
}

const ItemMeatballMenu: React.FC<ItemMeatballMenuProps> = ({ itemId, type }) => {
  const { show: openShareModal } = useContext(ShareModalContext);
  const { show: openDownloadModal } = useContext(DownloadModalContext);
  const { shouldShowDesignPreferenceSwitch, prefersNewDesign } = useNewDesign();
  const user = useSelector(selectUser);
  const item = useSelector((state) => {
    if (type === 'mindItem') {
      return selectMindItem(state, itemId);
    }

    if (type === 'product') {
      return selectProduct(state, itemId);
    }

    return undefined;
  });

  const deleteItemFromCollection = useAction(deleteItemFromCollectionAction);
  const pinAction = usePinAction(itemId, item?.isPinned, type);
  const { router } = useRouter();

  const sharingType = {
    mindItem: ShareModalItemTypeEnum.MINDITEM,
    product: ShareModalItemTypeEnum.PRODUCT,
  };

  const { collectionId, ownedByCurrentUser } = useContext(CurrentCollectionContext);

  const isPinned = item && item.isPinned;

  const sendCollectionContentRemovedEvent = () => {
    if (user && item) {
      const eventProperties = getAmpliContentEventPayload(item, type);

      services.track.ampliEventTrack({
        event: new CollectionContentRemoved(eventProperties as any)
      });
    }
  };

  const actions = {
    openItem: () => openItem(router, itemId, type),
    pinItem: (e: React.SyntheticEvent) => {
      e.preventDefault();
      pinAction();

      const eventProperties: (AmpliContentPayload & UIProperties) | undefined =
        item && ampliUtils.getAmpliContentEventPayload(item, type);

      const ampliAction = !isPinned ? ContentFavoriteAdded : ContentFavoriteRemoved;
      if (user && eventProperties) {
        if (shouldShowDesignPreferenceSwitch && !isPinned) {
          eventProperties.ui = ampliUtils.mapDesignPreference(prefersNewDesign);
        }
        
        services.track.ampliEventTrack({
          event: new ampliAction(eventProperties as any)
        });
      }
    },
    shareItem: () => openShareModal(itemId, sharingType[type]),
    downloadItem: () => openDownloadModal(itemId),
    deleteItemFromCollection: (_collectionId: string) => {
      deleteItemFromCollection(_collectionId, type, itemId);
      sendCollectionContentRemovedEvent();
    }
  };

  const hideDownloadAction = (item?.isReadOnly && !item.hasAttachments) || (item as MindItem)?.fileType === 'video';
  return (
    <MeatballMenu
      itemId={itemId}
      items={() => [
        <WithTracking events={{ onClick: trackingEvents.itemOpen }} key='open'>
          <MeatballItem
            onClick={actions.openItem}
            icon='view'
            key='open'
            label='Open'
          />
        </WithTracking>,
        type === 'mindItem' && !hideDownloadAction && (
          <MeatballItem
            onClick={actions.downloadItem}
            icon='download'
            key='download'
            label='Download'
          />
        ),
        <WithTracking
          key='pin'
          {...createPinTrackingConfig(!!isPinned, type, 'menu', item)}
        >
          <MeatballItem
            onClick={actions.pinItem}
            key='pin'
            icon={isPinned ? 'star-solid' : 'star-outline'}
            label={isPinned ? 'Unpin' : 'Pin'}
          />
        </WithTracking>,
        <MeatballItem
          onClick={actions.shareItem}
          icon='share'
          key='share'
          label='Share'
        />,
        ownedByCurrentUser && <hr key='divider' />,
        ownedByCurrentUser && (
          <MeatballItem
            onClick={() => collectionId && actions.deleteItemFromCollection(collectionId)}
            icon='cancel'
            key='Remove'
            label={'Remove from collection'}
            iconClass='color--coral'
          />
        ),
      ]}
    />
  );
};

export default ItemMeatballMenu;
