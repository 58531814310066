import React, { useEffect, useRef, useState } from 'react';

interface TruncateProps {
  text: string;
  lines: number;
  onTruncationChange?: (truncated: boolean) => void;
  className?: string;
  showTitle?: boolean;
}

const Truncate: React.FC<TruncateProps> = (props) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    if (!props.onTruncationChange || !divRef.current) {
      return;
    }
    
    const observer = new window.ResizeObserver((entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        // in some browsers (e.g. Firefox), entry.contentRect.height is denoted by a float value
        // we need to compare pixels, i.e. integers
        const heightAfterTruncation = Math.ceil(entry.contentRect.height);
        const heightWithoutTruncation = entry.target.scrollHeight;
        const isEnoughSpaceForContent = heightWithoutTruncation <= heightAfterTruncation;
        setIsTruncated(!isEnoughSpaceForContent);
      }
    });

    window.requestAnimationFrame(() => {
      if (!divRef.current) return;
      observer.observe(divRef.current);
    });

    return () => {
      divRef.current && observer.unobserve(divRef.current);
    };
  }, []);

  useEffect(() => {
    if (!props.onTruncationChange) {
      return;
    }

    props.onTruncationChange(isTruncated);
  }, [isTruncated]);

  return (
    <div
      className={`truncate-lines-${props.lines} ${props.className || ''}`}
      ref={divRef}
      dangerouslySetInnerHTML={{ __html: props.text }} 
      title={props.showTitle ? props.text : undefined}
    />
  );
};

export default Truncate;
