import { UserResponseData } from '@klab-berlin/api-sdk/lib/types/responses/User';

interface AppContext {
  resetPasswordEmail?: string;
  userId?: string;
  user?: UserResponseData;
}

const data: AppContext = {
  resetPasswordEmail: undefined,
  user: undefined,
  userId: undefined
};

const service = {
  getResetPasswordEmail: () => data.resetPasswordEmail,
  setResetPasswordEmail: (email: string) => data.resetPasswordEmail = email,
};

export default service;
