import React from 'react';
import './infoBox.scss';

const InfoBox : React.FC = ({ children }) => (
  <div className='info-box'>
    { children }
  </div>
);

export default InfoBox;
