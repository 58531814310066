import React, { useEffect, useMemo } from 'react';
import './rangeInputBoxes.scss';
import { RangeSliderValue } from '../common/RangeSlider/RangeSlider';
import { useForm } from 'react-hook-form';
import { isPositiveInteger } from '../../../../utils/string';

interface RangeInputBoxesProps {
  min: number;
  max: number;
  save: (values: RangeSliderValue) => void;
  setPotentialValues: (values: RangeSliderValue | undefined) => void;
  onFocus?: () => void;
}

const RangeInputBoxes: React.FC<RangeInputBoxesProps> = ({
  min,
  max,
  save,
  onFocus,
  setPotentialValues,
}) => {
  const {
    register,
    getValues: getFormValues,
    setValue: setFormValue,
    reset,
  } = useForm<{min: string, max: string}>({
    defaultValues: useMemo(() => {
      return { min: min.toString(), max: max.toString() };
    }, [min, max])
  });

  useEffect(() => {
    reset({ min: min.toString(), max: max.toString() });
  }, [min, max]);

  const getValues = (): RangeSliderValue => {
    const value = getFormValues();
    return {
      min: parseInt(value.min, 10),
      max: parseInt(value.max, 10),
    };
  };

  const setValue = (name: string, value: number) => {
    setFormValue(name, value.toString());
  };

  const validate = () => {
    const values = getValues();
    if (Number.isNaN(values.min) || values.min > max) {
      setValue('min', min);
    } else {
      setValue('min', values.min);
    }
    if (Number.isNaN(values.max) || values.max < min) {
      setValue('max', max);
    } else {
      setValue('max', values.max);
    }
    setPotentialValues(undefined);
    save(getValues());
  };

  const validateRange = (value: number): boolean => {
    return value >= 1 && value <= 13;
  };

  const onChange = () => {
    const values = getValues();
    const formValues = getFormValues();
    // If the input is empty, set the potential values to min/max range,
    // to be validated to the actual saved values later
    if (formValues.min === '') {
      setPotentialValues({ min: 1, max });
      return;
    }
    if (formValues.max === '') {
      setPotentialValues({ min, max: 13 });
      return;
    }
    
    // Set the values to the saved values if they are not valid
    // Also reset the potential values to the saved values, because it might have been
    // set to range values in the previous if statements
    if (!isPositiveInteger(formValues.min) || !validateRange(values.min)) {
      setValue('min', min);
      setPotentialValues({ min, max });
      return;
    }
    if (!isPositiveInteger(formValues.max) || !validateRange(values.max)) {
      setValue('max', max);
      setPotentialValues({ min, max });
      return;
    }

    // If the values are totally valid and in range, set the potential values to the input values
    // To be validated into the saved values (actual values) later
    if (values.min <= max && values.max >= min) {
      setPotentialValues(values);
      save(values);
      return;
    }
  };

  return (
    <form onChange={onChange}>
      <fieldset>
        <div className='range-input-boxes'>
          <div className='range-input-boxes__min'>
            <input
              inputMode='numeric'  // Display numeric keyboard on mobile.
              data-testid='range-input-boxes__min'
              name='min'
              ref={register}
              defaultValue={min}
              onBlur={validate}
              onFocus={onFocus}
            />
          </div>
          <div className='range-input-boxes__hyphen'>
          &mdash;
          </div>
          <div className='range-input-boxes__max'>
            <input
              inputMode='numeric'
              data-testid='range-input-boxes__max'
              defaultValue={max}
              name='max'
              ref={register}
              onBlur={validate}
              onFocus={onFocus}
            />
          </div>
        </div>
      </fieldset>
    </form>
  );
};

export default RangeInputBoxes;
