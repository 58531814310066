import React from 'react';
import { Button } from 'react-bootstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

interface SaveButtonProps {
  disabled?: boolean;
  label?: string;
  size?: 'sm' | 'lg';
  onClick?: () => void;
  className?: string;
  testId?: string
}

const SaveButton: React.FC<SaveButtonProps> = ({ className = '', ...props }) => {
  const { t } = useTranslation();
  
  return (
    <Button
      size={props.size}
      className={classnames(className, { 'disabled btn-secondary': props.disabled })}
      onClick={props.onClick}
      disabled={props.disabled}
      data-testid={props.testId}
    >
      {props.label || t('Save')}
    </Button>
  );
};

export default SaveButton;
