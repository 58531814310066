import React, { useEffect, useState } from 'react';
import { routes } from '../../constants';
import useRouter from '../../utils/useRouter';
import PersonalDetailsAccountSection from './PersonalDetailsAccountSection';
import PersonalDetailsPageLayout from './PersonalDetailsPageLayout';
import PersonalDetailsProfileSection from './PersonalDetailsProfileSection';

export enum PersonalDetailsSection {
  PROFILE = 'profil',
  ACCOUNT = 'konto'
}

const PersonalDetailsPage: React.FC = () => {
  const { router, match } = useRouter();
  const [currentSection, setCurrentSection] = useState(PersonalDetailsSection.PROFILE);
  const isValidParam =
    match.params.section &&
    match.params.section === PersonalDetailsSection.PROFILE ||
    match.params.section === PersonalDetailsSection.ACCOUNT;

  useEffect(() => {
    if (isValidParam) {
      setCurrentSection(match.params.section as PersonalDetailsSection);
    }
    else {
      router.replace(`/${routes.personalDetails.root}/${PersonalDetailsSection.PROFILE}`);
    }
  }, [match.params.section]);

  return (
    <PersonalDetailsPageLayout currentSection={currentSection}>
      {currentSection === PersonalDetailsSection.PROFILE &&
        <PersonalDetailsProfileSection />
      }
      {currentSection === PersonalDetailsSection.ACCOUNT &&
        <PersonalDetailsAccountSection />
      }
    </PersonalDetailsPageLayout>
  );
};

export default PersonalDetailsPage;
