import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import NavigationLayoutDesktop from '../components/AppLayout/NavigationLayout/NavigationLayoutDesktop';
import NavigationLayoutMobile from '../components/AppLayout/NavigationLayout/NavigationLayoutMobile';
import ReaderDocumentContent from '../components/Reader/ReaderContent/ReaderDocumentContent';
import ReaderHead from '../components/Reader/ReaderHead';
import {
  fetchMindItem as fetchMindItemAction,
  fetchSiblings as fetchSiblingsAction
} from '../actions/mindItems.actions';
import {
  fetchAttachments as fetchAttachmentsAction,
  fetchProduct as fetchProductAction,
} from '../actions/product.actions';
import {
  selectMindItem,
  isMindItemInvalid as isMindItemInvalidSelector,
  selectAdjacentSiblings
} from '../reducers/mindItems.selectors';
import { selectUser } from '../reducers/user.selectors';
import { ReaderContext } from '../components/Reader/ReaderContext';
import ReaderProductNavigation from '../components/Reader/ReaderProductNavigation';
import { selectIsItemInOwnCollections } from '../reducers/collections.selectors';
import { UIProperties, amplitudeContentTypes, amplitudeEvents, routes } from '../constants';
import PdfReader from '../components/Reader/PdfReader/PdfReader';
import MasterLayout from '../components/AppLayout/MasterLayout';
import services from '../services';
import { useNavigationLayout }  from '../utils/useNavigationLayout';
import ReaderInfoSidebar from '../components/Reader/ReaderInfoSidebar';
import { ReaderScrollDirection } from '../types/reader';
import ReaderActionsMobile from '../components/Reader/ReaderControls/ReaderActionsMobile';
import { setCustomPageTitle } from '../utils/customTitle';
import useAction from '../utils/useAction';
import useRouter from '../utils/useRouter';
import ReaderVideoContent from '../components/Reader/ReaderContent/ReaderVideoContent/ReaderVideoContent';
import { getContentEventPayload, mapDesignPreference } from '../utils/ampli';
import { AmplitudeTrackPayload, ContentType } from '@klab-berlin/api-sdk/lib/types/requests/Track';
import { MindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import FeedbackDialog from '../components/modals/FeedbackDialog';
import { useNewDesign } from '../state/DesignPreferenceContextProvider';
import { isValidVideoItem } from '../utils/subdomain';

const ReaderPage = () => {
  const { match, router } = useRouter();
  const { params: { documentId }, location: { query } } = match;
  const [loadedPages, setLoadedPages] = useState<number>(0);
  const [initialPage, setInitialPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPremiumPdfDocument, setIsPremiumPdfDocument] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  const fetchMindItem = useAction(fetchMindItemAction);
  const fetchProduct = useAction(fetchProductAction);
  const fetchSiblings = useAction(fetchSiblingsAction);

  const mindItem = useSelector(state => selectMindItem(state, documentId));
  const isInCollection = useSelector(state => selectIsItemInOwnCollections(state, documentId));
  const user = useSelector(selectUser);
  const adjacentSiblings = useSelector(state => selectAdjacentSiblings(state, { id: documentId, type: 'mindItem' }));
  const isMindItemInvalid = useSelector(state => isMindItemInvalidSelector(state, documentId));
  const nextSibling = adjacentSiblings?.next?.mindItem;

  const { renderNavigationMobileLayout, showSidebarRight, setShowSidebarRight } = useNavigationLayout();
  const [scrollDirection, setScrollDirection] = useState<ReaderScrollDirection>();
  const showPublicShare = user?.isPremium && mindItem?.fileType === 'video'
   && mindItem?.isShareable;
  const contentScrollTop = useRef<number>(0);
  const shouldHideAllMIActions = mindItem?.fileType === 'video' && !isValidVideoItem(mindItem?.publisher);
  const { shouldShowDesignPreferenceSwitch, prefersNewDesign } = useNewDesign();

  const trackContentViewed = (
    mindItem: MindItem
  ): void => {
    let contentType: ContentType;
    let publicVideo: string | undefined;

    if (mindItem?.fileType === 'video') {
      contentType = amplitudeContentTypes.video;
      publicVideo = 'No';
    } else {
      contentType = mindItem.isReadOnly ? amplitudeContentTypes.readOnlyDocument : amplitudeContentTypes.document;
    }

    const contentViewedPayload: (AmplitudeTrackPayload & UIProperties) | null = getContentEventPayload({
      content: {
        ...mindItem,
        publicVideo
      },
      event_type: amplitudeEvents.ContentViewed,
      contentType,
    });

    if (contentViewedPayload) {
      if (shouldShowDesignPreferenceSwitch) {
        contentViewedPayload.event_properties['ui'] = mapDesignPreference(prefersNewDesign);
      }
      services.track.ampliServerSideEventTrack(false, contentViewedPayload);
    }
  };

  useEffect(() => {
    setLoadedPages(0);
    fetchMindItem(documentId);
  }, [documentId]);

  useEffect(() => {
    if (query && query.page) {
      const newInitPage = parseInt(query.page);
      setInitialPage(newInitPage > 0 ? newInitPage : 1);
    }
    else {
      setInitialPage(1);
    }
  }, [query]);

  useEffect(() => {
    if (mindItem) {
      fetchProduct(mindItem.product);
      fetchSiblings(mindItem.id, mindItem.product);
      fetchAttachmentsAction(mindItem.product);
      setCustomPageTitle(mindItem.title);

      if (user) {
        trackContentViewed(mindItem);
      }
    }
  }, [mindItem?.product, mindItem?.product, mindItem?.id]);

  useEffect(() => {
    if (user?.isPremium && mindItem?.fileType === 'document')
      setIsPremiumPdfDocument(true);
    else
      setIsPremiumPdfDocument(false);
  }, [user?.isPremium, mindItem?.fileType]);

  if (isMindItemInvalid) {
    router.push(`/${routes.dashboard.root}`);
  }
  if (!user || !mindItem) {
    return <></>;
  }

  const handlePageLoad = () => {
    setLoadedPages(loadedPages + 1);
  };

  const handleInfoClick = () => {
    setShowSidebarRight(sideBarPosition => !sideBarPosition);
  };

  const handleSearchClick = () => {
    setIsSearchVisible(searchVisibility => !searchVisibility);
  };

  const handleScroll = (scrollTop: number) => {
    if (!renderNavigationMobileLayout) return;
    const didScrollDown = (scrollTop - contentScrollTop.current) > 10;
    const didScrollUp = (contentScrollTop.current - scrollTop) > 10;

    if (didScrollDown) {
      setScrollDirection(ReaderScrollDirection.DOWN);
    }

    if (didScrollUp) {
      setScrollDirection(ReaderScrollDirection.UP);
    }

    contentScrollTop.current = scrollTop;
  };

  const getReaderContent = () => {
    if (!mindItem || !user) return null;

    if (mindItem?.fileType === 'video') {
      return <ReaderVideoContent
        mindItemId={mindItem.id}
        variant={renderNavigationMobileLayout ? 'mobile' : 'desktop'}
        showPublicShare={showPublicShare}
      />;
    }

    if (user.isPremium) {
      return <PdfReader
        isSearchVisible={isSearchVisible}
        setIsSearchVisible={setIsSearchVisible}
        onSearchClick={handleSearchClick}
        variant={renderNavigationMobileLayout ? 'mobile' : 'desktop'}
        onScroll={handleScroll}
      />;
    }
    else {
      return <ReaderDocumentContent
        pages={mindItem.pages}
        variant={renderNavigationMobileLayout ? 'mobile' : 'desktop'}
        onScroll={handleScroll}
      />;
    }
  };

  return (
    <ReaderContext.Provider
      value={{
        currentPage: currentPage,
        setCurrentPage: setCurrentPage,
        initialPage: initialPage,
        mindItemId: mindItem.id,
        isReadOnly: mindItem.isReadOnly,
        nextSibling,
        isPinned: mindItem.isPinned,
        isInCollection: isInCollection,
        isTextBased: mindItem.isTextBased,
        onPageLoad: handlePageLoad,
        pagesLoaded: mindItem.fileType === 'document' && loadedPages >= mindItem.pages.length,
        hasAttachments: mindItem.hasAttachments,
      }}
    >
      <FeedbackDialog />
      <MasterLayout>
        {renderNavigationMobileLayout && <NavigationLayoutMobile
          head={
            <ReaderHead
              header={mindItem.title}
              showInfoIcon
              onInfoClick={handleInfoClick}
              isInfoIconActive={showSidebarRight}
              productId={mindItem.product}
            />
          }
          main={getReaderContent()}
          sidebarRight={
            <ReaderInfoSidebar
              mindItemId={documentId}
              variant='mobile'
              onClose={() => setShowSidebarRight(false)}
            />
          }
          actions={
            <ReaderActionsMobile
              hideDownloadAction={mindItem?.fileType === 'video'}
              showSearchIcon={mindItem?.fileType !== 'video' && user.isPremium}
              hideAllMIActions={shouldHideAllMIActions}
              onSearchClick={handleSearchClick}
            />
          }
          showSidebarRight={showSidebarRight}
          hideHeadAndActions={!isSearchVisible && scrollDirection === ReaderScrollDirection.DOWN}
        />}
        {!renderNavigationMobileLayout && <NavigationLayoutDesktop
          head={
            <ReaderHead
              header={mindItem.title}
              showInfoIcon
              onInfoClick={handleInfoClick}
              isInfoIconActive={showSidebarRight}
              showSearchIcon={isPremiumPdfDocument}
              onSearchClick={handleSearchClick}
              productId={mindItem.product}
            />
          }
          main={getReaderContent()}
          sidebarLeft={
            <ReaderProductNavigation
              mindItemId={mindItem.id}
              productId={mindItem.product}
              onNavigate={(pageIndex) => {
                // Navigate to different page inside the current mindItem
                setInitialPage(pageIndex);
              }}
            />}
          sidebarRight={
            <ReaderInfoSidebar
              mindItemId={documentId}
              variant='desktop'
              onClose={() => setShowSidebarRight(false)}
            />
          }
          showSidebarRight={showSidebarRight}
        />
        }
      </MasterLayout>
    </ReaderContext.Provider>
  );
};

export default ReaderPage;
