import React, { useState } from 'react';
import { ShareModal } from '../components/modals';
import { ShareModalItemTypeEnum } from '../types/sharing';

type ShowFunc = (itemId: string, itemType: ShareModalItemTypeEnum) => void;
const noop = () => undefined;

interface Context {
  isOpen: boolean;
  show: ShowFunc;
}

const defaultContext: Context = {
  isOpen: false,
  show: noop as ShowFunc,
};

const ShareModalContext = React.createContext(defaultContext);

const ShareModalProvider: React.FC = (props) => {
  const [itemId, setItemId] = useState('');
  const [itemType, setItemType] = useState(ShareModalItemTypeEnum.MINDITEM);
  const [isOpen, setIsOpen] = useState(false);
  
  const setModalVisibility = (open: boolean, itemId?: string, itemType?: ShareModalItemTypeEnum) => {
    if (itemId) setItemId(itemId);
    if (itemType) setItemType(itemType);
    setIsOpen(open);
  };

  return (
    <ShareModalContext.Provider value={{
      show: (itemId, itemType) => setModalVisibility(true, itemId, itemType),
      isOpen,
    }}>
      <ShareModal
        show={isOpen}
        itemId={itemId}
        type={itemType}
        onClose={() => setModalVisibility(false)}
      />
      {props.children}
    </ShareModalContext.Provider>
  );
};

export { ShareModalContext, ShareModalProvider };
