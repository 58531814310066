import React, { useContext } from 'react';
import MuiCardBase, { MuiCardCommonProps } from '../MuiCardBase/MuiCardBase';
import { useTranslation } from 'react-i18next';
import { routes } from '../../../../constants';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../reducers/user.selectors';
import { IconName } from '../../../../utils/getMuiIcon';
import { ModalsContext } from '../../../../state/ModalsProvider';

export interface ProductMuiCardProps extends MuiCardCommonProps {
  numberOfAttachments: number,
  numberOfMindItems: number,
}

const ProductMuiCard: React.FC<ProductMuiCardProps> = (props) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const { openModal } = useContext(ModalsContext);

  const chipText = `${props.numberOfMindItems} ${props.numberOfMindItems > 1 ? t('Units') : t('Unit')}`;
  const attachmentsPageLink = `/${routes.productAttachmentsPage.root}/${props.id}`;
  const productPageLink = `/${routes.productPage.root}/${props.id}`;
  const attachmentsLink = {
    to: user?.isPremium ? attachmentsPageLink : productPageLink,
    label: `${props.numberOfAttachments} ${props.numberOfAttachments === 1 ? t('Attachment') : t('Attachments')}`,
    icon: 'paperclip' as IconName,
    hasTooltip: false,
  };

  const customLinkFunction = () => {
    openModal('premiumContent');
  };

  return <MuiCardBase
    {...props}
    contentType='product'
    chipText={chipText}
    link={props.numberOfAttachments > 0 ? attachmentsLink : undefined}
    linkFunction={user?.isPremium ? undefined : customLinkFunction}
  />;
};

export default ProductMuiCard;
