import React from 'react';
import '../generalInput.scss';
import classNames from 'classnames';
import Icon from '../../../Icon';
import FormInputWrapper from '../../FormInputWrapper';
import WithTracking, { Events } from '../../../utils/WithTracking';
import { EventInputData } from '../../../../types/tracking';

interface TextInputProps {
  label: string;
  name: string;
  error?: string
  required?: boolean;
  placeholder?: string;
  inputEvents?: Events;
  eventPayload?: EventInputData;
  testId?: string;
}

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const inputProps = {
    className: classNames({
      'general-input__field--normal': !props.error,
      'general-input__field--error': !!props.error
    }),
    type: 'text',
    id: props.name,
    name: props.name,
    placeholder: props.placeholder,
  };

  return (
    <div className='general-input'>
      <FormInputWrapper
        label={props.label}
        error={props.error}
        required={props.required}
        labelFor={props.name}
      >
        <div className='general-input__input-container'>
          <WithTracking
            events={props.inputEvents || {}}
            eventPayload={props.eventPayload}
          >
            <input {...inputProps} data-testid={props.testId} ref={ref} />
          </WithTracking>
          <div className='general-input__overlay-icons'>
            {props.error && <CautionIcon />}
          </div>
        </div>
      </FormInputWrapper>
    </div>
  );
});

const CautionIcon: React.FC = () => {
  return <Icon size='lg' className='general-input__caution-icon' icon='caution' />;
};

export default TextInput;
