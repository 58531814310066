import React from 'react';
import ViewSwitch from '../../../ViewSwitch';
import './searchResultsHeader.scss';
import { useNewDesign } from '../../../../state/DesignPreferenceContextProvider';

interface OwnProps {
  totalText: string;
  loadedCount: number;
}

const SearchResultsHeader: React.FC<OwnProps> = (props) => {
  const { prefersNewDesign } = useNewDesign();

  return (
    <div className='search-results-header__container'>
      <div>
        {props.totalText}
      </div>
      <div className='search-results-header__section'>
        {prefersNewDesign ?
          <></> :
          <div className='search-results-header__view-switch'>
            <ViewSwitch listSize={props.loadedCount} />
          </div>
        }
      </div>
    </div>
  );
};

export default SearchResultsHeader;
