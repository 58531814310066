import { Link } from 'found';
import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import { LoginSources, routes } from '../../constants';
import FormInput from '../forms/FormInput';
import config from 'config';
import WithTracking from '../utils/WithTracking';
import { trackingEvents } from '../../services/tracking/trackConfig';
import Spinner from 'react-bootstrap/Spinner';
import iam from '../../assets/IAM.png';
import i18next from 'i18next';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { login } from '../../actions/auth.actions';
import { connect } from 'react-redux';
import FacebookLogin from './FacebookLogin';
import WithTooltip from '../WithTooltip';
import { isFeatureEnabled } from '../../utils/feature';
import { isSchoolSubdomain } from '../../utils/subdomain';

interface OwnProps {
  onLoginSuccess: (source: LoginSources) => void;
  onLoginFailure: (source: LoginSources, statusCode: number, errorCode?: number) => void;
}

interface DispatchProps {
  login: (username: string, password: string) => any;
}

interface LoginCredentials {
  email: string;
  password: string;
}

type LoginProps = OwnProps & DispatchProps;

const loginFormValidator = yup.object<LoginCredentials>({
  email: yup.string().email('The email is not valid').required('This field is required'),
  password: yup.string().required('This field is required'),
});

const getErrorMessage = (error: Error, t: i18next.TFunction) => {
  let errorMessage;

  if (error.message.match(/Invalid username or password/)) {
    errorMessage = t('Invalid username or password');
  } else if (error.message.match(/Password is expired/)) {
    errorMessage = t('Password is expired');
  } else if (error.message.match(/User has not confirmed through opt-in/)) {
    errorMessage = t('User has not confirmed through opt-in');
  }

  return errorMessage;
};

const Login: React.FC<LoginProps> = (props) => {
  const [fbError, setFbError] = useState<number>(0);
  const [error, setError] = useState<Error>();

  const { t } = useTranslation();
  const {
    errors,
    handleSubmit,
    register,
    formState,
  } = useForm<LoginCredentials>({ mode: 'onBlur', validationSchema: loginFormValidator });

  const resetError = React.useCallback(() => {
    setFbError(0);
    setError(undefined);
  }, []);

  const onSubmit = handleSubmit(React.useCallback(async (credentials: LoginCredentials) => {
    try {
      resetError();
      await props.login(credentials.email, credentials.password);
      props.onLoginSuccess(LoginSources.email);
    } catch (err: any) {
      err.data = err.data || {};
      props.onLoginFailure(LoginSources.email, err.statusCode, err.data.code);
      setError(err);
    }
  }, [props.onLoginSuccess, props.onLoginFailure]));

  const handleFbLoginFailure = React.useCallback((status: number, code?: number) => {
    props.onLoginFailure(LoginSources.facebook, status, code);
    setFbError(status);
  }, [props.onLoginFailure]);

  return (
    <>
      <Row>
        <Col>
          <form onSubmit={onSubmit} onChange={resetError} className='py-3 login-form'>
            <fieldset disabled={formState.isSubmitting}>
              <Row>
                <Col data-testid='login-email-input__container'>
                  <FormInput
                    id='login-form-email'
                    placeholder={t('E-Mail')}
                    type='email'
                    name='email'
                    errors={errors}
                    ref={register}
                    data-testid='login-email-input'
                  />
                </Col>
              </Row>
              <Row>
                <Col className='my-2' data-testid='login-password-input__container'>
                  <FormInput
                    id='login-form-password'
                    placeholder={t('Password')}
                    name='password'
                    type='password'
                    errors={errors}
                    ref={register}
                    data-testid='login-password-input'
                  />
                </Col>
              </Row>
              {
                error &&
                <Row className='my-1'>
                  <Col className='text-center login__error-message'>
                    <p className='text-danger'>
                      {getErrorMessage(error, t)}
                    </p>
                  </Col>
                </Row>
              }
              <Row>
                <Col>
                  <Button variant='primary' type='submit' className='login-button w-100'>
                    {!formState.isSubmitting && <span>{t('Login')}</span>}
                    {formState.isSubmitting && <Spinner animation='border' />}
                  </Button>
                </Col>
              </Row>
            </fieldset>
          </form>
          <Row className='my-2'>
            <Col className='d-flex justify-content-center'>
              <h4>
                {t('or')}
              </h4>
            </Col>
          </Row>
          {!isSchoolSubdomain(window.location.hostname) &&
            <Row className='mb-3'>
              <Col className='d-flex justify-content-center facbook-button-style-overwrite flex-column'>
                {
                  fbError !== 0 && (
                    <Row className='my-1'>
                      <Col className='text-center'>
                        <p className='text-danger'>
                          {t('Registration via Facebook is currently not possible, please use your email address')}.
                        </p>
                      </Col>
                    </Row>
                  )
                }
                <FacebookLogin
                  onClick={resetError}
                  onLoginSuccess={() => props.onLoginSuccess(LoginSources.facebook)}
                  onLoginFailure={handleFbLoginFailure}
                />
              </Col>
            </Row>
          }
          <Row>
            <Col className='d-flex justify-content-center'>
              {isFeatureEnabled('luxSSO')
                ? <a
                  className='btn login-sso-button border-secondary text-secondary bg-white'
                  href={config.links.sso.lux}
                >
                  <img src={iam} className='login-button__icon' />
                  {t('Login with IAM')}
                </a>
                : <WithTooltip text={t('Luxemburg Lock Information')} placement='right'>
                  <a className='btn login-sso-button border-secondary text-secondary bg-white'>
                    <img src={iam} className='login-button__icon' />
                    {t('Login with IAM')}
                  </a>
                </WithTooltip>
              }
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col>
          <Col className='d-flex justify-content-between border-top py-4 px-0'>
            <Link to={`/${routes.resetPassword.root}`}>{t('Forgot Password?')}</Link>
            {!isSchoolSubdomain(window.location.hostname) &&
              <WithTracking
                events={{ onClick: trackingEvents.registrationClicked }}
              >
                <Link to={`/${routes.register.root}`} data-testid='registration-btn'>{t('Registration')}</Link>
              </WithTracking>
            }
          </Col>
        </Col>
      </Row>
    </>
  );
};

const mapDispatch = {
  login,
};

export default connect<Record<string, unknown>, DispatchProps, OwnProps>(
  undefined,
  mapDispatch
)(Login);
