import api from '../clients/api';
import { handleUnauthorized } from '../utils/apiWrapper';
import { notifyBugsnagNetwork } from '../utils/bugsnagClient';

const service = {
  fetchPublishers,
  fetchSchoolTypes,
  fetchTeachingFormats,
  fetchWorkloads,
};

function fetchPublishers() {
  notifyBugsnagNetwork('text', 'fetchPublishers');
  return handleUnauthorized(() => {
    return api.text.getPublishers();
  });
}

function fetchSchoolTypes() {
  notifyBugsnagNetwork('text', 'fetchSchoolTypes');
  return handleUnauthorized(() => {
    return api.text.getSchoolTypes();
  });
}

function fetchWorkloads() {
  notifyBugsnagNetwork('text', 'fetchWorkloads');
  return handleUnauthorized(() => {
    return api.text.getWorkloads();
  });
}

function fetchTeachingFormats() {
  notifyBugsnagNetwork('text', 'fetchTeachingFormats');
  return handleUnauthorized(() => {
    return api.text.getTeachingFormats();
  });
}

export default service;
