import { SubscriptionResponse } from '@klab-berlin/api-sdk/lib/types/responses/Billing';
import { SuspendedPageType } from '../routes/upgrade/SuspendedPage/SuspendedPage';

export const isSuspended = (subscription: SubscriptionResponse | undefined) => {
  return subscription?.status === 'Suspended' && !subscription.suspension?.isPause;
};

export const isPaused = (subscription: SubscriptionResponse | undefined) => {
  return subscription?.status === 'Suspended' && subscription.suspension?.isPause;
};

export const getSuspensionType = (subscription: SubscriptionResponse | undefined) => {
  return isPaused(subscription) ? SuspendedPageType.PAUSED : SuspendedPageType.SUSPENDED;
};
