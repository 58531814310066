import {
  RegisterConfirmPayload,
  RegisterBasisPayload,
  RegisterLibraryPayload,
  RegisterPremiumPayload,
} from '@klab-berlin/api-sdk/lib/types/requests/Registration';
import api from '../clients/api';
import { notifyBugsnagNetwork } from '../utils/bugsnagClient';

const service = {
  confirm,
  registerBasis,
  registerLibrary,
  registerPremium,
};

function confirm(token: string, payload: RegisterConfirmPayload) {
  notifyBugsnagNetwork('registration', 'confirm', { token, payload });
  return api.registration.confirm(token, payload);
}

function registerPremium(payload: RegisterPremiumPayload) {
  notifyBugsnagNetwork('registration', 'confirm', { payload });
  return api.registration.registerPremium(payload);
}

function registerBasis(payload: RegisterBasisPayload) {
  notifyBugsnagNetwork('registration', 'register-basis', { payload });
  return api.registration.registerBasis(payload);
}

function registerLibrary(payload: RegisterLibraryPayload) {
  notifyBugsnagNetwork('registration', 'register-basis', { payload });
  return api.registration.registerLibrary(payload);
}

export default service;
