/**
 * Returns an array mapped to an object
 * 
 * @param byKey Function to determine the object key for a given array value
 * @param condition Function to determine whether array entry should be in the object
 */
export const filteredArrayToObject = <T>(
  array: T[],
  byKey: (value: T) => string,
  condition: (value: T) => boolean
): Dictionary<T> => {
  const mappedArray = array.map(value =>
    condition(value)
      ? { [byKey(value)]: value }
      : undefined);

  return Object.assign({}, ...mappedArray);
};

export const mappedArrayToObject = <T, V>(
  array: T[],
  byKey: (value: T) => string,
  mapper: (value: T) => V,
): Dictionary<V> => {
  const mappedArray = array.map(value => ({ [byKey(value)]: mapper(value) }));

  return Object.assign({}, ...mappedArray);
};

export const arrayToObject = <T>(
  array: T[],
  byKey: (value: T) => string,
): Dictionary<T> => {
  return filteredArrayToObject(array, byKey, () => true);
};
