import { AsyncAction } from './common.actionTypes';
import { Lernset, PartsImages } from '@klab-berlin/api-sdk/lib/types/responses/Lernsets';

export enum GET_LERNSETS {
  START = 'GET_LERNSETS_START',
  SUCCESS = 'GET_LERNSETS_SUCCESS',
  ERROR = 'GET_LERNSETS_ERROR',
}

export enum ADD_LERNSET {
  START = 'ADD_LERNSET_START',
  SUCCESS = 'ADD_LERNSET_SUCCESS',
  ERROR = 'ADD_LERNSET_ERROR',
}

export enum ADD_PAGES_LERNSET {
  START = 'ADD_PAGES_LERNSET_START',
  SUCCESS = 'ADD_PAGES_LERNSET_SUCCESS',
  ERROR = 'ADD_PAGES_LERNSET_ERROR',
}

export enum FETCH_PART_IMAGES {
  START = 'FETCH_PART_IMAGES_START',
  SUCCESS = 'FETCH_PART_IMAGES_SUCCESS',
  ERROR = 'FETCH_PART_IMAGES_ERROR',
}

export enum DELETE_LERNSET {
  START = 'DELETE_LERNSET_START',
  SUCCESS = 'DELETE_LERNSET_SUCCESS',
  ERROR = 'DELETE_LERNSET_ERROR',
}

export enum DUPLICATE_LERNSET {
  START = 'DUPLICATE_LERNSET_START',
  SUCCESS = 'DUPLICATE_LERNSET_SUCCESS',
  ERROR = 'DUPLICATE_LERNSET_ERROR',
}

export enum UPDATE_LERNSET {
  START = 'UPDATE_LERNSET_START',
  SUCCESS = 'UPDATE_LERNSET_SUCCESS',
  ERROR = 'UPDATE_LERNSET_ERROR',
}

export enum ADD_BLANK_PAGE {
  START = 'ADD_BLANK_PAGE_START',
  SUCCESS = 'ADD_BLANK_PAGE_SUCCESS',
  ERROR = 'ADD_BLANK_PAGE_ERROR',
}

export enum ADD_RICH_TEXT_PART {
  START = 'ADD_RICH_TEXT_PART_START',
  SUCCESS = 'ADD_RICH_TEXT_PART_SUCCESS',
  ERROR = 'ADD_RICH_TEXT_PART_ERROR',
}

export type GetLernsetsAction = AsyncAction<
  typeof GET_LERNSETS.START,
  typeof GET_LERNSETS.SUCCESS,
  typeof GET_LERNSETS.ERROR,
  Lernset[]
>;

export type AddLernsetAction = AsyncAction<
  typeof ADD_LERNSET.START,
  typeof ADD_LERNSET.SUCCESS,
  typeof ADD_LERNSET.ERROR,
  Lernset
>;

export type AddPagesLernsetAction = AsyncAction<
  typeof ADD_PAGES_LERNSET.START,
  typeof ADD_PAGES_LERNSET.SUCCESS,
  typeof ADD_PAGES_LERNSET.ERROR,
  Lernset
>;

export type DeleteLernsetAction = AsyncAction<
  typeof DELETE_LERNSET.START,
  typeof DELETE_LERNSET.SUCCESS,
  typeof DELETE_LERNSET.ERROR,
  { id: string }
>;

export type DuplicateLernsetAction = AsyncAction<
  typeof DUPLICATE_LERNSET.START,
  typeof DUPLICATE_LERNSET.SUCCESS,
  typeof DUPLICATE_LERNSET.ERROR,
  { duplicatedLernsetPartId: string },
  { lernsetId: string, partId: string }
>;

export type FetchPartImagesAction = AsyncAction<
  typeof FETCH_PART_IMAGES.START,
  typeof FETCH_PART_IMAGES.SUCCESS,
  typeof FETCH_PART_IMAGES.ERROR,
  PartsImages,
  { lernsetId: string }
>;

export const SetLastAnnotatedPartIdActionType = 'SET_LAST_ANNOTATED_PART_ID';
export type SetLastAnnotatedPartIdAction = {
  type: typeof SetLastAnnotatedPartIdActionType,
  payload: { partId: string }
};

export const ClearPartsImagesActionType = 'CLEAR_PARTS_IMAGES';
export type ClearPartsImagesAction = {
  type: typeof ClearPartsImagesActionType,
};

export type UpdateLernsetAction = AsyncAction<
  typeof UPDATE_LERNSET.START,
  typeof UPDATE_LERNSET.SUCCESS,
  typeof UPDATE_LERNSET.ERROR,
  Record<string, never>,
  { action: string, id: string, title?: string, partId?: string, direction?: string}
>;

export type AddBlankPageAction = AsyncAction<
  typeof ADD_BLANK_PAGE.START,
  typeof ADD_BLANK_PAGE.SUCCESS,
  typeof ADD_BLANK_PAGE.ERROR,
  { newPartId: string, imageUrl: string},
  { partIndex: number, lernsetId: string}
>;

export type AddRichTextPartAction = AsyncAction<
  typeof ADD_RICH_TEXT_PART.START,
  typeof ADD_RICH_TEXT_PART.SUCCESS,
  typeof ADD_RICH_TEXT_PART.ERROR,
  { newPartId: string },
  { partIndex: number, lernsetId: string }
>;
