import { ItemVariation } from '@klab-berlin/api-sdk/lib/types/responses/Collection';
import { useCallback } from 'react';
import {
  pinMindItem as pinMindItemAction,
  pinProduct as pinProductAction,
  unpinDocument as unpinDocumentAction
} from '../actions/pinned.actions';
import useAction from './useAction';

const usePinAction = (itemId: string, isPinned: boolean | undefined, type: ItemVariation) => {
  const pinMindItem = useAction(pinMindItemAction);
  const pinProduct = useAction(pinProductAction);
  const unpinDocument = useAction(unpinDocumentAction);

  const action = useCallback(() => {
    if (isPinned === true) {
      return unpinDocument(itemId, type);
    } else if (isPinned === false) {
      if (type === 'product') {
        return pinProduct(itemId);
      }

      return pinMindItem(itemId);
    } else {
      return;
    }
  }, [itemId, isPinned]);

  return action;
};

export default usePinAction;
