import Icon from './Icon';
import MuBag from '../assets/MuBag.svg';
import classnames from 'classnames';
import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Jumbo from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { deleteCollection, updateCollection } from '../actions/collections.actions';
import FollowButton from '../components/FollowButton';
import JumbotronMeatballMenu from './JumbotronMeatballMenu';
import Timestamp from './Timestamp';
import CollectionIcon from './CollectionIcon';
import { CollectionProperties } from '../types/AppContent';
import SubjectBanners from '../assets/subjects/banners';
import { StoreType } from '../reducers';
import { Image } from 'react-bootstrap';

interface JumbotronOwnProps {
  collection: CollectionProperties;
}

interface StateProps {
  me: StoreType['user']['me'];
}

interface JumbotronProps extends JumbotronOwnProps {
  deleteCollection: typeof deleteCollection;
  updateCollection: typeof updateCollection;
}

type JumbotronContextSelectedProps = JumbotronProps & StateProps;

const Jumbotron: React.FC<JumbotronContextSelectedProps> = ({ collection, ...props }) => { 
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const ownedByCurrentUser = collection.owner === (props.me && props.me._id);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const subject = collection.subjects[0];

  const JumbotronSVG = subject && SubjectBanners[subject]
    ? SubjectBanners[subject]
    : SubjectBanners.Didaktik;

  return (
    <Jumbo className={classnames('my-4 mx-0 p-0 bg-white', {
      'text-white': !ownedByCurrentUser
    })}>
      <div
        className={
          classnames('toogle border w-100 h-100 position-relative', {
            'is-open': isOpen,
            'with-background': !ownedByCurrentUser,
          })
        }
      >
        {collection.isPublic &&
        <div className='jumbotron__background absolute-all-0 w-100 overflow-hidden rounded'>
          <JumbotronSVG.large />
        </div>
        }
        <div className={classnames('absolute-all-0 d-flex flex-column px-4 py-3', {
          'text-dark': !collection.isPublic,
          'text-white': collection.isPublic,
        })}>
          <div className='heading d-flex pb-md-1'>
            <CollectionIcon color={collection.isPublic ? 'white' : collection.color} className='pr-2'/>

            <h1
              className={'text-truncate my-0'}
            >
              {collection.title}
            </h1>
          </div>
          <div>
            {collection.subjects && collection.subjects.length > 0 &&
            <div className='pt-sm-2 pt-md-3'>{collection.subjects.join(', ')}</div>
            }
            {collection.schoolType &&
            <div className='pt-sm-2 pt-md-3'>{collection.schoolType}</div>
            }
          </div>
          <div className='flex-grow-1 p-0 d-flex flex-row align-items-end'>
            <Container className={'flex-fill'} fluid={true}>
              <Row className='pt-sm-2 pt-md-3'>
                <Col xs={6} sm={5} className='p-0 d-flex flex-row justify-content-start align-items-center'>
                  {!ownedByCurrentUser &&
                  <>
                    <div className='owner-icon rounded-circle mr-2 bg-white text-center'>
                      {collection.isPublic && (collection.ownerData && collection.ownerData.imageUrl ?
                        (
                          <Image roundedCircle={true} height='30px' width='30px' src={collection.ownerData.imageUrl} />
                        )
                        :
                        (
                          <div>
                            <MuBag />
                          </div>
                        ))
                      }
                    </div>
                    <span>{ collection.ownerData && collection.ownerData.name }</span>
                  </>
                  }
                  {ownedByCurrentUser && !collection.isPublic &&
                  <>
                    <Icon icon='private' size='xl' className='pr-2 align-middle color--grey-light' />
                    <span>{t('Private')}</span>
                  </>
                  }
                  {ownedByCurrentUser && collection.isPublic &&
                  <>
                    <Icon icon='public' size='xl' className='pr-2 align-middle color--grey-light' />
                    <span>{t('Public')}</span>
                  </>}
                </Col>
                <Col xs={{ span: 12, order: 12 }} sm={{ span: 2, order: 1 }}
                  className='p-0 d-flex flex-row justify-content-center align-items-end'>
                  <div onClick={toggle} className='cursor-pointer'>
                    <Icon icon={isOpen ? 'arrow-small-up' : 'arrow-small-down'} />
                  </div>
                </Col>
                <Col xs={6} sm={{ span:5, order: 12 }}
                  className='p-0 d-flex flex-row justify-content-end align-items-end'
                >
                  {
                    !ownedByCurrentUser &&
                    <div className={'d-none d-md-block'}>
                      <FollowButton collection={collection} />
                    </div>
                  }
                  {
                    ownedByCurrentUser &&
                    <div className={classnames({ 'rounded-sm ml-2 btn btn-light': collection.isPublic })}>
                      <JumbotronMeatballMenu
                        collection={collection}
                        deleteCollection={props.deleteCollection}
                        canPublish={props.me && props.me.canPublishCuratedCollections || false}
                        updateCollection={props.updateCollection}
                      />
                    </div>
                  }
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <Container fluid={true} className={
        classnames('toggle-content border border-top-0 pt-3 pb-4', {
          'd-none': !isOpen,
        })
      }>
        {collection.description && collection.description.length &&
          <Row className='py-2 text-muted'>
            <Col>
              {collection.description.split('\n').map((item, key) =>
                <React.Fragment key={key}>{item}<br/></React.Fragment>)
              }
            </Col>
          </Row>
        }
        <Row className='py-2 text-muted'>
          <Col>
            <div>{t('Created')}:&nbsp;<Timestamp value={collection.created}/></div>
            <div className='pt-3'>{t('Updated')}:&nbsp;<Timestamp value={collection.changed}/></div>
          </Col>
        </Row>
      </Container>
      <Container fluid={true}>
        {collection.isPublic &&
          <div className='pt-3 d-flex d-md-none flex-row justify-content-center'>
            <FollowButton collection={collection} />
          </div>
        }
      </Container>
    </Jumbo>
  );
};

export default compose<React.FC<JumbotronOwnProps>>(
  connect(
    (state: StoreType): StateProps => ({
      me: state.user.me,
    }),
    {
      updateCollection,
      deleteCollection,
    },
  ),
)(Jumbotron);
