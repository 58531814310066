import React from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import DownloadLimitExceededOverlay
  from '../Reader/ReaderContent/ReaderPagesBasis/NewBasisReaderOverlay/DownloadLimitExceededOverlay';
import { WatermarkTrackingObject }
  from '../Reader/ReaderContent/ReaderPagesBasis/NewBasisReaderOverlay/NewBasisReaderOverlay';

interface DownloadLimitExceededProps {
  isPremium: boolean;
  onClose: () => void;
  show: boolean;
  trackingObject: WatermarkTrackingObject;
}

const NewDownloadLimitExceeded: React.FC<DownloadLimitExceededProps> = (props) => {
  return <BootstrapModal onHide={props.onClose} show={props.show} centered={true}>
    <DownloadLimitExceededOverlay
      trackingObject={props.trackingObject}
      isPremium={props.isPremium}
      onClose={props.onClose}
    />
  </BootstrapModal>;
};

export default NewDownloadLimitExceeded;
