import React, { useState } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const ZoomContainer: React.FC = (props) => {
  const [panDisabled, setPanDisabled] = useState(true);
  return (
    <TransformWrapper
      // Prevent zoom when using mouse wheel
      wheel={{ disabled: true }}
      pan={{ disabled: panDisabled }}
      onZoomChange={(zoom: { scale: number }) => {
        // Allow scrolling when zoomed out
        setPanDisabled(zoom.scale <= 1);
      }}>
      <TransformComponent>
        {props.children}
      </TransformComponent>
    </TransformWrapper>
  );
};

export default ZoomContainer;
