import BiologieBannerLarge from './large/biology.svg';
import ChemieBannerLarge from './large/chemistry.svg';
import DeutschBannerLarge from './large/german.svg';
import DaZBannerLarge from './large/germanAsForeign.svg';
import EnglischBannerLarge from './large/english.svg';
import ErdkundeBannerLarge from './large/geography.svg';
import FranzösischBannerLarge from './large/french.svg';
import GeschichteBannerLarge from './large/history.svg';
import HauswirtschaftBannerLarge from './large/householdArts.svg';
import LateinBannerLarge from './large/latin.svg';
import MathematikBannerLarge from './large/mathematics.svg';
import DidaktikBannerLarge from './large/didactics.svg';
import PhysikBannerLarge from './large/physics.svg';
import PolitikBannerLarge from './large/politics.svg';
import ReligionBannerLarge from './large/religion.svg';
import SachkundeBannerLarge from './large/socialStudies.svg';
import SpanischBannerLarge from './large/spanish.svg';

import BiologieBannerSmall from './small/biology.svg';
import ChemieBannerSmall from './small/chemistry.svg';
import DeutschBannerSmall from './small/german.svg';
import DaZBannerSmall from './small/germanAsForeign.svg';
import EnglischBannerSmall from './small/english.svg';
import ErdkundeBannerSmall from './small/geography.svg';
import FranzösischBannerSmall from './small/french.svg';
import GeschichteBannerSmall from './small/history.svg';
import HauswirtschaftBannerSmall from './small/householdArts.svg';
import LateinBannerSmall from './small/latin.svg';
import MathematikBannerSmall from './small/mathematics.svg';
import DidaktikBannerSmall from './small/didactics.svg';
import PhysikBannerSmall from './small/physics.svg';
import PolitikBannerSmall from './small/politics.svg';
import ReligionBannerSmall from './small/religion.svg';
import SachkundeBannerSmall from './small/socialStudies.svg';
import SpanischBannerSmall from './small/spanish.svg';

const images: {
  [key: string]: {
    large: React.FC<{ className?: string }>;
    small: React.FC<{ className?: string }>;
  };
} = {
  Biologie: {
    large: BiologieBannerLarge,
    small: BiologieBannerSmall
  },
  Chemie: {
    large: ChemieBannerLarge,
    small: ChemieBannerSmall
  },
  Deutsch: {
    large: DeutschBannerLarge,
    small: DeutschBannerSmall
  },
  DaZ: {
    large: DaZBannerLarge,
    small: DaZBannerSmall
  },
  Englisch: {
    large: EnglischBannerLarge,
    small: EnglischBannerSmall
  },
  Erdkunde: {
    large: ErdkundeBannerLarge,
    small: ErdkundeBannerSmall
  },
  Französisch: {
    large: FranzösischBannerLarge,
    small: FranzösischBannerSmall
  },
  Geschichte: {
    large: GeschichteBannerLarge,
    small: GeschichteBannerSmall
  },
  Hauswirtschaft: {
    large: HauswirtschaftBannerLarge,
    small: HauswirtschaftBannerSmall
  },
  Latein: {
    large: LateinBannerLarge,
    small: LateinBannerSmall
  },
  Mathematik: {
    large: MathematikBannerLarge,
    small: MathematikBannerSmall
  },
  Didaktik: {
    large: DidaktikBannerLarge,
    small: DidaktikBannerSmall
  },
  Physik: {
    large: PhysikBannerLarge,
    small: PhysikBannerSmall
  },
  Politik: {
    large: PolitikBannerLarge,
    small: PolitikBannerSmall
  },
  'Religion-Ethik': {
    large: ReligionBannerLarge,
    small: ReligionBannerSmall
  },
  Sachkunde: {
    large: SachkundeBannerLarge,
    small: SachkundeBannerSmall
  },
  Spanisch: {
    large: SpanischBannerLarge,
    small: SpanischBannerSmall
  }
};

export default images;
