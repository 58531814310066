import React from 'react';
import { AsyncActionStatus } from '../../actions/common.actionTypes';
import { ContentItem } from '../../types/AppContent';
import { SlideDefinition } from '../Carousel/Carousel';
import CarouselSkeleton from '../Carousel/CarouselSkeleton';
import DashboardCarousel from './DashboardCarousel';
import DashboardSlideItem from './DashboardSlideItem';
import { useTranslation } from 'react-i18next';
import { routes } from '../../constants';

interface DashboardPinnedDocumentsCarouselProps {
  carouselPosition: number;
  items: ContentItem[];
  numberOfSlides: SlideDefinition;
  status: AsyncActionStatus;
}

const DashboardPinnedDocumentsCarousel: React.FC<DashboardPinnedDocumentsCarouselProps> = ({ items, ...props }) => {
  const { t } = useTranslation();

  const itemIds = items.map(item => item.id);

  return (
    <DashboardCarousel
      title={t('Your Pinned Documents')}
      uri={`/${routes.pinned.root}`}
      numberOfSlides={props.numberOfSlides}
      carouselDocumentIds={itemIds}
      carouselType='pinnedDocuments'
      carouselPosition={props.carouselPosition}
      slides={items.map(item => (
        <DashboardSlideItem
          key={item.id}
          item={item}
          type={item.type}
          carouselPosition={props.carouselPosition}
          carouselDocumentIds={itemIds}
          carouselType='pinnedDocuments'
        />
      ))}
      infinite={true}
      isLoading={props.status === 'loading'}
      skeleton={
        <CarouselSkeleton
          height={376}
          numberOfSlides={props.numberOfSlides}
          width={256}
        />
      }
    />
  );
};

export default React.memo(DashboardPinnedDocumentsCarousel);
