import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../reducers/user.selectors';
import { isCorrectHostnameForUser, switchSubdomain } from '../../utils/subdomain';

const SubdomainChecker: React.FC = () => {
  const user = useSelector(selectUser);

  useEffect(() => {
    if (user) {
      if (!isCorrectHostnameForUser(window.location.hostname, user)) {
        switchSubdomain(window.location);
      }
    }
  }, [user]);

  return null;
};

export default SubdomainChecker;
