import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { QRCodeSVG } from 'qrcode.react';
import { Box, Button, SvgIcon, Typography } from '@mui/material';
import { borderRadius, palette, spaces } from '../../../assets/styles/themes/tokens';
import { SnackbarContext } from '../../../state/SnackbarContextProvider';
import { downloadFile, convertSvgToPng } from '../../../utils/lernsets';
import download from '../../../assets/lernsets/icons/import.svg';

interface QrCodeProps { link: string, imageName: string, onDownload: () => void }

const QrCode: React.FC<QrCodeProps> = (props) => {
  const { t } = useTranslation();
  const { openSnackbar } = useContext(SnackbarContext);
  const { link, imageName, onDownload } = props;
  const qrContainerRef = useRef<HTMLElement | null>(null);
  const qrDimensions = { width: 300, height: 300 };

  const handleDownload = async () => {
    try {
      const { width, height } = qrDimensions;
      const svg = qrContainerRef.current?.querySelector('svg');
      if (!svg) throw new Error('SVG element not found');

      const fileUrl = await convertSvgToPng(svg, width, height);
      downloadFile(fileUrl, `${imageName}.png`);
      onDownload();
    } catch (error) {
      openSnackbar({
        type: 'error',
        message: t('The QR code could not be downloaded. Please try again'),
      });
    }
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: spaces.xs,
    }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <Typography variant='h6' color={palette.text.primary}>{t('QR-Code')}</Typography>
        <Button
          variant='text'
          size='medium'
          onClick={handleDownload}
          startIcon={
            <SvgIcon 
              component={download} 
              sx={{  fill: palette.primary.main }}
            />
          }
        >
          {t('Download')}
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 396,
          width: '100%',
          border: `1px solid ${palette.elevation.outlined}`,
          borderRadius: borderRadius.sm,
        }}>
        <Box ref={qrContainerRef}>
          <QRCodeSVG
            data-testid='qr-code' 
            value={link}
            // eslint-disable-next-line react/forbid-component-props
            style={{ width: qrDimensions.width, height: qrDimensions.height }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default QrCode;
