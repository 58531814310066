import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'found';
import MuLogoSvg from '../../assets/MuLogo.svg';
import { emailAddresses, routes } from '../../constants';

const ChangeEmailError = () => {
  const { t } = useTranslation();

  return (
    <Container className='h-100 d-flex flex-column justify-content-between'>
      <Row>
        <Container>
          <Row className='my-4'>
            <MuLogoSvg className='fill--mu-blue h-100 w-100' />
          </Row>
          <Row>
            <div className='font-size-larger font-weight-bolder my-2'>
              {t('Ups, something went wrong')}
            </div>
            <div className='my-2'>
              {`${t('Please')} `}
              <Link to={`/${routes.personalDetails.root}/profil`}>
                {`${t('Try again to change your email address')}`}
              </Link>
              {`${t('Or contact')} `}<a href={`mailto:${emailAddresses.support}`}>{emailAddresses.support}</a>.
            </div>
          </Row>
        </Container>
      </Row>
      <Row className='justify-content-center my-4'>
        <Link className='btn btn-lg btn-primary w-100' to={`/${routes.dashboard.root}`}>
          {t('Back to meinUnterricht')}
        </Link>
      </Row>
    </Container>
  );
};

export default ChangeEmailError;
