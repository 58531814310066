import React from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import ExclusiveContentOverlay
  from '../../Reader/ReaderContent/ReaderPagesBasis/NewBasisReaderOverlay/ExclusiveContentOverlay';
import './premiumContentModal.scss';

interface OwnProps {
  show: boolean;
  onClose: () => void;
}

const PremiumContentModal: React.FC<OwnProps> = ({ show, onClose }) => {
  return <NewPremiumContentModal show={show} onClose={onClose} />;
};

const NewPremiumContentModal: React.FC<OwnProps> = ({ show, onClose }) => (
  <BootstrapModal onHide={onClose} show={show} centered={true}>
    <ExclusiveContentOverlay category='attachment' onClose={onClose} />
  </BootstrapModal>
);

export default PremiumContentModal;
