import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import services from '../../../services';
import { Instance } from 'pspdfkit';
import useAction from '../../../utils/useAction';
import { updateLernset } from '../../../actions/lernsets.actions';
import { AnnotationToolUsed } from '../../../ampli';
import { getAmpliContentData } from '../../../utils/ampli';
import { getLernsetById } from '../../../reducers/lernsets.selectors';
import { useSelector } from 'react-redux';
import { selectMindItem } from '../../../reducers/mindItems.selectors';
import toolsIcons from './AnnotatorToolsIcons';
import useRouter from '../../../utils/useRouter';
import { routes } from '../../../constants';
import { useTranslation } from 'react-i18next';
import { trackDownloadedEvent, trackPrintedEvent } from '../../../utils/lernsets';

interface AnnotatorProps {
  lernsetId: string;  
  partId: string;
}

const LernsetsAnnotator: React.FC<AnnotatorProps> = ({ lernsetId, partId }) => {
  const updateLernsetAction = useAction(updateLernset);
  const lernset = useSelector(state => getLernsetById(state, lernsetId));
  const lernsetTitle = lernset?.title;
  const mindItemId = lernset?.parts.find(part => part.id === partId)?.origin?.mindItemId;
  const mindItem = useSelector(state => selectMindItem(state, mindItemId || ''));
  const [instance, setInstance] = useState<Instance>();
  const { router } = useRouter();
  const { t } = useTranslation();
  const saveStatusToolIndex = useRef<number | null>(null);
  const amountOfUpdate = useRef(0);
  const containerRef = useRef(null);

  const onBackClick = () => {
    router.replace(`/${routes.lernsetsOverview.root}/${lernsetId}`);
  };

  const saveStatuses = {
    saved: {
      icon: toolsIcons.saved,
      title: t('Saved to set'),
    },
    saving: {
      icon: toolsIcons.saving,
      title: t('Will be saved'),
    }
  };

  const tools = [
    {
      type: 'custom',
      id: 'back-button',
      title: 'Zum Set',
      icon: toolsIcons.back,
      onPress: onBackClick,
      className: 'back-button-tool',
    },
    { type: 'ink', dropdownGroup: 'draw', icon: toolsIcons.ink },
    { type: 'highlighter', dropdownGroup: 'draw', icon: toolsIcons.highlighter },
    { type: 'text-highlighter', dropdownGroup: 'draw', icon: toolsIcons.textHighlighter },
    { type: 'ink-eraser', dropdownGroup: 'draw', icon: toolsIcons.inkEraser },
    { type: 'text', dropdownGroup: 'text', icon: toolsIcons.text },
    { type: 'callout', dropdownGroup: 'text', icon: toolsIcons.callout },
    { type: 'arrow', dropdownGroup: 'line', icon: toolsIcons.arrow },
    { type: 'line', dropdownGroup: 'line', icon: toolsIcons.line },
    { type: 'polyline', dropdownGroup: 'line', icon: toolsIcons.polyline },
    { type: 'rectangle', dropdownGroup: 'shape',icon: toolsIcons.rectangle },
    { type: 'ellipse', dropdownGroup: 'shape', icon: toolsIcons.ellipse },
    { type: 'polygon', dropdownGroup: 'shape', icon: toolsIcons.polygon },
    { type: 'image', dropdownGroup: 'other', icon: toolsIcons.image },
    { type: 'note', dropdownGroup: 'other', icon: toolsIcons.note },
    { type: 'link', dropdownGroup: 'other', icon: toolsIcons.link },
    { type: 'spacer' },
    { type: 'undo', icon: toolsIcons.undo },
    { type: 'redo', icon: toolsIcons.redo },
    { type: 'spacer' },
    { type: 'zoom-out', icon: toolsIcons.zoomOut },
    { type: 'zoom-in', icon: toolsIcons.zoomIn },
    { type: 'zoom-mode', icon: toolsIcons.zoomMode },
    { type: 'export-pdf', icon: toolsIcons.exportPdf },
    { type: 'print', icon: toolsIcons.print },
    { type: 'search', icon: toolsIcons.search },
    {
      type: 'custom',
      id: 'save-status',
      title: saveStatuses.saved.title,
      icon: saveStatuses.saved.icon,
      className: 'save-status-tool',
    },
  ];

  const trackAnnotationToolUsedEvent = async () => {
    if (lernsetTitle && amountOfUpdate.current > 0){
      services.track.ampliEventTrack({ 
        event: new AnnotationToolUsed({
          content: mindItem ? getAmpliContentData(mindItem, 'mindItem') : getAmpliContentData({}, 'blankPage') as any,
          'lernset id': lernsetId,
          'lernset title': lernsetTitle,
          'amount of updates': amountOfUpdate.current,
        })
      }); 
  
      amountOfUpdate.current = 0;
    }
  };
  
  useEffect(() => {
    loadPSPDFKit();

    return () => {
      if (instance) {
        window.PSPDFKit.unload(instance);
      }
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(trackAnnotationToolUsedEvent, 60000); // 60000 milliseconds = 1 minute

    return () => {
      if (interval) clearInterval(interval);
      trackAnnotationToolUsedEvent();
    };
  }, [mindItem, lernsetTitle]);

  useEffect(() => {
    if (instance && lernsetTitle) {
      const printButton = instance.contentDocument.querySelector('.PSPDFKit-Toolbar-Button-Print');
      const downloadButton = instance.contentDocument.querySelector('.PSPDFKit-Toolbar-Button-Export-PDF');

      printButton?.addEventListener('click', () => {
        trackPrintedEvent(lernsetId, lernsetTitle, 'editor');
      });

      downloadButton?.addEventListener('click', () => {
        trackDownloadedEvent(lernsetId, lernsetTitle, 'editor');
      });
    }
  }, [instance, lernsetTitle]);

  const updateSaveStatus = (instance: Instance, isSaving: boolean) => {
    if (instance) {
      const tools = instance.toolbarItems;

      if (saveStatusToolIndex.current === null) {
        saveStatusToolIndex.current = tools.findIndex((tool) => tool.id === 'save-status');
      }

      tools[saveStatusToolIndex.current].icon = isSaving ? saveStatuses.saving.icon : saveStatuses.saved.icon;
      tools[saveStatusToolIndex.current].title = isSaving ? saveStatuses.saving.title : saveStatuses.saved.title;

      instance.setToolbarItems(tools);
    }
  };

  const customizeGermanLocalization = async () => {
    await window.PSPDFKit.I18n.preloadLocalizationData('de');
    window.PSPDFKit.I18n.messages.de.textAnnotation = 'Text';
    window.PSPDFKit.I18n.messages.de.calloutAnnotation = 'Hinweis';
    window.PSPDFKit.I18n.messages.de.export = 'Herunterladen';
    window.PSPDFKit.I18n.messages.de.fitWidth = 'Ganze Breite';
    window.PSPDFKit.I18n.messages.de.fitPage = 'Ganze Seite';
    window.PSPDFKit.I18n.messages.de.deleteAnnotationConfirmMessage = 'Möchtest du diese Änderungen wirklich löschen?';
  };

  const loadPSPDFKit = async () => {
    const { token } = await services.lernsets.getLernsetPartToken(lernsetId, partId);

    if (containerRef.current) {
      const configuration = {
        container: containerRef.current,
        locale: 'de',
        documentId: partId,
        authPayload: { jwt: token },
        instant: false,
        autoSaveMode: window.PSPDFKit.AutoSaveMode.IMMEDIATE,
        enableHistory: true,
        initialViewState: new window.PSPDFKit.ViewState({
          showToolbar: true,
          enableAnnotationToolbar: true
        }),
        toolbarItems: tools,
        enableRichText: () => true,
        styleSheets: ['/lernsetsAnnotator.pspdfkit.css']
      };

      await customizeGermanLocalization();

      const loadedInstance: Instance = await window.PSPDFKit.load(configuration);
      setInstance(loadedInstance);

      loadedInstance.addEventListener('annotations.didSave', async () => {
        await updateLernsetAction(lernsetId, { action: 'updateUpdatedAt' });
        amountOfUpdate.current += 1;
        updateSaveStatus(loadedInstance, false);
      });

      loadedInstance.addEventListener('annotations.willSave', () => {
        updateSaveStatus(loadedInstance, true);
      });
    }
  };

  return <Box 
    sx={{ 
      height: '100%',
    }} 
    ref={containerRef as React.RefObject<any>}
  />;
};

export default LernsetsAnnotator;

