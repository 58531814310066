import Andere from './multiple.svg';
import Biologie from './biology.svg';
import Chemie from './chemistry.svg';
import Deutsch from './german.svg';
import DaZ from './german.svg';
import Englisch from './english.svg';
import Erdkunde from './geography.svg';
import Fächerübergreifend from './interdisciplinary.svg';
import Französisch from './french.svg';
import Geschichte from './history.svg';
import Hauswirtschaft from './householdArts.svg';
import Kunst from './art.svg';
import Latein from './latin.svg';
import Mathematik from './mathematics.svg';
import Didaktik from './didactics.svg';
import Musik from './music.svg';
import Naturwissenschaften from './sciences.svg';
import Physik from './physics.svg';
import Politik from './politics.svg';
import Religion from './religion.svg';
import Sachkunde from './socialstudies.svg';
import Spanisch from './spanish.svg';
import Informatik from './informatik.svg';
import Sport from './sports.svg';
import Wirtschaft from './economics.svg';

const subject: { [key: string]: React.FC<{ className?: string }> } = {
  Andere,
  Biologie,
  Chemie,
  Deutsch,
  DaZ,
  Englisch,
  Erdkunde,
  Fächerübergreifend,
  Französisch,
  Geschichte,
  Hauswirtschaft,
  Informatik,
  Kunst,
  Latein,
  Mathematik,
  Didaktik,
  Musik,
  Naturwissenschaften,
  Physik,
  Politik,
  Philosophie: Latein,
  'Religion-Ethik': Religion,
  Sachkunde,
  Spanisch,
  Sport,
  Wirtschaft,
};

export default subject;
