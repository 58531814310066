import React from 'react';
import Toast from 'react-bootstrap/Toast';
import './customToast.scss';
import Icon from '../Icon/Icon';
import { Button } from 'react-bootstrap';
import { noop } from 'lodash';
import classnames from 'classnames';

interface CustomToastProps {
  delay?: number;
  show: boolean;
  title: string;
  bodyText: string;
  autoHide?: boolean;
  onClose?: () => void;
  variant?: string;
}

const CustomToast: React.FC<CustomToastProps> = ({
  delay,
  show,
  title,
  bodyText,
  onClose,
  autoHide = true,
  variant }) => (
  <>
    {show &&
      // @ts-ignore - ts says that <Toast /> need 'transition' prop, but it has that already (default value)
      <Toast
        autohide={autoHide}
        delay={delay}
        show={show}
        onClose={onClose || noop}
        className={classnames('custom-toast', variant === 'mobile' && 'custom-toast--mobile')}
      >
        <Toast.Header
          className='custom-toast__header'
          closeButton={false}
        >
          <div
            className='custom-toast__title'
            data-testid='custom-toast'
          > {title} </div>
          {onClose && <Button variant='link' onClick={onClose} className='custom-toast__cancel-button'>
            <Icon icon='cancel' size='lg' />
          </Button>}
        </Toast.Header>
        <Toast.Body className='custom-toast__body'>
          {bodyText}
        </Toast.Body>
      </Toast>
    }
  </>
);

export default CustomToast;
