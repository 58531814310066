import React from 'react';
import Icon from './Icon';
import { MeatballMenuContext } from '../state/MeatballMenuProvider';

interface MeatballMenuType {
  itemId: string;
  items: () => Array<React.ReactElement<any> | false>;
  className?: string;
}

const MeatballMenu: React.FC<MeatballMenuType> = ({ items, itemId }) => {
  const { toggle, itemId: contextItemId, hide } = React.useContext(MeatballMenuContext);

  const onClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    toggle(itemId !== contextItemId, itemId);
  };

  return (
    <div className='meatball-menu'>
      <button
        className='btn meatball-menu-btn p-1 text-muted'
        onClick={onClick}
      >
        <Icon icon='ellipsis' />
      </button>
      {contextItemId === itemId && (
        <div className='dropdown-menu d-block'>
          {items().map((menuItem, index) => {
            if (!menuItem) {
              return;
            }
            if (menuItem.type === 'hr') {
              return <div className='dropdown-divider' key={menuItem.key || `${index}`} />;
            }
            return (
              <span
                className='dropdown-item cursor-pointer text-dark'
                onClick={hide}
                key={menuItem.key || `${index}`}
              >
                {menuItem}
              </span>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MeatballMenu;
