import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import TeacherImg from '../../../assets/TeacherWithPhone.svg';

interface BasisRegistrationConfirmProps {
  email: string;
  onResetForm: () => void;
  onResubmit: () => void;
}

const BasisRegistrationConfirm: React.FC<BasisRegistrationConfirmProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <div className='mx-auto pt-3 pb-4 w-75 text-center'>
          {t('A confirmation E-Mail was sent successfully')}.
        </div>
      </Row>
      <Row>
        <h2 className='mx-auto pb-3 px-3 text-dark'>
          {props.email}
        </h2>
      </Row>
      <Row>
        <div className='mx-auto pb-4 w-75 text-center'>
          {t('Please click on the link within to confirm your registration.')}
        </div>
      </Row>
      <Row>
        <TeacherImg className='mx-auto w-75 h-75' />
      </Row>
      <Row className='mt-4'>
        <Col>
          <Col className='d-flex justify-content-between border-top py-4 px-0'>
            <a onClick={props.onResetForm} className='text-primary'>{t('Change E-Mail Address')}</a>
            <a onClick={props.onResubmit} className='text-primary'>{t('Resend Link')}</a>
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default BasisRegistrationConfirm;
