import { Instance } from 'pspdfkit';

export const addDragListener = (instance: Instance, callback: (event: MouseEvent) => void) => {
  const delta = 6;
  let startX: number;
  let startY: number;

  instance.contentDocument.addEventListener('mousedown', function (event) {
    startX = event.pageX;
    startY = event.pageY;
  });

  instance.addEventListener('page.press', function (pagePressEvent) {
    const event = pagePressEvent.nativeEvent as MouseEvent;
    const diffX = Math.abs(event.pageX - startX);
    const diffY = Math.abs(event.pageY - startY);

    if (diffX > delta || diffY > delta) {
      callback(event);
    }
  });
};

export const addTouchHoldListener = (instance: Instance, callback: (event: any) => void) => {
  // for every browser except safari
  instance.contentDocument.addEventListener('contextmenu', (event) => {
    event.preventDefault();
    callback(event);
  });
  // for safari
  instance.contentDocument.addEventListener('selectionchange', (event) => {
    event.preventDefault();
    callback(event);
  });
};
