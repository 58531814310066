import React from 'react';
import classnames from 'classnames';
import './dropdownSelectOption.scss';
import Icon from '../../../Icon';

interface DropdownSelectOptionProps {
  name: string;
  isHighlighted: boolean;
  isSelected: boolean;
  itemProps: Record<string, unknown>;
  onClick: () => void;
  isMultiselect: boolean;
}

const DropdownSelectOption: React.FC<DropdownSelectOptionProps> = (props) => { 
  return (
    <div
      className={
        props.isMultiselect ?
          classnames(`
            d-flex
            mb-1
            cursor-pointer
          `) :
          classnames(`
            d-flex
            justify-content-between
            align-items-center
            mb-1
            cursor-pointer`,
          {
            'color--black': props.isSelected,
            'color--grey-medium': !props.isSelected,
            'bg--grey-light-2': props.isHighlighted
          }
          )
      }
      {...props.itemProps}
      onClick={props.onClick}
      aria-label={props.isHighlighted ? 'highlighted' : ''}
    >
      {
        props.isMultiselect && 
        <input 
          type='checkbox'
          checked={props.isSelected}
          className='filter-checkbox'
          data-testid='dropdown-multi-checkmark'
          onChange={() => {}}
        />
      }
      <span className={props.isSelected ? 'mr-1 highlighted-text' : 'mr-1'}>
        {props.name}
      </span>
      {
        !props.isMultiselect && 
        <span className={classnames({ 'invisible': !props.isSelected })} data-testid='dropdown-checkmark'>
          <Icon icon='check' />
        </span>
      }
    </div>
  );
};

export default DropdownSelectOption;
