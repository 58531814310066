import Icon from '../Icon';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { sharingLinks } from '../../constants';
import { ShareModalItemTypeEnum } from '../../types/sharing';
import { copyToClipboard } from '../../utils';

interface ShareModalProps {
  itemId: string;
  type: ShareModalItemTypeEnum;
  show: boolean;
  onClose: () => void;
}

const generateLink = (id: string, type: ShareModalItemTypeEnum) => {
  switch (type) {
    case ShareModalItemTypeEnum.COLLECTION:
      return `${sharingLinks.curatedCollection}/${id}?shared=1`;
    case ShareModalItemTypeEnum.MINDITEM:
      return `${sharingLinks.mindItem}/${id}?shared=1`;
    case ShareModalItemTypeEnum.PRODUCT:
      return `${sharingLinks.product}/${id}?shared=1`;
  }
};

const ShareModal: React.FC<ShareModalProps> = ({ show, itemId, type, onClose }) => {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);
  const link = generateLink(itemId, type);
  const sharedLinkInput = React.createRef<HTMLInputElement>();

  const handleClose = () => {
    setIsCopied(false);
    onClose();
  };

  const handleClick = () => {
    copyToClipboard(link);
    setIsCopied(true);

    if (sharedLinkInput.current) {
      sharedLinkInput.current.select();
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton={true}>
        <Modal.Title>
          {t('Share')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
              <Icon icon='link' />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            readOnly={true}
            value={link}
            id={'shared-link-input'}
            data-testid='shared-link-input'
            ref={sharedLinkInput as React.RefObject<any>}
            as='input'
          />
          <InputGroup.Append>
            <Button
              variant='outline-secondary'
              onClick={handleClick}
            >
              {isCopied ? `${t('Copied')}!` : t('Copy')}
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Modal.Body >
      <Modal.Footer>
        <Button variant='primary' onClick={handleClose}>
          {t('Ok')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShareModal;
