import classnames from 'classnames';
import { format } from 'date-fns';
import React, { useCallback } from 'react';
import MuBag from '../assets/MuBag.svg';
import { CollectionProperties } from '../types/AppContent';
import Icon from './Icon';
import Card from 'react-bootstrap/Card';
import CollectionCardMeatball from './CollectionCardMeatballMenu';
import { useTranslation } from 'react-i18next';
import SubjectBanners from '../assets/subjects/banners';
import { updateCollection } from '../actions/collections.actions';
import { Image } from 'react-bootstrap';
import { dateFormat } from '../constants';

interface CollectionCardProps {
  active: boolean;
  canPublish: boolean;
  collection: CollectionProperties;
  actions?: {
    openCollection: (id: string) => void;
    deleteCollection: (id: string) => void;
    followCollection: (collection: CollectionProperties) => void;
    unfollowCollection: (collection: CollectionProperties) => void;
    updateCollection: typeof updateCollection;
  };
  userId?: string;
}

const CollectionCard: React.FunctionComponent<CollectionCardProps> = (props) => {
  const { t } = useTranslation();
  const { collection } = props;
  const contentCount = collection.contentsCount;
  const subject = collection.subjects[0];
  const subjects = collection.subjects.join(', ');

  const SubjectBanner = subject && SubjectBanners[subject]
    ? SubjectBanners[subject]
    : SubjectBanners.Didaktik;

  const publish = useCallback(
    (id: string) => props.actions && props.actions.updateCollection(id, { isPublic: true }),
    [1]);
  const unpublish = useCallback(
    (id: string) => props.actions && props.actions.updateCollection(id, { isPublic: false }),
    [1]);

  return (
    <Card className={classnames('collection-card', { 'border-primary': props.active })}>
      {props.active && <div className='overlay bg-primary' />}
      <div className='header'>
        {collection.isPublic
          ? (
            // @ts-ignore
            <SubjectBanner.small className='w-100 rounded-top pb-1' preserveAspectRatio='xMidYMid slice' />
          )
          : (
            <div className='header--private p-3'>
              <div className={`color-bar collection__bg--default collection__bg--${collection.color}`}/>
              <div className='d-flex justify-content-end mt-2'>
                <Icon className='color--grey-light' icon='private' size='lg'/>
              </div>
            </div>
          )
        }
      </div>
      <Card.Body className='px-3 py-1 text-primary d-flex flex-column'>
        <h3 className='text-truncate mb-1'>
          {collection.title}
        </h3>
        <p className='text-truncate'>
          {contentCount} {contentCount === 1 ? t('Document') : t('Documents')}
          {subjects.length ? <span className='px-1'>&bull;</span> : ''}
          {subjects}
        </p>
        <div className='flex-grow-1 d-flex flex-column justify-content-end'>
          {collection.isPublic && (collection.ownerData && collection.ownerData.imageUrl ?
            (
              <Image roundedCircle={true} height='30px' width='30px' src={collection.ownerData.imageUrl} />
            )
            :
            (
              <div>
                <MuBag />
              </div>
            ))
          }
          <div className='d-flex flex-row align-items-end justify-content-between'>
            <Card.Text className='text-light font-size-12 mb-0'>
              {t('Updated')} {format(new Date(collection.changed), dateFormat)}
            </Card.Text>
            {props.actions &&
              <CollectionCardMeatball
                collection={collection}
                openCollection={props.actions.openCollection}
                deleteCollection={props.actions.deleteCollection}
                followCollection={props.actions.followCollection}
                unfollowCollection={props.actions.unfollowCollection}
                canPublish={props.canPublish}
                publishCollection={publish}
                unpublishCollection={unpublish}
                userId={props.userId}
              />
            }
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CollectionCard;
