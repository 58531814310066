import { PublisherListResponse } from '@klab-berlin/api-sdk/lib/types/responses/Text';
import { AsyncAction } from './common.actionTypes';

export enum FETCH_PUBLISHERS {
  START = 'FETCH_PUBLISHERS_START',
  SUCCESS = 'FETCH_PUBLISHERS_SUCCESS',
  ERROR = 'FETCH_PUBLISHERS_ERROR',
}

export type FetchPublishersAction = AsyncAction<
  FETCH_PUBLISHERS.START,
  FETCH_PUBLISHERS.SUCCESS,
  FETCH_PUBLISHERS.ERROR,
  PublisherListResponse
>;
