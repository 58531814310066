import {
  BrowserProtocol,
  createBasenameMiddleware,
  queryMiddleware,
} from 'farce';
import {
  CreateRenderArgs,
  createFarceRouter,
  makeRouteConfig,
  resolver,
  Redirect
} from 'found';
import Route from './Route';
import React from 'react';
import AppPage from '../AppPage';
import AuthenticatedRoute from './AuthenticatedRoute';
import { RouteDescriptor, routes, } from '../constants';
import ChangeEmailConfirm from '../routes/ChangeEmailConfirm';
import CuratedCollectionItemListPage from '../routes/CuratedCollectionItemListPage';
import CuratedCollectionListPage from '../routes/CuratedCollectionListPage';
import DashboardPage from '../routes/DashboardPage';
import DownloadListPage from '../routes/DownloadListPage';
import Error404 from '../routes/Error404';
import MaintenancePage from '../routes/MaintenancePage/MaintenancePage';
import FollowedCollectionItemListPage from '../routes/FollowedCollectionItemListPage';
import FollowedCollectionListPage from '../routes/FollowedCollectionListPage';
import CreateProfileClassesPage from '../routes/CreateProfileClassesPage';
import CreateProfileFinishPage from '../routes/CreateProfileFinishPage';
import CreateProfilePage from '../routes/CreateProfilePage';
import CreateProfilePasswordPage from '../routes/CreateProfilePasswordPage';
import CreateProfileSchoolTypePage from '../routes/CreateProfileSchoolTypePage';
import CreateProfileSubjectsPage from '../routes/CreateProfileSubjectsPage';
import OwnCollectionItemListPage from '../routes/OwnCollectionItemListPage';
import OwnCollectionListPage from '../routes/OwnCollectionListPage';
import PinnedItemListPage from '../routes/PinnedItemListPage';
import SetPasswordPage from '../routes/SetPasswordPage';
import LoginPage from '../routes/login/LoginPage';
import LoginPageExternal from '../routes/login/LoginPageExternal';
import ResetPasswordPage from '../routes/ResetPasswordPage';
import ResetPasswordConfirmedPage from '../routes/ResetPasswordConfirmedPage';
import ProductPage from '../routes/ProductPage';
import ProductAttachmentsPage from '../routes/ProductAttachmentsPage';
import IpErrorPage from '../routes/login/IpRangeErrorPage';
import ChangeEmailPage from '../routes/ChangeEmailPage';
import ReaderPage from '../routes/ReaderPage';
import RegisterBasisPage from '../routes/RegisterBasisPage/RegisterBasisPage';
import SearchPage from '../routes/SearchPage';
import PersonalDetailsPage from '../routes/PersonalDetailsPage';
import WalkInRestrictedRoute from './WalkInRestrictedRoute';
import PublicVideoPlayerPage from '../routes/PublicVideoPlayerPage';
import PublicLernsetPage from '../routes/lernsets/PublicLernsetPage';
import SchoolInfoPage from '../routes/SchoolInfoPage';
import UpgradePackageSelectionPage from '../routes/upgrade/UpgradePackageSelectionPage/UpgradePackageSelectionPage';
import SinglePagePayment from '../routes/SinglePagePayment';
import { PaymentMode } from '../routes/SinglePagePayment/SinglePagePaymentForm/SinglePagePaymentForm';
import DeprecatedRegisterPremiumPage from '../routes/register/DeprecatedRegisterPremiumPage';
import LernsetsAnnotationsPage from '../routes/lernsets/LernsetsAnnotationsPage';
import LernsetsOverviewPage from '../routes/lernsets/LernsetsOverviewPage';
import LernsetsDashboardPage from '../routes/lernsets/LernsetsDashboardPage';

const getRouteConfig = (config: RouteDescriptor) => {
  let path = config.root;
  if (config.param) {
    path = `${path}/:${config.param}`;
  } else if (config.optionalParam) {
    path = `${path}/:${config.optionalParam}?`;
  }
  return {
    title: config.documentTitle,
    path,
  };
};

const getBackwardCompatibilityRedirects = () => 
  <>
    <Redirect from='/angebote/12fuer10'
      to={'/zahlungsdaten?data=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsaWNlbmNlSWQiOjEzMn0'
      +'.IUKeDfyhOud0vwgDmr5sZGTSiNjH7IN6XeuCSxyG5ys&promoCode=schulstart22'} />
    <Redirect from='/angebote/12fuer10-Ref'
      to={'/zahlungsdaten?data=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsaWNlbmNlSWQiOjQxfQ'
      +'.VIzjPXGWBeOAvfKTVUNbOb0apCRon9uV7d4YxTeSMLE&promoCode=schulstart22-ref'} />
  </>;

const RouteContainer = (
  <Route
    path='/'
    Component={AppPage}
    render={({ Component, props }) => {
      // This 'render' property is being used to stop Found-router
      // from continuously un-mounting all of the apps components
      // on each route change (while navigation).
      // Hence, it shouldn't be removed.
      if (Component)
        return <Component {...props} />;
      else
        return null;
    }}
  >
    <Redirect from='/' to={`/${routes.dashboard.root}`} />
    { getBackwardCompatibilityRedirects() }
    <AuthenticatedRoute>
      <WalkInRestrictedRoute>
        <Route path={routes.collections.root}>
          <Route Component={OwnCollectionListPage} title={routes.collections.documentTitle} />
          <Route
            Component={OwnCollectionItemListPage}
            path=':collectionId'
            title={routes.collections.documentTitle}
          />
        </Route>
        <Route {...getRouteConfig(routes.pinned)} Component={PinnedItemListPage} />
        <Route {...getRouteConfig(routes.downloads)} Component={DownloadListPage} />
        <Route
          path={routes.curatedCollections.root}
        >
          <Route Component={CuratedCollectionListPage} title={routes.curatedCollections.root} />
          <Route
            Component={CuratedCollectionItemListPage}
            path=':collectionId'
            title={routes.curatedCollections.documentTitle}
          />
        </Route>
        <Route path={routes.followed.root}>
          <Route Component={FollowedCollectionListPage} title={routes.followed.documentTitle} />
          <Route
            Component={FollowedCollectionItemListPage}
            path=':collectionId'
            title={routes.followed.documentTitle}
          />
        </Route>
        <Route {...getRouteConfig(routes.dashboard)} Component={DashboardPage} />
        <Route {...getRouteConfig(routes.personalDetails)} Component={PersonalDetailsPage} />
        <Route {...getRouteConfig(routes.lernsetsEditor)} Component={LernsetsAnnotationsPage} />
        <Route {...getRouteConfig(routes.lernsetsOverview)} Component={LernsetsOverviewPage} />
        <Route {...getRouteConfig(routes.learnsetsDashboard)} Component={LernsetsDashboardPage} />
      </WalkInRestrictedRoute>
      <Route {...getRouteConfig(routes.search)} Component={SearchPage} />
      <Route
        {...getRouteConfig(routes.productPage)}
        Component={ProductPage}
      />
      <Route
        title={routes.productAttachmentsPage.documentTitle}
        path={`${routes.productAttachmentsPage.root}/:productId/:attachmentsPath*`}
        Component={ProductAttachmentsPage}
      />
      <Route
        path={`${routes.reader.root}/:documentId`}
        Component={ReaderPage}
      />
      <Route
        {...getRouteConfig(routes.upgradeToSchoolLicence)}
        Component={SchoolInfoPage}
      />
    </AuthenticatedRoute>
    <Route {...getRouteConfig(routes.maintenance)} Component={MaintenancePage} />
    <Route {...getRouteConfig(routes.login)} Component={LoginPage} />
    <Route {...getRouteConfig(routes.loginExternal)} Component={LoginPageExternal} />
    <Route {...getRouteConfig(routes.resetPassword)} Component={ResetPasswordPage} />
    <Route {...getRouteConfig(routes.resetPasswordConfirmed)} Component={ResetPasswordConfirmedPage} />
    <Route {...getRouteConfig(routes.setPassword)} Component={SetPasswordPage} defer={true} />
    <Route {...getRouteConfig(routes.setNewEmail)} Component={ChangeEmailPage} defer={true} />
    <Route {...getRouteConfig(routes.changeEmailConfirm)} Component={ChangeEmailConfirm} defer={true} />
    <Route {...getRouteConfig(routes.ipRangeError)} Component={IpErrorPage} />
    <Route path={routes.createProfile.root}>
      <Route Component={CreateProfilePage} title={routes.createProfile.documentTitle} />
      <Route {...getRouteConfig(routes.createProfilePassword)} Component={CreateProfilePasswordPage} />
      <AuthenticatedRoute>
        <Route {...getRouteConfig(routes.createProfileSubjects)} Component={CreateProfileSubjectsPage} />
        <Route {...getRouteConfig(routes.createProfileClasses)} Component={CreateProfileClassesPage} />
        <Route
          {...getRouteConfig(routes.createProfileSchoolType)}
          Component={CreateProfileSchoolTypePage}
        />
        <Route {...getRouteConfig(routes.createProfileFinish)} Component={CreateProfileFinishPage} />
      </AuthenticatedRoute>
    </Route>
    <AuthenticatedRoute>
      <Route {...getRouteConfig(routes.upgradePackageSelection)}>
        <Route title={routes.upgradePackageSelection.documentTitle} Component={UpgradePackageSelectionPage} />
        <Redirect from={routes.deprecatedUpgradeRef.root} to={`/${routes.upgradePackageSelection.root}`} />
      </Route>
      <Redirect from={`${routes.deprecatedUpgradeOfferCode.root}*`} to={`/${routes.upgradePackageSelection.root}`} />
      <Route {...getRouteConfig(routes.upgradePayment)} 
        Component={
          (props) => <SinglePagePayment {...props} paymentMode={PaymentMode.PremiumUpgrade} isPublicPage={false} />
        } 
      />
      <Redirect from={routes.deprecatedUpgradeConfirm.root} to={`/${routes.upgradePackageSelection.root}`} />
    </AuthenticatedRoute>
    <Redirect 
      from={routes.deprecatedPremiumTrialRegistration.root}
      to={(url) => {
        const { location: { query } } = url;
        return {
          pathname: `/${routes.register.root}/${routes.registerPremiumCreateAccount.root}`,
          query,
        };
      }}
    />
    <Route {...getRouteConfig(routes.register)}>
      <Route Component={RegisterBasisPage} />
      <Route
        {...getRouteConfig(routes.registerPremiumCreateAccount)}
        Component={(props) => 
          <SinglePagePayment {...props} paymentMode={PaymentMode.PremiumRegistration} isPublicPage />
        }
        defer={true}
      />
      <Route
        {...getRouteConfig(routes.deprecatedRegisterPremiumPackageSelection)}
        Component={DeprecatedRegisterPremiumPage}
      />
      <Route
        {...getRouteConfig(routes.deprecatedRegisterPremiumPayment)}
        Component={DeprecatedRegisterPremiumPage}
      />
      <Route
        {...getRouteConfig(routes.deprecatedRegisterPremiumConfirm)}
        Component={DeprecatedRegisterPremiumPage}
      />
    </Route>
    <Route
      path={`${routes.publicReader.root}/:accessToken`}
      Component={PublicVideoPlayerPage}
    />
    <Route
      {...getRouteConfig(routes.lernsetsPublicView)}
      Component={PublicLernsetPage}
    />
  </Route>
);

const routeConfig = makeRouteConfig(
  RouteContainer,
);

const renderError: CreateRenderArgs['renderError'] = ({ error }) => {
  return (
    <AppPage>
      {error.status === 404 ? <Error404 /> : 'Got error, please refresh.'}
    </AppPage>
  );
};

const basenameMiddleware = createBasenameMiddleware({ basename: process.env.BASENAME });

const FarceRouter = createFarceRouter({
  historyMiddlewares: [queryMiddleware, basenameMiddleware],
  historyProtocol: new BrowserProtocol(),
  renderError,
  routeConfig,
});

export default () => <FarceRouter resolver={resolver} />;
