exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".card.card--white-edge{padding:0 24px}.card.card--white-edge--active .card-body{min-height:170px}.card.card--white-edge .card-header__accordion-toggle{height:80px;border-bottom:0;padding:0;position:relative}@media only screen and (max-width: 767px){.card.card--white-edge .card-header__accordion-toggle{height:58px;margin:0 40px}}.card.card--white-edge .card-header__accordion-toggle .card-header__icon--left{position:absolute;left:16px}@media only screen and (max-width: 767px){.card.card--white-edge .card-header__accordion-toggle .card-header__icon--left{left:8px}}.card.card--white-edge .card-header__accordion-toggle .card-header__icon--right{position:absolute;right:0}.card.card--white-edge .card-header__accordion-toggle .card-header__icon--right svg{width:auto}.card.card--white-edge .card-header__accordion-toggle .card-header__icon--right .svg--sepa{height:44px}@media only screen and (max-width: 767px){.card.card--white-edge .card-header__accordion-toggle .card-header__icon--right .svg--sepa{height:27px}}.card.card--white-edge .card-header__accordion-toggle .card-header__icon--right .svg--paypal{height:30px}@media only screen and (max-width: 767px){.card.card--white-edge .card-header__accordion-toggle .card-header__icon--right .svg--paypal{height:21px}}.card.card--white-edge .card-header__accordion-toggle .card-header__icon--right .svg--creditcard{height:26px}@media only screen and (max-width: 767px){.card.card--white-edge .card-header__accordion-toggle .card-header__icon--right .svg--creditcard{height:13px}}@media only screen and (max-width: 767px){.card.card--white-edge .card-body{border-top:0;margin:0 40px;padding-top:0}}", ""]);
// Exports
exports.locals = {
	"collectionGridGap": "15px",
	"collectionGridItemHeight": "211px",
	"collectionGridItemWidth": "225px",
	"collectionListHeight": "84px",
	"greyLightest": "#f7f9fa",
	"greyLight2": "#d8d8d8",
	"neuVeryLightBlue": "#e9ebec",
	"muBlue": "#4298b5",
	"gridBreakPoints_-xs": "0",
	"gridBreakPoints_-sm": "576px",
	"gridBreakPoints_-md": "768px",
	"gridBreakPoints_-lg": "992px",
	"gridBreakPoints_-xl": "1440px",
	"medium": "768px",
	"large": "1024px",
	"xlarge": "1440px"
};