import Icon, { IconName } from './Icon';
import React from 'react';
import { Trans } from 'react-i18next';

interface MeatballItemProps {
  onClick?: React.MouseEventHandler;
  iconClass?: string;
  icon: IconName;
  label: string;
}

const MeatballItem: React.FC<MeatballItemProps> = ({ icon, iconClass, label, onClick }) => {
  const clickHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onClick) {
      onClick(e);
    }
  };
  return (
    <div onClick={clickHandler}>
      <div className='py-1 p1-2 pr-2 d-inline-block'>
        <Icon
          icon={icon}
          className={iconClass}
        />
      </div>
      <Trans i18nKey={label} />
    </div>
  );
};

MeatballItem.defaultProps = {
  iconClass: 'color--mu-blue',
};

export default MeatballItem;
