import React from 'react';
import { Dialog, DialogTitle, DialogContent, Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { palette, spaces } from '../../../assets/styles/themes/tokens';
import CopyLinkField from './CopyLinkField';
import QrCode from './QrCode';

interface OwnProps {
  title: string;
  onClose: () => void;
  open: boolean;
  linkUrl: string;
  qrImageName: string;
  onQrDownload: () => void;
  onLinkCopy: () => void;
  subTitle?: string;
}

const ShareDialog: React.FC<OwnProps> = (props) => {
  const { title, subTitle, linkUrl, qrImageName,  onClose, open, onQrDownload, onLinkCopy } = props;
  
  return (
    <Dialog
      data-testid='share-dialog'
      onClose={onClose} 
      open={open} 
      fullWidth={true}
    >
      <DialogTitle
        component='div' 
        sx={{
          paddingTop: spaces.lg,
          paddingBottom: spaces.lg,
          display: 'flex',
          flexDirection: 'column',
          gap: spaces.xs,
        }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <Typography variant='h5' color={palette.text.primary}>{title}</Typography>
          <Box>
            <IconButton
              color='default'
              size='small'
              onClick={onClose}
              data-testid='close-button'
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        {subTitle && <Typography variant='subtitle1' color={palette.text.secondary}>{subTitle}</Typography>}
      </DialogTitle>
      <DialogContent sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: spaces.lg,      
      }}>
        <CopyLinkField link={linkUrl} onCopy={onLinkCopy}/>
        <QrCode link={linkUrl} imageName={qrImageName} onDownload={onQrDownload}/>
      </DialogContent>
    </Dialog>
  );
};

export default ShareDialog;
