import immutable from 'object-path-immutable';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import {
  FETCH_USER_PRODUCT_RECOMMENDATIONS,
  FetchUserProductRecommendationsAction
} from '../actions/recommendation.actionTypes';
import { LOGOUT, LogoutAction } from '../actions/auth.actionTypes';

type RecommendationActionTypes =
  | FetchUserProductRecommendationsAction
  | LogoutAction;

export type RecommendationContentArray = Array<string>;

export interface RecommendationInitialStateType {
  user: RecommendationContentArray;
  error: null | string;
  status: AsyncActionStatus;
}

const initialState: RecommendationInitialStateType = {
  user: [],
  error: null,
  status: AsyncActionStatus.LOADING,
};

const Recommendation = (
  state = initialState,
  action: RecommendationActionTypes
): RecommendationInitialStateType => {
  switch (action.type) {
    case LOGOUT.SUCCESS:
      return initialState;

    case FETCH_USER_PRODUCT_RECOMMENDATIONS.START:
      return immutable(state)
        .set('status', AsyncActionStatus.LOADING)
        .value();

    case FETCH_USER_PRODUCT_RECOMMENDATIONS.ERROR:
      return immutable(state)
        .set('status', AsyncActionStatus.ERROR)
        .set('error', action.error.message)
        .value();

    case FETCH_USER_PRODUCT_RECOMMENDATIONS.SUCCESS: {
      const mappedContentsResponse = action.result.map(product => product.id);
      return immutable(state)
        .set('status', AsyncActionStatus.READY)
        .set('user', mappedContentsResponse)
        .value();
    }
  }

  return state;
};

export default Recommendation;
