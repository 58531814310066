import React from 'react';
import { useTranslation } from 'react-i18next';
import Error404SVG from '../assets/error-404.svg';
import MasterLayout from '../components/AppLayout/MasterLayout';
// import CollectionsAppLayout from '../components/CollectionsAppLayout/CollectionsAppLayout';

const Error404: React.FC = () => {
  const { t } = useTranslation();
  return (
    <MasterLayout>
      <div className='d-flex flex-column justify-content-center align-content-center mt-4 error-page'>
        <Error404SVG />
        <p>{t('Oops, we didn\'t find your page')}</p>
      </div>
    </MasterLayout>
  );
};

export default Error404;
