import React, { useCallback, useState } from 'react';
import { ItemVariation } from '@klab-berlin/api-sdk/lib/types/responses/Collection';
import { ManageItemInCollectionModal } from '../components/modals';
import { MindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import { Product } from '@klab-berlin/api-sdk/lib/types/responses/Product';

type ShowFunc = (
  itemId: string, 
  itemType: ItemVariation, 
  itemData?: Partial<MindItem> | Partial<Product> | undefined,
  closeCallback?: () => void,
) => void;
const noop = () => undefined;

interface Context {
  isOpen: boolean;
  show: ShowFunc;
}

const defaultContext: Context = {
  isOpen: false,
  show: noop as ShowFunc,
};

const ManageItemInCollectionModalContext = React.createContext(defaultContext);

const ManageItemInCollectionModalProvider: React.FC = (props) => {
  const [itemId, setItemId] = useState('');
  const [itemData, setItemData] = useState({});
  const [itemType, setItemType] = useState<ItemVariation>('mindItem');
  const [isOpen, setIsOpen] = useState(false);
  const [closeCallback, setCloseCallback] = useState<(() => void) | undefined>(undefined);

  const setModalConfig = (
    open: boolean, 
    itemId?: string, 
    itemType?: ItemVariation,
    itemData?: Partial<MindItem> | Partial<Product>,
    closeCallbackFunc?: () => void,
  ) => {
    if (itemId) setItemId(itemId);
    if (itemType) setItemType(itemType);
    if (itemData) setItemData(itemData);
    if (closeCallbackFunc) {
      setCloseCallback(() => closeCallbackFunc);
    }
    
    setIsOpen(open);
  };

  const show = useCallback((
    itemId: string, 
    itemType: ItemVariation, 
    itemData: Partial<MindItem> | Partial<Product> | undefined,
    closeCallback?: () => void,
  ) => {
    setModalConfig(true, itemId, itemType, itemData, closeCallback);
  }, []);

  const onClose = () => {
    if (closeCallback) closeCallback();
    setModalConfig(false, '', 'mindItem', undefined, undefined);
  };

  return (
    <ManageItemInCollectionModalContext.Provider value={{
      show,
      isOpen,
    }}>
      <ManageItemInCollectionModal
        show={isOpen}
        onClose={onClose}
        itemId={itemId}
        itemData={itemData}
        itemType={itemType}
      />
      {props.children}
    </ManageItemInCollectionModalContext.Provider>
  );
};

export { ManageItemInCollectionModalContext, ManageItemInCollectionModalProvider };
