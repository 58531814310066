import { SchoolType } from '@klab-berlin/api-sdk/lib/types/responses/Text';
import immutable from 'object-path-immutable';
import { StoreItem } from '.';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import { FetchSchoolTypesAction, FETCH_SCHOOL_TYPES } from '../actions/schoolTypes.actionsTypes';

type SchoolTypeActionTypes =
  | FetchSchoolTypesAction;

export type SchoolTypeState = StoreItem<SchoolType[]>;

const initialState: SchoolTypeState = {
  data: [],
  status: AsyncActionStatus.INITIAL,
};

const schoolTypes = (state = initialState, action: SchoolTypeActionTypes) => {
  switch (action.type) {
    case FETCH_SCHOOL_TYPES.START:
      return immutable(state)
        .set('status', AsyncActionStatus.LOADING)
        .value();

    case FETCH_SCHOOL_TYPES.SUCCESS:
      return immutable(state)
        .set('data',action.result)
        .set('status', AsyncActionStatus.READY)
        .value();

    case FETCH_SCHOOL_TYPES.ERROR:
      return immutable(state)
        .set('error', action.error.message)
        .set('status', AsyncActionStatus.ERROR)
        .value();

    default:
      return state;
  }
};

export default schoolTypes;
