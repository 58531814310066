import React from 'react';
import Dialog, { DialogProps } from '../Dialog/Dialog';

export interface OnboardingDialogProps extends Omit<DialogProps, 'open'> {
  id: string;
  primaryCta: DialogProps['primaryCta'] & { dismissOnAction?: boolean };
  secondaryCta?: DialogProps['secondaryCta'] & { dismissOnAction?: boolean };
  imageStyles?: React.CSSProperties;
}

const OnboardingDialog: React.FC<OnboardingDialogProps> = (props) => {
  const localStorageKey = `onboarding-${props.id}`;
  const localStorageDateKey = `onboarding-${props.id}-date`;

  const [shownToUser, setShownToUser] = React.useState(
    localStorage.getItem(localStorageKey) === 'true'
  );

  const dismiss = () => {
    localStorage.setItem(localStorageKey, 'true');
    localStorage.setItem(localStorageDateKey, Date.now().toString());
    setShownToUser(true);
    if (props.onClose) {
      props.onClose();
    }
  };

  return <Dialog
    open={!shownToUser}
    onClose={dismiss}
    primaryCta={{
      ...props.primaryCta,
      onClick: () => {
        props.primaryCta.onClick();
        props.primaryCta.dismissOnAction && dismiss();
      }
    }}
    secondaryCta={props.secondaryCta && {
      ...props.secondaryCta,
      onClick: () => {
        props.secondaryCta?.onClick();
        props.secondaryCta?.dismissOnAction && dismiss();
      }
    }}
    title={props.title}
    image={props.image}
    imageStyles={props.imageStyles}
    htmlBody={props.htmlBody}
    data-testid='onboarding-dialog'
    position={props.position}
  />;
};

export default OnboardingDialog;
