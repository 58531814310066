import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { cancelSubscription as cancelSubscriptionAction } from '../../../actions/user.actions';
import { emailAddresses } from '../../../constants';
import useAction from '../../../utils/useAction';
import ScreenStepContainer, { ScreenStepProps } from '../ScreenModal/ScreenStepContainer';

const TrialCancellationStepErrorRetry: React.FC<ScreenStepProps> = (props) => {
  const { t } = useTranslation();
  const cancelSubscription = useAction(cancelSubscriptionAction);
  const retryButton = {
    action: () => cancelSubscription()
      .then(() => props.setScreen('finishCancelling'))
      .catch(() => { return; }),
    title: t('Try again'),
  };
  return (
    <ScreenStepContainer
      trackingId='errorRetry'
      title={t('Oops!')}
      primaryButton={retryButton}
      onClose={props.onClose}
    >
      <Trans i18nKey='Oh no! Something went wrong with your Cancellation!'>
        <p>
          Something went wrong with your cancellation!
          Please try again or contact our <a href={`mailto:${emailAddresses.support}`}>Support</a>.
        </p>
      </Trans>
    </ScreenStepContainer>
  );
};

export default TrialCancellationStepErrorRetry;
