import React from 'react';
import { PaymentPeriod } from '@klab-berlin/api-sdk/lib/types/common';
import { DiscountInformation, LicenceModel } from '@klab-berlin/api-sdk/lib/types/responses/Billing';
import { useTranslation } from 'react-i18next';
import './amountDueToday.scss';
import { currencyFormat } from '../../../../utils/format';

interface OwnProps {
    licenceModel: LicenceModel;
    paymentRhythm: PaymentPeriod;
    promoCodeDiscountInfo: DiscountInformation | undefined;
}

const AmountDueToday: React.FC<OwnProps> = (props) => {
  const { licenceModel, paymentRhythm, promoCodeDiscountInfo } = props;
  const { t } = useTranslation();

  const getAmountDueTodayFromLicence = () => {
    const priceForPaymentRythms: {[key in PaymentPeriod]: string} = {
      monthly: currencyFormat(licenceModel.price),
      quarterly: currencyFormat(licenceModel.price * 3),
      annually: currencyFormat(licenceModel.price * 12),
      fullAmount: currencyFormat(licenceModel.totalPrice)
    };

    return priceForPaymentRythms[paymentRhythm];
  };

  const getAmountDueTodayFromDiscountInfo = (promoCodeDiscountInfo: DiscountInformation) => {
    return currencyFormat(promoCodeDiscountInfo.firstPayment[paymentRhythm]);
  };

  let amountDueToday = currencyFormat(0);
  if (!licenceModel.isTrial) {
    amountDueToday = promoCodeDiscountInfo && promoCodeDiscountInfo.valid
      ? getAmountDueTodayFromDiscountInfo(promoCodeDiscountInfo)
      : getAmountDueTodayFromLicence();
  }

  return (
    <div className='amount-due-today__payment-info'>
      <div className='amount-due-today__payment-info__text'>
        {t('Amount Due Today')}
      </div>
      <div className='amount-due-today__payment-info__price' data-testid='amount-due-today__price'>
        {amountDueToday}
      </div>
    </div>
  );
};

export default AmountDueToday;
