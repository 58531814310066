import React from 'react';
import { useTranslation } from 'react-i18next';
import MuBag from '../../assets/MuBag.svg';
import MasterLayout from '../../components/AppLayout/MasterLayout';
import './maintenancePage.scss';

const MaintenancePage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <MasterLayout>
      <div className='maintenance-page'>
        <MuBag className='maintenance-page__mu-bag' />
        <h1 className='maintenance-page__header'>{t('This page is under construction 🚧')}</h1>
        <p>{t('We apologize for any inconveniences, and will be back as soon as possible.')}<br/>
          {t('Please try again later.')}</p>
      </div>
    </MasterLayout>
  );
};

export default MaintenancePage;
