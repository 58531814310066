import React from 'react';
import classnames from 'classnames';
import muIconMapper from './MuIcon/mapper';
import { IconName, IconProps } from './IconTypes';
import MuIcon from './MuIcon';

const Icon: React.FC<IconProps> = (props) => {
  const iconName: IconName = props.icon;
  const icon = muIconMapper.iconMapper[iconName];
  const sizeClass = muIconMapper.sizeMapper[props.size || 'm'];

  return <MuIcon
    className={classnames(`${sizeClass}`, props.className)}
    testId={props.testId}
    icon={icon}
    label={props.label}
  />;
};

export default Icon;
