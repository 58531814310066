import ApiError from '@klab-berlin/api-sdk/lib/utils/ApiError';
import api from '../clients/api';
import cookies from '../clients/cookies';
import store from '../store';
import { REFRESH_TOKEN } from '../actions/auth.actionTypes';

let refreshTokenPromise: Promise<void> | null = null;

async function refreshToken() {
  await api.auth.refresh(cookies.getRefreshToken())
    .then((token) =>{
      cookies.setAuthToken(token);
    })
    .catch((e) => {
      cookies.deleteAuthToken();
      store.dispatch({ type: REFRESH_TOKEN.EXPIRED });
      throw e;
    })
    .finally(() => {
      refreshTokenPromise = null;
    });
}

async function refreshTokenAndWait() {
  if (!refreshTokenPromise) {
    refreshTokenPromise = refreshToken();
  }

  await refreshTokenPromise;
}

export function handleUnauthorized<T>(action: () => Promise<T>): Promise<T> {
  return Promise.resolve()
    .then(() => refreshTokenPromise || Promise.resolve())
    .then(action)
    .catch(async (e: ApiError) => {
      if (e.statusCode !== 401) {
        throw e;
      }

      if (!cookies.getRefreshToken()) {
        cookies.deleteAuthToken();
        store.dispatch({ type: REFRESH_TOKEN.EXPIRED });
        throw e;
      }

      await refreshTokenAndWait();

      return await action();
    });
}
