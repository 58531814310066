import React, { ReactElement } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Div100vh from 'react-div-100vh';

interface GradientModalAppLayoutProps {
  className?: string;
  footer?: ReactElement;
}

const GradientModalAppLayout: React.FC<GradientModalAppLayoutProps> = (props) => (
  <Div100vh className='gradient-modal__layout d-flex flex-column'>
    <Card className={`gradient-modal__layout__content-box ${props.className ? props.className : ''}`}>
      {props.children}
    </Card>
    {props.footer &&
      <div className='d-none d-md-block'>
        <Row>
          <Col className='login-footer d-flex justify-content-center color--white w-100 mt-4'>
            {props.footer}
          </Col>
        </Row>
      </div>
    }
  </Div100vh>
);

export default GradientModalAppLayout;
