import { SortByOption } from '@klab-berlin/api-sdk/lib/types/requests/Common';
import { ContentType } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';

export interface SearchFilters {
  boost?: string;
  term?: string;
  publisher?: string[];
  subject?: string[];
  subTopic?: string[];
  minClassYear?: number;
  maxClassYear?: number;
  workload?: string[];
  teachingFormat?: string[];
  learningFormat?: string[];
  contentType?: ContentType;
  schoolType?: string[];
  sortBy?: SortByOption;
}

export interface Pagination {
  total: number;
  limit: number;
  offset: number;
}

export interface BasicFilterProps<T> {
  updateFilter: (values: T) => void;
  clearFilter: () => void;
  currentValue?: T;
  onToggle?: () => void;
  onItemClick?: (item: string) => void;
}

export enum SearchResultsTab {
  MINDITEMS = 'arbeitsblaetter',
  PRODUCTS = 'ebooks',
  VIDEOS = 'videos',
  ALL = 'all'
}

export interface Subject {
  id: string;
  text: string;
}
