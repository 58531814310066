import React from 'react';
import Button from 'react-bootstrap/Button';
import CreateProfileProgressBar from './CreateProfileProgressBar';

interface CreateProfileFrameProps {
  progressBar?: {
    currentStep: number;
    numberOfSteps: number;
  };
  next?: {
    action?: () => void;
    disabled?: boolean;
    text: string;
  };
  back?: {
    action?: () => void;
    disabled?: boolean;
    text: string;
  };
}

const CreateProfileFrame: React.FC<CreateProfileFrameProps> = ({ children, next, back, progressBar }) => {
  const submit = (e: React.FormEvent) => {
    e.preventDefault();
    next && next.action && next.action();
  };

  return (
    <form
      className='h-100 d-flex flex-column justify-content-between profile__frame'
      onSubmit={submit}
    >
      {progressBar && (
        <CreateProfileProgressBar currentStep={progressBar.currentStep} numberOfSteps={progressBar.numberOfSteps}/>
      )}
      <div className='content w-100 h-100 p-4'>
        {children}
      </div>
      <div className='buttons p-3 border-top'>
        {
          back &&
          <Button
            variant='secondary'
            className='rounded-pill float-left px-5'
            size='lg'
            onClick={back.action}
            disabled={back.disabled}
          >
            {back.text}
          </Button>
        }
        {
          next &&
          <Button
            variant='success'
            type='submit'
            className='rounded-pill float-right px-5'
            size='lg'
            onClick={submit}
            disabled={next.disabled}
            data-testid='create-profile-frame__next-button'
          >
            {next.text}
          </Button>
        }
      </div>
    </form>
  );
};

export default CreateProfileFrame;
