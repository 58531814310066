import { Link } from 'found';
import React from 'react';
import services from '../../services';
import { EventInputData, TrackingEvent } from '../../types/tracking';
import Icon, { IconName } from '../Icon';
import './actionLink.scss';

interface Props {
  icon: IconName;
  to: string;
  labelledBy?: string;
  disabled?: boolean;
  onClickEvent?: TrackingEvent;
  eventPayload?: EventInputData;
}

const ActionLink: React.FC<Props> = (props) => {
  const handleOnClickTracking = () => {
    if (props.onClickEvent) {
      services.track.eventTrack(props.onClickEvent, props.eventPayload);
    }
  };

  if (props.disabled) {
    return (
      <button
        className='action-link'
        disabled
        aria-labelledby={props.labelledBy}
      >
        <Icon
          className='action-link__icon action-link__icon--disabled'
          icon={props.icon}
          size='lg'
        />
      </button>
    );
  }

  return (
    <Link
      className='action-link'
      aria-labelledby={props.labelledBy}
      to={props.to}
      onClick={handleOnClickTracking}
    >
      <Icon
        className='action-link__icon'
        icon={props.icon}
        size='lg'
      />
    </Link>
  );
};

export default ActionLink;
