import api from '../clients/api';
import { notifyBugsnagNetwork } from '../utils/bugsnagClient';

const service = {
  resendConfirmationEmail,
  isFakeEmail,
};

function resendConfirmationEmail(email: string) {
  notifyBugsnagNetwork('email', 'resendConfirmationEmail', { email });
  return api.email.resendConfirmationEmail(email);
}

function isFakeEmail(email: string) {
  notifyBugsnagNetwork('email', 'isFakeEmail', { email });
  return api.email.isFakeEmail(email);
}

export default service;
