import { noop } from 'lodash';
import React, { createContext, useContext, useState } from 'react';
import cookies from '../../clients/cookies';
import { ItemViewMode } from '../../types/AppContent';
import useMedia from '../../utils/useMedia';

interface ViewModeContext {
  viewMode: ItemViewMode;
  setViewMode: (viewMode: ItemViewMode) => void;
}

const viewModeContext = createContext<ViewModeContext>({
  viewMode: 'grid',
  setViewMode: noop
});

const ViewModeContextProvider: React.FC = (props) => {
  const { isSmallViewport, isMediumViewport } = useMedia();
  const initialViewMode = cookies.getViewMode();

  const [viewMode, setViewMode] = useState(initialViewMode);

  return (
    <viewModeContext.Provider
      value={{
        viewMode: (isSmallViewport || isMediumViewport) ? 'grid' : viewMode,
        setViewMode: (newViewMode) => {
          cookies.setViewMode(newViewMode);
          setViewMode(newViewMode);
        }
      }}
    >
      {props.children}
    </viewModeContext.Provider>
  );
};

export const useViewMode = () => useContext(viewModeContext);

export default ViewModeContextProvider;
