import { useContext, createContext } from 'react';
import { MindItem } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import { noop } from 'lodash';

interface ReaderContext {
  mindItemId: string;
  isReadOnly: boolean;
  nextSibling: MindItem | undefined;
  isPinned: boolean;
  isInCollection: boolean;
  isTextBased: boolean;
  onPageLoad: () => void;
  pagesLoaded: boolean;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  initialPage: number;
  hasAttachments: boolean;
}

export const ReaderContext = createContext<ReaderContext>({
  mindItemId: '',
  isReadOnly: false,
  nextSibling: undefined,
  currentPage: 0,
  setCurrentPage: noop,
  isPinned: false,
  isInCollection: false,
  isTextBased: false,
  onPageLoad: noop,
  pagesLoaded: false,
  initialPage: 1,
  hasAttachments: false,
});

export const useReaderContext = () => useContext(ReaderContext);
