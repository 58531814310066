import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AsyncActionStatus } from '../../../actions/common.actionTypes';
import ViewSwitch from '../../ViewSwitch';
import { selectProductMindItemsLoadedStatus, selectProductWithMindItems } from '../../../reducers/products.selectors';
import { CurrentProductContext } from '../CurrentProductContext';
import { useViewMode } from '../../ViewSwitch/ViewModeContext';
import ProductMindItemsList from './ProductMindItemsList';
import { ItemViewMode } from '../../../types/AppContent';
import { SkeletonMindItemCard } from '../../cards/MindItemCard';
import SkeletonMindItemListItem from '../../cards/MindItemListItem/SkeletonMindItemListItem';
import _ from 'lodash';
import './productContents.scss';
import { useNewDesign } from '../../../state/DesignPreferenceContextProvider';

export const ProductContents = () => {
  const { productId } = useContext(CurrentProductContext);
  const product = useSelector(state => selectProductWithMindItems(state, productId));
  const mindItemsStatus = useSelector(state => selectProductMindItemsLoadedStatus(state, productId));

  const { t } = useTranslation();
  const { viewMode } = useViewMode();

  const mindItems = product?.mindItems || [];

  const mindItemCount = mindItems.length;

  const sectionText = mindItemCount === 1 ? t('Section') : t('Sections');

  const areMindItemsLoaded = mindItemsStatus && mindItemsStatus.every((status) => status === AsyncActionStatus.READY);

  const { prefersNewDesign } = useNewDesign();

  const getLoadingSkeletons = (viewMode: ItemViewMode) => (
    <>
      {viewMode === 'grid'
        ? _.times(6, i => <SkeletonMindItemCard key={i} />)
        : _.times(6, i => <SkeletonMindItemListItem key={i} />)}
    </>
  );

  return (
    <div className='product-content'>
      <div className='d-flex flex-row justify-content-between align-items-center color--grey-medium mb-3'>
        <div>
          {mindItemCount} {sectionText}
        </div>
        {prefersNewDesign ?
          <></> :
          <div className='product-content__view-switch'>
            <ViewSwitch listSize={mindItemCount} />
          </div>
        }
      </div>
      <div className={
        viewMode === 'grid'
          ? 'product-content__container__mind-items-grid'
          : 'product-content__container__mind-items-list'
      }>
        {
          areMindItemsLoaded &&
          <ProductMindItemsList
            mindItems={mindItems}
            viewMode={viewMode}
          />
        }
        {!areMindItemsLoaded && getLoadingSkeletons(viewMode)}
      </div>
    </div>
  );
};
