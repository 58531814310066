exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".reader-information{width:320px;padding-top:16px}.reader-information--mobile{width:100%;padding-top:0}.reader-information--mobile .reader-information__header-bar{background-color:#f7f9fa;border:1px solid #e9ebec;height:64px;padding:0 16px;text-transform:none}.reader-information--mobile .reader-information__body{padding:0 32px}.reader-information--mobile .reader-information__header{font-size:20px;line-height:32px;font-weight:400}.reader-information__publisher-logo{height:88px}.reader-information__header-bar{display:flex;justify-content:space-between;align-items:center;flex-direction:row;flex-wrap:nowrap;text-transform:uppercase;padding-left:24px;padding-right:16px;margin-bottom:16px}.reader-information__header{font-size:12px;line-height:16px;font-weight:700;display:flex;align-items:center}.reader-information__header-text{padding-left:16px}.reader-information__body{padding-bottom:40px;padding-left:24px;padding-right:16px}.reader-information__title{font-size:16px;line-height:24px;font-weight:bold;color:#44555d}.comma-list{display:inline;list-style:none;padding:0}.comma-list .comma-list-item::after{content:\", \"}.comma-list .comma-list-item:last-child::after{content:\"\"}", ""]);
// Exports
exports.locals = {
	"medium": "768px",
	"large": "1024px",
	"xlarge": "1440px",
	"collectionGridGap": "15px",
	"collectionGridItemHeight": "211px",
	"collectionGridItemWidth": "225px",
	"collectionListHeight": "84px",
	"greyLightest": "#f7f9fa",
	"greyLight2": "#d8d8d8",
	"neuVeryLightBlue": "#e9ebec",
	"muBlue": "#4298b5",
	"gridBreakPoints_-xs": "0",
	"gridBreakPoints_-sm": "576px",
	"gridBreakPoints_-md": "768px",
	"gridBreakPoints_-lg": "992px",
	"gridBreakPoints_-xl": "1440px"
};