import { createTheme } from '@mui/material/styles';
import { borderRadius, boxShadows, getFontProps, palette, spaces, tooltipArrow, typography } from './tokens';
import { colors } from './variables';

// Material UI theme documentation: https://mui.com/material-ui/customization/default-theme/
// Note that some properties do not work in theme component, but they do in Material-UI components.
// For example, the `paddingY` property is an invalid theme property, but it works in the `MuiButton` component.

const mainTheme = createTheme({
  palette,
  typography,
  breakpoints: {
    values: {
      // these are the default values from the MUI docs
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      // these are the custom values. we add one to each to solve the
      // exclusivity/inclusivity problem of breakpoint functions
      mobile: 743 + 1,
      tablet: 1023 + 1,
      desktop: 1920 + 1,
    }
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            borderRadius: borderRadius.md,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: boxShadows.elevation4,
          ':hover': {
            boxShadow: boxShadows.elevation8,
          }
        },
        outlinedPrimary: {
          ':hover': {
            borderColor: palette.primary.outlinedBorder,
          }
        },
        textPrimary: {
          color: palette.text.primary,
        },
        sizeSmall: {
          borderRadius: borderRadius.xs,
          ...getFontProps('Poppins, sans-serif', 500, '0.75rem'),
          lineHeight: '1.375rem',
          letterSpacing: '0.46px',
          paddingLeft: spaces.xs,
          paddingRight: spaces.xs,
          paddingTop: spaces.xxs,
          paddingBottom: spaces.xxs,
        },
        sizeMedium: {
          borderRadius: borderRadius.xs,
        },
        sizeLarge: {
          borderRadius: borderRadius.sm,
          fontSize: '1rem',
          fontWeight: 500,
          lineHeight: '1.625rem',
          letterSpacing: '0.46px',
          padding: `${spaces.xs} ${spaces.lg}`
        },
        containedInherit: {
          color: palette.text.primary,
          background: palette.secondary.contrastText,
          ':hover': {
            background: colors.neutral50,
          }
        },
        outlinedInherit: {
          borderColor: colors.neutral300,
          color: palette.text.secondary,
          ':hover': {
            background: colors.neutral200,
          },
        }
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: borderRadius.xs,
        },
        sizeLarge: {
          padding: spaces.sm + '!important',
        },
        sizeMedium: {
          padding: spaces.xs,
        },
        sizeSmall: {
          padding: spaces.xxs,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ...getFontProps('Poppins, sans-serif', 400, '1rem'),
          letterSpacing: '0.15px',
          lineHeight: '1.75rem',
          minHeight: spaces.xxl,
          gap: spaces.md,
          alignItems: 'center',
          backgroundColor: 'transparent!important',
          ':hover': {
            borderRadius: spaces.sm,
            backgroundColor: `${colors.gray100}!important`,
          },
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          zIndex: 2 + ' !important',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          padding: spaces.xxs,
        },
        label: {
          // in the designs, it is 500. but this looks more like the design
          ...getFontProps('Poppins, sans-serif', 400, '0.875rem'),
          lineHeight: '1.125rem',
          letterSpacing: '0.16px',
        },
        labelSmall: {
          paddingTop: spaces.xxxs,
          paddingBottom: spaces.xxxs,
          paddingLeft: spaces.xs,
          paddingRight: spaces.xs,
        },
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          ...getFontProps('Poppins, sans-serif', 500, '0.625rem'),
          lineHeight: '0.714rem',
          borderRadius: spaces.xxs,
          backgroundColor: palette.components.tooltip.fill,
        },
        arrow: {
          transform: 'unset !important',
          left: `calc(50% - ${tooltipArrow.width/2}px) !important`,
          width: tooltipArrow.width,
          height: tooltipArrow.height,
          color: palette.components.tooltip.fill,
        },
        tooltipPlacementTop: {
          '.MuiTooltip-arrow': {
            marginBottom: -(tooltipArrow.height - 2) + 'px !important',
          }
        }
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: borderRadius.sm,
          border: '1px solid',
        },
        standardSuccess: {
          borderColor: palette.success.main,
          color: palette.success.main,
        },
        standardError: {
          borderColor: palette.error.main,
          color: palette.error.main,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: palette.primary.main,
          ':hover': {
            color: palette.primary.main,
          },
        },
        underlineNone: {
          ':visited': {
            color: palette.text.visited,
          },
          '&.Mui-focusVisible': {
            borderRadius: '4px',
            boxShadow: `0px 0px 0px 2px ${palette.text.outlineBorder}`,
            display: 'inline-flex',
            outline: 'none',
          }
        }
      }
    },
  }
});

const customElevations = [1,2,3,4,6,8,10,12,16,24];
for (let i = 0; i < customElevations.length; i += 1) {
  const customElevationValue = customElevations[i];
  mainTheme.shadows[customElevationValue] = boxShadows['elevation' + customElevationValue];
}

export default mainTheme;
