import React from 'react';
import { ItemLink } from '../../../utils';

interface ProductMindItemProps {
  mindItemId: string;
}

const ProductMindItem: React.FC<ProductMindItemProps> = ({ mindItemId, ...props }) => {
  return (
    <ItemLink key={mindItemId} id={mindItemId} type='mindItem' className='product-content__mind_item d-block'>
      {props.children}
    </ItemLink>
  );
};

export default ProductMindItem;
