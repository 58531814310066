import React, { ElementType } from 'react';
import { Link } from 'found';
import classnames from 'classnames';
import Icon, { IconName } from '../../Icon';
import './Button.scss';

export type ButtonVariant = 'primary' | 'secondary' | 'subtle' | 'ghost' | 'black';

interface BaseProps {
  icon?: IconName;
  emoji?: string;
  variant?: ButtonVariant;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  dataTestId?: string
}

interface ButtonProps extends BaseProps {
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
  url?: never;
}

interface ButtonAsLinkProps extends BaseProps {
  url: string;
  disabled?: never;
  type?: never;
}

type OwnProps = ButtonProps | ButtonAsLinkProps;

const Button: React.FC<OwnProps> = (props) => {
  const { variant = 'primary', url } = props;
  const Component = url ? Link : 'button' as ElementType;

  return (
    <Component
      data-testid={props.dataTestId}
      disabled={props.disabled}
      className={classnames(`button button--${variant}`, props.className, props.disabled && 'button--disabled')}
      type={props.type}
      onClick={props.onClick}
      to={url}>
      {props.icon && <Icon icon={props.icon} className='button__icon' />}
      <span>{props.children}{props.emoji && <span className='button__emoji'> {props.emoji}</span>}</span>
    </Component>
  );
};

export default Button;
