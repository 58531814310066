import { DownloadLink, DownloadInformationInterface } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import React from 'react';
import Button from '../../components/common/Button';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { StoreType } from '../../reducers';
import { storeDownloadedItem } from '../../actions/download.actions';
import { incrementDownloadsCounter } from '../../actions/user.actions';
import { DownloadableFileType } from '@klab-berlin/api-sdk/lib/types/responses/Common';
import {
  DownloadLinks,
} from '../../types/DownloadModalTypes';
import DocumentDownload from './DocumentDownload';
import { selectUser } from '../../reducers/user.selectors';
import { User } from '../../types/User';
import initFileDownload from '../utils/downloadIFrame';
import './downloadManager.scss';
import services from '../../services';
import { trackingEvents } from '../../services/tracking/trackConfig';
import { AmplitudeTrackPayload } from '@klab-berlin/api-sdk/lib/types/requests/Track';
import { getContentEventPayload, mapDesignPreference } from '../../utils/ampli';
import { amplitudeContentTypes, amplitudeEvents } from '../../constants';
import { selectMindItem } from '../../reducers/mindItems.selectors';
import { useNewDesign } from '../../state/DesignPreferenceContextProvider';

type DownloadPreselect = 'mindItem' | 'attachments';
interface OwnProps {
  mindItemId: string;
  downloadInformation: DownloadInformationInterface;
  preselect: DownloadPreselect;
  render: (DownloadsList: React.FC, DownloadBtn: React.FC<any>, CancelBtn: React.FC<any>) => React.ReactNode;
}

interface StateProps {
  user?: User;
}

interface DispatchProps {
  storeDownloadedItem: typeof storeDownloadedItem;
  incrementDownloadsCounter: typeof incrementDownloadsCounter;
}

type DownloadManagerProps = OwnProps & DispatchProps & StateProps;

const initCheckboxesState = (links: DownloadLinks, preselect: DownloadPreselect): Dictionary<boolean> => {
  const { attachments, document } = links;
  const preselectMindItems = preselect === 'mindItem';
  const state: { [x: string]: boolean } = {};

  if (document) state[document.id] = preselectMindItems;

  if (attachments) {
    Object.keys(attachments)
      .forEach((id) => { state[id] = !preselectMindItems; });
  }

  return state;
};

const getSelectedDownloadLinks = (links: DownloadLinks, state: Dictionary<boolean>) => {
  const { attachments, document } = links;
  const result: DownloadLink<DownloadableFileType>[] = [];

  if (document && state[document.id]) {
    result.push(document);
  }

  if (attachments) {
    Object.keys(attachments)
      .forEach((id) => state[id] && result.push(attachments[id]));
  }

  return result;
};

const DownloadManager: React.FC<DownloadManagerProps> = (props) => {
  const { t } = useTranslation();
  const { prefersNewDesign, shouldShowDesignPreferenceSwitch } = useNewDesign();
  const isPremium = props.user && props.user.isPremium;
  const { downloadInformation } = props;
  if (!downloadInformation) {
    return null;
  }
  const { mainDocument: document } = downloadInformation;

  const initialCheckboxState = initCheckboxesState({ document }, props.preselect);
  const [checkboxesState] = React.useState(initialCheckboxState);
  const [downloadTriggered, setDownloadTriggered] = React.useState(false);
  const mindItem = useSelector(state => selectMindItem(state, props.mindItemId));

  const isNoneSelected = !Object.values(checkboxesState)
    .reduce((accu, state) => accu || state, false);

  const trackContentDownloaded = () => {
    const downloadTrackPayload: AmplitudeTrackPayload | null = getContentEventPayload({
      content: mindItem,
      event_type: amplitudeEvents.ContentDownloaded,
      contentType: amplitudeContentTypes.document
    });
  
    if (downloadTrackPayload) {
      if (shouldShowDesignPreferenceSwitch) {
        downloadTrackPayload.event_properties['ui'] = mapDesignPreference(prefersNewDesign);
      }

      services.track.ampliServerSideEventTrack(false, downloadTrackPayload);
    }
  };

  const onDownloadClick = () => {
    services.track.eventTrack(trackingEvents.reader.itemDownload, { other: { 'minditem_id': props.mindItemId } });
    trackContentDownloaded();

    getSelectedDownloadLinks({ document }, checkboxesState)
      .forEach((downloadLink) => initFileDownload(downloadLink.link));

    if (!downloadTriggered && document && document.countsTowardsLimit) {
      props.incrementDownloadsCounter();
    }

    if (document && checkboxesState[document.id] === true) {
      props.storeDownloadedItem(document.id);
    }

    setDownloadTriggered(true);
  };

  // All of the visual components
  const CancelBtn = (props: Record<string, unknown>) =>
    <Button {...props} variant='secondary'>
      {t('Cancel')}
    </Button>;

  const DownloadBtn = (props: Record<string, unknown>) => <Button
    {...props}
    icon='download'
    disabled={isNoneSelected}
    dataTestId='download-btn'
    onClick={onDownloadClick}
    type='button'>
    {t('Download')}
  </Button>;

  const DownloadsList = () =>
    <DocumentDownload
      downloadInformation={downloadInformation}
      isPremium={isPremium}
    />;

  return (
    <>{props.render(DownloadsList, DownloadBtn, CancelBtn)}</>
  );
};

const mapState = (state: StoreType): StateProps => ({
  user: selectUser(state),
});

export default compose<React.FC<OwnProps>>(
  connect(
    mapState,
    { storeDownloadedItem, incrementDownloadsCounter }
  ),
)(DownloadManager);
