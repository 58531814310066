import Icon from './Icon';
import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  deleteCollection,
  followCollection,
  unfollowCollection,
  updateCollection,
} from '../actions/collections.actions';
import { trackingEvents } from '../services/tracking/trackConfig';
import WithTracking from './utils/WithTracking';
import { CollectionFormModalContext } from '../state/CollectionFormModalProvider';
import { CollectionProperties } from '../types/AppContent';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import { StoreType } from '../reducers';
import CollectionsListItem from './CollectionsListItem';
import useMedia from '../utils/useMedia';

interface DispatchProps {
  deleteCollection: typeof deleteCollection;
  followCollection: typeof followCollection;
  updateCollection: typeof updateCollection;
  unfollowCollection: typeof unfollowCollection;
}

interface StateProps {
  me: StoreType['user']['me'];
}

interface OwnProps {
  activeId?: string;
  emptyText?: React.ReactNode;
  collections: CollectionProperties[];
  collectionsStatus: AsyncActionStatus;
  linkRoot: string;
  setActiveId: React.Dispatch<React.SetStateAction<string>>;
  showNewCollectionButton?: boolean;
}

type CollectionsListProps = StateProps & DispatchProps & OwnProps;

const CollectionsList: React.FC<CollectionsListProps> =
({ activeId, linkRoot, collections, collectionsStatus, me, ...props }) => {
  const { t } = useTranslation();
  const { isMobile } = useMedia();
  const { show: openCollectionFormModal } = useContext(CollectionFormModalContext);

  if (collectionsStatus !== 'loading' && (!collections || !collections.length)) {
    return (
      <>
        <div>
          {props.emptyText}
          {props.showNewCollectionButton && (
            <WithTracking events={{ onClick: trackingEvents.emptyStateCreateCollectionModalOpen }}>
              <Button
                className='button--bottom-right font-weight-bold'
                variant='primary'
                onClick={() => openCollectionFormModal()}
              >
                <Icon icon='plus' className='mr-2' />
                <span className='text-uppercase'>{t('new collection')}</span>
              </Button>
            </WithTracking>
          )}
        </div>
      </>
    );
  }

  return (
    <>
      <div className='pt-3 grid-container'>
        {collectionsStatus === 'loading' &&
          collections &&
          collections.length === 0 && (
          <>
            <Skeleton height={211} />
            <Skeleton height={211} />
            <Skeleton height={211} />
          </>
        )}
        {collections && collections.length > 0 &&
          collections.map((collection) =>
            <CollectionsListItem
              key={collection.id}
              deleteCollection={props.deleteCollection}
              followCollection={props.followCollection}
              unfollowCollection={props.unfollowCollection}
              updateCollection={props.updateCollection}
              collection={collection}
              userId={me && me._id || ''}
              canPublish={me && me.canPublishCuratedCollections || false}
              isSelected={activeId === collection.id}
              selectOnClick={!!isMobile}
              setActiveId={props.setActiveId}
              linkRoot={linkRoot}
            />
          )}
      </div>
    </>
  );
};

export default compose<React.FC<OwnProps>>(
  connect(
    (state: StoreType): StateProps => ({
      me: state.user.me,
    }),
    {
      deleteCollection,
      followCollection,
      updateCollection,
      unfollowCollection,
    } as DispatchProps,
  ),
)(CollectionsList);
