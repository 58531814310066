import { Publisher } from '@klab-berlin/api-sdk/lib/types/responses/Text';
import immutable from 'object-path-immutable';
import { StoreItem } from '.';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import { FetchPublishersAction, FETCH_PUBLISHERS } from '../actions/publishers.actionTypes';

type PublisherActionTypes =
  | FetchPublishersAction;

export type PublisherState= StoreItem<Publisher[]>;

const initialState: PublisherState = {
  data: [],
  status: AsyncActionStatus.INITIAL,
};

const publishers = (state = initialState, action: PublisherActionTypes) => {
  switch (action.type) {
    case FETCH_PUBLISHERS.START:
      return immutable(state)
        .set('status', AsyncActionStatus.LOADING)
        .value();
    case FETCH_PUBLISHERS.SUCCESS:
      return immutable(state)
        .set('data', action.result)
        .set('status', AsyncActionStatus.READY)
        .value();
    case FETCH_PUBLISHERS.ERROR:
      return immutable(state)
        .set('status', AsyncActionStatus.ERROR)
        .value();
    default:
      return state;
  }
};

export default publishers;
