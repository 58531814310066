import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/common/Button';
import { routes } from '../../../constants';
import useRouter from '../../../utils/useRouter';
import './successModal.scss';

const UpgradeSuccessModal: React.FC = () => {
  const { router } = useRouter();
  const { t } = useTranslation();

  const onSuccessModalConfirmButtonClick = () => {
    router.push(`/${routes.search.root}`);
  };

  return (
    <Modal show={true} backdrop='static' centered={true}>
      <div className='success-modal' data-testid='upgrade-success-modal'>
        <h1 className='success-modal__emoji'>🚀</h1>
        <h1>{t('Your upgrade was successful')}</h1>
        <p className='success-modal__body'>
          {t('Profit directly of your premium benefits and find materials for your next lesson')}
        </p>
        <Button
          variant='primary'
          onClick={onSuccessModalConfirmButtonClick}
          className='success-modal__button'
        >
          {t('Find Lesson Materials')}
        </Button>
      </div>
    </Modal>
  );
};

export default UpgradeSuccessModal;
