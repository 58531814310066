import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Carousel, { SlideDefinition } from './Carousel';
import './carouselSkeleton.scss';

interface CarouselSkeletonProps {
  height: number;
  numberOfSlides: SlideDefinition;
  width: number;
}

const CarouselSkeleton: React.FC<CarouselSkeletonProps> = ({ height, width, ...props }) => {
  const slides = new Array(props.numberOfSlides.xl).fill(null);

  return (
    <div className='carousel-skeleton'>
      <h2 className='carousel-skeleton__header'><Skeleton /></h2>
      <Carousel
        className='carousel-skeleton__body'
        numberOfSlides={props.numberOfSlides}
        slides={
          slides.map((_, i) => (
            <div className='carousel-skeleton__item' key={i}>
              <Skeleton
                height={height}
                width={width}
              />
            </div>
          ))
        }
        infinite={false}
      />
    </div>
  );
};

export default CarouselSkeleton;
