import { IconName } from '../components/Icon/IconTypes';
import constants, { routes } from '../constants';
import { DownloadableFileType } from '@klab-berlin/api-sdk/lib/types/responses/Common';
import {
  UserResponseData,
  SubjectPrioritized,
} from '@klab-berlin/api-sdk/lib/types/responses/User';
import { ItemVariation } from '@klab-berlin/api-sdk/lib/types/responses/Collection';
import { Link, Router } from 'found';
import React from 'react';

const SUBJECTS = constants.subjectsById;

export function itemLink(id: string, type: ItemVariation) {
  return type === 'mindItem'
    ? `/${routes.reader.root}/${id}`
    : `/${routes.productPage.root}/${id}`;
}

interface ItemLinkProperties {
  id: string;
  type: ItemVariation;
  className: string;
}

export const ItemLink: React.FC<ItemLinkProperties> = ({ id, type, children, className }) => {
  const link = itemLink(id, type);
  return <Link to={link} key={id} className={className}>{children}</Link>;
};

export function openItem(router: Router, id: string, type: ItemVariation) {
  const link = itemLink(id, type);
  return router.push(link);
}

export function generateCollectionLink(collectionId: string) {
  return '/' + routes.collections.root + '/' + collectionId;
}

export function copyToClipboard(str: string) {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

export function isModifiedMouseClick(event: React.MouseEvent) {
  return event.defaultPrevented ||
    event.metaKey ||
    event.altKey ||
    event.ctrlKey ||
    event.shiftKey ||
    event.button !== 0;
}

const contentTypeToIcon: { [key in DownloadableFileType]: IconName } = {
  wordDocument: 'doc-file',
  pdf: 'pdf-file',
  powerpoint: 'ppt-file',
  audio: 'audio-file',
  image: 'image-file',
  video: 'video-file',
  flash: 'unknown-file',
  spreadsheet: 'excel-file',
  zip: 'zip-file',
  folder: 'collection-filled',
  unknown: 'unknown-file',
};

export function getIconForFileType(
  contentType: DownloadableFileType
): IconName {
  const defaultIcon = 'paperclip';

  return contentTypeToIcon[contentType] || defaultIcon;
}

export function flattenUserSubjects(subjects: UserResponseData['subjects']) {
  if (!subjects) {
    return [];
  }
  const sort = (a: SubjectPrioritized, b: SubjectPrioritized) =>
    a.priority >= b.priority ? 1 : -1;
  return [
    ...subjects.majors
      .sort(sort)
      .map((s) => SUBJECTS[s.subject])
      .filter((v) => v),
    ...subjects.minors
      .sort(sort)
      .map((s) => SUBJECTS[s.subject])
      .filter((v) => v),
  ];
}

export function mapCollectionContents(
  mindItemIds: string[],
  productIds: string[]
): Array<{ id: string; type: ItemVariation }> {
  return [
    ...mindItemIds.map((id) => ({ id, type: 'mindItem' as ItemVariation })),
    ...productIds.map((id) => ({ id, type: 'product' as ItemVariation })),
  ];
}

export const getSeconds = (date?: string | number | Date) => {
  if (!date) {
    return null;
  }

  return Math.floor(new Date(date).getTime() / 1000);
};

export const padValue = (value: number) => (value.toString().padStart(2, '0'));
