import { PaymentPeriod } from '@klab-berlin/api-sdk/lib/types/common';
import api from '../clients/api';
import { handleUnauthorized } from '../utils/apiWrapper';
import { notifyBugsnagNetwork } from '../utils/bugsnagClient';
import { ZuoraPaymentMethod, ZuoraPaymentMethodSignatureSource } from '@klab-berlin/api-sdk/lib/services/billing';
import { getUserId } from './user.service';
import config from 'config';

const service = {
  cancelSubscription,
  getBraintreeSignature,
  getLicenceById,
  getSignature,
  updatePaymentPeriod,
  getInvoices,
  getInvoiceDownloadLink,
  getSubscription,
  getDiscountInformation,
  getActiveLicences
};

function cancelSubscription(userId: string) {
  notifyBugsnagNetwork('billing', 'cancelSubscription');
  return handleUnauthorized(() => api.billing.cancelSubscription(userId));
}

function getInvoices(userId: string) {
  notifyBugsnagNetwork('billing', 'getInvoices');
  return handleUnauthorized(() => api.billing.getInvoices(userId));
}

function getInvoiceDownloadLink(userId: string, invoiceId: string) {
  notifyBugsnagNetwork('billing', 'getInvoiceDownloadLink');
  return handleUnauthorized(() => api.billing.getInvoiceDownloadLink(userId, invoiceId));
}

function getBraintreeSignature() {
  notifyBugsnagNetwork('billing', 'getBraintreeSignature');
  return handleUnauthorized(() => api.billing.getBraintreeSignature());
}

function getSignature(method: ZuoraPaymentMethod, source?:string) {
  notifyBugsnagNetwork('billing', 'getSignature');

  // Get payment page id for neu.meinunterricht.de
  const _source = (source || config.zuoraPaymentSource) as ZuoraPaymentMethodSignatureSource;

  return handleUnauthorized(() => api.billing.getSignature(method, _source));
}

function getLicenceById(licenceModelId: number) {
  return api.billing.getLicenceById(licenceModelId);
}

function updatePaymentPeriod(paymentRhythm: PaymentPeriod) {
  notifyBugsnagNetwork('billing', 'updatePaymentPeriod');
  return handleUnauthorized(() => api.billing.updatePaymentPeriod(getUserId(), paymentRhythm));
}

function getSubscription(userId: string) {
  notifyBugsnagNetwork('billing', 'getSubscription');
  return handleUnauthorized(() => api.billing.getSubscription(userId));
}

function getDiscountInformation(promoCode: string, licenceId: number) {
  notifyBugsnagNetwork('billing', 'getDiscountInformation');
  return handleUnauthorized(() => api.billing.getDiscountInformation(promoCode, licenceId));
}

function getActiveLicences() {
  return handleUnauthorized(() => api.billing.getActiveLicences());
}

export default service;
