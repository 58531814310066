import React, { useState, useCallback } from 'react';
import { InputGroup, Button, DropdownButton, Dropdown, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';
import config from 'config';
import constants, { Subject } from '../../constants';
import { Subject as TrimmedSubject } from '../../types/search';
import WithTracking from '../utils/WithTracking';
import { trackingEvents } from '../../services/tracking/trackConfig';
import services from '../../services';
import { SearchSuggestionsResponseData } from '@klab-berlin/api-sdk/lib/types/responses/MindItem';
import Downshift, { DownshiftState, StateChangeOptions } from 'downshift';
import classnames from 'classnames';
import './dashboardSearchBar.scss';
import { getRecentSearches } from '../../utils/recentSearches';
import { useSelector } from 'react-redux';
import { selectUser } from '../../reducers/user.selectors';
import _ from 'lodash';
import { isClientNetworkError } from '../../utils/isClientNetworkError';
import { notifyBugsnagHandledError } from '../../utils/bugsnagClient';
import sortAlphabetically from '../../utils/sortAlphabetically';
import filterByTaxonomy from '../utils/filterByTaxonomy';
import { useAmplitudeExperiment } from '../../utils/ampli';

interface SearchSuggestionItemProps {
  item: SearchSuggestionsResponseData;
  label: string;
  isHighlighted: boolean;
  isSelected: boolean;
}

type OnChangeFunc = (value: string) => void;

interface Props {
  otherSubjects: Subject[];
  mainSubjects: Subject[];
  onSubmit: (query: string, subject: string | null) => void;
}

const renderSubjectOptions = (subjects: Subject[], onClick: OnChangeFunc) => {
  const sortedSubjects = sortAlphabetically(subjects, { field: 'text' });
  
  return filterByTaxonomy(
    sortedSubjects,
    (subject) => {
      return <Dropdown.Item
        className='cursor-pointer'
        as={'div'}
        key={subject.id}
        onClick={() => onClick(subject.id)}
        data-testid='dashboard-subject-dropdown-option'
      >
        {subject.text}
      </Dropdown.Item>;
    }
  );
};

let timeout: number | null = null;

const DashboardSearchBar: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [subject, setSubject] = useState<string | null>(null);
  const [suggestions, setSuggestions] = useState<SearchSuggestionsResponseData[]>([]);
  const user = useSelector(selectUser);
  const userId = user && user.id;
  const {
    variant: searchVariant,
    isReady: isSearchAmplitudeReady,
  } = useAmplitudeExperiment(user, config.amplitudeExperiments['search']);
  const isNewSearch = isSearchAmplitudeReady && searchVariant !== 'control';

  const updateSuggestions = useCallback((searchTerm: string) => {
    setSearchTerm(searchTerm);

    if (timeout !== null) {
      clearTimeout(timeout);
    }

    if (searchTerm === '') {
      return setSuggestions([]);
    }

    const appendValidRecentSearchSuggestions = (suggestions: Array<SearchSuggestionsResponseData>) => {
      const recentSearches = getRecentSearches(userId);
      if (!recentSearches) return;

      const validRecentSearches = recentSearches.filter(
        search => search.term?.toLowerCase().includes(searchTerm.toLowerCase())
      ).map(validRecentSearch => ({ text: validRecentSearch.term || '' }));

      const recentSearchTerms = validRecentSearches.map(search => search.text);
      _.remove(suggestions, (suggestion) => recentSearchTerms.includes(suggestion.text));
      suggestions.unshift(...validRecentSearches);
    };

    timeout = window.setTimeout(() => {
      services.mindItem.getSearchSuggestions(searchTerm, isNewSearch).then((suggestions) => {
        appendValidRecentSearchSuggestions(suggestions);
        setSuggestions(suggestions);
        timeout = null;
      }).catch(error => { 
        if (isClientNetworkError(error.message)) notifyBugsnagHandledError(error);
      });
    }, 300);
  }, [searchTerm, timeout, isNewSearch]);

  const handleSubmit = (ev: React.FormEvent) => {
    ev.preventDefault();
    props.onSubmit(searchTerm, subject);
  };

  const showRecentSearchSuggestions = () => {
    const recentSearches = getRecentSearches(userId);
    if (searchTerm) return;  // Show this list only on click.
    if (!recentSearches) return;

    const recentSearchSuggestions: TrimmedSubject[] = recentSearches.map(
      (search, index) => ({ id: String(index), text: search.term || '' })
    );

    setShowSuggestions(true);
    setSuggestions(recentSearchSuggestions);
  };

  const hideRecentSearchSuggestions = () => {
    setShowSuggestions(false);
  };

  const handleSuggestionSelect = (item: SearchSuggestionsResponseData | null) => {
    item && props.onSubmit(item.text, subject);
  };

  const stateReducer = (state: DownshiftState<any>, changes: StateChangeOptions<any>) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.mouseUp:
        return { ...state, isOpen: false };
      default:
        return changes;
    }
  };

  const SearchSuggestionItem = ({ isHighlighted, label, isSelected, ...props }: SearchSuggestionItemProps) => {
    return (
      <div
        {...props}
        className={
          classnames('cursor-pointer px-3 py-1 rounded', {
            'bg--grey-lightest': isHighlighted,
            'text-bold': isSelected,
          })
        }
      >
        {label}
      </div>
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InputGroup className='dashboard-search-bar'>
        <DropdownButton
          className='dashboard-search-bar__subject-dropdown-toggle'
          variant='outline-secondary'
          title={subject && constants.subjectsById[subject].text || t('All Subjects')}
          id='input-group-dropdown-1'
          data-testid='dashboard-search-bar-subject-button'
          as={InputGroup.Append}
        >
          <div className='dashboard-search-bar__subject-dropdown-list'>
            {renderSubjectOptions(props.mainSubjects, setSubject)}
            <Dropdown.Divider />
            <div className='dashboard-search-bar__subject-dropdown-list-section'>
              {renderSubjectOptions(props.otherSubjects, setSubject)}
            </div>
          </div>
        </DropdownButton>
        <div className='flex-fill h-100 rounded-0 d-flex'>
          <Downshift
            onChange={handleSuggestionSelect}
            onInputValueChange={updateSuggestions}
            stateReducer={stateReducer}
            itemToString={item => (item ? item.text : '')}
          >
            {({
              getInputProps,
              getItemProps,
              getMenuProps,
              isOpen,
              highlightedIndex,
              selectedItem,
            }) => {
              return (
                <div className='flex-fill'>
                  <input
                    {...getInputProps()}
                    onClick={showRecentSearchSuggestions}
                    onBlur={hideRecentSearchSuggestions}
                    className='w-100 h-100 px-3 border border-light text-secondary'
                    data-testid='dashboard-search-bar'
                    placeholder={t('Search')}
                    maxLength={128}
                  />
                  <div
                    {...getMenuProps()}
                    className={
                      classnames(`
                        bg-white
                        border
                        border-light
                        border-top-0
                        rounded-bottom
                        py-3
                        position-relative
                        z-index-dropdown`,
                      { 'd-none': !(showSuggestions || isOpen) || suggestions.length === 0  })
                    }
                    data-testid='dashboard-search-bar-suggestions'
                  > 
                    {(showSuggestions || isOpen) && suggestions.map((suggestion, index) => (
                      <SearchSuggestionItem
                        key={suggestion.text}
                        {...getItemProps({
                          key: suggestion.text,
                          index,
                          item: suggestion,
                        })}
                        label={suggestion.text}
                        isHighlighted={highlightedIndex === index}
                        isSelected={selectedItem === suggestion}
                      />
                    ))}
                  </div>
                </div>
              );
            }}
          </Downshift>
        </div>
        <InputGroup.Append>
          <WithTracking
            events={{ onClick: trackingEvents.searchDashboardExecute }}
            eventPayload={{
              other: {
                'search_taxonomy': subject && constants.subjectsById[subject].text || t('All Subjects')
              }
            }}
          >
            <Button
              variant='primary'
              className='rounded-right px-3'
              type='submit'
            >
              <Icon icon='search' />
            </Button>
          </WithTracking>
        </InputGroup.Append>
      </InputGroup>
    </Form>
  );
};

export default DashboardSearchBar;
