import { CreateCollectionRequest, EditCollectionRequest } from '@klab-berlin/api-sdk/lib/types/requests/Collection';
import React, { useState } from 'react';
import { AsyncActionStatus } from '../actions/common.actionTypes';
import { CollectionFormModal } from '../components/modals';
import { generateCollectionLink } from '../utils';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { WithRouter, withRouter } from 'found';
import { createCollection, updateCollection } from '../actions/collections.actions';
import { CollectionProperties } from '../types/AppContent';
import { CollectionColor, CollectionResponseData } from '@klab-berlin/api-sdk/lib/types/responses/Collection';
import { StoreType } from '../reducers';

type SaveFunc = (collectionId: string | undefined, data: CreateCollectionRequest) => Promise<void>;
type ShowFunc = (collectionId?: string, data?: CollectionProperties) => void;
type HideFunc = () => void;
const noop = () => undefined;

export interface Collection {
  color: CollectionColor;
  description: string;
  schoolType: string;
  subjects: string[];
  title: string;
  isPublic: boolean;
}

export interface CollectionFormModalContextState {
  canPublish: boolean;
  collectionId?: string;
  data?: CollectionProperties;
  error?: string;
  hide: HideFunc;
  isOpen: boolean;
  save: SaveFunc;
  show: ShowFunc;
  status: AsyncActionStatus;
}

interface CollectionFormModalProviderProps extends WithRouter {
  canPublish: boolean;
  createCollection: (data: CreateCollectionRequest) => Promise<CollectionResponseData>;
  updateCollection: (
    id: string,
    data: EditCollectionRequest
  ) => Promise<CollectionResponseData>;
}

const defaultContext: CollectionFormModalContextState = {
  canPublish: false,
  hide: noop as HideFunc,
  isOpen: false,
  save: noop as any as SaveFunc,
  show: noop as ShowFunc,
  status: AsyncActionStatus.READY,
};

export const CollectionFormModalContext = React.createContext(defaultContext);

const CollectionFormModalProviderInner: React.FC<CollectionFormModalProviderProps> = (props) => {
  const [modalState, setModalState] = useState<CollectionFormModalContextState>(defaultContext);

  const show: ShowFunc = (collectionId?, data?) => {
    setModalState({ ...modalState, collectionId, data, isOpen: true });
  };
  const hide: HideFunc = () => setModalState({ ...defaultContext, isOpen: false });
  const save: SaveFunc = (collectionId, data) => {
    setModalState((prevState) => ({ ...prevState, status: AsyncActionStatus.LOADING }));

    const promise: Promise<any> = collectionId
      ? props.updateCollection(collectionId, data)
      : props.createCollection(data)
        .then((collection) => {
          props.router.replace(generateCollectionLink(collection._id));
        });

    return promise
      .then(() => setModalState((s) => ({ ...s, status: AsyncActionStatus.READY })))
      .then(modalState.hide)
      .catch((error: Error) => {
        setModalState((prevState => ({ ...prevState, error: error.message, status: AsyncActionStatus.ERROR })));
      });
  };

  Object.assign(defaultContext, { canPublish: props.canPublish, save, show, hide });

  return (
    <CollectionFormModalContext.Provider value={modalState}>
      <CollectionFormModal />
      {props.children}
    </CollectionFormModalContext.Provider>
  );
};

export const CollectionFormModalProvider = compose<React.FC>(
  withRouter,
  connect(
    (state: StoreType) => ({ canPublish: state.user.me && state.user.me.canPublishCuratedCollections || false }),
    { createCollection, updateCollection }
  ),
)(CollectionFormModalProviderInner);
