import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from '../../reducers/user.selectors';
import { ModalsContext } from '../../state/ModalsProvider';
import { routes } from '../../constants';
import { Button, SvgIcon, SxProps, Theme } from '@mui/material';
import { palette } from '../../assets/styles/themes/tokens';
import useRouter from '../../utils/useRouter';
import './attachmentsButton.scss';
import getMuiIcon from '../../utils/getMuiIcon';

interface OwnProps {
  productId: string;
  attachmentsCount: number;
  buttonSx?: SxProps<Theme>
}

export const NewAttachmentsButton: React.FC<OwnProps> = ({ attachmentsCount, productId, buttonSx }) => {
  const { t } = useTranslation();
  const { router } = useRouter();
  const user = useSelector(selectUser);
  const { openModal } = useContext(ModalsContext);

  const handleButtonClick = () => {
    if (user?.isPremium) router.push(`/${routes.productAttachmentsPage.root}/${productId}`);
    else openModal('premiumContent');
  };

  const attachmentsText = attachmentsCount === 1 ? t('Attachment') : t('Attachments');
  
  return (
    <Button
      onClick={handleButtonClick}
      variant='outlined'
      size='large'
      color='primary'
      key='attachments-button'
      data-testid='attachments-button'
      startIcon={
        <SvgIcon 
          component={getMuiIcon('paperclip')} 
          sx={{ fill: palette.primary.main }} 
        />}
      sx={{
        ...buttonSx,
      }}
    >
      {attachmentsCount}{' '}{attachmentsText}
    </Button>
  );
};
