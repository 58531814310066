import React, { useEffect, useRef } from 'react';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import lernsetsTheme from '../../../assets/styles/themes/lernsetsTheme';
import LernsetsTopbar from '../LernsetsTopbar';
import Sidebar from '../LernsetsSidebar';
import { useSelector } from 'react-redux';
import { routes } from '../../../constants';
import { selectUser } from '../../../reducers/user.selectors';
import useRouter from '../../../utils/useRouter';

export type TopbarTitle = {
  text?: string;
  tooltipMessage?: string;
  isEditable?: boolean;
  onNewTitleSave?: (newTitle: string) => void;
};

interface OwnProps {
  title?: TopbarTitle;
  onBackClick?: () => void;
  topRightComponent?: React.JSX.Element;
  lockContentScrolling?: boolean;
  hideSidebar?: boolean;
  isPublicView?: boolean;
}

const LernsetsMasterLayout: React.FC<OwnProps> = (props) => {
  const user = useSelector(selectUser);
  const { router } = useRouter();
  const { 
    children,
    title,
    onBackClick,
    topRightComponent,
    lockContentScrolling,
    hideSidebar,
    isPublicView,
  } = props;
  const contentRef = useRef<HTMLDivElement | null>(null);
  const scrollLockPosition = useRef<number | undefined>(undefined);

  useEffect(() => {
    if (user && !user.isPremium) {
      router.push(`/${routes.dashboard.root}`);
    }
  }, [user]);

  useEffect(() => {
    if (lockContentScrolling) {
      scrollLockPosition.current = contentRef.current?.scrollTop;
    }
    else {
      scrollLockPosition.current = undefined;
    }
  }, [lockContentScrolling]);

  const onScroll = () => {
    if (lockContentScrolling && scrollLockPosition.current !== undefined) {
      contentRef.current?.scrollTo(contentRef.current.scrollLeft, scrollLockPosition.current);
    }
  };

  return (
    <ThemeProvider theme={lernsetsTheme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', height: '100vh', width: '100%' }}>
        {!hideSidebar && <Sidebar />}
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <LernsetsTopbar 
            title={title}
            onBackClick={onBackClick} 
            rightComponent={topRightComponent}
            isPublicView={isPublicView}
          />
          <Box
            ref={contentRef} 
            onScroll={onScroll}
            sx={{
              overflow: 'auto',
              height: '100%',
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default LernsetsMasterLayout;
