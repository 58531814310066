import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { emailAddresses } from '../../constants';

interface ImprintModalProps {
  show: boolean;
  onClose: () => void;
}

const ImprintModal: React.FC<ImprintModalProps> = ({ show, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={onClose} size='lg'>
      <Modal.Header closeButton={true}>
        <Modal.Title>
          {t('Imprint')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='mb-3'>
          meinUnterricht ist ein Angebot der<br />
        meinUnterricht GmbH<br />
        Karl-Marx-Straße 100 | D-12043 Berlin<br />
        Tel: +49 (0) 30 120 89 41 99 | Fax: +49 (0) 30 120 89 41 97<br />
          <a href={`mailto:${emailAddresses.support}`}>{emailAddresses.support}</a>
        </p>
        <Row as={'dl'}>
          <Col as={'dt'} xs={6} className='text-right'>Unternehmenswebseite:</Col>
          <Col as={'dd'} xs={6}>
            <a href='https://www.meinunterricht.de' target='_blank' rel='noreferrer noopener'>www.meinunterricht.de</a>
          </Col>
          <Col as={'dt'} xs={6} className='text-right'>Geschäftsführer:</Col>
          <Col as={'dd'} xs={6}>Maximilian Daub</Col>
          <Col as={'dt'} xs={6} className='text-right'>Sitz der Gesellschaft:</Col>
          <Col as={'dd'} xs={6}>Berlin</Col>
          <Col as={'dt'} xs={6} className='text-right'>Steuernummer:</Col>
          <Col as={'dd'} xs={6}>29/431/02058</Col>
          <Col as={'dt'} xs={6} className='text-right'>Umsatzsteuer-Identifikationsnummer:</Col>
          <Col as={'dd'} xs={6}>DE281638550</Col>
          <Col as={'dt'} xs={6} className='text-right'>Handelsregister:</Col>
          <Col as={'dd'} xs={6}>HRB 139386B</Col>
        </Row>
        <p className='mb-3'>
        Amtsgericht Charlottenburg Online-Streitbeilegung gemäß Art. 14 Abs. 1<br />
        ODR-VO: Die Europäische Kommission stellt eine Plattform zur
        Online-Streitbeilegung (OS) bereit, die Sie unter
          {' '}
          <a
            href='https://ec.europa.eu/consumers/odr/'
            target='_blank'
            rel='noreferrer noopener'
          >
          https://ec.europa.eu/consumers/odr/
          </a> finden.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='outline-secondary'
          onClick={onClose}
        >
          {t('Ok')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImprintModal;
