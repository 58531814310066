import React from 'react';
import { useTranslation } from 'react-i18next';
import ScreenStepContainer, { ScreenStepProps } from '../ScreenModal/ScreenStepContainer';
import { selectUser, getSubscription } from '../../../reducers/user.selectors';
import { SubscriptionResponse } from '@klab-berlin/api-sdk/lib/types/responses/Billing';
import { useSelector } from 'react-redux';
import { 
  ampliTrackSubscriptionCancellationSelectedEvent,
  ampliTrackSubscriptionPauseSelectedEvent 
} from '../../../services/track.service';
import '../ScreenModal/ScreenStepContainer.scss';
import { ampliSubscriptionPageSource } from '../../../constants';

const CancellationStepOfferPause:React.FC<ScreenStepProps> = (props) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const subscription: SubscriptionResponse | undefined = useSelector(getSubscription);

  const goToPauseSelectionButton = {
    action: () => {
      if (user && subscription) {
        ampliTrackSubscriptionPauseSelectedEvent(ampliSubscriptionPageSource.PAUSE_SUGGESTION);
      }
      props.setScreen('pauseSelection');
    },
    title: t('Pause Subscription'),
  };
  const continueCancellationButton = {
    action: () => {
      if (user && subscription) {
        ampliTrackSubscriptionCancellationSelectedEvent(
          user.isTrial,
          ampliSubscriptionPageSource.PAUSE_SUGGESTION
        );
      }

      props.setScreen('arguments');
    },
    title: t('Continue Cancellation')
  };
  return (
    <ScreenStepContainer
      trackingId='offerPause'
      title={t('Do you need a break?')}
      primaryButton={goToPauseSelectionButton}
      secondaryButton={continueCancellationButton}
      onClose={props.onClose}
    >
      <div className='screen-step__img screen-step__park-bench-small' />
      <p>{t('We know there are times when you want to lean back and relax')}</p>
    </ScreenStepContainer>
  );
};

export default CancellationStepOfferPause;
