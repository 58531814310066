import { Box, Stack, Theme, useMediaQuery } from '@mui/material';
import React, { useContext } from 'react';
import { spaces } from '../../../assets/styles/themes/tokens';
import SearchFilterButton from './SearchFilterButton';
import { useTranslation } from 'react-i18next';
import useQueryModification from '../../../routes/SearchPage/useQueryModification';
import { SearchFilters } from '../../../types/search';
import useRouter from '../../../utils/useRouter';
import SortByFilterNewUI from '../../SearchFilters/SortByFilterNewUI';
import { searchSettings } from '../../../constants';
import MoreFiltersButton from './MoreFiltersButton';
import { SearchFiltersDialogContext } from '../../../state/SearchFiltersDialogProvider';

const SearchFiltersContainer: React.FC = () => {
  const { t } = useTranslation();
  const { router, match } = useRouter();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobile'));
  const { updateQueryParams, getQueryParam } = useQueryModification<SearchFilters>(router, match);
  const { openSearchFiltersDialog } = useContext(SearchFiltersDialogContext);

  return <Stack 
    direction='row'
    sx={{
      display: 'flex',
      alignItems: 'center',
      alignContent: 'flex-start',
      gap: spaces.xs,
      alignSelf: 'stretch',
    }}>
    {!isMobile && <>
      <SearchFilterButton text={t('Subject')} onClick={openSearchFiltersDialog} />
      <SearchFilterButton text={t('Class years')} numberOfSelections={4} onClick={openSearchFiltersDialog}  />
      <SearchFilterButton text={t('SchoolType')} numberOfSelections={1} onClick={openSearchFiltersDialog} />
      <SearchFilterButton text={t('Publisher')} onClick={openSearchFiltersDialog} />
    </>}
    <Box sx={{
      flex: 1,
    }}>
      <MoreFiltersButton numberOfSelections={1} onClick={openSearchFiltersDialog} />
    </Box>
    {
      isMobile && <Box sx={{
        display: 'flex',
        flex: 1,
        alignSelf: 'stretch',
        minWidth: 0,
        '& .MuiInputBase-root': {
          height: '100%',
          width: 'unset !important',
        },
        '& .MuiFormControl-root': {
          height: '100%',
          width: '100%',
        },
        '& .MuiListItemText-primary': {
          maxWidth: 'unset !important',
        }
      }}><SortByFilterNewUI
          currentValue={getQueryParam('sortBy')}
          updateFilter={(sortBy) => updateQueryParams({ sortBy })}
          clearFilter={() => updateQueryParams({ 'sortBy': searchSettings.defaultSortBy })}
        /></Box>
    }
  </Stack>;
};

export default SearchFiltersContainer;
