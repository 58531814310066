import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ItemMeatballMenu from '../ItemMeatballMenu';
import ItemMetadata from '../ItemMetadata';
import classnames from 'classnames';
import { ItemCardProps } from '../Item.props';
import BackgroundImage from '../BackgroundImage';
import PublisherImage from '../PublisherImage';

const PdfItem: React.FC<ItemCardProps> = (props) => {
  return (
    <>
      <div className='item-header'>
        <BackgroundImage
          className='item--header-image'
          url={props.item.thumbnail}
        />
      </div>
      <Card.Body
        className={classnames('pt-2 pb-2 px-3', { 'item--active': props.selected })}
      >
        <Row className='align-items-center'>
          <Col>
            <div className='item__title font-weight-bold'>{props.item.title}</div>
          </Col>
          <Col
            xs={1}
            className='rounded-circle px-0 justify-content-center align-items-center mr-2'
          >
            <PublisherImage className='publisher-logo' publisher={props.item.publisher} />
          </Col>
        </Row>
        <Row>
          <Col xs={10} className='pt-2'>
            <ItemMetadata
              schoolTypes={props.item.schoolTypes}
              subjects={props.item.subjects}
              classYears={props.item.classYears}
            />
          </Col>
          <Col xs={2} className='p-0'>
            <ItemMeatballMenu itemId={props.item.id} type={props.item.type} />
          </Col>
        </Row>
      </Card.Body>
    </>
  );
};

export default PdfItem;
