import { PaginationRequest } from '@klab-berlin/api-sdk/lib/types/common';
import {
  CreateCollectionRequest,
  EditCollectionRequest,
} from '@klab-berlin/api-sdk/lib/types/requests/Collection';
import {
  CollectionContentsPaginatedData,
  CollectionResponseData,
} from '@klab-berlin/api-sdk/lib/types/responses/Collection';
import { AsyncAction } from './common.actionTypes';
import { ItemVariation } from '@klab-berlin/api-sdk/lib/types/responses/Collection';
import { ManageCollectionRequest } from '@klab-berlin/api-sdk/lib/types/requests/Common';

export enum FETCH_COLLECTIONS {
  START = 'FETCH_COLLECTIONS_START',
  SUCCESS = 'FETCH_COLLECTIONS_SUCCESS',
  ERROR = 'FETCH_COLLECTIONS_ERROR',
}

export type FetchCollectionsAction = AsyncAction<
  typeof FETCH_COLLECTIONS.START,
  typeof FETCH_COLLECTIONS.SUCCESS,
  typeof FETCH_COLLECTIONS.ERROR,
  CollectionResponseData[],
  { ownerId: string }
>;

export enum FETCH_CURATED_COLLECTIONS {
  START = 'FETCH_CURATED_COLLECTIONS_START',
  SUCCESS = 'FETCH_CURATED_COLLECTIONS_SUCCESS',
  ERROR = 'FETCH_CURATED_COLLECTIONS_ERROR',
}

export type FetchCuratedCollectionsAction = AsyncAction<
  typeof FETCH_CURATED_COLLECTIONS.START,
  typeof FETCH_CURATED_COLLECTIONS.SUCCESS,
  typeof FETCH_CURATED_COLLECTIONS.ERROR,
  CollectionResponseData[]
>;

export enum FETCH_RECOMMENDED_COLLECTIONS {
  START = 'FETCH_RECOMMENDED_COLLECTIONS_START',
  SUCCESS = 'FETCH_RECOMMENDED_COLLECTIONS_SUCCESS',
  ERROR = 'FETCH_RECOMMENDED_COLLECTIONS_ERROR',
}

export type FetchRecommendedCollectionsAction = AsyncAction<
  typeof FETCH_RECOMMENDED_COLLECTIONS.START,
  typeof FETCH_RECOMMENDED_COLLECTIONS.SUCCESS,
  typeof FETCH_RECOMMENDED_COLLECTIONS.ERROR,
  CollectionResponseData[]
>;

export enum FETCH_COLLECTION_CONTENTS {
  START = 'FETCH_COLLECTION_CONTENTS_START',
  SUCCESS = 'FETCH_COLLECTION_CONTENTS_SUCCESS',
  ERROR = 'FETCH_COLLECTION_CONTENTS_ERROR',
}

export enum MANAGE_PRODUCT_IN_MULTIPLE_COLLECTIONS {
  START = 'MANAGE_PRODUCT_IN_MULTIPLE_COLLECTIONS_START',
  SUCCESS = 'MANAGE_PRODUCT_IN_MULTIPLE_COLLECTIONS_SUCCESS',
  ERROR = 'MANAGE_PRODUCT_IN_MULTIPLE_COLLECTIONS_ERROR',
}

export enum MANAGE_MINDITEM_IN_MULTIPLE_COLLECTIONS {
  START = 'MANAGE_MINDITEM_IN_MULTIPLE_COLLECTIONS_START',
  SUCCESS = 'MANAGE_MINDITEM_IN_MULTIPLE_COLLECTIONS_SUCCESS',
  ERROR = 'MANAGE_MINDITEM_IN_MULTIPLE_COLLECTIONS_ERROR',
}

export type FetchCollectionContentsAction = AsyncAction<
  typeof FETCH_COLLECTION_CONTENTS.START,
  typeof FETCH_COLLECTION_CONTENTS.SUCCESS,
  typeof FETCH_COLLECTION_CONTENTS.ERROR,
  CollectionContentsPaginatedData,
  { collectionId: string; pagination: PaginationRequest }
>;

export enum FETCH_DOWNLOAD_CONTENTS {
  START = 'FETCH_DOWNLOAD_CONTENTS_START',
  SUCCESS = 'FETCH_DOWNLOAD_CONTENTS_SUCCESS',
  ERROR = 'FETCH_DOWNLOAD_CONTENTS_ERROR',
}

export enum ADD_COLLECTION {
  START = 'ADD_COLLECTION_START',
  SUCCESS = 'ADD_COLLECTION_SUCCESS',
  ERROR = 'ADD_COLLECTION_ERROR',
}

export type AddCollectionAction = AsyncAction<
  typeof ADD_COLLECTION.START,
  typeof ADD_COLLECTION.SUCCESS,
  typeof ADD_COLLECTION.ERROR,
  CollectionResponseData,
  CreateCollectionRequest
>;

export enum ADD_ITEM_TO_COLLECTION {
  START = 'ADD_ITEM_TO_COLLECTION_START',
  SUCCESS = 'ADD_ITEM_TO_COLLECTION_SUCCESS',
  ERROR = 'ADD_ITEM_TO_COLLECTION_ERROR',
}

export type AddItemToCollectionAction = AsyncAction<
  typeof ADD_ITEM_TO_COLLECTION.START,
  typeof ADD_ITEM_TO_COLLECTION.SUCCESS,
  typeof ADD_ITEM_TO_COLLECTION.ERROR,
  CollectionResponseData,
  { collectionId: string; itemType: ItemVariation; itemId: string }
>;

export enum DELETE_ITEM_FROM_COLLECTION {
  START = 'DELETE_ITEM_FROM_COLLECTION_START',
  SUCCESS = 'DELETE_ITEM_FROM_COLLECTION_SUCCESS',
  ERROR = 'DELETE_ITEM_FROM_COLLECTION_ERROR',
}

export type DeleteItemFromCollectionAction = AsyncAction<
  typeof DELETE_ITEM_FROM_COLLECTION.START,
  typeof DELETE_ITEM_FROM_COLLECTION.SUCCESS,
  typeof DELETE_ITEM_FROM_COLLECTION.ERROR,
  CollectionResponseData,
  { collectionId: string; itemType: ItemVariation; itemId: string }
>;

export enum DELETE_COLLECTION {
  START = 'DELETE_COLLECTION_START',
  SUCCESS = 'DELETE_COLLECTION_SUCCESS',
  ERROR = 'DELETE_COLLECTION_ERROR',
}

export type DeleteCollectionAction = AsyncAction<
  typeof DELETE_COLLECTION.START,
  typeof DELETE_COLLECTION.SUCCESS,
  typeof DELETE_COLLECTION.ERROR,
  { status: 204 },
  { collectionId: string }
>;

export enum EDIT_COLLECTION {
  START = 'EDIT_COLLECTION_START',
  SUCCESS = 'EDIT_COLLECTION_SUCCESS',
  ERROR = 'EDIT_COLLECTION_ERROR',
}

export type EditCollectionAction = AsyncAction<
  typeof EDIT_COLLECTION.START,
  typeof EDIT_COLLECTION.SUCCESS,
  typeof EDIT_COLLECTION.ERROR,
  CollectionResponseData,
  { data: EditCollectionRequest; collectionId: string }
>;

export enum FOLLOW_COLLECTION {
  START = 'FOLLOW_COLLECTION_START',
  SUCCESS = 'FOLLOW_COLLECTION_SUCCESS',
  ERROR = 'FOLLOW_COLLECTION_ERROR',
}

export type FollowCollectionAction = AsyncAction<
  typeof FOLLOW_COLLECTION.START,
  typeof FOLLOW_COLLECTION.SUCCESS,
  typeof FOLLOW_COLLECTION.ERROR,
  CollectionResponseData,
  { collectionId: string }
>;

export enum UNFOLLOW_COLLECTION {
  START = 'UNFOLLOW_COLLECTION_START',
  SUCCESS = 'UNFOLLOW_COLLECTION_SUCCESS',
  ERROR = 'UNFOLLOW_COLLECTION_ERROR',
}

export type UnfollowCollectionAction = AsyncAction<
  typeof UNFOLLOW_COLLECTION.START,
  typeof UNFOLLOW_COLLECTION.SUCCESS,
  typeof UNFOLLOW_COLLECTION.ERROR,
  CollectionResponseData,
  { collectionId: string }
>;

export type ManageProductInMultipleCollectionsAction = AsyncAction<
  typeof MANAGE_PRODUCT_IN_MULTIPLE_COLLECTIONS.START,
  typeof MANAGE_PRODUCT_IN_MULTIPLE_COLLECTIONS.SUCCESS,
  typeof MANAGE_PRODUCT_IN_MULTIPLE_COLLECTIONS.ERROR,
  undefined,
  { collections: ManageCollectionRequest['collections']; itemId: string; itemType: ItemVariation }
>;

export type ManageMindItemInMultipleCollectionsAction = AsyncAction<
  typeof MANAGE_MINDITEM_IN_MULTIPLE_COLLECTIONS.START,
  typeof MANAGE_MINDITEM_IN_MULTIPLE_COLLECTIONS.SUCCESS,
  typeof MANAGE_MINDITEM_IN_MULTIPLE_COLLECTIONS.ERROR,
  undefined,
  { collections: ManageCollectionRequest['collections']; itemId: string; itemType: ItemVariation }
>;
