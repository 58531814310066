import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './mindItemListItem.scss';

const SkeletonMindItemListItem = () => {
  return <div className='mind-item-list-item'>
    <Skeleton height={350} />
  </div>;
};

export default SkeletonMindItemListItem;
