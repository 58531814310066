import React, { useEffect, useState } from 'react';
import './attachmentsView.scss';
import { useTranslation } from 'react-i18next';
import Checkbox from '../common/Checkbox';
import Button from '../common/Button';
import { AttachmentItem } from '@klab-berlin/api-sdk/lib/types/responses/Common';
import useMedia from '../../utils/useMedia';
import formatByteSize from '../../utils/formatByteSize';
import _ from 'lodash';
import AttachmentItemView from './AttachmentItem';
import { selectAttachments, selectAttachmentsStatus } from '../../reducers/products.selectors';
import { useSelector } from 'react-redux';
import services from '../../services';
import { AttachmentsDownloadRequest } from '@klab-berlin/api-sdk/lib/types/requests/Product';
import initFileDownload from '../utils/downloadIFrame';
import { zipRetryTime } from '../../constants';
import { ReaderContentViewVariant } from '../../types/reader';
import classnames from 'classnames';
import { AsyncActionStatus } from '../../actions/common.actionTypes';
import Skeleton from 'react-loading-skeleton';
import { notifyBugsnagHandledError } from '../../utils/bugsnagClient';
import { sleep } from '../../utils/sleep';
import { addError as addErrorAction } from '../../actions/errors.actions';
import { useNonThunkAction } from '../../utils/useAction';

interface OwnProps {
  productId: string;
  onFolderClick: (attachmentName: string) => void;
  onError: (errorMessage: string) => void;
  attachmentsPath: string;
  variant: ReaderContentViewVariant;
}

const AttachmentsView: React.FC<OwnProps> = (props) => {
  const { t } = useTranslation();
  const [totalSelectedAttachmentsSize, setTotalSelectedAttachmentsSize] = useState<number>(0);
  const [numFolders, setNumFolders] = useState<number>(0);
  const [numFiles, setNumFiles] = useState<number>(0);
  const [isDownloadDisabled, setIsDownloadDisabled] = useState<boolean>(true);
  const [isFetchingDownload, setIsFetchingDownload] = useState<boolean>(false);
  const [currentAttachmentFiles, setCurrentAttachmentsFiles] = useState<AttachmentItem[]>([]);
  const [selectedContentPaths, setSelectedContentPaths] = useState<string[]>([]);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(t('Attachments'));
  const { isSmallViewport } = useMedia();
  const addError = useNonThunkAction(addErrorAction);
  const attachmentsData = useSelector(state => selectAttachments(state, props.productId));
  const isLoading = 
    useSelector(state => selectAttachmentsStatus(state, props.productId)) === AsyncActionStatus.LOADING;

  useEffect(() => {
    if (attachmentsData) {
      const {
        attachments,
        title
      } = getFolderDetails(attachmentsData.folderStructure, props.attachmentsPath);

      setTitle(title);
      setNumFolders(getCurrentFoldersNumber(attachments));
      setNumFiles(getCurrentFilesNumber(attachments));
      setCurrentAttachmentsFiles(attachments);
    }

    return () => {
      setNumFolders(0);
      setNumFiles(0);
      setCurrentAttachmentsFiles([]);
      setIsSelectAll(false);
      setTotalSelectedAttachmentsSize(0);
      setSelectedContentPaths([]);
    };
  }, [attachmentsData, props.attachmentsPath]);

  useEffect(() => {
    setTotalSelectedAttachmentsSize(getTotalSizeOfSelectedAttachments());

    if (selectedContentPaths.length > 0) setIsDownloadDisabled(false);
    else setIsDownloadDisabled(true);

    if (selectedContentPaths.length === currentAttachmentFiles.length && currentAttachmentFiles.length > 0) {
      setIsSelectAll(true);
    }
  }, [selectedContentPaths]);

  useEffect(() => {
    if (isFetchingDownload)
      setIsDownloadDisabled(true);
    else
      setIsDownloadDisabled(selectedContentPaths.length === 0);
  }, [isFetchingDownload]);

  useEffect(() => {
    // If isFetchingDownload, don't allow download-button to be enabled
    if (isFetchingDownload && isDownloadDisabled === false)
      setIsDownloadDisabled(true);
  }, [isDownloadDisabled]);

  const getFolderDetails = (attachments: AttachmentItem[], attachmentsPath: string) => {
    let title = t('Attachments');

    if (attachmentsPath) {
      const attachmentsPathFolders = props.attachmentsPath.split('/');

      attachmentsPathFolders.forEach(
        name => {
          const subFolder = _.find(attachments, { 'name': name });
          if (subFolder) {
            attachments = subFolder.files;
            title = subFolder.fileName;
          }
          else {
            props.onError(`Nested attachments folder ${name} not found for Product ${props.productId}`);
          }
        }
      );
    }

    return {
      title,
      attachments
    };
  };

  const getTotalSizeOfSelectedAttachments = () => {
    if (selectedContentPaths.length === 0)
      return 0;

    return currentAttachmentFiles.reduce((sum, curAttachment) => {
      if (selectedContentPaths.includes(curAttachment.contentPath))
        return sum + curAttachment.fileSize;
      return sum;
    }, 0);
  };

  const getCurrentFoldersNumber = (attachments: AttachmentItem[]) => {
    return attachments.reduce((sum, curAttachment) => {
      if (curAttachment.type.toLowerCase() == 'folder')
        return sum + 1;
      return sum;
    }, 0);
  };

  const getCurrentFilesNumber = (attachments: AttachmentItem[]) => {
    return attachments.reduce((sum, curAttachment) => {
      if (curAttachment.type.toLowerCase() == 'file')
        return sum + 1;
      return sum;
    }, 0);
  };

  const onSelectAllClick = (checked: boolean) => {
    setIsSelectAll(checked);

    if (checked) {
      setSelectedContentPaths(currentAttachmentFiles.map((item) => item.contentPath));
    } else {
      setSelectedContentPaths([]);
    }
  };

  const downloadZip = async (zipID: string) => {
    let isFinished = false;
    while (!isFinished) {
      try {
        const attachmentZipLink = await services.product.getAttachmentZipLink(zipID);
        if (attachmentZipLink.link) {
          initFileDownload(attachmentZipLink.link);
          setIsFetchingDownload(false);
          isFinished = true;
        } else if (attachmentZipLink.error) {
          setIsFetchingDownload(false);
          notifyBugsnagHandledError(new Error(attachmentZipLink.error));
          isFinished = true;
        } else {
          await sleep(zipRetryTime);  // Throttle calls to prevent API / s3-zipper overload.
        }
      } catch (error) {
        notifyBugsnagHandledError(error as Error);
        setIsFetchingDownload(false);
        isFinished = true;
      }
    }
  };

  const downloadSelectedAttachments = async (selectedAttachments: AttachmentsDownloadRequest[]) => {
    try {
      const downloadInfo = 
          await services.product.handleProductAttachmentsDownload(props.productId, selectedAttachments);
      if (downloadInfo.link) {
        initFileDownload(downloadInfo.link);
        setIsFetchingDownload(false);
      } else if (downloadInfo.zipID) downloadZip(downloadInfo.zipID);
    } catch (error) {
      setIsFetchingDownload(false);
      notifyBugsnagHandledError(error as Error);
      addError('Please try again');
    }
  };

  const onDownloadClick = async () => {
    setIsFetchingDownload(true);
    const selectedAttachments = selectedContentPaths.reduce(
      (attachments: AttachmentsDownloadRequest[], contentPath: string) => {
        const attachment = currentAttachmentFiles.find(attachment => attachment.contentPath === contentPath);
        if (!attachment) return attachments;
        attachments.push({
          contentPath,
          id: attachment.id,
          type: attachment.type,
        });

        return attachments;
      }, []);

    downloadSelectedAttachments(selectedAttachments);
  };

  const onItemDownloadBtnClick = async (contentPath: string) => {
    setIsFetchingDownload(true);
    const attachment = currentAttachmentFiles.find(attachment => attachment.contentPath === contentPath);
    if (!attachment) return;
    const selectedAttachment = {
      contentPath,
      id: attachment.id,
      type: attachment.type,
    };

    downloadSelectedAttachments([selectedAttachment]);
  };

  const onItemCheckboxClick = (attachmentPath: string, checked: boolean) => {
    if (checked) {
      setSelectedContentPaths([...selectedContentPaths, attachmentPath]);
    } else {
      setSelectedContentPaths(selectedContentPaths.filter((item) => item != attachmentPath));
      setIsSelectAll(false);
    }
  };

  const onItemBodyClick = (itemName: string, attachmentType: string) => {
    if (attachmentType == 'folder') {
      props.onFolderClick(itemName);
    }
  };

  const DownloadButtonSection : React.FC = () =>
    <div>
      {
        !!totalSelectedAttachmentsSize && 
        <span className='attachment-items__size'>{formatByteSize(totalSelectedAttachmentsSize)}</span>
      }
      <Button 
        type='button' 
        icon='download'
        onClick={onDownloadClick} 
        disabled={isDownloadDisabled}
        className='attachments-view__download-btn'
      >
        {isFetchingDownload ? `${t('Loading')}...` : t('Download')}
      </Button>
    </div>;

  return (
    <div
      className={
        classnames('attachments-view', {
          'attachments-view--mobile': props.variant === 'mobile'
        })}
      data-testid='attachments-view'
    >
      <div className='title'>{isLoading ? <Skeleton height={80} width={240} /> : title}</div>
      <div className='sub-title'>{!isLoading && `${numFolders} 
        ${t('Folders')}, ${numFiles} ${numFiles === 1 ? t('File') : t('Files')}`}</div>
      <div className='attachment-items'>
        <div className='attachment-items__header' data-testid={'attachment-items__header'}>
          <Checkbox id='select-all-attachments'
            label={isSmallViewport ? null : t('Select All')}
            onChange={onSelectAllClick}
            checked={isSelectAll} />
          <DownloadButtonSection />
        </div>
        <div className='attachment-items__body'>
          <hr />
          {isLoading
            ? <Skeleton height={80} count={3} />
            : currentAttachmentFiles.map((item) =>
              <div key={item.contentPath}>
                <div>
                  <AttachmentItemView
                    id={item.id}
                    name={item.name}
                    type={item.contentType}
                    size={item.fileSize}
                    isChecked={selectedContentPaths.includes(item.contentPath)}
                    contentPath={item.contentPath}
                    numberOfContainedFiles={item.contentType == 'folder' ? item.fileAmount : 1}
                    onCheckboxClick={onItemCheckboxClick}
                    onDownloadBtnClick={onItemDownloadBtnClick}
                    onBodyClick={onItemBodyClick}
                  />
                </div>
                <hr />
              </div>
            )}
        </div>
        <div className='attachment-items__header' data-testid={'attachment-items__header'}>
          <div />
          <DownloadButtonSection />
        </div>
      </div>
    </div>
  );
};

export default AttachmentsView;
